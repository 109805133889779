import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import PlatformSelector from './components/PlatformSelector';
import RangeSelector from './components/RangeSelector';

const useStyles = makeStyles((theme) => ({
  counts: {
    color: theme.palette.primary.light,
  },
  separator: {
    width: theme.spacing(1),
  },
  wrapperButtons: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  wrapperRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const FiltersBar = ({
  startDate,
  changeStartDate,
  endDate,
  changeEndDate,
  rangeFilter,
  changeRangeFilter,
  platformFilter,
  changePlatformFilter,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapperRoot}>
      <RangeSelector
        startDate={startDate}
        changeStartDate={changeStartDate}
        endDate={endDate}
        changeEndDate={changeEndDate}
        rangeFilter={rangeFilter}
        changeRangeFilter={changeRangeFilter}
      />
      <div className={classes.wrapperButtons}>
        <PlatformSelector
          platformFilter={platformFilter}
          changePlatformFilter={changePlatformFilter}
        />
      </div>
    </div>
  );
};

FiltersBar.defaultProps = {
  startDate: new Date(),
  changeStartDate: (newValue) =>
    `Start date was changed to '${newValue.toString()}'`,
  endDate: new Date(),
  changeEndDate: (newValue) =>
    `End date was changed to '${newValue.toString()}'`,
  rangeFilter: 'PAST_7_DAYS',
  changeRangeFilter: (newFilter) =>
    console.log('User changes the range filter to:', newFilter),
  platformFilter: 'AMAZON',
  changePlatformFilter: (newFilter) =>
    console.log('User changes the platform filter to:', newFilter),
};

FiltersBar.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  changeStartDate: PropTypes.func,
  endDate: PropTypes.instanceOf(Date),
  changeEndDate: PropTypes.func,
  rangeFilter: PropTypes.string,
  changeRangeFilter: PropTypes.func,
  platformFilter: PropTypes.string,
  changePlatformFilter: PropTypes.func,
};

export default FiltersBar;
