/* eslint-disable no-unused-vars */
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import allStyles from '../../../../../utils/styles';
import MissingInfoDialog from '../MissingInfoDialog';
import { findMissingInfo } from '../../logic';

const headerStyle = {
  backgroundColor: '#A7A5A5',
  color: '#171415',
};

const useStyles = makeStyles(allStyles);

const PresaleSkuTable = ({ skus }) => {
  const classes = useStyles();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [incompletedSkuMissingInfo, setIncompletedSkuMissingInfo] = useState(
    {}
  );

  useEffect(() => {
    console.log('inc', incompletedSkuMissingInfo);
  }, [incompletedSkuMissingInfo]);

  const columns = [
    {
      title: 'SKU',
      field: 'eolSku',
      editable: 'never',
    },
    {
      title: 'Supplier Description',
      field: 'supplierDescription',
      editable: 'never',
    },

    {
      field: 'internal_action',
      editable: false,
      render: (rowData) =>
        rowData && (
          <Button
            onClick={() => {
              setIncompletedSkuMissingInfo(findMissingInfo(rowData));
              setIsDialogOpen(true);
            }}
            color="secondary"
          >
            View Info
          </Button>
        ),
      width: '5%',
    },
  ];

  return (
    <div className={classes.wrapperContent}>
      <MaterialTable
        columns={columns}
        data={skus}
        options={{
          headerStyle,
          sorting: false,
          grouping: false,
          draggable: false,
          search: false,
          showTitle: false,
          toolbar: false,
          selection: false,
        }}
        components={{
          OverlayLoading: () => (
            <div className={classes.loadingOverlay}>
              <CircularProgress color="primary" size={32} />
            </div>
          ),
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'No available products.',
          },
        }}
      />
      <MissingInfoDialog
        isOpen={isDialogOpen}
        closeDialog={() => setIsDialogOpen(false)}
        missingSkuInfo={incompletedSkuMissingInfo}
      />
    </div>
  );
};

PresaleSkuTable.defaultProps = {
  skus: [],
};

PresaleSkuTable.propTypes = {
  skus: PropTypes.arrayOf(PropTypes.shape()),
};

export default PresaleSkuTable;
