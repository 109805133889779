import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ClientSelector from './components/ClientSelector';
import CopywriterSelector from './components/CopywriterSelector';
import StatusSelector from './components/StatusSelector';

const useStyles = makeStyles((theme) => ({
  results: {
    margin: theme.spacing(1),
    color: grey[800],
  },
  separator: {
    width: theme.spacing(1),
  },
  wrapperButtons: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  wrapperRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
}));

const FiltersBar = ({
  searchText,
  changeSearchText,
  listingCount,
  copywriterFilter,
  changeCopywriterFilter,
  copywriterOptions,
  clientFilter,
  changeClientFilter,
  clientOptions,
  statusFilter,
  changeStatusFilter,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapperRoot}>
      <TextField
        placeholder="Search"
        value={searchText}
        onChange={(e) => changeSearchText(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <Typography className={classes.results} variant="body1" component="span">
        {`${listingCount} ${
          listingCount === 1 ? 'result' : 'results'
        } displayed`}
      </Typography>
      <div className={classes.wrapperButtons}>
        <CopywriterSelector
          copywriterFilter={copywriterFilter}
          changeCopywriterFilter={changeCopywriterFilter}
          copywriterOptions={copywriterOptions}
        />
        <div className={classes.separator} />
        <ClientSelector
          clientFilter={clientFilter}
          changeClientFilter={changeClientFilter}
          clientOptions={clientOptions}
        />
        <div className={classes.separator} />
        <StatusSelector
          statusFilter={statusFilter}
          changeStatusFilter={changeStatusFilter}
          statuses={[
            {
              id: 'NOT_STARTED',
              label: 'Not Started',
            },
            {
              id: 'DRAFTING',
              label: 'Drafting',
            },
            {
              id: 'REVIEWING',
              label: 'Reviewing',
            },
            {
              id: 'COMPLETED',
              label: 'Completed',
            },
          ]}
        />
      </div>
    </div>
  );
};

FiltersBar.defaultProps = {
  searchText: '',
  changeSearchText: (newValue) => `Search text was changed to '${newValue}'`,
  listingCount: 1,
  copywriterFilter: 'John Doe',
  changeCopywriterFilter: (newFilter) =>
    console.log('User changes the copywriter filter to:', newFilter),
  copywriterOptions: [],
  clientFilter: 'AMAZON',
  changeClientFilter: (newFilter) =>
    console.log('User changes the client filter to:', newFilter),
  clientOptions: [],
  statusFilter: 'ALL',
  changeStatusFilter: (newFilter) =>
    console.log('User changes the status filter to:', newFilter),
};

FiltersBar.propTypes = {
  searchText: PropTypes.string,
  changeSearchText: PropTypes.func,
  listingCount: PropTypes.number,
  copywriterFilter: PropTypes.string,
  changeCopywriterFilter: PropTypes.func,
  copywriterOptions: PropTypes.arrayOf(PropTypes.string),
  clientFilter: PropTypes.string,
  changeClientFilter: PropTypes.func,
  clientOptions: PropTypes.arrayOf(PropTypes.string),
  statusFilter: PropTypes.string,
  changeStatusFilter: PropTypes.func,
};

export default FiltersBar;
