import { Redirect } from 'react-router-dom';
import ClientsAdd from '../pages/CRM/ClientsAdd';
import ClientsBrowse from '../pages/CRM/ClientsBrowse';
import ClientsDocumentUpload from '../pages/CRM/ClientsDocumentsUpload';
import ClientsEdit from '../pages/CRM/ClientsEdit';
import ClientsQueriesBrowse from '../pages/CRM/ClientsQueriesBrowse';
import ClientsQueryAdd from '../pages/CRM/ClientsQueryAdd';
import ClientsQueryEdit from '../pages/CRM/ClientsQueryEdit';
import ClientsQueryDocumentsUpload from '../pages/CRM/ClientsQueryDocumentsUpload';
import ClientsQueryImageUpload from '../pages/CRM/ClientsQueryImageUpload';
import ClientsQueryView from '../pages/CRM/ClientsQueryView';
import ClientsView from '../pages/CRM/ClientsView';
import DealsAdjustments from '../pages/Sales/DealsAdjustments';
import DealsBrowse from '../pages/Sales/DealsBrowse';
import DealsCreate from '../pages/Sales/DealsCreate';
import DealsView from '../pages/Sales/DealsView';
import FinanceStatements from '../pages/Finances/FinanceStatements';
import ListingsAdd from '../pages/Listings/ListingsAdd';
import ListingsAdjustPricing from '../pages/Listings/ListingsAdjustPricing';
import ListingsAnalytics from '../pages/Listings/ListingsAnalytics';
import ListingsAnalyticsSku from '../pages/Listings/ListingsAnalyticsSku';
import ListingsBasicInfo from '../pages/Listings/ListingsBasicInfo';
import ListingsCopyDashboard from '../pages/Listings/ListingsCopyDashboard';
import ListingsCopyWriting from '../pages/Listings/ListingsCopyWriting';
import ListingsEdit from '../pages/Listings/ListingsEdit';
import ListingsImageUpload from '../pages/Listings/ListingsImageUpload';
import ListingsOnboarding from '../pages/Listings/ListingsOnboarding';
import ListingsStockInfo from '../pages/Listings/ListingsStockInfo';
import ListingsView from '../pages/Listings/ListingsView';
import LogisticsReplenishments from '../pages/Logistics/LogisticsReplenishments';
import LogisticsShipmentAdd from '../pages/Logistics/ShipmentAdd';
import LogisticsShipmentEdit from '../pages/Logistics/ShipmentEdit';
import LogisticsShipments from '../pages/Logistics/LogisticsShipment';
import LogisticsStorage from '../pages/Logistics/LogisticsStorage';
import MaintenanceReports from '../pages/MaintenanceReports';
import PresalesListingEditAdd from '../pages/Presales/ListingEditsAdd';
import PresalesListingEditsBrowse from '../pages/Presales/ListingEditsBrowse';
import PresalesOrdersBrowse from '../pages/Presales/OrdersBrowse';
import PresalesPipelineView from '../pages/Presales/PipelineView';
import PresalesProductsBrowse from '../pages/Presales/ProductsBrowse';
import PresalesProductsTakealotUpdate from '../pages/Presales/ProductsTakealotUpdate';
import PresalesReportsBrowse from '../pages/Presales/ReportsBrowse';
import PresalesSubmissionsAdd from '../pages/Presales/SubmissionsAdd';
import PresalesSubmissionsBrowse from '../pages/Presales/SubmissionsBrowse';
import PresalesSuppliersAdd from '../pages/Presales/SuppliersAdd';
import PresalesSuppliersBrowse from '../pages/Presales/SuppliersBrowse';
import PresalesSuppliersDocumentUpload from '../pages/Presales/SuppliersDocumentsUpload';
import PresalesSuppliersEdit from '../pages/Presales/SuppliersEdit';
import PresalesSuppliersUpdatePricing from '../pages/Presales/SuppliersUpdatePricing';
import PresalesSuppliersUpdatePricingSettings from '../pages/Presales/SuppliersUpdatePricingSettings';
import PresalesSuppliersUpdateStock from '../pages/Presales/SuppliersUpdateStock';
import PresalesSuppliersUpdateStockSettings from '../pages/Presales/SuppliersUpdateStockSettings';
import PresalesSuppliersView from '../pages/Presales/SuppliersView';
import PresalesUpdatesBrowse from '../pages/Presales/UpdatesBrowse';
import ShipmentsDocumentsUpload from '../pages/Logistics/ShipmentsDocumentsUpload';
import ShipmentsView from '../pages/Logistics/ShipmentsView';
import SkuBulkUpload from '../pages/Presales/SkuBulkUpload';
import SourcedProductsAdd from '../pages/Sales/SourcedProductsAdd';
import SourcedProductsBrowse from '../pages/Sales/SourcedProductsBrowse';
import SourcedProductsEdit from '../pages/Sales/SourcedProductsEdit';
import SourcedProductsView from '../pages/Sales/SourcedProductsView';
import StockRequestsBrowse from '../pages/CRM/StockRequestsBrowse';
import StockRequestsDocumentsUpload from '../pages/CRM/StockRequestsDocumentsUpload';
import StockRequestsView from '../pages/CRM/StockRequestsView';
import SuppliersBrowse from '../pages/Sales/SuppliersBrowse';
import SuppliersDocumentUpload from '../pages/Sales/SuppliersDocumentsUpload';
import SuppliersEdit from '../pages/Sales/SuppliersEdit';
import SuppliersView from '../pages/Sales/SuppliersView';
import UsersAdd from '../pages/Users/UsersAdd';
import UsersBrowse from '../pages/Users/UsersBrowse';
import UsersView from '../pages/Users/UsersView';

const routes = [
  {
    path: '/dashboard',
    component: <Redirect to="/sales/deals/browse" />,
  },
  {
    path: '/presales/products',
    component: <Redirect to="/presales/products/browse" />,
  },
  {
    path: '/presales/products/browse',
    component: <PresalesProductsBrowse />,
  },
  {
    path: '/presales/products/takealot-update',
    component: <PresalesProductsTakealotUpdate />,
  },
  {
    path: '/presales/suppliers',
    component: <Redirect to="/presales/suppliers/browse" />,
  },
  {
    path: '/presales/suppliers/browse',
    component: <PresalesSuppliersBrowse />,
  },
  {
    path: '/presales/suppliers/add',
    component: <PresalesSuppliersAdd />,
  },
  {
    path: '/presales/suppliers/edit/:supplierId',
    component: <PresalesSuppliersEdit />,
  },
  {
    path: '/presales/suppliers/browse/:supplierId',
    component: <PresalesSuppliersView />,
  },
  {
    path: '/presales/suppliers/browse/upload-documents/:supplierId',
    component: <PresalesSuppliersDocumentUpload />,
  },
  {
    path: '/presales/suppliers/browse/update-pricing/:supplierId',
    component: <PresalesSuppliersUpdatePricing />,
  },
  {
    path: '/presales/suppliers/browse/update-pricing-settings/:supplierId',
    component: <PresalesSuppliersUpdatePricingSettings />,
  },
  {
    path: '/presales/suppliers/browse/update-stock/:supplierId',
    component: <PresalesSuppliersUpdateStock />,
  },
  {
    path: '/presales/suppliers/browse/update-stock-settings/:supplierId',
    component: <PresalesSuppliersUpdateStockSettings />,
  },
  {
    path: '/presales/orders',
    component: <Redirect to="/presales/orders/browse" />,
  },
  {
    path: '/presales/orders/browse',
    component: <PresalesOrdersBrowse />,
  },
  {
    path: '/presales/prices',
    component: <Redirect to="/presales/prices/browse" />,
  },
  {
    path: '/presales/stock',
    component: <Redirect to="/presales/stock/browse" />,
  },
  {
    path: '/presales/listing-edits',
    component: <Redirect to="/presales/listing-edits/browse" />,
  },
  {
    path: '/presales/listing-edits/browse',
    component: <PresalesListingEditsBrowse />,
  },
  {
    path: '/presales/listing-edits/add',
    component: <PresalesListingEditAdd />,
  },
  {
    path: '/presales/reports',
    component: <Redirect to="/presales/reports/browse" />,
  },
  {
    path: '/presales/reports/browse',
    component: <PresalesReportsBrowse />,
  },
  {
    path: '/presales/pipeline',
    component: <Redirect to="/presales/pipeline/view" />,
  },
  {
    path: '/presales/pipeline/view',
    component: <PresalesPipelineView />,
  },
  {
    path: '/presales/tailgating',
    component: <Redirect to="/presales/tailgating/dashboard" />,
  },
  {
    path: '/presales/pipeline/view/bulk-upload',
    component: <SkuBulkUpload />,
  },
  {
    path: '/presales/submissions',
    component: <Redirect to="/presales/submissions/browse" />,
  },
  {
    path: '/presales/submissions/browse',
    component: <PresalesSubmissionsBrowse />,
  },
  {
    path: '/presales/submissions/browse/add',
    component: <PresalesSubmissionsAdd />,
  },
  {
    path: '/presales/updates',
    component: <Redirect to="/presales/updates/browse" />,
  },
  {
    path: '/presales/updates/browse',
    component: <PresalesUpdatesBrowse />,
  },
  {
    path: '/sales/leads',
    component: <Redirect to="/sales/deals/browse" />,
  },
  {
    path: '/sales/emails',
    component: <Redirect to="/sales/deals/browse" />,
  },
  {
    path: '/sales/deals',
    component: <Redirect to="/sales/deals/browse" />,
  },
  {
    path: '/sales/deals/browse',
    component: <DealsBrowse />,
  },
  {
    path: '/sales/deals/browse/:dealId',
    component: <DealsView />,
  },
  {
    path: '/sales/deals/browse/:dealId/adjust',
    component: <DealsAdjustments />,
  },
  {
    path: '/sales/deals/create',
    component: <DealsCreate />,
  },
  {
    path: '/sales/proposals',
    component: <Redirect to="/sales/proposals/list" />,
  },
  {
    path: '/sales/products/add',
    component: <SourcedProductsAdd />,
  },
  {
    path: '/sales/products',
    component: <Redirect to="/sales/products/browse" />,
  },
  {
    path: '/sales/products/browse',
    component: <SourcedProductsBrowse />,
  },
  {
    path: '/sales/products/browse/:id',
    component: <SourcedProductsView />,
  },
  {
    path: '/sales/products/browse/edit/:id',
    component: <SourcedProductsEdit />,
  },
  {
    path: '/sales/suppliers',
    component: <Redirect to="/sales/suppliers/browse" />,
  },
  {
    path: '/sales/suppliers/browse',
    component: <SuppliersBrowse />,
  },
  {
    path: '/sales/suppliers/browse/upload-documents/:id',
    component: <SuppliersDocumentUpload />,
  },
  {
    path: '/sales/suppliers/browse/:id',
    component: <SuppliersView />,
  },
  {
    path: '/sales/suppliers/browse/edit/:id',
    component: <SuppliersEdit />,
  },
  {
    path: '/sales/supplier/browse/add',
    component: <SuppliersEdit />,
  },
  {
    path: '/listings',
    component: <Redirect to="/listings/basic-info/browse" />,
  },
  {
    path: '/listings/basic-info',
    component: <Redirect to="/listings/basic-info/browse" />,
  },
  {
    path: '/listings/copywriting',
    component: <Redirect to="/listings/copywriting/dashboard" />,
  },
  {
    path: '/listings/copywriting/dashboard',
    component: <ListingsCopyDashboard />,
  },
  {
    path: '/listings/analytics',
    component: <Redirect to="/listings/analytics/dashboard" />,
  },
  {
    path: '/listings/analytics/dashboard',
    component: <ListingsAnalytics />,
  },
  {
    path: '/listings/analytics/dashboard/:sku',
    component: <ListingsAnalyticsSku />,
  },
  {
    path: '/listings/onboarding',
    component: <Redirect to="/listings/onboarding/dashboard" />,
  },
  {
    path: '/listings/onboarding/dashboard',
    component: <ListingsOnboarding />,
  },
  {
    path: '/listings/basic-info/copy-writing/:sku',
    component: <ListingsCopyWriting />,
  },
  {
    path: '/listings/basic-info/browse',
    component: <ListingsBasicInfo />,
  },
  {
    path: '/listings/basic-info/browse/:sku',
    component: <ListingsView />,
  },
  {
    path: '/listings/basic-info/add',
    component: <ListingsAdd />,
  },
  {
    path: '/listings/basic-info/edit',
    component: <Redirect to="/listings/basic-info/browse" />,
  },
  {
    path: '/listings/basic-info/edit/:sku',
    component: <ListingsEdit />,
  },
  {
    path: '/listings/basic-info/image-upload/:sku',
    component: <ListingsImageUpload />,
  },
  {
    path: '/listings/pricing',
    component: <Redirect to="/listings/pricing/adjust" />,
  },
  {
    path: '/listings/pricing/adjust',
    component: <ListingsAdjustPricing />,
  },
  {
    path: '/listings/stock-info',
    component: <Redirect to="/listings/stock-info/browse" />,
  },
  {
    path: '/listings/stock-info/browse',
    component: <ListingsStockInfo />,
  },
  {
    path: '/logistics',
    component: <Redirect to="/logistics/storage/browse" />,
  },
  {
    path: '/logistics/storage',
    component: <Redirect to="/logistics/storage/browse" />,
  },
  {
    path: '/logistics/storage/browse',
    component: <LogisticsStorage />,
  },
  {
    path: '/logistics/shipments',
    component: <Redirect to="/logistics/shipments/browse" />,
  },
  {
    path: '/logistics/shipments/browse',
    component: <LogisticsShipments />,
  },
  {
    path: '/logistics/replenishments',
    component: <Redirect to="/logistics/replenishments/browse" />,
  },
  {
    path: '/logistics/replenishments/browse',
    component: <LogisticsReplenishments />,
  },
  {
    path: '/logistics/shipments/browse/:shipmentId',
    component: <ShipmentsView />,
  },
  {
    path: '/logistics/shipments/add',
    component: <LogisticsShipmentAdd />,
  },
  {
    path: '/logistics/shipments/edit',
    component: <Redirect to="/logistics/shipments/browse" />,
  },
  {
    path: '/logistics/shipments/edit/:shipmentId',
    component: <LogisticsShipmentEdit />,
  },
  {
    path: '/logistics/shipments/browse/upload-documents/:shipmentId',
    component: <ShipmentsDocumentsUpload />,
  },
  {
    path: '/crm',
    component: <Redirect to="/crm/clients/browse" />,
  },
  {
    path: '/crm/clients',
    component: <Redirect to="/crm/clients/browse" />,
  },
  {
    path: '/crm/clients/add',
    component: <ClientsAdd />,
  },
  {
    path: '/crm/clients/browse',
    component: <ClientsBrowse />,
  },
  {
    path: '/crm/clients/browse/:clientId',
    component: <ClientsView />,
  },
  {
    path: '/crm/clients/edit/:clientId',
    component: <ClientsEdit />,
  },
  {
    path: '/crm/clients/upload-documents/:clientId',
    component: <ClientsDocumentUpload />,
  },
  {
    path: '/crm/queries/browse/:queryId',
    component: <ClientsQueryView />,
  },
  {
    path: '/crm/stock-requests',
    component: <Redirect to="/crm/stock-requests/browse" />,
  },
  {
    path: '/crm/stock-requests/browse',
    component: <StockRequestsBrowse />,
  },
  {
    path: '/crm/stock-requests/browse/:requestId',
    component: <StockRequestsView />,
  },
  {
    path: '/crm/stock-requests/browse/upload-documents/:requestId',
    component: <StockRequestsDocumentsUpload />,
  },
  {
    path: '/crm/queries',
    component: <Redirect to="/crm/queries/browse" />,
  },
  {
    path: '/crm/queries/add',
    component: <ClientsQueryAdd />,
  },
  {
    path: '/crm/queries/edit/:queryId',
    component: <ClientsQueryEdit />,
  },
  {
    path: '/crm/queries/image-upload/:queryId',
    component: <ClientsQueryImageUpload />,
  },
  {
    path: '/crm/queries/document-upload/:queryId',
    component: <ClientsQueryDocumentsUpload />,
  },
  {
    path: '/crm/queries/browse',
    component: <ClientsQueriesBrowse />,
  },
  {
    path: '/finance',
    component: <Redirect to="/finance/statements/reports" />,
  },
  {
    path: '/finance/statements',
    component: <Redirect to="/finance/statements/reports" />,
  },
  {
    path: '/finance/statements/reports',
    component: <FinanceStatements />,
  },
  {
    path: '/finance/statements/reports/:reportId',
    component: <FinanceStatements />,
  },
  {
    path: '/finance/statements/reports/:reportId/:platform',
    component: <FinanceStatements />,
  },
  {
    path: '/finance/statements/reports/:reportId/:platform/:clientId',
    component: <FinanceStatements />,
  },
  {
    path: '/user-management',
    component: <Redirect to="/user-management/users/browse" />,
  },
  {
    path: '/user-management/staff',
    component: <Redirect to="/user-management/staff/browse" />,
  },
  {
    path: '/user-management/staff/browse',
    component: <UsersBrowse />,
  },
  {
    path: '/user-management/staff/browse/:userId',
    component: <UsersView />,
  },
  {
    path: '/user-management/staff/add',
    component: <UsersAdd />,
  },
  {
    path: '/system-maintenance',
    component: <Redirect to="/system-maintenance/reports" />,
  },
  {
    path: '/system-maintenance/reports',
    component: <MaintenanceReports />,
  },
];

export default routes;
