import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  loadingWrapper: {
    padding: theme.spacing(8),
    textAlign: 'center',
  },
}));

const getFormattedType = (type) => {
  switch (type) {
    case 'STANDARD':
      return 'Standard';
    case 'SUPER_DEALER':
      return 'Super Dealer';
    default:
      return type;
  }
};

const MiscellaneousCard = ({ isLoading, onMissingInfoClick, clientInfo }) => {
  const classes = useStyles();

  const { type = '', dealerCode = '' } = clientInfo;

  return (
    <Card>
      <CardHeader title="Miscellaneous" />
      <Divider />
      <CardContent>
        {isLoading ? (
          <div className={classes.loadingWrapper}>
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <List>
            <ListItem>
              <ListItemText
                primary={
                  type.length === 0
                    ? 'Missing Info'
                    : `${getFormattedType(type)}`
                }
                secondary="Client Type"
              />
              {type.length === 0 && (
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={onMissingInfoClick}
                  >
                    <HelpOutlineIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
            {type === 'SUPER_DEALER' && (
              <ListItem>
                <ListItemText
                  primary={
                    dealerCode.length === 0 ? 'No Dealer Code' : `${dealerCode}`
                  }
                  secondary="Dealer Code"
                />
                {dealerCode.length === 0 && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={onMissingInfoClick}
                    >
                      <HelpOutlineIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            )}
          </List>
        )}
      </CardContent>
    </Card>
  );
};

MiscellaneousCard.defaultProps = {
  isLoading: false,
  onMissingInfoClick: () => console.log('Missing info button was clicked.'),
  clientInfo: {
    type: PropTypes.string,
    dealerCode: PropTypes.string,
  },
};

MiscellaneousCard.propTypes = {
  isLoading: PropTypes.bool,
  onMissingInfoClick: PropTypes.func,
  clientInfo: PropTypes.shape({
    type: PropTypes.string,
    dealerCode: PropTypes.string,
  }),
};

export default MiscellaneousCard;
