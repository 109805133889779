import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { green, red, yellow } from '@material-ui/core/colors';
import AppBar from '@material-ui/core/AppBar';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Grid from '@material-ui/core/Grid';
import ImageIcon from '@material-ui/icons/Image';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import SpellcheckIcon from '@material-ui/icons/Spellcheck';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import {
  deleteSkuImage,
  fetchImages,
  fetchSkuInfo,
  fetchWarehouses,
  updateListingOrders,
  fetchCopies,
} from './slice';
import { getFilteredImages, getSkuName } from './logic';
import AmazonInfo from './components/AmazonInfo';
import BasicInfoCard from './components/BasicInfoCard';
import ContentWrapper from '../../../components/ContentWrapper';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import EmptyViewMessage from '../../../components/EmptyViewMessage';
import FiltersBar from './components/FiltersBar';
import ImagePreviewCard from './components/ImagePreviewCard';
import PageHeader from '../../../components/PageHeader';
import StockInfo from './components/StockInfo';
import TakealotInfo from './components/TakealotInfo';
import CopiesCard from './components/CopiesCard';

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
  icon: {
    color: theme.palette.common.black,
  },
  link: {
    color: theme.palette.common.black,
  },
  pdfButtonInner: {
    display: 'flex',
    alignItems: 'center',
  },
  pdfButtonOuter: {
    color: theme.palette.common.white,
    textDecoration: 'none',
  },
  seperatorButtons: {
    height: theme.spacing(1),
  },
  seperator: {
    height: theme.spacing(3),
  },
  statusActive: {
    width: '100%',
    color: theme.palette.common.black,
    backgroundColor: green[500],
  },
  statusInactive: {
    width: '100%',
    color: theme.palette.common.black,
    backgroundColor: red[500],
  },
  statusOnboarding: {
    width: '100%',
    color: theme.palette.common.black,
    backgroundColor: yellow[500],
  },
  statusWrapper: {
    width: '100%',
    textAlign: 'center',
    padding: theme.spacing(4),
  },
  tradeOutButton: {
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
  totalsWrapper: {
    padding: theme.spacing(4),
  },
}));

const breadcrumbs = [
  {
    label: 'Listings',
    link: '/listings',
  },
  {
    label: 'Basic Info',
    link: '/listings/basic-info',
  },
  {
    label: 'Browse',
    link: '/listings/basic-info/browse',
  },
];

const ListingsView = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { sku } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('TAKEALOT');
  const [tagFilter, setTagFilter] = useState('ALL');
  const [isLinkCopiedFeedbackOpen, setIsLinkCopiedFeedbackOpen] =
    useState(false);
  const [isEditingListingOrder, setIsEditingListingOrder] = useState(false);
  const [isDeleteImageDialogOpen, setIsDeleteImageDialogOpen] = useState(false);
  const [imageToBeDeleted, setImageToBeDeleted] = useState({
    imagePath: '',
    imageId: '',
  });
  const [imageOrder, setImageOrder] = useState({});

  const skuInfo = useSelector((state) => state.listingsView.skuInfo);
  const warehouses = useSelector((state) => state.listingsView.warehousesList);
  const images = useSelector((state) => state.listingsView.imagesList);
  const copies = useSelector((state) => state.listingsView.copiesList);
  const skuInfoLoadingStatus = useSelector(
    (state) => state.listingsView.loadingStatus.skuInfo
  );
  const warehousesLoadingStatus = useSelector(
    (state) => state.listingsView.loadingStatus.warehouses
  );
  const imagesLoadingStatus = useSelector(
    (state) => state.listingsView.loadingStatus.images
  );
  const deleteImageLoadingStatus = useSelector(
    (state) => state.listingsView.loadingStatus.deleteImage
  );
  const updateImageLoadingStatus = useSelector(
    (state) => state.listingsView.loadingStatus.updateImage
  );
  const copiesLoadingStatus = useSelector(
    (state) => state.listingsView.loadingStatus.copies
  );

  useEffect(() => {
    dispatch(fetchSkuInfo(sku));
    dispatch(fetchImages(sku));
    dispatch(fetchCopies(sku));
    if (warehousesLoadingStatus === 'NOT_STARTED') {
      dispatch(fetchWarehouses(sku));
    }
  }, []);

  useEffect(() => {
    if (
      skuInfoLoadingStatus === 'PENDING' ||
      warehousesLoadingStatus === 'PENDING' ||
      imagesLoadingStatus === 'PENDING' ||
      deleteImageLoadingStatus === 'PENDING' ||
      updateImageLoadingStatus === 'PENDING' ||
      copiesLoadingStatus === 'PENDING'
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [
    skuInfoLoadingStatus,
    warehousesLoadingStatus,
    imagesLoadingStatus,
    deleteImageLoadingStatus,
    updateImageLoadingStatus,
    copiesLoadingStatus,
  ]);

  useEffect(() => {
    const tempImageOrder = {};

    images.forEach((imageInfo) => {
      tempImageOrder[imageInfo.id] = imageInfo.listingOrder || null;
    });

    setImageOrder(tempImageOrder);
  }, [images]);

  const adjustImageOrder = (adjustedImageId, newOrderNumber) => {
    let nulledImageId = null;
    _.toPairs(imageOrder).forEach(([imageId, orderNumber]) => {
      if (orderNumber === newOrderNumber) {
        nulledImageId = imageId;
      }
    });

    if (nulledImageId) {
      setImageOrder({
        ...imageOrder,
        [nulledImageId]: null,
        [adjustedImageId]: newOrderNumber,
      });
    } else {
      setImageOrder({
        ...imageOrder,
        [adjustedImageId]: newOrderNumber,
      });
    }
  };

  const getStatusChip = (status) => {
    switch (status) {
      case 'ONBOARDING':
        return (
          <Chip
            icon={<AutorenewIcon className={classes.icon} />}
            label="Onboarding"
            className={classes.statusOnboarding}
          />
        );
      case 'NOT_BUYABLE':
        return (
          <Chip
            icon={<ErrorOutlineIcon className={classes.icon} />}
            label="Not Buyable"
            className={classes.statusOnboarding}
          />
        );
      case 'BUYABLE':
        return (
          <Chip
            icon={<DoneIcon className={classes.icon} />}
            label="Buyable"
            className={classes.statusActive}
          />
        );
      case 'ACTIVE':
        return (
          <Chip
            icon={<DoneIcon className={classes.icon} />}
            label="Active"
            className={classes.statusActive}
          />
        );
      case 'DISABLED_BY_SELLER':
        return (
          <Chip
            icon={<ClearIcon className={classes.icon} />}
            label="Disabled by Seller"
            className={classes.statusInactive}
          />
        );
      case 'DISABLED_BY_TAKEALOT':
        return (
          <Chip
            icon={<ClearIcon className={classes.icon} />}
            label="Disabled by Takealot"
            className={classes.statusInactive}
          />
        );
      case 'INACTIVE':
        return (
          <Chip
            icon={<ClearIcon className={classes.icon} />}
            label="Inactive"
            className={classes.statusInactive}
          />
        );
      default:
        return (
          <Chip
            icon={<DoneIcon className={classes.icon} />}
            label="Active"
            className={classes.statusActive}
          />
        );
    }
  };

  return (
    <div>
      <PageHeader
        title="SKU Info"
        subtitle={
          isLoading
            ? 'Loading...'
            : getSkuName(skuInfo.brand, skuInfo.productName, skuInfo.variation)
        }
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="SKU"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
      />
      {isLoading && <LinearProgress color="secondary" />}
      <ContentWrapper>
        {!isLoading && skuInfo.sku === 'Loading' ? (
          <EmptyViewMessage
            heading="Info Failed to Load"
            message="Please check your internet connection and reload the page."
          />
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <div>
                <Button
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  startIcon={<SpellcheckIcon />}
                  onClick={() =>
                    history.push(`/listings/basic-info/copy-writing/${sku}`)
                  }
                  disabled={copiesLoadingStatus === 'PENDING'}
                >
                  {copies.length === 0 ? 'Draft copy' : 'Adjust Copy'}
                </Button>
                <div className={classes.seperatorButtons} />
                <Button
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  startIcon={<ImageIcon />}
                  onClick={() =>
                    history.push(`/listings/basic-info/image-upload/${sku}`)
                  }
                >
                  Upload images
                </Button>
                <div className={classes.seperatorButtons} />
                <Button
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  startIcon={<EditIcon />}
                  onClick={() =>
                    history.push(`/listings/basic-info/edit/${sku}`)
                  }
                >
                  Edit details
                </Button>
              </div>
              <div className={classes.seperator} />
              <BasicInfoCard
                sku={sku}
                clientName={skuInfo.clientName}
                supplierName={
                  skuInfo.supplierName
                    ? skuInfo.supplierName
                    : skuInfo.clientName
                }
                brand={skuInfo.brand}
                productName={skuInfo.productName}
                variation={skuInfo.variation}
                isLoading={skuInfoLoadingStatus === 'PENDING'}
                onMissingInfoClick={() =>
                  history.push(`/listings/basic-info/edit/${sku}`)
                }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={9}>
              {!isLoading && (
                <div>
                  <AppBar position="static">
                    <Tabs
                      variant="scrollable"
                      scrollButtons="auto"
                      value={activeTab}
                      onChange={(e, newValue) => setActiveTab(newValue)}
                      aria-label="sku tabs"
                    >
                      <Tab label="Takealot" value="TAKEALOT" />
                      <Tab label="Amazon" value="AMAZON" />
                      <Tab label="Stock Info" value="STOCK_INFO" />
                      <Tab label="Images" value="IMAGES" />
                      <Tab label="Copy" value="COPY" />
                    </Tabs>
                  </AppBar>
                  {(activeTab === 'TAKEALOT' || activeTab === 'AMAZON') && (
                    <div className={classes.statusWrapper}>
                      {getStatusChip(
                        activeTab === 'TAKEALOT'
                          ? skuInfo.takealot.status
                          : skuInfo.amazon.status
                      )}
                    </div>
                  )}
                  {(activeTab === 'STOCK_INFO' ||
                    activeTab === 'IMAGES' ||
                    activeTab === 'COPY') && (
                    <div className={classes.seperator} />
                  )}
                  {activeTab === 'IMAGES' && (
                    <FiltersBar
                      tagFilter={tagFilter}
                      changeTagFilter={setTagFilter}
                      changeIsEditingListingOrder={setIsEditingListingOrder}
                      isEditingListingOrder={isEditingListingOrder}
                      updateListingOrders={() =>
                        dispatch(updateListingOrders(sku, imageOrder, images))
                      }
                    />
                  )}
                  {activeTab === 'IMAGES' && (
                    <Grid container spacing={2}>
                      {getFilteredImages(
                        images,
                        tagFilter,
                        isEditingListingOrder
                      ).map((imageInfo) => (
                        <Grid key={imageInfo.id} item xs={4}>
                          <ImagePreviewCard
                            listingOrder={imageOrder[imageInfo.id]}
                            imageTitle={imageInfo.id}
                            fileSize={imageInfo.fileSize}
                            tag={imageInfo.tag}
                            imageLink={imageInfo.url}
                            showLinkCopyFeedback={() =>
                              setIsLinkCopiedFeedbackOpen(true)
                            }
                            deleteImage={() => {
                              setIsDeleteImageDialogOpen(true);
                              setImageToBeDeleted({
                                imagePath: imageInfo.reference,
                                imageId: imageInfo.id,
                              });
                            }}
                            isEditingListingOrder={isEditingListingOrder}
                            adjustImageOrder={(newOrder) =>
                              adjustImageOrder(imageInfo.id, newOrder)
                            }
                          />
                        </Grid>
                      ))}
                    </Grid>
                  )}
                  {activeTab === 'IMAGES' &&
                    getFilteredImages(images, tagFilter, isEditingListingOrder)
                      .length === 0 && (
                      <EmptyViewMessage
                        heading="No Images"
                        message="Use the Upload Images button to add images to this SKU or adjust the filter."
                      />
                    )}
                  <Paper>
                    {activeTab === 'TAKEALOT' && (
                      <TakealotInfo
                        takealotInfo={skuInfo.takealot}
                        isLoading={isLoading}
                        onMissingInfoClick={() =>
                          history.push(`/listings/basic-info/edit/${sku}`)
                        }
                      />
                    )}
                    {activeTab === 'AMAZON' && (
                      <AmazonInfo
                        amazonInfo={skuInfo.amazon}
                        isLoading={isLoading}
                        onMissingInfoClick={() =>
                          history.push(`/listings/basic-info/edit/${sku}`)
                        }
                      />
                    )}
                    {activeTab === 'STOCK_INFO' && (
                      <StockInfo
                        batchSize={skuInfo.batchSize}
                        measurements={skuInfo.measurements}
                        units={skuInfo.stockQuantities}
                        warehouses={warehouses}
                        isLoading={isLoading}
                        onMissingInfoClick={() =>
                          history.push(`/listings/basic-info/edit/${sku}`)
                        }
                      />
                    )}
                    {activeTab === 'COPY' &&
                      !isLoading &&
                      (copies.length > 0 ? (
                        <CopiesCard copies={copies} />
                      ) : (
                        <EmptyViewMessage
                          heading="No Copies"
                          message="Use the Draft Copy button to add a copy to this SKU."
                        />
                      ))}
                  </Paper>
                </div>
              )}
            </Grid>
          </Grid>
        )}
      </ContentWrapper>
      <Snackbar
        open={isLinkCopiedFeedbackOpen}
        autoHideDuration={3000}
        onClose={() => setIsLinkCopiedFeedbackOpen(false)}
      >
        <Alert
          onClose={() => setIsLinkCopiedFeedbackOpen(false)}
          variant="filled"
          elevation={6}
          severity="success"
        >
          Image link copied to clipboard.
        </Alert>
      </Snackbar>
      <ConfirmationDialog
        isOpen={isDeleteImageDialogOpen}
        message="Are you sure you want to delete this image?"
        closeDialog={() => {
          setIsDeleteImageDialogOpen(false);
          setImageToBeDeleted({
            imagePath: '',
            imageId: '',
          });
        }}
        confirmAction={() =>
          dispatch(
            deleteSkuImage(
              sku,
              imageToBeDeleted.imagePath,
              imageToBeDeleted.imageId
            )
          )
        }
      />
    </div>
  );
};

export default ListingsView;
