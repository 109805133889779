/* eslint-disable react/jsx-props-no-spreading */
import 'firebase/storage';
import { Divider } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import moment from 'moment';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import {
  fetchPresalesSubmissions,
  fetchPresalesSubmissionSubcollection,
  fetchPresalesSuppliers,
  requestTemplate,
  updateSubmissionFile,
  uploadSubmission,
} from './slice';
import { firestore } from '../../../utils/firebase';
import allStyles from '../../../utils/styles';
import ContentWrapper from '../../../components/ContentWrapper';
import PageHeader from '../../../components/PageHeader';
import RequestedTemplates from './components/RequestedTemplates';
import Submissions from './components/Submissions';
import TemplateActionBar from './components/TemplateActionBar';
import theme from '../../../utils/theme';

const useStyles = makeStyles(allStyles);

const baseStyle = {
  padding: 14,
  textAlign: 'center',
  borderRadius: 4,
  border: '1px dashed white',
  color: 'white',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const breadcrumbs = [
  {
    label: 'Presales',
    link: '/presales',
  },
  {
    label: 'Updates',
    link: '/presales/updates',
  },
];

const UpdatesBrowse = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const fileUploadRef = useRef();
  const history = useHistory();

  const [activeTab, setActiveTab] = useState('TEMPLATE');
  const [documents, setDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [metadataType, setMetadataType] = useState('ALL');
  const [fileInfo, setFileInfo] = useState([]);
  const [statusId, setStatusId] = useState('INCOMPLETE_SKUS');
  const [supplier, setSupplier] = useState('ALL');
  const [updateSubmissions, setUpdateSubmissions] = useState([]);
  const [updateTemplates, setUpdateTemplates] = useState([]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop: (acceptedDocuments) => {
      setDocuments([...documents, ...acceptedDocuments]);
      setFileInfo(acceptedDocuments.map((file) => file.path));
    },
  });

  const suppliers = useSelector(
    (state) => state.presalesUpdatesBrowse.suppliers
  );
  const submissions = useSelector(
    (state) => state.presalesUpdatesBrowse.submissions
  );
  const submissionSubcollection = useSelector(
    (state) => state.presalesUpdatesBrowse.subcollectionSkus
  );
  const suppliersLoadingStatus = useSelector(
    (state) => state.presalesUpdatesBrowse.loadingStatus.suppliers
  );
  const requestTemplateLoadingStatus = useSelector(
    (state) => state.presalesUpdatesBrowse.loadingStatus.requestTemplate
  );
  const uploadSubmissionLoadingStatus = useSelector(
    (state) => state.presalesUpdatesBrowse.loadingStatus.uploadSubmissions
  );
  const updateSubmissionLoadingStatus = useSelector(
    (state) => state.presalesUpdatesBrowse.loadingStatus.updateSubmissions
  );
  const fetchSubmissionsStatus = useSelector(
    (state) => state.presalesUpdatesBrowse.loadingStatus.fetchSubmissions
  );
  const fetchSubmissionSubcollectionStatus = useSelector(
    (state) =>
      state.presalesUpdatesBrowse.loadingStatus.fetchSubmissionSubcollection
  );
  const userInfo = useSelector((state) => state.account.userInfo);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const checkBatchUpdate = () => {
    dispatch(fetchPresalesSubmissions());
    if (submissions.length > 0) {
      submissions.map(async (updateInfo) => {
        dispatch(fetchPresalesSubmissionSubcollection(updateInfo.id));
        let successCount = 0;
        let failCount = 0;
        submissionSubcollection.forEach((skuInfo) => {
          if (skuInfo.isComplete === true) {
            successCount += 1;
          }
          if (skuInfo.isComplete === false) {
            failCount += 1;
          }
        });
        await updateSubmissionFile(updateInfo.id, {
          totalPresaleSkuUpdateFailed: failCount,
          totalPresaleSkuUpdateSuccessful: successCount,
        });
        if (updateInfo.totalPresaleSkusToUpdate === successCount + failCount) {
          await updateSubmissionFile(updateInfo.id, {
            allSkusCompleted: true,
          });
        }
      });
    }
  };

  useEffect(() => {
    dispatch(fetchPresalesSuppliers());
    dispatch(fetchPresalesSubmissions());

    const updateTemplateRef = firestore
      .collection('presalesUpdatesTemplates')
      .orderBy('requestDate', 'desc')
      .limit(20);

    const updateSubmissionRef = firestore
      .collection('presalesUpdatesSubmissions')
      .orderBy('submissionDate', 'desc')
      .limit(20);

    const unsubscribeTemplates = updateTemplateRef.onSnapshot(
      (querySnapshot) => {
        const result = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            requestDate: data.requestDate.toDate(),
          };
        });

        setUpdateTemplates(result);
      }
    );

    const unsubscribeSubmission = updateSubmissionRef.onSnapshot(
      (querySnapshot) => {
        const result = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
          };
        });

        setUpdateSubmissions(result);
      }
    );

    return () => {
      unsubscribeTemplates();
      unsubscribeSubmission();
    };
  }, []);

  useEffect(() => {
    if (fileUploadRef.current) {
      fileUploadRef.current.removeFile();
    }

    const interval = setInterval(checkBatchUpdate, 1000);
    if (activeTab === 'TEMPLATE') {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [activeTab]);

  useEffect(() => {
    if (
      suppliersLoadingStatus === 'PENDING' ||
      requestTemplateLoadingStatus === 'PENDING' ||
      uploadSubmissionLoadingStatus === 'PENDING' ||
      fetchSubmissionsStatus === 'PENDING' ||
      updateSubmissionLoadingStatus === 'PENDING'
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [
    suppliersLoadingStatus,
    requestTemplateLoadingStatus,
    uploadSubmissionLoadingStatus,
    fetchSubmissionsStatus,
    fetchSubmissionSubcollectionStatus,
    updateSubmissionLoadingStatus,
  ]);

  useEffect(() => {
    if (requestTemplateLoadingStatus === 'COMPLETE') {
      setSupplier('ALL');
      setStatusId('INCOMPLETE_SKUS');
      setMetadataType('ALL');
    }
  }, [requestTemplateLoadingStatus]);

  const uploadUpdateSubmission = () => {
    dispatch(
      uploadSubmission(documents, {
        userID: userInfo.uid,
        requestedBy: `${userInfo.name} ${userInfo.surname}`,
        allSkusCompleted: false,
      })
    );
    setFileInfo([]);
    setDocuments([]);
  };

  const getSupplierName = () => {
    const supplierInfo = suppliers.find((info) => info.id === supplier);
    if (!supplierInfo) {
      return 'Unknown';
    }

    return supplierInfo.name;
  };

  const formatSubmissions = () => {
    const presaleSkusInfo = [];
    updateSubmissions.forEach((subInfo) => {
      let percent = 0;
      if (subInfo.allSkusCompleted === true) {
        percent = 100;
      } else {
        percent = Math.ceil(
          (subInfo.totalPresaleSkuUpdateSuccessful /
            subInfo.totalPresaleSkusToUpdate) *
            100
        );
      }
      presaleSkusInfo.push({
        percentage: percent,
        name: subInfo.id,
        submissionDate: moment(subInfo.submissionDate.toDate()).format(
          'MM/DD/YYYY'
        ),
        dataType: subInfo.metadataType,
        successfulSkus: subInfo.totalPresaleSkuUpdateSuccessful,
        totalSkus: subInfo.totalPresaleSkusToUpdate,
      });
    });
    return presaleSkusInfo;
  };

  return (
    <div>
      <PageHeader
        title="Updates"
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Browse"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
      />
      {isLoading && <LinearProgress color="secondary" />}
      <ContentWrapper>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Tabs
              value={activeTab}
              indicatorColor="secondary"
              textColor="secondary"
              onChange={(e, newTab) => setActiveTab(newTab)}
            >
              <Tab label="Templates" value="TEMPLATE" />
              <Tab label="Uploads" value="UPLOADS" />
            </Tabs>
          </Grid>
          {activeTab === 'TEMPLATE' && (
            <Grid item sm={12}>
              <TemplateActionBar
                statusId={statusId}
                changeStatusId={setStatusId}
                supplier={supplier}
                changeSupplier={setSupplier}
                suppliers={suppliers}
                metadataType={metadataType}
                changeMetadataType={setMetadataType}
                isLoading={isLoading}
                requestTemplate={() =>
                  dispatch(
                    requestTemplate(
                      userInfo.uid,
                      `${userInfo.name} ${userInfo.surname}`,
                      supplier,
                      getSupplierName(),
                      statusId,
                      metadataType
                    )
                  )
                }
              />
              <RequestedTemplates updateTemplates={updateTemplates} />
            </Grid>
          )}
          {activeTab === 'UPLOADS' && (
            <Grid item sm={12}>
              <Grid
                container
                spacing={4}
                alignItems="center"
                style={{
                  backgroundColor: grey[800],
                  borderRadius: 8,
                  marginTop: theme.spacing(2),
                  marginBottom: theme.spacing(4),
                }}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={() => uploadUpdateSubmission()}
                    disabled={documents.length === 0}
                  >
                    Upload Submission
                  </Button>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item style={{ flex: 1 }}>
                  <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    {fileInfo.length > 0 ? (
                      <p>{fileInfo}</p>
                    ) : (
                      <p>
                        Drag &rsquo;n&lsquo; drop submission, or click to select
                        submission
                      </p>
                    )}
                  </div>
                </Grid>
              </Grid>
              <div className={classes.separator} />
              <Submissions submissionInfo={formatSubmissions()} />
            </Grid>
          )}
        </Grid>
      </ContentWrapper>
    </div>
  );
};
export default UpdatesBrowse;
