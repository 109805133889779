import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import SelectedSelector from './components/SelectedSelector';
import SupplierSelector from './components/SupplierSelector';

const useStyles = makeStyles((theme) => ({
  results: {
    margin: theme.spacing(1),
    color: grey[800],
  },
  separator: {
    width: theme.spacing(1),
  },
  wrapperButtons: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  wrapperRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
}));

const FiltersBar = ({
  searchText,
  changeSearchText,
  productSelectedCount,
  supplierFilter,
  changeSupplierFilter,
  supplierOptions,
  selectedFilter,
  changeSelectedFilter,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapperRoot}>
      <TextField
        placeholder="Search"
        value={searchText}
        onChange={(e) => changeSearchText(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <Typography className={classes.results} variant="body1" component="span">
        {`${productSelectedCount} ${
          productSelectedCount === 1 ? 'product' : 'products'
        } selected`}
      </Typography>
      <div className={classes.wrapperButtons}>
        <div className={classes.separator} />
        <SupplierSelector
          supplierFilter={supplierFilter}
          changeSupplierFilter={changeSupplierFilter}
          supplierOptions={supplierOptions}
        />
        <div className={classes.separator} />
        <SelectedSelector
          selectedFilter={selectedFilter}
          changeSelectedFilter={changeSelectedFilter}
        />
      </div>
    </div>
  );
};

FiltersBar.defaultProps = {
  searchText: '',
  changeSearchText: (newValue) => `Search text was changed to '${newValue}'`,
  productSelectedCount: 1,
  supplierFilter: 'ALL',
  changeSupplierFilter: (newFilter) =>
    console.log('User changes the supplier filter to:', newFilter),
  supplierOptions: [],
  selectedFilter: 'ALL',
  changeSelectedFilter: (newFilter) =>
    console.log('User changes the selected filter to:', newFilter),
};

FiltersBar.propTypes = {
  searchText: PropTypes.string,
  changeSearchText: PropTypes.func,
  productSelectedCount: PropTypes.number,
  supplierFilter: PropTypes.string,
  changeSupplierFilter: PropTypes.func,
  supplierOptions: PropTypes.arrayOf(PropTypes.string),
  selectedFilter: PropTypes.string,
  changeSelectedFilter: PropTypes.func,
};

export default FiltersBar;
