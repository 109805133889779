import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import MetadataSelector from './components/MetadataSelector';
import StatusSelector from './components/StatusSelector';
import SupplierSelector from './components/SupplierSelector';

const useStyles = makeStyles((theme) => ({
  seperator: {
    width: theme.spacing(2),
  },
  wrapper: {
    backgroundColor: grey[800],
    borderRadius: 8,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
}));

const TemplateActionBar = ({
  metadataType,
  changeMetadataType,
  statusId,
  changeStatusId,
  supplier,
  changeSupplier,
  suppliers,
  isLoading,
  requestTemplate,
}) => {
  const classes = useStyles();

  return (
    <Grid container spacing={4} alignItems="center" className={classes.wrapper}>
      <Grid item>
        <Button
          size="large"
          variant="contained"
          disabled={isLoading}
          onClick={requestTemplate}
        >
          Request Template
        </Button>
      </Grid>
      <Divider orientation="vertical" flexItem />
      <Grid item>
        <Grid container direction="row">
          <MetadataSelector
            metadataType={metadataType}
            changeMetadataType={changeMetadataType}
          />
          <div className={classes.seperator} />
          <SupplierSelector
            supplier={supplier}
            changeSupplier={changeSupplier}
            supplierOptions={suppliers}
          />
          <div className={classes.seperator} />
          <StatusSelector
            statusFilter={statusId}
            changeStatusFilter={changeStatusId}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

TemplateActionBar.defaultProps = {
  metadataType: 'ALL',
  changeMetadataType: (newMetadataType) =>
    console.log(`Metadata type was changed to: ${newMetadataType}`),
  statusId: 'ALL',
  changeStatusId: (newStatus) =>
    console.log(`Status was changed to: ${newStatus}`),
  supplier: 'ALL',
  changeSupplier: (newSupplier) =>
    console.log(`Supplier was changed to: ${newSupplier}`),
  suppliers: [],
  isLoading: false,
  requestTemplate: () => console.log('New orders template requested.'),
};

TemplateActionBar.propTypes = {
  supplier: PropTypes.string,
  changeSupplier: PropTypes.func,
  metadataType: PropTypes.string,
  changeMetadataType: PropTypes.func,
  statusId: PropTypes.string,
  changeStatusId: PropTypes.func,
  suppliers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  isLoading: PropTypes.bool,
  requestTemplate: PropTypes.func,
};

export default TemplateActionBar;
