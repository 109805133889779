import _ from 'lodash';
import { getCurrentDate } from '../../../utils/styles';

export const getFilteredSkus = (skus, searchText, supplierId) => {
  return (skus || [])
    .filter((skuInfo) => {
      if (supplierId === 'N/A') {
        return true;
      }

      return skuInfo.supplierId === supplierId;
    })
    .filter((skuInfo) => {
      if (searchText !== '') {
        const caseInsensitiveSearch = _.toLower(searchText);
        const caseInsensitiveEolSku = _.toLower(skuInfo.eolSku);
        const caseInsensitiveSupplierDescription = _.toLower(
          skuInfo.supplierDescription
        );

        if (caseInsensitiveEolSku.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (
          caseInsensitiveSupplierDescription.includes(caseInsensitiveSearch)
        ) {
          return true;
        }

        return false;
      }

      return true;
    });
};

export const checkFields = (fields, expectedFields) => {
  let isCorrect = true;
  fields.forEach((field, index) => {
    if (field !== expectedFields[index]) {
      isCorrect = false;
    }
  });
  return isCorrect;
};

export const checkIfSelected = (sku, selectedPresalesSkus) => {
  const relevantSku = selectedPresalesSkus.find(
    (selectedSkuInfo) => selectedSkuInfo.eolSku === sku
  );

  if (relevantSku) {
    return true;
  }

  return false;
};

export const updateCompletionStatus = (filteredSkus, updateSku, dispatch) => {
  filteredSkus.forEach((info) => {
    dispatch(
      updateSku(info.eolSku, {
        onboardingStatuses: {
          measurementsComplete: !!(
            info.measurements.height &&
            info.measurements.length &&
            info.measurements.width &&
            info.measurements.weight
          ),
          imagesComplete: !!info.primaryImageLink,
          barcodesComplete: !!info.barcode,
          categoriesComplete: !!(
            info.categories.department &&
            info.categories.division &&
            info.categories.lowestCategory &&
            info.categories.mainCategory
          ),
          copyComplete: !!(
            info.copy.description &&
            info.copy.title &&
            info.copy.whatsInTheBox
          ),
        },
      })
    );
  });
};
export const updateSubmission = (selectedPresalesSkus, updateSku, dispatch) => {
  selectedPresalesSkus.forEach((info) => {
    dispatch(
      updateSku(info.eolSku, {
        submissionDate: getCurrentDate('/'),
        status: 'SUBMITTED',
      })
    );
  });
};

export const getCompletedSkus = (filteredSkus) => {
  return filteredSkus.filter(
    (sku) =>
      sku.onboardingStatuses.measurementsComplete &&
      sku.onboardingStatuses.barcodesComplete &&
      sku.onboardingStatuses.categoriesComplete &&
      sku.onboardingStatuses.imagesComplete &&
      sku.onboardingStatuses.copyComplete
  );
};

export const getIncompletedSkus = (filteredSkus) => {
  return filteredSkus.filter(
    (sku) =>
      !sku.onboardingStatuses.measurementsComplete ||
      !sku.onboardingStatuses.barcodesComplete ||
      !sku.onboardingStatuses.categoriesComplete ||
      !sku.onboardingStatuses.imagesComplete ||
      !sku.onboardingStatuses.copyComplete
  );
};

export const findMissingInfo = (sku) => {
  return {
    barcode: sku.barcode === null,
    primaryImageLink: sku.primaryImageLink === null,
    height: sku.measurements.height === null,
    length: sku.measurements.length === null,
    width: sku.measurements.width === null,
    weight: sku.measurements.weight === null,
    department: sku.categories.department === null,
    division: sku.categories.division === null,
    lowestCategory: sku.categories.lowestCategory === null,
    mainCategory: sku.categories.mainCategory === null,
    description: sku.copy.description === null,
    title: sku.copy.title == null,
    whatsInTheBox: sku.copy.whatsInTheBox === null,
  };
};

export const getFormattedSkus = (filteredSkus, selectedPresalesSkus) => {
  const filt = filteredSkus.map((sku, index) => ({
    skuId: sku.id,
    supplierSku: sku.supplierSku,
    supplierId: sku.supplierId,
    eolSku: sku.eolSku,
    supplierDescription: sku.supplierDescription,
    barcode: sku.barcode,
    primaryImageLink: sku.primaryImageLink,
    status: sku.status,
    categories: {
      department: sku.categories.department,
      division: sku.categories.division,
      lowestCategory: sku.categories.lowestCategory,
      mainCategory: sku.categories.mainCategory,
    },
    copy: {
      description: sku.copy.description,
      title: sku.copy.title,
      subtitle: sku.copy.subtitle,
      whatsInTheBox: sku.copy.whatsInTheBox,
    },
    measurements: {
      height: sku.measurements.height,
      length: sku.measurements.length,
      width: sku.measurements.width,
      weight: sku.measurements.weight,
    },

    tableData: {
      id: index,
      checked: checkIfSelected(sku.eolSku, selectedPresalesSkus),
    },
  }));
  return filt;
};

export const handleError = (err) => {
  console.log(err);
};

export const createSkuCSV = (selectedPresalesSkus) => {
  const csvData = [
    [
      'Loadsheet',
      'Date',
      'Product or Variant',
      'Your own SKU',
      'Main Category',
      'Lowest Category',
      'Barcode',
      'Product Title',
      'Product Subtitle',
      'Key Selling Features',
      "What's in the Box",
      'Brand',
      'Primary Image URL',
      'Warranty Type',
      'Warranty Period',
      'Packaged Width (cm)',
      'Packaged Length (cm)',
      'Packaged Height (cm)',
      'Packaged Weight (g)',
      'Model',
    ],
  ];

  selectedPresalesSkus.forEach((skuInformation) => {
    csvData.push([
      skuInformation.categories.department,
      getCurrentDate('/'),
      'Product without Variants',
      skuInformation.eolSku,
      skuInformation.categories.mainCategory,
      skuInformation.categories.lowestCategory,
      skuInformation.barcode,
      skuInformation.copy.title,
      skuInformation.copy.subtitle,
      skuInformation.copy.description,
      skuInformation.copy.whatsInTheBox,
      skuInformation.brand,
      skuInformation.primaryImageLink,
      'Limited (1)',
      '6',
      skuInformation.measurements.width,
      skuInformation.measurements.length,
      skuInformation.measurements.height,
      skuInformation.measurements.weight,
      skuInformation.supplierSku,
    ]);
  });
  return csvData;
};

export const getLoadingValue = (
  updateLoadingCountTotal,
  updateLoadingCountSuccess,
  updateLoadingCountFailed
) => {
  if (updateLoadingCountTotal === 0) {
    return -1;
  }
  return Math.round(
    ((updateLoadingCountSuccess + updateLoadingCountFailed) /
      updateLoadingCountTotal) *
      100
  );
};
