import { useState } from 'react';
import Button from '@material-ui/core/Button';
import FolderIcon from '@material-ui/icons/Folder';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';

const StatusSelector = ({ statusFilter, changeStatusFilter, statuses }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  const getStatusLabel = (id) => {
    if (id === 'ALL') {
      return 'All Statuses';
    }
    const statusInfo = statuses.find((info) => info.id === id);
    if (!statusInfo) {
      return 'Unknown';
    }
    return statusInfo.label;
  };
  return (
    <>
      <Button
        aria-controls="status-menu"
        aria-haspopup="true"
        size="large"
        startIcon={<FolderIcon />}
        onClick={openMenu}
      >
        {getStatusLabel(statusFilter)}
      </Button>
      <Menu
        id="status-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem
          onClick={() => {
            changeStatusFilter('ALL');
            closeMenu();
          }}
        >
          All Statuses
        </MenuItem>
        {statuses
          .map((info) => info)
          .map((info) => (
            <MenuItem
              key={info.id}
              onClick={() => {
                changeStatusFilter(info.id);
                closeMenu();
              }}
            >
              {info.label}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};
StatusSelector.defaultProps = {
  statusFilter: 'ALL',
  changeStatusFilter: (newValue) => `Status was changed to '${newValue}'`,
  statuses: [
    {
      id: 'NEW_LEAD_TIME_ORDER',
      label: 'New Lead Time Order',
    },
    {
      id: 'PREPARING_FOR_CUSTOMER',
      label: 'Preparing for Customer',
    },
    {
      id: 'SHIPPED_TO_CUSTOMER',
      label: 'Shipped to Customer',
    },
    {
      id: 'INTER_DC_TRANSFER',
      label: 'Inter DC Transfer',
    },
    {
      id: 'DRAFT_SHIPMENT',
      label: 'Draft Shipment',
    },
    {
      id: 'CONFIRMED_SHIPMENT',
      label: 'Confirmed Shipment',
    },
    {
      id: 'SHIPPED_SHIPMENT',
      label: 'Shipped Shipment',
    },
    {
      id: 'CHECKED_IN_SHIPMENT',
      label: 'Checked In Shipment',
    },
    {
      id: 'RECEIVED_SHIPMENT',
      label: 'Received Shipment',
    },
    {
      id: 'CANCELLED_BY_CUSTOMER',
      label: 'Cancelled by Customer',
    },
    {
      id: 'CANCELLED_BY_SELLER',
      label: 'Cancelled by Seller',
    },
    {
      id: 'CANCELLED_BY_TAKEALOT',
      label: 'Cancelled by Takealot',
    },
    {
      id: 'CANCELLED_LATE_DELIVERY',
      label: 'Cancelled - Late Delivery',
    },
    {
      id: 'CANCELLED_INBOUND_EXCEPTION',
      label: 'Cancelled - Inbound Exception',
    },
    {
      id: 'RETURNED',
      label: 'Returned',
    },
    {
      id: 'NEW_DROP_SHIP_ORDER',
      label: 'New Drop Ship Order',
    },
    {
      id: 'IN_PROGRESS_DROP_SHIP_ORDER',
      label: 'In Progress Drop Ship Order',
    },
    {
      id: 'READY_TO_COLLECT_DROP_SHIP_ORDER',
      label: 'Ready To Collect Drop Ship Order',
    },
    {
      id: 'COLLECTED_DROP_SHIP_ORDER',
      label: 'Collected Drop Ship Order',
    },
  ],
};

StatusSelector.propTypes = {
  statusFilter: PropTypes.oneOf([
    'ALL',
    'NEW_LEAD_TIME_ORDER',
    'PREPARING_FOR_CUSTOMER',
    'SHIPPED_TO_CUSTOMER',
    'INTER_DC_TRANSFER',
    'DRAFT_SHIPMENT',
    'CONFIRMED_SHIPMENT',
    'SHIPPED_SHIPMENT',
    'CHECKED_IN_SHIPMENT',
    'RECEIVED_SHIPMENT',
    'CANCELLED_BY_CUSTOMER',
    'CANCELLED_BY_SELLER',
    'CANCELLED_BY_TAKEALOT',
    'CANCELLED_LATE_DELIVERY',
    'CANCELLED_INBOUND_EXCEPTION',
    'RETURNED',
    'NEW_DROP_SHIP_ORDER',
    'IN_PROGRESS_DROP_SHIP_ORDER',
    'READY_TO_COLLECT_DROP_SHIP_ORDER',
    'COLLECTED_DROP_SHIP_ORDER',
  ]),
  changeStatusFilter: PropTypes.func,
  statuses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};
export default StatusSelector;
