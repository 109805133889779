import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';
import {
  fetchClients,
  fetchClientSkus,
  initiateStateReset,
  queryAdd,
  fetchUsers,
} from './slice';
import { getFilteredSkus, getFilteredSelected } from './logic';
import ContentWrapper from '../../../components/ContentWrapper';
import FiltersBar from './components/FiltersBar';
import ListingInfo from './components/ListingInfo';
import MiscellaneousInfo from './components/MiscellaneousInfo';
import PageHeader from '../../../components/PageHeader';
import PriceChangeInfo from './components/PriceChangeInfo';
import ProductSelectTable from './components/ProductSelectTable';
import ReceivedProductMismatch from './components/ReceivedProductMismatch';

const useStyles = makeStyles((theme) => ({
  wrapperWizard: {
    width: '100%',
    padding: theme.spacing(4),
  },
  actionsBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  wrapperStep: {
    padding: `${theme.spacing(4)}px 0px`,
  },
  formControl: {
    width: '100%',
  },
  formWrapper: {
    padding: `${theme.spacing(4)}px ${theme.spacing(1)}px`,
  },
  separator: {
    height: theme.spacing(2),
  },
  alert: {
    marginBottom: theme.spacing(3),
  },
}));

const breadcrumbs = [
  {
    label: 'Customer Relationship Management',
    link: '/crm',
  },
  {
    label: 'Queries',
    link: '/crm/queries',
  },
];

const ClientsQueryAdd = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedClient, setSelectedClient] = useState({
    id: 'NONE_SELECTED',
    name: 'None selected',
  });
  const [selectedSkus, setSelectedSkus] = useState([]);
  const [queryType, setQueryType] = useState('NONE_SELECTED');
  const [note, setNote] = useState('');
  const [filteredSkus, setFilteredSkus] = useState([]);
  const [fieldUpdate, setFieldUpdate] = useState('NONE_SELECTED');
  const [priceType, setPriceType] = useState('');
  const [vatOption, setVatOption] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [validation, setValidation] = useState({
    isErrorShowing: false,
    message: '',
    errorStep: 0,
  });
  const [skuSearchText, setSkuSearchText] = useState('');
  const [requirementsSearch, setRequirementsSearch] = useState('');
  const [assignee, setAssignee] = useState({
    id: 'NONE_SELECTED',
    name: 'None selected',
  });

  const clients = useSelector((state) => state.queryAdd.clients);
  const skusLoadingStatus = useSelector(
    (state) => state.queryAdd.loadingStatus.skus
  );
  const clientsLoadingStatus = useSelector(
    (state) => state.queryAdd.loadingStatus.clients
  );
  const queryAddLoadingStatus = useSelector(
    (state) => state.queryAdd.loadingStatus.saveQuery
  );
  const skus = useSelector((state) => state.queryAdd.skus);
  const isUserRedirectedToBrowse = useSelector(
    (state) => state.queryAdd.isUserRedirectedToBrowse
  );
  const userInfo = useSelector((state) => state.account.userInfo);

  const userList = useSelector((state) => state.queryAdd.users);

  const usersLoadingStatus = useSelector(
    (state) => state.queryAdd.loadingStatus.users
  );

  useEffect(() => {
    dispatch(fetchClients());
    dispatch(fetchUsers());
  }, []);

  useEffect(() => {
    setFilteredSkus(getFilteredSkus(skus, skuSearchText));
  }, [skus, skuSearchText]);

  useEffect(() => {
    if (
      clientsLoadingStatus === 'PENDING' ||
      skusLoadingStatus === 'PENDING' ||
      queryAddLoadingStatus === 'PENDING' ||
      usersLoadingStatus === 'PENDING'
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [
    clientsLoadingStatus,
    skusLoadingStatus,
    queryAddLoadingStatus,
    usersLoadingStatus,
  ]);

  useEffect(() => {
    if (isUserRedirectedToBrowse) {
      dispatch(initiateStateReset());
      setIsLoading(true);
      history.goBack();
    }
  }, [isUserRedirectedToBrowse]);

  useEffect(() => {
    if (activeStep === 2) {
      setFilteredSkus(getFilteredSelected(selectedSkus, requirementsSearch));
    }
  }, [selectedSkus, requirementsSearch]);

  const checkIfSelected = (sku) => {
    const relevantSku = selectedSkus.find(
      (selectedSkuInfo) => selectedSkuInfo.sku === sku
    );

    if (relevantSku) {
      return true;
    }

    return false;
  };

  const getFormattedSkus = () => {
    return filteredSkus.map((skuInfo, index) => ({
      sku: skuInfo.sku,
      childAsin: skuInfo.amazon.childAsin,
      tsin: skuInfo.takealot.tsin,
      description:
        skuInfo.variation === 'N/A'
          ? skuInfo.productName
          : `${skuInfo.productName} (${skuInfo.variation})`,
      brand: skuInfo.brand,
      tableData: {
        id: index,
        checked: checkIfSelected(skuInfo.sku),
      },
    }));
  };

  const validateMismatchType = () => {
    let error = false;
    selectedSkus.forEach((value) => {
      if (value.mismatchType === undefined) {
        setValidation({
          isErrorShowing: true,
          message: 'Please select the mismatched property.',
          errorStep: 2,
        });
        error = true;
      }
      if (value.mismatchType === 'OTHER') {
        if (value.changeTo === undefined) {
          setValidation({
            isErrorShowing: true,
            message: 'Please describe the mismatch with type other.',
            errorStep: 2,
          });
          error = true;
        }
      }
    });
    if (!error) {
      setValidation({
        isErrorShowing: false,
        message: '',
        errorStep: 2,
      });
    }
    return error;
  };

  const onNextClick = () => {
    switch (activeStep) {
      case 0:
        if (selectedClient.id === 'NONE_SELECTED') {
          setValidation({
            isErrorShowing: true,
            message: 'Please select a client.',
            errorStep: 0,
          });
        } else if (queryType === 'NONE_SELECTED') {
          setValidation({
            isErrorShowing: true,
            message: 'Please select the query type.',
            errorStep: 0,
          });
        } else if (
          assignee.id === 'NONE_SELECTED' ||
          assignee.name === 'Please select'
        ) {
          setValidation({
            isErrorShowing: true,
            message: 'Please assign a person.',
            errorStep: 0,
          });
        } else {
          setValidation({
            isErrorShowing: false,
            message: '',
            errorStep: 0,
          });
          dispatch(fetchClientSkus(selectedClient.id));
          setActiveStep(1);
        }
        break;
      case 1:
        if (selectedSkus.length === 0) {
          setValidation({
            isErrorShowing: true,
            message: 'Please select at least one sku.',
            errorStep: 1,
          });
        } else {
          setValidation({
            isErrorShowing: false,
            message: '',
            errorStep: 1,
          });

          setFilteredSkus(
            getFilteredSelected(selectedSkus, requirementsSearch)
          );
          setActiveStep(2);
        }
        break;
      case 2:
        switch (queryType) {
          case 'MISCELLANEOUS':
            dispatch(
              queryAdd({
                clientId: selectedClient.id,
                clientName: selectedClient.name,
                requestDate: new Date(),
                completionDate: null,
                creatorId: userInfo.uid,
                creatorName: [userInfo.name, userInfo.surname].join(' '),
                assigneeId: assignee.id,
                assigneeName: assignee.name,
                requestType: queryType,
                status: 'NOT_STARTED',
                notes: note,
                skus: selectedSkus.reduce((finalSkus, currentSku) => {
                  return {
                    ...finalSkus,
                    [currentSku.sku]: {
                      sku: currentSku.sku,
                      product: currentSku.product,
                      changeTo: currentSku.changeTo || '',
                      tsin: currentSku.tsin,
                    },
                  };
                }, {}),
              })
            );
            break;
          case 'PRICE_CHANGE':
            if (priceType === '') {
              setValidation({
                isErrorShowing: true,
                message: 'Please select the price.',
                errorStep: 2,
              });
            } else {
              dispatch(
                queryAdd({
                  clientId: selectedClient.id,
                  clientName: selectedClient.name,
                  requestDate: new Date(),
                  completionDate: null,
                  creatorId: userInfo.uid,
                  creatorName: [userInfo.name, userInfo.surname].join(' '),
                  assigneeId: assignee.id,
                  assigneeName: assignee.name,
                  requestType: queryType,
                  status: 'NOT_STARTED',
                  notes: note,
                  priceToChange: priceType,
                  vatIncluded: vatOption,
                  startDate:
                    startDate === null || startDate.length === 0
                      ? null
                      : startDate,
                  endDate:
                    endDate === null || endDate.length === 0 ? null : endDate,
                  skus: selectedSkus.reduce((finalSkus, currentSku) => {
                    return {
                      ...finalSkus,
                      [currentSku.sku]: {
                        sku: currentSku.sku,
                        product: currentSku.product,
                        changeTo: currentSku.changeTo || '',
                        tsin: currentSku.tsin,
                      },
                    };
                  }, {}),
                })
              );
            }
            break;
          case 'LISTING_CHANGE':
            if (fieldUpdate === 'NONE_SELECTED') {
              setValidation({
                isErrorShowing: true,
                message: 'Please select the field that needs to be updated.',
                errorStep: 2,
              });
            } else {
              setValidation({
                isErrorShowing: false,
                message: '',
                errorStep: 2,
              });
              dispatch(
                queryAdd({
                  clientId: selectedClient.id,
                  clientName: selectedClient.name,
                  requestDate: new Date(),
                  completionDate: null,
                  creatorId: userInfo.uid,
                  creatorName: [userInfo.name, userInfo.surname].join(' '),
                  assigneeId: assignee.id,
                  assigneeName: assignee.name,
                  requestType: queryType,
                  status: 'NOT_STARTED',
                  notes: note,
                  skus: selectedSkus.reduce((finalSkus, currentSku) => {
                    return {
                      ...finalSkus,
                      [currentSku.sku]: {
                        sku: currentSku.sku,
                        product: currentSku.product,
                        changeTo: currentSku.changeTo || '',
                        tsin: currentSku.tsin,
                      },
                    };
                  }, {}),
                })
              );
            }
            break;
          case 'PRODUCT_MISMATCH':
            if (!validateMismatchType()) {
              dispatch(
                queryAdd({
                  clientId: selectedClient.id,
                  clientName: selectedClient.name,
                  requestDate: new Date(),
                  completionDate: null,
                  creatorId: userInfo.uid,
                  creatorName: [userInfo.name, userInfo.surname].join(' '),
                  assigneeId: assignee.id,
                  assigneeName: assignee.name,
                  requestType: queryType,
                  status: 'NOT_STARTED',
                  notes: note,
                  skus: selectedSkus.reduce((finalSkus, currentSku) => {
                    return {
                      ...finalSkus,
                      [currentSku.sku]: {
                        sku: currentSku.sku,
                        product: currentSku.product,
                        changeTo: currentSku.changeTo || '',
                        mismatchType: currentSku.mismatchType,
                      },
                    };
                  }, {}),
                })
              );
            }
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  };

  const getRequirementsContent = () => {
    switch (queryType) {
      case 'MISCELLANEOUS':
        return (
          <MiscellaneousInfo
            note={note}
            setNote={setNote}
            skus={filteredSkus}
            updateSkus={setSelectedSkus}
            searchText={requirementsSearch}
            setSearchText={setRequirementsSearch}
          />
        );
      case 'PRICE_CHANGE':
        return (
          <PriceChangeInfo
            note={note}
            setNote={setNote}
            skus={filteredSkus}
            updateSkus={setSelectedSkus}
            priceType={priceType}
            setPriceType={setPriceType}
            vatInc={vatOption}
            setVatInc={setVatOption}
            endDate={endDate}
            setEndDate={setEndDate}
            startDate={startDate}
            setStartDate={setStartDate}
            searchText={requirementsSearch}
            setSearchText={setRequirementsSearch}
          />
        );
      case 'LISTING_CHANGE':
        return (
          <ListingInfo
            note={note}
            setNote={setNote}
            skus={filteredSkus}
            updateSkus={setSelectedSkus}
            updateField={fieldUpdate}
            setUpdateField={setFieldUpdate}
            searchText={requirementsSearch}
            setSearchText={setRequirementsSearch}
          />
        );
      case 'PRODUCT_MISMATCH':
        return (
          <ReceivedProductMismatch
            note={note}
            setNote={setNote}
            skus={selectedSkus}
            updateSkus={setSelectedSkus}
          />
        );
      default:
        return <div />;
    }
  };

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <div className={classes.wrapperStep}>
            <Typography variant="h4" gutterBottom>
              Enter query info
            </Typography>
            <Typography variant="body1">
              Please complete all fields below.
            </Typography>
            <form className={classes.formWrapper}>
              <FormControl className={classes.formControl} required>
                <InputLabel shrink id="client-select-label">
                  Client
                </InputLabel>
                <Select
                  labelId="client-select-label"
                  id="client-select"
                  value={selectedClient.id}
                  onChange={(e) => {
                    setSelectedClient({
                      id: e.target.value,
                      name: e.nativeEvent.target.innerText,
                    });
                  }}
                >
                  {[
                    {
                      id: 'NONE_SELECTED',
                      name: 'Please select',
                      status: 'HIGH_PRIORITY',
                    },
                    ...clients,
                  ]
                    .filter(
                      (clientInfo) => clientInfo.status === 'HIGH_PRIORITY'
                    )
                    .sort((a, b) => {
                      if (a.id === 'NONE_SELECTED') return -1;
                      if (b.id === 'NONE_SELECTED') return +1;
                      if (a.name > b.name) return +1;
                      if (a.name < b.name) return -1;
                      return 0;
                    })
                    .map((clientInfo) => (
                      <MenuItem
                        key={clientInfo.id}
                        value={clientInfo.id}
                        name={clientInfo.name}
                      >
                        {clientInfo.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <div className={classes.separator} />
              <FormControl className={classes.formControl} required>
                <InputLabel shrink id="type-select-label">
                  Type
                </InputLabel>
                <Select
                  labelId="type-select-label"
                  id="type-select"
                  value={queryType}
                  onChange={(e) => setQueryType(e.target.value)}
                >
                  <MenuItem value="NONE_SELECTED">Please select</MenuItem>
                  <MenuItem value="MISCELLANEOUS">Miscellaneous</MenuItem>
                  <MenuItem value="PRICE_CHANGE">Price Change</MenuItem>
                  <MenuItem value="LISTING_CHANGE">Listing Edit</MenuItem>
                  <MenuItem value="PRODUCT_MISMATCH">
                    Received Product Mismatch
                  </MenuItem>
                </Select>
              </FormControl>
              <div className={classes.separator} />
              <FormControl className={classes.formControl} required>
                <InputLabel shrink id="assignee-select-label">
                  Assign To
                </InputLabel>
                <Select
                  labelId="assignee-select-label"
                  id="assignee-select"
                  value={assignee.id}
                  onChange={(e) =>
                    setAssignee({
                      id: e.target.value,
                      name: e.nativeEvent.target.innerText,
                    })
                  }
                >
                  <MenuItem value="NONE_SELECTED">Please select</MenuItem>
                  {[...userList]
                    .sort((a, b) => {
                      if (a.name > b.name) return +1;
                      if (a.name < b.name) return -1;
                      return 0;
                    })
                    .map((info) => {
                      return (
                        <MenuItem value={info.id} key={info.id}>
                          {`${info.name} ${info.surname}`}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </form>
            {validation.isErrorShowing && validation.errorStep === 0 && (
              <Alert className={classes.alert} severity="error">
                {validation.message}
              </Alert>
            )}
          </div>
        );
      case 1:
        return (
          <div className={classes.wrapperStep}>
            <Typography variant="h4" gutterBottom>
              Select SKUs
            </Typography>
            <Typography variant="body1">
              Please select all SKUs that should be associated with this query.
            </Typography>
            <div className={classes.separator} />
            <FiltersBar
              searchText={skuSearchText}
              changeSearchText={setSkuSearchText}
              skuCount={filteredSkus.length}
            />
            <ProductSelectTable
              isLoading={isLoading}
              skus={getFormattedSkus()}
              updateSelectedProducts={setSelectedSkus}
            />
            {validation.isErrorShowing && validation.errorStep === 1 && (
              <Alert className={classes.alert} severity="error">
                {validation.message}
              </Alert>
            )}
          </div>
        );
      case 2:
        return (
          <div className={classes.wrapperStep}>
            {getRequirementsContent()}
            <div className={classes.separator} />
            {validation.isErrorShowing && validation.errorStep === 2 && (
              <Alert className={classes.alert} severity="error">
                {validation.message}
              </Alert>
            )}
          </div>
        );
      default:
        console.log('Unknown Step');
        return <div />;
    }
  };

  return (
    <div>
      <PageHeader
        isLoading={isLoading}
        title="Add New Query"
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Add"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
      />
      <ContentWrapper>
        <Paper className={classes.wrapperWizard}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Stepper activeStep={activeStep} orientation="vertical">
                <Step>
                  <StepLabel>Query Info</StepLabel>
                </Step>
                <Step>
                  <StepLabel>SKU Selection</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Requirements</StepLabel>
                </Step>
              </Stepper>
            </Grid>
            <Grid item xs={9}>
              {getStepContent()}
              <div className={classes.actionsBar}>
                <Button
                  disabled={activeStep === 0 || isLoading}
                  onClick={() => {
                    setFilteredSkus(getFilteredSkus(skus, skuSearchText));
                    setActiveStep(activeStep - 1);
                  }}
                >
                  Previous
                </Button>
                <Button
                  disabled={isLoading}
                  variant="contained"
                  color="secondary"
                  onClick={onNextClick}
                >
                  {activeStep !== 2 ? 'Next' : 'Create'}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </ContentWrapper>
    </div>
  );
};

export default ClientsQueryAdd;
