/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  deleteDocument,
  getClient,
  getDocuments,
  updateClientStatus,
} from '../../../api/clientsAPI';

const initialState = {
  clientInfo: {
    id: 'Loading',
    name: '',
    billingInfo: {
      legalEntityName: '',
      physicalAddress: '',
      emailAddress: '',
      telephoneNumber: '',
      mainContact: '',
      vatNumber: '',
    },
    leadTime: -1,
  },
  documents: [],
  loadingStatus: {
    clientInfo: 'NOT_STARTED',
    clientStatus: 'NOT_STARTED',
    documents: 'NOT_STARTED',
    deleteDocument: 'NOT_STARTED',
  },
  feedback: {
    isOpen: false,
    type: undefined,
    message: '',
  },
};

const clientsViewSlice = createSlice({
  name: 'clientsView',
  initialState,
  reducers: {
    fetchClientInfoStarted(state) {
      state.loadingStatus.clientInfo = 'PENDING';
    },
    fetchClientInfoSuccess(state, action) {
      state.clientInfo = action.payload;
      state.loadingStatus.clientInfo = 'COMPLETE';
    },
    fetchClientInfoFailed(state, action) {
      state.loadingStatus.clientInfo = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    updateClientStatusStarted(state) {
      state.loadingStatus.clientStatus = 'PENDING';
    },
    updateClientStatusSuccess(state, action) {
      state.loadingStatus.clientStatus = 'COMPLETE';
      state.clientInfo.status = action.payload;
    },
    updateClientStatusFailed(state, action) {
      state.loadingStatus.clientStatus = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getClientDocumentsStarted(state) {
      state.loadingStatus.documents = 'PENDING';
    },
    getClientDocumentsSuccess(state, action) {
      state.documents = action.payload;
      state.loadingStatus.documents = 'COMPLETE';
    },
    getClientDocumentsFailed(state, action) {
      state.loadingStatus.documents = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    deleteClientDocumentStarted(state) {
      state.loadingStatus.deleteDocument = 'PENDING';
    },
    deleteClientDocumentSuccess(state, action) {
      state.loadingStatus.deleteDocument = 'COMPLETE';
      state.documents = state.documents.filter(
        (documentInfo) => documentInfo.id !== action.payload
      );
    },
    deleteClientDocumentFailed(state, action) {
      state.loadingStatus.deleteDocument = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
  },
});

export const {
  fetchClientInfoStarted,
  fetchClientInfoSuccess,
  fetchClientInfoFailed,
  updateClientStatusStarted,
  updateClientStatusSuccess,
  updateClientStatusFailed,
  deleteClientDocumentSuccess,
  deleteClientDocumentStarted,
  deleteClientDocumentFailed,
  getClientDocumentsStarted,
  getClientDocumentsSuccess,
  getClientDocumentsFailed,
} = clientsViewSlice.actions;

export default clientsViewSlice.reducer;

export const fetchClientInfo = (clientId) => async (dispatch) => {
  dispatch(fetchClientInfoStarted());
  const result = await getClient(clientId);

  if (result.status === 200) {
    dispatch(fetchClientInfoSuccess(result.clientInfo));
  } else {
    dispatch(fetchClientInfoFailed(result.error));
  }
};

export const changeClientStatus = (clientId, newStatus) => async (dispatch) => {
  dispatch(updateClientStatusStarted());
  const result = await updateClientStatus(clientId, newStatus);

  if (result.status === 200) {
    dispatch(updateClientStatusSuccess(newStatus));
  } else {
    dispatch(updateClientStatusFailed(result.error));
  }
};

export const fetchClientDocuments = (clientId) => async (dispatch) => {
  dispatch(getClientDocumentsStarted());
  const result = await getDocuments(clientId);

  if (result.status === 200) {
    dispatch(getClientDocumentsSuccess(result.documents));
  } else {
    dispatch(getClientDocumentsFailed(result.error));
  }
};

export const deleteClientDocument =
  (clientId, documentPath, documentId) => async (dispatch) => {
    dispatch(deleteClientDocumentStarted());
    const result = await deleteDocument(clientId, documentPath, documentId);

    if (result.status === 200) {
      dispatch(deleteClientDocumentSuccess(documentId));
    } else {
      dispatch(deleteClientDocumentFailed(result.error));
    }
  };
