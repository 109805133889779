import { green, red, yellow } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import {
  deleteSupplierDocument,
  fetchDocuments,
  fetchProducts,
  fetchSupplierInfo,
} from './slice';
import BasicInfoCard from './components/BasicInfoCard';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import ContentWrapper from '../../../components/ContentWrapper';
import DocumentsTab from '../../../components/DocumentsTab';
import EmptyViewMessage from '../../../components/EmptyViewMessage';
import PageHeader from '../../../components/PageHeader';
import ProductsTab from './components/ProductsTab';

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
  icon: {
    color: theme.palette.common.black,
  },
  link: {
    color: theme.palette.common.black,
  },
  loadingWrapper: {
    padding: theme.spacing(4),
    textAlign: 'center',
  },
  pdfButtonInner: {
    display: 'flex',
    alignItems: 'center',
  },
  pdfButtonOuter: {
    color: theme.palette.common.white,
    textDecoration: 'none',
  },
  seperatorButtons: {
    height: theme.spacing(1),
  },
  seperator: {
    height: theme.spacing(3),
  },
  statusActive: {
    width: '100%',
    color: theme.palette.common.black,
    backgroundColor: green[500],
  },
  statusInactive: {
    width: '100%',
    color: theme.palette.common.black,
    backgroundColor: red[500],
  },
  statusOnboarding: {
    width: '100%',
    color: theme.palette.common.black,
    backgroundColor: yellow[500],
  },
  statusWrapper: {
    width: '100%',
    textAlign: 'center',
    padding: theme.spacing(4),
  },
  tradeOutButton: {
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
  totalsWrapper: {
    padding: theme.spacing(4),
  },
}));

const breadcrumbs = [
  {
    label: 'Sales',
    link: '/sales',
  },
  {
    label: 'Suppliers',
    link: '/sales/suppliers',
  },
  {
    label: 'Browse',
    link: '/sales/suppliers/browse',
  },
];

const SuppliersView = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('PRODUCTS');

  const supplierInfo = useSelector((state) => state.suppliersView.supplierInfo);
  const products = useSelector((state) => state.suppliersView.products);
  const documents = useSelector((state) => state.suppliersView.documents);
  const supplierInfoLoadingStatus = useSelector(
    (state) => state.suppliersView.loadingStatus.supplierInfo
  );
  const productsLoadingStatus = useSelector(
    (state) => state.suppliersView.loadingStatus.products
  );
  const documentLoadingStatus = useSelector(
    (state) => state.suppliersView.loadingStatus.documents
  );
  const [isDeleteDocumentDialogOpen, setIsDeleteDocumentDialogOpen] =
    useState(false);
  const [documentToBeDeleted, setDocumentToBeDeleted] = useState({
    documentPath: '',
    documentId: '',
  });
  const deleteDocumentLoadingStatus = useSelector(
    (state) => state.listingsView.loadingStatus.deleteDocument
  );

  useEffect(() => {
    dispatch(fetchSupplierInfo(id));
    dispatch(fetchProducts(id));
    dispatch(fetchDocuments(id));
  }, []);

  useEffect(() => {
    if (
      supplierInfoLoadingStatus === 'PENDING' ||
      productsLoadingStatus === 'PENDING' ||
      documentLoadingStatus === 'PENDING' ||
      deleteDocumentLoadingStatus === 'PENDING'
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [
    supplierInfoLoadingStatus,
    productsLoadingStatus,
    documentLoadingStatus,
    deleteDocumentLoadingStatus,
  ]);

  return (
    <div>
      <PageHeader
        title="Supplier Details"
        subtitle={isLoading ? 'Loading...' : supplierInfo.name}
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Supplier"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
      />
      {isLoading && <LinearProgress color="secondary" />}
      <ContentWrapper>
        {!isLoading && supplierInfo.sku === 'Loading' ? (
          <EmptyViewMessage
            heading="Info Failed to Load"
            message="Please check your internet connection and reload the page."
          />
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <div>
                <Button
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  startIcon={<EditIcon />}
                  onClick={() =>
                    history.push({
                      pathname: `/sales/suppliers/browse/edit/${id}`,
                      state: { selectedData: supplierInfo, type: 'edit' },
                    })
                  }
                >
                  Edit details
                </Button>
                <div className={classes.seperatorButtons} />
                <Button
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  startIcon={<NoteAddIcon />}
                  onClick={() =>
                    history.push(
                      `/sales/suppliers/browse/upload-documents/${id}`
                    )
                  }
                >
                  Upload Documents
                </Button>
              </div>
              <div className={classes.seperator} />
              <BasicInfoCard
                name={supplierInfo.name}
                websiteUrl={supplierInfo.websiteUrl}
                contactPerson={supplierInfo.contactPerson}
                contactNumber={supplierInfo.contactNumber}
                status={supplierInfo.status}
                isLoading={supplierInfoLoadingStatus === 'PENDING'}
                onMissingInfoClick={() =>
                  history.push({
                    pathname: `/sales/suppliers/browse/edit/${id}`,
                    state: { selectedData: supplierInfo, type: 'edit' },
                  })
                }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={9}>
              {!isLoading && (
                <div>
                  <AppBar position="static">
                    <Tabs
                      value={activeTab}
                      onChange={(e, newValue) => setActiveTab(newValue)}
                      aria-label="supplier tabs"
                    >
                      <Tab label="Products" value="PRODUCTS" />
                      <Tab label="Documents" value="DOCUMENTS" />
                    </Tabs>
                  </AppBar>
                  <div className={classes.seperator} />
                  {activeTab === 'PRODUCTS' && (
                    <ProductsTab products={products} />
                  )}
                  {activeTab === 'DOCUMENTS' && (
                    <DocumentsTab
                      documents={documents}
                      deleteDocument={(documentInfo) => {
                        setIsDeleteDocumentDialogOpen(true);
                        setDocumentToBeDeleted({
                          documentPath: documentInfo.reference,
                          documentId: documentInfo.id,
                        });
                      }}
                    />
                  )}
                </div>
              )}
            </Grid>
          </Grid>
        )}
      </ContentWrapper>
      <ConfirmationDialog
        isOpen={isDeleteDocumentDialogOpen}
        message="Are you sure you want to delete this document?"
        closeDialog={() => {
          setIsDeleteDocumentDialogOpen(false);
          setDocumentToBeDeleted({
            documentPath: '',
            documentId: '',
          });
        }}
        confirmAction={() =>
          dispatch(
            deleteSupplierDocument(
              id,
              documentToBeDeleted.documentPath,
              documentToBeDeleted.documentId
            )
          )
        }
      />
    </div>
  );
};

export default SuppliersView;
