import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  loadingWrapper: {
    padding: theme.spacing(8),
    textAlign: 'center',
  },
}));

const RolesCard = ({ roles, isLoading }) => {
  const classes = useStyles();

  const getRoleText = (role) => {
    switch (role) {
      case 'ADMIN':
        return 'Administrator';
      case 'LEADS_MANAGER':
        return 'Leads Manager';
      case 'LEADS_AGENT':
        return 'Leads Agent';
      case 'SALES_MANAGER':
        return 'Sales Manager';
      case 'SALES_AGENT':
        return 'Sales Agent';
      default:
        return 'Unknown';
    }
  };

  return (
    <Card>
      <CardHeader title="Roles" />
      <Divider />
      <CardContent>
        {isLoading ? (
          <div className={classes.loadingWrapper}>
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <List>
            {roles.map((role) => (
              <ListItem key={role}>
                <ListItemText primary={getRoleText(role)} />
              </ListItem>
            ))}
          </List>
        )}
      </CardContent>
    </Card>
  );
};

RolesCard.defaultProps = {
  roles: ['SALES_AGENT', 'LEADS_MANAGER'],
  isLoading: false,
};

RolesCard.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string),
  isLoading: PropTypes.bool,
};

export default RolesCard;
