import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  loadingWrapper: {
    padding: theme.spacing(8),
    textAlign: 'center',
  },
}));

const BasicInfoCard = ({
  sku,
  clientName,
  supplierName,
  brand,
  productName,
  variation,
  isLoading,
}) => {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader title="Basic Info" />
      <Divider />
      <CardContent>
        {isLoading ? (
          <div className={classes.loadingWrapper}>
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <List>
            <ListItem>
              <ListItemText primary={sku} secondary="SKU" />
            </ListItem>
            <ListItem>
              <ListItemText primary={clientName} secondary="Client" />
            </ListItem>
            <ListItem>
              <ListItemText primary={supplierName} secondary="Supplier" />
            </ListItem>
            <ListItem>
              <ListItemText primary={brand} secondary="Brand" />
            </ListItem>
            <ListItem>
              <ListItemText primary={productName} secondary="Product Name" />
            </ListItem>
            <ListItem>
              <ListItemText primary={variation} secondary="Variation" />
            </ListItem>
          </List>
        )}
      </CardContent>
    </Card>
  );
};

BasicInfoCard.defaultProps = {
  sku: PropTypes.string,
  clientName: PropTypes.string,
  brand: PropTypes.string,
  productName: PropTypes.string,
  variation: PropTypes.string,
  isLoading: false,
  supplierName: '',
};

BasicInfoCard.propTypes = {
  sku: PropTypes.string,
  clientName: PropTypes.string,
  brand: PropTypes.string,
  productName: PropTypes.string,
  variation: PropTypes.string,
  isLoading: PropTypes.bool,
  supplierName: PropTypes.string,
};

export default BasicInfoCard;
