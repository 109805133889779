/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getSkus, updateSku } from '../../../api/listingsAPI';

const initialState = {
  skusList: [],
  loadingStatus: {
    skus: 'NOT_STARTED',
    updateSku: 'NOT_STARTED',
  },
  pageSize: 5,
  initialPage: 0,
};

const listingsStockInfoSlice = createSlice({
  name: 'listingsStockInfo',
  initialState,
  reducers: {
    getListingsStarted(state) {
      state.loadingStatus.skus = 'PENDING';
    },
    getListingsSuccess(state, action) {
      state.skusList = action.payload;
      state.loadingStatus.skus = 'COMPLETE';
    },
    getListingsFailed(state, action) {
      state.loadingStatus.skus = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    updateSkuStarted(state) {
      state.loadingStatus.updateSku = 'PENDING';
    },
    updateSkuSuccess(state, action) {
      state.skusList = state.skusList.map((info) => {
        if (info.sku === action.payload.sku) {
          return action.payload.newInfo;
        }
        return info;
      });
      state.loadingStatus.updateSku = 'COMPLETE';
    },
    updateSkuFailed(state, action) {
      state.loadingStatus.updateSku = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    changePageSize(state, action) {
      state.pageSize = action.payload;
    },
    changeInitialPage(state, action) {
      state.initialPage = action.payload;
    },
  },
});

export const {
  getListingsStarted,
  getListingsSuccess,
  getListingsFailed,
  updateSkuStarted,
  updateSkuSuccess,
  updateSkuFailed,
  changePageSize,
  changeInitialPage,
} = listingsStockInfoSlice.actions;

export const fetchSkus = (platform) => async (dispatch) => {
  dispatch(getListingsStarted());
  const result = await getSkus(platform);

  if (result.status === 200) {
    dispatch(getListingsSuccess(result.skus));
  } else {
    dispatch(getListingsFailed(result.error));
  }
};

export const changeSku = (sku, newInfo) => async (dispatch) => {
  dispatch(updateSkuStarted());
  const result = await updateSku(sku, newInfo);

  if (result.status === 200) {
    dispatch(updateSkuSuccess({ sku, newInfo }));
  } else {
    dispatch(updateSkuFailed(result.error));
  }
};

export const updatePageSize = (newPageSize) => (dispatch) => {
  dispatch(changePageSize(newPageSize));
};

export const updateInitialPage = (newInitialPage) => (dispatch) => {
  dispatch(changeInitialPage(newInitialPage));
};

export default listingsStockInfoSlice.reducer;
