import { connectRefinementList } from 'react-instantsearch-dom';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import numeral from 'numeral';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
  },
  listItemCount: {
    color: theme.palette.secondary.light,
    borderColor: theme.palette.secondary.light,
  },
  listItemText: {
    color: theme.palette.primary.light,
  },
}));
const StatusRefinementList = ({ items, refine }) => {
  const classes = useStyles();

  const getPrettyStatus = (status) => {
    switch (status) {
      case 'PREPARING_FOR_CUSTOMER':
        return 'Preparing for Customer';
      case 'SHIPPED_TO_CUSTOMER':
        return 'Shipped to Customer';
      case 'INTER_DC_TRANSFER':
        return 'Inter DC Transfer';
      case 'NEW_LEAD_TIME_ORDER':
        return 'New Lead Time Order';
      case 'DRAFT_SHIPMENT':
        return 'Draft Shipment';
      case 'CONFIRMED_SHIPMENT':
        return 'Confirmed Shipment';
      case 'SHIPPED_SHIPMENT':
        return 'Shipped Shipment';
      case 'CHECKED_IN_SHIPMENT':
        return 'Checked In Shipment';
      case 'RECEIVED_SHIPMENT':
        return 'Received Shipment';
      case 'CANCELLED_BY_CUSTOMER':
        return 'Cancelled by Customer';
      case 'CANCELLED_BY_SELLER':
        return 'Cancelled by Seller';
      case 'CANCELLED_BY_TAKEALOT':
        return 'Cancelled by Takealot';
      case 'CANCELLED_LATE_DELIVERY':
        return 'Cancelled - Late Delivery';
      case 'CANCELLED_INBOUND_EXCEPTION':
        return 'Cancelled - Inbound Exception';
      case 'RETURNED':
        return 'Returned';
      case 'NEW_DROP_SHIP_ORDER':
        return 'New Drop Ship Order';
      case 'IN_PROGRESS_DROP_SHIP_ORDER':
        return 'In Progress Drop Ship Order';
      case 'READY_TO_COLLECT_DROP_SHIP_ORDER':
        return 'Ready to Collect Drop Ship Order';
      case 'COLLECTED_DROP_SHIP_ORDER':
        return 'Collected Drop Ship Order';
      default:
        return 'UNKNOWN';
    }
  };

  return (
    <List
      className={classes.root}
      subheader={<ListSubheader>Status</ListSubheader>}
    >
      {items.map((item) => {
        return (
          <ListItem
            key={item.label}
            dense
            button
            onClick={() => {
              refine(item.value);
            }}
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={item.isRefined}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': item.label }}
              />
            </ListItemIcon>
            <ListItemText
              id={item.label}
              primary={getPrettyStatus(item.label)}
              className={classes.listItemText}
            />
            <ListItemSecondaryAction>
              <Chip
                variant="outlined"
                size="small"
                label={numeral(item.count).format('0,0')}
                className={classes.listItemCount}
              />
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
};
StatusRefinementList.defaultProps = {
  items: [],
  refine: (itemValue) => console.log(`User refined the filter by ${itemValue}`),
};
StatusRefinementList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.arrayOf(PropTypes.string),
      isRefined: PropTypes.bool,
      count: PropTypes.number,
    })
  ),
  refine: PropTypes.func,
};
export default connectRefinementList(StatusRefinementList);
