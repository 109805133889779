import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import EmptyViewMessage from '../../../../../components/EmptyViewMessage';
import SkusTable from './components/SkusTable';

const useStyles = makeStyles((theme) => ({
  seperator: {
    height: theme.spacing(3),
  },
}));

const AdjustmentsTab = ({ adjustments, platform }) => {
  const classes = useStyles();
  const history = useHistory();

  if (adjustments.length === 0) {
    return (
      <div className={classes.root}>
        <EmptyViewMessage
          heading="No Adjustments"
          message="The client / supplier has not requested any adjustments to the order quantities."
        />
      </div>
    );
  }

  return (
    <SkusTable
      platform={platform}
      skus={adjustments.map((info) => {
        let unfrozenSku = {};
        unfrozenSku = {
          sku: info.sku,
          tsin: info.tsin,
          childAsin: info.childAsin,
          product: info.product,
          requestedUnits: info.requestedUnits,
          adjustedUnits: info.adjustedUnits,
        };
        return unfrozenSku;
      })}
      viewSku={(sku) => history.push(`/listings/basic-info/browse/${sku}`)}
    />
  );
};

AdjustmentsTab.defaultProps = {
  adjustments: [
    {
      sku: 'ABC-123-UNME',
      tsin: 123456,
      childAsin: 'R2D2C3PO',
      product: 'Nike - Yeezy Boost - M',
      requestedUnits: 10,
      adjustedUnits: 8,
    },
  ],
  platform: 'TAKEALOT',
};

AdjustmentsTab.propTypes = {
  adjustments: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string,
      tsin: PropTypes.number,
      childAsin: PropTypes.string,
      product: PropTypes.string,
      requestedUnits: PropTypes.number,
      adjustedUnits: PropTypes.number,
    })
  ),
  platform: PropTypes.oneOf(['AMAZON', 'TAKEALOT', 'BOTH']),
};

export default AdjustmentsTab;
