/* eslint-disable react/jsx-wrap-multilines */
import { useEffect, useState } from 'react';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  adornment: {
    color: theme.palette.primary.light,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  formControl: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  loadingIndicator: {
    color: theme.palette.common.white,
  },
  loadingWrapper: {
    textAlign: 'center',
    margin: theme.spacing(4),
  },
  input: {
    color: theme.palette.common.white,
    marginLeft: theme.spacing(2),
    width: theme.spacing(20),
  },
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    backgroundColor: grey[900],
  },
  select: {
    width: theme.spacing(12),
    borderColor: theme.palette.common.white,
    color: theme.palette.common.white,
    fontSize: '1.6em',
    textAlign: 'center',
  },
}));

const UnitAdjustmentDialog = ({ isOpen, closeDialog, sku, adjustUnits }) => {
  const classes = useStyles();
  const [operator, setOperator] = useState('ADD');
  const [unitQuantity, setUnitQuantity] = useState(0);

  useEffect(() => {
    if (isOpen) {
      setOperator('ADD');
      setUnitQuantity(0);
    }
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onClose={closeDialog}>
      <DialogTitle>{`Unit Adjustment [${sku}]`}</DialogTitle>
      <DialogContent>
        <Paper component="form" className={classes.root}>
          <FormControl className={classes.formControl}>
            <Select
              className={classes.select}
              variant="outlined"
              value={operator}
              onChange={(e) => setOperator(e.target.value)}
            >
              <MenuItem value="ADD">+</MenuItem>
              <MenuItem value="SUBTRACT">-</MenuItem>
            </Select>
          </FormControl>
          <Divider className={classes.divider} orientation="vertical" />
          <InputBase
            className={classes.input}
            value={unitQuantity}
            type="number"
            onChange={(e) =>
              setUnitQuantity(Math.abs(parseInt(e.target.value, 10)))
            }
            endAdornment={
              <InputAdornment
                classes={{
                  positionEnd: classes.adornment,
                }}
                disableTypography
                position="end"
              >
                units
              </InputAdornment>
            }
          />
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Close</Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => {
            closeDialog();
            adjustUnits(operator === 'ADD' ? unitQuantity : -unitQuantity);
          }}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UnitAdjustmentDialog.defaultProps = {
  isOpen: true,
  sku: 'KAN-BOO-0101',
  closeDialog: () => console.log('Dialog was closed.'),
  adjustUnits: (units) => console.log(`Units were adjusted by ${units}.`),
};

UnitAdjustmentDialog.propTypes = {
  isOpen: PropTypes.bool,
  sku: PropTypes.string,
  closeDialog: PropTypes.func,
  adjustUnits: PropTypes.func,
};

export default UnitAdjustmentDialog;
