/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getAnalyticsData, getSku } from '../../../api/listingsAPI';

const initialState = {
  skuInfo: {
    sku: 'Loading',
    amazon: {
      status: 'INACTIVE',
    },
    takealot: {
      status: 'INACTIVE',
    },
  },
  analyticsData: [],
  loadingStatus: {
    skuInfo: 'NOT_STARTED',
    analyticsData: 'NOT_STARTED',
  },
};

const listingsAnalyticsSkuSlice = createSlice({
  name: 'listingsAnalyticsSku',
  initialState,
  reducers: {
    fetchSkuInfoStarted(state) {
      state.loadingStatus.skuInfo = 'PENDING';
    },
    fetchSkuInfoSuccess(state, action) {
      state.skuInfo = action.payload;
      state.loadingStatus.skuInfo = 'COMPLETE';
    },
    fetchSkuInfoFailed(state, action) {
      state.loadingStatus.skuInfo = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getAnalyticsDataStarted(state) {
      state.loadingStatus.analyticsData = 'PENDING';
    },
    getAnalyticsDataSuccess(state, action) {
      state.analyticsData = action.payload;
      state.loadingStatus.analyticsData = 'COMPLETE';
    },
    getAnalyticsDataFailed(state, action) {
      state.loadingStatus.analyticsData = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
  },
});

export const {
  fetchSkuInfoStarted,
  fetchSkuInfoSuccess,
  fetchSkuInfoFailed,
  getAnalyticsDataStarted,
  getAnalyticsDataSuccess,
  getAnalyticsDataFailed,
} = listingsAnalyticsSkuSlice.actions;

export const fetchSkuInfo = (sku) => async (dispatch) => {
  dispatch(fetchSkuInfoStarted());
  const result = await getSku(sku);

  if (result.status === 200) {
    dispatch(fetchSkuInfoSuccess(result.skuInfo));
  } else {
    dispatch(fetchSkuInfoFailed(result.error));
  }
};

export const fetchAnalyticsData =
  (platform, startDate, endDate) => async (dispatch) => {
    dispatch(getAnalyticsDataStarted());
    const result = await getAnalyticsData(platform, startDate, endDate);

    if (result.status === 200) {
      dispatch(getAnalyticsDataSuccess(result.reportData));
    } else {
      dispatch(getAnalyticsDataFailed(result.error));
    }
  };

export default listingsAnalyticsSkuSlice.reducer;
