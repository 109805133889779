import { InstantSearch } from 'react-instantsearch-dom';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import assembleTypesenseServerConfig from '../../../utils/typesense';
import BrandRefinementList from './components/BrandRefinementList';
import BuyBoxOwnerRefinementList from './components/BuyBoxOwnerRefinementList';
import ContentWrapper from '../../../components/ContentWrapper';
import Hits from './components/Hits';
import ManuallyDisabledRefinementList from './components/ManuallyDisabledRefinementList';
import PageHeader from '../../../components/PageHeader';
import StatusRefinementList from './components/StatusRefinementList';
import SupplierRefinementList from './components/SupplierRefinementList';
import TypesenseSearchBar from '../../../components/TypesenseSearchBar';

const TYPESENSE_SERVER_CONFIG = assembleTypesenseServerConfig();
const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: TYPESENSE_SERVER_CONFIG,
  additionalSearchParameters: {
    queryBy:
      'barcode,copy.description,copy.title,brand,categories.department,categories.division,categories.lowestCategory,categories.mainCategory,copy.whatsInTheBox,supplierDescription,supplierId,supplierName,supplierSku,eolSku,tailgating.buyBoxOwner',
    numTypos: 1,
    typoTokensThreshold: 1,
  },
});
const { searchClient } = typesenseInstantsearchAdapter;

const breadcrumbs = [
  {
    label: 'Presales',
    link: '/presales',
  },
  {
    label: 'Products',
    link: '/presales/products',
  },
];

const ProductsBrowse = () => {
  const history = useHistory();

  return (
    <div>
      <PageHeader
        title="Products"
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Browse"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
      />
      <ContentWrapper>
        <InstantSearch
          searchClient={searchClient}
          indexName="presalesSkus"
          refresh
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TypesenseSearchBar />
            </Grid>
            <Grid item xs={6} sm={9}>
              <Hits />
            </Grid>
            <Grid item xs={6} sm={3}>
              <ManuallyDisabledRefinementList attribute="manuallyDisabled" />
              <StatusRefinementList attribute="status" />
              <BuyBoxOwnerRefinementList attribute="tailgating.buyBoxOwner" />
              <SupplierRefinementList attribute="supplierName" />
              <BrandRefinementList attribute="brand" />
            </Grid>
          </Grid>
        </InstantSearch>
      </ContentWrapper>
    </div>
  );
};
export default ProductsBrowse;
