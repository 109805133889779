/* eslint-disable react/prop-types */
import { fade } from '@material-ui/core/styles/colorManipulator';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';

const headerStyle = {
  backgroundColor: '#A7A5A5',
  color: '#171415',
};

const useStyles = makeStyles((theme) => ({
  loadingOverlay: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: fade(theme.palette.common.black, 0.8),
  },
  wrapperContent: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

const ProductSelectTable = ({ isLoading, skus, updateSelectedProducts }) => {
  const classes = useStyles();

  const columns = [
    {
      title: 'SKU',
      field: 'sku',
      editable: 'never',
    },
    {
      title: 'TSIN',
      field: 'tsin',
      editable: 'never',
    },
    {
      title: 'Description',
      field: 'description',
      editable: 'never',
    },
    {
      title: 'Brand',
      field: 'brand',
      editable: 'never',
    },
  ];

  return (
    <div className={classes.wrapperContent}>
      <MaterialTable
        isLoading={isLoading}
        columns={columns}
        data={skus}
        options={{
          headerStyle,
          sorting: false,
          grouping: false,
          draggable: false,
          search: false,
          showTitle: false,
          toolbar: false,
          selection: true,
        }}
        components={{
          OverlayLoading: () => (
            <div className={classes.loadingOverlay}>
              <CircularProgress color="primary" size={32} />
            </div>
          ),
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'No available products.',
          },
        }}
        onSelectionChange={(rows) => {
          updateSelectedProducts(
            rows.map(({ sku, tsin, childAsin, description }) => ({
              sku,
              tsin,
              childAsin,
              product: description,
            }))
          );
        }}
      />
    </div>
  );
};

ProductSelectTable.defaultProps = {
  skus: [],
  updateSelectedProducts: (rows) =>
    console.log(`Users selected the following rows:`, rows),
  isLoading: false,
};

ProductSelectTable.propTypes = {
  skus: PropTypes.arrayOf(PropTypes.shape()),
  updateSelectedProducts: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default ProductSelectTable;
