import { useState } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import StoreIcon from '@material-ui/icons/Store';

const SupplierSelector = ({ supplier, changeSupplier, supplierOptions }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };

  const getPrettyFilter = (filter) => {
    if (filter === 'ALL') {
      return 'All Suppliers';
    }

    const supplierInfo = supplierOptions.find((info) => info.id === filter);
    if (!supplierInfo) {
      return 'Unknown';
    }

    return supplierInfo.name;
  };

  return (
    <>
      <Button
        aria-controls="suppliers-menu"
        aria-haspopup="true"
        size="large"
        startIcon={<StoreIcon />}
        onClick={openMenu}
      >
        {getPrettyFilter(supplier)}
      </Button>
      <Menu
        id="suppliers-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem
          onClick={() => {
            changeSupplier('ALL');
            closeMenu();
          }}
        >
          All
        </MenuItem>
        {supplierOptions.map((option) => (
          <MenuItem
            key={`filter-option-${option.id}`}
            onClick={() => {
              changeSupplier(option.id);
              closeMenu();
            }}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

SupplierSelector.defaultProps = {
  supplier: 'ALL',
  changeSupplier: (newFilter) =>
    console.log('User changes the supplier filter to:', newFilter),
  supplierOptions: [],
};

SupplierSelector.propTypes = {
  supplier: PropTypes.string,
  changeSupplier: PropTypes.func,
  supplierOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
};

export default SupplierSelector;
