import { green, red, yellow } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  chipGreen: {
    color: green[800],
    borderColor: green[800],
  },
  chipRed: {
    color: red[800],
    borderColor: red[800],
  },
  chipYellow: {
    color: yellow[800],
    borderColor: yellow[800],
  },
  root: {
    width: '100%',
  },
  code: {
    fontSize: 14,
    color: theme.palette.secondary.light,
  },
  title: {
    color: theme.palette.primary.light,
    marginBottom: 10,
  },
  pos: {
    marginBottom: 12,
  },
}));

const Hit = ({ title, code, status, goToSupplier }) => {
  const classes = useStyles();

  const getStatusChip = () => {
    switch (status) {
      case 'REJECTED':
        return (
          <Chip
            variant="outlined"
            size="small"
            label="Rejected"
            className={classes.chipRed}
          />
        );
      case 'TRADING':
        return (
          <Chip
            variant="outlined"
            size="small"
            label="Trading"
            className={classes.chipGreen}
          />
        );
      case 'AWAITING_APPROVAL':
        return (
          <Chip
            variant="outlined"
            size="small"
            label="Awaiting Approval"
            className={classes.chipYellow}
          />
        );
      case 'ONBOARDING_PRODUCTS':
        return (
          <Chip
            variant="outlined"
            size="small"
            label="Onboarding Products"
            className={classes.chipYellow}
          />
        );
      default:
        return <Chip variant="outlined" size="small" label="Basic" />;
    }
  };

  return (
    <Card className={classes.root} onClick={goToSupplier}>
      <CardActionArea>
        <CardContent>
          <Typography className={classes.title} variant="h5" component="h2">
            {title}
          </Typography>
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography className={classes.code} variant="body2" component="p">
              {code}
            </Typography>
            {getStatusChip()}
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

Hit.defaultProps = {
  code: 'VMT',
  title: 'Vermont',
  status: 'ONBOARDING_PRODUCTS',
  goToSupplier: () => console.log('User navigated to supplier info'),
};

Hit.propTypes = {
  code: PropTypes.string,
  title: PropTypes.string,
  status: PropTypes.oneOf([
    'AWAITING_APPROVAL',
    'ONBOARDING_PRODUCTS',
    'TRADING',
    'REJECTED',
  ]),
  goToSupplier: PropTypes.func,
};

export default Hit;
