/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getSku } from '../../../api/listingsAPI';
import { getClient } from '../../../api/clientsAPI';
import {
  getStatementReports,
  getAmazonStatements,
  getTakealotStatements,
  createNewStatementReport,
  addAmazonStatementToReport,
  addTakealotStatementToReport,
  finalizeAmazonStatement,
  finalizeTakealotStatement,
  updatePricing,
  loadNewAdjustment,
  deleteAdjustment,
} from '../../../api/financeAPI';

const initialState = {
  amazonStatements: [],
  takealotStatements: [],
  statementReportsList: [],
  clientsList: [],
  clientLoadingCount: {
    total: -1,
    success: 0,
    failed: 0,
  },
  failedClientIds: [],
  amazonSkusList: [],
  amazonFailedSkus: [],
  amazonSkuLoadingCount: {
    total: -1,
    success: 0,
    failed: 0,
  },
  takealotSkusList: [],
  takealotFailedSkus: [],
  takealotSkuLoadingCount: {
    total: -1,
    success: 0,
    failed: 0,
  },
  amazonFailedStatements: [],
  amazonStatementsLoadingCount: {
    total: -1,
    success: 0,
    failed: 0,
  },
  takealotFailedStatements: [],
  takealotStatementsLoadingCount: {
    total: -1,
    success: 0,
    failed: 0,
  },
  loadingStatus: {
    statementReports: 'NOT_STARTED',
    statementReportCreation: 'NOT_STARTED',
    amazonStatements: 'NOT_STARTED',
    takealotStatements: 'NOT_STARTED',
    finalizeStatement: 'NOT_STARTED',
    fixPricing: 'NOT_STARTED',
    addAdjustment: 'NOT_STARTED',
    removeAdjustment: 'NOT_STARTED',
  },
  amazonPageSize: 5,
  amazonInitialPage: 0,
  takealotPageSize: 5,
  takealotInitialPage: 0,
};

const financeStatementsSlice = createSlice({
  name: 'financeStatements',
  initialState,
  reducers: {
    getStatementReportsStarted(state) {
      state.loadingStatus.statementReports = 'PENDING';
    },
    getStatementReportsSuccess(state, action) {
      state.statementReportsList = action.payload;
      state.loadingStatus.statementReports = 'COMPLETE';
    },
    getStatementReportsFailed(state, action) {
      state.loadingStatus.statementReports = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    finalizeStatementStarted(state) {
      state.loadingStatus.finalizeStatement = 'PENDING';
    },
    finalizeStatementSuccess(state, action) {
      state.loadingStatus.finalizeStatement = 'COMPLETE';
      if (action.payload.platform === 'amazon') {
        const changeIndex = state.amazonStatements.findIndex(
          (statement) => statement.clientId === action.payload.statementId
        );
        state.amazonStatements[changeIndex].status = 'FINALIZED';
      } else {
        const changeIndex = state.takealotStatements.findIndex(
          (statement) => statement.clientId === action.payload.statementId
        );
        state.takealotStatements[changeIndex].status = 'FINALIZED';
      }
    },
    finalizeStatementFailed(state, action) {
      state.loadingStatus.finalizeStatement = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getAmazonStatementsStarted(state) {
      state.loadingStatus.amazonStatements = 'PENDING';
    },
    getAmazonStatementsSuccess(state, action) {
      state.amazonStatements = action.payload;
      state.loadingStatus.amazonStatements = 'COMPLETE';
    },
    getAmazonStatementsFailed(state, action) {
      state.loadingStatus.amazonStatements = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getTakealotStatementsStarted(state) {
      state.loadingStatus.takealotStatements = 'PENDING';
    },
    getTakealotStatementsSuccess(state, action) {
      state.takealotStatements = action.payload;
      state.loadingStatus.takealotStatements = 'COMPLETE';
    },
    getTakealotStatementsFailed(state, action) {
      state.loadingStatus.takealotStatements = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    addStatementReportStarted(state) {
      state.loadingStatus.statementReportCreation = 'PENDING';
    },
    addStatementReportSuccess(state, action) {
      state.statementReportsList.push(action.payload);
      state.loadingStatus.statementReportCreation = 'COMPLETE';
    },
    addStatementReportFailed(state, action) {
      state.loadingStatus.statementReportCreation = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getClientSuccess(state, action) {
      state.clientsList.push(action.payload);
      state.clientLoadingCount.success += 1;
    },
    getClientFailed(state, action) {
      state.clientLoadingCount.failed += 1;
      state.failedClientIds.push(action.payload);
    },
    updateTotalClientsToLoad(state, action) {
      state.clientsList = [];
      state.failedClientIds = [];
      state.clientLoadingCount.total = action.payload;
      state.clientLoadingCount.success = 0;
      state.clientLoadingCount.failed = 0;
    },
    getAmazonSkuSuccess(state, action) {
      state.amazonSkusList.push(action.payload);
      state.amazonSkuLoadingCount.success += 1;
    },
    getAmazonSkuFailed(state, action) {
      state.amazonSkuLoadingCount.failed += 1;
      state.amazonFailedSkus.push(action.payload);
    },
    updateTotalAmazonSkusToLoad(state, action) {
      state.amazonSkusList = [];
      state.amazonFailedSkus = [];
      state.amazonSkuLoadingCount.total = action.payload;
      state.amazonSkuLoadingCount.success = 0;
      state.amazonSkuLoadingCount.failed = 0;
    },
    getTakealotSkuSuccess(state, action) {
      state.takealotSkusList.push(action.payload);
      state.takealotSkuLoadingCount.success += 1;
    },
    getTakealotSkuFailed(state, action) {
      state.takealotSkuLoadingCount.failed += 1;
      state.takealotFailedSkus.push(action.payload);
    },
    updateTotalTakealotSkusToLoad(state, action) {
      state.takealotSkusList = [];
      state.takealotFailedSkus = [];
      state.takealotSkuLoadingCount.total = action.payload;
      state.takealotSkuLoadingCount.success = 0;
      state.takealotSkuLoadingCount.failed = 0;
    },
    amazonStatementUploadSuccess(state) {
      state.amazonStatementsLoadingCount.success += 1;
    },
    amazonStatementUploadFailed(state, action) {
      state.amazonStatementsLoadingCount.failed += 1;
      state.amazonFailedStatements.push(action.payload);
    },
    updateTotalAmazonStatementsToLoad(state, action) {
      state.amazonFailedStatements = [];
      state.amazonStatementsLoadingCount.total = action.payload;
      state.amazonStatementsLoadingCount.success = 0;
      state.amazonStatementsLoadingCount.failed = 0;
    },
    takealotStatementUploadSuccess(state) {
      state.takealotStatementsLoadingCount.success += 1;
    },
    takealotStatementUploadFailed(state, action) {
      state.takealotStatementsLoadingCount.failed += 1;
      state.takealotFailedStatements.push(action.payload);
    },
    updatePricingStarted(state) {
      state.loadingStatus.pricingFix = 'PENDING';
    },
    updatePricingSuccess(state, action) {
      state.loadingStatus.pricingFix = 'COMPLETE';
      if (action.payload.platform === 'amazon') {
        state.amazonStatements = state.amazonStatements.map((info) => {
          if (info.clientId === action.payload.clientId) {
            return action.payload.newStatement;
          }
          return info;
        });
      } else {
        state.takealotStatements = state.takealotStatements.map((info) => {
          if (info.clientId === action.payload.clientId) {
            return action.payload.newStatement;
          }
          return info;
        });
      }
    },
    updatePricingFailed(state, action) {
      state.loadingStatus.pricingFix = 'COMPLETE';
      console.error(action.payload.error);
    },
    addAdjustmentStarted(state) {
      state.loadingStatus.addAdjustment = 'PENDING';
    },
    addAdjustmentSuccess(state, action) {
      state.loadingStatus.addAdjustment = 'COMPLETE';
      if (action.payload.platform === 'amazon') {
        state.amazonStatements = state.amazonStatements.map((info) => {
          if (info.clientId === action.payload.clientId) {
            return action.payload.newStatement;
          }
          return info;
        });
      } else {
        state.takealotStatements = state.takealotStatements.map((info) => {
          if (info.clientId === action.payload.clientId) {
            return action.payload.newStatement;
          }
          return info;
        });
      }
    },
    addAdjustmentFailed(state, action) {
      state.loadingStatus.addAdjustment = 'COMPLETE';
      console.error(action.payload.error);
    },
    removeAdjustmentStarted(state) {
      state.loadingStatus.removeAdjustment = 'PENDING';
    },
    removeAdjustmentSuccess(state, action) {
      state.loadingStatus.removeAdjustment = 'COMPLETE';
      if (action.payload.platform === 'amazon') {
        state.amazonStatements = state.amazonStatements.map((info) => {
          if (info.clientId === action.payload.clientId) {
            return action.payload.newStatement;
          }
          return info;
        });
      } else {
        state.takealotStatements = state.takealotStatements.map((info) => {
          if (info.clientId === action.payload.clientId) {
            return action.payload.newStatement;
          }
          return info;
        });
      }
    },
    removeAdjustmentFailed(state, action) {
      state.loadingStatus.removeAdjustment = 'COMPLETE';
      console.error(action.payload.error);
    },
    updateTotalTakealotStatementsToLoad(state, action) {
      state.takealotFailedStatements = [];
      state.takealotStatementsLoadingCount.total = action.payload;
      state.takealotStatementsLoadingCount.success = 0;
      state.takealotStatementsLoadingCount.failed = 0;
    },
    resetToInitialState() {
      return initialState;
    },
    changeAmazonPageSize(state, action) {
      state.amazonPageSize = action.payload;
    },
    changeAmazonInitialPage(state, action) {
      state.amazonInitialPage = action.payload;
    },
    changeTakealotPageSize(state, action) {
      state.takealotPageSize = action.payload;
    },
    changeTakealotInitialPage(state, action) {
      state.takealotInitialPage = action.payload;
    },
  },
});

// eslint-disable-next-line no-empty-pattern
export const {
  getStatementReportsStarted,
  getStatementReportsSuccess,
  getStatementReportsFailed,
  getAmazonStatementsStarted,
  getAmazonStatementsSuccess,
  getAmazonStatementsFailed,
  getTakealotStatementsStarted,
  getTakealotStatementsSuccess,
  getTakealotStatementsFailed,
  finalizeStatementStarted,
  finalizeStatementSuccess,
  finalizeStatementFailed,
  addStatementReportStarted,
  addStatementReportSuccess,
  addStatementReportFailed,
  getClientSuccess,
  getClientFailed,
  updateTotalClientsToLoad,
  getAmazonSkuSuccess,
  getAmazonSkuFailed,
  updateTotalAmazonSkusToLoad,
  getTakealotSkuSuccess,
  getTakealotSkuFailed,
  updateTotalTakealotSkusToLoad,
  amazonStatementUploadSuccess,
  amazonStatementUploadFailed,
  updateTotalAmazonStatementsToLoad,
  takealotStatementUploadSuccess,
  takealotStatementUploadFailed,
  updateTotalTakealotStatementsToLoad,
  resetToInitialState,
  updatePricingStarted,
  updatePricingSuccess,
  updatePricingFailed,
  addAdjustmentStarted,
  addAdjustmentSuccess,
  addAdjustmentFailed,
  removeAdjustmentStarted,
  removeAdjustmentSuccess,
  removeAdjustmentFailed,
  changeAmazonPageSize,
  changeAmazonInitialPage,
  changeTakealotPageSize,
  changeTakealotInitialPage,
} = financeStatementsSlice.actions;

export const fetchStatementReports = () => async (dispatch) => {
  dispatch(getStatementReportsStarted());
  const result = await getStatementReports();

  if (result.status === 200) {
    dispatch(getStatementReportsSuccess(result.statementReports));
  } else {
    dispatch(getStatementReportsFailed(result.error));
  }
};

export const fetchAmazonStatements = (reportId) => async (dispatch) => {
  dispatch(getAmazonStatementsStarted());
  const result = await getAmazonStatements(reportId);

  if (result.status === 200) {
    dispatch(getAmazonStatementsSuccess(result.statements));
  } else {
    dispatch(getAmazonStatementsFailed(result.error));
  }
};

export const fetchTakealotStatements = (reportId) => async (dispatch) => {
  dispatch(getTakealotStatementsStarted());
  const result = await getTakealotStatements(reportId);

  if (result.status === 200) {
    dispatch(getTakealotStatementsSuccess(result.statements));
  } else {
    dispatch(getTakealotStatementsFailed(result.error));
  }
};

export const saveFinalizeAmazonStatement =
  (reportId, statementId) => async (dispatch) => {
    dispatch(finalizeStatementStarted());
    const result = await finalizeAmazonStatement(reportId, statementId);

    if (result.status === 200) {
      dispatch(
        finalizeStatementSuccess({
          statementId,
          platform: 'amazon',
        })
      );
    } else {
      dispatch(finalizeStatementFailed(result.error));
    }
  };

export const saveFinalizeTakealotStatement =
  (reportId, statementId) => async (dispatch) => {
    dispatch(finalizeStatementStarted());
    const result = await finalizeTakealotStatement(reportId, statementId);

    if (result.status === 200) {
      dispatch(
        finalizeStatementSuccess({
          statementId,
          platform: 'takealot',
        })
      );
    } else {
      dispatch(finalizeStatementFailed(result.error));
    }
  };

export const addStatementReport = (reportInfo) => async (dispatch) => {
  dispatch(addStatementReportStarted());
  const result = await createNewStatementReport(reportInfo);

  if (result.status === 200) {
    dispatch(addStatementReportSuccess(result.reportInfo));
  } else {
    dispatch(addStatementReportFailed(result.error));
  }
};

export const fetchClient = (clientId) => async (dispatch) => {
  const result = await getClient(clientId);

  if (result.status === 200) {
    dispatch(getClientSuccess(result.clientInfo));
  } else {
    dispatch(getClientFailed(result.clientId));
  }
};

export const setTotalClientsToLoad = (total) => (dispatch) => {
  dispatch(updateTotalClientsToLoad(total));
};

export const fetchAmazonSku = (sku) => async (dispatch) => {
  const result = await getSku(sku, 'AMAZON');

  if (result.status === 200) {
    dispatch(getAmazonSkuSuccess(result.skuInfo));
  } else {
    dispatch(getAmazonSkuFailed(result.sku));
  }
};

export const setTotalAmazonSkusToLoad = (total) => (dispatch) => {
  dispatch(updateTotalAmazonSkusToLoad(total));
};

export const fetchTakealotSku = (sku) => async (dispatch) => {
  const result = await getSku(sku, 'TAKEALOT');

  if (result.status === 200) {
    dispatch(getTakealotSkuSuccess(result.skuInfo));
  } else {
    dispatch(getTakealotSkuFailed(result.sku));
  }
};

export const setTotalTakealotSkusToLoad = (total) => (dispatch) => {
  dispatch(updateTotalTakealotSkusToLoad(total));
};

export const uploadAmazonStatement =
  (reportId, statementId, statementInfo) => async (dispatch) => {
    const result = await addAmazonStatementToReport(
      reportId,
      statementId,
      statementInfo
    );

    if (result.status === 200) {
      dispatch(amazonStatementUploadSuccess());
    } else {
      dispatch(amazonStatementUploadFailed(result.statementInfo));
    }
  };

export const setTotalAmazonStatementsToLoad = (total) => (dispatch) => {
  dispatch(updateTotalAmazonStatementsToLoad(total));
};

export const uploadTakealotStatement =
  (reportId, statementId, statementInfo) => async (dispatch) => {
    const result = await addTakealotStatementToReport(
      reportId,
      statementId,
      statementInfo
    );

    if (result.status === 200) {
      dispatch(takealotStatementUploadSuccess());
    } else {
      dispatch(takealotStatementUploadFailed(result.statementInfo));
    }
  };

export const uploadPriceFixes =
  (
    reportId,
    clientId,
    statementUpdates,
    reportUpdates,
    newStatement,
    platform
  ) =>
  async (dispatch) => {
    dispatch(updatePricingStarted());
    const result = await updatePricing(
      reportId,
      clientId,
      statementUpdates,
      reportUpdates,
      platform
    );

    if (result.status === 200) {
      dispatch(updatePricingSuccess({ clientId, newStatement, platform }));
    } else {
      dispatch(updatePricingFailed(result.error));
    }
  };

export const uploadAdjustmentAddition =
  (
    reportId,
    clientId,
    statementUpdates,
    reportUpdates,
    newStatement,
    platform
  ) =>
  async (dispatch) => {
    dispatch(addAdjustmentStarted());
    const result = await loadNewAdjustment(
      reportId,
      clientId,
      statementUpdates,
      reportUpdates,
      platform
    );

    if (result.status === 200) {
      dispatch(addAdjustmentSuccess({ clientId, newStatement, platform }));
    } else {
      dispatch(addAdjustmentFailed(result.error));
    }
  };

export const uploadAdjustmentRemoval =
  (
    reportId,
    clientId,
    statementUpdates,
    reportUpdates,
    newStatement,
    platform
  ) =>
  async (dispatch) => {
    dispatch(removeAdjustmentStarted());
    const result = await deleteAdjustment(
      reportId,
      clientId,
      statementUpdates,
      reportUpdates,
      platform
    );

    if (result.status === 200) {
      dispatch(removeAdjustmentSuccess({ clientId, newStatement, platform }));
    } else {
      dispatch(removeAdjustmentFailed(result.error));
    }
  };

export const setTotalTakealotStatementsToLoad = (total) => (dispatch) => {
  dispatch(updateTotalTakealotStatementsToLoad(total));
};

export const resetState = () => (dispatch) => {
  dispatch(resetToInitialState());
};

export const updateAmazonPageSize = (newPageSize) => (dispatch) => {
  dispatch(changeAmazonPageSize(newPageSize));
};

export const updateAmazonInitialPage = (newInitialPage) => (dispatch) => {
  dispatch(changeAmazonInitialPage(newInitialPage));
};

export const updateTakealotPageSize = (newPageSize) => (dispatch) => {
  dispatch(changeTakealotPageSize(newPageSize));
};

export const updateTakealotInitialPage = (newInitialPage) => (dispatch) => {
  dispatch(changeTakealotInitialPage(newInitialPage));
};

export default financeStatementsSlice.reducer;
