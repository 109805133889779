/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getQueries, updateQueryStatus } from '../../../api/clientsAPI';
import { closeFeedback } from '../StockRequestsBrowse/slice';

const initialState = {
  queries: [],
  loadingStatus: {
    queries: 'NOT_STARTED',
    updateStatus: 'NOT_STARTED',
  },
  pageSize: 5,
  initialPage: 0,
  feedback: {
    isOpen: false,
    type: 'error',
    message: '',
  },
};

const clientsQueriesBrowseSlice = createSlice({
  name: 'clientsQueriesBrowse',
  initialState,
  reducers: {
    getQueriesStarted(state) {
      state.loadingStatus.queries = 'PENDING';
    },
    getQueriesSuccess(state, action) {
      state.queries = action.payload;
      state.loadingStatus.queries = 'COMPLETE';
    },
    getQueriesFailed(state, action) {
      state.loadingStatus.queries = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload,
      };
    },
    changePageSize(state, action) {
      state.pageSize = action.payload;
    },
    changeInitialPage(state, action) {
      state.initialPage = action.payload;
    },
    updateStatusStarted(state) {
      state.loadingStatus.updateStatus = 'PENDING';
    },
    updateStatusSuccess(state, action) {
      state.queries = state.queries.map((info) => {
        if (info.id === action.payload.id) {
          return {
            ...info,
            status: action.payload.status,
          };
        }
        return info;
      });
      state.loadingStatus.updateStatus = 'COMPLETE';
    },
    updateStatusFailed(state, action) {
      state.loadingStatus.updateStatus = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload,
      };
    },
  },
});

export const {
  getQueriesStarted,
  getQueriesSuccess,
  getQueriesFailed,
  changePageSize,
  changeInitialPage,
  updateStatusStarted,
  updateStatusSuccess,
  updateStatusFailed,
} = clientsQueriesBrowseSlice.actions;

export const fetchQueries = () => async (dispatch) => {
  dispatch(getQueriesStarted());
  const result = await getQueries();

  if (result.status === 200) {
    dispatch(getQueriesSuccess(result.queries));
  } else {
    dispatch(getQueriesFailed(result.error));
  }
};

export const changeQueryStatus = (id, status) => async (dispatch) => {
  dispatch(updateStatusStarted());
  const result = await updateQueryStatus(id, status);
  if (result.status === 200) {
    dispatch(updateStatusSuccess({ id, status }));
  } else {
    dispatch(updateStatusFailed(result.error));
  }
};

export const updatePageSize = (newPageSize) => (dispatch) => {
  dispatch(changePageSize(newPageSize));
};

export const updateInitialPage = (newInitialPage) => (dispatch) => {
  dispatch(changeInitialPage(newInitialPage));
};

export const closeSnackbar = () => (dispatch) => {
  dispatch(closeFeedback());
};

export default clientsQueriesBrowseSlice.reducer;
