import moment from 'moment';
import numeral from 'numeral';
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import logo from '../../img/logo-white.png';
import roboto from '../../fonts/Roboto-Regular.ttf';
import robotoBold from '../../fonts/Roboto-Bold.ttf';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: roboto,
    },
    {
      src: robotoBold,
      fontWeight: 'bold',
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    padding: 50,
    paddingBottom: 70,
    lineHeight: 1.4,
    fontFamily: 'Roboto',
  },
  header: {
    flexDirection: 'row',
    backgroundColor: '#171415',
    width: '100%',
    padding: 20,
  },
  headerTitle: {
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    marginVertical: 12,
    color: '#f36438',
    fontSize: 16,
  },
  headerInfo: {
    width: '60%',
    fontSize: 10,
    color: '#fcf9f8',
    textAlign: 'right',
  },
  logoWrapper: {
    width: '40%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: 120,
    height: 'auto',
  },
  dateRange: {
    fontSize: 7,
    color: '#bdbdbd',
  },
  section: {
    width: '100%',
    textAlign: 'center',
    color: '#595959',
    fontSize: 10,
    paddingVertical: 24,
  },
  sectionTitle: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginVertical: 24,
    backgroundColor: '#171415',
    color: '#fcf9f8',
    padding: 9,
    fontSize: 10,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    fontSize: 9,
  },
  tableSales: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderBottomWidth: 0,
    fontSize: 9,
  },
  oddRow: {
    margin: 'auto',
    flexDirection: 'row',
    backgroundColor: '#f3f3f3',
  },
  evenRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  titleRow: {
    fontFamily: 'Roboto',
    margin: 'auto',
    width: '100%',
    flexDirection: 'row',
    textAlign: 'left',
    fontWeight: 'bold',
    paddingVertical: 8,
    paddingHorizontal: 14,
    borderWidth: 1,
    backgroundColor: '#595959',
    color: '#fcf9f8',
  },
  tableHeader: {
    fontFamily: 'Roboto',
    margin: 'auto',
    flexDirection: 'row',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  tableFooter: {
    fontFamily: 'Roboto',
    margin: 'auto',
    flexDirection: 'row',
    fontWeight: 'bold',
  },
  page1Col1: {
    fontFamily: 'Roboto',
    width: '30%',
    fontWeight: 'bold',
    borderStyle: 'solid',
    textAlign: 'right',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderTopWidth: 0,
    borderRightWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
  },
  page1Col2: {
    width: '70%',
    borderStyle: 'solid',
    textAlign: 'left',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
  },
  salesCol1: {
    width: '80%',
    borderStyle: 'solid',
    textAlign: 'left',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderTopWidth: 0,
    borderRightWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
  },
  salesCol2: {
    width: '20%',
    borderStyle: 'solid',
    textAlign: 'right',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
  },
  headerCol1: {
    width: '80%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderTopWidth: 0,
    borderRightWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
  },
  headerCol2: {
    width: '20%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
  },
  footer1Col1: {
    fontSize: 8,
    width: '80%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    paddingVertical: 4,
    paddingHorizontal: 8,
    textAlign: 'right',
  },
  footer1Col2: {
    fontSize: 8,
    width: '20%',
    borderStyle: 'solid',
    borderWidth: 1,
    paddingVertical: 4,
    paddingHorizontal: 8,
    textAlign: 'right',
  },
  footer2Col1: {
    fontSize: 8,
    width: '80%',
    paddingVertical: 4,
    paddingHorizontal: 8,
    textAlign: 'right',
  },
  footer2Col2: {
    fontSize: 8,
    width: '20%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderTopWidth: 0,
    paddingVertical: 4,
    paddingHorizontal: 8,
    textAlign: 'right',
  },
  footer3Col1: {
    fontSize: 12,
    width: '80%',
    paddingVertical: 4,
    paddingHorizontal: 8,
    textAlign: 'right',
  },
  footer3Col2: {
    fontSize: 12,
    width: '20%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderTopWidth: 0,
    paddingVertical: 4,
    paddingHorizontal: 8,
    textAlign: 'right',
  },
  finePrint: {
    paddingHorizontal: 72,
    fontSize: 8,
    marginBottom: 8,
  },
  finePrint2: {
    marginTop: 8,
    textAlign: 'right',
    fontSize: 8,
    marginBottom: 8,
  },
});

const TakealotReconciliation = ({
  ourInfo,
  clientInfo,
  startDate,
  endDate,
  lineItems,
  adjustments,
}) => {
  const totalRetail = lineItems.reduce((total, current) => {
    if (current.totalRevenueRands) {
      return total + current.totalRevenueRands;
    }
    return total;
  }, 0);
  const totalForClient = lineItems.reduce((total, current) => {
    if (current.total && current.type !== 'ADJUSTMENT') {
      return total + current.total;
    }
    return total;
  }, 0);
  const totalCourierFees = adjustments.reduce(
    (total, current) => total + current.total,
    0
  );
  const easyonlineMargin = totalRetail * -0.1;
  const takealotRelatedFees =
    -1 * (totalRetail - totalForClient + easyonlineMargin);
  const totalDue =
    totalRetail + easyonlineMargin + totalCourierFees + takealotRelatedFees;

  return (
    <Document
      title="Takealot Reconciliation"
      creator="EasyOnline Pty Ltd"
      subject={`Takealot Reconciliation for ${clientInfo.name} (${moment(
        startDate
      ).format('DD/MM/YYYY')} - ${moment(endDate).format('DD/MM/YYYY')})`}
    >
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.logoWrapper}>
            <Image src={logo} style={styles.logo} />
          </View>
          <View style={styles.headerInfo}>
            <Text style={styles.headerTitle}>Takealot Reconciliation</Text>
            <Text style={styles.dateRange}>
              {`${moment(startDate).format('D MMMM YYYY')} to ${moment(
                endDate
              ).format('D MMMM YYYY')}`}
            </Text>
            <Text>{clientInfo.name}</Text>
          </View>
        </View>
        <View style={styles.section}>
          <Text style={styles.finePrint}>
            {`This reconciliation is for unit sales activity on Takealot from ${`${moment(
              startDate
            ).format('D MMMM YYYY')} to ${moment(endDate).format(
              'D MMMM YYYY'
            )}`}. Please send us an invoice reflecting the information below.`}
          </Text>
          <Text style={styles.sectionTitle}>Address Invoice To:</Text>
          <View style={styles.table}>
            <View style={styles.oddRow}>
              <View style={styles.page1Col1}>
                <Text>Business Name</Text>
              </View>
              <View style={styles.page1Col2}>
                <Text>{ourInfo.businessName}</Text>
              </View>
            </View>
            <View style={styles.evenRow}>
              <View style={styles.page1Col1}>
                <Text>VAT Number</Text>
              </View>
              <View style={styles.page1Col2}>
                <Text>{ourInfo.vatNumber}</Text>
              </View>
            </View>
            <View style={styles.oddRow}>
              <View style={styles.page1Col1}>
                <Text>Physical Address</Text>
              </View>
              <View style={styles.page1Col2}>
                <Text>{ourInfo.physicalAddress}</Text>
              </View>
            </View>
            <View style={styles.evenRow}>
              <View style={styles.page1Col1}>
                <Text>Telephone Number</Text>
              </View>
              <View style={styles.page1Col2}>
                <Text>{ourInfo.telephoneNumber}</Text>
              </View>
            </View>
          </View>
          <Text style={styles.sectionTitle}>Your Details:</Text>
          <Text style={styles.finePrint}>
            Please ensure that your bank details are clearly stated.
          </Text>
          <View style={styles.table}>
            <View style={styles.oddRow}>
              <View style={styles.page1Col1}>
                <Text>Business Name</Text>
              </View>
              <View style={styles.page1Col2}>
                <Text>{clientInfo.legalEntityName}</Text>
              </View>
            </View>
            <View style={styles.evenRow}>
              <View style={styles.page1Col1}>
                <Text>VAT Number</Text>
              </View>
              <View style={styles.page1Col2}>
                <Text>{clientInfo.vatNumber}</Text>
              </View>
            </View>
            <View style={styles.oddRow}>
              <View style={styles.page1Col1}>
                <Text>Physical Address</Text>
              </View>
              <View style={styles.page1Col2}>
                <Text>{clientInfo.physicalAddress}</Text>
              </View>
            </View>
            <View style={styles.evenRow}>
              <View style={styles.page1Col1}>
                <Text>Telephone Number</Text>
              </View>
              <View style={styles.page1Col2}>
                <Text>{clientInfo.telephoneNumber}</Text>
              </View>
            </View>
            <View style={styles.oddRow}>
              <View style={styles.page1Col1}>
                <Text>Email Address</Text>
              </View>
              <View style={styles.page1Col2}>
                <Text>{clientInfo.emailAddress}</Text>
              </View>
            </View>
            <View style={styles.evenRow}>
              <View style={styles.page1Col1}>
                <Text>Main Contact</Text>
              </View>
              <View style={styles.page1Col2}>
                <Text>{clientInfo.mainContact}</Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.section} break>
          <Text style={styles.sectionTitle}>Reconciliation</Text>
          <View style={styles.tableSales}>
            <View style={styles.tableHeader}>
              <View style={styles.headerCol1}>
                <Text>Description</Text>
              </View>
              <View style={styles.headerCol2}>
                <Text>Due</Text>
              </View>
            </View>
            <View style={styles.oddRow}>
              <View style={styles.salesCol1}>
                <Text>Unit Sales</Text>
              </View>
              <View style={styles.salesCol2}>
                <Text>
                  {`R ${numeral(totalRetail * 0.85).format('0,0.00')}`}
                </Text>
              </View>
            </View>
            <View style={styles.evenRow}>
              <View style={styles.salesCol1}>
                <Text>EasyOnline Commission</Text>
              </View>
              <View style={styles.salesCol2}>
                <Text>
                  {`R ${numeral(easyonlineMargin * 0.85).format('0,0.00')}`}
                </Text>
              </View>
            </View>
            <View style={styles.oddRow}>
              <View style={styles.salesCol1}>
                <Text>Courier Fees</Text>
              </View>
              <View style={styles.salesCol2}>
                <Text>
                  {`R ${numeral(totalCourierFees * 0.85).format('0,0.00')}`}
                </Text>
              </View>
            </View>
            <View style={styles.evenRow}>
              <View style={styles.salesCol1}>
                <Text>Takealot-Related Fees</Text>
              </View>
              <View style={styles.salesCol2}>
                <Text>
                  {`R ${numeral(takealotRelatedFees * 0.85).format('0,0.00')}`}
                </Text>
              </View>
            </View>
            <View style={styles.tableFooter}>
              <View style={styles.footer1Col1}>
                <Text>Subtotal Due</Text>
              </View>
              <View style={styles.footer1Col2}>
                <Text>{`R ${numeral(totalDue * 0.85).format('0,0.00')}`}</Text>
              </View>
            </View>
            <View style={styles.tableFooter}>
              <View style={styles.footer2Col1}>
                <Text>VAT 15%</Text>
              </View>
              <View style={styles.footer2Col2}>
                <Text>{`R ${numeral(totalDue * 0.15).format('0,0.00')}`}</Text>
              </View>
            </View>
            <View style={styles.tableFooter}>
              <View style={styles.footer3Col1}>
                <Text>Total Due to You</Text>
              </View>
              <View style={styles.footer3Col2}>
                <Text>{`R ${numeral(totalDue).format('0,0.00')}`}</Text>
              </View>
            </View>
            <View>
              <Text style={styles.finePrint2}>
                Please send us an invoice reflecting this value.
              </Text>
            </View>
          </View>
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

TakealotReconciliation.defaultProps = {
  ourInfo: {},
  clientInfo: {},
  startDate: '01-12-2020',
  endDate: '31-12-2020',
  lineItems: [],
  adjustments: [],
};

TakealotReconciliation.propTypes = {
  ourInfo: PropTypes.shape({
    businessName: PropTypes.string,
    vatNumber: PropTypes.string,
    physicalAddress: PropTypes.string,
    telephoneNumber: PropTypes.string,
  }),
  clientInfo: PropTypes.shape({
    name: PropTypes.string,
    legalEntityName: PropTypes.string,
    vatNumber: PropTypes.string,
    physicalAddress: PropTypes.string,
    telephoneNumber: PropTypes.string,
    mainContact: PropTypes.string,
    emailAddress: PropTypes.string,
  }),
  lineItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      units: PropTypes.number,
      perUnit: PropTypes.number,
      total: PropTypes.number,
    })
  ),
  adjustments: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      total: PropTypes.number,
    })
  ),
  startDate: PropTypes.string,
  endDate: PropTypes.string,
};

export default TakealotReconciliation;
