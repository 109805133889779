import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import {
  closeSnackbar,
  logIn,
  redirectedToPortal,
  resetPassword,
} from './slice';
import logo from './images/logo.png';
import FeedbackSnackbar from '../../components/FeedbackSnackbar';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import LoginForm from './components/LoginForm';

const useStyles = makeStyles((theme) => ({
  sectionLeft: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  sectionRight: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  wrapperIllustration: {
    height: '66%',
    padding: `0 ${theme.spacing(8)}px`,
    borderRight: `1px solid ${grey[900]}`,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
  },
  wrapperRoot: {
    height: '100vh',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    overflow: 'hidden',
  },
}));

const Login = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();

  const feedback = useSelector((state) => state.login.feedback);
  const goToPortal = useSelector((state) => state.login.goToPortal);
  const loadingStatus = useSelector((state) => state.login.loadingStatus);

  useEffect(() => {
    if (goToPortal) {
      history.push('/');
      dispatch(redirectedToPortal());
    }
  }, [goToPortal]);

  const getActiveSection = () => {
    switch (match.path) {
      case '/login':
        return (
          <LoginForm
            isLoading={loadingStatus.signIn === 'PENDING'}
            logIn={(email, password) => dispatch(logIn(email, password))}
            goToForgotPassword={() => history.push('/login/forgot-password')}
          />
        );
      case '/login/forgot-password':
        return (
          <ForgotPasswordForm
            isLoading={loadingStatus.passwordReset === 'PENDING'}
            resetPassword={(email) => dispatch(resetPassword(email))}
            goToLogin={() => history.push('/login')}
          />
        );
      default:
        return (
          <LoginForm
            isLoading={loadingStatus.signIn === 'PENDING'}
            logIn={(email, password) => dispatch(logIn(email, password))}
            goToForgotPassword={() => history.push('/login/forgot-password')}
          />
        );
    }
  };

  return (
    <Grid container className={classes.wrapperRoot}>
      <Grid item xs={7}>
        <div className={classes.sectionLeft}>
          <div className={classes.wrapperIllustration}>
            <div>
              <img src={logo} alt="EasyOnline Logo" />
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={5}>
        <div className={classes.sectionRight}>{getActiveSection()}</div>
      </Grid>
      <FeedbackSnackbar
        isOpen={feedback.isOpen}
        message={feedback.message}
        type={feedback.type}
        closeSnackbar={() => dispatch(closeSnackbar())}
      />
    </Grid>
  );
};

export default Login;
