import { fade, makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const headerStyle = {
  backgroundColor: '#A7A5A5',
  color: '#171415',
};

const useStyles = makeStyles((theme) => ({
  loadingOverlay: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: fade(theme.palette.common.black, 0.8),
  },
  wrapperContent: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
  separator: {
    height: theme.spacing(2),
  },
}));

const ReceivedProductMismatch = ({ skus, updateSkus, note, setNote }) => {
  const classes = useStyles();

  const columns = [
    {
      title: 'SKU',
      field: 'sku',
      editable: 'never',
    },
    {
      title: 'Product',
      field: 'product',
      editable: 'never',
    },
    {
      title: 'Mismatch',
      field: 'mismatchType',
      render: (rowData) => (
        <FormControl>
          <Select
            labelId="type-select-label"
            id="type-select"
            value={rowData.mismatchType}
            onChange={(e) => {
              updateSkus(
                // eslint-disable-next-line react/prop-types
                skus.map((skuInfo) => {
                  const mismatchType = e.target.value;

                  if (skuInfo.sku === rowData.sku) {
                    return {
                      ...skuInfo,
                      mismatchType,
                    };
                  }

                  return skuInfo;
                })
              );
            }}
          >
            <MenuItem value="COLOR">Color</MenuItem>
            <MenuItem value="SIZE">Size</MenuItem>
            <MenuItem value="OTHER">Other</MenuItem>
          </Select>
        </FormControl>
      ),
    },
    {
      title: 'Comment',
      field: 'changeTo',
      render: (rowData) => {
        return (
          <TextField
            value={rowData.changeTo}
            onChange={(e) => {
              updateSkus(
                // eslint-disable-next-line react/prop-types
                skus.map((skuInfo) => {
                  const changeTo = e.target.value;

                  if (skuInfo.sku === rowData.sku) {
                    return {
                      ...skuInfo,
                      changeTo,
                    };
                  }

                  return skuInfo;
                })
              );
            }}
            type="string"
          />
        );
      },
    },
  ];

  return (
    <div className={classes.wrapperContent}>
      <Typography variant="h4" gutterBottom>
        Specify requirements
      </Typography>
      <Typography variant="body1">
        Please specify the changes required in general or for specific SKUs.
      </Typography>
      <div className={classes.separator} />
      <TextField
        required
        label="General notes"
        fullWidth
        multiline
        rows={4}
        value={note}
        onChange={(e) => setNote(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <div className={classes.separator} />
      <MaterialTable
        columns={columns}
        data={skus}
        options={{
          headerStyle,
          sorting: false,
          grouping: false,
          draggable: false,
          search: false,
          showTitle: false,
          toolbar: false,
        }}
        components={{
          OverlayLoading: () => (
            <div className={classes.loadingOverlay}>
              <CircularProgress color="primary" size={32} />
            </div>
          ),
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'SKUs not yet selected.',
          },
        }}
      />
    </div>
  );
};

ReceivedProductMismatch.defaultProps = {
  skus: [],
  updateSkus: (skus) => console.log('Skus were updated to:', skus),
  note: '',
  setNote: (newNote) => console.log('Note updated to:', newNote),
};

ReceivedProductMismatch.propTypes = {
  skus: PropTypes.arrayOf(PropTypes.shape()),
  updateSkus: PropTypes.func,
  note: PropTypes.string,
  setNote: PropTypes.func,
};

export default ReceivedProductMismatch;
