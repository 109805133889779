/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getClients } from '../../../api/clientsAPI';
import { getSkus } from '../../../api/listingsAPI';
import { getWarehouses, saveShipmentInfo } from '../../../api/logisticsAPI';

const initialState = {
  clients: [],
  warehouses: [],
  skus: [],
  loadingStatus: {
    warehouses: 'NOT_STARTED',
    clients: 'NOT_STARTED',
    skus: 'NOT_STARTED',
    saving: 'NOT_STARTED',
  },
};

const shipmentAddSlice = createSlice({
  name: 'shipmentAdd',
  initialState,
  reducers: {
    saveShipmentStarted(state) {
      state.loadingStatus.saving = 'PENDING';
    },
    saveShipmentSuccess(state) {
      state.loadingStatus.saving = 'COMPLETE';
      state.isUserRedirectedToBrowse = true;
    },
    saveShipmentFailed(state, action) {
      state.loadingStatus.saving = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getClientsStarted(state) {
      state.loadingStatus.clients = 'PENDING';
    },
    getClientsSuccess(state, action) {
      state.clients = action.payload;
      state.loadingStatus.clients = 'COMPLETE';
    },
    getClientsFailed(state, action) {
      state.loadingStatus.clients = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getWarehousesStarted(state) {
      state.loadingStatus.warehouses = 'PENDING';
    },
    getWarehousesSuccess(state, action) {
      state.warehouses = action.payload;
      state.loadingStatus.warehouses = 'COMPLETE';
    },
    getWarehousesFailed(state, action) {
      state.loadingStatus.warehouses = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getSkusStarted(state) {
      state.loadingStatus.skus = 'PENDING';
    },
    getSkusSuccess(state, action) {
      state.skus = action.payload;
      state.loadingStatus.skus = 'COMPLETE';
    },
    getSkusFailed(state, action) {
      state.loadingStatus.skus = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    resetState() {
      return initialState;
    },
  },
});

export const {
  saveShipmentStarted,
  saveShipmentSuccess,
  saveShipmentFailed,
  getClientsStarted,
  getClientsSuccess,
  getClientsFailed,
  getWarehousesStarted,
  getWarehousesSuccess,
  getWarehousesFailed,
  getSkusStarted,
  getSkusSuccess,
  getSkusFailed,
  resetState,
} = shipmentAddSlice.actions;

export default shipmentAddSlice.reducer;

export const saveShipment = (shipmentId, shipmentInfo) => async (dispatch) => {
  dispatch(saveShipmentStarted());
  const result = await saveShipmentInfo(shipmentId, shipmentInfo);

  if (result.status === 200) {
    dispatch(saveShipmentSuccess());
  } else {
    dispatch(saveShipmentFailed(result.error));
  }
};

export const fetchClients = () => async (dispatch) => {
  dispatch(getClientsStarted());
  const result = await getClients();

  if (result.status === 200) {
    dispatch(getClientsSuccess(result.clients));
  } else {
    dispatch(getClientsFailed(result.error));
  }
};

export const fetchWarehouses = () => async (dispatch) => {
  dispatch(getWarehousesStarted());
  const result = await getWarehouses();

  if (result.status === 200) {
    dispatch(getWarehousesSuccess(result.warehouses));
  } else {
    dispatch(getWarehousesFailed(result.error));
  }
};

export const fetchSkus = () => async (dispatch) => {
  dispatch(getSkusStarted());
  const result = await getSkus('NOT_SPECIFIED');

  if (result.status === 200) {
    dispatch(getSkusSuccess(result.skus));
  } else {
    dispatch(getSkusFailed(result.error));
  }
};

export const initiateStateReset = () => async (dispatch) => {
  dispatch(resetState());
};
