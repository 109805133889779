import { useEffect, useState } from 'react';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ClearIcon from '@material-ui/icons/Clear';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DoneIcon from '@material-ui/icons/Done';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ThumbDownOutlinedIcon from '@material-ui/icons/ThumbDownOutlined';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import RateReviewIcon from '@material-ui/icons/RateReview';
import PageviewIcon from '@material-ui/icons/Pageview';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { copyStatusUpdate } from '../../slice';

const useStyles = makeStyles((theme) => ({
  contentText: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  dialogContent: {
    padding: theme.spacing(4),
  },
  statusAvatar: {
    backgroundColor: theme.palette.secondary.light,
  },
  statusFinalized: {
    backgroundColor: green[500],
  },
}));

const CopiesCard = ({ copies }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [copyDialog, setCopyDialog] = useState(false);
  const [currentCopyIndex, setCurrentCopyIndex] = useState(0);
  const [reviewStatusAction, setReviewStatusAction] = useState('UNKNOWN');
  const [rejectionMessage, setRejectionMessage] = useState('');
  const reviewCopyStatus = useSelector(
    (state) => state.listingsView.loadingStatus.copyReviewStatus
  );

  useEffect(() => {
    if (reviewStatusAction === 'APPROVED') {
      dispatch(
        copyStatusUpdate(copies[currentCopyIndex].id, reviewStatusAction)
      );
    } else if (reviewStatusAction === 'SAVE') {
      if (rejectionMessage.length > 0) {
        dispatch(
          copyStatusUpdate(
            copies[currentCopyIndex].id,
            'REJECTED',
            rejectionMessage
          )
        );
      }
    }
  }, [reviewStatusAction]);

  useEffect(() => {
    if (reviewCopyStatus === 'COMPLETE') {
      setReviewStatusAction('UNKNOWN');
      setRejectionMessage('');
    }
  }, [reviewCopyStatus]);

  const getStatusText = (status) => {
    switch (status) {
      case 'AWAITING_REVIEW':
        return 'Awaiting Review';
      case 'REJECTED':
        return 'Rejected';
      case 'APPROVED':
        return 'Approved';
      default:
        return 'Unknown';
    }
  };

  const getStatusAvatar = (status) => {
    switch (status) {
      case 'AWAITING_REVIEW':
        return (
          <Avatar className={classes.statusAvatar}>
            <AutorenewIcon />
          </Avatar>
        );
      case 'REJECTED':
        return (
          <Avatar className={classes.statusAvatar}>
            <ClearIcon />
          </Avatar>
        );
      case 'APPROVED':
        return (
          <Avatar className={classes.statusFinalized}>
            <DoneIcon />
          </Avatar>
        );
      default:
        return <Avatar />;
    }
  };
  const displayDialog = (idx) => {
    setCurrentCopyIndex(idx);
    setCopyDialog(true);
  };

  return (
    <Card>
      <CardContent>
        <List>
          {copies
            .slice()
            .sort((infoA, infoB) => {
              if (infoA.version > infoB.version) return +1;
              if (infoA.version < infoB.version) return -1;
              return 0;
            })
            .map((info) => (
              <ListItem key={info.id}>
                <ListItemAvatar>{getStatusAvatar(info.status)}</ListItemAvatar>
                <ListItemText
                  primary={`Copy - Version ${info.version}`}
                  secondary={`${getStatusText(info.status)} | ${
                    info.type === 'NEW' ? 'New' : 'Template'
                  } | ${info.copywriterName}`}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={() => {
                      displayDialog(copies.indexOf(info));
                    }}
                  >
                    {info.status === 'AWAITING_REVIEW' ? (
                      <RateReviewIcon />
                    ) : (
                      <PageviewIcon />
                    )}
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
        </List>
      </CardContent>
      <Dialog open={copyDialog} onClose={() => setCopyDialog(false)}>
        <DialogTitle>{`Copy - Version ${copies[currentCopyIndex].version}`}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText>
            {copies[currentCopyIndex].status === 'REJECTED' && (
              <>
                <Typography variant="h5" component="h2">
                  Reason for Rejection
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  className={classes.contentText}
                >
                  {copies[currentCopyIndex].rejectionReason}
                </Typography>
              </>
            )}
            <Typography variant="h5" component="h2">
              Title
            </Typography>
            <Typography
              variant="body1"
              component="p"
              className={classes.contentText}
            >
              {copies[currentCopyIndex].copy.title}
            </Typography>
            <Typography variant="h5" component="h2">
              Subtitle
            </Typography>
            <Typography
              variant="body1"
              component="p"
              className={classes.contentText}
            >
              {copies[currentCopyIndex].copy.subtitle}
            </Typography>
            <Typography variant="h5" component="h2">
              Description
            </Typography>
            <Typography
              className={classes.contentText}
              variant="body1"
              component="p"
              dangerouslySetInnerHTML={{
                __html: copies[currentCopyIndex].copy.description,
              }}
            />
            <Typography variant="h5" component="h2">
              What&apos;s in the Box
            </Typography>
            <Typography
              className={classes.contentText}
              variant="body1"
              component="p"
              dangerouslySetInnerHTML={{
                __html: copies[currentCopyIndex].copy.whatsInTheBox,
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {copies[currentCopyIndex].status === 'AWAITING_REVIEW' && (
            <>
              <Tooltip title="Approve">
                <IconButton
                  onClick={() => {
                    setReviewStatusAction('APPROVED');
                  }}
                >
                  <ThumbUpOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Reject">
                <IconButton
                  onClick={() => {
                    setReviewStatusAction('REJECTED');
                    setCopyDialog(false);
                  }}
                >
                  <ThumbDownOutlinedIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
          <Button onClick={() => setCopyDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={reviewStatusAction === 'REJECTED'} onClose={setCopyDialog}>
        <DialogTitle>{`Rejection Feedback for Copy - Version ${copies[currentCopyIndex].version}`}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <TextField
            label="Reason"
            fullWidth
            multiline
            rows={4}
            onChange={(e) => setRejectionMessage(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setReviewStatusAction('UNKNOWN')}>
            Close
          </Button>
          <Button
            disabled={rejectionMessage.length === 0}
            variant="contained"
            color="secondary"
            onClick={() => setReviewStatusAction('SAVE')}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

CopiesCard.defaultProps = {
  copies: [
    {
      id: '1',
      version: 0,
      status: 'AWAITING_REVIEW',
      copywriterName: '',
      rejectionReason: '',
    },
  ],
};

CopiesCard.propTypes = {
  copies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      version: PropTypes.number,
      status: PropTypes.oneOf(['AWAITING_REVIEW', 'REJECTED', 'APPROVED']),
      copywriterName: PropTypes.string,
      rejectionReason: PropTypes.string,
      copy: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        subtitle: PropTypes.string,
        whatsInTheBox: PropTypes.string,
      }),
    })
  ),
};

export default CopiesCard;
