import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));

const AdjustBriefingDialog = ({
  isOpen,
  closeDialog,
  sku,
  productName,
  briefing,
  updateBriefing,
}) => {
  const classes = useStyles();

  const [photosRequired, setPhotosRequired] = useState(null);
  const [clientCopyLink, setClientCopyLink] = useState('');
  const [clientImagesLink, setClientImagesLink] = useState('');
  const [specialRequests, setSpecialRequests] = useState('');

  useEffect(() => {
    if (isOpen) {
      setPhotosRequired(briefing.photosRequired);
      setClientCopyLink(briefing.clientCopyLink);
      setClientImagesLink(briefing.clientImagesLink);
      setSpecialRequests(briefing.specialRequests);
    }
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onClose={closeDialog}>
      <DialogTitle>{`Briefing [${sku}]`}</DialogTitle>
      <DialogContent className={classes.root}>
        <DialogContentText>
          {`Adjust the briefing for ${productName} below:`}
        </DialogContentText>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              id="photos-required"
              label="Photos Required"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              value={photosRequired}
              onChange={(e) => setPhotosRequired(parseInt(e.target.value, 10))}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              id="client-images-link"
              label="Client Images Link"
              InputLabelProps={{
                shrink: true,
              }}
              value={clientImagesLink}
              onChange={(e) => setClientImagesLink(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              id="client-copy-link"
              label="Client Copy Link"
              InputLabelProps={{
                shrink: true,
              }}
              value={clientCopyLink}
              onChange={(e) => setClientCopyLink(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="special-requests"
              label="Special Requests"
              InputLabelProps={{
                shrink: true,
              }}
              value={specialRequests}
              onChange={(e) => setSpecialRequests(e.target.value)}
              rows={4}
              multiline
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Close</Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            updateBriefing(
              {
                photosRequired,
                clientImagesLink,
                clientCopyLink,
                specialRequests,
              },
              true
            );
            closeDialog();
          }}
        >
          Bulk Update Client SKUs
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            updateBriefing(
              {
                photosRequired,
                clientImagesLink,
                clientCopyLink,
                specialRequests,
              },
              false
            );
            closeDialog();
          }}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AdjustBriefingDialog.defaultProps = {
  isOpen: true,
  sku: 'KAN-BOO-0101',
  productName: 'adidas - Yeezy Boost 350 - UK7',
  closeDialog: () => console.log('Dialog was closed.'),
  briefing: {
    photosRequired: 3,
    clientImagesLink: 'https://www.google.com/',
    clientCopyLink: '',
    specialRequests: 'Single ladies should win the Best Female Video award.',
  },
  updateBriefing: (briefing, isBulkUpdate) =>
    isBulkUpdate
      ? console.log(
          'User updated the briefing for all clients SKUs to:',
          briefing
        )
      : console.log('User updated the briefing to:', briefing),
};

AdjustBriefingDialog.propTypes = {
  isOpen: PropTypes.bool,
  sku: PropTypes.string,
  productName: PropTypes.string,
  closeDialog: PropTypes.func,
  briefing: PropTypes.shape({
    photosRequired: PropTypes.number,
    clientImagesLink: PropTypes.string,
    clientCopyLink: PropTypes.string,
    specialRequests: PropTypes.string,
  }),
  updateBriefing: PropTypes.func,
};

export default AdjustBriefingDialog;
