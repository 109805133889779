import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';

export const getFormattedListings = (
  skus,
  analyticsData,
  platformFilter = 'TAKEALOT',
  clientFilter,
  brandFilter
) => {
  return skus
    .map((info) => {
      const dailyData = analyticsData.map((allAnalytics) => {
        const skuAnalytics = allAnalytics.find((data) => {
          if (platformFilter === 'TAKEALOT') {
            return info.takealot && data.tsin === info.takealot.tsin;
          }
          return info.amazon && info.amazon.childAsin === data.childAsin;
        });
        if (skuAnalytics) {
          return skuAnalytics;
        }
        return {};
      });

      const unitsSold = dailyData.reduce((total, currentDay) => {
        if (currentDay.unitsSold) {
          return total + currentDay.unitsSold;
        }
        return total;
      }, 0);

      const revenue = dailyData.reduce((total, currentDay) => {
        if (currentDay.revenue) {
          return total + currentDay.revenue;
        }
        return total;
      }, 0);
      const pageViews = dailyData.reduce((total, currentDay) => {
        if (currentDay.pageViews) {
          return total + currentDay.pageViews;
        }
        return total;
      }, 0);
      const conversionRate = pageViews === 0 ? null : unitsSold / pageViews;

      return {
        sku: info.sku,
        tsin: info.takealot.tsin,
        asin: info.amazon.asin,
        barcode: info.takealot.barcode,
        clientName: info.clientName,
        brand: info.brand,
        productName: info.productName,
        variation: info.variation,
        creationDate: info.creationDate || '-',
        unitsSold,
        conversionRate,
        pageViews,
        revenue,
        dailyData,
      };
    })
    .filter((info) => {
      if (clientFilter !== 'ALL') {
        return info.clientName === clientFilter;
      }
      return true;
    })
    .filter((info) => {
      if (brandFilter !== 'ALL') {
        return info.brand === brandFilter;
      }
      return true;
    })
    .sort((a, b) => {
      if (a.creationDate < b.creationDate) return +1;
      if (a.creationDate > b.creationDate) return -1;
      if (a.clientName < b.clientName) return -1;
      if (a.clientName > b.clientName) return +1;
      if (a.brand < b.brand) return -1;
      if (a.brand > b.brand) return +1;
      if (a.productName < b.productName) return -1;
      if (a.productName > b.productName) return +1;
      if (a.variation < b.variation) return -1;
      if (a.variation > b.variation) return +1;
      return 0;
    });
};

export const applyExtraFilters = (
  skus,
  searchQuery = '',
  pageViewsFilter = 'ALL',
  conversionRateFilter = 'ALL'
) => {
  return skus
    .filter((info) => {
      if (searchQuery !== '') {
        const caseInsensitiveSearch = _.toLower(searchQuery);
        const caseInsensitiveSku = _.toLower(info.sku);
        const caseInsensitiveAsin = _.toLower(info.asin);
        const caseInsensitiveTsin = _.toLower(info.tsin);
        const caseInsensitiveVariation = _.toLower(info.variation);
        const caseInsensitiveProductName = _.toLower(info.productName);
        const caseInsensitiveClientName = _.toLower(info.clientName);
        const caseInsensitiveBrand = _.toLower(info.brand);
        const caseInsensitiveBarcode = _.toLower(info.barcode);

        if (caseInsensitiveSku.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveAsin.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveTsin.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveVariation.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveProductName.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveClientName.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveBrand.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveBarcode.includes(caseInsensitiveSearch)) {
          return true;
        }

        return false;
      }

      return true;
    })
    .filter((info) => {
      let returned = true;

      if (conversionRateFilter !== 'ALL') {
        if (conversionRateFilter === 'LESS_THAN_5') {
          returned = returned && info.conversionRate < 0.05;
        } else if (conversionRateFilter === 'MORE_THAN_10') {
          returned = returned && info.conversionRate > 0.1;
        } else {
          returned =
            returned &&
            info.conversionRate <= 0.1 &&
            info.conversionRate >= 0.05;
        }
      }
      if (pageViewsFilter !== 'ALL') {
        if (pageViewsFilter === 'LESS_THAN_25') {
          returned = returned && info.pageViews < 25;
        } else if (pageViewsFilter === 'MORE_THAN_250') {
          returned = returned && info.pageViews > 250;
        } else if (pageViewsFilter === 'BETWEEN_25_AND_250') {
          returned = returned && info.pageViews <= 250 && info.pageViews >= 15;
        } else if (pageViewsFilter === 'LESS_THAN_100') {
          returned = returned && info.pageViews < 100;
        } else if (pageViewsFilter === 'MORE_THAN_1000') {
          returned = returned && info.pageViews > 1000;
        } else {
          returned =
            returned && info.pageViews <= 1000 && info.pageViews >= 100;
        }
      }

      return returned;
    });
};

export const getProductName = (sku, skus) => {
  const skuInfo = skus.find((info) => info.sku === sku);
  if (skuInfo) {
    return skuInfo.variation === 'N/A'
      ? `${skuInfo.brand} - ${skuInfo.productName}`
      : `${skuInfo.brand} - ${skuInfo.productName} (${skuInfo.variation})`;
  }
  return 'Unknown';
};

export const getConversionRateSkuCounts = (skus) => {
  return skus.reduce(
    (totals, current) => {
      const { conversionRate } = current;

      let conversionRateIndex = 0;
      if (conversionRate < 0.05) conversionRateIndex = 0;
      else if (conversionRate >= 0.05 && conversionRate <= 0.1)
        conversionRateIndex = 1;
      else conversionRateIndex = 2;

      const newTotals = [...totals];
      newTotals[conversionRateIndex] += 1;
      return newTotals;
    },
    [0, 0, 0]
  );
};

export const getPageViewsSkuCounts = (skus, rangeInDays) => {
  return skus.reduce(
    (totals, current) => {
      const { pageViews } = current;

      let pageViewIndex = 0;

      if (rangeInDays <= 7) {
        if (pageViews < 25) pageViewIndex = 0;
        else if (pageViews >= 25 && pageViews <= 250) pageViewIndex = 1;
        else pageViewIndex = 2;
      } else if (pageViews < 100) {
        pageViewIndex = 0;
      } else if (pageViews >= 100 && pageViews <= 1000) {
        pageViewIndex = 1;
      } else {
        pageViewIndex = 2;
      }

      const newTotals = [...totals];
      newTotals[pageViewIndex] += 1;
      return newTotals;
    },
    [0, 0, 0]
  );
};

export const findSku = (sku, skus) => {
  const skuInfo = skus.find((info) => info.sku === sku);
  return skuInfo;
};

export const changeDateRange = (
  rangeFilter,
  changeStartDate,
  changeEndDate
) => {
  if (rangeFilter === 'THIS_MONTH') {
    changeStartDate(moment().startOf('month').subtract(1, 'days').toDate());
    changeEndDate(moment().subtract(1, 'days').endOf('day').toDate());
  } else if (rangeFilter === 'THIS_WEEK') {
    changeStartDate(moment().startOf('week').subtract(1, 'days').toDate());
    changeEndDate(moment().subtract(1, 'days').endOf('day').toDate());
  } else if (rangeFilter === 'PAST_7_DAYS') {
    changeStartDate(moment().subtract(8, 'days').toDate());
    changeEndDate(moment().subtract(1, 'days').endOf('day').toDate());
  } else if (rangeFilter === 'PAST_30_DAYS') {
    changeStartDate(moment().subtract(31, 'days').toDate());
    changeEndDate(moment().subtract(1, 'days').endOf('day').toDate());
  }
};

export const getMetricTotals = (formattedSkus, platform) => {
  const metricTotals = formattedSkus.reduce(
    (totals, currentSku) => {
      return [
        totals[0] + currentSku.revenue,
        totals[1] + currentSku.unitsSold,
        totals[2] + currentSku.pageViews,
      ];
    },
    [0, 0, 0]
  );

  return [
    {
      value: `${platform === 'TAKEALOT' ? 'R' : '$'} ${numeral(
        metricTotals[0]
      ).format('0,0')}`,
      label: 'Revenue',
    },
    {
      value: numeral(metricTotals[1]).format('0,0'),
      label: 'Units Sold',
    },
    {
      value: numeral(metricTotals[2]).format('0,0'),
      label: 'Page Views',
    },
    {
      value: `${
        metricTotals[2] === 0
          ? 0
          : Math.round((metricTotals[1] / metricTotals[2]) * 100)
      } %`,
      label: 'Conversion Rate',
    },
  ];
};

export const getLineGraphLabels = (startDate, endDate) => {
  const yesterdaysDateString = moment().subtract(1, 'days').format('DDMMYYYY');
  return _.times(moment(endDate).diff(startDate, 'days')).map((index) => {
    const dateString = moment(startDate)
      .add(index + 1, 'days')
      .format('DDMMYYYY');
    if (dateString === yesterdaysDateString) {
      return 'Yesterday';
    }
    return moment(startDate)
      .add(index + 1, 'days')
      .format('DD/MM/YYYY');
  });
};

export const getLineGraphRevenue = (skus, startDate, endDate) => {
  return _.times(moment(endDate).diff(startDate, 'days')).map((index) => {
    return skus.reduce((total, current) => {
      if (current.dailyData[index] && current.dailyData[index].revenue) {
        return total + current.dailyData[index].revenue;
      }
      return total;
    }, 0);
  });
};

export const getLineGraphUnitsSold = (skus, startDate, endDate) => {
  return _.times(moment(endDate).diff(startDate, 'days')).map((index) => {
    return skus.reduce((total, current) => {
      if (current.dailyData[index] && current.dailyData[index].unitsSold) {
        return total + current.dailyData[index].unitsSold;
      }
      return total;
    }, 0);
  });
};

export const getLineGraphPageViews = (skus, startDate, endDate) => {
  return _.times(moment(endDate).diff(startDate, 'days')).map((index) => {
    return skus.reduce((total, current) => {
      if (current.dailyData[index] && current.dailyData[index].pageViews) {
        return total + current.dailyData[index].pageViews;
      }
      return total;
    }, 0);
  });
};
