/* eslint-disable react/prop-types */
import { fade } from '@material-ui/core/styles/colorManipulator';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';

const headerStyle = {
  backgroundColor: '#A7A5A5',
  color: '#171415',
};

const useStyles = makeStyles((theme) => ({
  loadingOverlay: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: fade(theme.palette.common.black, 0.8),
  },
  wrapperContent: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

const ProductSelectionTable = ({ products, updateSelectedProducts }) => {
  const classes = useStyles();

  const columns = [
    {
      title: 'Description',
      field: 'description',
      editable: 'never',
    },
    {
      title: 'Supplier',
      field: 'supplierName',
      editable: 'never',
    },
    {
      title: 'Cost Price',
      field: 'costPrice',
      editable: 'never',
      render: (rowData) => `R ${rowData.costPrice.toFixed(2)}`,
    },
    {
      title: 'Wholesale Price',
      field: 'wholesalePrice',
      editable: 'never',
      render: (rowData) => `R ${rowData.wholesalePrice.toFixed(2)}`,
    },
    {
      title: 'Retail Price',
      field: 'retailPrice',
      editable: 'never',
      render: (rowData) => `R ${rowData.retailPrice}`,
    },
  ];

  return (
    <div className={classes.wrapperContent}>
      <MaterialTable
        columns={columns}
        data={products}
        options={{
          headerStyle,
          sorting: false,
          grouping: false,
          draggable: false,
          search: false,
          showTitle: false,
          toolbar: false,
          selection: true,
        }}
        components={{
          OverlayLoading: () => (
            <div className={classes.loadingOverlay}>
              <CircularProgress color="primary" size={32} />
            </div>
          ),
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'No available products.',
          },
        }}
        onSelectionChange={(rows) => updateSelectedProducts(rows)}
      />
    </div>
  );
};

ProductSelectionTable.defaultProps = {
  products: [],
  updateSelectedProducts: (rows) =>
    console.log(`Users selected the following rows:`, rows),
};

ProductSelectionTable.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape()),
  updateSelectedProducts: PropTypes.func,
};

export default ProductSelectionTable;
