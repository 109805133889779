import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { fetchSkus, updateInitialPage, updatePageSize } from './slice';
import { getFormattedListings, getClients, getCopywriters } from './logic';
import ContentWrapper from '../../../components/ContentWrapper';
import EmptyViewMessage from '../../../components/EmptyViewMessage';
import FiltersBar from './components/FiltersBar';
import PageHeader from '../../../components/PageHeader';
import SkusTable from './components/SkusTable';

const breadcrumbs = [
  {
    label: 'Listings',
    link: '/listings',
  },
  {
    label: 'Copywriting',
    link: '/listings/copywriting',
  },
];

const ListingsCopyDashboard = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [copywriterFilter, setCopywriterFilter] = useState('ALL');
  const [clientFilter, setClientFilter] = useState('ALL');
  const [statusFilter, setStatusFilter] = useState('ALL');

  const skus = useSelector((state) => state.listingsCopyDashboard.skusList);
  const pageSize = useSelector((state) => state.listingsCopyDashboard.pageSize);
  const initialPage = useSelector(
    (state) => state.listingsCopyDashboard.initialPage
  );
  const skusLoadingStatus = useSelector(
    (state) => state.listingsCopyDashboard.loadingStatus.skus
  );
  const updateSkuLoadingStatus = useSelector(
    (state) => state.listingsCopyDashboard.loadingStatus.updateSku
  );

  useEffect(() => {
    dispatch(fetchSkus());
  }, []);

  useEffect(() => {
    if (
      skusLoadingStatus === 'PENDING' ||
      updateSkuLoadingStatus === 'PENDING'
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [skusLoadingStatus, updateSkuLoadingStatus]);

  return (
    <div>
      <PageHeader
        title="Copywriting Dashboard"
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Dashboard"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
      />
      {isLoading && <LinearProgress color="secondary" />}
      <ContentWrapper>
        {!isLoading && skus.length === 0 ? (
          <EmptyViewMessage
            heading="No SKUs Available"
            message="Please adjust the filters or add new SKU to view them here."
          />
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FiltersBar
                searchText={searchQuery}
                changeSearchText={setSearchQuery}
                listingCount={
                  getFormattedListings(
                    skus,
                    searchQuery,
                    clientFilter,
                    copywriterFilter,
                    statusFilter
                  ).length
                }
                copywriterFilter={copywriterFilter}
                changeCopywriterFilter={(newCopywriter) => {
                  setCopywriterFilter(newCopywriter);
                  setClientFilter('ALL');
                }}
                copywriterOptions={getCopywriters(
                  skus,
                  searchQuery,
                  clientFilter,
                  statusFilter
                )}
                clientFilter={clientFilter}
                changeClientFilter={setClientFilter}
                clientOptions={getClients(
                  skus,
                  searchQuery,
                  copywriterFilter,
                  statusFilter
                )}
                statusFilter={statusFilter}
                changeStatusFilter={setStatusFilter}
              />
              <SkusTable
                isLoading={isLoading}
                skus={getFormattedListings(
                  skus,
                  searchQuery,
                  clientFilter,
                  copywriterFilter,
                  statusFilter
                )}
                viewSku={(sku) =>
                  history.push(`/listings/basic-info/browse/${sku}`)
                }
                pageSize={pageSize}
                initialPage={initialPage}
                updatePageSize={(newPageSize) =>
                  dispatch(updatePageSize(newPageSize))
                }
                updateInitialPage={(newInitialPage) =>
                  dispatch(updateInitialPage(newInitialPage))
                }
              />
            </Grid>
          </Grid>
        )}
      </ContentWrapper>
    </div>
  );
};

export default ListingsCopyDashboard;
