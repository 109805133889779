import { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import numeral from 'numeral';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import { CSVReader } from 'react-papaparse';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import ReplayIcon from '@material-ui/icons/Replay';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  alert: {
    marginTop: theme.spacing(3),
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    padding: theme.spacing(4),
  },
  explanationWrapper: {
    color: grey[700],
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  loadingIndicator: {
    marginBottom: theme.spacing(2),
  },
}));

const getExpectedAmazonFields = () => {
  return [
    'date/time',
    'settlement id',
    'type',
    'order id',
    'sku',
    'description',
    'quantity',
    'marketplace',
    'account type',
    'fulfillment',
    'order city',
    'order state',
    'order postal',
    'tax collection model',
    'product sales',
    'product sales tax',
    'shipping credits',
    'shipping credits tax',
    'gift wrap credits',
    'giftwrap credits tax',
    'Regulatory Fee',
    'Tax On Regulatory Fee',
    'promotional rebates',
    'promotional rebates tax',
    'marketplace withheld tax',
    'selling fees',
    'fba fees',
    'other transaction fees',
    'other',
    'total',
  ];
};

const getExpectedTakealotFields = () => {
  return [
    'Transaction Date',
    'Type',
    'Order ID',
    'SKU',
    'Title',
    'Quantity Sold',
    'Unit Price',
    'Total Sales Value',
    'Seller Earnings',
    'Success Fee',
    'Fulfilment Fee',
    'Courier Collection Fee',
    'Stock Transfer Fee',
    'Accounting Transaction Reference',
  ];
};

const getSteps = () => {
  return [
    'Upload Amazon report',
    'Upload Takealot report',
    'Set report range',
    'Create report',
  ];
};

const getStepExplanation = (stepIndex) => {
  switch (stepIndex) {
    case 0:
      return 'Download the report in the Payment Reports section of Seller Central.';
    case 1:
      return 'Download the report in the Report section of the Takealot Seller portal.';
    case 2:
      return 'Select the date range for the desired statement period.';
    case 3:
      return '';
    default:
      return 'Unknown stepIndex';
  }
};

const getLoadingValue = (loadingCount) => {
  if (loadingCount.total === -1) {
    return -1;
  }
  if (loadingCount.total === 0) {
    return 100;
  }
  return Math.round(
    ((loadingCount.success + loadingCount.failed) / loadingCount.total) * 100
  );
};

const CreateNewStatementReportDialog = ({
  clients,
  loadClients,
  clientLoadingCount,
  failedClientIds,
  loadAmazonSkus,
  amazonSkuLoadingCount,
  amazonFailedSkus,
  amazonSkus,
  loadTakealotSkus,
  takealotSkuLoadingCount,
  takealotFailedSkus,
  takealotSkus,
  isOpen,
  addStatementReport,
  addReportLoadingStatus,
  uploadAmazonStatements,
  amazonStatementsLoadingCount,
  amazonFailedStatements,
  uploadTakealotStatements,
  takealotStatementsLoadingCount,
  takealotFailedStatements,
  closeDialog,
}) => {
  const classes = useStyles();
  const steps = getSteps();

  const amazonUploadRef = useRef();
  const takealotUploadRef = useRef();

  const clientLoadingValue = getLoadingValue(clientLoadingCount);
  const amazonSkuLoadingValue = getLoadingValue(amazonSkuLoadingCount);
  const takealotSkuLoadingValue = getLoadingValue(takealotSkuLoadingCount);
  const amazonStatementsLoadingValue = getLoadingValue(
    amazonStatementsLoadingCount
  );
  const takealotStatementsLoadingValue = getLoadingValue(
    takealotStatementsLoadingCount
  );

  const [activeStep, setActiveStep] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [transactionsViewData, setTransactionsViewData] = useState([]);
  const [ordersDetailedData, setOrdersDetailedData] = useState([]);
  const [uploadError, setUploadError] = useState({
    isError: false,
    message: '',
  });
  const [takealotStatements, setTakealotStatements] = useState([]);
  const [amazonStatements, setAmazonStatements] = useState([]);
  const [amazonClientIds, setAmazonClientIds] = useState([]);
  const [takealotClientIds, setTakealotClientIds] = useState([]);
  const [isNextEnabled, setIsNextEnabled] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setActiveStep(0);
      setStartDate(null);
      setEndDate(null);
      setTransactionsViewData([]);
      setOrdersDetailedData([]);
      setAmazonClientIds([]);
      setTakealotClientIds([]);
      setTakealotStatements([]);
      setAmazonStatements([]);
      setIsNextEnabled(false);
      setUploadError({
        isError: false,
        message: '',
      });
    }
  }, [isOpen]);

  useEffect(() => {
    if (amazonUploadRef.current) {
      amazonUploadRef.current.removeFile();
    }
    if (takealotUploadRef.current) {
      takealotUploadRef.current.removeFile();
    }

    if (activeStep === 3) {
      const getAmazonSkus = () => {
        const amazonSkusToLoad = transactionsViewData.map(
          (skuInfo) => skuInfo.sku
        );

        return amazonSkusToLoad;
      };

      const amazonSkusToLoad = getAmazonSkus();
      loadAmazonSkus(amazonSkusToLoad);
    }

    if (activeStep === 2) {
      setIsNextEnabled(true);
    } else {
      setIsNextEnabled(false);
    }
  }, [activeStep]);

  useEffect(() => {
    if (amazonSkuLoadingValue >= 100 && amazonFailedSkus.length === 0) {
      const getTakealotSkus = () => {
        const takealotSkusToLoad = ordersDetailedData.map(
          (skuInfo) => skuInfo.sku
        );

        return takealotSkusToLoad;
      };

      const takealotSkusToLoad = getTakealotSkus();
      loadTakealotSkus(takealotSkusToLoad);
    }
  }, [amazonSkuLoadingValue]);

  useEffect(() => {
    if (takealotSkuLoadingValue >= 100 && takealotFailedSkus.length === 0) {
      const getClientIds = () => {
        const tempAmazonClientIds = _.uniq(
          amazonSkus.map((skuInfo) => skuInfo.clientId)
        );
        const tempTakealotClientIds = _.uniq(
          takealotSkus.map((skuInfo) => skuInfo.clientId)
        );
        setAmazonClientIds(tempAmazonClientIds);
        setTakealotClientIds(tempTakealotClientIds);
        const allClientIds = _.union(
          tempAmazonClientIds,
          tempTakealotClientIds
        );

        return allClientIds;
      };

      const clientIds = getClientIds();
      loadClients(clientIds);
    }
  }, [takealotSkuLoadingValue]);

  useEffect(() => {
    if (clientLoadingValue >= 100 && failedClientIds.length === 0) {
      const getRelevantSku = (sku, platform) => {
        let relevantSku = {};
        if (platform === 'AMAZON') {
          relevantSku = amazonSkus.find(
            (skuInfo) => skuInfo.amazon.sku === sku
          );
        } else {
          relevantSku = takealotSkus.find(
            (skuInfo) => skuInfo.takealot.sku === sku
          );
        }
        return relevantSku;
      };

      const getName = (skuInfo) => {
        if (skuInfo.variation === 'N/A') {
          return `${skuInfo.brand} - ${skuInfo.productName}`;
        }
        return `${skuInfo.brand} - ${skuInfo.productName} - ${skuInfo.variation}`;
      };

      const getDiscountStatus = (expectedRetail, actualRetail) => {
        if (expectedRetail === actualRetail) {
          return 'STANDARD';
        }
        return 'UNKNOWN';
      };

      const getPerUnit = (skuInfo, platform) => {
        if (platform === 'AMAZON') {
          if (skuInfo.amazon.paymentCalculation === 'WHOLESALE') {
            return numeral(skuInfo.amazon.wholesalePriceRands).value();
          }
          return (
            numeral(skuInfo.amazon.wholesalePriceRands).value() +
            numeral(skuInfo.amazon.logisticsCostRands).value()
          );
        }
        return skuInfo.takealot.wholesalePriceRands;
      };

      const getEasyonlineMargin = (
        platform,
        totalDueToClient,
        totalRevenue,
        revenueAfterFees,
        exchangeRate
      ) => {
        if (platform === 'AMAZON') {
          return (
            (revenueAfterFees * exchangeRate - totalDueToClient) /
            (totalRevenue * exchangeRate)
          );
        }
        return (revenueAfterFees - totalDueToClient) / totalRevenue;
      };

      const getUnknownDiscountCount = (lineItems) => {
        let count = 0;
        lineItems.forEach((lineItem) => {
          if (lineItem.discountStatus === 'UNKNOWN') {
            count += 1;
          }
        });
        return count;
      };

      const getOverallMetrics = (statements, platform) => {
        let count = 0;
        let unknownDiscountCount = 0;
        let totalEasyonlineMargin = 0;
        let totalDueToClient = 0;
        const totalUnitsSold = {
          standardSales: 0,
          discountedSales: 0,
          standardRefunds: 0,
          discountedRefunds: 0,
          total: 0,
        };
        const totalRevenueDollars = {
          standardSales: 0,
          discountedSales: 0,
          standardRefunds: 0,
          discountedRefunds: 0,
          total: 0,
        };
        const totalRevenueRands = {
          standardSales: 0,
          discountedSales: 0,
          standardRefunds: 0,
          discountedRefunds: 0,
          total: 0,
        };

        statements.forEach((statement) => {
          count += 1;
          unknownDiscountCount += statement.summary.unknownDiscountCount;
          totalEasyonlineMargin += statement.summary.avgEasyonlineMargin;
          totalDueToClient += statement.summary.totalDueToClient;

          totalUnitsSold.standardSales +=
            statement.summary.totalUnitsSold.standardSales;
          totalUnitsSold.discountedSales +=
            statement.summary.totalUnitsSold.discountedSales;
          totalUnitsSold.standardRefunds +=
            statement.summary.totalUnitsSold.standardRefunds;
          totalUnitsSold.discountedRefunds +=
            statement.summary.totalUnitsSold.discountedRefunds;
          totalUnitsSold.total += statement.summary.totalUnitsSold.total;

          if (platform === 'AMAZON') {
            totalRevenueDollars.standardSales +=
              statement.summary.totalRevenueDollars.standardSales;
            totalRevenueDollars.discountedSales +=
              statement.summary.totalRevenueDollars.discountedSales;
            totalRevenueDollars.standardRefunds +=
              statement.summary.totalRevenueDollars.standardRefunds;
            totalRevenueDollars.discountedRefunds +=
              statement.summary.totalRevenueDollars.discountedRefunds;
            totalRevenueDollars.total +=
              statement.summary.totalRevenueDollars.total;
          } else {
            totalRevenueRands.standardSales +=
              statement.summary.totalRevenueRands.standardSales;
            totalRevenueRands.discountedSales +=
              statement.summary.totalRevenueRands.discountedSales;
            totalRevenueRands.standardRefunds +=
              statement.summary.totalRevenueRands.standardRefunds;
            totalRevenueRands.discountedRefunds +=
              statement.summary.totalRevenueRands.discountedRefunds;
            totalRevenueRands.total +=
              statement.summary.totalRevenueRands.total;
          }
        });

        if (platform === 'AMAZON') {
          return {
            unknownDiscountCount,
            totalDueToClient,
            totalUnitsSold,
            totalRevenueDollars,
            avgEasyonlineMargin: totalEasyonlineMargin / count,
          };
        }
        return {
          unknownDiscountCount,
          totalDueToClient,
          totalUnitsSold,
          totalRevenueRands,
          avgEasyonlineMargin: totalEasyonlineMargin / count,
        };
      };

      const getAvgEasyonlineMargin = (lineItems) => {
        let total = 0;
        let count = 0;
        lineItems.forEach((lineItem) => {
          count += 1;
          total += lineItem.easyonlineMargin;
        });
        return total / count;
      };

      const getTotalDueToClient = (lineItems) => {
        let total = 0;
        lineItems.forEach((lineItem) => {
          total += lineItem.total;
        });
        return total;
      };

      const getRevenueTotals = (lineItems, platform) => {
        let standardSales = 0;
        let discountedSales = 0;
        let standardRefunds = 0;
        let discountedRefunds = 0;
        let total = 0;

        if (platform === 'AMAZON') {
          lineItems.forEach((lineItem) => {
            if (lineItem.type === 'SALE') {
              if (lineItem.discountStatus === 'STANDARD') {
                standardSales += lineItem.totalRevenueDollars;
              } else {
                discountedSales += lineItem.totalRevenueDollars;
              }
            } else if (lineItem.discountStatus === 'STANDARD') {
              standardRefunds += lineItem.totalRevenueDollars;
            } else {
              discountedRefunds += lineItem.totalRevenueDollars;
            }
            total += lineItem.totalRevenueDollars;
          });
        } else {
          lineItems.forEach((lineItem) => {
            if (lineItem.type === 'SALE') {
              if (lineItem.discountStatus === 'STANDARD') {
                standardSales += lineItem.totalRevenueRands;
              } else {
                discountedSales += lineItem.totalRevenueRands;
              }
            } else if (lineItem.discountStatus === 'STANDARD') {
              standardRefunds += lineItem.totalRevenueRands;
            } else {
              discountedRefunds += lineItem.totalRevenueRands;
            }
            total += lineItem.totalRevenueRands;
          });
        }

        return {
          standardSales,
          discountedSales,
          standardRefunds,
          discountedRefunds,
          total,
        };
      };

      const getUnitTotals = (lineItems) => {
        let standardSales = 0;
        let discountedSales = 0;
        let standardRefunds = 0;
        let discountedRefunds = 0;
        let total = 0;

        lineItems.forEach((lineItem) => {
          if (lineItem.type === 'SALE') {
            if (lineItem.discountStatus === 'STANDARD') {
              standardSales += lineItem.units;
            } else {
              discountedSales += lineItem.units;
            }
          } else if (lineItem.discountStatus === 'STANDARD') {
            standardRefunds += lineItem.units;
          } else {
            discountedRefunds += lineItem.units;
          }
          total += lineItem.units;
        });

        return {
          standardSales,
          discountedSales,
          standardRefunds,
          discountedRefunds,
          total,
        };
      };

      const getAmazonLineItems = (clientId, randDollarExchangeRate) => {
        const clientSkus = amazonSkus.filter(
          (skuInfo) => skuInfo.clientId === clientId
        );
        const clientData = transactionsViewData.filter(
          (transactionInfo) =>
            clientSkus.find(
              (clientSkuInfo) =>
                clientSkuInfo.amazon.sku === transactionInfo.sku
            ) !== undefined
        );

        return _.flatten(
          clientData.map((transactionInfo) => {
            const skuInfo = getRelevantSku(transactionInfo.sku, 'AMAZON');
            const name = getName(skuInfo);
            const refundLineItems = [];
            const saleLineItems = [];

            _.toPairs(transactionInfo.refundLineItems).forEach(
              ([retailPrice, details]) => {
                const expectedRetailPriceDollars =
                  -skuInfo.amazon.retailPriceDollars;
                const actualRetailPriceDollars = numeral(retailPrice).value();
                const discountStatus = getDiscountStatus(
                  expectedRetailPriceDollars,
                  actualRetailPriceDollars
                );
                const perUnit = -getPerUnit(skuInfo, 'AMAZON');
                const revenueAfterFeesDollars = details.revenueAfterFees;
                const totalRevenueDollars = details.total;
                const units = -details.units;
                const total = -(perUnit * units);
                const easyonlineMargin = getEasyonlineMargin(
                  'AMAZON',
                  total,
                  totalRevenueDollars,
                  revenueAfterFeesDollars,
                  randDollarExchangeRate
                );

                refundLineItems.push({
                  expectedRetailPriceDollars,
                  actualRetailPriceDollars,
                  revenueAfterFeesDollars,
                  totalRevenueDollars,
                  name,
                  discountStatus,
                  easyonlineMargin,
                  perUnit,
                  units,
                  total,
                  type: 'REFUND',
                  code: skuInfo.amazon.sku,
                });
              }
            );
            _.toPairs(transactionInfo.standardLineItems).forEach(
              ([retailPrice, details]) => {
                const expectedRetailPriceDollars =
                  skuInfo.amazon.retailPriceDollars;
                const actualRetailPriceDollars = numeral(retailPrice).value();
                const discountStatus = getDiscountStatus(
                  expectedRetailPriceDollars,
                  actualRetailPriceDollars
                );
                const perUnit = getPerUnit(skuInfo, 'AMAZON');
                const revenueAfterFeesDollars = details.revenueAfterFees;
                const totalRevenueDollars = details.total;
                const { units } = details;
                const total = perUnit * units;
                const easyonlineMargin = getEasyonlineMargin(
                  'AMAZON',
                  total,
                  totalRevenueDollars,
                  revenueAfterFeesDollars,
                  randDollarExchangeRate
                );

                saleLineItems.push({
                  expectedRetailPriceDollars,
                  actualRetailPriceDollars,
                  revenueAfterFeesDollars,
                  totalRevenueDollars,
                  name,
                  discountStatus,
                  easyonlineMargin,
                  perUnit,
                  units,
                  total,
                  type: 'SALE',
                  code: skuInfo.amazon.sku,
                });
              }
            );

            return [...refundLineItems, ...saleLineItems];
          })
        );
      };

      const getTakealotLineItems = (clientId) => {
        const clientSkus = takealotSkus.filter(
          (skuInfo) => skuInfo.clientId === clientId
        );
        const clientData = ordersDetailedData.filter(
          (transactionInfo) =>
            clientSkus.find(
              (clientSkuInfo) =>
                clientSkuInfo.takealot.sku === transactionInfo.sku
            ) !== undefined
        );

        return _.flatten(
          clientData.map((transactionInfo) => {
            const skuInfo = getRelevantSku(transactionInfo.sku, 'TAKEALOT');
            const name = getName(skuInfo);
            const refundLineItems = [];
            const saleLineItems = [];

            _.toPairs(transactionInfo.refundLineItems).forEach(
              ([retailPrice, details]) => {
                const expectedRetailPriceRands =
                  -skuInfo.takealot.retailPriceRands;
                const actualRetailPriceRands = -numeral(retailPrice).value();
                const discountStatus = getDiscountStatus(
                  expectedRetailPriceRands,
                  actualRetailPriceRands
                );
                const perUnit = -getPerUnit(skuInfo, 'TAKEALOT');
                const revenueAfterFeesRands = details.revenueAfterFees;
                const totalRevenueRands = details.total;
                const { units } = details;
                const total = -(perUnit * units);
                const easyonlineMargin = getEasyonlineMargin(
                  'TAKEALOT',
                  total,
                  totalRevenueRands,
                  revenueAfterFeesRands
                );

                refundLineItems.push({
                  expectedRetailPriceRands,
                  actualRetailPriceRands,
                  revenueAfterFeesRands,
                  totalRevenueRands,
                  name,
                  discountStatus,
                  easyonlineMargin,
                  perUnit,
                  units,
                  total,
                  type: 'REFUND',
                  code: skuInfo.takealot.sku,
                });
              }
            );
            _.toPairs(transactionInfo.standardLineItems).forEach(
              ([retailPrice, details]) => {
                const expectedRetailPriceRands =
                  skuInfo.takealot.retailPriceRands;
                const actualRetailPriceRands = numeral(retailPrice).value();
                const discountStatus = getDiscountStatus(
                  expectedRetailPriceRands,
                  actualRetailPriceRands
                );
                const perUnit = getPerUnit(skuInfo, 'TAKEALOT');
                const revenueAfterFeesRands = details.revenueAfterFees;
                const totalRevenueRands = details.total;
                const { units } = details;
                const total = perUnit * units;
                const easyonlineMargin = getEasyonlineMargin(
                  'TAKEALOT',
                  total,
                  totalRevenueRands,
                  revenueAfterFeesRands
                );

                saleLineItems.push({
                  expectedRetailPriceRands,
                  actualRetailPriceRands,
                  revenueAfterFeesRands,
                  totalRevenueRands,
                  name,
                  discountStatus,
                  easyonlineMargin,
                  perUnit,
                  units,
                  total,
                  type: 'SALE',
                  code: skuInfo.takealot.sku,
                });
              }
            );

            return [...refundLineItems, ...saleLineItems];
          })
        );
      };

      const createStatementReport = () => {
        const randDollarExchangeRate = 14.0;

        const newAmazonStatements = amazonClientIds.map((clientId) => {
          const clientInfo = clients.find((info) => info.id === clientId);
          const lineItems = getAmazonLineItems(
            clientId,
            randDollarExchangeRate
          );

          return {
            clientId,
            status: 'IN_PROGRESS',
            clientInfo: {
              name: clientInfo.name,
              legalEntityName: clientInfo.billingInfo.legalEntityName,
              emailAddress: clientInfo.billingInfo.emailAddress,
              mainContact: clientInfo.billingInfo.mainContact,
              vatNumber: clientInfo.billingInfo.vatNumber,
              physicalAddress: clientInfo.billingInfo.physicalAddress,
              telephoneNumber: clientInfo.billingInfo.telephoneNumber,
            },
            summary: {
              unknownDiscountCount: getUnknownDiscountCount(lineItems),
              skuCount: amazonSkus.length,
              totalRevenueDollars: getRevenueTotals(lineItems, 'AMAZON'),
              totalUnitsSold: getUnitTotals(lineItems),
              avgEasyonlineMargin: getAvgEasyonlineMargin(lineItems),
              totalDueToClient: getTotalDueToClient(lineItems),
              randDollarExchangeRate,
            },
            lineItems,
          };
        });

        const newTakealotStatements = takealotClientIds.map((clientId) => {
          const clientInfo = clients.find((info) => info.id === clientId);
          const lineItems = getTakealotLineItems(clientId);

          return {
            clientId,
            status: 'IN_PROGRESS',
            clientInfo: {
              name: clientInfo.name,
              legalEntityName: clientInfo.billingInfo.legalEntityName,
              emailAddress: clientInfo.billingInfo.emailAddress,
              mainContact: clientInfo.billingInfo.mainContact,
              vatNumber: clientInfo.billingInfo.vatNumber,
              physicalAddress: clientInfo.billingInfo.physicalAddress,
              telephoneNumber: clientInfo.billingInfo.telephoneNumber,
            },
            summary: {
              unknownDiscountCount: getUnknownDiscountCount(lineItems),
              skuCount: amazonSkus.length,
              totalRevenueRands: getRevenueTotals(lineItems, 'TAKEALOT'),
              totalUnitsSold: getUnitTotals(lineItems),
              avgEasyonlineMargin: getAvgEasyonlineMargin(lineItems),
              totalDueToClient: getTotalDueToClient(lineItems),
            },
            lineItems,
          };
        });

        addStatementReport({
          version: 2,
          startDate: startDate.toDate(),
          endDate: endDate.toDate(),
          status: 'IN_PROGRESS',
          bankDetails: {
            businessName: 'EASYONLINE(PTY)LTD',
            vatNumber: '4620291122',
            physicalAddress:
              'Unit 10, Sundowner Plaza, Taurus Road, Sundowner, 2161',
            telephoneNumber: '+27 11 730 8247',
          },
          amazonSummary: {
            clientCount: amazonClientIds.length,
            skuCount: amazonSkus.length,
            ...getOverallMetrics(_.values(newAmazonStatements), 'AMAZON'),
          },
          takealotSummary: {
            clientCount: takealotClientIds.length,
            skuCount: takealotSkus.length,
            ...getOverallMetrics(_.values(newTakealotStatements), 'TAKEALOT'),
          },
        });
        setTakealotStatements(newTakealotStatements);
        setAmazonStatements(newAmazonStatements);
      };

      createStatementReport();
    }
  }, [clientLoadingValue]);

  useEffect(() => {
    if (addReportLoadingStatus === 'COMPLETE') {
      uploadAmazonStatements(
        `${startDate.format('YYYYMMDD')}-${endDate.format('YYYYMMDD')}`,
        amazonStatements
      );
    }
  }, [addReportLoadingStatus]);

  useEffect(() => {
    if (
      amazonStatementsLoadingValue >= 100 &&
      amazonFailedStatements.length === 0
    ) {
      uploadTakealotStatements(
        `${startDate.format('YYYYMMDD')}-${endDate.format('YYYYMMDD')}`,
        takealotStatements
      );
    }
  }, [amazonStatementsLoadingValue]);

  useEffect(() => {
    if (
      takealotStatementsLoadingValue >= 100 &&
      takealotFailedStatements.length === 0
    ) {
      setIsNextEnabled(true);
    }
  }, [takealotStatementsLoadingValue]);

  const checkFields = (fields, expectedFields) => {
    let isCorrect = true;
    fields.forEach((field, index) => {
      if (field !== expectedFields[index]) {
        isCorrect = false;
      }
    });
    return isCorrect;
  };

  const formatTransactionsViewData = (data) => {
    const fields = data[7].data;
    const isFieldsCorrect = checkFields(fields, getExpectedAmazonFields());

    if (isFieldsCorrect) {
      setUploadError({
        isError: false,
        message: '',
      });

      const transactionDates = data
        .map((row, index) => {
          if (index < 8) {
            return false;
          }

          const values = row.data;
          if (values.length !== fields.length) {
            return false;
          }

          return moment(values[0], 'MMM D, YYYY h:mm:ss A z').format(
            'MMM D, YYYY'
          );
        })
        .filter((rowInfo) => rowInfo !== false)
        .filter((rowInfo) => rowInfo.sku !== '')
        .sort((rowA, rowB) => {
          if (rowA < rowB) return -1;
          if (rowA > rowB) return +1;
          return 0;
        });

      const orderIds = data
        .filter(
          (rowInfo) =>
            rowInfo.data[2] === 'Order' || rowInfo.data[2] === 'Refund'
        )
        .map((row, rowIndex) => {
          if (rowIndex < 8) {
            return false;
          }

          const values = row.data;
          if (values.length !== fields.length) {
            return false;
          }

          if (values[2] === 'Refund') {
            return `${values[3]}-refund`;
          }
          return values[3];
        })
        .filter((orderId) => typeof orderId === 'string');

      const skus = _.union(
        data
          .filter((rowInfo) => rowInfo.data[2] !== '')
          .map((row, rowIndex) => {
            if (rowIndex < 8) {
              return false;
            }

            const values = row.data;
            if (values.length !== fields.length) {
              return false;
            }

            return values[4];
          })
          .filter((sku) => typeof sku === 'string')
      );

      const groupedByOrders = orderIds
        .map((orderId) => {
          let totalEarnings = 0;
          let totalRevenue = 0;
          let totalUnits = 0;
          let type = 'STANDARD';

          const relevantTransactions = data
            .filter((rowInfo) => {
              if (orderId.includes('refund') && rowInfo.data[2] === 'Refund') {
                type = 'REFUND';

                return (
                  `${rowInfo.data[3]}-refund` === orderId &&
                  rowInfo.data[2] === 'Refund'
                );
              }

              return rowInfo.data[3] === orderId;
            })
            .map((rowInfo) => {
              const transactionInfo = rowInfo.data;

              totalEarnings += numeral(transactionInfo[27]).value();
              totalRevenue += numeral(transactionInfo[14]).value();
              totalUnits += numeral(transactionInfo[6]).value();

              return transactionInfo;
            });

          const sku = relevantTransactions[0][4];
          const name = relevantTransactions[0][5];

          return {
            orderId,
            sku,
            type,
            name,
            revenueAfterFees: totalEarnings,
            units: totalUnits,
            total: totalRevenue,
            expectedRetailPriceDollars: undefined,
            actualRetailPriceDollars: totalRevenue / totalUnits,
          };
        })
        .filter((orderInfo) => orderInfo.total !== 0);

      const groupedBySkus = skus
        .map((sku) => {
          const standardLineItems = {};
          const refundLineItems = {};
          let name = '';

          groupedByOrders
            .filter((orderInfo) => orderInfo.sku === sku)
            .forEach((orderInfo) => {
              name = orderInfo.name;

              if (orderInfo.type === 'STANDARD') {
                if (standardLineItems[orderInfo.actualRetailPriceDollars]) {
                  standardLineItems[
                    orderInfo.actualRetailPriceDollars
                  ].revenueAfterFees += orderInfo.revenueAfterFees;
                  standardLineItems[orderInfo.actualRetailPriceDollars].units +=
                    orderInfo.units;
                  standardLineItems[orderInfo.actualRetailPriceDollars].total +=
                    orderInfo.total;
                } else {
                  standardLineItems[orderInfo.actualRetailPriceDollars] = {
                    expectedRetailPriceDollars: undefined,
                    revenueAfterFees: orderInfo.revenueAfterFees,
                    units: orderInfo.units,
                    total: orderInfo.total,
                  };
                }
              } else if (orderInfo.type === 'REFUND') {
                if (refundLineItems[orderInfo.actualRetailPriceDollars]) {
                  refundLineItems[
                    orderInfo.actualRetailPriceDollars
                  ].revenueAfterFees += orderInfo.revenueAfterFees;
                  refundLineItems[orderInfo.actualRetailPriceDollars].units +=
                    orderInfo.units;
                  refundLineItems[orderInfo.actualRetailPriceDollars].total +=
                    orderInfo.total;
                } else {
                  refundLineItems[orderInfo.actualRetailPriceDollars] = {
                    expectedRetailPriceDollars: undefined,
                    revenueAfterFees: orderInfo.revenueAfterFees,
                    units: orderInfo.units,
                    total: orderInfo.total,
                  };
                }
              }
            });
          return {
            sku,
            name,
            standardLineItems,
            refundLineItems,
          };
        })
        .filter((skuInfo) => skuInfo.name !== '');

      setStartDate(moment(transactionDates[0], 'MMM D, YYYY'));
      setEndDate(
        moment(transactionDates[transactionDates.length - 1], 'MMM D, YYYY')
      );

      setTransactionsViewData(groupedBySkus);
      setIsNextEnabled(true);
    } else {
      setUploadError({
        isError: true,
        message:
          'Incorrect report uploaded. Please upload the transactions view report.',
      });
    }
  };

  const formatOrdersDetailedData = (data) => {
    const fields = data[0].data;
    const isFieldsCorrect = checkFields(fields, getExpectedTakealotFields());

    if (isFieldsCorrect) {
      setUploadError({
        isError: false,
        message: '',
      });

      const transactionDates = data
        .map((row, index) => {
          if (index < 2) {
            return false;
          }

          const values = row.data;
          if (values.length !== fields.length) {
            return false;
          }

          return moment(values[0], 'YYYY-MM-DD');
        })
        .filter((rowInfo) => rowInfo !== false)
        .filter((rowInfo) => rowInfo.SKU !== '')
        .sort((rowA, rowB) => {
          if (rowA < rowB) return -1;
          if (rowA > rowB) return +1;
          return 0;
        });

      const skus = _.union(
        data
          .filter((rowInfo) => rowInfo.data[3] !== '')
          .map((row, rowIndex) => {
            if (rowIndex < 2) {
              return false;
            }

            const values = row.data;
            if (values.length !== fields.length) {
              return false;
            }

            return values[3];
          })
          .filter((rowInfo) => rowInfo !== false)
      );

      const groupedBySkus = skus
        .map((sku) => {
          const standardLineItems = {};
          const refundLineItems = {};
          let name = '';

          data
            .filter((rowInfo) => rowInfo.data[3] === sku)
            .forEach((rowInfo) => {
              // eslint-disable-next-line prefer-destructuring
              name = rowInfo.data[4];
              const units = numeral(rowInfo.data[5]).value();
              const actualRetailPriceRands = numeral(rowInfo.data[6]).value();
              const total = numeral(rowInfo.data[7]).value();
              const revenueAfterFees = numeral(rowInfo.data[8]).value();

              if (rowInfo.data[1] === 'Order') {
                if (standardLineItems[actualRetailPriceRands]) {
                  standardLineItems[actualRetailPriceRands].revenueAfterFees +=
                    revenueAfterFees;
                  standardLineItems[actualRetailPriceRands].units += units;
                  standardLineItems[actualRetailPriceRands].total += total;
                } else {
                  standardLineItems[actualRetailPriceRands] = {
                    revenueAfterFees,
                    units,
                    total,
                    expectedRetailPriceRands: undefined,
                  };
                }
              } else if (rowInfo.data[1] === 'Return') {
                if (refundLineItems[actualRetailPriceRands]) {
                  refundLineItems[actualRetailPriceRands].revenueAfterFees +=
                    revenueAfterFees;
                  refundLineItems[actualRetailPriceRands].units += units;
                  refundLineItems[actualRetailPriceRands].total += total;
                } else {
                  refundLineItems[actualRetailPriceRands] = {
                    revenueAfterFees,
                    units,
                    total,
                    expectedRetailPriceRands: undefined,
                  };
                }
              }
            });
          return {
            sku,
            name,
            standardLineItems,
            refundLineItems,
          };
        })
        .filter((skuInfo) => skuInfo.name !== '');

      if (transactionDates[0].isBefore(startDate)) {
        setStartDate(moment(transactionDates[0]));
      }
      if (transactionDates[transactionDates.length - 1].isAfter(endDate)) {
        setEndDate(moment(transactionDates[transactionDates.length - 1]));
      }

      setOrdersDetailedData(groupedBySkus);
      setIsNextEnabled(true);
    } else {
      setUploadError({
        isError: true,
        message:
          'Incorrect report uploaded. Please upload the orders detailed report.',
      });
    }
  };

  const handleAmazonFileLoad = (data) => {
    formatTransactionsViewData(data);
  };

  const handleTakealotFileLoad = (data) => {
    formatOrdersDetailedData(data);
  };

  const handleAmazonError = (err) => {
    console.log(err);
  };

  const handleTakealotError = (err) => {
    console.log(err);
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <CSVReader
            ref={amazonUploadRef}
            onDrop={handleAmazonFileLoad}
            onError={handleAmazonError}
            addRemoveButton
          >
            <span>Drop report here or click to upload.</span>
          </CSVReader>
        );
      case 1:
        return (
          <CSVReader
            ref={takealotUploadRef}
            onDrop={handleTakealotFileLoad}
            onError={handleTakealotError}
            addRemoveButton
          >
            <span>Drop report here or click to upload.</span>
          </CSVReader>
        );
      case 2:
        return (
          <Grid container justify="space-around">
            {startDate !== null && (
              <TextField
                id="date"
                label="Start Date"
                type="date"
                value={startDate.format('YYYY-MM-DD')}
                onChange={(e) => setStartDate(moment(e.target.value))}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            {endDate !== null && (
              <TextField
                id="date"
                label="End Date"
                type="date"
                value={endDate.format('YYYY-MM-DD')}
                onChange={(e) => setEndDate(moment(e.target.value))}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          </Grid>
        );
      case 3:
        return (
          <div>
            {amazonSkuLoadingValue < 100 && amazonSkuLoadingValue !== -1 && (
              <div>
                <LinearProgress
                  color="secondary"
                  className={classes.loadingIndicator}
                  variant="determinate"
                  value={amazonSkuLoadingValue}
                />
                <Typography component="p" variant="subtitle1" align="center">
                  Fetching Amazon product info...
                </Typography>
              </div>
            )}
            {amazonFailedSkus.length > 0 && (
              <div>
                <Alert className={classes.alert} severity="error">
                  {`Could not find ${amazonFailedSkus.length} skus.`}
                </Alert>
                <List>
                  {amazonFailedSkus.map((sku) => (
                    <ListItem key={sku}>
                      <ListItemText primary={sku} />
                    </ListItem>
                  ))}
                </List>
              </div>
            )}
            {takealotSkuLoadingValue < 100 &&
              takealotSkuLoadingValue !== -1 && (
                <div>
                  <LinearProgress
                    color="secondary"
                    className={classes.loadingIndicator}
                    variant="determinate"
                    value={takealotSkuLoadingValue}
                  />
                  <Typography component="p" variant="subtitle1" align="center">
                    Fetching Takealot product info...
                  </Typography>
                </div>
              )}
            {takealotFailedSkus.length > 0 && (
              <div>
                <Alert className={classes.alert} severity="error">
                  {`Could not find ${takealotFailedSkus.length} skus.`}
                </Alert>
                <List>
                  {takealotFailedSkus.map((sku) => (
                    <ListItem key={sku}>
                      <ListItemText primary={sku} />
                    </ListItem>
                  ))}
                </List>
              </div>
            )}
            {clientLoadingValue < 100 && clientLoadingValue !== -1 && (
              <div>
                <LinearProgress
                  color="secondary"
                  className={classes.loadingIndicator}
                  variant="determinate"
                  value={clientLoadingValue}
                />
                <Typography component="p" variant="subtitle1" align="center">
                  Fetching client info...
                </Typography>
              </div>
            )}
            {failedClientIds.length > 0 && (
              <div>
                <Alert className={classes.alert} severity="error">
                  {`Could not find ${failedClientIds.length} clients.`}
                </Alert>
                <List>
                  {failedClientIds.map((clientId) => (
                    <ListItem key={clientId}>
                      <ListItemText primary={clientId} />
                    </ListItem>
                  ))}
                </List>
              </div>
            )}
            {addReportLoadingStatus === 'PENDING' && (
              <div>
                <LinearProgress
                  color="secondary"
                  className={classes.loadingIndicator}
                  variant="indeterminate"
                />
                <Typography component="p" variant="subtitle1" align="center">
                  Performing calculations...
                </Typography>
              </div>
            )}
            {amazonStatementsLoadingValue < 100 &&
              amazonStatementsLoadingValue !== -1 && (
                <div>
                  <LinearProgress
                    color="secondary"
                    className={classes.loadingIndicator}
                    variant="determinate"
                    value={amazonStatementsLoadingValue}
                  />
                  <Typography component="p" variant="subtitle1" align="center">
                    Uploading Amazon statements...
                  </Typography>
                </div>
              )}
            {amazonFailedStatements.length > 0 && (
              <div>
                <Alert className={classes.alert} severity="error">
                  {`${amazonFailedStatements.length} statements failed to upload.`}
                </Alert>
                <Button
                  fullWidth
                  color="secondary"
                  variant="contained"
                  onClick={() => uploadAmazonStatements(amazonFailedStatements)}
                >
                  <ReplayIcon className={classes.buttonIcon} />
                  Retry
                </Button>
              </div>
            )}
            {takealotStatementsLoadingValue < 100 &&
              takealotStatementsLoadingValue !== -1 && (
                <div>
                  <LinearProgress
                    color="secondary"
                    className={classes.loadingIndicator}
                    variant="determinate"
                    value={takealotStatementsLoadingValue}
                  />
                  <Typography component="p" variant="subtitle1" align="center">
                    Uploading Takealot statements...
                  </Typography>
                </div>
              )}
            {takealotFailedStatements.length > 0 && (
              <div>
                <Alert className={classes.alert} severity="error">
                  {`${takealotFailedStatements.length} statements failed to upload.`}
                </Alert>
                <Button
                  fullWidth
                  color="secondary"
                  variant="contained"
                  onClick={() =>
                    uploadTakealotStatements(takealotFailedStatements)
                  }
                >
                  <ReplayIcon className={classes.buttonIcon} />
                  Retry
                </Button>
              </div>
            )}
            {takealotFailedStatements.length === 0 &&
              takealotStatementsLoadingValue >= 100 && (
                <div>
                  <Alert className={classes.alert} severity="success">
                    Statement report has been created.
                  </Alert>
                </div>
              )}
          </div>
        );
      default:
        return 'Unknown stepIndex';
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return (
    <Dialog open={isOpen}>
      <DialogTitle>Create New Statement Report</DialogTitle>
      <DialogContent className={classes.contentWrapper}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <DialogContentText className={classes.explanationWrapper}>
          {getStepExplanation(activeStep)}
        </DialogContentText>
        {getStepContent(activeStep)}
        {uploadError.isError && (
          <Alert className={classes.alert} severity="error">
            {uploadError.message}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        {activeStep < 3 && (
          <Button onClick={closeDialog} color="primary">
            Cancel
          </Button>
        )}
        <Button
          disabled={uploadError.isError || !isNextEnabled}
          onClick={activeStep === steps.length - 1 ? closeDialog : handleNext}
          variant="contained"
          color="secondary"
        >
          {activeStep === steps.length - 1 ? 'Done' : 'Next'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CreateNewStatementReportDialog.defaultProps = {
  isOpen: true,
  clientLoadingCount: {
    total: 0,
    success: 0,
    failed: 0,
  },
  failedClientIds: [],
  loadClients: (clientIdsList) =>
    console.log('User loaded the following clients: ', clientIdsList),
  clients: [],
  amazonSkuLoadingCount: {
    total: 0,
    success: 0,
    failed: 0,
  },
  amazonFailedSkus: [],
  amazonSkus: [],
  loadAmazonSkus: (skusList) =>
    console.log('User loaded the following skus: ', skusList),
  takealotSkuLoadingCount: {
    total: 0,
    success: 0,
    failed: 0,
  },
  takealotFailedSkus: [],
  takealotSkus: [],
  addReportLoadingStatus: 'NOT_STARTED',
  uploadAmazonStatements: (statements) =>
    console.log('User loaded the following statements: ', statements),
  amazonStatementsLoadingCount: {
    total: 0,
    success: 0,
    failed: 0,
  },
  amazonFailedStatements: [],
  uploadTakealotStatements: (statements) =>
    console.log('User loaded the following statements: ', statements),
  takealotStatementsLoadingCount: {
    total: 0,
    success: 0,
    failed: 0,
  },
  takealotFailedStatements: [],
  loadTakealotSkus: (skusList) =>
    console.log('User loaded the following skus: ', skusList),
  addStatementReport: (reportInfo) =>
    console.log('User created the following report: ', reportInfo),
  closeDialog: () => console.log('Create statement report dialog was closed.'),
};

CreateNewStatementReportDialog.propTypes = {
  isOpen: PropTypes.bool,
  clientLoadingCount: PropTypes.shape({
    total: PropTypes.number,
    success: PropTypes.number,
    failed: PropTypes.number,
  }),
  failedClientIds: PropTypes.arrayOf(PropTypes.string),
  loadClients: PropTypes.func,
  clients: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      billingInfo: PropTypes.shape({
        legalEntityName: PropTypes.string,
        telephoneNumber: PropTypes.string,
        vatNumber: PropTypes.string,
        mainContact: PropTypes.string,
        emailAddress: PropTypes.string,
        physicalAddress: PropTypes.string,
      }),
      status: PropTypes.oneOf(['HIGH_PRIORITY', 'TRADE_OUT', 'TERMINATED']),
    })
  ),
  amazonSkuLoadingCount: PropTypes.shape({
    total: PropTypes.number,
    success: PropTypes.number,
    failed: PropTypes.number,
  }),
  amazonFailedSkus: PropTypes.arrayOf(PropTypes.string),
  amazonSkus: PropTypes.arrayOf(
    PropTypes.shape({
      amazon: PropTypes.shape({
        barcode: PropTypes.string,
        childAsin: PropTypes.string,
        parentAsin: PropTypes.string,
        sku: PropTypes.string,
        status: PropTypes.string,
        wholesalePrice: PropTypes.number,
        retailPrice: PropTypes.number,
      }),
      brand: PropTypes.string,
      clientId: PropTypes.string,
      clientName: PropTypes.string,
      productName: PropTypes.string,
      takealot: PropTypes.shape({
        barcode: PropTypes.string,
        tsin: PropTypes.string,
        sku: PropTypes.string,
        status: PropTypes.string,
        wholesalePrice: PropTypes.number,
        retailPrice: PropTypes.number,
      }),
      variantion: PropTypes.string,
    })
  ),
  loadAmazonSkus: PropTypes.func,
  takealotSkuLoadingCount: PropTypes.shape({
    total: PropTypes.number,
    success: PropTypes.number,
    failed: PropTypes.number,
  }),
  takealotFailedSkus: PropTypes.arrayOf(PropTypes.string),
  takealotSkus: PropTypes.arrayOf(
    PropTypes.shape({
      amazon: PropTypes.shape({
        barcode: PropTypes.string,
        childAsin: PropTypes.string,
        parentAsin: PropTypes.string,
        sku: PropTypes.string,
        status: PropTypes.string,
        wholesalePrice: PropTypes.number,
        retailPrice: PropTypes.number,
      }),
      brand: PropTypes.string,
      clientId: PropTypes.string,
      clientName: PropTypes.string,
      productName: PropTypes.string,
      takealot: PropTypes.shape({
        barcode: PropTypes.string,
        tsin: PropTypes.string,
        sku: PropTypes.string,
        status: PropTypes.string,
        wholesalePrice: PropTypes.number,
        retailPrice: PropTypes.number,
      }),
      variantion: PropTypes.string,
    })
  ),
  amazonStatementsLoadingCount: PropTypes.shape({
    total: PropTypes.number,
    success: PropTypes.number,
    failed: PropTypes.number,
  }),
  amazonFailedStatements: PropTypes.arrayOf(PropTypes.string),
  takealotStatementsLoadingCount: PropTypes.shape({
    total: PropTypes.number,
    success: PropTypes.number,
    failed: PropTypes.number,
  }),
  takealotFailedStatements: PropTypes.arrayOf(PropTypes.string),
  uploadAmazonStatements: PropTypes.func,
  uploadTakealotStatements: PropTypes.func,
  loadTakealotSkus: PropTypes.func,
  addStatementReport: PropTypes.func,
  addReportLoadingStatus: PropTypes.string,
  closeDialog: PropTypes.func,
};

export default CreateNewStatementReportDialog;
