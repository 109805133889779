import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  results: {
    margin: theme.spacing(1),
    color: grey[800],
  },
  separator: {
    width: theme.spacing(1),
  },
  wrapperButtons: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  wrapperRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
}));

const FiltersBar = ({ searchText, changeSearchText, skuCount }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapperRoot}>
      <TextField
        placeholder="Search"
        value={searchText}
        onChange={(e) => changeSearchText(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <Typography className={classes.results} variant="body1" component="span">
        {`${skuCount} ${skuCount === 1 ? 'result' : 'results'} displayed`}
      </Typography>
    </div>
  );
};

FiltersBar.defaultProps = {
  searchText: '',
  changeSearchText: (searchText) =>
    console.log('User searched for:', searchText),
  skuCount: 0,
};

FiltersBar.propTypes = {
  searchText: PropTypes.string,
  changeSearchText: PropTypes.func,
  skuCount: PropTypes.number,
};

export default FiltersBar;
