/* eslint-disable react/prop-types */
import _ from 'lodash';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import MaterialTable from 'material-table';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';

const headerStyle = {
  backgroundColor: '#A7A5A5',
  color: '#171415',
};

const useStyles = makeStyles((theme) => ({
  loadingOverlay: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: fade(theme.palette.common.black, 0.8),
  },
  wrapperContent: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

const SKUCategoryTable = ({ skus, updateSkus, categoryData }) => {
  const classes = useStyles();

  const columns = [
    {
      title: 'Description',
      field: 'description',
      editable: 'never',
    },
    {
      title: 'Department',
      field: 'department',
      render: (rowData) => {
        const departments = _.uniq(
          categoryData.map((data) => data.department)
        ).sort((departmentA, departmentB) => {
          if (departmentA > departmentB) return +1;
          if (departmentA < departmentB) return -1;
          return 0;
        });

        return (
          <Select
            fullWidth
            value={rowData.department}
            onChange={(e) => {
              updateSkus(
                skus.map((skuInfo) => {
                  if (skuInfo.id === rowData.id) {
                    return {
                      ...skuInfo,
                      department: e.target.value,
                      category: undefined,
                      subCategory: undefined,
                    };
                  }
                  return skuInfo;
                })
              );
            }}
          >
            {departments.map((department) => (
              <MenuItem key={`department-${department}`} value={department}>
                {department}
              </MenuItem>
            ))}
          </Select>
        );
      },
    },
    {
      title: 'Category',
      field: 'category',
      render: (rowData) => {
        const categories = _.uniq(
          categoryData
            .filter((data) => data.department === rowData.department)
            .map((data) => data.category)
        ).sort((categoryA, categoryB) => {
          if (categoryA > categoryB) return +1;
          if (categoryA < categoryB) return -1;
          return 0;
        });

        return (
          <Select
            fullWidth
            disabled={rowData.department === undefined}
            value={rowData.category}
            onChange={(e) => {
              updateSkus(
                skus.map((skuInfo) => {
                  if (skuInfo.id === rowData.id) {
                    return {
                      ...skuInfo,
                      category: e.target.value,
                      subCategory: undefined,
                    };
                  }
                  return skuInfo;
                })
              );
            }}
          >
            {categories.map((category) => (
              <MenuItem key={`category-${category}`} value={category}>
                {category}
              </MenuItem>
            ))}
          </Select>
        );
      },
    },
    {
      title: 'Sub-Category',
      field: 'subCategory',
      render: (rowData) => {
        const subCategories = _.uniq(
          categoryData
            .filter(
              (data) =>
                data.department === rowData.department &&
                data.category === rowData.category
            )
            .map((data) => data.subCategory)
        ).sort((subCategoryA, subCategoryB) => {
          if (subCategoryA > subCategoryB) return +1;
          if (subCategoryA < subCategoryB) return -1;
          return 0;
        });

        return (
          <Select
            fullWidth
            disabled={
              rowData.department === undefined || rowData.category === undefined
            }
            value={rowData.subCategory}
            onChange={(e) => {
              updateSkus(
                skus.map((skuInfo) => {
                  if (skuInfo.id === rowData.id) {
                    return {
                      ...skuInfo,
                      subCategory: e.target.value,
                    };
                  }
                  return skuInfo;
                })
              );
            }}
          >
            {subCategories.map((subCategory) => (
              <MenuItem key={`subcategory-${subCategory}`} value={subCategory}>
                {subCategory}
              </MenuItem>
            ))}
          </Select>
        );
      },
    },
  ];

  return (
    <div className={classes.wrapperContent}>
      <MaterialTable
        columns={columns}
        data={skus}
        options={{
          headerStyle,
          sorting: false,
          grouping: false,
          draggable: false,
          search: false,
          showTitle: false,
          toolbar: false,
          paging: false,
        }}
        components={{
          OverlayLoading: () => (
            <div className={classes.loadingOverlay}>
              <CircularProgress color="primary" size={32} />
            </div>
          ),
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'No Takealot SKUs.',
          },
        }}
      />
    </div>
  );
};

SKUCategoryTable.defaultProps = {
  skus: [],
  updateSkus: (newSkus) => console.log('Skus were updated to:', newSkus),
};

SKUCategoryTable.propTypes = {
  skus: PropTypes.arrayOf(PropTypes.shape()),
  updateSkus: PropTypes.func,
};

export default SKUCategoryTable;
