import { fade } from '@material-ui/core/styles/colorManipulator';
import { makeStyles } from '@material-ui/core/styles';
import { green, yellow } from '@material-ui/core/colors';
import numeral from 'numeral';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';

const headerStyle = {
  backgroundColor: '#171415',
};

const useStyles = makeStyles((theme) => ({
  loadingOverlay: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: fade(theme.palette.common.black, 0.8),
  },
  icon: {
    color: theme.palette.common.black,
  },
  statusFinalized: {
    color: theme.palette.common.black,
    backgroundColor: green[500],
  },
  statusInProgress: {
    color: theme.palette.common.black,
    backgroundColor: yellow[700],
  },
  wrapperContent: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

const StatementsTable = ({
  statements,
  viewStatement,
  pageSize,
  initialPage,
  updatePageSize,
  updateInitialPage,
}) => {
  const classes = useStyles();

  const columns = [
    {
      title: 'Client Name',
      field: 'clientName',
      defaultSort: 'asc',
    },
    {
      title: 'Due to Client',
      field: 'due',
      type: 'numeric',
      render: (rowData) => `R ${numeral(rowData.due).format('0,00')}`,
    },
    {
      title: 'Units Sold',
      field: 'units',
      type: 'numeric',
      render: (rowData) => numeral(rowData.units).format('0,00'),
    },
    {
      title: 'Pricing Issues',
      field: 'unknownDiscountCount',
      type: 'numeric',
      render: (rowData) => numeral(rowData.unknownDiscountCount).format('0,00'),
    },
    {
      title: 'Status',
      field: 'status',
      render: (rowData) => {
        if (rowData.status === 'IN_PROGRESS') {
          return (
            <Chip
              icon={<AutorenewIcon className={classes.icon} />}
              label="Work in Progress"
              className={classes.statusInProgress}
            />
          );
        }
        return (
          <Chip
            icon={<DoneIcon className={classes.icon} />}
            label="Finalized"
            className={classes.statusFinalized}
          />
        );
      },
    },
  ];

  return (
    <div className={classes.wrapperContent}>
      <MaterialTable
        columns={columns}
        data={statements}
        title={`${statements.length} Client Statements`}
        onChangeRowsPerPage={updatePageSize}
        onChangePage={updateInitialPage}
        options={{
          pageSize,
          initialPage,
          headerStyle,
          grouping: false,
          draggable: false,
          actionsColumnIndex: -1,
        }}
        actions={[
          {
            icon: 'keyboard_arrow_right',
            tooltip: 'View SKU info',
            onClick: (event, rowData) => viewStatement(rowData.clientId),
          },
        ]}
        components={{
          OverlayLoading: () => (
            <div className={classes.loadingOverlay}>
              <CircularProgress color="primary" size={32} />
            </div>
          ),
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'No statement reports to display',
          },
        }}
      />
    </div>
  );
};

StatementsTable.defaultProps = {
  statements: [],
  viewStatement: (platform, clientId) =>
    console.log(
      `Display info for statement for client ID ${clientId} on ${platform}`
    ),
  pageSize: 5,
  initialPage: 0,
  updatePageSize: (newPageSize) =>
    console.log(`Page size updated to: ${newPageSize}`),
  updateInitialPage: (newInitialPage) =>
    console.log(`Initial page updated to: ${newInitialPage}`),
};

StatementsTable.propTypes = {
  viewStatement: PropTypes.func,
  statements: PropTypes.arrayOf(PropTypes.shape()),
  pageSize: PropTypes.number,
  initialPage: PropTypes.number,
  updatePageSize: PropTypes.func,
  updateInitialPage: PropTypes.func,
};

export default StatementsTable;
