import { connectHits, connectStateResults } from 'react-instantsearch-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Hit from './components/Hit';
import EmptyViewMessage from '../../../../../components/EmptyViewMessage';
import HitsPagination from '../../../../../components/HitsPagination';
import SearchStats from '../../../../../components/SearchStats';

const Results = connectStateResults(({ isSearchStalled, searchResults }) => {
  if (isSearchStalled) {
    return (
      <Grid item xs={12} container justifyContent="center">
        <CircularProgress color="secondary" />
      </Grid>
    );
  }

  if (searchResults.nbHits === 0) {
    return (
      <EmptyViewMessage
        heading="No orders found"
        message="Please adjust the filters or search query."
      />
    );
  }

  return <div />;
});

const Hits = ({ hits }) => {
  return (
    <Grid container spacing={3}>
      {hits.length > 0 && (
        <Grid item xs={12}>
          <Grid container spacing={1} justifyContent="center">
            <HitsPagination />
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        <SearchStats />
      </Grid>
      <Results />
      {hits.map((hit) => {
        return (
          <Grid key={hit.objectID} item xs={12}>
            <Hit
              orderId={hit.orderId}
              title={hit.productTitle}
              eolSku={hit.sku}
              shipmentId={hit.shipmentId}
              shipmentName={hit.shipmentName}
              supplier={hit['skuInfo.supplierName']}
              status={hit.saleStatus}
              dc={hit.dc}
              primaryImageUrl={hit['skuInfo.primaryImageLink']}
              takealotListingLink={hit.takealotUrl}
              quantity={hit.quantity}
              bundledUnits={hit['skuInfo.bundledUnits']}
              costPrice={hit.supplierPurchaseCost}
              retailPrice={hit.sellingPrice}
            />
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <Grid container spacing={1} justifyContent="center">
          <HitsPagination />
        </Grid>
      </Grid>
    </Grid>
  );
};
Hits.defaultProps = {
  hits: [],
};
Hits.propTypes = {
  hits: PropTypes.arrayOf(
    PropTypes.shape({
      objectID: PropTypes.string,
      orderId: PropTypes.string,
      productTitle: PropTypes.string,
      sku: PropTypes.string,
      shipmentId: PropTypes.string,
      shipmentName: PropTypes.string,
      'skuInfo.supplierName': PropTypes.string,
      saleStatus: PropTypes.string,
      dc: PropTypes.string,
      'skuInfo.primaryImageLink': PropTypes.string,
      takealotListingLink: PropTypes.string,
      quantity: PropTypes.number,
      'skuInfo.bundledUnits': PropTypes.number,
      supplierPurchaseCost: PropTypes.number,
      sellingPrice: PropTypes.number,
    })
  ),
};
export default connectHits(Hits);
