/* eslint-disable react/jsx-props-no-spreading */
import _ from 'lodash';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { green, red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import numeral from 'numeral';
import CircularProgress from '@material-ui/core/CircularProgress';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const headerStyle = {
  backgroundColor: '#171415',
};

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.common.black,
  },
  loadingOverlay: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: fade(theme.palette.common.black, 0.8),
  },
  statusActive: {
    color: theme.palette.common.black,
    backgroundColor: green[500],
  },
  statusInactive: {
    color: theme.palette.common.black,
    backgroundColor: red[500],
  },
  wrapperContent: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

const SkusTable = ({
  isLoading,
  skus,
  platform,
  changePrices,
  viewPriceHistory,
  viewSku,
  pageSize,
  initialPage,
  updatePageSize,
  updateInitialPage,
}) => {
  const classes = useStyles();

  const formatNumber = (value, currencySymbol, type = 'float') => {
    if (value === null) {
      return '-';
    }
    if (type === 'integer') {
      return `${currencySymbol} ${numeral(value).format('0,0')}`;
    }
    return `${currencySymbol} ${numeral(value).format('0,0.00')}`;
  };

  const getColumns = () => {
    const columns = [
      { title: 'SKU', field: 'sku', editable: false },
      {
        title: platform === 'AMAZON' ? 'ASIN' : 'TSIN',
        field: platform === 'AMAZON' ? 'asin' : 'tsin',
        editable: false,
      },
      {
        title: 'Product',
        render: (rowData) => {
          const productTitle =
            rowData.variation === 'N/A'
              ? `${rowData.brand} - ${rowData.productName}`
              : `${rowData.brand} - ${rowData.productName} (${rowData.variation})`;
          return (
            <Tooltip title={productTitle} aria-label="add" interactive>
              <Typography variant="body2" component="span">
                {_.truncate(productTitle)}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        title: 'Wholesale Price',
        field: 'wholesalePrice',
        render: (rowData) => (
          <span>{formatNumber(rowData.wholesalePrice, 'R')}</span>
        ),
      },
      {
        title: 'Retail Price',
        field: 'retailPrice',
        render: (rowData) => {
          if (platform === 'AMAZON') {
            return <span>{formatNumber(rowData.retailPrice, '$')}</span>;
          }
          return (
            <span>{formatNumber(rowData.retailPrice, 'R', 'integer')}</span>
          );
        },
      },
      {
        title: 'Creation Date',
        field: 'creationDate',
        hidden: true,
        export: true,
      },
    ];

    return columns;
  };

  return (
    <div className={classes.wrapperContent}>
      <MaterialTable
        title=""
        columns={getColumns()}
        data={skus}
        isLoading={isLoading}
        onChangeRowsPerPage={updatePageSize}
        onChangePage={updateInitialPage}
        options={{
          pageSize,
          initialPage,
          headerStyle,
          actionsColumnIndex: -1,
          search: false,
          grouping: false,
          draggable: false,
          exportButton: true,
          exportAllData: true,
          exportFileName: 'SKU Pricing',
        }}
        actions={[
          {
            icon: 'history',
            tooltip: 'View price history',
            onClick: (event, rowData) => viewPriceHistory(rowData.sku),
          },
          {
            icon: 'keyboard_arrow_right',
            tooltip: 'View SKU info',
            onClick: (event, rowData) => viewSku(rowData.sku),
          },
          {
            icon: 'edit',
            tooltip: 'Edit prices',
            onClick: (event, rowData) => changePrices(rowData.sku),
          },
        ]}
        components={{
          OverlayLoading: () => (
            <div className={classes.loadingOverlay}>
              <CircularProgress color="primary" size={32} />
            </div>
          ),
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'No skus to display',
          },
        }}
      />
    </div>
  );
};

SkusTable.defaultProps = {
  isLoading: false,
  platform: 'AMAZON',
  skus: [],
  changePrices: (sku) => console.log(`Edit pricing for ${sku}`),
  viewPriceHistory: (sku) => console.log(`View pricing history for ${sku}`),
  viewSku: (sku) => console.log(`SKU viewed: ${sku}`),
  pageSize: 5,
  initialPage: 0,
  updatePageSize: (newPageSize) =>
    console.log(`Page size updated to: ${newPageSize}`),
  updateInitialPage: (newInitialPage) =>
    console.log(`Initial page updated to: ${newInitialPage}`),
};

SkusTable.propTypes = {
  isLoading: PropTypes.bool,
  platform: PropTypes.string,
  skus: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      surname: PropTypes.string,
      email: PropTypes.string,
      phoneNumber: PropTypes.string,
      title: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  changePrices: PropTypes.func,
  viewPriceHistory: PropTypes.func,
  viewSku: PropTypes.func,
  pageSize: PropTypes.number,
  initialPage: PropTypes.number,
  updatePageSize: PropTypes.func,
  updateInitialPage: PropTypes.func,
};

export default SkusTable;
