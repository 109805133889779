import { connectStats } from 'react-instantsearch-dom';
import { makeStyles } from '@material-ui/core/styles';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  resultsText: {
    fontSize: 14,
    color: theme.palette.primary.light,
  },
}));

const SearchStats = ({ nbHits }) => {
  const classes = useStyles();

  return (
    <Typography color="primary" className={classes.resultsText}>
      {`${numeral(nbHits).format('0,0')} results found`}
    </Typography>
  );
};

SearchStats.defaultProps = {
  nbHits: 10,
};

SearchStats.propTypes = {
  nbHits: PropTypes.number,
};

export default connectStats(SearchStats);
