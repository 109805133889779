import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  loadingWrapper: {
    padding: theme.spacing(8),
    textAlign: 'center',
  },
}));

const DatesCard = ({
  startDate,
  endDate,
  requestDate,
  completionDate,
  requestType,
  isLoading,
}) => {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader title="Dates" />
      <Divider />
      <CardContent>
        {isLoading ? (
          <div className={classes.loadingWrapper}>
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <List>
            <ListItem>
              <ListItemText
                primary={moment(requestDate).format('D MMMM YYYY')}
                secondary="Date Requested"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  completionDate.length > 0
                    ? moment(completionDate).format('D MMMM YYYY')
                    : 'Not Completed'
                }
                secondary="Date Completed"
              />
            </ListItem>
            {requestType === 'PRICE_CHANGE' && (
              <>
                <ListItem>
                  <ListItemText
                    primary={
                      startDate === ''
                        ? 'ASAP'
                        : moment(startDate).format('D MMMM YYYY')
                    }
                    secondary="Start Date"
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      endDate === ''
                        ? 'Indefinite'
                        : moment(endDate).format('D MMMM YYYY')
                    }
                    secondary="End Date"
                  />
                </ListItem>
              </>
            )}
          </List>
        )}
      </CardContent>
    </Card>
  );
};

DatesCard.defaultProps = {
  startDate: 'DD/MM/YY',
  endDate: 'DD/MM/YY',
  requestDate: 'DD/MM/YY',
  completionDate: '',
  requestType: 'MISCELLANEOUS',
  isLoading: false,
};

DatesCard.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  requestDate: PropTypes.string,
  completionDate: PropTypes.string,
  requestType: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default DatesCard;
