import { useState } from 'react';
import FolderIcon from '@material-ui/icons/Folder';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';

const TypeSelector = ({ typeId, changeType, types }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const getTypeLabel = (id) => {
    if (id === 'ALL') {
      return 'All Types';
    }

    const typeInfo = types.find((info) => info.id === id);
    if (!typeInfo) {
      return 'Unknown';
    }

    return typeInfo.label;
  };

  return (
    <>
      <Button
        aria-controls="type-menu"
        aria-haspopup="true"
        size="large"
        startIcon={<FolderIcon />}
        onClick={openMenu}
      >
        {getTypeLabel(typeId)}
      </Button>
      <Menu
        id="type-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem
          onClick={() => {
            changeType('ALL');
            closeMenu();
          }}
        >
          All Types
        </MenuItem>
        {types
          .map((info) => info)
          .map((info) => (
            <MenuItem
              key={info.id}
              onClick={() => {
                changeType(info.id);
                closeMenu();
              }}
            >
              {info.label}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

TypeSelector.defaultProps = {
  typeId: 'ALL',
  changeType: (newValue) => `Type was changed to '${newValue}'`,
  types: [
    {
      id: 'STANDARD',
      label: 'Standard',
    },
    {
      id: 'SUPER_DEALER',
      label: 'Super Dealer',
    },
  ],
};

TypeSelector.propTypes = {
  typeId: PropTypes.oneOf(['ALL', 'STANDARD', 'SUPER_DEALER']),
  changeType: PropTypes.func,
  types: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};

export default TypeSelector;
