import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import LinearProgress from '@material-ui/core/LinearProgress';
import allStyles from '../../../utils/styles';
import ContentWrapper from '../../../components/ContentWrapper';
import PageHeader from '../../../components/PageHeader';
import UnderConstruction from '../../../components/UnderConstruction';

const useStyles = makeStyles(allStyles);

const breadcrumbs = [
  {
    label: 'Presales',
    link: '/presales',
  },
  {
    label: 'Listing Edits',
    link: '/presales/listing-edits',
  },
];

const ListingEditsBrowse = () => {
  const classes = useStyles();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const productsLoadingStatus = useSelector(
    (state) => state.presalesListingEditsBrowse.loadingStatus.products
  );

  useEffect(() => {
    if (productsLoadingStatus === 'PENDING') {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [productsLoadingStatus]);

  return (
    <div>
      <PageHeader
        title="Listings"
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Browse"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
        actions={[
          {
            label: 'Update Listings',
            icon: <CloudUploadIcon className={classes.headerButtonIcon} />,
            onClick: () => history.push('/presales/listing-edits/add'),
          },
        ]}
      />
      {isLoading && <LinearProgress color="secondary" />}
      <ContentWrapper>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <UnderConstruction />
          </Grid>
        </Grid>
      </ContentWrapper>
    </div>
  );
};

export default ListingEditsBrowse;
