/* eslint-disable react/jsx-props-no-spreading */
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import getStyles from './styles';

const headerStyle = {
  backgroundColor: '#171415',
};

const useStyles = makeStyles(getStyles);

const SkusTable = ({ isLoading, skus, viewSku, platform }) => {
  const classes = useStyles();

  const getColumns = () => {
    let columns = [
      {
        title: 'SKU',
        field: 'sku',
        editable: 'never',
      },
    ];

    if (platform === 'BOTH') {
      columns = [
        ...columns,
        {
          title: 'TSIN',
          field: 'tsin',
          editable: 'never',
        },
        {
          title: 'Child ASIN',
          field: 'childAsin',
          editable: 'never',
        },
      ];
    } else {
      columns.push({
        title: platform === 'TAKEALOT' ? 'TSIN' : 'Child ASIN',
        field: platform === 'TAKEALOT' ? 'tsin' : 'childAsin',
        editable: 'never',
      });
    }

    columns = [
      ...columns,
      {
        title: 'Product Name',
        field: 'product',
        editable: 'never',
      },
      {
        title: 'Requested Units',
        field: 'requestedUnits',
      },
      {
        title: 'Suggested Units',
        field: 'adjustedUnits',
      },
    ];

    return columns;
  };

  return (
    <div className={classes.wrapperContent}>
      <MaterialTable
        title=""
        columns={getColumns()}
        data={skus}
        isLoading={isLoading}
        options={{
          headerStyle,
          actionsColumnIndex: -1,
          search: false,
          grouping: false,
          draggable: false,
          exportButton: true,
          exportAllData: true,
          exportFileName: 'SKUs with Adjustments',
        }}
        actions={[
          {
            icon: 'keyboard_arrow_right',
            tooltip: 'View SKU info',
            onClick: (event, rowData) => viewSku(rowData.sku),
          },
        ]}
        components={{
          OverlayLoading: () => (
            <div className={classes.loadingOverlay}>
              <CircularProgress color="primary" size={32} />
            </div>
          ),
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'No skus to display',
          },
        }}
      />
    </div>
  );
};

SkusTable.defaultProps = {
  isLoading: false,
  skus: [],
  platform: 'TAKEALOT',
  viewSku: (sku) => console.log(`SKU viewed: ${sku}`),
};

SkusTable.propTypes = {
  isLoading: PropTypes.bool,
  platform: PropTypes.oneOf(['AMAZON', 'TAKEALOT', 'BOTH']),
  skus: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string,
      tsin: PropTypes.string,
      childAsin: PropTypes.string,
      product: PropTypes.string,
      requestedUnits: PropTypes.number,
      adjustedUnits: PropTypes.number,
    })
  ),
  viewSku: PropTypes.func,
};

export default SkusTable;
