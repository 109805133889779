import { InstantSearch } from 'react-instantsearch-dom';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import assembleTypesenseServerConfig from '../../../utils/typesense';
import ContentWrapper from '../../../components/ContentWrapper';
import Hits from './components/Hits';
import PageHeader from '../../../components/PageHeader';
import StatusRefinementList from './components/StatusRefinementList';
import TypesenseSearchBar from '../../../components/TypesenseSearchBar';

const TYPESENSE_SERVER_CONFIG = assembleTypesenseServerConfig();
const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: TYPESENSE_SERVER_CONFIG,
  additionalSearchParameters: {
    queryBy: 'name,status',
    numTypos: 1,
    typoTokensThreshold: 1,
  },
});
const { searchClient } = typesenseInstantsearchAdapter;

const breadcrumbs = [
  {
    label: 'Suppliers',
    link: '/presales/suppliers',
  },
  {
    label: 'Browse',
    link: '/presales/suppliers/browse',
  },
];

const SuppliersBrowse = () => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const suppliersLoadingStatus = useSelector(
    (state) => state.presalesSuppliersBrowse.loadingStatus.suppliers
  );

  useEffect(() => {
    if (suppliersLoadingStatus === 'PENDING') {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [suppliersLoadingStatus]);

  return (
    <div>
      <PageHeader
        title="Suppliers"
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Browse"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
        actions={[
          {
            label: 'Add New Supplier',
            icon: <AddIcon />,
            onClick: () => history.push('/presales/suppliers/add'),
          },
        ]}
      />
      {isLoading && <LinearProgress color="secondary" />}
      <ContentWrapper>
        <InstantSearch
          searchClient={searchClient}
          indexName="presalesSuppliers"
          routing
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TypesenseSearchBar />
            </Grid>
            <Grid item xs={6} md={9}>
              <Hits goToLink={(path) => history.push(path)} />
            </Grid>
            <Grid item xs={6} md={3}>
              <StatusRefinementList attribute="status" />
            </Grid>
          </Grid>
        </InstantSearch>
      </ContentWrapper>
    </div>
  );
};

export default SuppliersBrowse;
