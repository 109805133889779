import _ from 'lodash';

export const getFormattedListings = (
  skus,
  searchQuery,
  clientFilter,
  copywriterFilter,
  statusFilter
) => {
  return skus
    .map((info) => {
      if (info.sku === 'CAP-SGP-0101') {
        return {
          sku: info.sku,
          clientName: info.clientName,
          brand: info.brand,
          productName: info.productName,
          variation: info.variation,
          copywriter: !info.copywriter ? 'John Doe' : info.copywriter,
          status: info.onboarding
            ? info.onboarding.statuses.copywriting
            : 'NOT_STARTED',
          creationDate: info.creationDate || '-',
        };
      }
      return {
        sku: info.sku,
        clientName: info.clientName,
        brand: info.brand,
        productName: info.productName,
        variation: info.variation,
        copywriter: !info.copywriter ? 'Unassigned' : info.copywriter,
        status: info.onboarding
          ? info.onboarding.statuses.copywriting
          : 'NOT_STARTED',
        creationDate: info.creationDate || '-',
      };
    })
    .filter((info) => {
      if (searchQuery !== '') {
        const caseInsensitiveSearch = _.toLower(searchQuery);
        const caseInsensitiveSku = _.toLower(info.sku);
        const caseInsensitiveAsin = _.toLower(info.asin);
        const caseInsensitiveTsin = _.toLower(info.tsin);
        const caseInsensitiveVariation = _.toLower(info.variation);
        const caseInsensitiveProductName = _.toLower(info.productName);
        const caseInsensitiveClientName = _.toLower(info.clientName);
        const caseInsensitiveBrand = _.toLower(info.brand);

        if (caseInsensitiveSku.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveAsin.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveTsin.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveVariation.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveProductName.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveClientName.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveBrand.includes(caseInsensitiveSearch)) {
          return true;
        }

        return false;
      }

      return true;
    })
    .filter((info) => {
      if (clientFilter !== 'ALL') {
        return info.clientName === clientFilter;
      }
      return true;
    })
    .filter((info) => {
      if (copywriterFilter !== 'ALL') {
        return info.copywriter === copywriterFilter;
      }
      return true;
    })
    .filter((info) => {
      if (statusFilter !== 'ALL') {
        return info.status === statusFilter;
      }
      return true;
    })
    .sort((a, b) => {
      if (a.creationDate < b.creationDate) return +1;
      if (a.creationDate > b.creationDate) return -1;
      if (a.clientName < b.clientName) return -1;
      if (a.clientName > b.clientName) return +1;
      if (a.brand < b.brand) return -1;
      if (a.brand > b.brand) return +1;
      if (a.productName < b.productName) return -1;
      if (a.productName > b.productName) return +1;
      if (a.variation < b.variation) return -1;
      if (a.variation > b.variation) return +1;
      return 0;
    });
};

export const getClients = (
  skus,
  searchQuery,
  copywriterFilter,
  statusFilter
) => {
  return _.uniq(
    getFormattedListings(
      skus,
      searchQuery,
      'ALL',
      copywriterFilter,
      statusFilter
    ).map((info) => info.clientName)
  ).sort();
};

export const getCopywriters = (
  skus,
  searchQuery,
  clientFilter,
  statusFilter
) => {
  return _.uniq(
    getFormattedListings(
      skus,
      searchQuery,
      clientFilter,
      'ALL',
      statusFilter
    ).map((info) => info.copywriter)
  )
    .filter((copywriterName) => copywriterName !== 'Unassigned')
    .sort();
};

export const findSku = (sku, skus) => {
  const skuInfo = skus.find((info) => info.sku === sku);
  return skuInfo;
};
