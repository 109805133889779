import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {
  fetchClientSkus,
  fetchQueryInfo,
  initiateStateReset,
  updateQuery,
  fetchUsers,
} from './slice';
import { getFilteredSkus, getFilteredSelected } from '../ClientsQueryAdd/logic';
import ContentWrapper from '../../../components/ContentWrapper';
import FiltersBar from '../ClientsQueryAdd/components/FiltersBar';
import ListingInfo from '../ClientsQueryAdd/components/ListingInfo';
import MiscellaneousInfo from '../ClientsQueryAdd/components/MiscellaneousInfo';
import PageHeader from '../../../components/PageHeader';
import PriceChangeInfo from '../ClientsQueryAdd/components/PriceChangeInfo';
import ProductSelectTable from './component/ProductSelectionTable';
import ReceivedProductMismatch from '../ClientsQueryAdd/components/ReceivedProductMismatch';

const useStyles = makeStyles((theme) => ({
  actionsBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  alert: {
    marginBottom: theme.spacing(3),
  },
  formControl: {
    width: '100%',
  },
  formWrapper: {
    padding: `${theme.spacing(4)}px ${theme.spacing(1)}px`,
  },
  separator: {
    height: theme.spacing(2),
  },
  wrapperStep: {
    padding: `${theme.spacing(4)}px 0px`,
  },
  wrapperWizard: {
    width: '100%',
    padding: theme.spacing(4),
  },
}));

const breadcrumbs = [
  {
    label: 'Customer Relationship Management',
    link: '/crm',
  },
  {
    label: 'Queries',
    link: '/crm/queries',
  },
];

const ClientsQueryEdit = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { queryId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [note, setNote] = useState('');
  const [queryType, setQueryType] = useState('');
  const [selectedSkus, setSelectedSkus] = useState([]);
  const [filteredSkus, setFilteredSkus] = useState([]);
  const [priceType, setPriceType] = useState('');
  const [vatOption, setVatOption] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [skuSearchText, setSkuSearchText] = useState('');
  const [fieldUpdate, setFieldUpdate] = useState('NONE_SELECTED');
  const [validation, setValidation] = useState({
    isErrorShowing: false,
    message: '',
    errorStep: 0,
  });
  const [requirementsSearch, setRequirementsSearch] = useState('');
  const [assignee, setAssignee] = useState({
    id: 'NONE_SELECTED',
    name: 'None selected',
  });

  const skus = useSelector((state) => state.clientsQueryEdit.skus);
  const updateLoadingStatus = useSelector(
    (state) => state.clientsQueryEdit.loadingStatus.update
  );
  const queryInfoLoadingStatus = useSelector(
    (state) => state.clientsQueryEdit.loadingStatus.queryInfo
  );
  const skusLoadingStatus = useSelector(
    (state) => state.clientsQueryEdit.loadingStatus.skus
  );
  const isUserRedirectedToBrowse = useSelector(
    (state) => state.clientsQueryEdit.isUserRedirectedToBrowse
  );
  const queryInfo = useSelector((state) => state.clientsQueryEdit.queryInfo);
  const userList = useSelector((state) => state.clientsQueryEdit.users);
  const usersLoadingStatus = useSelector(
    (state) => state.clientsQueryEdit.loadingStatus.users
  );

  useEffect(() => {
    dispatch(fetchQueryInfo(queryId));
    dispatch(fetchUsers());
  }, []);

  useEffect(() => {
    dispatch(fetchClientSkus(queryInfo.clientId));
  }, [queryInfo]);

  useEffect(() => {
    setFilteredSkus(getFilteredSkus(skus, skuSearchText, queryInfo.clientId));
  }, [skus, skuSearchText]);

  useEffect(() => {
    if (
      updateLoadingStatus === 'PENDING' ||
      queryInfoLoadingStatus === 'PENDING' ||
      skusLoadingStatus === 'PENDING' ||
      usersLoadingStatus === 'PENDING'
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [
    updateLoadingStatus,
    queryInfoLoadingStatus,
    skusLoadingStatus,
    usersLoadingStatus,
  ]);

  useEffect(() => {
    if (activeStep === 1) {
      setFilteredSkus(getFilteredSelected(selectedSkus, requirementsSearch));
    }
  }, [selectedSkus, requirementsSearch]);

  useEffect(() => {
    if (isUserRedirectedToBrowse) {
      dispatch(initiateStateReset());
      setIsLoading(true);
      history.goBack();
    }
  }, [isUserRedirectedToBrowse]);

  const checkIfSelected = (sku) => {
    const relevantSku = selectedSkus.find(
      (selectedSkuInfo) => selectedSkuInfo.sku === sku
    );

    if (relevantSku) {
      return true;
    }

    return false;
  };

  const getSelectedSkuChange = (sku) => {
    const relevantSku = selectedSkus.find(
      (selectedSkuInfo) => selectedSkuInfo.sku === sku
    );

    return relevantSku ? relevantSku.changeTo : '';
  };

  const getFormattedSkus = (unformattedSkus) => {
    return unformattedSkus.map((skuInfo, index) => ({
      sku: skuInfo.sku,
      childAsin: skuInfo.amazon.childAsin,
      tsin: skuInfo.takealot.tsin,
      description:
        skuInfo.variation === 'N/A'
          ? skuInfo.productName
          : `${skuInfo.productName} (${skuInfo.variation})`,
      brand: skuInfo.brand,
      changeTo: getSelectedSkuChange(skuInfo.sku),
      tableData: {
        id: index,
        checked: checkIfSelected(skuInfo.sku),
      },
    }));
  };

  useEffect(() => {
    setNote(queryInfo.notes);
    setQueryType(queryInfo.requestType);
    if (queryInfo.skus)
      setSelectedSkus(
        queryInfo.skus.map(({ sku, product, changeTo, mismatchType }) => ({
          sku,
          product,
          changeTo,
          mismatchType: mismatchType || '',
        }))
      );
    if (queryInfo.requestType === 'PRICE_CHANGE') {
      setVatOption(queryInfo.vatIncluded);
      setPriceType(queryInfo.priceToChange);
      setStartDate(queryInfo.startDate);
      setEndDate(queryInfo.endDate);
    }
    if (queryInfo.requestType === 'LISTING_CHANGE') {
      setFieldUpdate(queryInfo.fieldChange);
    }
    if (queryInfo.assigneeName && queryInfo.assigneeId) {
      setAssignee({ id: queryInfo.assigneeId, name: queryInfo.assigneeName });
    }
  }, [queryInfo]);

  const getTypeStep = () => {
    switch (queryType) {
      case 'MISCELLANEOUS':
        return (
          <MiscellaneousInfo
            skus={filteredSkus}
            updateSkus={setSelectedSkus}
            searchText={requirementsSearch}
            setSearchText={setRequirementsSearch}
            note={note}
            setNote={setNote}
          />
        );
      case 'PRICE_CHANGE':
        return (
          <PriceChangeInfo
            skus={filteredSkus}
            updateSkus={setSelectedSkus}
            priceType={priceType}
            setPriceType={setPriceType}
            vatInc={vatOption}
            setVatInc={setVatOption}
            endDate={endDate}
            setEndDate={setEndDate}
            startDate={startDate}
            setStartDate={setStartDate}
            searchText={requirementsSearch}
            setSearchText={setRequirementsSearch}
            note={note}
            setNote={setNote}
          />
        );
      case 'LISTING_CHANGE':
        return (
          <ListingInfo
            skus={filteredSkus}
            updateSkus={setSelectedSkus}
            updateField={fieldUpdate}
            setUpdateField={setFieldUpdate}
            searchText={requirementsSearch}
            setSearchText={setRequirementsSearch}
            note={note}
            setNote={setNote}
          />
        );
      case 'PRODUCT_MISMATCH':
        return (
          <ReceivedProductMismatch
            updateSkus={setSelectedSkus}
            skus={selectedSkus}
            setNote={setNote}
            note={note}
          />
        );
      default:
        return <div />;
    }
  };

  const onNextClick = () => {
    switch (activeStep) {
      case 0:
        if (selectedSkus.length === 0) {
          setValidation({
            isErrorShowing: true,
            message: 'Please select at least one sku.',
            errorStep: 0,
          });
        } else {
          setValidation({
            isErrorShowing: false,
            message: '',
            errorStep: 0,
          });
          setFilteredSkus(
            getFilteredSelected(selectedSkus, requirementsSearch)
          );
          setActiveStep(1);
        }
        break;
      case 1:
        if (
          assignee.id === 'NONE_SELECTED' ||
          assignee.name === 'None selected'
        ) {
          setValidation({
            isErrorShowing: true,
            message: 'Please assign a person.',
            errorStep: 1,
          });
        } else if (
          queryType === 'LISTING_CHANGE' &&
          fieldUpdate === 'NONE_SELECTED'
        ) {
          setValidation({
            isErrorShowing: true,
            message: 'Please select the field to be changed.',
            errorStep: 1,
          });
        } else if (
          queryType === 'PRICE_CHANGE' &&
          (!priceType.length > 0 || startDate === null || endDate === null)
        ) {
          if (!priceType.length > 0) {
            setValidation({
              isErrorShowing: true,
              message: 'Please select the price to be changed.',
              errorStep: 1,
            });
          } else if (startDate === null) {
            setValidation({
              isErrorShowing: true,
              message: 'Please select a starting date.',
              errorStep: 1,
            });
          } else if (endDate === null) {
            setValidation({
              isErrorShowing: true,
              message: 'Please select an ending date.',
              errorStep: 1,
            });
          }
        } else {
          setValidation({
            isErrorShowing: false,
            message: '',
            errorStep: 1,
          });

          let queryUpdated = {
            clientId: queryInfo.clientId,
            clientName: queryInfo.clientName,
            requestDate: new Date(queryInfo.requestDate),
            completionDate: queryInfo.completionDate,
            creatorId: queryInfo.creatorId,
            creatorName: queryInfo.creatorName,
            assigneeId: assignee.id,
            assigneeName: assignee.name,
            requestType: queryType,
            status: queryInfo.status,
            notes: note,
            skus: selectedSkus.reduce((finalSkus, currentSku) => {
              // eslint-disable-next-line no-param-reassign
              finalSkus[currentSku.sku] = {
                sku: currentSku.sku,
                product: currentSku.product,
                changeTo: currentSku.changeTo,
                ...(queryType === 'PRODUCT_MISMATCH' && {
                  mismatchType: currentSku.mismatchType,
                }),
              };
              return finalSkus;
            }, {}),
          };

          if (queryType === 'LISTING_CHANGE') {
            queryUpdated = {
              ...queryUpdated,
              fieldChange: fieldUpdate,
            };
          }
          if (queryType === 'PRICE_CHANGE') {
            queryUpdated = {
              ...queryUpdated,
              vatIncluded: vatOption,
              priceToChange: priceType,
              startDate: startDate === '' ? '' : new Date(startDate),
              endDate: endDate === '' ? '' : new Date(endDate),
            };
          }
          dispatch(updateQuery(queryId, queryUpdated));
        }
        break;
      default:
        console.log('Unknown step');
        break;
    }
  };

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <div className={classes.wrapperStep}>
            <Typography variant="h4" gutterBottom>
              Select or remove relevant SKU&apos;s.
            </Typography>
            <div className={classes.separator} />
            <FiltersBar
              searchText={skuSearchText}
              changeSearchText={setSkuSearchText}
              skuCount={filteredSkus.length}
            />
            <div className={classes.separator} />
            {!isLoading && (
              <ProductSelectTable
                skus={getFormattedSkus(filteredSkus)}
                updateSelectedProducts={setSelectedSkus}
              />
            )}
            <div className={classes.separator} />
            {validation.isErrorShowing && validation.errorStep === 0 && (
              <Alert className={classes.alert} severity="error">
                {validation.message}
              </Alert>
            )}
          </div>
        );
      case 1:
        return (
          <div className={classes.wrapperStep}>
            {getTypeStep()}
            <div className={classes.separator} />
            <FormControl className={classes.formControl} required>
              <InputLabel shrink id="assignee-select-label">
                Assign To
              </InputLabel>
              <Select
                labelId="assignee-select-label"
                id="assignee-select"
                value={assignee.id}
                onChange={(e) =>
                  setAssignee({
                    id: e.target.value,
                    name: e.currentTarget.dataset.assigneename,
                  })
                }
              >
                <MenuItem value="NONE_SELECTED">Please Select</MenuItem>
                {[...userList]
                  .sort((a, b) => {
                    if (a.name > b.name) return +1;
                    if (a.name < b.name) return -1;
                    return 0;
                  })
                  .map((info) => {
                    return (
                      <MenuItem
                        value={info.id}
                        key={info.id}
                        data-assigneename={`${info.name} ${info.surname}`}
                      >
                        {`${info.name} ${info.surname}`}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            <div className={classes.separator} />
            {validation.isErrorShowing && validation.errorStep === 1 && (
              <Alert className={classes.alert} severity="error">
                {validation.message}
              </Alert>
            )}
          </div>
        );
      default:
        return <div />;
    }
  };

  return (
    <div>
      <PageHeader
        isLoading={isLoading}
        title="Edit Query"
        subtitle={isLoading ? 'Loading...' : queryInfo.id}
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Edit"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
      />
      <ContentWrapper>
        <Paper className={classes.wrapperWizard}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Stepper activeStep={activeStep} orientation="vertical">
                <Step>
                  <StepLabel>SKU Selection</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Requirements</StepLabel>
                </Step>
              </Stepper>
            </Grid>
            <Grid item xs={9}>
              {getStepContent()}
              <div className={classes.actionsBar}>
                <Button
                  disabled={activeStep === 0 || isLoading}
                  onClick={() => {
                    setFilteredSkus(getFilteredSkus(skus, skuSearchText));
                    setActiveStep(activeStep - 1);
                  }}
                >
                  Previous
                </Button>
                <Button
                  disabled={isLoading}
                  variant="contained"
                  color="secondary"
                  onClick={onNextClick}
                >
                  {activeStep !== 1 ? 'Next' : 'Update'}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </ContentWrapper>
    </div>
  );
};

export default ClientsQueryEdit;
