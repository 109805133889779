/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getSourcedProducts } from '../../../api/salesAPI';
import {
  getSupplier,
  getDocuments,
  deleteDocument,
} from '../../../api/suppliersAPI';

const initialState = {
  supplierInfo: {},
  products: [],
  documents: [],
  loadingStatus: {
    supplierInfo: 'NOT_STARTED',
    products: 'NOT_STARTED',
    documents: 'NOT_STARTED',
    deleteDocument: 'NOT_STARTED',
  },
  feedback: {
    isOpen: false,
    type: undefined,
    message: '',
  },
};

const suppliersViewSlice = createSlice({
  name: 'suppliersView',
  initialState,
  reducers: {
    getProductsStarted(state) {
      state.loadingStatus.products = 'PENDING';
    },
    getProductsSuccess(state, action) {
      state.products = action.payload;
      state.loadingStatus.products = 'COMPLETE';
    },
    getProductsFailed(state, action) {
      state.loadingStatus.products = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    fetchSupplierInfoStarted(state) {
      state.loadingStatus.supplierInfo = 'PENDING';
    },
    fetchSupplierInfoSuccess(state, action) {
      state.supplierInfo = action.payload;
      state.loadingStatus.supplierInfo = 'COMPLETE';
    },
    fetchSupplierInfoFailed(state, action) {
      state.loadingStatus.supplierInfo = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getDocumentsStarted(state) {
      state.loadingStatus.documents = 'PENDING';
    },
    getDocumentsSuccess(state, action) {
      state.documents = action.payload;
      state.loadingStatus.documents = 'COMPLETE';
    },
    getDocumentsFailed(state, action) {
      state.loadingStatus.documents = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    deleteDocumentStarted(state) {
      state.loadingStatus.deleteDocument = 'PENDING';
    },
    deleteDocumentSuccess(state, action) {
      state.loadingStatus.deleteDocument = 'COMPLETE';
      state.documents = state.documents.filter(
        (documentInfo) => documentInfo.id !== action.payload
      );
    },
    deleteDocumentFailed(state, action) {
      state.loadingStatus.deleteDocument = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
  },
});

export const {
  getProductsStarted,
  getProductsSuccess,
  getProductsFailed,
  fetchSupplierInfoStarted,
  fetchSupplierInfoSuccess,
  fetchSupplierInfoFailed,
  getDocumentsSuccess,
  getDocumentsFailed,
  getDocumentsStarted,
  deleteDocumentFailed,
  deleteDocumentStarted,
  deleteDocumentSuccess,
} = suppliersViewSlice.actions;

export default suppliersViewSlice.reducer;

export const fetchSupplierInfo = (sku) => async (dispatch) => {
  dispatch(fetchSupplierInfoStarted());
  const result = await getSupplier(sku);

  if (result.status === 200) {
    dispatch(fetchSupplierInfoSuccess(result.supplierInfo));
  } else {
    dispatch(fetchSupplierInfoFailed({ message: result.error }));
  }
};

export const fetchProducts = (supplierId) => async (dispatch) => {
  dispatch(getProductsStarted());
  const result = await getSourcedProducts('ALL', supplierId);

  if (result.status === 200) {
    dispatch(getProductsSuccess(result.products));
  } else {
    dispatch(getProductsFailed(result.error));
  }
};

export const fetchDocuments = (supplierId) => async (dispatch) => {
  dispatch(getDocumentsStarted());
  const result = await getDocuments(supplierId);

  if (result.status === 200) {
    dispatch(getDocumentsSuccess(result.documents));
  } else {
    dispatch(getDocumentsFailed(result.error));
  }
};

export const deleteSupplierDocument =
  (supplierId, documentPath, documentId) => async (dispatch) => {
    dispatch(deleteDocumentStarted());
    const result = await deleteDocument(supplierId, documentPath, documentId);

    if (result.status === 200) {
      dispatch(deleteDocumentSuccess(documentId));
    } else {
      dispatch(deleteDocumentFailed(result.error));
    }
  };
