/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getSupplier } from '../../../api/presalesSuppliersAPI';
import { getStockSkuToUpdate, updateSkuInfo } from '../../../api/presalesAPI';

const initialState = {
  isUserRedirectedToBrowse: false,
  supplierInfo: {
    id: '',
    stockColumns: {
      supplierSkuStockIndex: 0,
      sohIndex: 0,
    },
  },
  presalesSkusToUpdate: [],
  failedStockSkus: [],
  stockUpdateLoadingCount: {
    total: 0,
    success: 0,
    failed: 0,
  },
  loadingStatus: {
    supplierInfo: 'NOT_STARTED',
    presalesSkusToUpdate: 'NOT_STARTED',
  },
  feedback: {
    isOpen: false,
    type: undefined,
    message: '',
  },
};

const presalesSuppliersUpdateStockSlice = createSlice({
  name: 'presalesSuppliersUpdateStock',
  initialState,
  reducers: {
    getSkusStarted(state) {
      state.loadingStatus.presalesSkusToUpdate = 'PENDING';
    },
    getSkusSuccess(state, action) {
      state.presalesSkusToUpdate = action.payload;
      state.loadingStatus.presalesSkusToUpdate = 'COMPLETE';
    },
    getSkusFailed(state, action) {
      state.loadingStatus.presalesSkusToUpdate = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    fetchSupplierInfoStarted(state) {
      state.loadingStatus.supplierInfo = 'PENDING';
    },
    fetchSupplierInfoSuccess(state, action) {
      state.supplierInfo = action.payload;
      state.loadingStatus.supplierInfo = 'COMPLETE';
    },
    fetchSupplierInfoFailed(state, action) {
      state.loadingStatus.supplierInfo = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    stockSkusToUpdate(state, action) {
      state.failedStockSkus = [];
      state.stockUpdateLoadingCount.total = action.payload;
      state.stockUpdateLoadingCount.success = 0;
      state.stockUpdateLoadingCount.failed = 0;
    },

    stockUpdateSuccess(state) {
      state.stockUpdateLoadingCount.success += 1;
    },
    stockUpdateFailed(state, action) {
      state.stockUpdateLoadingCount.failed += 1;
      state.failedStockSkus.push(action.payload);
    },
    resetState() {
      return initialState;
    },
  },
});

export const {
  updateSupplierStarted,
  updateSupplierSuccess,
  updateSupplierFailed,
  fetchSupplierInfoStarted,
  fetchSupplierInfoSuccess,
  fetchSupplierInfoFailed,
  stockSkusToUpdate,
  stockUpdateSuccess,
  stockUpdateFailed,
  resetState,
} = presalesSuppliersUpdateStockSlice.actions;

export default presalesSuppliersUpdateStockSlice.reducer;

export const updateStockInfo = (supplierSku, newInfo) => async (dispatch) => {
  const result = await getStockSkuToUpdate(newInfo);
  if (result.data === 200) {
    dispatch(stockUpdateSuccess());
  } else {
    dispatch(stockUpdateFailed({ sku: supplierSku }));
  }
};

export const updateSku = (skuId, skuInfo) => async (dispatch) => {
  const result = await updateSkuInfo(skuId, skuInfo);
  if (result.status === 200) {
    dispatch(stockUpdateSuccess());
  } else {
    dispatch(
      stockUpdateFailed({
        eolSku: result.eolSku,
      })
    );
  }
};

export const fetchSupplierInfo = (supplierId) => async (dispatch) => {
  dispatch(fetchSupplierInfoStarted());
  const result = await getSupplier(supplierId);

  if (result.status === 200) {
    dispatch(fetchSupplierInfoSuccess(result.supplierInfo));
  } else {
    dispatch(fetchSupplierInfoFailed(result.error));
  }
};

export const setTotalSkusToUpdate = (total) => (dispatch) => {
  dispatch(stockSkusToUpdate(total));
};

export const initiateStateReset = () => async (dispatch) => {
  dispatch(resetState());
};
