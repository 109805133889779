import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
  bulkStockUpdate,
  fetchSkus,
  fetchStockChanges,
  fetchWarehouses,
  setTotalSkusToUpdate,
  updateInitialPage,
  updatePageSize,
  updateStockQuantities,
} from './slice';
import { findSku, getFormattedListings, getWarehouseTotals } from './logic';
import BulkUpdateDialog from './components/BulkUpdateDialog';
import ContentWrapper from '../../../components/ContentWrapper';
import EmptyViewMessage from '../../../components/EmptyViewMessage';
import FiltersBar from './components/FiltersBar';
import PageHeader from '../../../components/PageHeader';
import SkusTable from './components/SkusTable';
import StockHistoryDialog from './components/StockHistoryDialog';
import TotalsBar from '../../../components/TotalsBar';
import UnitAdjustmentDialog from './components/UnitAdjustmentDialog';

const breadcrumbs = [
  {
    label: 'Logistics',
    link: '/logistics',
  },
  {
    label: 'Storage',
    link: '/logistics/storage',
  },
];

const ListingsBasicInfo = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [isUnitAdjustmentDialogOpen, setIsUnitAdjustmentDialogOpen] =
    useState(false);
  const [isStockHistoryDialogOpen, setIsStockHistoryDialogOpen] =
    useState(false);
  const [unitAdjustmentSkuSelected, setUnitAdjustmentSkuSelected] =
    useState('');
  const [stockHistorySkuSelected, setStockHistorySkuSelected] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [platformFilter, setPlatformFilter] = useState('TAKEALOT');
  const [clientFilter, setClientFilter] = useState('ALL');
  const [statusFilter, setStatusFilter] = useState('ALL');
  const [formattedListings, setFormattedListings] = useState([]);
  const [isBulkUpdateDialogOpen, setIsBulkUpdateDialogOpen] = useState(false);

  const skus = useSelector((state) => state.logisticsStorage.skusList);
  const pageSize = useSelector((state) => state.logisticsStorage.pageSize);
  const initialPage = useSelector(
    (state) => state.logisticsStorage.initialPage
  );
  const userInfo = useSelector((state) => state.account.userInfo);
  const stockChanges = useSelector(
    (state) => state.logisticsStorage.stockChanges
  );
  const warehouses = useSelector(
    (state) => state.logisticsStorage.warehousesList
  );
  const skusLoadingStatus = useSelector(
    (state) => state.logisticsStorage.loadingStatus.skus
  );
  const stockChangesLoadingStatus = useSelector(
    (state) => state.logisticsStorage.loadingStatus.stockChanges
  );
  const warehousesLoadingStatus = useSelector(
    (state) => state.logisticsStorage.loadingStatus.warehouses
  );
  const updateSkuLoadingStatus = useSelector(
    (state) => state.logisticsStorage.loadingStatus.updateSku
  );
  const bulkStockUploadLoadingCount = useSelector(
    (state) => state.logisticsStorage.bulkStockUploadLoadingCount
  );
  const failedBulkSkus = useSelector(
    (state) => state.logisticsStorage.failedBulkSkus
  );

  useEffect(() => {
    dispatch(fetchWarehouses());
  }, []);

  useEffect(() => {
    if (skusLoadingStatus !== 'PENDING') {
      dispatch(fetchSkus(platformFilter));
    }
  }, [platformFilter]);

  useEffect(() => {
    if (
      skusLoadingStatus === 'PENDING' ||
      warehousesLoadingStatus === 'PENDING' ||
      updateSkuLoadingStatus === 'PENDING'
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [skusLoadingStatus, warehousesLoadingStatus, updateSkuLoadingStatus]);

  useEffect(() => {
    if (stockHistorySkuSelected.length > 0) {
      dispatch(fetchStockChanges(stockHistorySkuSelected));
    }
  }, [stockHistorySkuSelected]);

  useEffect(() => {
    setFormattedListings(
      getFormattedListings(
        skus,
        warehouses,
        searchQuery,
        clientFilter,
        platformFilter,
        statusFilter
      )
    );
  }, [
    skus,
    warehouses,
    searchQuery,
    clientFilter,
    platformFilter,
    statusFilter,
  ]);

  const getClients = () => {
    return _.uniq(skus.map((info) => info.clientName)).sort();
  };

  return (
    <div>
      <PageHeader
        title="Storage"
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Browse"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
        actions={[
          {
            label: 'Bulk Update',
            icon: <CloudUploadIcon />,
            onClick: () => setIsBulkUpdateDialogOpen(true),
          },
        ]}
      />
      {isLoading && <LinearProgress color="secondary" />}
      <ContentWrapper>
        {!isLoading && skus.length === 0 ? (
          <EmptyViewMessage
            heading="No SKUs Available"
            message="Please adjust the filters or add new SKU to view them here."
          />
        ) : (
          <Grid container spacing={3}>
            {!isLoading && (
              <Grid item xs={12}>
                <TotalsBar
                  totals={getWarehouseTotals(
                    formattedListings,
                    warehouses,
                    platformFilter
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <FiltersBar
                searchText={searchQuery}
                changeSearchText={setSearchQuery}
                listingCount={formattedListings.length}
                platformFilter={platformFilter}
                changePlatformFilter={(newPlatform) => {
                  setPlatformFilter(newPlatform);
                  setClientFilter('ALL');
                }}
                clientFilter={clientFilter}
                changeClientFilter={setClientFilter}
                clientOptions={getClients()}
                statusFilter={statusFilter}
                changeStatusFilter={setStatusFilter}
              />
              {skusLoadingStatus === 'COMPLETE' &&
                warehousesLoadingStatus === 'COMPLETE' && (
                  <SkusTable
                    platform={platformFilter}
                    isLoading={isLoading}
                    skus={formattedListings}
                    warehouses={warehouses}
                    viewSku={(sku) =>
                      history.push(`/listings/basic-info/browse/${sku}`)
                    }
                    viewStockHistory={(sku) => {
                      setIsStockHistoryDialogOpen(true);
                      setStockHistorySkuSelected(sku);
                    }}
                    adjustUnitQuantity={(sku) => {
                      setIsUnitAdjustmentDialogOpen(true);
                      setUnitAdjustmentSkuSelected(sku);
                    }}
                    pageSize={pageSize}
                    initialPage={initialPage}
                    updatePageSize={(newPageSize) =>
                      dispatch(updatePageSize(newPageSize))
                    }
                    updateInitialPage={(newInitialPage) =>
                      dispatch(updateInitialPage(newInitialPage))
                    }
                  />
                )}
            </Grid>
          </Grid>
        )}
      </ContentWrapper>
      <StockHistoryDialog
        isOpen={isStockHistoryDialogOpen}
        isLoading={stockChangesLoadingStatus === 'PENDING'}
        sku={stockHistorySkuSelected}
        stockChanges={stockChanges}
        closeDialog={() => {
          setIsStockHistoryDialogOpen(false);
          setStockHistorySkuSelected('');
        }}
      />
      <UnitAdjustmentDialog
        isOpen={isUnitAdjustmentDialogOpen}
        isLoading={updateSkuLoadingStatus === 'PENDING'}
        sku={unitAdjustmentSkuSelected}
        closeDialog={() => {
          setIsUnitAdjustmentDialogOpen(false);
        }}
        adjustUnits={(unitQuantity) => {
          const skuInfo = findSku(unitAdjustmentSkuSelected, skus);
          const originalQuantity =
            skuInfo.stockQuantities['5tI7NvUkhMtemnqcirGy'] || 0;
          dispatch(
            updateStockQuantities(
              unitAdjustmentSkuSelected,
              skuInfo.stockQuantities,
              {
                ...skuInfo.stockQuantities,
                '5tI7NvUkhMtemnqcirGy': originalQuantity + unitQuantity,
              },
              userInfo
            )
          );
          setUnitAdjustmentSkuSelected('');
        }}
      />
      <BulkUpdateDialog
        isOpen={isBulkUpdateDialogOpen}
        bulkStockUploadLoadingCount={bulkStockUploadLoadingCount}
        bulkStockUpdate={(code, easyOnlineQty, amazonUsQty, type) =>
          dispatch(
            bulkStockUpdate(code, easyOnlineQty, amazonUsQty, type, userInfo)
          )
        }
        setTotalSkusToUpdate={(total) => dispatch(setTotalSkusToUpdate(total))}
        failedBulkSkus={failedBulkSkus}
        closeDialog={() => setIsBulkUpdateDialogOpen(false)}
      />
    </div>
  );
};

export default ListingsBasicInfo;
