/* eslint-disable react/prop-types */
import { fade } from '@material-ui/core/styles/colorManipulator';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

const headerStyle = {
  backgroundColor: '#A7A5A5',
  color: '#171415',
};

const useStyles = makeStyles((theme) => ({
  loadingOverlay: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: fade(theme.palette.common.black, 0.8),
  },
  wrapperContent: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

const SKUWeightAndDimensionsTable = ({ skus, updateSkus }) => {
  const classes = useStyles();

  const columns = [
    {
      title: 'Description',
      field: 'description',
      editable: 'never',
    },
    {
      title: 'Weight',
      field: 'weight',
      render: (rowData) => (
        <TextField
          InputProps={{
            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
          }}
          type="number"
          value={rowData.weight}
          onChange={(e) => {
            updateSkus(
              skus.map((skuInfo) => {
                if (skuInfo.id === rowData.id) {
                  return {
                    ...skuInfo,
                    weight: e.target.value,
                  };
                }
                return skuInfo;
              })
            );
          }}
        />
      ),
    },
    {
      title: 'Length',
      field: 'length',
      render: (rowData) => (
        <TextField
          InputProps={{
            endAdornment: <InputAdornment position="end">cm</InputAdornment>,
          }}
          type="number"
          value={rowData.length}
          onChange={(e) => {
            updateSkus(
              skus.map((skuInfo) => {
                if (skuInfo.id === rowData.id) {
                  return {
                    ...skuInfo,
                    length: e.target.value,
                  };
                }
                return skuInfo;
              })
            );
          }}
        />
      ),
    },
    {
      title: 'Width',
      field: 'width',
      render: (rowData) => (
        <TextField
          InputProps={{
            endAdornment: <InputAdornment position="end">cm</InputAdornment>,
          }}
          type="number"
          value={rowData.width}
          onChange={(e) => {
            updateSkus(
              skus.map((skuInfo) => {
                if (skuInfo.id === rowData.id) {
                  return {
                    ...skuInfo,
                    width: e.target.value,
                  };
                }
                return skuInfo;
              })
            );
          }}
        />
      ),
    },
    {
      title: 'Height',
      field: 'height',
      render: (rowData) => (
        <TextField
          InputProps={{
            endAdornment: <InputAdornment position="end">cm</InputAdornment>,
          }}
          type="number"
          value={rowData.height}
          onChange={(e) => {
            updateSkus(
              skus.map((skuInfo) => {
                if (skuInfo.id === rowData.id) {
                  return {
                    ...skuInfo,
                    height: e.target.value,
                  };
                }
                return skuInfo;
              })
            );
          }}
        />
      ),
    },
  ];

  return (
    <div className={classes.wrapperContent}>
      <MaterialTable
        columns={columns}
        data={skus}
        options={{
          headerStyle,
          sorting: false,
          grouping: false,
          draggable: false,
          search: false,
          showTitle: false,
          toolbar: false,
          paging: false,
        }}
        components={{
          OverlayLoading: () => (
            <div className={classes.loadingOverlay}>
              <CircularProgress color="primary" size={32} />
            </div>
          ),
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'SKUs not yet added.',
          },
        }}
      />
    </div>
  );
};

SKUWeightAndDimensionsTable.defaultProps = {
  skus: [],
  updateSkus: (newSkus) => console.log('Skus were updated to:', newSkus),
};

SKUWeightAndDimensionsTable.propTypes = {
  skus: PropTypes.arrayOf(PropTypes.shape()),
  updateSkus: PropTypes.func,
};

export default SKUWeightAndDimensionsTable;
