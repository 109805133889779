/* eslint-disable consistent-return */
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
} from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import allStyles from '../../../../../utils/styles';

const useStyles = makeStyles(allStyles);

const UpdatePricingColumnDialog = ({ isOpen, closeDialog, missingSkuInfo }) => {
  const classes = useStyles();

  const checkIfCompleted = (info) => {
    const [key, title] = info;
    if (missingSkuInfo[key]) {
      return (
        <TableRow>
          <TableCell style={{ lineHeight: 0.8, borderBottom: 'none' }}>
            {title}
          </TableCell>
        </TableRow>
      );
    }
  };

  const missingInfoCategory = [
    ['barcode', 'Barcode'],
    ['primaryImageLink', 'Images'],
    ['height', 'Height (Measurements)'],
    ['length', 'Length (Measurements)'],
    ['width', 'Width (Measurements)'],
    ['weight', 'Weight (Measurements)'],
    ['department', 'Department (Categories)'],
    ['division', 'Division (Categories)'],
    ['lowestCategory', 'Lowest Category (Categories)'],
    ['mainCategory', 'Main Category (Categories)'],
    ['description', 'Description (Copy)'],
    ['title', 'Title (Copy)'],
    ['whatsInTheBox', "What's In The Box (Copy)"],
  ];

  return (
    <Dialog open={isOpen} onClose={closeDialog} fullWidth maxWidth="xs">
      <DialogTitle>Missing Information</DialogTitle>
      <DialogContent dividers className={classes.wrapperDialog}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              {missingInfoCategory.map((info) => checkIfCompleted(info))}
            </TableHead>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

UpdatePricingColumnDialog.defaultProps = {
  isOpen: true,
  missingSkuInfo: [],
  closeDialog: () => console.log('Dialog was closed.'),
};

UpdatePricingColumnDialog.propTypes = {
  isOpen: PropTypes.bool,
  missingSkuInfo: PropTypes.arrayOf(PropTypes.shape()),
  closeDialog: PropTypes.func,
};

export default UpdatePricingColumnDialog;
