/* eslint-disable react/jsx-props-no-spreading */
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { resetState, setTotalImageUploadCount, uploadNewImage } from './slice';
import ContentWrapper from '../../../components/ContentWrapper';
import ImagePreviewCard from './components/ImagePreviewCard';
import PageHeader from '../../../components/PageHeader';

const useStyles = makeStyles((theme) => ({
  actionsBar: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  loadingWrapper: {
    padding: theme.spacing(4),
    width: '100%',
    textAlign: 'center',
  },
  wrapperWizard: {
    width: '100%',
    padding: theme.spacing(4),
  },
}));

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#171415',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  marginTop: 24,
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const breadcrumbs = [
  {
    label: 'Customer Relationship Management',
    link: '/crm',
  },
  {
    label: 'Queries',
    link: '/crm/queries',
  },
];

const ClientsQueryImageUpload = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { queryId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setFiles([...files, ...newFiles]);
    },
  });

  const imageLoadingCounts = useSelector(
    (state) => state.clientsQueryImageUpload.imageUploadCounts
  );
  const uploadTransfers = useSelector(
    (state) => state.clientsQueryImageUpload.uploadTransfers
  );

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  useEffect(() => {
    if (
      imageLoadingCounts.total >
      imageLoadingCounts.success + imageLoadingCounts.failed
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [imageLoadingCounts]);

  useEffect(() => {
    if (
      imageLoadingCounts.total > 0 &&
      imageLoadingCounts.total ===
        imageLoadingCounts.success + imageLoadingCounts.failed
    ) {
      dispatch(resetState());
      setIsLoading(true);
      history.goBack();
    }
  }, [imageLoadingCounts]);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const getProgress = () => {
    const totalBytes = Object.values(uploadTransfers).reduce(
      (total, current) => {
        return total + current.totalBytes;
      },
      0
    );
    const transferredBytes = Object.values(uploadTransfers).reduce(
      (total, current) => {
        return total + current.transferredBytes;
      },
      0
    );
    if (totalBytes === 0) {
      return 0;
    }
    return Math.round((transferredBytes / totalBytes) * 100);
  };

  return (
    <div>
      <PageHeader
        isLoading={isLoading}
        title="Upload Images"
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Images"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
      />
      <ContentWrapper>
        <Paper className={classes.wrapperWizard}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom>
                {isLoading ? 'Loading...' : `Images for Query ${queryId}`}
              </Typography>
              <Typography variant="body1" gutterBottom>
                Only JPEG or PNG files allowed.
              </Typography>
              <div className="container">
                {imageLoadingCounts.total === 0 ? (
                  <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    <p>
                      Drag &rsquo;n&lsquo; drop some files here, or click to
                      select files
                    </p>
                  </div>
                ) : (
                  <div className={classes.loadingWrapper}>
                    <Box position="relative" display="inline-flex">
                      <CircularProgress
                        value={getProgress()}
                        size={64}
                        variant="determinate"
                        color="secondary"
                      />
                      <Box
                        top={0}
                        left={0}
                        bottom={0}
                        right={0}
                        position="absolute"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography variant="body1" component="div">
                          {`${getProgress()}%`}
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                )}
              </div>
            </Grid>
            {files.map((file, index) => (
              <Grid key={file.preview} item xs={3}>
                <ImagePreviewCard
                  isLoading={isLoading}
                  imageLink={file.preview}
                  removeImage={() =>
                    setFiles(
                      files.filter(
                        (filterFile, filterIndex) => filterIndex !== index
                      )
                    )
                  }
                  localImageName={files[index].name}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <div className={classes.actionsBar}>
                <Button
                  disabled={isLoading}
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    dispatch(setTotalImageUploadCount(files.length));
                    files.map((file) =>
                      dispatch(uploadNewImage(file, queryId))
                    );
                  }}
                >
                  Upload
                </Button>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </ContentWrapper>
    </div>
  );
};

export default ClientsQueryImageUpload;
