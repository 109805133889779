import { InstantSearch } from 'react-instantsearch-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import assembleTypesenseServerConfig from '../../../utils/typesense';
import ContentWrapper from '../../../components/ContentWrapper';
import Hits from './components/Hits';
import OnboardingStatusRefinement from './components/OnboardingStatusRefinement';
import PageHeader from '../../../components/PageHeader';
import StatusRefinementList from './components/StatusRefinementList';
import SupplierRefinementList from './components/SupplierRefinementList';
import TypesenseSearchBar from '../../../components/TypesenseSearchBar';

const TYPESENSE_SERVER_CONFIG = assembleTypesenseServerConfig();
const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: TYPESENSE_SERVER_CONFIG,
  additionalSearchParameters: {
    queryBy:
      'barcode,copy.description,copy.title,brand,categories.department,categories.division,categories.lowestCategory,categories.mainCategory,copy.whatsInTheBox,supplierDescription,supplierId,supplierName,supplierSku,eolSku,tailgating.buyBoxOwner',
    numTypos: 1,
    typoTokensThreshold: 1,
  },
});
const { searchClient } = typesenseInstantsearchAdapter;
const useStyles = makeStyles((theme) => ({
  headerButtonIcon: {
    marginRight: theme.spacing(1),
  },
}));
const breadcrumbs = [
  {
    label: 'Presales',
    link: '/presales',
  },
  {
    label: 'Pipeline',
    link: '/presales/pipeline',
  },
];
const PipelineView = () => {
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const suppliersLoadingStatus = useSelector(
    (state) => state.presalesPipelineView.loadingStatus.suppliers
  );
  useEffect(() => {
    if (suppliersLoadingStatus === 'PENDING') {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [suppliersLoadingStatus]);
  return (
    <div>
      <PageHeader
        title="Pipeline"
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="View"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
        actions={[
          {
            label: 'Add New Products',
            icon: <AddIcon className={classes.headerButtonIcon} />,
            onClick: () => history.push('/presales/pipeline/view/bulk-upload'),
          },
        ]}
      />
      {isLoading && <LinearProgress color="secondary" />}
      <ContentWrapper>
        <InstantSearch
          searchClient={searchClient}
          indexName="presalesSkus"
          routing
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TypesenseSearchBar />
            </Grid>
            <Grid item xs={6} sm={9}>
              <Hits goToLink={(path) => history.push(path)} />
            </Grid>
            <Grid item xs={6} sm={3}>
              <OnboardingStatusRefinement attribute="onboardingStatuses.measurementsComplete" />
              <StatusRefinementList attribute="status" />
              <SupplierRefinementList attribute="supplierName" />
            </Grid>
          </Grid>
        </InstantSearch>
      </ContentWrapper>
    </div>
  );
};
export default PipelineView;
