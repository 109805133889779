/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { bulkUpdate, getPresalesSuppliers } from '../../../api/presalesAPI';

const initialState = {
  isUserRedirectedToBrowse: false,
  suppliers: [],
  loadingStatus: {
    creation: 'NOT_STARTED',
    agencies: 'NOT_STARTED',
  },
  failedBulkSkus: [],
  bulkStockUploadLoadingCount: {
    total: 0,
    success: 0,
    failed: 0,
  },
  feedback: {
    isOpen: false,
    type: undefined,
    message: '',
  },
};

const presalesSkuUploadSlice = createSlice({
  name: 'presalesSkuUpload',
  initialState,
  reducers: {
    fetchPresalesSuppliersStarted(state) {
      state.loadingStatus.suppliers = 'PENDING';
    },
    fetchPresalesSuppliersSuccess(state, action) {
      state.suppliers = action.payload;
      state.loadingStatus.suppliers = 'COMPLETE';
    },
    fetchPresalesSuppliersFailed(state, action) {
      state.loadingStatus.suppliers = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    updateTotalSkusToUpdate(state, action) {
      state.failedBulkSkus = [];
      state.bulkStockUploadLoadingCount.total = action.payload;
      state.bulkStockUploadLoadingCount.success = 0;
      state.bulkStockUploadLoadingCount.failed = 0;
    },
    bulkStockUpdateSuccess(state) {
      state.bulkStockUploadLoadingCount.success += 1;
    },
    bulkStockUpdateFailed(state, action) {
      state.bulkStockUploadLoadingCount.failed += 1;
      state.failedBulkSkus.push(action.payload);
    },
    resetState() {
      return initialState;
    },
  },
});

export const {
  fetchPresalesSuppliersStarted,
  fetchPresalesSuppliersSuccess,
  fetchPresalesSuppliersFailed,
  updateTotalSkusToUpdate,
  bulkStockUpdateSuccess,
  bulkStockUpdateFailed,
  resetState,
} = presalesSkuUploadSlice.actions;

export default presalesSkuUploadSlice.reducer;

export const fetchPresalesSuppliers = () => async (dispatch) => {
  dispatch(fetchPresalesSuppliersStarted());
  const result = await getPresalesSuppliers();

  if (result.status === 200) {
    dispatch(fetchPresalesSuppliersSuccess(result.suppliers));
  } else {
    dispatch(fetchPresalesSuppliersFailed(result.error));
  }
};

export const setTotalSkusToUpdate = (total) => (dispatch) => {
  dispatch(updateTotalSkusToUpdate(total));
};

export const bulkStockUpdate = (skuInfo) => async (dispatch) => {
  const result = await bulkUpdate(skuInfo);

  if (result.status === 200) {
    dispatch(bulkStockUpdateSuccess());
  } else {
    dispatch(
      bulkStockUpdateFailed({
        sku: result.sku,
        eolSku: result.eolSku,
      })
    );
  }
};

export const initiateStateReset = () => async (dispatch) => {
  dispatch(resetState());
};
