/* eslint-disable react/jsx-props-no-spreading */
import _ from 'lodash';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const headerStyle = {
  backgroundColor: '#171415',
};

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.common.black,
  },
  link: {
    color: theme.palette.common.black,
  },
  loadingOverlay: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: fade(theme.palette.common.black, 0.8),
  },
  wrapperContent: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

const SkusTable = ({
  isLoading,
  skus,
  viewSku,
  changeStockInfo,
  platform,
  pageSize,
  initialPage,
  updatePageSize,
  updateInitialPage,
}) => {
  const classes = useStyles();

  const getColumns = () => {
    const columns = [
      { title: 'SKU', field: 'sku', editable: false },
      {
        title: platform === 'AMAZON' ? 'ASIN' : 'TSIN',
        field: platform === 'AMAZON' ? 'asin' : 'tsin',
        editable: false,
      },
      {
        title: 'Product',
        render: (rowData) => {
          const productTitle =
            rowData.variation === 'N/A'
              ? `${rowData.brand} - ${rowData.productName}`
              : `${rowData.brand} - ${rowData.productName} (${rowData.variation})`;
          return (
            <Tooltip title={productTitle} aria-label="add" interactive>
              <Typography variant="body2" component="span">
                {_.truncate(productTitle)}
              </Typography>
            </Tooltip>
          );
        },
      },
      { title: 'Weight (kg)', field: 'weight', type: 'numeric' },
      { title: 'Length (cm)', field: 'length', type: 'numeric' },
      { title: 'Width (cm)', field: 'width', type: 'numeric' },
      { title: 'Height (cm)', field: 'height', type: 'numeric' },
      {
        title: 'Creation Date',
        field: 'creationDate',
        hidden: true,
        export: true,
      },
    ];

    return columns;
  };

  return (
    <div className={classes.wrapperContent}>
      <MaterialTable
        title=""
        columns={getColumns()}
        data={skus}
        isLoading={isLoading}
        onChangeRowsPerPage={updatePageSize}
        onChangePage={updateInitialPage}
        options={{
          pageSize,
          initialPage,
          headerStyle,
          actionsColumnIndex: -1,
          search: false,
          grouping: false,
          draggable: false,
          exportButton: true,
          exportAllData: true,
          exportFileName: 'Listings',
        }}
        editable={{
          onRowUpdate: (newData) =>
            new Promise((resolve) => {
              changeStockInfo(
                Number.isNaN(parseFloat(newData.weight))
                  ? null
                  : parseFloat(newData.weight),
                Number.isNaN(parseFloat(newData.length))
                  ? null
                  : parseFloat(newData.length),
                Number.isNaN(parseFloat(newData.width))
                  ? null
                  : parseFloat(newData.width),
                Number.isNaN(parseFloat(newData.height))
                  ? null
                  : parseFloat(newData.height),
                newData.stockImagesLink || '',
                newData.sku
              );
              resolve();
            }),
        }}
        actions={[
          {
            icon: 'keyboard_arrow_right',
            tooltip: 'View SKU info',
            onClick: (event, rowData) => viewSku(rowData.sku),
          },
        ]}
        components={{
          OverlayLoading: () => (
            <div className={classes.loadingOverlay}>
              <CircularProgress color="primary" size={32} />
            </div>
          ),
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'No listings to display',
          },
        }}
      />
    </div>
  );
};

SkusTable.defaultProps = {
  isLoading: false,
  skus: [],
  viewSku: (sku) => console.log(`SKU viewed: ${sku}`),
  changeStockInfo: (sku) => console.log(`SKU edited: ${sku}`),
  platform: 'AMAZON',
  pageSize: 5,
  initialPage: 0,
  updatePageSize: (newPageSize) =>
    console.log(`Page size updated to: ${newPageSize}`),
  updateInitialPage: (newInitialPage) =>
    console.log(`Initial page updated to: ${newInitialPage}`),
};

SkusTable.propTypes = {
  isLoading: PropTypes.bool,
  skus: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      brand: PropTypes.string,
      clientId: PropTypes.string,
      clientName: PropTypes.string,
      productName: PropTypes.string,
      variation: PropTypes.string,
      stockImagesLink: PropTypes.string,
      status: PropTypes.oneOf(['ACTIVE', 'INACTIVE']),
    })
  ),
  viewSku: PropTypes.func,
  changeStockInfo: PropTypes.func,
  platform: PropTypes.string,
  pageSize: PropTypes.number,
  initialPage: PropTypes.number,
  updatePageSize: PropTypes.func,
  updateInitialPage: PropTypes.func,
};

export default SkusTable;
