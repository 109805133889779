import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import numeral from 'numeral';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {
  createDeal,
  fetchClients,
  fetchProducts,
  fetchSalesAgents,
  initiateStateReset,
} from './slice';
import takealotCategoryData from './takealotCategories';
import ContentWrapper from '../../../components/ContentWrapper';
import FiltersBar from './components/FiltersBar';
import PageHeader from '../../../components/PageHeader';
import ProductSelectionTable from './components/ProductSelectionTable';
import SKUAdditionTable from './components/SKUAdditionTable';
import SKUCategoryTable from './components/SKUCategoryTable';
import SKUPricingTakealotTable from './components/SKUPricingTakealotTable';
import SKUWeightAndDimensionsTable from './components/SKUWeightAndDimensionsTable';

const useStyles = makeStyles((theme) => ({
  actionsBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  alert: {
    marginBottom: theme.spacing(3),
  },
  formControl: {
    minWidth: 200,
  },
  formWrapper: {
    padding: `${theme.spacing(4)}px ${theme.spacing(1)}px`,
  },
  formSectionWrapper: {
    display: 'flex',
  },
  separator: {
    height: theme.spacing(2),
  },
  separatorVertical: {
    width: theme.spacing(2),
  },
  wrapperStep: {
    padding: `${theme.spacing(4)}px 0px`,
  },
  wrapperWizard: {
    width: '100%',
    padding: theme.spacing(4),
  },
}));

const breadcrumbs = [
  {
    label: 'Sales',
    link: '/sales',
  },
  {
    label: 'Deals',
    link: '/sales/deals',
  },
];

const DealsCreate = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [dealType, setDealType] = useState('NEW');
  const [dealerCode, setDealerCode] = useState('');
  const [selectedClientId, setSelectedClientId] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [dealName, setDealName] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [skus, setSkus] = useState([]);
  const [selectedAgentId, setSelectedAgentId] = useState('');
  const [selectedAgentName, setSelectedAgentName] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [supplierFilter, setSupplierFilter] = useState('ALL');
  const [selectedFilter, setSelectedFilter] = useState('ALL');
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [validation, setValidation] = useState({
    isErrorShowing: false,
    message: '',
    errorStep: 0,
  });

  const loadingStatusCreation = useSelector(
    (state) => state.dealsCreate.loadingStatus.creation
  );
  const loadingStatusSalesAgents = useSelector(
    (state) => state.dealsCreate.loadingStatus.salesAgents
  );
  const loadingStatusClients = useSelector(
    (state) => state.dealsCreate.loadingStatus.clients
  );
  const loadingStatusProducts = useSelector(
    (state) => state.dealsCreate.loadingStatus.products
  );
  const isUserRedirectedToBrowse = useSelector(
    (state) => state.dealsCreate.isUserRedirectedToBrowse
  );
  const userInfo = useSelector((state) => state.account.userInfo);
  const salesAgents = useSelector((state) => state.dealsCreate.salesAgents);
  const clients = useSelector((state) => state.dealsCreate.clients);
  const products = useSelector((state) => state.dealsCreate.products);

  useEffect(() => {
    if (userInfo.type !== 'SALES_AGENT') {
      dispatch(fetchSalesAgents());
    }
    dispatch(fetchClients());
    setSelectedAgentId(userInfo.uid);
    setSelectedAgentName(`${userInfo.name} ${userInfo.surname}`);
  }, []);

  useEffect(() => {
    if (
      loadingStatusCreation === 'PENDING' ||
      loadingStatusSalesAgents === 'PENDING' ||
      loadingStatusClients === 'PENDING' ||
      loadingStatusProducts === 'PENDING'
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [
    loadingStatusCreation,
    loadingStatusSalesAgents,
    loadingStatusClients,
    loadingStatusProducts,
  ]);

  useEffect(() => {
    if (isUserRedirectedToBrowse) {
      dispatch(initiateStateReset());
      setIsLoading(true);
      history.goBack();
    }
  }, [isUserRedirectedToBrowse]);

  useEffect(() => {
    if (dealType === 'SUPER_DEALER' && loadingStatusProducts !== 'COMPLETE') {
      dispatch(fetchProducts());
    }
  }, [dealType]);

  const updateSkus = (newSkus) => {
    setSkus(newSkus);
  };

  const getPhotosCount = (cleanedSkus) => {
    let photosCount = 0;

    cleanedSkus.forEach((sku) => {
      photosCount += sku.photos;
    });

    return photosCount;
  };

  const getPlatform = () => {
    let isAmazon = false;
    let isTakealot = false;

    skus.forEach((sku) => {
      if (sku.platform === 'AMAZON') {
        isAmazon = true;
      } else if (sku.platform === 'TAKEALOT') {
        isTakealot = true;
      } else {
        isAmazon = true;
        isTakealot = true;
      }
    });

    if (isAmazon && isTakealot) {
      return 'AMAZON_TAKEALOT';
    }
    if (isAmazon) {
      return 'AMAZON';
    }
    return 'TAKEALOT';
  };

  const getTakealotSkus = () => {
    return skus.filter(
      (sku) => sku.platform === 'TAKEALOT' || sku.platform === 'AMAZON_TAKEALOT'
    );
  };

  const isAllWeightAndDimensionsComplete = () => {
    let isComplete = true;

    skus.forEach((sku) => {
      if (
        sku.weight <= 0 ||
        sku.height <= 0 ||
        sku.length <= 0 ||
        sku.width <= 0
      ) {
        isComplete = false;
      }
    });

    return isComplete;
  };

  const isPricingComplete = () => {
    let isComplete = true;

    skus.forEach((sku) => {
      if (
        (sku.wholesalePrice <= 0 && sku.retailPrice <= 0) ||
        sku.wholesalePrice < 0 ||
        sku.retailPrice < 0
      ) {
        isComplete = false;
      }
    });

    return isComplete;
  };

  const getAgentName = (agentId) => {
    const agentInfo = salesAgents.find((info) => info.id === agentId);
    if (agentInfo === undefined) {
      return `${userInfo.name} ${userInfo.surname}`;
    }
    return agentInfo.name;
  };

  const getClientName = (clientId) => {
    const clientInfo = clients.find((info) => info.id === clientId);
    if (clientInfo === undefined) {
      return '';
    }
    return clientInfo.name;
  };

  const getDealerCode = (clientId) => {
    const clientInfo = clients.find((info) => info.id === clientId);
    if (clientInfo === undefined) {
      return '';
    }
    return clientInfo.dealerCode;
  };

  const getSizeCategory = (volume) => {
    if (volume <= 35000) {
      return 'Standard';
    }
    if (volume <= 130000) {
      return 'Large';
    }
    if (volume <= 200000) {
      return 'Oversize';
    }
    if (volume <= 545000) {
      return 'Bulky';
    }
    return 'Extra Bulky';
  };

  const getWeightCategory = (weight) => {
    if (weight <= 7) {
      return 'Light';
    }
    if (weight <= 25) {
      return 'Heavy';
    }
    if (weight <= 40) {
      return 'Heavy Plus';
    }
    return 'Very Heavy';
  };

  const getFulfilmentFee = (sizeCategory, weightCategory) => {
    switch (sizeCategory) {
      case 'Standard':
        switch (weightCategory) {
          case 'Light':
            return 32;
          case 'Heavy':
            return 36;
          case 'Heavy Plus':
            return 85;
          case 'Very Heavy':
            return 85;
          default:
            return 0;
        }
      case 'Large':
        switch (weightCategory) {
          case 'Light':
            return 42;
          case 'Heavy':
            return 46;
          case 'Heavy Plus':
            return 85;
          case 'Very Heavy':
            return 95;
          default:
            return 0;
        }
      case 'Oversize':
        switch (weightCategory) {
          case 'Light':
            return 95;
          case 'Heavy':
            return 105;
          case 'Heavy Plus':
            return 125;
          case 'Very Heavy':
            return 100;
          default:
            return 0;
        }
      case 'Bulky':
        switch (weightCategory) {
          case 'Light':
            return 95;
          case 'Heavy':
            return 115;
          case 'Heavy Plus':
            return 130;
          case 'Very Heavy':
            return 140;
          default:
            return 0;
        }
      case 'Extra Bulky':
        switch (weightCategory) {
          case 'Light':
            return 220;
          case 'Heavy':
            return 220;
          case 'Heavy Plus':
            return 270;
          case 'Very Heavy':
            return 325;
          default:
            return 0;
        }
      default:
        return 0;
    }
  };

  const calculateRetailPrice = (flatFees, wholesalePrice, margins) => {
    return (flatFees + wholesalePrice) / (1 - margins);
  };

  const calculateWholesalePrice = (
    flatFees,
    insurancePercentage,
    retailPrice,
    margins
  ) => {
    return (
      (retailPrice * (1 - margins) - flatFees) / (1 + 0.5 * insurancePercentage)
    );
  };

  const getUnits = () => {
    return 8;
  };

  const getSuccessFeePercentage = (department, category, subCategory) => {
    const relevantSubCategory = takealotCategoryData.find(
      (data) =>
        data.department === department &&
        data.category === category &&
        data.subCategory === subCategory
    );
    return relevantSubCategory.successFee || 0;
  };

  const updateBasedOnRetailPricing = (newSkus, id) => {
    const newSkusWithPricing = newSkus.map((skuInfo) => {
      const retailPrice = numeral(skuInfo.retailPrice).value();

      if (skuInfo.id !== id || !retailPrice) {
        return skuInfo;
      }
      const {
        length,
        width,
        height,
        weight,
        department,
        category,
        subCategory,
      } = skuInfo;
      const volume = length * width * height;
      const insurancePercentage = 0.0075;
      const handlingFee = 5;
      let costPrice = numeral(skuInfo.costPrice).value();

      const sizeCategory = getSizeCategory(volume);
      const weightCategory = getWeightCategory(weight);
      const fulfilmentFee = getFulfilmentFee(sizeCategory, weightCategory);
      const successFeePercentage =
        getSuccessFeePercentage(department, category, subCategory) * 1.15;
      const fulfilmentFeeVat = fulfilmentFee * 1.15;
      const easyonlineMarginPercentage = 0.1;
      const totalMargin = successFeePercentage + easyonlineMarginPercentage;

      const actualWholesalePrice = calculateWholesalePrice(
        fulfilmentFeeVat + handlingFee,
        insurancePercentage,
        retailPrice,
        totalMargin
      );

      if (!costPrice || costPrice >= actualWholesalePrice) {
        costPrice = actualWholesalePrice / 2;
      }

      const pricing = {
        retailPrice,
        costPrice,
        wholesalePrice: actualWholesalePrice,
      };

      return {
        ...skuInfo,
        costPrice: pricing.costPrice.toFixed(2),
        wholesalePrice: pricing.wholesalePrice.toFixed(2),
        retailPrice: pricing.retailPrice.toFixed(0),
      };
    });
    setSkus(newSkusWithPricing);
  };

  const updateBasedOnWholesalePricing = (newSkus, id) => {
    const newSkusWithPricing = newSkus.map((skuInfo) => {
      const wholesalePrice = numeral(skuInfo.wholesalePrice).value();

      if (skuInfo.id !== id || !wholesalePrice) {
        return skuInfo;
      }
      const {
        length,
        width,
        height,
        weight,
        department,
        category,
        subCategory,
      } = skuInfo;
      const volume = length * width * height;
      const insurancePercentage = 0.0075;
      const handlingFee = 5;
      let costPrice = numeral(skuInfo.costPrice).value() || wholesalePrice / 2;

      if (costPrice >= wholesalePrice) {
        costPrice = wholesalePrice / 2;
      }

      const sizeCategory = getSizeCategory(volume);
      const weightCategory = getWeightCategory(weight);
      const fulfilmentFee = getFulfilmentFee(sizeCategory, weightCategory);
      const successFeePercentage =
        getSuccessFeePercentage(department, category, subCategory) * 1.15;
      const fulfilmentFeeVat = fulfilmentFee * 1.15;
      const easyonlineMarginPercentage = 0.1;
      const totalMargin = successFeePercentage + easyonlineMarginPercentage;

      const insuranceFee = costPrice * insurancePercentage;
      const actualRetailPrice = Math.ceil(
        calculateRetailPrice(
          fulfilmentFeeVat + insuranceFee + handlingFee,
          wholesalePrice,
          totalMargin
        )
      );
      const pricing = {
        wholesalePrice,
        costPrice: costPrice === 0 ? wholesalePrice / 2 : costPrice,
        retailPrice: actualRetailPrice,
      };

      return {
        ...skuInfo,
        costPrice: pricing.costPrice.toFixed(2),
        wholesalePrice: pricing.wholesalePrice.toFixed(2),
        retailPrice: pricing.retailPrice.toFixed(0),
      };
    });
    setSkus(newSkusWithPricing);
  };

  const updateBasedOnCostPricing = (newSkus, id) => {
    const newSkusWithPricing = newSkus.map((skuInfo) => {
      const costPrice = numeral(skuInfo.costPrice).value();

      if (skuInfo.id !== id || !costPrice) {
        return skuInfo;
      }
      const {
        length,
        width,
        height,
        weight,
        department,
        category,
        subCategory,
      } = skuInfo;
      const volume = length * width * height;
      const insurancePercentage = 0.0075;
      const handlingFee = 5;
      let wholesalePrice =
        numeral(skuInfo.wholesalePrice).value() || costPrice * 2;

      if (wholesalePrice <= costPrice) {
        wholesalePrice = costPrice * 2;
      }

      const sizeCategory = getSizeCategory(volume);
      const weightCategory = getWeightCategory(weight);
      const fulfilmentFee = getFulfilmentFee(sizeCategory, weightCategory);
      const successFeePercentage =
        getSuccessFeePercentage(department, category, subCategory) * 1.15;
      const fulfilmentFeeVat = fulfilmentFee * 1.15;
      const easyonlineMarginPercentage = 0.1;
      const totalMargin = successFeePercentage + easyonlineMarginPercentage;

      const insuranceFee = costPrice * insurancePercentage;
      const calculatedWholesalePrice =
        wholesalePrice === 0 ? costPrice * 2 : wholesalePrice;
      const actualRetailPrice = Math.ceil(
        calculateRetailPrice(
          fulfilmentFeeVat + insuranceFee + handlingFee,
          calculatedWholesalePrice,
          totalMargin
        )
      );
      const pricing = {
        costPrice,
        wholesalePrice: calculatedWholesalePrice,
        retailPrice: actualRetailPrice,
      };

      return {
        ...skuInfo,
        costPrice: pricing.costPrice.toFixed(2),
        wholesalePrice: pricing.wholesalePrice.toFixed(2),
        retailPrice: pricing.retailPrice.toFixed(0),
      };
    });
    setSkus(newSkusWithPricing);
  };

  const cleanSkus = () => {
    return skus.map((skuInfo) => {
      const {
        description,
        length,
        width,
        height,
        weight,
        department,
        category,
        subCategory,
        costPrice,
        wholesalePrice,
        retailPrice,
        imageCount,
      } = skuInfo;
      const volume = length * width * height;
      const insurancePercentage = 0.0075;
      const handlingFee = 5;

      const sizeCategory = getSizeCategory(volume);
      const weightCategory = getWeightCategory(weight);
      const fulfilmentFee = getFulfilmentFee(sizeCategory, weightCategory);
      const fulfilmentFeeVat = fulfilmentFee * 1.15;
      const successFeePercentage = getSuccessFeePercentage(
        department,
        category,
        subCategory
      );
      const easyonlineMarginPercentage = 0.1;
      const totalMargin = successFeePercentage + easyonlineMarginPercentage;
      const successFee = parseFloat(retailPrice) * successFeePercentage;
      const successFeeVat =
        parseFloat(retailPrice) * successFeePercentage * 1.15;
      const easyonlineFee = parseFloat(retailPrice) * 0.1;
      const insuranceFee = parseFloat(costPrice) * insurancePercentage;
      const units = getUnits(weight);
      const photos = parseInt(imageCount, 10);

      return {
        description,
        units,
        stockInfo: {
          volume,
          weight: parseFloat(weight),
          height: parseFloat(height),
          length: parseFloat(length),
          width: parseFloat(width),
        },
        categories: {
          department,
          category,
          subCategory,
          size: sizeCategory,
          weight: weightCategory,
        },
        margins: {
          takealotSuccessFee: successFeePercentage,
          easyonlineMargin: easyonlineMarginPercentage,
          total: totalMargin,
        },
        fees: {
          handlingFee,
          fulfilmentFee,
          fulfilmentFeeVat,
          successFee,
          successFeeVat,
          easyonlineFee,
          insuranceFee,
        },
        pricing: {
          costPrice: parseFloat(costPrice),
          wholesalePrice: parseFloat(wholesalePrice),
          retailPrice: parseFloat(retailPrice),
        },
        photos,
      };
    });
  };

  const getOnboardingUnitPrice = (numberOfSkus) => {
    switch (numberOfSkus) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        return 1000;
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
        return 500;
      default:
        return 250;
    }
  };

  const checkIfSelected = (productId) => {
    const relevantProduct = selectedProducts.find(
      (selectedProductInfo) => selectedProductInfo.id === productId
    );

    if (relevantProduct) {
      return true;
    }
    return false;
  };

  const getFormattedProducts = () => {
    return products
      .map((productInfo, index) => ({
        id: productInfo.id,
        description: productInfo.description,
        supplierName: productInfo.supplierName,
        supplierId: productInfo.supplierId,
        costPrice: productInfo.pricing.costPrice,
        wholesalePrice: productInfo.pricing.wholesalePrice,
        retailPrice: productInfo.pricing.retailPrice,
        tableData: {
          id: index,
          checked: checkIfSelected(productInfo.id),
        },
      }))
      .filter((productInfo) => {
        if (searchQuery !== '') {
          const caseInsensitiveSearch = _.toLower(searchQuery);
          const caseInsensitiveDescription = _.toLower(productInfo.description);
          const caseInsensitiveSupplier = _.toLower(productInfo.supplierName);

          if (caseInsensitiveDescription.includes(caseInsensitiveSearch)) {
            return true;
          }
          if (caseInsensitiveSupplier.includes(caseInsensitiveSearch)) {
            return true;
          }

          return false;
        }

        return true;
      })
      .filter((productInfo) => {
        if (supplierFilter === 'ALL') {
          return true;
        }
        return supplierFilter === productInfo.supplierName;
      })
      .filter((productInfo) => {
        if (selectedFilter === 'ALL') {
          return true;
        }
        if (selectedFilter === 'SELECTED_ONLY') {
          return productInfo.tableData.checked;
        }
        return !productInfo.tableData.checked;
      });
  };

  const getFinalSelectedProducts = () => {
    return selectedProducts.map((productInfo) => {
      const relevantProductInfo = products.find(
        (potentialProductInfo) => potentialProductInfo.id === productInfo.id
      );
      return relevantProductInfo;
    });
  };

  const onNextClick = () => {
    switch (activeStep) {
      case 0:
        if (
          businessName === '' ||
          (dealType === 'NEW' && websiteUrl === '') ||
          (dealType === 'ADD_ON' && selectedClientId === '')
        ) {
          setValidation({
            isErrorShowing: true,
            message: 'Please complete all fields.',
            errorStep: 0,
          });
        } else {
          setValidation({
            isErrorShowing: false,
            message: '',
            errorStep: 0,
          });
          setActiveStep(1);
        }
        break;
      case 1:
        if (dealType === 'SUPER_DEALER') {
          if (selectedProducts.length < 20) {
            setValidation({
              isErrorShowing: true,
              message: 'Please add at least 20 products to the proposal.',
              errorStep: 1,
            });
          } else if (selectedProducts.length % 10 !== 0) {
            setValidation({
              isErrorShowing: true,
              message: 'Number of products selected must be a multiple of 10.',
              errorStep: 1,
            });
          } else if (selectedProducts.length > 250) {
            setValidation({
              isErrorShowing: true,
              message: 'Max 250 characters allowed.',
              errorStep: 1,
            });
          } else {
            const currentTime = new Date();
            const salesAgentId =
              userInfo.type === 'SALES_AGENT' ? userInfo.uid : selectedAgentId;
            const salesAgentName =
              userInfo.type === 'SALES_AGENT'
                ? `${userInfo.name} ${userInfo.surname}`
                : selectedAgentName;
            const selectedProductsFinal = getFinalSelectedProducts();

            const onboardingUnitPrice = 1000;
            const onboardingTotal =
              onboardingUnitPrice * selectedProductsFinal.length;

            const setupFees = [
              {
                name: 'Product Onboarding',
                description:
                  "Includes first consignment stock pickup, professional copywriting, and the setup of the SKU on Takealot's system.",
                quantity: selectedProductsFinal.length,
                unitPrice: onboardingUnitPrice * 0.85,
                total: onboardingTotal * 0.85,
              },
            ];
            const subTotal = onboardingTotal * 0.85;
            const vat = onboardingTotal * 0.15;
            const total = subTotal + vat;

            dispatch(
              createDeal(
                {
                  dealName,
                  businessName,
                  websiteUrl,
                  dealType,
                  dealerCode,
                  batchCode: `${dealerCode}${moment().format('MMYYYY')}`,
                  clientId: selectedClientId,
                  createdBy: userInfo.uid,
                  status: 'AWAITING_PROSPECT_RESPONSE',
                  lastUpdated: currentTime,
                  creationDate: currentTime,
                  dealValue: total,
                  salesPersonName: salesAgentName,
                  salesPersonId: salesAgentId,
                  skuCount: selectedProductsFinal.length,
                  platform: 'TAKEALOT',
                },
                {
                  businessName,
                  setupFees,
                  subTotal,
                  vat,
                  total,
                  creationDate: currentTime,
                  versionNumber: 1,
                  status: 'COMPLETE',
                  type: 'TAKEALOT',
                  listingInfo: selectedProductsFinal,
                }
              )
            );
            setValidation({
              isErrorShowing: false,
              message: '',
              errorStep: 1,
            });
          }
        } else if (skus.length === 0) {
          setValidation({
            isErrorShowing: true,
            message: 'Please add at least 1 SKU to the proposal.',
            errorStep: 1,
          });
        } else {
          setValidation({
            isErrorShowing: false,
            message: '',
            errorStep: 1,
          });
          setActiveStep(2);
        }
        break;
      case 2:
        if (!isAllWeightAndDimensionsComplete()) {
          setValidation({
            isErrorShowing: true,
            message: 'Please enter valid weights and dimensions for all SKUs.',
            errorStep: 2,
          });
        } else {
          setValidation({
            isErrorShowing: false,
            message: '',
            errorStep: 2,
          });
          setActiveStep(3);
        }
        break;
      case 3:
        if (!isAllWeightAndDimensionsComplete()) {
          setValidation({
            isErrorShowing: true,
            message:
              'Select the appropriate department, category and sub-category for all SKUs.',
            errorStep: 3,
          });
        } else {
          setValidation({
            isErrorShowing: false,
            message: '',
            errorStep: 3,
          });
          setActiveStep(4);
        }
        break;
      case 4:
        if (!isPricingComplete()) {
          setValidation({
            isErrorShowing: true,
            message: 'Please enter valid prices for all SKUs.',
            errorStep: 4,
          });
        } else {
          const currentTime = new Date();
          const salesAgentId =
            userInfo.type === 'SALES_AGENT' ? userInfo.uid : selectedAgentId;
          const salesAgentName =
            userInfo.type === 'SALES_AGENT'
              ? `${userInfo.name} ${userInfo.surname}`
              : selectedAgentName;

          const cleanedSkus = cleanSkus();
          const photosCount = getPhotosCount(cleanedSkus);
          const onboardingUnitPrice =
            dealType === 'NEW'
              ? getOnboardingUnitPrice(cleanedSkus.length)
              : 250;
          const onboardingTotal = onboardingUnitPrice * cleanedSkus.length;

          const setupFees = [
            {
              name: 'SKU Onboarding',
              description:
                "Includes first consignment stock pickup, professional copywriting, and the setup of the SKU on Takealot's system.",
              quantity: cleanedSkus.length,
              unitPrice: onboardingUnitPrice,
              total: onboardingTotal,
            },
          ];
          let subTotal = onboardingTotal;
          let vat = subTotal * 0.15;
          let total = subTotal + vat;

          if (photosCount > 0) {
            setupFees.push({
              name: 'Product Photography',
              description:
                "Each image is shot by a professional product photographer and edited according to Takealot's requirements.",
              quantity: photosCount,
              unitPrice: 150,
              total: 150 * photosCount,
            });
            subTotal += 150 * photosCount;
            vat = subTotal * 0.15;
            total = subTotal + vat;
          }

          dispatch(
            createDeal(
              {
                dealName,
                businessName,
                websiteUrl,
                photosCount,
                dealType,
                clientId: selectedClientId,
                createdBy: userInfo.uid,
                status: 'AWAITING_PROSPECT_RESPONSE',
                lastUpdated: currentTime,
                creationDate: currentTime,
                dealValue: total,
                salesPersonName: salesAgentName,
                salesPersonId: salesAgentId,
                skuCount: skus.length,
                platform: getPlatform(),
              },
              {
                businessName,
                setupFees,
                subTotal,
                vat,
                total,
                creationDate: currentTime,
                versionNumber: 1,
                status: 'COMPLETE',
                type: 'TAKEALOT',
                listingInfo: cleanedSkus,
              }
            )
          );
          setValidation({
            isErrorShowing: false,
            message: '',
            errorStep: 4,
          });
        }
        break;
      default:
        console.log('Unknown step');
        break;
    }
  };

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <div className={classes.wrapperStep}>
            <Typography variant="h4" gutterBottom>
              Enter deal info
            </Typography>
            <Typography variant="body1">
              Please enter the basic details for this new deal.
            </Typography>
            <form className={classes.formWrapper}>
              <div className={classes.formSectionWrapper}>
                <FormControl className={classes.formControl}>
                  <InputLabel shrink id="deal-type-select-label">
                    Type
                  </InputLabel>
                  <Select
                    labelId="deal-type-select-label"
                    id="deal-type-select"
                    value={dealType}
                    onChange={(e) => {
                      setDealType(e.target.value);
                      setSelectedClientId('');
                      setDealName('');
                      setBusinessName('');
                      setWebsiteUrl('');
                    }}
                  >
                    <MenuItem value="NEW">New Prospect</MenuItem>
                    <MenuItem value="ADD_ON">Existing Client</MenuItem>
                    <MenuItem value="SUPER_DEALER">Super Dealer</MenuItem>
                  </Select>
                </FormControl>
                <div className={classes.separatorVertical} />
                {dealType === 'SUPER_DEALER' && (
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel shrink id="dealer-select-label">
                      Dealer
                    </InputLabel>
                    <Select
                      labelId="dealer-select-label"
                      id="dealer-select"
                      value={selectedClientId}
                      onChange={(e) => {
                        setDealerCode(getDealerCode(e.target.value));
                        setSelectedClientId(e.target.value);
                        setBusinessName(getClientName(e.target.value));
                        setDealName(
                          `${getClientName(
                            e.target.value
                          )} (Super Dealer Products List)`
                        );
                      }}
                    >
                      {clients
                        .filter(
                          (clientInfo) =>
                            clientInfo.status === 'HIGH_PRIORITY' &&
                            clientInfo.type === 'SUPER_DEALER'
                        )
                        .map((clientInfo) => (
                          <MenuItem key={clientInfo.id} value={clientInfo.id}>
                            {clientInfo.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}
                {dealType === 'ADD_ON' && (
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel shrink id="client-select-label">
                      Client
                    </InputLabel>
                    <Select
                      labelId="client-select-label"
                      id="client-select"
                      value={selectedClientId}
                      onChange={(e) => {
                        setSelectedClientId(e.target.value);
                        setBusinessName(getClientName(e.target.value));
                        setDealName(
                          `${getClientName(e.target.value)} (Add-Ons)`
                        );
                      }}
                    >
                      {clients
                        .filter(
                          (clientInfo) => clientInfo.status === 'HIGH_PRIORITY'
                        )
                        .map((clientInfo) => (
                          <MenuItem key={clientInfo.id} value={clientInfo.id}>
                            {clientInfo.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}
              </div>
              <div className={classes.separator} />
              <TextField
                label={dealType === 'NEW' ? 'Business Name' : 'Deal Name'}
                onChange={(e) => {
                  setDealName(e.target.value);
                  setBusinessName(e.target.value);
                }}
                value={dealName}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <div className={classes.separator} />
              {dealType === 'NEW' && (
                <TextField
                  label="Website URL"
                  onChange={(e) => setWebsiteUrl(e.target.value)}
                  value={websiteUrl}
                  fullWidth
                  placeholder="https://wwww.madeupwebsite.com/"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              {userInfo.type !== 'SALES_AGENT' && (
                <div>
                  <div className={classes.separator} />
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink id="sales-agent-select-label">
                      Sales Agent
                    </InputLabel>
                    <Select
                      labelId="sales-agent-select-label"
                      id="sales-agent-select"
                      value={selectedAgentId}
                      onChange={(e) => {
                        setSelectedAgentId(e.target.value);
                        setSelectedAgentName(getAgentName(e.target.value));
                      }}
                    >
                      <MenuItem value={userInfo.uid}>Me</MenuItem>
                      {salesAgents
                        .filter((agentInfo) => {
                          if (userInfo.agencyId) {
                            return agentInfo.agencyId === userInfo.agencyId;
                          }
                          return true;
                        })
                        .map((agentInfo) => (
                          <MenuItem key={agentInfo.id} value={agentInfo.id}>
                            {agentInfo.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              )}
            </form>
            {validation.isErrorShowing && validation.errorStep === 0 && (
              <Alert className={classes.alert} severity="error">
                {validation.message}
              </Alert>
            )}
          </div>
        );
      case 1:
        if (dealType === 'SUPER_DEALER') {
          return (
            <div className={classes.wrapperStep}>
              <Typography variant="h4" gutterBottom>
                Select Super Dealer&apos;s products
              </Typography>
              <Typography variant="body1">
                Please select all products that should be associated with this
                Super Dealer deal.
              </Typography>
              <div className={classes.separator} />
              <FiltersBar
                searchText={searchQuery}
                changeSearchText={setSearchQuery}
                productSelectedCount={selectedProducts.length}
                supplierFilter={supplierFilter}
                changeSupplierFilter={setSupplierFilter}
                supplierOptions={_.uniq(
                  products.map((productInfo) => productInfo.supplierName)
                )}
                selectedFilter={selectedFilter}
                changeSelectedFilter={setSelectedFilter}
              />
              <ProductSelectionTable
                products={getFormattedProducts(products)}
                updateSelectedProducts={(newSelection) => {
                  const formattedProducts = getFormattedProducts(products);
                  const trimmedSelectedProducts = selectedProducts.filter(
                    (info) => {
                      const relevantProduct = formattedProducts.findIndex(
                        (formattedProductInfo) =>
                          formattedProductInfo.id === info.id
                      );
                      if (relevantProduct === -1) {
                        return true;
                      }
                      const includedInNewSelection = newSelection.findIndex(
                        (newSelectionInfo) => newSelectionInfo.id === info.id
                      );
                      return includedInNewSelection !== -1;
                    }
                  );
                  const newlySelectedProducts = newSelection.filter((info) => {
                    const alreadySelected = selectedProducts.findIndex(
                      (selectedProductInfo) =>
                        selectedProductInfo.id === info.id
                    );
                    return alreadySelected === -1;
                  });
                  setSelectedProducts([
                    ...trimmedSelectedProducts,
                    ...newlySelectedProducts,
                  ]);
                }}
              />
              {validation.isErrorShowing && validation.errorStep === 1 && (
                <Alert className={classes.alert} severity="error">
                  {validation.message}
                </Alert>
              )}
            </div>
          );
        }
        return (
          <div className={classes.wrapperStep}>
            <Typography variant="h4" gutterBottom>
              Add SKUs with basic details
            </Typography>
            <Typography variant="body1">
              Please add all desired SKUs, which platform they should be listed
              on, and whether or not the client requires photos.
            </Typography>
            <div className={classes.separator} />
            {validation.isErrorShowing && validation.errorStep === 1 && (
              <Alert className={classes.alert} severity="error">
                {validation.message}
              </Alert>
            )}
            <SKUAdditionTable skus={skus} updateSkus={updateSkus} />
          </div>
        );
      case 2:
        return (
          <div className={classes.wrapperStep}>
            <Typography variant="h4" gutterBottom>
              Enter weight and dimensions
            </Typography>
            <Typography variant="body1">
              Please enter the packaged weight and dimensions for each SKU.
            </Typography>
            <div className={classes.separator} />
            {validation.isErrorShowing && validation.errorStep === 2 && (
              <Alert className={classes.alert} severity="error">
                {validation.message}
              </Alert>
            )}
            <SKUWeightAndDimensionsTable skus={skus} updateSkus={updateSkus} />
          </div>
        );
      case 3:
        return (
          <div className={classes.wrapperStep}>
            <Typography variant="h4" gutterBottom>
              Enter product categories
            </Typography>
            <Typography variant="body1">
              Please select the department, category and sub-category for each
              SKU so that we can accurately calculate Takealot fees.
            </Typography>
            <div className={classes.separator} />
            {validation.isErrorShowing && validation.errorStep === 3 && (
              <Alert className={classes.alert} severity="error">
                {validation.message}
              </Alert>
            )}
            <SKUCategoryTable
              categoryData={takealotCategoryData}
              skus={getTakealotSkus()}
              updateSkus={updateSkus}
            />
          </div>
        );
      case 4:
        return (
          <div className={classes.wrapperStep}>
            <Typography variant="h4" gutterBottom>
              Enter pricing details
            </Typography>
            <Typography variant="body1">
              Please enter the desired pricing on Takealot for each SKU.
            </Typography>
            <div className={classes.separator} />
            {validation.isErrorShowing && validation.errorStep === 3 && (
              <Alert className={classes.alert} severity="error">
                {validation.message}
              </Alert>
            )}
            <SKUPricingTakealotTable
              skus={getTakealotSkus()}
              updateSkus={updateSkus}
              updateBasedOnCostPricing={(id) =>
                updateBasedOnCostPricing(skus, id)
              }
              updateBasedOnWholesalePricing={(id) =>
                updateBasedOnWholesalePricing(skus, id)
              }
              updateBasedOnRetailPricing={(id) =>
                updateBasedOnRetailPricing(skus, id)
              }
            />
          </div>
        );
      default:
        return <div />;
    }
  };

  return (
    <div>
      <PageHeader
        isLoading={isLoading}
        title="Deal Creation"
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Create"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
      />
      <ContentWrapper>
        <Paper className={classes.wrapperWizard}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              {dealType === 'SUPER_DEALER' ? (
                <Stepper activeStep={activeStep} orientation="vertical">
                  <Step>
                    <StepLabel>Deal Details</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>Product Selection</StepLabel>
                  </Step>
                </Stepper>
              ) : (
                <Stepper activeStep={activeStep} orientation="vertical">
                  <Step>
                    <StepLabel>Deal Details</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>SKUs</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>Weight & Dimensions</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>Categories</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>Pricing</StepLabel>
                  </Step>
                </Stepper>
              )}
            </Grid>
            <Grid item xs={9}>
              {getStepContent()}
              <div className={classes.actionsBar}>
                <Button
                  disabled={activeStep === 0 || isLoading}
                  onClick={() => setActiveStep(activeStep - 1)}
                >
                  Previous
                </Button>
                <Button
                  disabled={isLoading}
                  variant="contained"
                  color="secondary"
                  onClick={onNextClick}
                >
                  {(dealType === 'SUPER_DEALER' && activeStep !== 1) ||
                  (dealType !== 'SUPER_DEALER' && activeStep !== 4)
                    ? 'Next'
                    : 'Create'}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </ContentWrapper>
    </div>
  );
};

export default DealsCreate;
