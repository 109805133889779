import _ from 'lodash';

export const getShipmentPlatform = (fromId, toId) => {
  let platform = 'BOTH';

  if (fromId === 'baGsBI92BgjNEB9r0tPT' || toId === 'baGsBI92BgjNEB9r0tPT') {
    platform = 'AMAZON';
  }
  if (
    fromId === 'ktwmpFt2DUXU1wsC59EJ' ||
    toId === 'ktwmpFt2DUXU1wsC59EJ' ||
    fromId === 't6NDHxRtKUiOhmv64ptz' ||
    toId === 't6NDHxRtKUiOhmv64ptz'
  ) {
    platform = 'TAKEALOT';
  }

  return platform;
};

export const getTotalInsuranceValue = (skus) => {
  return skus.reduce((total, current) => {
    return total + current.costPrice * current.units;
  }, 0);
};

export const getFilteredSkus = (skus, searchText) => {
  return (skus || []).filter((skuInfo) => {
    if (searchText !== '') {
      const caseInsensitiveSearch = _.toLower(searchText);
      const caseInsensitiveSku = _.toLower(skuInfo.sku);
      const caseInsensitiveAsin = _.toLower(skuInfo.asin);
      const caseInsensitiveTsin = _.toLower(skuInfo.tsin);
      const caseInsensitiveVariation = _.toLower(skuInfo.variation);
      const caseInsensitiveProductName = _.toLower(skuInfo.productName);
      const caseInsensitiveClientName = _.toLower(skuInfo.clientName);
      const caseInsensitiveBrand = _.toLower(skuInfo.brand);
      const caseInsensitiveBarcode = _.toLower(skuInfo.barcode);
      const caseInsensitiveExpectedUnits = _.toLower(skuInfo.expectedUnits);
      const caseInsensitiveReceivedUnits = _.toLower(skuInfo.receivedUnits);
      const caseInsensitiveProduct = _.toLower(skuInfo.product);
      const caseInsensitiveRequestedUnits = _.toLower(skuInfo.requestedUnits);
      const caseInsensitiveAdjustedUnits = _.toLower(skuInfo.adjustedUnits);

      if (caseInsensitiveSku.includes(caseInsensitiveSearch)) {
        return true;
      }
      if (caseInsensitiveAsin.includes(caseInsensitiveSearch)) {
        return true;
      }
      if (caseInsensitiveTsin.includes(caseInsensitiveSearch)) {
        return true;
      }
      if (caseInsensitiveVariation.includes(caseInsensitiveSearch)) {
        return true;
      }
      if (caseInsensitiveProductName.includes(caseInsensitiveSearch)) {
        return true;
      }
      if (caseInsensitiveClientName.includes(caseInsensitiveSearch)) {
        return true;
      }
      if (caseInsensitiveBrand.includes(caseInsensitiveSearch)) {
        return true;
      }
      if (caseInsensitiveBarcode.includes(caseInsensitiveSearch)) {
        return true;
      }
      if (caseInsensitiveExpectedUnits.includes(caseInsensitiveSearch)) {
        return true;
      }
      if (caseInsensitiveReceivedUnits.includes(caseInsensitiveSearch)) {
        return true;
      }
      if (caseInsensitiveRequestedUnits.includes(caseInsensitiveSearch)) {
        return true;
      }
      if (caseInsensitiveAdjustedUnits.includes(caseInsensitiveSearch)) {
        return true;
      }
      if (caseInsensitiveProduct.includes(caseInsensitiveSearch)) {
        return true;
      }

      return false;
    }

    return true;
  });
};
