/* eslint-disable react/jsx-wrap-multilines */
import { makeStyles } from '@material-ui/core/styles';
import numeral from 'numeral';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  loadingWrapper: {
    padding: theme.spacing(8),
    textAlign: 'center',
  },
}));

const ProductDetails = ({
  stockInfo,
  categories,
  pricing,
  isLoading,
  onMissingInfoClick,
}) => {
  const classes = useStyles();
  const { isGiven, length, width, height, weight } = stockInfo;
  const { department, category, subCategory } = categories;
  const { costPrice, wholesalePrice, retailPrice } = pricing;

  const formatNumber = (value, currencySymbol = null, type = 'float') => {
    if (value === null) {
      return '-';
    }
    if (currencySymbol === null) {
      if (type === 'integer') {
        return `${numeral(value).format('0,0')}`;
      }
      return `${numeral(value).format('0,0.00')}`;
    }
    if (type === 'integer') {
      return `${currencySymbol} ${numeral(value).format('0,0')}`;
    }
    return `${currencySymbol} ${numeral(value).format('0,0.00')}`;
  };

  return (
    <>
      {isLoading ? (
        <div className={classes.loadingWrapper}>
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <List
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  Stock Info
                </ListSubheader>
              }
            >
              <ListItem>
                <ListItemText
                  primary={!isGiven ? 'Missing Info' : formatNumber(length)}
                  secondary="Length (cm)"
                />
                {!isGiven && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={onMissingInfoClick}
                    >
                      <HelpOutlineIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={!isGiven ? 'Missing Info' : formatNumber(width)}
                  secondary="Width (cm)"
                />
                {!isGiven && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={onMissingInfoClick}
                    >
                      <HelpOutlineIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={!isGiven ? 'Missing Info' : formatNumber(height)}
                  secondary="Height (cm)"
                />
                {!isGiven && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={onMissingInfoClick}
                    >
                      <HelpOutlineIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={!isGiven ? 'Missing Info' : formatNumber(weight)}
                  secondary="Weight (kg)"
                />
                {!isGiven && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={onMissingInfoClick}
                    >
                      <HelpOutlineIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={4}>
            <List
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  Categories
                </ListSubheader>
              }
            >
              <ListItem>
                <ListItemText primary={department} secondary="Department" />
              </ListItem>
              <ListItem>
                <ListItemText primary={category} secondary="Category" />
              </ListItem>
              <ListItem>
                <ListItemText primary={subCategory} secondary="Sub-Category" />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={4}>
            <List
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  Pricing
                </ListSubheader>
              }
            >
              <ListItem>
                <ListItemText
                  primary={formatNumber(costPrice, 'R')}
                  secondary="Supplier Cost Price"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={formatNumber(wholesalePrice, 'R')}
                  secondary="Wholesale Price"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={formatNumber(retailPrice, 'R', 'integer')}
                  secondary="Retail Price"
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      )}
    </>
  );
};

ProductDetails.defaultProps = {
  stockInfo: {
    isGiven: false,
    length: 1,
    width: 1,
    height: 1,
    weight: 1,
  },
  categories: {
    department: 'Baby',
    category: 'Clothing',
    subCategory: 'Hats',
  },
  pricing: {
    costPrice: 25,
    wholesalePrice: 50,
    retailPrice: 100,
  },
  isLoading: false,
  onMissingInfoClick: () => console.log('Missing info button was clicked.'),
};

ProductDetails.propTypes = {
  stockInfo: PropTypes.shape({
    isGiven: PropTypes.bool,
    length: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    weight: PropTypes.number,
  }),
  categories: {
    department: PropTypes.string,
    category: PropTypes.string,
    subCategory: PropTypes.string,
  },
  pricing: PropTypes.shape({
    costPrice: PropTypes.number,
    wholesalePrice: PropTypes.number,
    retailPrice: PropTypes.number,
  }),
  isLoading: PropTypes.bool,
  onMissingInfoClick: PropTypes.func,
};

export default ProductDetails;
