/* eslint-disable react/jsx-wrap-multilines */
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CreateIcon from '@material-ui/icons/Create';
import InfoIcon from '@material-ui/icons/Info';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import PropTypes from 'prop-types';
import SpeedIcon from '@material-ui/icons/Speed';
import TimelineIcon from '@material-ui/icons/Timeline';

const useStyles = makeStyles((theme) => ({
  activeSection: {
    color: theme.palette.secondary.main,
  },
  inactiveSection: {
    color: grey[700],
  },
  listItem: {
    paddingLeft: theme.spacing(3),
  },
}));

const ListingsNav = ({ isOpen, activeSectionLink, goToLink }) => {
  const classes = useStyles();

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader disableSticky component="div" id="nested-list-subheader">
          {isOpen ? 'Listings' : ''}
        </ListSubheader>
      }
      className={classes.root}
    >
      <ListItem
        className={classes.listItem}
        button
        onClick={() => goToLink('/listings/onboarding/dashboard')}
      >
        <ListItemIcon
          className={
            activeSectionLink.includes('/listings/onboarding')
              ? classes.activeSection
              : classes.inactiveSection
          }
        >
          <AutorenewIcon />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            className: activeSectionLink.includes('/listings/onboarding')
              ? classes.activeSection
              : classes.inactiveSection,
          }}
          primary="Onboarding"
        />
      </ListItem>
      <ListItem
        className={classes.listItem}
        button
        onClick={() => goToLink('/listings/copywriting/dashboard')}
      >
        <ListItemIcon
          className={
            activeSectionLink.includes('/listings/copywriting')
              ? classes.activeSection
              : classes.inactiveSection
          }
        >
          <CreateIcon />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            className: activeSectionLink.includes('/listings/copywriting')
              ? classes.activeSection
              : classes.inactiveSection,
          }}
          primary="Copywriting"
        />
      </ListItem>
      <ListItem
        className={classes.listItem}
        button
        onClick={() => goToLink('/listings/analytics/dashboard')}
      >
        <ListItemIcon
          className={
            activeSectionLink.includes('/listings/analytics')
              ? classes.activeSection
              : classes.inactiveSection
          }
        >
          <TimelineIcon />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            className: activeSectionLink.includes('/listings/analytics')
              ? classes.activeSection
              : classes.inactiveSection,
          }}
          primary="Analytics"
        />
      </ListItem>
      <ListItem
        className={classes.listItem}
        button
        onClick={() => goToLink('/listings/basic-info/browse')}
      >
        <ListItemIcon
          className={
            activeSectionLink.includes('/listings/basic-info')
              ? classes.activeSection
              : classes.inactiveSection
          }
        >
          <InfoIcon />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            className: activeSectionLink.includes('/listings/basic-info')
              ? classes.activeSection
              : classes.inactiveSection,
          }}
          primary="Basic Info"
        />
      </ListItem>
      <ListItem
        className={classes.listItem}
        button
        onClick={() => goToLink('/listings/stock-info/browse')}
      >
        <ListItemIcon
          className={
            activeSectionLink.includes('/listings/stock-info')
              ? classes.activeSection
              : classes.inactiveSection
          }
        >
          <SpeedIcon />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            className: activeSectionLink.includes('/listings/stock-info')
              ? classes.activeSection
              : classes.inactiveSection,
          }}
          primary="Stock Info"
        />
      </ListItem>
      <ListItem
        className={classes.listItem}
        button
        onClick={() => goToLink('/listings/pricing/adjust')}
      >
        <ListItemIcon
          className={
            activeSectionLink.includes('/listings/pricing')
              ? classes.activeSection
              : classes.inactiveSection
          }
        >
          <LocalOfferIcon />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            className: activeSectionLink.includes('/listings/pricing')
              ? classes.activeSection
              : classes.inactiveSection,
          }}
          primary="Pricing"
        />
      </ListItem>
    </List>
  );
};

ListingsNav.defaultProps = {
  activeSectionLink: '/listings/basic-info/browse',
  goToLink: (link) => console.log('User navigated to:', link),
  isOpen: true,
};

ListingsNav.propTypes = {
  activeSectionLink: PropTypes.string,
  goToLink: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default ListingsNav;
