import { useState } from 'react';
import Button from '@material-ui/core/Button';
import WarningIcon from '@material-ui/icons/Warning';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';

const WarningsSelector = ({
  warningsFilter,
  changeWarningsFilter,
  warningOptions,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const getPrettyFilter = (filter) => {
    switch (filter) {
      case 'ALL':
        return 'All Statuses';
      case 'WARNINGS_ONLY':
        return 'Warnings Only';
      case 'NO_WARNINGS':
        return 'No Warnings';
      default:
        return 'Unknown';
    }
  };

  return (
    <>
      <Button
        aria-controls="warnings-menu"
        aria-haspopup="true"
        size="large"
        startIcon={<WarningIcon />}
        onClick={openMenu}
      >
        {getPrettyFilter(warningsFilter)}
      </Button>
      <Menu
        id="from-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem
          onClick={() => {
            changeWarningsFilter('ALL');
            closeMenu();
          }}
        >
          All Statuses
        </MenuItem>
        {warningOptions.map((option) => (
          <MenuItem
            key={`filter-option-${option.id}`}
            onClick={() => {
              changeWarningsFilter(option.id);
              closeMenu();
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

WarningsSelector.defaultProps = {
  warningsFilter: 'ALL',
  changeWarningsFilter: (selection) =>
    console.log(`User selected ${selection} as the warnings filter`),
  warningOptions: [
    {
      id: 'WARNINGS_ONLY',
      label: 'Warnings Only',
    },
    {
      id: 'NO_WARNINGS',
      label: 'No Warnings',
    },
  ],
};

WarningsSelector.propTypes = {
  warningsFilter: PropTypes.string,
  changeWarningsFilter: PropTypes.func,
  warningOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};
export default WarningsSelector;
