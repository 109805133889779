/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { updateClientInfo, getClient } from '../../../api/clientsAPI';

const initialState = {
  isUserRedirectedToBrowse: false,
  clientInfo: {
    id: '',
    name: '',
    status: '',
    billingInfo: {
      legalEntityName: '',
      physicalAddress: '',
      emailAddress: '',
      telephoneNumber: '',
      mainContact: '',
      vatNumber: '',
    },
    leadTime: -1,
  },
  loadingStatus: {
    updating: 'NOT_STARTED',
    clientInfo: 'NOT_STARTED',
  },
  feedback: {
    isOpen: false,
    type: undefined,
    message: '',
  },
};

const clientsEditSlice = createSlice({
  name: 'clientsEdit',
  initialState,
  reducers: {
    updateClientStarted(state) {
      state.loadingStatus.updating = 'PENDING';
    },
    updateClientSuccess(state) {
      state.loadingStatus.updating = 'COMPLETE';
      state.isUserRedirectedToBrowse = true;
    },
    updateClientFailed(state, action) {
      state.loadingStatus.updating = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    fetchClientInfoStarted(state) {
      state.loadingStatus.clientInfo = 'PENDING';
    },
    fetchClientInfoSuccess(state, action) {
      state.clientInfo = action.payload;
      state.loadingStatus.clientInfo = 'COMPLETE';
    },
    fetchClientInfoFailed(state, action) {
      state.loadingStatus.clientInfo = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    resetState() {
      return initialState;
    },
  },
});

export const {
  updateClientStarted,
  updateClientSuccess,
  updateClientFailed,
  fetchClientInfoStarted,
  fetchClientInfoSuccess,
  fetchClientInfoFailed,
  resetState,
} = clientsEditSlice.actions;

export default clientsEditSlice.reducer;

export const editClient = (clientId, newInfo) => async (dispatch) => {
  dispatch(updateClientStarted());
  const result = await updateClientInfo(clientId, newInfo);

  if (result.status === 200) {
    dispatch(updateClientSuccess());
  } else {
    dispatch(updateClientFailed(result.error));
  }
};

export const fetchClientInfo = (clientId) => async (dispatch) => {
  dispatch(fetchClientInfoStarted());
  const result = await getClient(clientId);

  if (result.status === 200) {
    dispatch(fetchClientInfoSuccess(result.clientInfo));
  } else {
    dispatch(fetchClientInfoFailed(result.error));
  }
};

export const initiateStateReset = () => async (dispatch) => {
  dispatch(resetState());
};
