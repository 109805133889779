import { useState } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';

const TagSelector = ({ tagFilter, changeTagFilter }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const getPrettyFilter = () => {
    switch (tagFilter) {
      case 'ALL':
        return 'All Tags';
      case 'STOCK':
        return 'Stock';
      case 'PRODUCT':
        return 'Product';
      case 'INFOGRAPHIC':
        return 'Infographic';
      case 'LIFESTYLE':
        return 'Lifestyle';
      case 'OTHER':
        return 'Other';
      default:
        return 'Unknown';
    }
  };

  return (
    <>
      <Button
        aria-controls="tags-menu"
        aria-haspopup="true"
        size="large"
        startIcon={<LocalOfferIcon />}
        onClick={openMenu}
      >
        {getPrettyFilter(tagFilter)}
      </Button>
      <Menu
        id="tags-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem
          onClick={() => {
            changeTagFilter('ALL');
            closeMenu();
          }}
        >
          All Tags
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeTagFilter('STOCK');
            closeMenu();
          }}
        >
          Stock
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeTagFilter('PRODUCT');
            closeMenu();
          }}
        >
          Product
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeTagFilter('INFOGRAPHIC');
            closeMenu();
          }}
        >
          Infographic
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeTagFilter('LIFESTYLE');
            closeMenu();
          }}
        >
          Lifestyle
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeTagFilter('OTHER');
            closeMenu();
          }}
        >
          Other
        </MenuItem>
      </Menu>
    </>
  );
};

TagSelector.defaultProps = {
  tagFilter: 'ALL',
  changeTagFilter: (newFilter) =>
    console.log('User changes the tag filter to:', newFilter),
};

TagSelector.propTypes = {
  tagFilter: PropTypes.string,
  changeTagFilter: PropTypes.func,
};

export default TagSelector;
