/* eslint-disable react/jsx-wrap-multilines */
import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    maxWidth: 360,
  },
}));

const CreateShipmentsDialog = ({
  isOpen,
  createShipments,
  closeDialog,
  shipments,
}) => {
  const classes = useStyles();

  const [checkedTakealot, setCheckedTakealot] = useState([]);
  const [checkedAmazon, setCheckedAmazon] = useState([]);
  const [checkedClient, setCheckedClient] = useState([]);
  const [takealotShipments, setTakealotShipments] = useState([]);
  const [amazonShipments, setAmazonShipments] = useState([]);
  const [clientShipments, setClientShipments] = useState([]);

  useEffect(() => {
    setTakealotShipments(
      shipments.filter(
        (info) =>
          info.toName === 'Takealot JHB' || info.toName === 'Takealot CPT'
      )
    );
    setAmazonShipments(shipments.filter((info) => info.toName === 'Amazon US'));
    setClientShipments(shipments.filter((info) => info.fromId === 'CLIENT'));
  }, [shipments]);

  const handleTakealotToggle = (value) => () => {
    const currentIndex = checkedTakealot.indexOf(value);
    const newChecked = [...checkedTakealot];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedTakealot(newChecked);
  };

  const handleAmazonToggle = (value) => () => {
    const currentIndex = checkedAmazon.indexOf(value);
    const newChecked = [...checkedAmazon];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedAmazon(newChecked);
  };

  const handleClientToggle = (value) => () => {
    const currentIndex = checkedClient.indexOf(value);
    const newChecked = [...checkedClient];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedClient(newChecked);
  };

  const getSelectedShipments = () => {
    let selectedShipments = takealotShipments.filter((info, index) => {
      const foundIndex = checkedTakealot.findIndex((value) => value === index);
      return foundIndex !== -1;
    });
    selectedShipments = [
      ...selectedShipments,
      ...amazonShipments.filter((info, index) => {
        const foundIndex = checkedAmazon.findIndex((value) => value === index);
        return foundIndex !== -1;
      }),
    ];
    selectedShipments = [
      ...selectedShipments,
      ...clientShipments.filter((info, index) => {
        const foundIndex = checkedClient.findIndex((value) => value === index);
        return foundIndex !== -1;
      }),
    ];

    return selectedShipments;
  };

  return (
    <Dialog
      onClose={closeDialog}
      aria-labelledby="create-shipments-dialog-title"
      open={isOpen}
    >
      <DialogTitle id="create-shipments-title">Create Shipments</DialogTitle>
      <DialogContent>
        <DialogContentText id="create-shipments-dialog-description">
          Please select the shipments you would like to create:
        </DialogContentText>
        {takealotShipments.length > 0 && (
          <List
            dense
            className={classes.root}
            subheader={<ListSubheader>Takealot Replenishments</ListSubheader>}
          >
            {takealotShipments.map((shipmentInfo, index) => {
              const labelId = `checkbox-list-secondary-label-${index}`;
              const { fromId, toId, toName, totalSkus, totalUnits } =
                shipmentInfo;

              return (
                <ListItem
                  key={`${fromId}-to-${toId}`}
                  button
                  onClick={handleTakealotToggle(index)}
                >
                  <ListItemText
                    id={labelId}
                    primary={`EasyOnline to ${toName}`}
                    secondary={`${totalSkus} SKUs, ${totalUnits} units`}
                  />
                  <ListItemSecondaryAction>
                    <Checkbox
                      edge="end"
                      onChange={handleTakealotToggle(index)}
                      checked={checkedTakealot.indexOf(index) !== -1}
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        )}
        {amazonShipments.length > 0 && (
          <>
            <Divider />
            <List
              dense
              className={classes.root}
              subheader={<ListSubheader>Amazon Replenishments</ListSubheader>}
            >
              {amazonShipments.map((shipmentInfo, index) => {
                const labelId = `checkbox-list-secondary-label-${index}`;
                const { fromId, toId, totalSkus, totalUnits } = shipmentInfo;

                return (
                  <ListItem
                    key={`${fromId}-to-${toId}`}
                    button
                    onClick={handleAmazonToggle(index)}
                  >
                    <ListItemText
                      id={labelId}
                      primary="EasyOnline to Amazon US"
                      secondary={`${totalSkus} SKUs, ${totalUnits} units`}
                    />
                    <ListItemSecondaryAction>
                      <Checkbox
                        edge="end"
                        onChange={handleAmazonToggle(index)}
                        checked={checkedAmazon.indexOf(index) !== -1}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          </>
        )}
        {clientShipments.length > 0 && (
          <>
            <Divider />
            <List
              dense
              className={classes.root}
              subheader={<ListSubheader>Client Replenishments</ListSubheader>}
            >
              {clientShipments.map((shipmentInfo, index) => {
                const labelId = `checkbox-list-secondary-label-${index}`;
                const { clientName, clientId, toId, totalSkus, totalUnits } =
                  shipmentInfo;

                return (
                  <ListItem
                    key={`${clientId}-to-${toId}`}
                    button
                    onClick={handleClientToggle(index)}
                  >
                    <ListItemText
                      id={labelId}
                      primary={`${clientName} to EasyOnline`}
                      secondary={`${totalSkus} SKUs, ${totalUnits} units`}
                    />
                    <ListItemSecondaryAction>
                      <Checkbox
                        edge="end"
                        onChange={handleClientToggle(index)}
                        checked={checkedClient.indexOf(index) !== -1}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => createShipments(getSelectedShipments())}
          color="secondary"
          variant="contained"
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CreateShipmentsDialog.defaultProps = {
  isOpen: false,
  createShipments: (selectedShipments) =>
    console.log('The following shipments were created:', selectedShipments),
  closeDialog: () => console.log('The dialog was closed.'),
  shipments: [],
};

CreateShipmentsDialog.propTypes = {
  isOpen: PropTypes.bool,
  createShipments: PropTypes.func,
  closeDialog: PropTypes.func,
  shipments: PropTypes.arrayOf(
    PropTypes.shape({
      clientId: PropTypes.string,
      clientName: PropTypes.string,
      cost: PropTypes.number,
      creationDate: PropTypes.instanceOf(Date),
      creatorId: PropTypes.string,
      creatorName: PropTypes.string,
      fromId: PropTypes.string,
      fromName: PropTypes.string,
      lastUpdated: PropTypes.instanceOf(Date),
      skus: PropTypes.arrayOf(
        PropTypes.shape({
          childAsin: PropTypes.string,
          product: PropTypes.string,
          sku: PropTypes.string,
          tsin: PropTypes.string,
          units: PropTypes.number,
          costPrice: PropTypes.number,
        })
      ),
      status: PropTypes.oneOf([
        'PREPARING',
        'REQUESTED',
        'INBOUND',
        'DELAYED',
        'CANCELLED',
        'COMPLETE',
      ]),
      toId: PropTypes.string,
      toName: PropTypes.string,
      totalSkus: PropTypes.number,
      totalUnits: PropTypes.number,
    })
  ),
};

export default CreateShipmentsDialog;
