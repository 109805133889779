import { makeStyles } from '@material-ui/core/styles';
import numeral from 'numeral';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  statusAvatar: {
    backgroundColor: theme.palette.secondary.light,
  },
  summaryTitle: {
    color: theme.palette.secondary.light,
    fontSize: '0.8rem',
  },
  summaryValue: {
    color: theme.palette.primary.light,
    fontSize: '1rem',
  },
  wrapperSummaries: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
  wrapperSummary: {
    width: '25%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const ProductCard = ({
  productName,
  costPrice,
  wholesalePrice,
  retailPrice,
  markup,
  status,
  goToProduct,
}) => {
  const classes = useStyles();

  const getStatusText = () => {
    switch (status) {
      case 'AVAILABLE':
        return 'Available';
      case 'RESERVED':
        return 'Reserved';
      case 'ASSIGNED':
        return 'Assigned';
      default:
        return 'Unknown';
    }
  };

  const getStatusAvatar = () => {
    switch (status) {
      case 'RESERVED':
        return (
          <Avatar className={classes.statusAvatar}>
            <AutorenewIcon />
          </Avatar>
        );
      case 'AVAILABLE':
        return (
          <Avatar className={classes.statusAvatar}>
            <DoneIcon />
          </Avatar>
        );
      case 'ASSIGNED':
        return (
          <Avatar className={classes.statusAvatar}>
            <ClearIcon />
          </Avatar>
        );
      default:
        return <Avatar />;
    }
  };

  return (
    <Card>
      <CardActionArea onClick={goToProduct}>
        <CardHeader
          avatar={getStatusAvatar(status)}
          title={productName}
          subheader={getStatusText()}
        />
        <CardContent>
          <div className={classes.wrapperSummaries}>
            <div className={classes.wrapperSummary}>
              <Typography variant="h6" className={classes.summaryValue}>
                {`R ${numeral(costPrice).format('0,0.00')}`}
              </Typography>
              <Typography variant="subtitle1" className={classes.summaryTitle}>
                Cost Price
              </Typography>
            </div>
            <div className={classes.wrapperSummary}>
              <Typography variant="h6" className={classes.summaryValue}>
                {`R ${numeral(wholesalePrice).format('0,0.00')}`}
              </Typography>
              <Typography variant="subtitle1" className={classes.summaryTitle}>
                Wholesale Price
              </Typography>
            </div>
            <div className={classes.wrapperSummary}>
              <Typography variant="h6" className={classes.summaryValue}>
                {`R ${numeral(retailPrice).format('0,0')}`}
              </Typography>
              <Typography variant="subtitle1" className={classes.summaryTitle}>
                Retail Price
              </Typography>
            </div>
            <div className={classes.wrapperSummary}>
              <Typography variant="h6" className={classes.summaryValue}>
                {`${numeral(markup).format('0,0')} %`}
              </Typography>
              <Typography variant="subtitle1" className={classes.summaryTitle}>
                Markup
              </Typography>
            </div>
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

ProductCard.defaultProps = {
  productName: 'Yeezy Boost',
  costPrice: 1000,
  wholesalePrice: 2000,
  retailPrice: 5000,
  markup: 100,
  status: 'AVAILABLE',
  goToProduct: () => console.log('User navigated to the product'),
};

ProductCard.propTypes = {
  productName: PropTypes.string,
  costPrice: PropTypes.number,
  wholesalePrice: PropTypes.number,
  retailPrice: PropTypes.number,
  markup: PropTypes.number,
  status: PropTypes.oneOf(['AVAILABLE', 'RESERVED', 'ASSIGNED']),
  goToProduct: PropTypes.func,
};

export default ProductCard;
