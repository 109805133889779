/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getSkus, getStockChanges } from '../../../api/listingsAPI';
import {
  bulkUpdate,
  getWarehouses,
  updateStockInfo,
} from '../../../api/logisticsAPI';

const initialState = {
  skusList: [],
  stockChanges: [],
  warehousesList: [],
  loadingStatus: {
    skus: 'NOT_STARTED',
    stockChanges: 'NOT_STARTED',
    warehouses: 'NOT_STARTED',
    updateSku: 'NOT_STARTED',
  },
  failedBulkSkus: [],
  bulkStockUploadLoadingCount: {
    total: 0,
    success: 0,
    failed: 0,
  },
  pageSize: 5,
  initialPage: 0,
};

const logisticsStorageSlice = createSlice({
  name: 'logisticsStorage',
  initialState,
  reducers: {
    getListingsStarted(state) {
      state.loadingStatus.skus = 'PENDING';
    },
    getListingsSuccess(state, action) {
      state.skusList = action.payload;
      state.loadingStatus.skus = 'COMPLETE';
    },
    getListingsFailed(state, action) {
      state.loadingStatus.skus = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getStockChangesStarted(state) {
      state.loadingStatus.stockChanges = 'PENDING';
    },
    getStockChangesSuccess(state, action) {
      state.stockChanges = action.payload;
      state.loadingStatus.stockChanges = 'COMPLETE';
    },
    getStockChangesFailed(state, action) {
      state.loadingStatus.stockChanges = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getWarehousesStarted(state) {
      state.loadingStatus.warehouses = 'PENDING';
    },
    getWarehousesSuccess(state, action) {
      state.warehousesList = action.payload;
      state.loadingStatus.warehouses = 'COMPLETE';
    },
    getWarehousesFailed(state, action) {
      state.loadingStatus.warehouses = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    updateSkuStarted(state) {
      state.loadingStatus.updateSku = 'PENDING';
    },
    updateSkuSuccess(state, action) {
      state.skusList = state.skusList.map((info) => {
        if (info.sku === action.payload.sku) {
          return {
            ...info,
            stockQuantities: action.payload.newStockQuantities,
          };
        }
        return info;
      });
      state.loadingStatus.updateSku = 'COMPLETE';
    },
    updateSkuFailed(state, action) {
      state.loadingStatus.updateSku = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    bulkStockUpdateSuccess(state) {
      state.bulkStockUploadLoadingCount.success += 1;
    },
    bulkStockUpdateFailed(state, action) {
      state.bulkStockUploadLoadingCount.failed += 1;
      state.failedBulkSkus.push(action.payload);
    },
    updateTotalSkusToUpdate(state, action) {
      state.failedBulkSkus = [];
      state.bulkStockUploadLoadingCount.total = action.payload;
      state.bulkStockUploadLoadingCount.success = 0;
      state.bulkStockUploadLoadingCount.failed = 0;
    },
    changePageSize(state, action) {
      state.pageSize = action.payload;
    },
    changeInitialPage(state, action) {
      state.initialPage = action.payload;
    },
  },
});

export const {
  getListingsStarted,
  getListingsSuccess,
  getListingsFailed,
  getStockChangesStarted,
  getStockChangesSuccess,
  getStockChangesFailed,
  getWarehousesStarted,
  getWarehousesSuccess,
  getWarehousesFailed,
  updateSkuStarted,
  updateSkuSuccess,
  updateSkuFailed,
  changePageSize,
  changeInitialPage,
  bulkStockUpdateSuccess,
  bulkStockUpdateFailed,
  updateTotalSkusToUpdate,
} = logisticsStorageSlice.actions;

export const fetchSkus = (platform) => async (dispatch) => {
  dispatch(getListingsStarted());
  const result = await getSkus(platform);

  if (result.status === 200) {
    dispatch(getListingsSuccess(result.skus));
  } else {
    dispatch(getListingsFailed(result.error));
  }
};

export const fetchStockChanges = (sku) => async (dispatch) => {
  dispatch(getStockChangesStarted());
  const result = await getStockChanges(sku);

  if (result.status === 200) {
    dispatch(getStockChangesSuccess(result.changes));
  } else {
    dispatch(getStockChangesFailed(result.error));
  }
};

export const fetchWarehouses = () => async (dispatch) => {
  dispatch(getWarehousesStarted());
  const result = await getWarehouses();

  if (result.status === 200) {
    dispatch(getWarehousesSuccess(result.warehouses));
  } else {
    dispatch(getWarehousesFailed(result.error));
  }
};

export const updateStockQuantities =
  (sku, oldStockQuantities, newStockQuantities, userInfo) =>
  async (dispatch) => {
    dispatch(updateSkuStarted());
    const result = await updateStockInfo(
      sku,
      oldStockQuantities,
      newStockQuantities,
      userInfo
    );

    if (result.status === 200) {
      dispatch(updateSkuSuccess({ sku, newStockQuantities }));
    } else {
      dispatch(updateSkuFailed(result.error));
    }
  };

export const bulkStockUpdate =
  (code, easyOnlineQty, amazonUsQty, type, userInfo) => async (dispatch) => {
    const result = await bulkUpdate(
      code,
      easyOnlineQty,
      amazonUsQty,
      type,
      userInfo
    );

    if (result.status === 200) {
      dispatch(bulkStockUpdateSuccess());
    } else {
      dispatch(
        bulkStockUpdateFailed({
          code: result.code,
          type: result.type,
        })
      );
    }
  };

export const setTotalSkusToUpdate = (total) => (dispatch) => {
  dispatch(updateTotalSkusToUpdate(total));
};

export const updatePageSize = (newPageSize) => (dispatch) => {
  dispatch(changePageSize(newPageSize));
};

export const updateInitialPage = (newInitialPage) => (dispatch) => {
  dispatch(changeInitialPage(newInitialPage));
};

export default logisticsStorageSlice.reducer;
