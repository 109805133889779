import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import moment from 'moment';
import {
  fetchReportSummary,
  fetchSku,
  setTotalSkusToLoad,
  updateTakealotOrders,
  updateTakealotSkuInfo,
  uploadReportData,
} from './slice';
import AmazonReportsTable from './components/AmazonReportsTable';
import ContentWrapper from '../../components/ContentWrapper';
import DatePicker from './components/DatePicker';
import PageHeader from '../../components/PageHeader';
import TakealotReportsTable from './components/TakealotReportsTable';
import UploadReportDialog from './components/UploadReportDialog';

const breadcrumbs = [
  {
    label: 'System Maintenance',
    link: '/system-maintenance',
  },
  {
    label: 'Reports',
    link: '/system-maintenance/reports',
  },
];

const useStyles = makeStyles((theme) => ({
  buttonSeparator: {
    width: theme.spacing(3),
  },
  seperator: {
    height: theme.spacing(3),
  },
}));

const MaintenanceReports = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    moment().subtract(1, 'day').toDate()
  );
  const [isUploadReportDialogOpen, setIsUploadReportDialogOpen] =
    useState(false);
  const [selectedReportId, setSelectedReportId] = useState('');

  const reportSummary = useSelector(
    (state) => state.maintenanceReports.reportSummary
  );
  const reportSummaryLoadingStatus = useSelector(
    (state) => state.maintenanceReports.loadingStatus.reportSummary
  );
  const uploadReportLoadingStatus = useSelector(
    (state) => state.maintenanceReports.loadingStatus.reportUpload
  );
  const updateRecentOrdersLoadingStatus = useSelector(
    (state) => state.maintenanceReports.loadingStatus.updateRecentOrders
  );
  const updateTakealotSkusLoadingStatus = useSelector(
    (state) => state.maintenanceReports.loadingStatus.updateTakealotSkus
  );
  const skuLoadingCount = useSelector(
    (state) => state.maintenanceReports.skuLoadingCount
  );
  const failedSkus = useSelector(
    (state) => state.maintenanceReports.failedSkus
  );
  const userInfo = useSelector((state) => state.account.userInfo);

  useEffect(() => {
    dispatch(fetchReportSummary(moment(selectedDate).format('MMYYYY')));
  }, [selectedDate]);

  useEffect(() => {
    if (
      reportSummaryLoadingStatus === 'PENDING' ||
      updateRecentOrdersLoadingStatus === 'PENDING' ||
      updateTakealotSkusLoadingStatus === 'PENDING'
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [
    reportSummaryLoadingStatus,
    updateRecentOrdersLoadingStatus,
    updateTakealotSkusLoadingStatus,
  ]);

  const loadSkus = (codesList, codeType) => {
    dispatch(setTotalSkusToLoad(codesList.length));
    codesList.map((info) =>
      dispatch(fetchSku(info.code, info.title, codeType))
    );
  };

  return (
    <div>
      <PageHeader
        title="Report Updates"
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Reports"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
      />
      {isLoading && <LinearProgress color="secondary" />}
      <ContentWrapper>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ButtonGroup>
              <Button
                disabled={updateRecentOrdersLoadingStatus === 'PENDING'}
                color="secondary"
                variant="contained"
                onClick={() => dispatch(updateTakealotOrders())}
              >
                Update Takealot orders
              </Button>
              <Button
                disabled={updateTakealotSkusLoadingStatus === 'PENDING'}
                color="secondary"
                variant="contained"
                onClick={() => dispatch(updateTakealotSkuInfo())}
              >
                Update Takealot SKUs
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DatePicker
              date={selectedDate}
              changeDate={setSelectedDate}
              reloadReportSummaries={(newDate) =>
                dispatch(fetchReportSummary(newDate))
              }
              reportSummary={reportSummary}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <AmazonReportsTable
              isLoading={isLoading}
              reportSummary={reportSummary}
              selectedDate={selectedDate}
              uploadReport={(reportId) => {
                setIsUploadReportDialogOpen(true);
                setSelectedReportId(reportId);
              }}
            />
            <div className={classes.seperator} />
            <TakealotReportsTable
              isLoading={isLoading}
              reportSummary={reportSummary}
              selectedDate={selectedDate}
              uploadReport={(reportId) => {
                setIsUploadReportDialogOpen(true);
                setSelectedReportId(reportId);
              }}
            />
          </Grid>
        </Grid>
      </ContentWrapper>
      <UploadReportDialog
        isOpen={isUploadReportDialogOpen}
        selectedReportId={selectedReportId}
        date={selectedDate}
        closeDialog={() => {
          setIsUploadReportDialogOpen(false);
          setSelectedReportId('');
        }}
        loadSkus={loadSkus}
        skuLoadingCount={skuLoadingCount}
        failedSkus={failedSkus}
        uploadReportLoadingStatus={uploadReportLoadingStatus}
        uploadReportData={(reportData) =>
          dispatch(
            uploadReportData(
              selectedReportId,
              moment(selectedDate).format('DDMMYYYY'),
              userInfo.uid,
              `${userInfo.name} ${userInfo.surname}`,
              reportData
            )
          )
        }
      />
    </div>
  );
};

export default MaintenanceReports;
