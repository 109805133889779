/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as XLSX from 'xlsx';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { initiateStateReset, setTotalSkusToUpdate, updateInfo } from './slice';
import { getLoadingValue } from './logic';
import ContentWrapper from '../../../components/ContentWrapper';
import PageHeader from '../../../components/PageHeader';

const useStyles = makeStyles((theme) => ({
  actionsBar: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  alert: {
    marginBottom: theme.spacing(3),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  loadingWrapper: {
    padding: theme.spacing(4),
    width: '100%',
    textAlign: 'center',
  },
  wrapperWizard: {
    width: '100%np',
    padding: theme.spacing(4),
  },
}));

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#171415',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  marginTop: 24,
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const breadcrumbs = [
  {
    label: 'Presales',
    link: '/presales',
  },
  {
    label: 'Products',
    link: '/presales/products',
  },
];

const ProductsTakealotUpdate = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [reportData, setReportData] = useState(null);
  const [validation, setValidation] = useState('');
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    maxFiles: 1,
    accept: '.xlsx',
    onDrop: (acceptedDocuments) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        try {
          setValidation('');
          const bstr = e.target.result;
          const workbook = XLSX.read(bstr, { type: 'binary' });
          const offersRaw = workbook.Sheets['Offer Export'];
          if (!offersRaw) throw new Error();
          const offers = XLSX.utils.sheet_to_json(offersRaw, { header: 1 });
          setReportData(
            offers.slice(2).filter((offerInfo) => offerInfo.length > 0)
          );
        } catch (error) {
          setValidation('Invalid report file provided.');
          setReportData(null);
        }
      };

      reader.readAsArrayBuffer(acceptedDocuments[0]);
    },
  });

  const updateLoadingCountTotal = useSelector(
    (state) => state.presalesProductsTakealotUpdate.updateLoadingCount.total
  );
  const updateLoadingCountFailed = useSelector(
    (state) => state.presalesProductsTakealotUpdate.updateLoadingCount.failed
  );
  const updateLoadingCountSuccess = useSelector(
    (state) => state.presalesProductsTakealotUpdate.updateLoadingCount.success
  );

  useEffect(() => {
    if (
      updateLoadingCountTotal >
      updateLoadingCountSuccess + updateLoadingCountFailed
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [
    updateLoadingCountTotal,
    updateLoadingCountSuccess,
    updateLoadingCountFailed,
  ]);

  useEffect(() => {
    if (
      updateLoadingCountTotal > 0 &&
      updateLoadingCountTotal ===
        updateLoadingCountSuccess + updateLoadingCountFailed
    ) {
      dispatch(initiateStateReset());
      setIsLoading(true);
      history.goBack();
    }
  }, [
    updateLoadingCountTotal,
    updateLoadingCountSuccess,
    updateLoadingCountFailed,
  ]);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const skuLoadingValue = getLoadingValue(
    updateLoadingCountTotal,
    updateLoadingCountSuccess,
    updateLoadingCountFailed
  );

  return (
    <div>
      <PageHeader
        title="Update Takealot info"
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Update"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
      />
      <ContentWrapper>
        <Paper className={classes.wrapperWizard}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom>
                Update Takealot Products
              </Typography>
              <Typography variant="body1" gutterBottom>
                Downlod the &ldquo;Export My Offers&rdquo; report from
                Takealot&apos;s Seller Portal and import the file below.
              </Typography>
              <div className="container">
                {reportData === null && (
                  <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    <p>
                      Drag &lsquo;n&rsquo; drop report here, or click to select
                      file
                    </p>
                  </div>
                )}
                {isLoading && (
                  <div className={classes.loadingWrapper}>
                    <Box position="relative" display="inline-flex">
                      <CircularProgress
                        value={skuLoadingValue}
                        size={64}
                        variant="determinate"
                        color="secondary"
                      />
                      <Box
                        top={0}
                        left={0}
                        bottom={0}
                        right={0}
                        position="absolute"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography variant="body1" component="div">
                          {`${skuLoadingValue}%`}
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                )}
              </div>
            </Grid>
            {validation.length === 0 &&
              reportData !== null &&
              skuLoadingValue === -1 && (
                <Alert className={classes.alert} severity="success">
                  File is ready for upload.
                </Alert>
              )}
            {validation.length > 0 && (
              <Alert className={classes.alert} severity="error">
                {validation}
              </Alert>
            )}
            <Grid item xs={12}>
              <div className={classes.actionsBar}>
                <Button
                  disabled={isLoading || reportData === null}
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    const batches = _.chunk(reportData, 100);
                    dispatch(setTotalSkusToUpdate(batches.length));
                    batches.forEach((batch) => dispatch(updateInfo(batch)));
                  }}
                >
                  Upload
                </Button>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </ContentWrapper>
    </div>
  );
};

export default ProductsTakealotUpdate;
