import { connectRefinementList } from 'react-instantsearch-dom';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import numeral from 'numeral';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
  },
  listItemCount: {
    color: theme.palette.secondary.light,
    borderColor: theme.palette.secondary.light,
  },
  listItemText: {
    color: theme.palette.primary.light,
  },
}));

const BrandRefinementList = ({ items, refine }) => {
  const classes = useStyles();

  return (
    <List
      className={classes.root}
      subheader={<ListSubheader>Brand</ListSubheader>}
    >
      {items.map((item) => {
        return (
          <ListItem
            key={item.label}
            dense
            button
            onClick={() => {
              refine(item.value);
            }}
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={item.isRefined}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': item.label }}
              />
            </ListItemIcon>
            <ListItemText
              id={item.label === '' ? 'Unknown' : item.label}
              primary={item.label === '' ? 'Unknown' : item.label}
              className={classes.listItemText}
            />
            <ListItemSecondaryAction>
              <Chip
                variant="outlined"
                size="small"
                label={numeral(item.count).format('0,0')}
                className={classes.listItemCount}
              />
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
};

BrandRefinementList.defaultProps = {
  items: [],
  refine: (itemValue) => console.log(`User refined the filter by ${itemValue}`),
};

BrandRefinementList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.arrayOf(PropTypes.string),
      isRefined: PropTypes.bool,
      count: PropTypes.number,
    })
  ),
  refine: PropTypes.func,
};

export default connectRefinementList(BrandRefinementList);
