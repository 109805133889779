import React from 'react';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  textLabel: {
    color: grey[500],
  },
  textValue: {
    color: theme.palette.common.white,
  },
  wrapperTotal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 300,
  },
  wrapperTotalSpacing: {
    marginRight: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 300,
  },
  wrapperRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const TotalsBar = ({ totals }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapperRoot}>
      {totals.map((total, index) => (
        <div
          key={`totals-bar-value-${total.label}`}
          className={
            index === totals.length - 1
              ? classes.wrapperTotal
              : classes.wrapperTotalSpacing
          }
        >
          <Typography component="p" variant="h5" className={classes.textValue}>
            {total.value}
          </Typography>
          <Typography
            component="p"
            variant="subtitle2"
            className={classes.textLabel}
          >
            {total.label}
          </Typography>
        </div>
      ))}
    </div>
  );
};

TotalsBar.defaultProps = {
  totals: [],
};

TotalsBar.propTypes = {
  totals: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

export default TotalsBar;
