export const getSkuName = (brand, productName, variation) => {
  if (variation === 'N/A') {
    return `${brand} - ${productName}`;
  }
  return `${brand} - ${productName} (${variation})`;
};

export const getFilteredImages = (images, tagFilter, isEditingListingOrder) => {
  return images
    .filter((imageInfo) => {
      if (tagFilter === 'ALL') {
        return true;
      }
      return tagFilter === imageInfo.tag;
    })
    .filter((imageInfo) => {
      if (isEditingListingOrder) {
        return (
          imageInfo.tag === 'PRODUCT' ||
          imageInfo.tag === 'LIFESTYLE' ||
          imageInfo.tag === 'INFOGRAPHIC' ||
          imageInfo.tag === 'OTHER'
        );
      }
      return true;
    })
    .map((imageInfo) => {
      if (!imageInfo.listingOrder) {
        return {
          ...imageInfo,
          listingOrder: 10000,
        };
      }
      return imageInfo;
    })
    .sort((imageA, imageB) => {
      if (imageA.listingOrder > imageB.listingOrder) return +1;
      if (imageA.listingOrder < imageB.listingOrder) return -1;
      return 0;
    });
};

export const sortCopies = (copies) => {
  return [...copies].sort((copyA, copyB) => {
    if (copyA.version > copyB.version) return +1;
    if (copyA.version < copyB.version) return -1;
    return 0;
  });
};
