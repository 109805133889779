import { fade } from '@material-ui/core/styles/colorManipulator';
import { makeStyles } from '@material-ui/core/styles';
import { green, yellow } from '@material-ui/core/colors';
import moment from 'moment';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';

const headerStyle = {
  backgroundColor: '#171415',
};

const useStyles = makeStyles((theme) => ({
  loadingOverlay: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: fade(theme.palette.common.black, 0.8),
  },
  icon: {
    color: theme.palette.common.black,
  },
  statusFinalized: {
    color: theme.palette.common.black,
    backgroundColor: green[500],
  },
  statusInProgress: {
    color: theme.palette.common.black,
    backgroundColor: yellow[700],
  },
  wrapperContent: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

const StatementReportsTable = ({ isLoading, statementReports, viewReport }) => {
  const classes = useStyles();

  const columns = [
    {
      title: 'Start Date',
      field: 'startDate',
      type: 'date',
      defaultSort: 'desc',
      render: (rowData) => moment(rowData.startDate).format('D MMMM YYYY'),
    },
    {
      title: 'End Date',
      field: 'endDate',
      type: 'date',
      render: (rowData) => moment(rowData.endDate).format('D MMMM YYYY'),
    },
    {
      title: 'Status',
      field: 'status',
      render: (rowData) => {
        if (rowData.status === 'IN_PROGRESS') {
          return (
            <Chip
              icon={<AutorenewIcon className={classes.icon} />}
              label="Work in Progress"
              className={classes.statusInProgress}
            />
          );
        }
        return (
          <Chip
            icon={<DoneIcon className={classes.icon} />}
            label="Finalized"
            className={classes.statusFinalized}
          />
        );
      },
    },
  ];

  return (
    <div className={classes.wrapperContent}>
      <MaterialTable
        columns={columns}
        data={statementReports}
        isLoading={isLoading}
        options={{
          headerStyle,
          grouping: false,
          draggable: false,
          search: false,
          showTitle: false,
          toolbar: false,
          actionsColumnIndex: -1,
        }}
        actions={[
          {
            icon: 'keyboard_arrow_right',
            tooltip: 'View SKU info',
            onClick: (event, rowData) => viewReport(rowData.id),
          },
        ]}
        components={{
          OverlayLoading: () => (
            <div className={classes.loadingOverlay}>
              <CircularProgress color="primary" size={32} />
            </div>
          ),
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'No statement reports to display',
          },
        }}
      />
    </div>
  );
};

StatementReportsTable.defaultProps = {
  isLoading: false,
  statementReports: [],
  viewReport: (id) =>
    console.log(`Display info for statement report with ID of ${id}`),
};

StatementReportsTable.propTypes = {
  isLoading: PropTypes.bool,
  viewReport: PropTypes.func,
  statementReports: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      status: PropTypes.oneOf(['IN_PROGRESS', 'FINALIZED']),
    })
  ),
};

export default StatementReportsTable;
