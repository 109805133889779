/* eslint-disable react/jsx-props-no-spreading */
import { fade } from '@material-ui/core/styles/colorManipulator';
import { green, red, yellow } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import MaterialTable from 'material-table';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import PropTypes from 'prop-types';

const headerStyle = {
  backgroundColor: '#171415',
};

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.common.black,
  },
  loadingOverlay: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: fade(theme.palette.common.black, 0.8),
  },
  statusActive: {
    color: theme.palette.common.black,
    backgroundColor: green[500],
  },
  statusTradeOut: {
    color: theme.palette.common.black,
    backgroundColor: yellow[500],
  },
  statusTerminated: {
    color: theme.palette.common.black,
    backgroundColor: red[500],
  },
  statusMissingInfo: {
    color: theme.palette.common.black,
    backgroundColor: red[500],
  },
  wrapperContent: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

const ClientsTable = ({
  isLoading,
  clients,
  viewClient,
  editClient,
  pageSize,
  initialPage,
  updatePageSize,
  updateInitialPage,
}) => {
  const classes = useStyles();

  const getStatusChip = (status) => {
    switch (status) {
      case 'TRADE_OUT':
        return (
          <Chip
            icon={<MeetingRoomIcon className={classes.icon} />}
            label="Trade Out"
            className={classes.statusTradeOut}
          />
        );
      case 'MISSING_INFO':
        return (
          <Chip
            icon={<HelpOutlineIcon className={classes.icon} />}
            label="Missing Info"
            className={classes.statusMissingInfo}
          />
        );
      case 'TERMINATED':
        return (
          <Chip
            icon={<ClearIcon className={classes.icon} />}
            label="Terminated"
            className={classes.statusTerminated}
          />
        );
      default:
        return (
          <Chip
            icon={<DoneIcon className={classes.icon} />}
            label="Active"
            className={classes.statusActive}
          />
        );
    }
  };

  const getColumns = () => {
    const columns = [
      { title: 'Name', field: 'name' },
      { title: 'Main Contact', field: 'mainContact' },
      { title: 'Type', field: 'formattedType' },
      { title: 'Email Address', field: 'emailAddress' },
      { title: 'Telephone Number', field: 'telephoneNumber' },
      { title: 'VAT Number', field: 'vatNumber' },
      {
        title: 'Status',
        field: 'status',
        render: (rowData) => getStatusChip(rowData.status),
      },
    ];

    return columns;
  };

  return (
    <div className={classes.wrapperContent}>
      <MaterialTable
        title=""
        columns={getColumns()}
        data={clients}
        isLoading={isLoading}
        onChangeRowsPerPage={updatePageSize}
        onChangePage={updateInitialPage}
        options={{
          pageSize,
          initialPage,
          headerStyle,
          actionsColumnIndex: -1,
          search: false,
          grouping: false,
          draggable: false,
          exportButton: true,
          exportAllData: true,
          exportFileName: 'Clients',
        }}
        actions={[
          {
            icon: 'edit',
            tooltip: 'Edit client info',
            onClick: (event, rowData) => editClient(rowData.id),
          },
          {
            icon: 'keyboard_arrow_right',
            tooltip: 'View client',
            onClick: (event, rowData) => viewClient(rowData.id),
          },
        ]}
        components={{
          OverlayLoading: () => (
            <div className={classes.loadingOverlay}>
              <CircularProgress color="primary" size={32} />
            </div>
          ),
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'No clients to display',
          },
        }}
      />
    </div>
  );
};

ClientsTable.defaultProps = {
  isLoading: false,
  clients: [],
  viewClient: (clientId) => console.log(`Client viewed: ${clientId}`),
  editClient: (clientId) => console.log(`Client edited: ${clientId}`),
  pageSize: 5,
  initialPage: 0,
  updatePageSize: (newPageSize) =>
    console.log(`Page size updated to: ${newPageSize}`),
  updateInitialPage: (newInitialPage) =>
    console.log(`Initial page updated to: ${newInitialPage}`),
};

ClientsTable.propTypes = {
  isLoading: PropTypes.bool,
  clients: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      status: PropTypes.oneOf([
        'TERMINATED',
        'ACTIVE',
        'TRADE_OUT',
        'MISSING_INFO',
      ]),
      emailAddress: PropTypes.string,
      legalEntityName: PropTypes.string,
      mainContact: PropTypes.string,
      physicalAddress: PropTypes.string,
      telephoneNumber: PropTypes.string,
      vatNumber: PropTypes.string,
    })
  ),
  viewClient: PropTypes.func,
  editClient: PropTypes.func,
  pageSize: PropTypes.number,
  initialPage: PropTypes.number,
  updatePageSize: PropTypes.func,
  updateInitialPage: PropTypes.func,
};

export default ClientsTable;
