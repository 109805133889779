import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { editSupplier, createSupplier, initiateStateReset } from './slice';
import ContentWrapper from '../../../components/ContentWrapper';
import PageHeader from '../../../components/PageHeader';

const useStyles = makeStyles((theme) => ({
  actionsBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  alert: {
    marginBottom: theme.spacing(3),
  },
  formControl: {
    width: '100%',
  },
  formWrapper: {
    padding: `${theme.spacing(4)}px ${theme.spacing(1)}px`,
  },
  separator: {
    height: theme.spacing(2),
  },
  wrapperStep: {
    padding: `${theme.spacing(4)}px 0px`,
  },
  wrapperWizard: {
    width: '100%',
    padding: theme.spacing(4),
  },
}));

const breadcrumbs = [
  {
    label: 'Sales',
    link: '/sales',
  },
  {
    label: 'Suppliers',
    link: '/sales/suppliers',
  },
];

const SupplierEdit = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { selectedData, type } = location.state;
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState(
    selectedData.status || ''
  );

  const [name, setName] = useState(selectedData.name || '');
  const [websiteUrl, setWebsiteUrl] = useState(selectedData.websiteUrl || '');
  const [contactPerson, setContactPerson] = useState(
    selectedData.contactPerson || ''
  );
  const [contactNumber, setContactNumber] = useState(
    selectedData.contactNumber || ''
  );

  const [validation, setValidation] = useState({
    isErrorShowing: false,
    message: '',
    errorStep: 0,
  });

  const creationLoadingStatus = useSelector(
    (state) => state.suppliersEdit.loadingStatus.creation
  );

  const editLoadingStatus = useSelector(
    (state) => state.suppliersEdit.loadingStatus.edit
  );

  const isUserRedirectedToBrowse = useSelector(
    (state) => state.suppliersEdit.isUserRedirectedToBrowse
  );

  const [supplierStatus] = useState([
    { label: 'Ready to Order', value: 'READY_TO_ORDER' },
    { label: 'Awaiting Approval', value: 'AWAITING_APPROVAL' },
    { label: 'Need to Contact', value: 'NEED_TO_CONTACT' },
    { label: 'Rejected', value: 'REJECTED' },
  ]);

  useEffect(() => {
    if (
      creationLoadingStatus === 'PENDING' ||
      editLoadingStatus === 'PENDING'
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [creationLoadingStatus, editLoadingStatus]);

  useEffect(() => {
    if (
      creationLoadingStatus === 'PENDING' ||
      editLoadingStatus === 'PENDING'
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [creationLoadingStatus, editLoadingStatus]);

  useEffect(() => {
    if (isUserRedirectedToBrowse) {
      dispatch(initiateStateReset());
      setIsLoading(true);
      history.goBack();
    }
  }, [isUserRedirectedToBrowse]);

  const onEdit = () => {
    dispatch(
      editSupplier(selectedData.id, {
        name,
        status: selectedStatus,
        contactPerson,
        websiteUrl,
        contactNumber,
      })
    );
    setIsLoading(true);
    history.goBack();
  };

  const onAdd = () => {
    dispatch(
      createSupplier({
        name,
        status: selectedStatus,
        contactPerson,
        websiteUrl,
        contactNumber,
      })
    );
    setIsLoading(true);
    history.goBack();
  };

  const onNextClick = () => {
    switch (activeStep) {
      case 0:
        if (name === '' || selectedStatus === '') {
          setValidation({
            isErrorShowing: true,
            message: 'Please complete all required fields.',
            errorStep: 0,
          });
        } else {
          setValidation({
            isErrorShowing: false,
            message: '',
            errorStep: 0,
          });
          setActiveStep(1);
        }
        break;
      case 1:
        setValidation({
          isErrorShowing: false,
          message: '',
          errorStep: 1,
        });
        if (type === 'add') {
          onAdd();
        } else if (type === 'edit') {
          onEdit();
        }
        break;
      default:
        console.log('Unknown step');
        break;
    }
  };

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <div className={classes.wrapperStep}>
            <Typography variant="h4" gutterBottom>
              Enter basic info
            </Typography>
            <Typography variant="body1">
              Please complete all fields below.
            </Typography>
            <form className={classes.formWrapper}>
              <TextField
                required
                label="Name"
                fullWidth
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <div className={classes.separator} />
              <FormControl className={classes.formControl} required>
                <InputLabel shrink id="client-select-label">
                  Status
                </InputLabel>
                <Select
                  labelId="status-select-label"
                  id="status-select"
                  value={selectedStatus}
                  onChange={(e) => setSelectedStatus(e.target.value)}
                >
                  {supplierStatus
                    .sort((a, b) => {
                      if (a.label > b.label) return +1;
                      if (a.label < b.label) return -1;
                      return 0;
                    })
                    .map((sta) => (
                      <MenuItem key={sta.value} value={sta.value}>
                        {sta.label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </form>
          </div>
        );
      case 1:
        return (
          <div className={classes.wrapperStep}>
            <Typography variant="h4" gutterBottom>
              Enter additional info
            </Typography>
            <form className={classes.formWrapper}>
              <TextField
                label="Website URL"
                fullWidth
                placeholder="https://www.google.com/"
                value={websiteUrl}
                onChange={(e) => setWebsiteUrl(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <div className={classes.separator} />

              <TextField
                label="Contact Person"
                fullWidth
                placeholder="John Doe"
                value={contactPerson}
                onChange={(e) => setContactPerson(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <div className={classes.separator} />
              <TextField
                label="Contact Number"
                fullWidth
                placeholder="082 222 2222"
                value={contactNumber}
                onChange={(e) => setContactNumber(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </form>
            <div className={classes.separator} />
            {validation.isErrorShowing && validation.errorStep === 1 && (
              <Alert className={classes.alert} severity="error">
                {validation.message}
              </Alert>
            )}
          </div>
        );

      default:
        return <div />;
    }
  };

  const getNextButtonText = () => {
    if (activeStep === 0) {
      return 'Next';
    }
    if (type === 'add') {
      return 'Create';
    }
    return 'Update';
  };

  return (
    <div>
      <PageHeader
        isLoading={isLoading}
        title={`${type === 'add' ? 'Add' : 'Edit'} Supplier`}
        subtitle={type === 'add' || isLoading ? '' : name}
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb={type === 'add' ? 'Add' : 'Edit'}
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
      />
      <ContentWrapper>
        <Paper className={classes.wrapperWizard}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Stepper activeStep={activeStep} orientation="vertical">
                <Step>
                  <StepLabel>Basic Info</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Additional Info</StepLabel>
                </Step>
              </Stepper>
            </Grid>
            <Grid item xs={9}>
              {getStepContent()}
              <div className={classes.actionsBar}>
                <Button
                  disabled={activeStep === 0 || isLoading}
                  onClick={() => setActiveStep(activeStep - 1)}
                >
                  Previous
                </Button>
                <Button
                  disabled={isLoading}
                  variant="contained"
                  color="secondary"
                  onClick={onNextClick}
                >
                  {getNextButtonText()}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </ContentWrapper>
    </div>
  );
};

export default SupplierEdit;
