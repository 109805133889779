/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getAgencies, getDeals, getSalesAgents } from '../../../api/salesAPI';

const initialState = {
  dealsList: [],
  salesAgents: [],
  agencies: [],
  loadingStatus: {
    deals: 'NOT_STARTED',
    salesAgents: 'NOT_STARTED',
    agencies: 'NOT_STARTED',
  },
  feedback: {
    isOpen: false,
    type: undefined,
    message: '',
  },
};

const dealsSlice = createSlice({
  name: 'deals',
  initialState,
  reducers: {
    fetchDealsStarted(state) {
      state.loadingStatus.deals = 'PENDING';
    },
    fetchDealsSuccess(state, action) {
      state.dealsList = action.payload;
      state.loadingStatus.deals = 'COMPLETE';
    },
    fetchDealsFailed(state, action) {
      state.loadingStatus.deals = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getSalesAgentsStarted(state) {
      state.loadingStatus.salesAgents = 'PENDING';
    },
    getSalesAgentsSuccess(state, action) {
      state.salesAgents = action.payload;
      state.loadingStatus.salesAgents = 'COMPLETE';
    },
    getSalesAgentsFailed(state, action) {
      state.loadingStatus.salesAgents = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getAgenciesStarted(state) {
      state.loadingStatus.agencies = 'PENDING';
    },
    getAgenciesSuccess(state, action) {
      state.agencies = action.payload;
      state.loadingStatus.agencies = 'COMPLETE';
    },
    getAgenciesFailed(state, action) {
      state.loadingStatus.agencies = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
  },
});

export const {
  fetchDealsStarted,
  fetchDealsSuccess,
  fetchDealsFailed,
  getSalesAgentsStarted,
  getSalesAgentsSuccess,
  getSalesAgentsFailed,
  getAgenciesStarted,
  getAgenciesSuccess,
  getAgenciesFailed,
} = dealsSlice.actions;

export default dealsSlice.reducer;

export const fetchDeals =
  (dateType, startDate, endDate, isAgent, userId) => async (dispatch) => {
    dispatch(fetchDealsStarted());
    const result = await getDeals(
      dateType,
      startDate,
      endDate,
      isAgent,
      userId
    );

    if (result.status === 200) {
      dispatch(fetchDealsSuccess(result.deals));
    } else {
      dispatch(fetchDealsFailed(result.error));
    }
  };

export const fetchSalesAgents = () => async (dispatch) => {
  dispatch(getSalesAgentsStarted());
  const result = await getSalesAgents();

  if (result.status === 200) {
    dispatch(getSalesAgentsSuccess(result.salesAgents));
  } else {
    dispatch(getSalesAgentsFailed(result.error));
  }
};

export const fetchAgencies = () => async (dispatch) => {
  dispatch(getAgenciesStarted());
  const result = await getAgencies();

  if (result.status === 200) {
    dispatch(getAgenciesSuccess(result.agencies));
  } else {
    dispatch(getAgenciesFailed(result.error));
  }
};
