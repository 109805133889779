/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  productsList: [],
  loadingStatus: {
    products: 'NOT_STARTED',
  },
  pageSize: 5,
  initialPage: 0,
};

const listingEditsBrowseSlice = createSlice({
  name: 'listingEditsBrowse',
  initialState,
  reducers: {
    changePageSize(state, action) {
      state.pageSize = action.payload;
    },
    changeInitialPage(state, action) {
      state.initialPage = action.payload;
    },
  },
});

export const { changePageSize, changeInitialPage } =
  listingEditsBrowseSlice.actions;

export const updatePageSize = (newPageSize) => (dispatch) => {
  dispatch(changePageSize(newPageSize));
};

export const updateInitialPage = (newInitialPage) => (dispatch) => {
  dispatch(changeInitialPage(newInitialPage));
};

export default listingEditsBrowseSlice.reducer;
