import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { createSupplier, initiateStateReset } from './slice';
import ContentWrapper from '../../../components/ContentWrapper';
import PageHeader from '../../../components/PageHeader';
import allStyles from '../../../utils/styles';

const useStyles = makeStyles(allStyles);

const breadcrumbs = [
  {
    label: 'Presales',
    link: '/presales',
  },
  {
    label: 'Suppliers',
    link: '/presales/suppliers',
  },
];

const SuppliersAdd = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [status, setStatus] = useState('AWAITING_APPROVAL');
  const [mainContact, setMainContact] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [telephoneNumber, setTelephoneNumber] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [minUnitsAvailable, setMinUnitsAvailable] = useState(5);
  const [maxCostPrice, setMaxCostPrice] = useState(10000);
  const [minRetailPrice, setMinRetailPrice] = useState(120);
  const [sohPercentMadeAvailable, setSohPercentMadeAvailable] = useState(50);
  const [maxWeight, setMaxWeight] = useState(50);
  const [maxLongestSide, setMaxLongestSide] = useState(300);
  const [minMargin, setMinMargin] = useState(20);
  const [vatOption, setVatOption] = useState('');
  const [validation, setValidation] = useState({
    isErrorShowing: false,
    message: '',
    errorStep: 0,
  });

  const creationLoadingStatus = useSelector(
    (state) => state.presalesSuppliersAdd.loadingStatus.creation
  );
  const isUserRedirectedToBrowse = useSelector(
    (state) => state.presalesSuppliersAdd.isUserRedirectedToBrowse
  );

  useEffect(() => {
    if (creationLoadingStatus === 'PENDING') {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [creationLoadingStatus]);

  useEffect(() => {
    if (isUserRedirectedToBrowse) {
      dispatch(initiateStateReset());
      setIsLoading(true);
      history.goBack();
    }
  }, [isUserRedirectedToBrowse]);

  const onNextClick = () => {
    switch (activeStep) {
      case 0:
        if (name === '' || code === '') {
          setValidation({
            isErrorShowing: true,
            message: 'Please complete all required fields.',
            errorStep: 0,
          });
        } else {
          setValidation({
            isErrorShowing: false,
            message: '',
            errorStep: 0,
          });
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        break;
      case 1:
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setValidation({
          isErrorShowing: false,
          message: '',
          errorStep: 1,
        });
        break;
      case 2:
        if (
          minUnitsAvailable < 0 ||
          maxCostPrice < 0 ||
          minRetailPrice < 0 ||
          maxWeight < 0 ||
          maxLongestSide < 0
        ) {
          setValidation({
            isErrorShowing: true,
            message: 'Please enter positive values.',
            errorStep: 2,
          });
        } else if (
          sohPercentMadeAvailable > 100 ||
          sohPercentMadeAvailable < 0 ||
          minMargin > 100 ||
          minMargin < 0
        ) {
          setValidation({
            isErrorShowing: true,
            message: 'Please enter a valid percentage value.',
            errorStep: 2,
          });
        } else if (!vatOption) {
          setValidation({
            isErrorShowing: true,
            message: 'Please select a VAT option',
            errorStep: 2,
          });
        } else {
          dispatch(
            createSupplier({
              name,
              code,
              status,
              additionalInfo: {
                mainContact,
                emailAddress,
                telephoneNumber,
                websiteUrl,
              },
              supplierSettings: {
                minUnitsAvailable,
                maxCostPrice,
                minRetailPrice,
                sohPercentMadeAvailable,
                maxWeight,
                maxLongestSide,
                minMargin,
                vatOption,
              },
            })
          );

          setValidation({
            isErrorShowing: false,
            message: '',
            errorStep: 2,
          });
        }
        break;
      default:
        console.log('Unknown step');
        break;
    }
  };

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <div className={classes.wrapperStep}>
            <Typography variant="h4" gutterBottom>
              Enter supplier&apos;s name
            </Typography>
            <Typography variant="body1">
              Please enter an easy-to-read name used to refer to the supplier.
              Note that this need not be the legal name.
            </Typography>
            <form className={classes.formWrapper}>
              <TextField
                required
                label="Name"
                fullWidth
                placeholder="Supply Corp."
                value={name}
                onChange={(e) => setName(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <div className={classes.separator} />
              <TextField
                required
                label="Code"
                fullWidth
                placeholder="SCP"
                value={code}
                onChange={(e) =>
                  setCode(_.toUpper(e.target.value).substring(0, 3))
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <div className={classes.separator} />
              <FormControl required className={classes.formControl}>
                <InputLabel shrink id="status-select-label">
                  Status
                </InputLabel>
                <Select
                  labelId="status-select-label"
                  id="status-select"
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                >
                  <MenuItem value="AWAITING_APPROVAL">
                    Awaiting Approval
                  </MenuItem>
                  <MenuItem value="ONBOARDING_PRODUCTS">
                    Onboarding Products
                  </MenuItem>
                  <MenuItem value="TRADING">Trading</MenuItem>
                </Select>
              </FormControl>
            </form>
            <div className={classes.separator} />
            {validation.isErrorShowing && validation.errorStep === 0 && (
              <Alert className={classes.alert} severity="error">
                {validation.message}
              </Alert>
            )}
          </div>
        );
      case 1:
        return (
          <div className={classes.wrapperStep}>
            <Typography variant="h4" gutterBottom>
              Enter the supplier&apos;s additional info
            </Typography>
            <Typography variant="body1">
              Please complete as many fields for the supplier&apos;s additional
              info as possible. You can edit this in the future if you are
              uncertain of the info now.
            </Typography>
            <div className={classes.separator} />
            <TextField
              label="Main Contact"
              fullWidth
              value={mainContact}
              onChange={(e) => setMainContact(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <div className={classes.separator} />
            <TextField
              label="Email Address"
              fullWidth
              type="email"
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <div className={classes.separator} />
            <TextField
              label="Telephone Number"
              fullWidth
              type="telephone"
              value={telephoneNumber}
              onChange={(e) => setTelephoneNumber(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <div className={classes.separator} />
            <TextField
              label="Website URL"
              fullWidth
              type="url"
              value={websiteUrl}
              onChange={(e) => setWebsiteUrl(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <div className={classes.separator} />
            {validation.isErrorShowing && validation.errorStep === 1 && (
              <Alert className={classes.alert} severity="error">
                {validation.message}
              </Alert>
            )}
          </div>
        );
      case 2:
        return (
          <div className={classes.wrapperStep}>
            <Typography variant="h4" gutterBottom>
              Adjust supplier&apos;s settings.
            </Typography>
            <Typography variant="body1">
              The current supplier&apos;s settings are set to default values.
              You can enter new values to adjust the suppler&apos;s settings or
              you can choose to edit this in the future if you are uncertain of
              the info now.
            </Typography>
            <div className={classes.separator} />
            <TextField
              label="Minimum Available Units"
              fullWidth
              value={minUnitsAvailable}
              onChange={(e) => setMinUnitsAvailable(Number(e.target.value))}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <div className={classes.separator} />
            <TextField
              label="Max Cost Price (R)"
              fullWidth
              value={maxCostPrice}
              onChange={(e) => setMaxCostPrice(Number(e.target.value))}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <div className={classes.separator} />
            <TextField
              label="Minimum Retail Price (R)"
              fullWidth
              value={minRetailPrice}
              onChange={(e) => setMinRetailPrice(Number(e.target.value))}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <div className={classes.separator} />
            <TextField
              label="Percentage of SOH made available (%)"
              fullWidth
              value={sohPercentMadeAvailable}
              onChange={(e) =>
                setSohPercentMadeAvailable(Number(e.target.value))
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
            <div className={classes.separator} />
            <TextField
              label="Maximum Weight (kg)"
              fullWidth
              value={maxWeight}
              onChange={(e) => setMaxWeight(Number(e.target.value))}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <div className={classes.separator} />
            <TextField
              label="Maximum Length of Longest Side (cm)"
              fullWidth
              value={maxLongestSide}
              onChange={(e) => setMaxLongestSide(Number(e.target.value))}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <div className={classes.separator} />
            <TextField
              label="Minimum Margin (%)"
              fullWidth
              value={minMargin}
              onChange={(e) => setMinMargin(Number(e.target.value))}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <div className={classes.separator} />
            <FormControl required className={classes.formControl}>
              <InputLabel shrink id="status-select-label">
                Is VAT included in the cost price?
              </InputLabel>
              <Select
                labelId="vat-select-label"
                id="vat-select"
                value={vatOption}
                label="VAT"
                onChange={(e) => setVatOption(e.target.value)}
              >
                <MenuItem value="EXCLUDED">VAT Excluded</MenuItem>
                <MenuItem value="INCLUDED">VAT Included</MenuItem>
              </Select>
            </FormControl>
            <div className={classes.separator} />
            {validation.isErrorShowing && validation.errorStep === 2 && (
              <Alert className={classes.alert} severity="error">
                {validation.message}
              </Alert>
            )}
          </div>
        );
      default:
        return <div />;
    }
  };

  return (
    <div>
      <PageHeader
        isLoading={isLoading}
        title="Add New Supplier"
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Add"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
      />
      <ContentWrapper>
        <Paper className={classes.wrapperWizard}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Stepper activeStep={activeStep} orientation="vertical">
                <Step>
                  <StepLabel>Basic Details</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Additional Info</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Supplier Settings</StepLabel>
                </Step>
              </Stepper>
            </Grid>
            <Grid item xs={9}>
              {getStepContent()}
              <div className={classes.actionsBar}>
                <Button
                  disabled={activeStep === 0 || isLoading}
                  onClick={() => setActiveStep(activeStep - 1)}
                >
                  Previous
                </Button>
                <Button
                  disabled={isLoading}
                  variant="contained"
                  color="secondary"
                  onClick={onNextClick}
                >
                  {activeStep !== 2 ? 'Next' : 'Create'}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </ContentWrapper>
    </div>
  );
};

export default SuppliersAdd;
