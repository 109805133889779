import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import {
  bulkUpdateSkus,
  changeSku,
  fetchSkus,
  updateInitialPage,
  updatePageSize,
} from './slice';
import {
  findSku,
  getCopywritingCounts,
  getFormattedListings,
  getImagesCounts,
  getProductName,
  getStockInfoCounts,
  getSubmissionCounts,
  getWeeksOutstandingSkuCounts,
} from './logic';
import AdjustBriefingDialog from './components/AdjustBriefingDialog';
import ContentWrapper from '../../../components/ContentWrapper';
import EmptyViewMessage from '../../../components/EmptyViewMessage';
import FiltersBar from './components/FiltersBar';
import PageHeader from '../../../components/PageHeader';
import SecondaryFiltersBar from './components/SecondaryFiltersBar';
import SkusTable from './components/SkusTable';
import StatusBarGraph from './components/StatusBarGraph';
import WeeksOutstandingDoughnutGraph from './components/WeeksOutstandingDoughnutGraph';

const breadcrumbs = [
  {
    label: 'Listings',
    link: '/listings',
  },
  {
    label: 'Onboarding',
    link: '/listings/onboarding',
  },
];

const ListingsOnboarding = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [platformFilter, setPlatformFilter] = useState('TAKEALOT');
  const [clientFilter, setClientFilter] = useState('ALL');
  const [stockInfoFilter, setStockInfoFilter] = useState('ALL');
  const [copywritingFilter, setCopywritingFilter] = useState('ALL');
  const [imagesFilter, setImagesFilter] = useState('ALL');
  const [submissionFilter, setSubmissionFilter] = useState('ALL');
  const [weeksOutstandingFilter, setWeeksOutstandingFilter] = useState('ALL');
  const [formattedListings, setFormattedListings] = useState([]);
  const [isAdjustBriefingDialogOpen, setIsAdjustBriefingDialogOpen] =
    useState(false);
  const [selectedSku, setSelectedSku] = useState({
    onboarding: {},
  });

  const skus = useSelector((state) => state.listingsOnboarding.skusList);
  const pageSize = useSelector((state) => state.listingsOnboarding.pageSize);
  const initialPage = useSelector(
    (state) => state.listingsOnboarding.initialPage
  );
  const skusLoadingStatus = useSelector(
    (state) => state.listingsOnboarding.loadingStatus.skus
  );
  const updateSkuLoadingStatus = useSelector(
    (state) => state.listingsOnboarding.loadingStatus.updateSku
  );

  useEffect(() => {
    if (skusLoadingStatus !== 'PENDING') {
      dispatch(fetchSkus(platformFilter));
      setClientFilter('ALL');
    }
  }, [platformFilter]);

  useEffect(() => {
    if (
      skusLoadingStatus === 'PENDING' ||
      updateSkuLoadingStatus === 'PENDING'
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [skusLoadingStatus, updateSkuLoadingStatus]);

  useEffect(() => {
    setFormattedListings(
      getFormattedListings(
        skus,
        clientFilter,
        searchQuery,
        weeksOutstandingFilter,
        stockInfoFilter,
        copywritingFilter,
        imagesFilter,
        submissionFilter
      )
    );
  }, [
    skus,
    clientFilter,
    searchQuery,
    weeksOutstandingFilter,
    stockInfoFilter,
    copywritingFilter,
    imagesFilter,
    submissionFilter,
  ]);

  const getClients = () => {
    return _.uniq(skus.map((info) => info.clientName)).sort();
  };

  const updateStatuses = (statuses, sku) => {
    const skuInfo = findSku(sku, skus);
    if (platformFilter === 'AMAZON') {
      dispatch(
        changeSku(
          sku,
          {
            ...skuInfo,
            amazon: {
              ...skuInfo.amazon,
              onboarding: {
                ...skuInfo.onboarding,
                statuses,
              },
            },
          },
          'AMAZON'
        )
      );
    } else {
      dispatch(
        changeSku(
          sku,
          {
            ...skuInfo,
            takealot: {
              ...skuInfo.takealot,
              onboarding: {
                ...skuInfo.onboarding,
                statuses,
              },
            },
          },
          'TAKEALOT'
        )
      );
    }
  };

  const updateBriefing = (briefing, isBulkUpdate, sku) => {
    const skuInfo = findSku(sku, skus);
    if (platformFilter === 'AMAZON') {
      if (isBulkUpdate) {
        const allClientSkus = skus.filter(
          (info) => info.clientId === skuInfo.clientId
        );
        dispatch(
          bulkUpdateSkus(
            allClientSkus,
            {
              'amazon.onboarding.photosRequired': briefing.photosRequired,
              'amazon.onboarding.clientCopyLink': briefing.clientCopyLink,
              'amazon.onboarding.clientImagesLink': briefing.clientImagesLink,
              'amazon.onboarding.specialRequests': briefing.specialRequests,
              'amazon.onboarding.statuses': skuInfo.onboarding.statuses,
            },
            'AMAZON'
          )
        );
      } else {
        dispatch(
          changeSku(
            sku,
            {
              ...skuInfo,
              amazon: {
                ...skuInfo.amazon,
                onboarding: {
                  ...skuInfo.onboarding,
                  ...briefing,
                },
              },
            },
            'AMAZON'
          )
        );
      }
    } else if (isBulkUpdate) {
      const allClientSkus = skus.filter(
        (info) => info.clientId === skuInfo.clientId
      );
      dispatch(
        bulkUpdateSkus(
          allClientSkus,
          {
            'takealot.onboarding.photosRequired': briefing.photosRequired,
            'takealot.onboarding.clientCopyLink': briefing.clientCopyLink,
            'takealot.onboarding.clientImagesLink': briefing.clientImagesLink,
            'takealot.onboarding.specialRequests': briefing.specialRequests,
            'takealot.onboarding.statuses': skuInfo.onboarding.statuses,
          },
          'TAKEALOT'
        )
      );
    } else {
      dispatch(
        changeSku(
          sku,
          {
            ...skuInfo,
            takealot: {
              ...skuInfo.takealot,
              onboarding: {
                ...skuInfo.onboarding,
                ...briefing,
              },
            },
          },
          'TAKEALOT'
        )
      );
    }
  };

  return (
    <div>
      <PageHeader
        title="Onboarding Dashboard"
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Dashboard"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
      />
      {isLoading && <LinearProgress color="secondary" />}
      <ContentWrapper>
        {!isLoading && skus.length === 0 ? (
          <Grid container spaacing={3}>
            <Grid item xs={12}>
              <FiltersBar
                selectedClientName={clientFilter}
                showClientCount={clientFilter === 'ALL'}
                clientCount={getClients().length}
                skuCount={getFormattedListings(skus, clientFilter).length}
                platformFilter={platformFilter}
                changePlatformFilter={(newPlatform) => {
                  setPlatformFilter(newPlatform);
                  setClientFilter('ALL');
                }}
                clientFilter={clientFilter}
                changeClientFilter={setClientFilter}
                clientOptions={getClients()}
              />
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <EmptyViewMessage
                  heading="No Onboarding SKUs"
                  message="Only SKUs being onboarded will be shown here."
                />
              </Paper>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FiltersBar
                selectedClientName={clientFilter}
                showClientCount={clientFilter === 'ALL'}
                clientCount={getClients().length}
                skuCount={getFormattedListings(skus, clientFilter).length}
                platformFilter={platformFilter}
                changePlatformFilter={(newPlatform) => {
                  setPlatformFilter(newPlatform);
                  setClientFilter('ALL');
                }}
                clientFilter={clientFilter}
                changeClientFilter={setClientFilter}
                clientOptions={getClients()}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <StatusBarGraph
                stockInfoCounts={getStockInfoCounts(skus, clientFilter)}
                copywritingCounts={getCopywritingCounts(skus, clientFilter)}
                imagesCounts={getImagesCounts(skus, clientFilter)}
                submissionCounts={getSubmissionCounts(skus, clientFilter)}
                isLoading={isLoading}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <WeeksOutstandingDoughnutGraph
                skuCounts={getWeeksOutstandingSkuCounts(skus, clientFilter)}
                isLoading={isLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <SecondaryFiltersBar
                searchText={searchQuery}
                changeSearchText={setSearchQuery}
                listingCount={formattedListings.length}
                weeksOutstandingFilter={weeksOutstandingFilter}
                changeWeeksOutstandingFilter={setWeeksOutstandingFilter}
                stockInfoFilter={stockInfoFilter}
                changeStockInfoFilter={setStockInfoFilter}
                copywritingFilter={copywritingFilter}
                changeCopywritingFilter={setCopywritingFilter}
                imagesFilter={imagesFilter}
                changeImagesFilter={setImagesFilter}
                submissionFilter={submissionFilter}
                changeSubmissionFilter={setSubmissionFilter}
              />
            </Grid>
            <Grid item xs={12}>
              <SkusTable
                isLoading={isLoading}
                skus={formattedListings}
                adjustBriefing={(skuInfo) => {
                  setIsAdjustBriefingDialogOpen(true);
                  setSelectedSku(skuInfo);
                }}
                updateStatuses={(statuses, sku) =>
                  updateStatuses(statuses, sku)
                }
                goToListing={(sku) =>
                  history.push(`/listings/basic-info/browse/${sku}`)
                }
                pageSize={pageSize}
                initialPage={initialPage}
                updatePageSize={(newPageSize) =>
                  dispatch(updatePageSize(newPageSize))
                }
                updateInitialPage={(newInitialPage) =>
                  dispatch(updateInitialPage(newInitialPage))
                }
              />
            </Grid>
          </Grid>
        )}
      </ContentWrapper>
      <AdjustBriefingDialog
        isOpen={isAdjustBriefingDialogOpen}
        sku={selectedSku.sku}
        productName={getProductName(selectedSku.sku, skus)}
        closeDialog={() => {
          setIsAdjustBriefingDialogOpen(false);
          setSelectedSku({
            onboarding: {},
          });
        }}
        briefing={selectedSku.onboarding}
        updateBriefing={(briefing, isBulkUpdate) =>
          updateBriefing(briefing, isBulkUpdate, selectedSku.sku)
        }
      />
    </div>
  );
};

export default ListingsOnboarding;
