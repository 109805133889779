/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  checkIfBarcodeTaken,
  checkIfSkuTaken,
  createNewListing,
} from '../../../api/listingsAPI';
import { getClients } from '../../../api/clientsAPI';
import { getWarehouses } from '../../../api/logisticsAPI';
import { getSuppliers } from '../../../api/suppliersAPI';

const initialState = {
  clients: [],
  warehousesList: [],
  suppliersList: [],
  isUserRedirectedToBrowse: false,
  skuExists: false,
  barcodeExists: false,
  loadingStatus: {
    creation: 'NOT_STARTED',
    warehouses: 'NOT_STARTED',
    clients: 'NOT_STARTED',
    barcodeCheck: 'NOT_STARTED',
    skuCheck: 'NOT_STARTED',
    suppliers: 'NOT_STARTED',
  },
  feedback: {
    isOpen: false,
    type: undefined,
    message: '',
  },
};

const listingsAddSlice = createSlice({
  name: 'listingsAdd',
  initialState,
  reducers: {
    createSkuStarted(state) {
      state.loadingStatus.creation = 'PENDING';
    },
    createSkuSuccess(state) {
      state.loadingStatus.creation = 'COMPLETE';
      state.isUserRedirectedToBrowse = true;
    },
    createSkuFailed(state, action) {
      state.loadingStatus.creation = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    checkBarcodeStarted(state) {
      state.loadingStatus.barcodeCheck = 'PENDING';
    },
    checkBarcodeSuccess(state, action) {
      state.loadingStatus.barcodeCheck = 'COMPLETE';
      state.barcodeExists = action.payload;
    },
    checkBarcodeFailed(state, action) {
      state.loadingStatus.barcodeCheck = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    checkSkuStarted(state) {
      state.loadingStatus.skuCheck = 'PENDING';
    },
    checkSkuSuccess(state, action) {
      state.loadingStatus.skuCheck = 'COMPLETE';
      state.skuExists = action.payload;
    },
    checkSkuFailed(state, action) {
      state.loadingStatus.skuCheck = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getClientsStarted(state) {
      state.loadingStatus.clients = 'PENDING';
    },
    getClientsSuccess(state, action) {
      state.clients = action.payload;
      state.loadingStatus.clients = 'COMPLETE';
    },
    getClientsFailed(state, action) {
      state.loadingStatus.clients = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getWarehousesStarted(state) {
      state.loadingStatus.warehouses = 'PENDING';
    },
    getWarehousesSuccess(state, action) {
      state.warehousesList = action.payload;
      state.loadingStatus.warehouses = 'COMPLETE';
    },
    getWarehousesFailed(state, action) {
      state.loadingStatus.warehouses = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getSuppliersStarted(state) {
      state.loadingStatus.suppliers = 'PENDING';
    },
    getSuppliersSuccess(state, action) {
      state.suppliersList = action.payload;
      state.loadingStatus.suppliers = 'COMPLETE';
    },
    getSuppliersFailed(state, action) {
      state.loadingStatus.suppliers = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    resetState() {
      return initialState;
    },
  },
});

export const {
  createSkuStarted,
  createSkuSuccess,
  createSkuFailed,
  checkBarcodeStarted,
  checkBarcodeSuccess,
  checkBarcodeFailed,
  checkSkuStarted,
  checkSkuSuccess,
  checkSkuFailed,
  getClientsStarted,
  getClientsSuccess,
  getClientsFailed,
  getWarehousesStarted,
  getWarehousesSuccess,
  getWarehousesFailed,
  getSuppliersFailed,
  getSuppliersStarted,
  getSuppliersSuccess,
  resetState,
} = listingsAddSlice.actions;

export default listingsAddSlice.reducer;

export const createSku = (sku, skuInfo) => async (dispatch) => {
  dispatch(createSkuStarted());
  const result = await createNewListing(sku, skuInfo);

  if (result.status === 200) {
    dispatch(createSkuSuccess());
  } else {
    dispatch(createSkuFailed(result.error));
  }
};

export const checkIfBarcodeExists = (barcode) => async (dispatch) => {
  dispatch(checkBarcodeStarted());
  const result1 = await checkIfBarcodeTaken(barcode, 'AMAZON');
  const result2 = await checkIfBarcodeTaken(barcode, 'TAKEALOT');

  if (result1.status === 200 && result2.status === 200) {
    dispatch(checkBarcodeSuccess(result1.taken || result2.taken));
  } else if (result1.status !== 200) {
    dispatch(checkBarcodeFailed(result1.error));
  } else {
    dispatch(checkBarcodeFailed(result2.error));
  }
};

export const checkIfSkuExists = (sku) => async (dispatch) => {
  dispatch(checkSkuStarted());
  const result1 = await checkIfSkuTaken(sku, 'GENERAL');
  const result2 = await checkIfSkuTaken(sku, 'AMAZON');
  const result3 = await checkIfSkuTaken(sku, 'TAKEALOT');

  if (
    result1.status === 200 &&
    result2.status === 200 &&
    result3.status === 200
  ) {
    dispatch(checkSkuSuccess(result1.taken || result2.taken || result3.taken));
  } else if (result1.status !== 200) {
    dispatch(checkSkuFailed(result1.error));
  } else if (result2.status !== 200) {
    dispatch(checkSkuFailed(result2.error));
  } else {
    dispatch(checkSkuFailed(result3.error));
  }
};

export const fetchClients = () => async (dispatch) => {
  dispatch(getClientsStarted());
  const result = await getClients();

  if (result.status === 200) {
    dispatch(getClientsSuccess(result.clients));
  } else {
    dispatch(getClientsFailed(result.error));
  }
};

export const fetchWarehouses = () => async (dispatch) => {
  dispatch(getWarehousesStarted());
  const result = await getWarehouses();

  if (result.status === 200) {
    dispatch(getWarehousesSuccess(result.warehouses));
  } else {
    dispatch(getWarehousesFailed(result.error));
  }
};

export const fetchSuppliers = () => async (dispatch) => {
  dispatch(getSuppliersStarted());
  const result = await getSuppliers();

  if (result.status === 200) {
    dispatch(getSuppliersSuccess(result.suppliers));
  } else {
    dispatch(getSuppliersFailed(result.error));
  }
};

export const initiateStateReset = () => async (dispatch) => {
  dispatch(resetState());
};
