import { connectRefinementList } from 'react-instantsearch-dom';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import numeral from 'numeral';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
  },
  listItemCount: {
    color: theme.palette.secondary.light,
    borderColor: theme.palette.secondary.light,
  },
  listItemText: {
    color: theme.palette.primary.light,
  },
}));

const StatusRefinementList = ({ items, refine }) => {
  const classes = useStyles();

  const getPrettyStatus = (status) => {
    switch (status) {
      case 'BUYABLE':
        return 'Buyable';
      case 'NOT_BUYABLE':
        return 'Not Buyable';
      case 'DISABLED_BY_SELLER':
        return 'Disabled By Seller';
      case 'DISABLED_BY_TAKEALOT':
        return 'Disabled By Takealot';
      case 'MISSING_INFO':
        return 'Missing Info';
      case 'READY_TO_SUBMIT':
        return 'Ready for Submission';
      case 'SUBMITTED':
        return 'Submitted';
      default:
        return status;
    }
  };

  return (
    <List
      className={classes.root}
      subheader={<ListSubheader>Status</ListSubheader>}
    >
      {items.map((item) => {
        return (
          <ListItem
            key={item.label}
            dense
            button
            onClick={() => {
              refine(item.value);
            }}
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={item.isRefined}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': item.label }}
              />
            </ListItemIcon>
            <ListItemText
              id={item.label}
              primary={getPrettyStatus(item.label)}
              className={classes.listItemText}
            />
            <ListItemSecondaryAction>
              <Chip
                variant="outlined"
                size="small"
                label={numeral(item.count).format('0,0')}
                className={classes.listItemCount}
              />
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
};

StatusRefinementList.defaultProps = {
  items: [],
  refine: (itemValue) => console.log(`User refined the filter by ${itemValue}`),
};

StatusRefinementList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.arrayOf(PropTypes.string),
      isRefined: PropTypes.bool,
      count: PropTypes.number,
    })
  ),
  refine: PropTypes.func,
};

export default connectRefinementList(StatusRefinementList);
