import _ from 'lodash';
import moment from 'moment';

export const getFilteredDeals = (
  deals,
  salesAgents,
  userInfo,
  searchText,
  salesPersonFilter,
  agencyFilter,
  agencyAgents,
  statusFilter
) => {
  return deals
    .filter((dealInfo) => {
      if (userInfo.agencyId && userInfo.type !== 'SALES_AGENT') {
        if (userInfo.uid === dealInfo.salesPersonId) {
          return true;
        }
        const agentsInAgency = salesAgents.filter(
          (agentInfo) => agentInfo.agencyId === userInfo.agencyId
        );
        const relevantAgent = agentsInAgency.find(
          (agentInfo) => agentInfo.id === dealInfo.salesPersonId
        );
        return relevantAgent !== undefined;
      }
      return true;
    })
    .filter((dealInfo) => {
      if (searchText !== '') {
        const caseInsensitiveSearch = _.toLower(searchText);
        const caseInsensitiveBusinessName = _.toLower(dealInfo.businessName);
        const caseInsensitiveSalesPerson = _.toLower(dealInfo.salesPersonName);

        if (caseInsensitiveBusinessName.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveSalesPerson.includes(caseInsensitiveSearch)) {
          return true;
        }

        return false;
      }

      return true;
    })
    .filter((dealInfo) => {
      if (salesPersonFilter === 'ALL') {
        return true;
      }

      return dealInfo.salesPersonId === salesPersonFilter;
    })
    .filter((dealInfo) => {
      if (agencyFilter === 'ALL') {
        return true;
      }

      return agencyAgents.find((info) => info.id === dealInfo.salesPersonId);
    })
    .filter((dealInfo) => {
      if (statusFilter === 'ALL') {
        return true;
      }

      return dealInfo.status === statusFilter;
    });
};

export const getRelevantSalesPeople = (deals, agencyFilter, salesAgents) => {
  return _.uniqBy(
    deals
      .map((dealInfo) => ({
        id: dealInfo.salesPersonId,
        name: dealInfo.salesPersonName,
      }))
      .filter((salesAgentInfo) => {
        if (agencyFilter === 'ALL') {
          return true;
        }
        const completeAgentInfo = salesAgents.find(
          (info) => info.id === salesAgentInfo.id
        );

        if (!completeAgentInfo) {
          return false;
        }

        return completeAgentInfo.agencyId === agencyFilter;
      }),
    (info) => info.id
  );
};

export const getAgencyAgents = (agencyId, salesAgents) => {
  return salesAgents.filter((info) => info.agencyId === agencyId);
};

export const changeDateRange = (
  rangeFilter,
  changeStartDate,
  changeEndDate
) => {
  if (rangeFilter === 'THIS_MONTH') {
    changeStartDate(moment().startOf('month').toDate());
    changeEndDate(moment().toDate());
  } else if (rangeFilter === 'THIS_YEAR') {
    changeStartDate(moment().startOf('year').toDate());
    changeEndDate(moment().toDate());
  } else if (rangeFilter === 'THIS_WEEK') {
    changeStartDate(moment().startOf('week').toDate());
    changeEndDate(moment().toDate());
  } else if (rangeFilter === 'TODAY') {
    changeStartDate(moment().startOf('day').toDate());
    changeEndDate(moment().toDate());
  }
};
