import _ from 'lodash';
import numeral from 'numeral';

export const getFormattedShipments = (
  shipments,
  searchQuery = '',
  statusFilter = 'ALL'
) => {
  return shipments
    .map((info) => {
      return {
        id: info.id,
        fromId: info.fromId,
        fromName: info.fromName,
        toId: info.toId,
        toName: info.toName,
        units: info.totalUnits,
        status: info.status,
        clientName: info.clientName,
        creationDate: info.creationDate || '-',
        isFlaggedWithDiscrepancies:
          info.status === 'COMPLETE' &&
          info.discrepancies &&
          info.discrepancies.length > 0,
        isFlaggedWithAdjustments:
          info.status !== 'COMPLETE' &&
          info.status !== 'CANCELLED' &&
          info.adjustments &&
          info.adjustments.length > 0,
      };
    })
    .filter((info) => {
      if (searchQuery !== '') {
        const caseInsensitiveSearch = _.toLower(searchQuery);
        const caseInsensitiveFromName = _.toLower(info.fromName);
        const caseInsensitiveToName = _.toLower(info.toName);
        const caseInsensitiveClientName = _.toLower(info.clientName);
        const caseInsensitiveShipmentId = _.toLower(info.id);

        if (caseInsensitiveFromName.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveToName.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveClientName.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveShipmentId.includes(caseInsensitiveSearch)) {
          return true;
        }

        return false;
      }

      return true;
    })
    .filter((info) => {
      if (statusFilter !== 'ALL') {
        return info.status === statusFilter;
      }
      return true;
    })
    .sort((a, b) => {
      if (a.creationDate < b.creationDate) return +1;
      if (a.creationDate > b.creationDate) return -1;
      if (a.clientName < b.clientName) return -1;
      if (a.clientName > b.clientName) return +1;
      return 0;
    });
};

export const getWarehouseTotals = (shipments, warehouses) => {
  const clientTotals = shipments.reduce((totalUnits, currentShipment) => {
    if (
      currentShipment.toId === 'CLIENT' &&
      currentShipment.status !== 'COMPLETE' &&
      currentShipment.status !== 'CANCELLED'
    ) {
      return totalUnits + currentShipment.units;
    }
    return totalUnits;
  }, 0);

  return [
    ...warehouses.map((warehouse) => {
      const total = shipments.reduce((totalUnits, currentShipment) => {
        if (
          currentShipment.toName === warehouse.name &&
          currentShipment.status !== 'COMPLETE' &&
          currentShipment.status !== 'CANCELLED'
        ) {
          return totalUnits + currentShipment.units;
        }
        return totalUnits;
      }, 0);

      return {
        value: numeral(total).format('0,0'),
        label: `${warehouse.name} Inbound`,
      };
    }),
    {
      value: numeral(clientTotals).format('0,0'),
      label: 'Clients Inbound',
    },
  ];
};

export const getShipmentToMarkCompleted = (shipmentId, shipments) => {
  const relevantShipment = shipments.find((info) => info.id === shipmentId);

  if (relevantShipment) {
    return Object.values(relevantShipment.skus);
  }

  return [];
};
