import { CSVLink } from 'react-csv';
import { CSVReader } from 'react-papaparse';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import GetAppIcon from '@material-ui/icons/GetApp';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';
import {
  fetchPresalesSuppliers,
  setTotalSkusToUpdate,
  updateSku,
  fetchSkus,
} from './slice';
import {
  checkFields,
  createlistingEditCSV,
  getFilteredSkus,
  getFormattedSkus,
  handleError,
  getLoadingValue,
} from './logic';
import ContentWrapper from '../../../components/ContentWrapper';
import SearchBar from '../../../components/SearchBar';
import PageHeader from '../../../components/PageHeader';
import PresaleSkuTable from '../../../components/PresaleSkuTable';
import allStyles from '../../../utils/styles';

const useStyles = makeStyles(allStyles);

const breadcrumbs = [
  {
    label: 'Presales',
    link: '/presales',
  },
  {
    label: 'Listing Edits',
    link: '/presales/listing-edits',
  },
];
const ListingEditsAdd = () => {
  // General hooks
  const classes = useStyles();
  const history = useHistory();
  const fileUploadRef = useRef();
  const csvLink = useRef();
  const dispatch = useDispatch();

  // State hooks
  const [isLoading, setIsLoading] = useState(false);
  const [supplierId, setSupplierId] = useState('N/A');
  const [skuSearchText, setSkuSearchText] = useState('');
  const [filteredSkus, setFilteredSkus] = useState([]);
  const [selectedPresalesSkus, setSelectedPresalesSkus] = useState([]);

  const [validation, setValidation] = useState({
    isErrorShowing: false,
    message: '',
    errorStep: 0,
  });

  const [activeStep, setActiveStep] = useState(0);
  const [reportData, setReportData] = useState([]);
  const [uploadError, setUploadError] = useState({
    isError: false,
    message: '',
  });

  // Selectors
  const supplierLoadingStatus = useSelector(
    (state) => state.presalesListingEditsAdd.loadingStatus.suppliers
  );

  const updatingLoadingStatus = useSelector(
    (state) => state.presalesListingEditsAdd.loadingStatus.updating
  );
  const updateLoadingCountTotal = useSelector(
    (state) => state.presalesListingEditsAdd.listingEditUpdateLoadingCount.total
  );

  const updateLoadingCountFailed = useSelector(
    (state) =>
      state.presalesListingEditsAdd.listingEditUpdateLoadingCount.failed
  );

  const updateLoadingCountSuccess = useSelector(
    (state) =>
      state.presalesListingEditsAdd.listingEditUpdateLoadingCount.success
  );

  const suppliers = useSelector(
    (state) => state.presalesListingEditsAdd.suppliers
  );
  const presalesSkus = useSelector(
    (state) => state.presalesListingEditsAdd.presalesSkus
  );
  const skusLoadingStatus = useSelector(
    (state) => state.presalesListingEditsAdd.loadingStatus.presalesSkus
  );
  const failedListingEditSkus = useSelector(
    (state) => state.presalesListingEditsAdd.failedListingEditSkus
  );
  // Effects
  useEffect(() => {
    dispatch(fetchPresalesSuppliers());
    dispatch(fetchSkus());
  }, []);

  useEffect(() => {
    if (
      supplierLoadingStatus === 'PENDING' ||
      skusLoadingStatus === 'PENDING' ||
      updatingLoadingStatus === 'PENDING'
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [supplierLoadingStatus, skusLoadingStatus, updatingLoadingStatus]);

  useEffect(() => {
    if (fileUploadRef.current) {
      fileUploadRef.current.removeFile();
    }
  }, [activeStep]);

  useEffect(() => {
    setFilteredSkus(getFilteredSkus(presalesSkus, skuSearchText, supplierId));
  }, [presalesSkus, skuSearchText, supplierId]);

  const skuLoadingValue = getLoadingValue(
    updateLoadingCountTotal,
    updateLoadingCountSuccess,
    updateLoadingCountFailed
  );

  const updateSkuListingEdit = () => {
    reportData.forEach((info) => {
      dispatch(
        updateSku(info.eolSku, {
          'onboardingStatuses.listingEditsComplete': true,
          takealotListingLink: info.takealotListingLink,
        })
      );
    });
  };

  const formatStockUpdates = (data) => {
    const expectedFields = ['SKU', 'Takealot Listing Link'];

    const fields = data[0].data;
    const isFieldsCorrect = checkFields(fields, expectedFields);
    if (isFieldsCorrect) {
      const formattedData = data
        .filter((lineItem, index) => index < data.length - 1 && index > 0)
        .map((lineItem) => {
          const unformattedData = lineItem.data;

          return {
            eolSku: unformattedData[0],
            takealotListingLink: unformattedData[1],
          };
        });

      setReportData(formattedData);
    } else {
      setUploadError({
        isError: true,
        message:
          'Incorrect report uploaded. Please upload a valid listing bulk update file',
      });
    }
  };

  const handleFileLoad = (data) => {
    formatStockUpdates(data);
  };

  const onNextClick = () => {
    switch (activeStep) {
      case 0:
        if (selectedPresalesSkus.length === 0) {
          setValidation({
            isErrorShowing: true,
            errorStep: 0,
            message: 'Please select at least one SKU.',
          });
        } else {
          setUploadError({
            isError: false,
            message: '',
          });

          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        break;
      case 1:
        if (reportData.length <= 0) {
          setUploadError({
            isError: true,
            message: 'Please upload a listing bulk update file',
          });
        } else if (selectedPresalesSkus.length !== reportData.length) {
          setUploadError({
            isError: true,
            message:
              'Incorrect report uploaded. SKUs in file do not match selected',
          });
        } else {
          setUploadError({
            isError: false,
            message: '',
          });
          dispatch(setTotalSkusToUpdate(reportData.length));
          updateSkuListingEdit(reportData, presalesSkus, dispatch);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        break;
      case 2:
        history.goBack();
        break;
      default:
        console.log('Unknown step');
        break;
    }
  };

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <div>
            <Typography variant="h4" gutterBottom>
              Select SKUs for Listing Update
            </Typography>
            <div className={classes.wrapperStep}>
              <Typography variant="body1">
                Please select the Presale Supplier of SKUs to update.
              </Typography>
              <div className={classes.separator} />
              <FormControl className={classes.formControl}>
                <Select
                  labelId="presale-supplier-select-label"
                  id="presale-supplier-select"
                  required
                  value={supplierId}
                  onChange={(e) => {
                    setSupplierId(e.target.value);
                  }}
                >
                  <MenuItem value="N/A">Not Applicable</MenuItem>
                  {suppliers.map((supplierInfo) => (
                    <MenuItem
                      key={supplierInfo.id}
                      value={supplierInfo.id}
                      code={supplierInfo.code}
                    >
                      {supplierInfo.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className={classes.separator} />
              <Typography variant="body1">
                Please select all SKUs for listing updates.
              </Typography>
              <div className={classes.separator} />
              <SearchBar
                searchText={skuSearchText}
                changeSearchText={setSkuSearchText}
                skuCount={filteredSkus.length}
              />
              <PresaleSkuTable
                skus={getFormattedSkus(filteredSkus, selectedPresalesSkus)}
                updateSelectedProducts={setSelectedPresalesSkus}
                contentTitle="Listings"
              />
              {validation.isErrorShowing && validation.errorStep === 0 && (
                <Alert className={classes.alert} severity="error">
                  {validation.message}
                </Alert>
              )}
            </div>
          </div>
        );
      case 1:
        return (
          <div>
            <Typography variant="body1">
              Please download the template file and add Takealot listing links
              for each SKU.
            </Typography>

            <div className={classes.separator} />
            <Button
              startIcon={<GetAppIcon />}
              color="secondary"
              variant="outlined"
              fullWidth
              onClick={() => csvLink.current.link.click()}
            >
              Download template
            </Button>
            <CSVLink
              className={classes.hidden}
              ref={csvLink}
              filename="listings-bulk-update.csv"
              data={createlistingEditCSV(selectedPresalesSkus)}
            >
              Download template
            </CSVLink>

            <div className={classes.separator} />
            <CSVReader
              ref={fileUploadRef}
              onDrop={handleFileLoad}
              onError={handleError}
              addRemoveButton
            >
              <span>Drop completed template here or click to upload.</span>
            </CSVReader>
            <div className={classes.separator} />
            {validation.isErrorShowing && validation.errorStep === 1 && (
              <Alert className={classes.alert} severity="error">
                {validation.message}
              </Alert>
            )}
            {uploadError.isError && (
              <Alert className={classes.alert} severity="error">
                {uploadError.message}
              </Alert>
            )}
          </div>
        );
      case 2:
        return (
          <div>
            {skuLoadingValue < 100 && skuLoadingValue !== -1 && (
              <div>
                <LinearProgress
                  color="secondary"
                  className={classes.loadingIndicator}
                  variant="determinate"
                  value={skuLoadingValue}
                />
                <Typography component="p" variant="subtitle1" align="center">
                  Updating listings...
                </Typography>
              </div>
            )}
            {skuLoadingValue >= 100 && updateLoadingCountFailed === 0 && (
              <div>
                <Alert className={classes.alert} severity="success">
                  All listings have been successfully updated.
                </Alert>
              </div>
            )}
            {skuLoadingValue >= 100 && updateLoadingCountFailed > 0 && (
              <div>
                <Alert className={classes.alert} severity="warning">
                  {`${updateLoadingCountFailed} SKUs failed to update. ${updateLoadingCountSuccess} SKUs successfully updated.`}
                </Alert>
                <div className={classes.separator} />
                <Typography component="p" variant="subtitle1">
                  Failed SKUs:
                </Typography>
                <List>
                  {failedListingEditSkus.map((failedInfo) => (
                    <ListItem key={failedInfo.eolSku}>
                      <ListItemText
                        primary={failedInfo.eolSku}
                        secondary={failedInfo.supplierDescription}
                      />
                    </ListItem>
                  ))}
                </List>
              </div>
            )}
            <div className={classes.separator} />
          </div>
        );
      default:
        return 'Unknown stepIndex';
    }
  };

  return (
    <div>
      <PageHeader
        isLoading={isLoading}
        title="Listings"
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Update"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
      />
      <ContentWrapper>
        <Paper className={classes.wrapperWizard}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Stepper activeStep={activeStep} orientation="vertical">
                <Step>
                  <StepLabel>Select SKUs</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Update Listings</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Confirm SKU changes </StepLabel>
                </Step>
              </Stepper>
            </Grid>
            <Grid item xs={9}>
              {getStepContent()}
              <div className={classes.actionsBar}>
                <Button
                  disabled={activeStep === 0 || isLoading}
                  onClick={() => setActiveStep(activeStep - 1)}
                >
                  Previous
                </Button>
                <Button
                  disabled={isLoading}
                  variant="contained"
                  color="secondary"
                  onClick={onNextClick}
                >
                  {activeStep !== 2 ? 'Next' : 'Done'}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </ContentWrapper>
    </div>
  );
};

export default ListingEditsAdd;
