import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

const ConfirmationDialog = ({
  isOpen,
  message,
  closeDialog,
  confirmAction,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
    >
      <DialogTitle id="confirmation-dialog-title">Are you sure?</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirmation-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary" autoFocus>
          No
        </Button>
        <Button
          onClick={() => {
            confirmAction();
            closeDialog();
          }}
          color="secondary"
          variant="contained"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.defaultProps = {
  isOpen: true,
  message: 'My clicking yes, you confirm that the action should be performed.',
  closeDialog: () => console.log('The dialog was closed.'),
  confirmAction: () => console.log('The action was performed.'),
};

ConfirmationDialog.propTypes = {
  isOpen: PropTypes.bool,
  message: PropTypes.string,
  closeDialog: PropTypes.func,
  confirmAction: PropTypes.func,
};

export default ConfirmationDialog;
