import _ from 'lodash';
import moment from 'moment';
import { firestore, storage, functions } from '../utils/firebase';

export const createNewListing = async (sku, info) => {
  const newListingRef = firestore.collection('skus').doc(sku);

  return newListingRef
    .set(info)
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const updateSku = async (sku, newInfo) => {
  const listingRef = firestore.collection('skus').doc(sku);

  if (newInfo.creationDate) {
    return listingRef
      .update({
        ...newInfo,
        creationDate: new Date(newInfo.creationDate),
      })
      .then(() => {
        return {
          status: 200,
        };
      })
      .catch((error) => {
        return {
          error,
          status: 500,
        };
      });
  }
  return listingRef
    .update(newInfo)
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const getSku = async (sku, platform = 'NOT_SPECIFED') => {
  if (platform === 'TAKEALOT') {
    const skuRef = firestore
      .collection('skus')
      .where('takealot.sku', '==', sku);

    return skuRef
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.length === 0) {
          return {
            sku,
            status: 500,
          };
        }
        let skuInfo = {};
        querySnapshot.forEach((doc) => {
          skuInfo = doc.data();
        });
        if (skuInfo.clientId === undefined) {
          return {
            sku,
            status: 500,
          };
        }
        let onboarding =
          platform === 'TAKEALOT'
            ? skuInfo.takealot.onboarding
            : skuInfo.amazon.onboarding;

        if (!onboarding) {
          onboarding = {
            photosRequired: null,
            clientImagesLink: '',
            clientCopyLink: '',
            specialRequests: '',
            statuses: {
              stockInfo: 'NO_STOCK',
              copywriting: 'NOT_STARTED',
              images: 'NOT_STARTED',
              submission: 'NOT_STARTED',
            },
          };
        }
        return {
          skuInfo: {
            sku,
            ...skuInfo,
            onboarding,
            creationDate: skuInfo.creationDate
              ? moment(skuInfo.creationDate.toDate()).format('MM/DD/YYYY')
              : null,
            lastTakealotPull: null,
          },
          status: 200,
        };
      })
      .catch((error) => {
        return {
          sku,
          error,
          status: 500,
        };
      });
  }
  if (platform === 'AMAZON') {
    const skuRef = firestore.collection('skus').where('amazon.sku', '==', sku);

    return skuRef
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.length === 0) {
          return {
            sku,
            status: 500,
          };
        }
        let skuInfo = {};
        querySnapshot.forEach((doc) => {
          skuInfo = doc.data();
        });
        if (skuInfo.clientId === undefined) {
          return {
            sku,
            status: 500,
          };
        }
        let onboarding =
          platform === 'TAKEALOT'
            ? skuInfo.takealot.onboarding
            : skuInfo.amazon.onboarding;

        if (!onboarding) {
          onboarding = {
            photosRequired: null,
            clientImagesLink: '',
            clientCopyLink: '',
            specialRequests: '',
            statuses: {
              stockInfo: 'NO_STOCK',
              copywriting: 'NOT_STARTED',
              images: 'NOT_STARTED',
              submission: 'NOT_STARTED',
            },
          };
        }
        return {
          skuInfo: {
            sku,
            onboarding,
            ...skuInfo,
            creationDate: skuInfo.creationDate
              ? moment(skuInfo.creationDate.toDate()).format('MM/DD/YYYY')
              : null,
            lastTakealotPull: null,
          },
          status: 200,
        };
      })
      .catch((error) => {
        return {
          sku,
          error,
          status: 500,
        };
      });
  }

  const skuRef = firestore.collection('skus').doc(sku);
  return skuRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        const skuInfo = doc.data();
        if (skuInfo.clientId === undefined) {
          return {
            sku,
            status: 200,
          };
        }
        let onboarding =
          platform === 'TAKEALOT'
            ? skuInfo.takealot.onboarding
            : skuInfo.amazon.onboarding;

        if (!onboarding) {
          onboarding = {
            photosRequired: null,
            clientImagesLink: '',
            clientCopyLink: '',
            specialRequests: '',
            statuses: {
              stockInfo: 'NO_STOCK',
              copywriting: 'NOT_STARTED',
              images: 'NOT_STARTED',
              submission: 'NOT_STARTED',
            },
          };
        }
        return {
          skuInfo: {
            sku,
            ...skuInfo,
            onboarding,
            creationDate: skuInfo.creationDate
              ? moment(skuInfo.creationDate.toDate()).format('MM/DD/YYYY')
              : null,
            lastTakealotPull: null,
          },
          status: 200,
        };
      }
      return {
        sku,
        status: 500,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const getSkuByTsin = async (tsin) => {
  const skuRef = firestore
    .collection('skus')
    .where('takealot.tsin', '==', tsin);

  return skuRef
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.length === 0) {
        return {
          tsin,
          status: 500,
        };
      }
      let skuInfo = {};
      querySnapshot.forEach((doc) => {
        skuInfo = doc.data();
      });
      if (skuInfo.clientId === undefined) {
        return {
          tsin,
          status: 500,
        };
      }
      let { onboarding } = skuInfo.takealot;

      if (!onboarding) {
        onboarding = {
          photosRequired: null,
          clientImagesLink: '',
          clientCopyLink: '',
          specialRequests: '',
          statuses: {
            stockInfo: 'NO_STOCK',
            copywriting: 'NOT_STARTED',
            images: 'NOT_STARTED',
            submission: 'NOT_STARTED',
          },
        };
      }
      return {
        skuInfo: {
          tsin,
          ...skuInfo,
          onboarding,
          creationDate: skuInfo.creationDate
            ? moment(skuInfo.creationDate.toDate()).format('MM/DD/YYYY')
            : null,
          lastTakealotPull: null,
        },
        status: 200,
      };
    })
    .catch((error) => {
      return {
        tsin,
        error,
        status: 500,
      };
    });
};

export const getSkuByChildAsin = async (childAsin) => {
  const skuRef = firestore
    .collection('skus')
    .where('amazon.childAsin', '==', childAsin);

  return skuRef
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.length === 0) {
        return {
          childAsin,
          status: 500,
        };
      }
      let skuInfo = {};
      querySnapshot.forEach((doc) => {
        skuInfo = doc.data();
      });
      if (skuInfo.clientId === undefined) {
        return {
          childAsin,
          status: 500,
        };
      }
      let { onboarding } = skuInfo.amazon;

      if (!onboarding) {
        onboarding = {
          photosRequired: null,
          clientImagesLink: '',
          clientCopyLink: '',
          specialRequests: '',
          statuses: {
            stockInfo: 'NO_STOCK',
            copywriting: 'NOT_STARTED',
            images: 'NOT_STARTED',
            submission: 'NOT_STARTED',
          },
        };
      }
      return {
        skuInfo: {
          childAsin,
          ...skuInfo,
          onboarding,
          creationDate: skuInfo.creationDate
            ? moment(skuInfo.creationDate.toDate()).format('MM/DD/YYYY')
            : null,
          lastTakealotPull: null,
        },
        status: 200,
      };
    })
    .catch((error) => {
      return {
        childAsin,
        error,
        status: 500,
      };
    });
};

export const getSkus = async (platform = 'NOT_SPECIFIED', clientId = '') => {
  let skusRef = firestore.collection('skus');

  if (platform === 'AMAZON') {
    skusRef = skusRef.where('amazon.parentAsin', '!=', '');
  }
  if (platform === 'TAKEALOT') {
    skusRef = skusRef.where('takealot.sku', '!=', '');
  }
  if (clientId !== '') {
    skusRef = skusRef.where('clientId', '==', clientId);
  }

  return skusRef
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.length === 0) {
        return {
          status: 500,
        };
      }
      const skus = [];
      querySnapshot.forEach((doc) => {
        const skuInfo = doc.data();
        let onboarding =
          platform === 'TAKEALOT'
            ? skuInfo.takealot.onboarding
            : skuInfo.amazon.onboarding;

        if (!onboarding) {
          onboarding = {
            photosRequired: null,
            clientImagesLink: '',
            clientCopyLink: '',
            specialRequests: '',
            statuses: {
              stockInfo: 'NO_STOCK',
              copywriting: 'NOT_STARTED',
              images: 'NOT_STARTED',
              submission: 'NOT_STARTED',
            },
          };
        }

        skus.push({
          ...skuInfo,
          onboarding,
          sku: doc.id,
          lastTakealotPull: null,
          creationDate: skuInfo.creationDate
            ? moment(skuInfo.creationDate.toDate()).format('MM/DD/YYYY')
            : null,
        });
      });
      return {
        skus,
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const getOnboardingSkus = async (platform = 'TAKEALOT') => {
  let skusRef = firestore.collection('skus');
  if (platform === 'AMAZON') {
    skusRef = skusRef.where('amazon.status', '==', 'ONBOARDING');
  }
  if (platform === 'TAKEALOT') {
    skusRef = skusRef.where('takealot.status', '==', 'ONBOARDING');
  }

  return skusRef
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.length === 0) {
        return {
          status: 500,
        };
      }
      const skus = [];
      querySnapshot.forEach((doc) => {
        const skuInfo = doc.data();
        let onboarding =
          platform === 'TAKEALOT'
            ? skuInfo.takealot.onboarding
            : skuInfo.amazon.onboarding;

        if (!onboarding) {
          onboarding = {
            photosRequired: null,
            clientImagesLink: '',
            clientCopyLink: '',
            specialRequests: '',
            statuses: {
              stockInfo: 'NO_STOCK',
              copywriting: 'NOT_STARTED',
              images: 'NOT_STARTED',
              submission: 'NOT_STARTED',
            },
          };
        }

        skus.push({
          ...skuInfo,
          onboarding,
          sku: doc.id,
          lastTakealotPull: null,
          creationDate: skuInfo.creationDate
            ? moment(skuInfo.creationDate.toDate()).format('MM/DD/YYYY')
            : null,
        });
      });
      return {
        skus,
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const getStockChanges = async (sku) => {
  const stockChangesRef = firestore
    .collection('skus')
    .doc(sku)
    .collection('stockChanges')
    .orderBy('date', 'desc');

  return stockChangesRef
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.length === 0) {
        return {
          status: 500,
        };
      }
      const changes = [];
      querySnapshot.forEach((doc) => {
        const changeDetails = doc.data();
        changes.push({
          ...changeDetails,
          date: changeDetails.date.toDate().toString(),
        });
      });
      return {
        changes,
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const getPriceChanges = async (sku, platform) => {
  const priceChangesRef = firestore
    .collection('skus')
    .doc(sku)
    .collection('priceChanges')
    .where('platform', '==', platform)
    .orderBy('date', 'desc');

  return priceChangesRef
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.length === 0) {
        return {
          status: 500,
        };
      }
      const changes = [];
      querySnapshot.forEach((doc) => {
        const changeDetails = doc.data();
        changes.push({
          ...changeDetails,
          date: changeDetails.date.toDate().toString(),
        });
      });
      return {
        changes,
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const getImages = async (sku) => {
  const imagesRef = firestore.collection('skus').doc(sku).collection('images');
  const storageRef = storage.ref();

  return imagesRef
    .get()
    .then(async (querySnapshot) => {
      if (querySnapshot.length === 0) {
        return {
          status: 500,
        };
      }

      const imageRefs = [];
      const images = [];

      querySnapshot.forEach(async (doc) => {
        const imageInfo = doc.data();
        const imageRef = storageRef.child(imageInfo.reference);

        imageRefs.push(imageRef);
        images.push({ ...imageInfo, id: doc.id });
      });

      await Promise.all(
        imageRefs.map((imageRef, index) =>
          imageRef.getDownloadURL().then((url) => {
            images[index] = {
              ...images[index],
              url,
            };
          })
        )
      );

      return {
        images,
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const uploadImage = async (
  file,
  tag,
  sku,
  updateUploadBytes,
  uploadSucessful,
  uploadFailed
) => {
  const storageRef = storage.ref();
  const listingImageRef = firestore
    .collection('skus')
    .doc(sku)
    .collection('images')
    .doc();
  const fileExtension = file.name.split('.').pop();
  const newFileRef = storageRef.child(
    `skus/${sku}/${listingImageRef.id}.${fileExtension}`
  );

  const uploadTask = newFileRef.put(file);

  uploadTask.on(
    'state_changed',
    (snapshot) => {
      updateUploadBytes(
        listingImageRef.id,
        snapshot.totalBytes,
        snapshot.bytesTransferred
      );
    },
    () => {
      uploadFailed();
    },
    async () => {
      return listingImageRef
        .set({
          tag,
          reference: newFileRef.fullPath,
          fileSize: file.size,
        })
        .then(() => {
          uploadSucessful();
        })
        .catch(() => {
          uploadFailed();
        });
    }
  );
};

export const deleteImage = async (sku, imagePath, imageId) => {
  const storageRef = storage.ref();
  const listingImageRef = firestore
    .collection('skus')
    .doc(sku)
    .collection('images')
    .doc(imageId);
  const imageRef = storageRef.child(imagePath);

  await imageRef.delete();

  return listingImageRef
    .delete()
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        error,
        status: 500,
      };
    });
};

export const updateImageListingOrder = async (sku, listingOrders, images) => {
  const batch = firestore.batch();

  _.toPairs(listingOrders).forEach(([imageId, listingOrder]) => {
    const imageRef = firestore
      .collection('skus')
      .doc(sku)
      .collection('images')
      .doc(imageId);
    batch.update(imageRef, {
      listingOrder,
    });
    if (listingOrder === 1) {
      const skuRef = firestore.collection('skus').doc(sku);
      const relevantImage = images.find(
        (imageInfo) => imageInfo.id === imageId
      );
      batch.update(skuRef, {
        primaryImageUrl: relevantImage.url,
      });
    }
  });

  return batch
    .commit()
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const updateAmazonPrice = async (
  sku,
  oldWholesalePrice,
  oldRetailPrice,
  newWholesalePrice,
  newRetailPrice,
  userInfo
) => {
  const priceChangeRef = firestore
    .collection('skus')
    .doc(sku)
    .collection('priceChanges')
    .doc();

  return priceChangeRef
    .set({
      oldWholesalePrice,
      oldRetailPrice,
      newWholesalePrice,
      newRetailPrice,
      platform: 'AMAZON',
      userName: `${userInfo.name} ${userInfo.surname}`,
      userId: userInfo.uid,
      date: new Date(),
    })
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const updateTakealotPrice = async (
  sku,
  oldWholesalePrice,
  oldRetailPrice,
  newWholesalePrice,
  newRetailPrice,
  dateType,
  dateTime,
  userInfo
) => {
  if (dateType === 'NOW') {
    const pushTakealotPriceNow = functions.httpsCallable(
      'pushTakealotPriceNow'
    );
    const priceChangeRef = firestore
      .collection('skus')
      .doc(sku)
      .collection('priceChanges')
      .doc();

    await pushTakealotPriceNow({
      sku,
      sellingPrice: newRetailPrice,
      rrp: newRetailPrice,
    });

    return priceChangeRef
      .set({
        oldWholesalePrice,
        oldRetailPrice,
        newWholesalePrice,
        newRetailPrice,
        platform: 'TAKEALOT',
        userName: `${userInfo.name} ${userInfo.surname}`,
        userId: userInfo.uid,
        date: new Date(dateTime),
      })
      .then(() => {
        return {
          status: 200,
        };
      })
      .catch((error) => {
        return {
          error,
          status: 500,
        };
      });
  }

  const batch = firestore.batch();

  const priceChangeRef = firestore
    .collection('skus')
    .doc(sku)
    .collection('priceChanges')
    .doc();
  const scheduledPriceChangeRef = firestore
    .collection('scheduledPriceChanges')
    .doc(priceChangeRef.id);
  batch.set(priceChangeRef, {
    oldWholesalePrice,
    oldRetailPrice,
    newWholesalePrice,
    newRetailPrice,
    platform: 'TAKEALOT',
    userName: `${userInfo.name} ${userInfo.surname}`,
    userId: userInfo.uid,
    date: new Date(dateTime),
  });
  batch.set(scheduledPriceChangeRef, {
    sku,
    sellingPrice: newRetailPrice,
    rrp: newRetailPrice,
    platform: 'TAKEALOT',
    userName: `${userInfo.name} ${userInfo.surname}`,
    userId: userInfo.uid,
    date: new Date(dateTime),
  });

  return batch
    .commit()
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const getCopyTemplate = async (department, category, subcategory) => {
  const copyRef = firestore
    .collection('copy')
    .where('categories.department', '==', department)
    .where('categories.category', '==', category)
    .where('categories.subCategory', '==', subcategory)
    .limit(1);

  return copyRef
    .get()
    .then((snapshot) => {
      if (snapshot.length === 0) {
        return {
          message: 'Template not found',
          status: 404,
        };
      }
      const copyInfo = snapshot.docs[0];
      return {
        templateData: {
          subtitle: copyInfo.get('copy.subtitle'),
          description: copyInfo.get('copy.description'),
        },
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const createNewCopy = async (info) => {
  const newCopyRef = firestore.collection('copy').doc();

  return newCopyRef
    .set(info)
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const checkIfBarcodeTaken = async (barcode, platform = 'TAKEALOT') => {
  let skusRef = firestore.collection('skus');

  if (platform === 'TAKEALOT') {
    skusRef = skusRef.where('takealot.barcode', '==', barcode);
  }
  if (platform === 'AMAZON') {
    skusRef = skusRef.where('amazon.barcode', '==', barcode);
  }

  return skusRef
    .get()
    .then((querySnapshot) => {
      return {
        taken: !querySnapshot.empty,
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const checkIfSkuTaken = async (sku, platform = 'GENERAL') => {
  let skusRef = firestore.collection('skus');

  if (platform === 'GENERAL') {
    skusRef = skusRef.where('sku', '==', sku);
  }
  if (platform === 'TAKEALOT') {
    skusRef = skusRef.where('takealot.sku', '==', sku);
  }
  if (platform === 'AMAZON') {
    skusRef = skusRef.where('amazon.sku', '==', sku);
  }

  return skusRef
    .get()
    .then((querySnapshot) => {
      return {
        taken: !querySnapshot.empty,
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const getCopies = async (sku) => {
  const copyRef = firestore.collection('copy').where('sku', '==', sku);

  return copyRef
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.length === 0) {
        return {
          status: 500,
        };
      }
      const copies = [];
      querySnapshot.forEach(async (doc) => {
        const copyInfo = doc.data();

        copies.push({
          ...copyInfo,
          id: doc.id,
          creationDate: copyInfo.creationDate.toDate().toString(),
        });
      });
      return {
        copies,
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const updateCopyStatus = async (
  copyId,
  copyStatus,
  rejectReason = ''
) => {
  const copyRef = firestore.collection('copy').doc(copyId);
  return copyRef
    .update({ status: copyStatus, rejectionReason: rejectReason })
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const getAnalyticsData = async (
  platform = 'TAKEALOT',
  startDate,
  endDate
) => {
  let reportsRef = firestore
    .collection('reports')
    .where('reportDate', '>=', startDate)
    .where('reportDate', '<=', endDate);
  if (platform === 'AMAZON') {
    reportsRef = reportsRef.where(
      'reportType',
      '==',
      'amazon-detail-page-sales-and-traffic-by-child-item'
    );
  }
  if (platform === 'TAKEALOT') {
    reportsRef = reportsRef.where('reportType', '==', 'takealot-product-sales');
  }

  return reportsRef
    .get()
    .then((querySnapshot) => {
      const reportData = _.times(moment(endDate).diff(startDate, 'days')).map(
        () => []
      );

      querySnapshot.forEach((doc) => {
        const reportInfo = doc.data();
        const reportIndex = moment(reportInfo.reportDate.toDate()).diff(
          startDate,
          'days'
        );

        reportData[reportIndex] = reportInfo.data.map((data) => {
          if (platform === 'TAKEALOT') {
            return {
              tsin: data.tsin,
              revenue: data.totalSalesRand,
              unitsSold: data.totalUnitsSold,
              pageViews: data.pageViews,
            };
          }

          return {
            childAsin: data.childAsin,
            revenue: data.orderedProductSales,
            unitsSold: data.unitsOrdered,
            pageViews: data.sessions,
          };
        });
      });

      return {
        reportData,
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};
