/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  getPresalesSuppliers,
  requestOrdersReport,
} from '../../../api/presalesAPI';

const initialState = {
  suppliers: [],
  orderReports: [],
  loadingStatus: {
    suppliers: 'NOT_STARTED',
    requestReport: 'NOT_STARTED',
    orderReports: 'NOT_STARTED',
  },
};

const ordersBrowseSlice = createSlice({
  name: 'ordersBrowse',
  initialState,
  reducers: {
    fetchPresalesSuppliersStarted(state) {
      state.loadingStatus.suppliers = 'PENDING';
    },
    fetchPresalesSuppliersSuccess(state, action) {
      state.suppliers = action.payload;
      state.loadingStatus.suppliers = 'COMPLETE';
    },
    fetchPresalesSuppliersFailed(state) {
      state.loadingStatus.suppliers = 'COMPLETE';
    },
    requestOrdersReportStarted(state) {
      state.loadingStatus.requestReport = 'PENDING';
    },
    requestOrdersReportSuccess(state) {
      state.loadingStatus.requestReport = 'COMPLETE';
    },
    requestOrdersReportFailed(state) {
      state.loadingStatus.requestReport = 'COMPLETE';
    },
  },
});

export const {
  fetchPresalesSuppliersStarted,
  fetchPresalesSuppliersSuccess,
  fetchPresalesSuppliersFailed,
  requestOrdersReportStarted,
  requestOrdersReportSuccess,
  requestOrdersReportFailed,
} = ordersBrowseSlice.actions;

export const fetchPresalesSuppliers = () => async (dispatch) => {
  dispatch(fetchPresalesSuppliersStarted());
  const result = await getPresalesSuppliers();

  if (result.status === 200) {
    dispatch(fetchPresalesSuppliersSuccess(result.suppliers));
  } else {
    dispatch(fetchPresalesSuppliersFailed(result.error));
  }
};

export const requestReport =
  (
    startDate,
    endDate,
    requestedById,
    requestedByName,
    supplierId,
    supplierName,
    statusId,
    status,
    reportType,
    dcFilter
  ) =>
  async (dispatch) => {
    dispatch(requestOrdersReportStarted());
    const result = await requestOrdersReport(
      startDate,
      endDate,
      requestedById,
      requestedByName,
      supplierId,
      supplierName,
      statusId,
      status,
      reportType,
      dcFilter
    );

    if (result.status === 200) {
      dispatch(requestOrdersReportSuccess());
    } else {
      dispatch(requestOrdersReportFailed(result.error));
    }
  };

export default ordersBrowseSlice.reducer;
