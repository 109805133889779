/* eslint-disable react/jsx-props-no-spreading */
import _ from 'lodash';
import { green, orange, red, yellow } from '@material-ui/core/colors';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { makeStyles } from '@material-ui/core/styles';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const headerStyle = {
  backgroundColor: '#171415',
};

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.common.black,
  },
  link: {
    color: theme.palette.common.black,
  },
  loadingOverlay: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: fade(theme.palette.common.black, 0.8),
  },
  statusAmber: {
    width: '100%',
    color: theme.palette.common.black,
    backgroundColor: orange[700],
  },
  statusGreen: {
    width: '100%',
    color: theme.palette.common.black,
    backgroundColor: green[700],
  },
  statusDarkRed: {
    width: '100%',
    color: theme.palette.common.black,
    backgroundColor: red[800],
  },
  statusRed: {
    width: '100%',
    color: theme.palette.common.black,
    backgroundColor: red[600],
  },
  statusYellow: {
    width: '100%',
    color: theme.palette.common.black,
    backgroundColor: yellow[700],
  },
  wrapperContent: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

const SkusTable = ({
  isLoading,
  skus,
  goToListing,
  updateStatuses,
  adjustBriefing,
  pageSize,
  initialPage,
  updatePageSize,
  updateInitialPage,
}) => {
  const classes = useStyles();

  const getPrettyStatus = (status) => {
    switch (status) {
      case 'COMPLETED':
        return 'Completed';
      case 'MEASURING':
        return 'Measuring';
      case 'TAKING_STOCK_PHOTOS':
        return 'Taking Stock Photos';
      case 'DRAFTING':
        return 'Drafting';
      case 'REVIEWING':
        return 'Reviewing';
      case 'AWAITING_CLIENT_IMAGES':
        return 'Awaiting Client Images';
      case 'SHOOTING':
        return 'Shooting';
      case 'EDITING':
        return 'Editing';
      case 'IN_REVIEW':
        return 'In Review';
      case 'NO_STOCK':
        return 'No Stock';
      case 'NOT_STARTED':
        return 'Not Started';
      case 'REJECTED':
        return 'Rejected';
      case 'APPROVED':
        return 'Approved';
      default:
        return status;
    }
  };

  const getStatusChip = (status) => {
    switch (status) {
      case 'COMPLETED':
      case 'APPROVED':
        return (
          <Chip
            icon={<DoneIcon className={classes.icon} />}
            label={getPrettyStatus(status)}
            className={classes.statusGreen}
          />
        );
      case 'AWAITING_CLIENT_IMAGES':
        return (
          <Chip
            icon={<AutorenewIcon className={classes.icon} />}
            label={getPrettyStatus(status)}
            className={classes.statusDarkRed}
          />
        );
      case 'MEASURING':
      case 'DRAFTING':
      case 'SHOOTING':
        return (
          <Chip
            icon={<AutorenewIcon className={classes.icon} />}
            label={getPrettyStatus(status)}
            className={classes.statusAmber}
          />
        );
      case 'TAKING_STOCK_PHOTOS':
      case 'REVIEWING':
      case 'EDITING':
      case 'IN_REVIEW':
        return (
          <Chip
            icon={<AutorenewIcon className={classes.icon} />}
            label={getPrettyStatus(status)}
            className={classes.statusYellow}
          />
        );
      case 'NO_STOCK':
      case 'NOT_STARTED':
      case 'REJECTED':
        return (
          <Chip
            icon={<ClearIcon className={classes.icon} />}
            label={getPrettyStatus(status)}
            className={classes.statusRed}
          />
        );
      default:
        return (
          <Chip
            icon={<DoneIcon className={classes.icon} />}
            label={getPrettyStatus(status)}
            className={classes.statusGreen}
          />
        );
    }
  };

  const getColumns = () => {
    const columns = [
      { title: 'SKU', field: 'sku', editable: false },
      { title: 'Client', field: 'clientName', hidden: true, export: true },
      {
        title: 'Supplier',
        field: 'supplierName',
        export: true,
        editable: false,
        render: (rowData) =>
          rowData.supplierName ? rowData.supplierName : rowData.clientName,
      },
      { title: 'Brand', field: 'brand', hidden: true, export: true },
      {
        title: 'Product Name',
        field: 'productName',
        hidden: true,
        export: true,
      },
      {
        title: 'Variation',
        field: 'variation',
        hidden: true,
        export: true,
      },
      {
        title: 'Product',
        editable: false,
        render: (rowData) => {
          const productTitle =
            rowData.variation === 'N/A'
              ? `${rowData.brand} - ${rowData.productName}`
              : `${rowData.brand} - ${rowData.productName} (${rowData.variation})`;
          return (
            <Tooltip title={productTitle} aria-label="add" interactive>
              <Typography variant="body2" component="span">
                {_.truncate(productTitle)}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        title: 'Stock Info',
        field: 'stockInfoStatus',
        render: (rowData) => getStatusChip(rowData.stockInfoStatus),
        lookup: {
          NO_STOCK: 'No Stock',
          MEASURING: 'Measuring',
          TAKING_STOCK_PHOTOS: 'Taking Stock Photos',
          COMPLETED: 'Completed',
        },
      },
      {
        title: 'Copywriting',
        field: 'copywritingStatus',
        render: (rowData) => getStatusChip(rowData.copywritingStatus),
        lookup: {
          NOT_STARTED: 'Not Started',
          DRAFTING: 'Drafting',
          REVIEWING: 'Reviewing',
          COMPLETED: 'Completed',
        },
      },
      {
        title: 'Images',
        field: 'imagesStatus',
        render: (rowData) => getStatusChip(rowData.imagesStatus),
        lookup: {
          NOT_STARTED: 'Not Started',
          AWAITING_CLIENT_IMAGES: 'Awaiting Client Images',
          SHOOTING: 'Shooting',
          EDITING: 'Editing',
          COMPLETED: 'Completed',
        },
      },
      {
        title: 'Submission',
        field: 'submissionStatus',
        render: (rowData) => getStatusChip(rowData.submissionStatus),
        lookup: {
          NOT_STARTED: 'Not Started',
          IN_REVIEW: 'In Review',
          REJECTED: 'Rejected',
        },
      },
      {
        title: 'Creation Date',
        field: 'creationDate',
        hidden: true,
        export: true,
      },
    ];

    return columns;
  };

  const flattenStatuses = () => {
    return skus.map((info) => ({
      ...info,
      stockInfoStatus: info.onboarding.statuses.stockInfo,
      copywritingStatus: info.onboarding.statuses.copywriting,
      imagesStatus: info.onboarding.statuses.images,
      submissionStatus: info.onboarding.statuses.submission,
    }));
  };

  return (
    <div className={classes.wrapperContent}>
      <MaterialTable
        title=""
        columns={getColumns()}
        data={flattenStatuses()}
        isLoading={isLoading}
        onChangeRowsPerPage={updatePageSize}
        onChangePage={updateInitialPage}
        options={{
          pageSize,
          initialPage,
          headerStyle,
          actionsColumnIndex: -1,
          search: false,
          grouping: false,
          draggable: false,
          exportButton: true,
          exportAllData: true,
          exportFileName: 'Onboarding SKUs',
        }}
        editable={{
          onRowUpdate: (newData) =>
            new Promise((resolve) => {
              updateStatuses(
                {
                  stockInfo: newData.stockInfoStatus,
                  copywriting: newData.copywritingStatus,
                  images: newData.imagesStatus,
                  submission: newData.submissionStatus,
                },
                newData.sku
              );
              resolve();
            }),
        }}
        actions={[
          {
            icon: 'assignment',
            tooltip: 'View briefing',
            onClick: (event, rowData) => adjustBriefing(rowData),
          },
          {
            icon: 'keyboard_arrow_right',
            tooltip: 'View SKU info',
            onClick: (event, rowData) => goToListing(rowData.sku),
          },
        ]}
        components={{
          OverlayLoading: () => (
            <div className={classes.loadingOverlay}>
              <CircularProgress color="primary" size={32} />
            </div>
          ),
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'No listings to display',
          },
        }}
      />
    </div>
  );
};

SkusTable.defaultProps = {
  isLoading: false,
  skus: [],
  goToListing: (sku) => console.log(`SKU viewed: ${sku}`),
  updateStatuses: (statuses, sku) =>
    console.log(`SKU edited: ${sku}`, statuses),
  adjustBriefing: (skuInfo) => console.log('Briefing adjusted:', skuInfo),
  pageSize: 5,
  initialPage: 0,
  updatePageSize: (newPageSize) =>
    console.log(`Page size updated to: ${newPageSize}`),
  updateInitialPage: (newInitialPage) =>
    console.log(`Initial page updated to: ${newInitialPage}`),
};

SkusTable.propTypes = {
  isLoading: PropTypes.bool,
  skus: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      brand: PropTypes.string,
      clientId: PropTypes.string,
      clientName: PropTypes.string,
      productName: PropTypes.string,
      variation: PropTypes.string,
      stockImagesLink: PropTypes.string,
      status: PropTypes.oneOf(['ACTIVE', 'INACTIVE']),
    })
  ),
  goToListing: PropTypes.func,
  updateStatuses: PropTypes.func,
  adjustBriefing: PropTypes.func,
  pageSize: PropTypes.number,
  initialPage: PropTypes.number,
  updatePageSize: PropTypes.func,
  updateInitialPage: PropTypes.func,
};

export default SkusTable;
