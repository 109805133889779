import { green, red, yellow } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import Button from '@material-ui/core/Button';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import Chip from '@material-ui/core/Chip';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import PauseIcon from '@material-ui/icons/Pause';
import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import {
  applyAdjustments,
  deleteSupplierDocument,
  fetchDocuments,
  fetchShipmentInfo,
} from './slice';
import {
  getShipmentPlatform,
  getTotalInsuranceValue,
  getFilteredSkus,
} from './logic';
import AdjustmentsTab from './components/AdjustmentsTab';
import BasicInfoCard from './components/BasicInfoCard';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import ContentWrapper from '../../../components/ContentWrapper';
import DiscrepanciesTab from './components/DiscrepanciesTab';
import DocumentsTab from '../../../components/DocumentsTab';
import EmptyViewMessage from '../../../components/EmptyViewMessage';
import PageHeader from '../../../components/PageHeader';
import RequestAdjustmentsDialog from './components/RequestAdjustmentsDialog';
import UnitsTab from './components/UnitsTab';

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
  icon: {
    color: theme.palette.common.black,
  },
  link: {
    color: theme.palette.common.black,
  },
  loadingWrapper: {
    padding: theme.spacing(4),
    textAlign: 'center',
  },
  pdfButtonInner: {
    display: 'flex',
    alignItems: 'center',
  },
  pdfButtonOuter: {
    color: theme.palette.common.white,
    textDecoration: 'none',
  },
  seperatorButtons: {
    height: theme.spacing(1),
  },
  seperator: {
    height: theme.spacing(3),
  },
  statusCompleted: {
    width: '100%',
    color: theme.palette.common.black,
    backgroundColor: green[500],
  },
  statusDelayed: {
    width: '100%',
    color: theme.palette.common.black,
    backgroundColor: yellow[500],
  },
  statusCancelled: {
    width: '100%',
    color: theme.palette.common.black,
    backgroundColor: red[500],
  },
  statusCollectionArranged: {
    width: '100%',
    color: theme.palette.common.black,
    backgroundColor: yellow[500],
  },
  statusInbound: {
    width: '100%',
    color: theme.palette.common.black,
    backgroundColor: yellow[500],
  },
  statusPreparing: {
    width: '100%',
    color: theme.palette.common.black,
    backgroundColor: yellow[500],
  },
  statusRequested: {
    width: '100%',
    color: theme.palette.common.black,
    backgroundColor: yellow[500],
  },
  statusWaybillCreated: {
    width: '100%',
    color: theme.palette.common.black,
    backgroundColor: yellow[500],
  },
  statusWrapper: {
    width: '100%',
    textAlign: 'center',
    padding: theme.spacing(4),
  },
  tradeOutButton: {
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
  totalsWrapper: {
    padding: theme.spacing(4),
  },
}));

const breadcrumbs = [
  {
    label: 'Logistics',
    link: '/logistics',
  },
  {
    label: 'Shipments',
    link: '/logistics/shipments',
  },
  {
    label: 'Browse',
    link: '/logistics/shipments/browse',
  },
];

const ShipmentsView = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { shipmentId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('UNITS');
  const [isDeleteDocumentDialogOpen, setIsDeleteDocumentDialogOpen] =
    useState(false);
  const [isRequestAdjustmentsDialogOpen, setIsRequestAdjustmentsDialogOpen] =
    useState(false);
  const [documentToBeDeleted, setDocumentToBeDeleted] = useState({
    documentPath: '',
    documentId: '',
  });
  const [skuSearch, setSKUSearch] = useState();
  const [discrepanciesSearch, setDiscrepanciesSearch] = useState('');
  const [filteredSkus, setFilteredSkus] = useState([]);
  const [filteredDiscrepancies, setFilteredDiscrepancies] = useState([]);
  const [adjustmentSearch, setAdjustmentSearch] = useState('');
  const [filteredAdjustments, setFilteredAdjustments] = useState([]);
  const shipmentInfo = useSelector((state) => state.shipmentsView.shipmentInfo);
  const documents = useSelector((state) => state.shipmentsView.documents);
  const shipmentInfoLoadingStatus = useSelector(
    (state) => state.shipmentsView.loadingStatus.shipmentInfo
  );
  const saveAdjustmentsLoadingStatus = useSelector(
    (state) => state.shipmentsView.loadingStatus.saveAdjustments
  );
  const documentLoadingStatus = useSelector(
    (state) => state.shipmentsView.loadingStatus.documents
  );
  const deleteDocumentLoadingStatus = useSelector(
    (state) => state.shipmentsView.loadingStatus.deleteDocument
  );

  useEffect(() => {
    dispatch(fetchShipmentInfo(shipmentId));
    dispatch(fetchDocuments(shipmentId));
  }, []);

  useEffect(() => {
    if (
      shipmentInfoLoadingStatus === 'PENDING' ||
      documentLoadingStatus === 'PENDING' ||
      deleteDocumentLoadingStatus === 'PENDING' ||
      saveAdjustmentsLoadingStatus === 'PENDING'
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [
    shipmentInfoLoadingStatus,
    documentLoadingStatus,
    deleteDocumentLoadingStatus,
    saveAdjustmentsLoadingStatus,
  ]);

  useEffect(() => {
    setFilteredSkus(getFilteredSkus(shipmentInfo.skus, skuSearch));
  }, [skuSearch, shipmentInfo.skus]);

  useEffect(() => {
    setFilteredDiscrepancies(
      getFilteredSkus(shipmentInfo.discrepancies, discrepanciesSearch)
    );
  }, [discrepanciesSearch, shipmentInfo.discrepancies]);

  useEffect(() => {
    setFilteredAdjustments(
      getFilteredSkus(shipmentInfo.adjustments, adjustmentSearch)
    );
  }, [adjustmentSearch, shipmentInfo.adjustments]);

  const getStatusChip = (status) => {
    switch (status) {
      case 'COMPLETE':
        return (
          <Chip
            icon={<DoneIcon className={classes.icon} />}
            label="Complete"
            className={classes.statusCompleted}
          />
        );
      case 'REQUESTED':
        return (
          <Chip
            icon={<PhoneCallbackIcon className={classes.icon} />}
            label="Requested"
            className={classes.statusRequested}
          />
        );
      case 'PREPARING':
        return (
          <Chip
            icon={<AutorenewIcon className={classes.icon} />}
            label="Preparing"
            className={classes.statusPreparing}
          />
        );
      case 'COLLECTION_ARRANGED':
        return (
          <Chip
            icon={<ThumbUpIcon className={classes.icon} />}
            label="Collection Arranged"
            className={classes.statusCollectionArranged}
          />
        );
      case 'WAYBILL_CREATED':
        return (
          <Chip
            icon={<AssignmentTurnedInIcon className={classes.icon} />}
            label="Waybill Created"
            className={classes.statusWaybillCreated}
          />
        );
      case 'DELAYED':
        return (
          <Chip
            icon={<PauseIcon className={classes.icon} />}
            label="Delayed"
            className={classes.statusDelayed}
          />
        );
      case 'CANCELLED':
        return (
          <Chip
            icon={<ClearIcon className={classes.icon} />}
            label="Cancelled"
            className={classes.statusCancelled}
          />
        );
      case 'INBOUND':
        return (
          <Chip
            icon={<CallReceivedIcon className={classes.icon} />}
            label="Inbound"
            className={classes.statusInbound}
          />
        );
      default:
        return (
          <Chip
            icon={<DoneIcon className={classes.icon} />}
            label={status}
            className={classes.statusCompleted}
          />
        );
    }
  };

  return (
    <div>
      <PageHeader
        title="Shipment Details"
        subtitle={isLoading ? 'Loading...' : shipmentInfo.id}
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Shipment"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
      />
      {isLoading && <LinearProgress color="secondary" />}
      <ContentWrapper>
        {!isLoading && shipmentInfo.sku === 'Loading' ? (
          <EmptyViewMessage
            heading="Info Failed to Load"
            message="Please check your internet connection and reload the page."
          />
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              {!isLoading && (
                <div className={classes.statusWrapper}>
                  {getStatusChip(shipmentInfo.status)}
                </div>
              )}
              <div>
                {shipmentInfo.status !== 'CANCELLED' &&
                  shipmentInfo.status !== 'COMPLETE' && (
                    <>
                      <Button
                        fullWidth
                        variant="outlined"
                        color="secondary"
                        startIcon={<EditIcon />}
                        onClick={() =>
                          history.push(
                            `/logistics/shipments/edit/${shipmentId}`
                          )
                        }
                      >
                        Edit details
                      </Button>
                      <div className={classes.seperatorButtons} />
                    </>
                  )}
                {(shipmentInfo.status === 'PREPARING' ||
                  shipmentInfo.status === 'REQUESTED') && (
                  <>
                    <Button
                      fullWidth
                      variant="outlined"
                      color="secondary"
                      startIcon={<ChangeHistoryIcon />}
                      onClick={() => setIsRequestAdjustmentsDialogOpen(true)}
                    >
                      Request Adjustments
                    </Button>
                    <div className={classes.seperatorButtons} />
                  </>
                )}
                <Button
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  startIcon={<NoteAddIcon />}
                  onClick={() =>
                    history.push(
                      `/logistics/shipments/browse/upload-documents/${shipmentId}`
                    )
                  }
                >
                  Upload Documents
                </Button>
              </div>
              <div className={classes.seperator} />
              <BasicInfoCard
                status={shipmentInfo.status}
                creationDate={shipmentInfo.creationDate}
                fromWarehouse={
                  shipmentInfo.fromId === 'CLIENT'
                    ? shipmentInfo.clientName
                    : shipmentInfo.fromName
                }
                toWarehouse={
                  shipmentInfo.toId === 'CLIENT'
                    ? shipmentInfo.clientName
                    : shipmentInfo.toName
                }
                totalUnits={shipmentInfo.totalUnits}
                totalSkus={shipmentInfo.totalSkus}
                totalInsuranceValue={getTotalInsuranceValue(shipmentInfo.skus)}
                cost={shipmentInfo.cost}
                isLoading={shipmentInfoLoadingStatus === 'PENDING'}
                onMissingInfoClick={() =>
                  history.push(`/logistics/shipments/browse/edit/${shipmentId}`)
                }
              />
            </Grid>
            <Grid item xs={12} md={9}>
              {!isLoading && (
                <div>
                  <AppBar position="static">
                    <Tabs
                      value={activeTab}
                      onChange={(e, newValue) => setActiveTab(newValue)}
                      aria-label="shipments tabs"
                    >
                      <Tab label="Units" value="UNITS" />
                      <Tab label="Discrepancies" value="DISCREPANCIES" />
                      <Tab label="Adjustments" value="ADJUSTMENTS" />
                      <Tab label="Documents" value="DOCUMENTS" />
                    </Tabs>
                  </AppBar>
                  <div className={classes.seperator} />
                  {activeTab === 'UNITS' && (
                    <UnitsTab
                      platform={getShipmentPlatform(
                        shipmentInfo.fromId,
                        shipmentInfo.toId
                      )}
                      skus={filteredSkus}
                      setSKUSearch={setSKUSearch}
                      skuSearchText={skuSearch}
                    />
                  )}
                  {activeTab === 'DISCREPANCIES' && (
                    <DiscrepanciesTab
                      platform={getShipmentPlatform(
                        shipmentInfo.fromId,
                        shipmentInfo.toId
                      )}
                      discrepancies={filteredDiscrepancies || []}
                      shipmentStatus={shipmentInfo.status}
                      discrepanciesSearchText={discrepanciesSearch}
                      setDiscrepanciesSearch={setDiscrepanciesSearch}
                    />
                  )}
                  {activeTab === 'ADJUSTMENTS' && (
                    <AdjustmentsTab
                      platform={getShipmentPlatform(
                        shipmentInfo.fromId,
                        shipmentInfo.toId
                      )}
                      adjustments={filteredAdjustments || []}
                      adjustmentsSearch={adjustmentSearch}
                      setSearch={setAdjustmentSearch}
                    />
                  )}
                  {activeTab === 'DOCUMENTS' && (
                    <DocumentsTab
                      documents={documents}
                      deleteDocument={(documentInfo) => {
                        setIsDeleteDocumentDialogOpen(true);
                        setDocumentToBeDeleted({
                          documentPath: documentInfo.reference,
                          documentId: documentInfo.id,
                        });
                      }}
                    />
                  )}
                </div>
              )}
            </Grid>
          </Grid>
        )}
      </ContentWrapper>
      <RequestAdjustmentsDialog
        isOpen={isRequestAdjustmentsDialogOpen}
        shipmentSkus={shipmentInfo.skus}
        adjustments={shipmentInfo.adjustments || []}
        applyAdjustments={(adjustments, shipmentSkus) => {
          dispatch(applyAdjustments(shipmentId, adjustments, shipmentSkus));
          setIsRequestAdjustmentsDialogOpen(false);
        }}
        closeDialog={() => setIsRequestAdjustmentsDialogOpen(false)}
      />
      <ConfirmationDialog
        isOpen={isDeleteDocumentDialogOpen}
        message="Are you sure you want to delete this document?"
        closeDialog={() => {
          setIsDeleteDocumentDialogOpen(false);
          setDocumentToBeDeleted({
            documentPath: '',
            documentId: '',
          });
        }}
        confirmAction={() =>
          dispatch(
            deleteSupplierDocument(
              shipmentId,
              documentToBeDeleted.documentPath,
              documentToBeDeleted.documentId
            )
          )
        }
      />
    </div>
  );
};

export default ShipmentsView;
