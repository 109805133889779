/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getQuery, updateQueryInfo } from '../../../api/clientsAPI';
import { getSkus } from '../../../api/listingsAPI';
import { getUsers } from '../../../api/userAPI';

const initialState = {
  queryInfo: {},
  skus: [],
  users: [],
  loadingStatus: {
    queryInfo: 'NOT_STARTED',
    update: 'NOT_STARTED',
    skus: 'NOT_STARTED',
    users: 'NOT_STARTED',
  },
  feedback: {
    isOpen: false,
    type: undefined,
    message: '',
  },
};

const clientsQueryEditSlice = createSlice({
  name: 'clientsQueryEdit',
  initialState,
  reducers: {
    getQueryStarted(state) {
      state.loadingStatus.queryInfo = 'PENDING';
    },
    getQuerySuccess(state, action) {
      state.loadingStatus.queryInfo = 'COMPLETE';
      state.queryInfo = action.payload;
    },
    getQueryFailed(state, action) {
      state.loadingStatus.queryInfo = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getSkusStarted(state) {
      state.loadingStatus.skus = 'PENDING';
    },
    getSkusSuccess(state, action) {
      state.skus = action.payload;
      state.loadingStatus.skus = 'COMPLETE';
    },
    getSkusFailed(state, action) {
      state.loadingStatus.skus = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    updateQueryStarted(state) {
      state.loadingStatus.update = 'PENDING';
    },
    updateQuerySuccess(state) {
      state.loadingStatus.update = 'COMPLETE';
      state.isUserRedirectedToBrowse = true;
    },
    updateQueryFailed(state, action) {
      state.loadingStatus.update = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getUsersStarted(state) {
      state.loadingStatus.users = 'PENDING';
    },
    getUsersSuccess(state, action) {
      state.users = action.payload;
      state.loadingStatus.users = 'COMPLETE';
    },
    getUsersFailed(state, action) {
      state.loadingStatus.users = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    resetState() {
      return initialState;
    },
  },
});

export const {
  getQueryStarted,
  getQueryFailed,
  getQuerySuccess,
  resetState,
  getSkusSuccess,
  getSkusStarted,
  getSkusFailed,
  updateQuerySuccess,
  updateQueryFailed,
  updateQueryStarted,
  getUsersFailed,
  getUsersSuccess,
  getUsersStarted,
} = clientsQueryEditSlice.actions;

export default clientsQueryEditSlice.reducer;

export const fetchQueryInfo = (id) => async (dispatch) => {
  dispatch(getQueryStarted());
  const result = await getQuery(id);

  if (result.status === 200) {
    dispatch(getQuerySuccess(result.queryInfo));
  } else {
    dispatch(getQueryFailed(result.error));
  }
};

export const fetchClientSkus = (clientId) => async (dispatch) => {
  dispatch(getSkusStarted());
  const result = await getSkus('NOT_SPECIFIED', clientId);

  if (result.status === 200) {
    dispatch(getSkusSuccess(result.skus));
  } else {
    dispatch(getSkusFailed(result.error));
  }
};

export const updateQuery = (queryId, queryInfo) => async (dispatch) => {
  dispatch(updateQueryStarted());
  const result = await updateQueryInfo(queryId, queryInfo);

  if (result.status === 200) {
    dispatch(updateQuerySuccess());
  } else {
    dispatch(updateQueryFailed(result.error));
  }
};

export const fetchUsers = () => async (dispatch) => {
  dispatch(getUsersStarted());
  const result = await getUsers();

  if (result.status === 200) {
    dispatch(getUsersSuccess(result.users));
  } else {
    dispatch(getUsersFailed(result.error));
  }
};

export const initiateStateReset = () => async (dispatch) => {
  console.log('here');
  dispatch(resetState());
};
