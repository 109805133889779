/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getClients, addQuery } from '../../../api/clientsAPI';
import { getSkus } from '../../../api/listingsAPI';
import { getUsers } from '../../../api/userAPI';

const initialState = {
  clients: [],
  skus: [],
  users: [],
  loadingStatus: {
    clients: 'NOT_STARTED',
    skus: 'NOT_STARTED',
    saveQuery: 'NOT_STARTED',
    users: 'NOT_STARTED',
  },
  feedback: {
    isOpen: false,
    type: undefined,
    message: '',
  },
};

const queryAddSlice = createSlice({
  name: 'queryAdd',
  initialState,
  reducers: {
    getClientsStarted(state) {
      state.loadingStatus.clients = 'PENDING';
    },
    getClientsSuccess(state, action) {
      state.clients = action.payload;
      state.loadingStatus.clients = 'COMPLETE';
    },
    getClientsFailed(state, action) {
      state.loadingStatus.clients = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getSkusStarted(state) {
      state.loadingStatus.skus = 'PENDING';
    },
    getSkusSuccess(state, action) {
      state.skus = action.payload;
      state.loadingStatus.skus = 'COMPLETE';
    },
    getSkusFailed(state, action) {
      state.loadingStatus.skus = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    queryAddStarted(state) {
      state.loadingStatus.saveQuery = 'PENDING';
    },
    queryAddSuccess(state) {
      state.loadingStatus.saveQuery = 'COMPLETE';
      state.isUserRedirectedToBrowse = true;
    },
    queryAddFailed(state, action) {
      state.loadingStatus.saveQuery = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getUsersStarted(state) {
      state.loadingStatus.users = 'PENDING';
    },
    getUsersSuccess(state, action) {
      state.users = action.payload;
      state.loadingStatus.users = 'COMPLETE';
    },
    getUsersFailed(state, action) {
      state.loadingStatus.users = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    resetState() {
      return initialState;
    },
  },
});

export const {
  getClientsStarted,
  getClientsSuccess,
  getClientsFailed,
  getSkusStarted,
  getSkusSuccess,
  getSkusFailed,
  queryAddSuccess,
  queryAddFailed,
  queryAddStarted,
  getUsersFailed,
  getUsersStarted,
  getUsersSuccess,
  resetState,
} = queryAddSlice.actions;

export default queryAddSlice.reducer;

export const fetchClients = () => async (dispatch) => {
  dispatch(getClientsStarted());
  const result = await getClients();

  if (result.status === 200) {
    dispatch(getClientsSuccess(result.clients));
  } else {
    dispatch(getClientsFailed(result.error));
  }
};

export const fetchClientSkus = (clientId) => async (dispatch) => {
  dispatch(getSkusStarted());
  const result = await getSkus('NOT_SPECIFIED', clientId);

  if (result.status === 200) {
    dispatch(getSkusSuccess(result.skus));
  } else {
    dispatch(getSkusFailed(result.error));
  }
};

export const queryAdd = (queryInfo) => async (dispatch) => {
  dispatch(queryAddStarted());
  const result = await addQuery(queryInfo);

  if (result.status === 200) {
    dispatch(queryAddSuccess());
  } else {
    dispatch(queryAddFailed(result.error));
  }
};

export const fetchUsers = () => async (dispatch) => {
  dispatch(getUsersStarted());
  const result = await getUsers();

  if (result.status === 200) {
    dispatch(getUsersSuccess(result.users));
  } else {
    dispatch(getUsersFailed(result.error));
  }
};

export const initiateStateReset = () => async (dispatch) => {
  dispatch(resetState());
};
