/* eslint-disable react/jsx-props-no-spreading */
import 'firebase/storage';
import { Divider, TextField } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import {
  fetchPresalesSuppliers,
  generateSubmissionSummary,
  uploadSubmission,
} from './slice';
import { firestore } from '../../../utils/firebase';
import allStyles from '../../../utils/styles';
import ContentWrapper from '../../../components/ContentWrapper';
import PageHeader from '../../../components/PageHeader';
import RequestedTemplates from './components/RequestedTemplates';
import Submissions from './components/Submissions';
import TemplateActionBar from './components/TemplateActionBar';
import theme from '../../../utils/theme';

const useStyles = makeStyles(allStyles);

const baseStyle = {
  padding: 14,
  textAlign: 'center',
  borderRadius: 4,
  border: '1px dashed white',
  color: 'white',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const breadcrumbs = [
  {
    label: 'Presales',
    link: '/presales',
  },
  {
    label: 'Submissions',
    link: '/submissions/updates',
  },
];

const SubmissionsBrowse = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [activeTab, setActiveTab] = useState('GENERATED');
  const [documents, setDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [fileInfo, setFileInfo] = useState([]);
  const [statusId, setStatusId] = useState('ALL');
  const [supplier, setSupplier] = useState('ALL');
  const [updateTemplates, setUpdateTemplates] = useState([]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop: (acceptedDocuments) => {
      setDocuments([...documents, ...acceptedDocuments]);
      setFileInfo(acceptedDocuments.map((file) => file.path));
    },
  });

  const suppliers = useSelector(
    (state) => state.presalesSubmissionsBrowse.suppliers
  );
  const suppliersLoadingStatus = useSelector(
    (state) => state.presalesSubmissionsBrowse.loadingStatus.suppliers
  );
  const requestSummaryLoadingStatus = useSelector(
    (state) => state.presalesSubmissionsBrowse.loadingStatus.requestSummary
  );
  const uploadSubmissionLoadingStatus = useSelector(
    (state) => state.presalesSubmissionsBrowse.loadingStatus.uploadSubmissions
  );
  const updateSubmissionLoadingStatus = useSelector(
    (state) => state.presalesSubmissionsBrowse.loadingStatus.updateSubmissions
  );
  const fetchSubmissionsStatus = useSelector(
    (state) => state.presalesSubmissionsBrowse.loadingStatus.fetchSubmissions
  );
  const fetchSubmissionSubcollectionStatus = useSelector(
    (state) =>
      state.presalesSubmissionsBrowse.loadingStatus.fetchSubmissionSubcollection
  );
  const userInfo = useSelector((state) => state.account.userInfo);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  useEffect(() => {
    dispatch(fetchPresalesSuppliers());

    const updateTemplateRef = firestore
      .collection('presalesSubmissionSummaries')
      .orderBy('requestDate', 'desc')
      .limit(20);

    const unsubscribeTemplates = updateTemplateRef.onSnapshot(
      (querySnapshot) => {
        const result = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            requestDate: data.requestDate.toDate(),
          };
        });

        setUpdateTemplates(result);
      }
    );

    return () => {
      unsubscribeTemplates();
    };
  }, []);

  useEffect(() => {
    if (
      suppliersLoadingStatus === 'PENDING' ||
      requestSummaryLoadingStatus === 'PENDING' ||
      uploadSubmissionLoadingStatus === 'PENDING' ||
      fetchSubmissionsStatus === 'PENDING' ||
      updateSubmissionLoadingStatus === 'PENDING'
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [
    suppliersLoadingStatus,
    requestSummaryLoadingStatus,
    uploadSubmissionLoadingStatus,
    fetchSubmissionsStatus,
    fetchSubmissionSubcollectionStatus,
    updateSubmissionLoadingStatus,
  ]);

  useEffect(() => {
    if (requestSummaryLoadingStatus === 'COMPLETE') {
      setSupplier('ALL');
      setStatusId('ALL');
    }
  }, [requestSummaryLoadingStatus]);

  const uploadUpdateSubmission = () => {
    dispatch(
      uploadSubmission(documents, {
        userID: userInfo.uid,
        requestedBy: `${userInfo.name} ${userInfo.surname}`,
        allSkusCompleted: false,
      })
    );
    setFileInfo([]);
    setDocuments([]);
  };

  const getSupplierName = () => {
    const supplierInfo = suppliers.find((info) => info.id === supplier);
    if (!supplierInfo) {
      return 'Unknown';
    }

    return supplierInfo.name;
  };

  const formatSubmissions = () => {
    const presaleSkusInfo = [];

    return presaleSkusInfo;
  };

  return (
    <div>
      <PageHeader
        title="Submissions"
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Browse"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
      />
      {isLoading && <LinearProgress color="secondary" />}
      <ContentWrapper>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Tabs
              value={activeTab}
              indicatorColor="secondary"
              textColor="secondary"
              onChange={(e, newTab) => setActiveTab(newTab)}
            >
              <Tab label="Generated Files" value="GENERATED" />
              <Tab label="Takealot Submissions" value="SUBMITTED" />
            </Tabs>
          </Grid>
          {activeTab === 'GENERATED' && (
            <Grid item sm={12}>
              <TemplateActionBar
                statusId={statusId}
                changeStatusId={setStatusId}
                supplier={supplier}
                changeSupplier={setSupplier}
                suppliers={suppliers}
                isLoading={isLoading}
                requestTemplate={() =>
                  dispatch(
                    generateSubmissionSummary(
                      userInfo.uid,
                      `${userInfo.name} ${userInfo.surname}`,
                      supplier,
                      getSupplierName(),
                      statusId
                    )
                  )
                }
              />
              <RequestedTemplates updateTemplates={updateTemplates} />
            </Grid>
          )}
          {activeTab === 'SUBMITTED' && (
            <Grid item sm={12}>
              <Grid
                container
                spacing={4}
                alignItems="center"
                style={{
                  backgroundColor: grey[800],
                  borderRadius: 8,
                  marginTop: theme.spacing(2),
                  marginBottom: theme.spacing(4),
                }}
              >
                <Grid item style={{ display: 'flex', flexDirection: 'column' }}>
                  <TextField
                    label="Submission ID"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <div style={{ height: 12 }} />
                  <Button
                    variant="contained"
                    onClick={() => uploadUpdateSubmission()}
                    disabled={documents.length === 0}
                  >
                    Update Submissions
                  </Button>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item style={{ flex: 1 }}>
                  <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    {fileInfo.length > 0 ? (
                      <p>{fileInfo}</p>
                    ) : (
                      <p>
                        Drag &rsquo;n&lsquo; drop submission, or click to select
                        submission
                      </p>
                    )}
                  </div>
                </Grid>
              </Grid>
              <div className={classes.separator} />
              <Submissions submissionInfo={formatSubmissions()} />
            </Grid>
          )}
        </Grid>
      </ContentWrapper>
    </div>
  );
};
export default SubmissionsBrowse;
