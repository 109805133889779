import { connectHits, connectStateResults } from 'react-instantsearch-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Hit from './components/Hit';
import EmptyViewMessage from '../../../../../components/EmptyViewMessage';
import HitsPagination from '../../../../../components/HitsPagination';
import SearchStats from '../../../../../components/SearchStats';

const Results = connectStateResults(({ isSearchStalled, searchResults }) => {
  if (isSearchStalled) {
    return (
      <Grid item xs={12} container justifyContent="center">
        <CircularProgress color="secondary" />
      </Grid>
    );
  }

  if (searchResults.nbHits === 0) {
    return (
      <EmptyViewMessage
        heading="No SKUs found"
        message="Please adjust the filters or search query."
      />
    );
  }

  return <div />;
});

const Hits = ({ goToLink, hits }) => {
  return (
    <Grid container spacing={3}>
      {hits.length > 0 && (
        <Grid item xs={12}>
          <Grid container spacing={1} justifyContent="center">
            <HitsPagination />
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        <SearchStats />
      </Grid>
      <Results />
      {hits.map((hit) => {
        return (
          <Grid key={hit.objectID} item xs={12}>
            <Hit
              title={hit.supplierDescription}
              status={hit.status}
              eolSku={hit.eolSku}
              supplier={hit.supplierName}
              primaryImageUrl={hit.primaryImageLink}
              onboardingStatuses={{
                barcodesComplete: hit['onboardingStatuses.barcodesComplete'],
                imagesComplete: hit['onboardingStatuses.imagesComplete'],
                copyComplete: hit['onboardingStatuses.copyComplete'],
                measurementsComplete:
                  hit['onboardingStatuses.measurementsComplete'],
                categoriesComplete:
                  hit['onboardingStatuses.categoriesComplete'],
              }}
              goToSkuInfo={() => goToLink(`/presales/pipeline/${hit.objectID}`)}
              manuallyDisabled={hit.manuallyDisabled}
            />
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <Grid container spacing={1} justifyContent="center">
          <HitsPagination />
        </Grid>
      </Grid>
    </Grid>
  );
};
Hits.defaultProps = {
  goToLink: (path) => console.log(`User navigated to: ${path}`),
  hits: [],
};
Hits.propTypes = {
  goToLink: PropTypes.func,
  hits: PropTypes.arrayOf(
    PropTypes.shape({
      supplierDescription: PropTypes.string,
      objectID: PropTypes.string,
      status: PropTypes.string,
      eolSku: PropTypes.string,
      supplierName: PropTypes.string,
      primaryImageLink: PropTypes.string,
      manuallyDisabled: PropTypes.bool,
      'onboardingStatuses.imagesComplete': PropTypes.bool,
      'onboardingStatuses.copyComplete': PropTypes.bool,
      'onboardingStatuses.measurementsComplete': PropTypes.bool,
      'onboardingStatuses.barcodeComplete': PropTypes.bool,
      'onboardingStatuses.categoriesComplete': PropTypes.bool,
    })
  ),
};
export default connectHits(Hits);
