import _ from 'lodash';

const getFormattedQueries = (
  queries,
  searchQuery = '',
  statusFilter = 'ALL',
  typesFilter = 'ALL',
  clientFilter = 'ALL',
  assigneeFilter = 'ALL'
) => {
  return queries
    .map((info) => {
      const tempSkus = [];

      if (info.skus) {
        info.skus.forEach((data) => {
          tempSkus.push({
            sku: data.sku,
            product: data.product,
            changeDetail: data.changeDetail,
            changeTo: data.changeTo,
          });
        });
      }

      return {
        id: info.id,
        clientId: info.clientId,
        clientName: info.clientName,
        requestDate: info.requestDate,
        requestType: info.requestType,
        status: info.status,
        contentRef: info.contentRef,
        notes: info.notes || '-',
        skus: tempSkus,
        assigneeName: info.assigneeName,
        assigneeId: info.assigneeId,
      };
    })
    .filter((info) => {
      if (searchQuery !== '') {
        const caseInsensitiveSearch = _.toLower(searchQuery);
        const caseInsensitiveId = _.toLower(info.id);
        const caseInsensitiveClientName = _.toLower(info.clientName);
        const caseInsensitiveAssigneeName = _.toLower(info.assigneeName);

        if (caseInsensitiveId.includes(caseInsensitiveSearch)) {
          return true;
        }

        if (caseInsensitiveClientName.includes(caseInsensitiveSearch)) {
          return true;
        }

        if (caseInsensitiveAssigneeName.includes(caseInsensitiveSearch)) {
          return true;
        }

        return false;
      }

      return true;
    })
    .filter((info) => {
      if (statusFilter !== 'ALL') {
        return info.status === statusFilter;
      }
      return true;
    })
    .filter((info) => {
      if (typesFilter !== 'ALL') {
        return info.requestType === typesFilter;
      }
      return true;
    })
    .filter((info) => {
      if (clientFilter !== 'ALL') {
        return info.clientName === clientFilter;
      }
      return true;
    })
    .filter((info) => {
      if (assigneeFilter !== 'ALL') {
        return info.assigneeName === assigneeFilter;
      }
      return true;
    })
    .sort((a, b) => {
      if (a.requestDate < b.requestDate) return +1;
      if (a.requestDate > b.requestDate) return -1;
      if (a.clientName < b.clientName) return -1;
      if (a.clientName > b.clientName) return +1;
      return 0;
    });
};

export default getFormattedQueries;
