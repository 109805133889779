/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  getSku,
  getImages,
  deleteImage,
  updateImageListingOrder,
  getCopies,
  updateCopyStatus,
} from '../../../api/listingsAPI';
import { getWarehouses } from '../../../api/logisticsAPI';

const initialState = {
  skuInfo: {
    sku: 'Loading',
    amazon: {
      status: 'INACTIVE',
    },
    takealot: {
      status: 'INACTIVE',
    },
  },
  imagesList: [],
  warehousesList: [],
  copiesList: [],
  loadingStatus: {
    skuInfo: 'NOT_STARTED',
    warehouses: 'NOT_STARTED',
    images: 'NOT_STARTED',
    deleteImage: 'NOT_STARTED',
    updateImage: 'NOT_STARTED',
    copies: 'NOT_STARTED',
    copyReviewStatus: 'NOT_STARTED',
  },
  feedback: {
    isOpen: false,
    type: undefined,
    message: '',
  },
};

const listingsViewSlice = createSlice({
  name: 'listingsView',
  initialState,
  reducers: {
    fetchSkuInfoStarted(state) {
      state.loadingStatus.skuInfo = 'PENDING';
    },
    fetchSkuInfoSuccess(state, action) {
      state.skuInfo = action.payload;
      state.loadingStatus.skuInfo = 'COMPLETE';
    },
    fetchSkuInfoFailed(state, action) {
      state.loadingStatus.skuInfo = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    fetchSkuImagesStarted(state) {
      state.loadingStatus.images = 'PENDING';
    },
    fetchSkuImagesSuccess(state, action) {
      state.imagesList = action.payload;
      state.loadingStatus.images = 'COMPLETE';
    },
    fetchSkuImagesFailed(state, action) {
      state.loadingStatus.images = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getWarehousesStarted(state) {
      state.loadingStatus.warehouses = 'PENDING';
    },
    getWarehousesSuccess(state, action) {
      state.warehousesList = action.payload;
      state.loadingStatus.warehouses = 'COMPLETE';
    },
    getWarehousesFailed(state, action) {
      state.loadingStatus.warehouses = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    deleteImageStarted(state) {
      state.loadingStatus.deleteImage = 'PENDING';
    },
    deleteImageSuccess(state, action) {
      state.loadingStatus.deleteImage = 'COMPLETE';
      state.imagesList = state.imagesList.filter(
        (imageInfo) => imageInfo.id !== action.payload
      );
    },
    deleteImageFailed(state, action) {
      state.loadingStatus.deleteImage = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    updateImageStarted(state) {
      state.loadingStatus.updateImage = 'PENDING';
    },
    updateImageSuccess(state, action) {
      state.loadingStatus.updateImage = 'COMPLETE';
      state.imagesList = state.imagesList.map((imageInfo) => ({
        ...imageInfo,
        listingOrder: action.payload[imageInfo.id],
      }));
    },
    updateImageFailed(state, action) {
      state.loadingStatus.updateImage = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getCopiesStarted(state) {
      state.loadingStatus.copies = 'PENDING';
    },
    getCopiesSuccess(state, action) {
      state.copiesList = action.payload;
      state.loadingStatus.copies = 'COMPLETE';
    },
    getCopiesFailed(state, action) {
      state.loadingStatus.copies = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    updateCopyStatusStarted(state) {
      state.loadingStatus.copyReviewStatus = 'PENDING';
    },
    updateCopyStatusSuccess(state, action) {
      state.copiesList = state.copiesList.map((info) => {
        if (info.id === action.payload.copyId) {
          return {
            ...info,
            status: action.payload.copyStatus,
            rejectionReason: action.payload.rejectReason,
          };
        }
        return info;
      });
      state.loadingStatus.copyReviewStatus = 'COMPLETE';
    },
    updateCopyStatusFailed(state, action) {
      state.loadingStatus.copyReviewStatus = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
  },
});

export const {
  fetchSkuInfoStarted,
  fetchSkuInfoSuccess,
  fetchSkuInfoFailed,
  fetchSkuImagesStarted,
  fetchSkuImagesSuccess,
  fetchSkuImagesFailed,
  getWarehousesStarted,
  getWarehousesSuccess,
  getWarehousesFailed,
  deleteImageStarted,
  deleteImageSuccess,
  deleteImageFailed,
  updateImageStarted,
  updateImageSuccess,
  updateImageFailed,
  getCopiesFailed,
  getCopiesStarted,
  getCopiesSuccess,
  updateCopyStatusFailed,
  updateCopyStatusStarted,
  updateCopyStatusSuccess,
} = listingsViewSlice.actions;

export default listingsViewSlice.reducer;

export const fetchSkuInfo = (sku) => async (dispatch) => {
  dispatch(fetchSkuInfoStarted());
  const result = await getSku(sku);

  if (result.status === 200) {
    dispatch(fetchSkuInfoSuccess(result.skuInfo));
  } else {
    dispatch(fetchSkuInfoFailed(result.error));
  }
};

export const fetchImages = (sku) => async (dispatch) => {
  dispatch(fetchSkuImagesStarted());
  const result = await getImages(sku);

  if (result.status === 200) {
    dispatch(fetchSkuImagesSuccess(result.images));
  } else {
    dispatch(fetchSkuImagesFailed(result.error));
  }
};

export const fetchWarehouses = () => async (dispatch) => {
  dispatch(getWarehousesStarted());
  const result = await getWarehouses();

  if (result.status === 200) {
    dispatch(getWarehousesSuccess(result.warehouses));
  } else {
    dispatch(getWarehousesFailed(result.error));
  }
};

export const deleteSkuImage = (sku, imagePath, imageId) => async (dispatch) => {
  dispatch(deleteImageStarted());
  const result = await deleteImage(sku, imagePath, imageId);

  if (result.status === 200) {
    dispatch(deleteImageSuccess(imageId));
  } else {
    dispatch(deleteImageFailed(result.error));
  }
};

export const updateListingOrders =
  (sku, imageOrders, images) => async (dispatch) => {
    dispatch(updateImageStarted());
    const result = await updateImageListingOrder(sku, imageOrders, images);

    if (result.status === 200) {
      dispatch(updateImageSuccess(imageOrders));
    } else {
      dispatch(updateImageFailed(result.error));
    }
  };

export const fetchCopies = (sku) => async (dispatch) => {
  dispatch(getCopiesStarted());
  const result = await getCopies(sku);

  if (result.status === 200) {
    dispatch(getCopiesSuccess(result.copies));
  } else {
    dispatch(getCopiesFailed(result.error));
  }
};

export const copyStatusUpdate =
  (copyId, copyStatus, rejectReason = '') =>
  async (dispatch) => {
    dispatch(updateCopyStatusStarted());
    const result = await updateCopyStatus(copyId, copyStatus, rejectReason);

    if (result.status === 200) {
      dispatch(updateCopyStatusSuccess({ copyId, copyStatus, rejectReason }));
    } else {
      dispatch(updateCopyStatusFailed(result.error));
    }
  };
