import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { fetchProducts, updateInitialPage, updatePageSize } from './slice';
import {
  getFormattedProducts,
  getProductSourcers,
  getSuppliers,
} from './logic';
import ContentWrapper from '../../../components/ContentWrapper';
import EmptyViewMessage from '../../../components/EmptyViewMessage';
import FiltersBar from './components/FiltersBar';
import PageHeader from '../../../components/PageHeader';
import ProductsTable from './components/ProductsTable';

const breadcrumbs = [
  {
    label: 'Sales',
    link: '/sales',
  },
  {
    label: 'Products',
    link: '/products',
  },
];

const SourcedProductsBrowse = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [productSourcerFilter, setProductSourcerFilter] = useState('ALL');
  const [supplierFilter, setSupplierFilter] = useState('ALL');
  const [statusFilter, setStatusFilter] = useState('ALL');

  const products = useSelector((state) => state.sourcedProductsBrowse.products);
  const pageSize = useSelector((state) => state.sourcedProductsBrowse.pageSize);
  const initialPage = useSelector(
    (state) => state.sourcedProductsBrowse.initialPage
  );
  const productsLoadingStatus = useSelector(
    (state) => state.sourcedProductsBrowse.loadingStatus.products
  );

  useEffect(() => {
    if (productsLoadingStatus !== 'PENDING') {
      dispatch(fetchProducts());
    }
  }, []);

  useEffect(() => {
    if (productsLoadingStatus === 'PENDING') {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [productsLoadingStatus]);

  return (
    <div>
      <PageHeader
        title="Browse Products"
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Browse"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
        actions={[
          {
            label: 'Add new products',
            icon: <AddIcon />,
            onClick: () => history.push('/sales/products/add'),
          },
        ]}
      />
      {isLoading && <LinearProgress color="secondary" />}
      <ContentWrapper>
        <FiltersBar
          searchText={searchQuery}
          changeSearchText={setSearchQuery}
          listingCount={
            getFormattedProducts(
              products,
              searchQuery,
              productSourcerFilter,
              supplierFilter,
              statusFilter
            ).length
          }
          productSourcerFilter={productSourcerFilter}
          changeProductSourcerFilter={setProductSourcerFilter}
          productSourcerOptions={getProductSourcers(products)}
          supplierFilter={supplierFilter}
          changeSupplierFilter={setSupplierFilter}
          supplierOptions={getSuppliers(products)}
          statusFilter={statusFilter}
          changeStatusFilter={setStatusFilter}
        />
        {!isLoading && products.length === 0 ? (
          <EmptyViewMessage
            heading="No Products Available"
            message="Please adjust the filters or add new products to view them here."
          />
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ProductsTable
                isLoading={isLoading}
                products={getFormattedProducts(
                  products,
                  searchQuery,
                  productSourcerFilter,
                  supplierFilter,
                  statusFilter
                )}
                editProduct={(id) =>
                  history.push(`/sales/products/browse/edit/${id}`)
                }
                viewProduct={(id) =>
                  history.push(`/sales/products/browse/${id}`)
                }
                pageSize={pageSize}
                initialPage={initialPage}
                updatePageSize={(newPageSize) =>
                  dispatch(updatePageSize(newPageSize))
                }
                updateInitialPage={(newInitialPage) =>
                  dispatch(updateInitialPage(newInitialPage))
                }
              />
            </Grid>
          </Grid>
        )}
      </ContentWrapper>
    </div>
  );
};

export default SourcedProductsBrowse;
