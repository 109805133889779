/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getUsers } from '../../../api/userAPI';

const initialState = {
  usersList: [],
  loadingStatus: {
    users: 'NOT_STARTED',
  },
  pageSize: 5,
  initialPage: 0,
};

const usersBrowseSlice = createSlice({
  name: 'usersBrowse',
  initialState,
  reducers: {
    getUsersStarted(state) {
      state.loadingStatus.users = 'PENDING';
    },
    getUsersSuccess(state, action) {
      state.usersList = action.payload;
      state.loadingStatus.users = 'COMPLETE';
    },
    getUsersFailed(state, action) {
      state.loadingStatus.users = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    changePageSize(state, action) {
      state.pageSize = action.payload;
    },
    changeInitialPage(state, action) {
      state.initialPage = action.payload;
    },
  },
});

export const {
  getUsersStarted,
  getUsersSuccess,
  getUsersFailed,
  changePageSize,
  changeInitialPage,
} = usersBrowseSlice.actions;

export const fetchUsers = () => async (dispatch) => {
  dispatch(getUsersStarted());
  const result = await getUsers();

  if (result.status === 200) {
    dispatch(getUsersSuccess(result.users));
  } else {
    dispatch(getUsersFailed(result.error));
  }
};

export const updatePageSize = (newPageSize) => (dispatch) => {
  dispatch(changePageSize(newPageSize));
};

export const updateInitialPage = (newInitialPage) => (dispatch) => {
  dispatch(changeInitialPage(newInitialPage));
};

export default usersBrowseSlice.reducer;
