import { green, grey, orange, red, yellow } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { Bar } from 'react-chartjs-2';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import PropTypes from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.secondary.light,
  },
  loadingRect: {
    height: 300,
    backgroundColor: grey[900],
  },
}));

const StatusBarGraph = ({
  stockInfoCounts,
  copywritingCounts,
  imagesCounts,
  submissionCounts,
  isLoading,
}) => {
  const classes = useStyles();

  const data = {
    labels: ['Stock Info', 'Copywriting', 'Images', 'Submission'],
    datasets: [
      {
        label: 'Not Started',
        backgroundColor: red[600],
        data: [
          null,
          copywritingCounts[0],
          imagesCounts[0],
          submissionCounts[0],
        ],
        stack: 1,
      },
      {
        label: 'No Stock',
        backgroundColor: red[700],
        data: [stockInfoCounts[0], null, null, null],
        stack: 1,
      },
      {
        label: 'Measuring',
        backgroundColor: orange[700],
        data: [stockInfoCounts[1], null, null, null],
        stack: 1,
      },
      {
        label: 'Taking Stock Photos',
        backgroundColor: yellow[700],
        data: [stockInfoCounts[2], null, null, null],
        stack: 1,
      },
      {
        label: 'Drafting',
        backgroundColor: orange[700],
        data: [null, copywritingCounts[1], null, null],
        stack: 1,
      },
      {
        label: 'Reviewing',
        backgroundColor: yellow[700],
        data: [null, copywritingCounts[2], null, null],
        stack: 1,
      },
      {
        label: 'Awaiting Client Images',
        backgroundColor: red[900],
        data: [null, null, imagesCounts[1], null],
        stack: 1,
      },
      {
        label: 'Shooting',
        backgroundColor: orange[700],
        data: [null, null, imagesCounts[2], null],
        stack: 1,
      },
      {
        label: 'Editing',
        backgroundColor: yellow[700],
        data: [null, null, imagesCounts[3], null],
        stack: 1,
      },
      {
        label: 'In Review',
        backgroundColor: yellow[700],
        data: [null, null, null, submissionCounts[1]],
        stack: 1,
      },
      {
        label: 'Rejected',
        backgroundColor: orange[700],
        data: [null, null, null, submissionCounts[2]],
        stack: 1,
      },
      {
        label: 'Approved',
        backgroundColor: green[700],
        data: [null, null, null, submissionCounts[3]],
        stack: 1,
      },
      {
        label: 'Completed',
        backgroundColor: green[700],
        data: [stockInfoCounts[3], copywritingCounts[3], imagesCounts[4], null],
        stack: 1,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        filter: (tooltipItem) => {
          const value =
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.dataIndex];
          if (value === null) {
            return false;
          }
          return true;
        },
      },
    },
  };

  return (
    <Card>
      <CardHeader
        avatar={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <Avatar className={classes.avatar}>
            <EqualizerIcon />
          </Avatar>
        }
        title="Pipeline"
      />
      <CardContent>
        {isLoading ? (
          <Skeleton variant="rect" className={classes.loadingRect} />
        ) : (
          <div>
            <Bar height={300} data={data} options={options} />
          </div>
        )}
      </CardContent>
    </Card>
  );
};

StatusBarGraph.defaultProps = {
  stockInfoCounts: [9, 12, 3],
  copywritingCounts: [3, 7, 4, 12],
  imagesCounts: [2, 2, 5, 4, 1],
  submissionCounts: [9, 3, 7, 11],
  isLoading: false,
};

StatusBarGraph.propTypes = {
  stockInfoCounts: PropTypes.arrayOf(PropTypes.number),
  copywritingCounts: PropTypes.arrayOf(PropTypes.number),
  imagesCounts: PropTypes.arrayOf(PropTypes.number),
  submissionCounts: PropTypes.arrayOf(PropTypes.number),
  isLoading: PropTypes.bool,
};

export default StatusBarGraph;
