/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getUserInfo } from '../../../api/userAPI';

const initialState = {
  userInfo: {
    id: 'Loading',
    email: '',
    name: '',
    phoneNumber: '',
    surname: '',
    title: '',
    type: '',
  },
  loadingStatus: {
    userInfo: 'NOT_STARTED',
  },
  feedback: {
    isOpen: false,
    type: undefined,
    message: '',
  },
};

const usersViewSlice = createSlice({
  name: 'usersView',
  initialState,
  reducers: {
    fetchUserInfoStarted(state) {
      state.loadingStatus.userInfo = 'PENDING';
    },
    fetchUserInfoSuccess(state, action) {
      state.userInfo = action.payload;
      state.loadingStatus.userInfo = 'COMPLETE';
    },
    fetchUserInfoFailed(state, action) {
      state.loadingStatus.userInfo = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
  },
});

export const {
  fetchUserInfoStarted,
  fetchUserInfoSuccess,
  fetchUserInfoFailed,
} = usersViewSlice.actions;

export default usersViewSlice.reducer;

export const fetchUserInfo = (userId) => async (dispatch) => {
  dispatch(fetchUserInfoStarted());
  const result = await getUserInfo(userId);

  if (result.status === 200) {
    dispatch(fetchUserInfoSuccess(result.userInfo));
  } else {
    dispatch(fetchUserInfoFailed(result.error));
  }
};
