import { useState } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import PublishIcon from '@material-ui/icons/Publish';

const SubmissionSelector = ({ submissionFilter, changeSubmissionFilter }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const getPrettyFilter = (filter) => {
    switch (filter) {
      case 'ALL':
        return 'All';
      case 'NOT_STARTED':
        return 'Not Started';
      case 'IN_REVIEW':
        return 'In Review';
      case 'REJECTED':
        return 'Rejected';
      case 'APPROVED':
        return 'Approved';
      default:
        return 'Custom';
    }
  };

  return (
    <>
      <Button
        aria-controls="submission-menu"
        aria-haspopup="true"
        size="large"
        startIcon={<PublishIcon />}
        onClick={openMenu}
      >
        {getPrettyFilter(submissionFilter)}
      </Button>
      <Menu
        id="submission-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem
          onClick={() => {
            changeSubmissionFilter('ALL');
            closeMenu();
          }}
        >
          All Statuses
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeSubmissionFilter('NOT_STARTED');
            closeMenu();
          }}
        >
          Not Started
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeSubmissionFilter('IN_REVIEW');
            closeMenu();
          }}
        >
          In Review
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeSubmissionFilter('REJECTED');
            closeMenu();
          }}
        >
          Rejected
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeSubmissionFilter('APPROVED');
            closeMenu();
          }}
        >
          Approved
        </MenuItem>
      </Menu>
    </>
  );
};

SubmissionSelector.defaultProps = {
  submissionFilter: 'ALL',
  changeSubmissionFilter: (newFilter) =>
    console.log('User changes the submission filter to:', newFilter),
};

SubmissionSelector.propTypes = {
  submissionFilter: PropTypes.string,
  changeSubmissionFilter: PropTypes.func,
};

export default SubmissionSelector;
