/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getClients } from '../../../api/clientsAPI';
import { getSkus } from '../../../api/listingsAPI';
import {
  getShipment,
  getWarehouses,
  updateShipmentInfo,
} from '../../../api/logisticsAPI';

const initialState = {
  shipmentInfo: {
    skus: [],
  },
  clients: [],
  warehouses: [],
  skus: [],
  loadingStatus: {
    shipmentInfo: 'NOT_STARTED',
    warehouses: 'NOT_STARTED',
    clients: 'NOT_STARTED',
    skus: 'NOT_STARTED',
    updating: 'NOT_STARTED',
  },
};

const shipmentEditSlice = createSlice({
  name: 'shipmentEdit',
  initialState,
  reducers: {
    fetchShipmentInfoStarted(state) {
      state.loadingStatus.shipmentInfo = 'PENDING';
    },
    fetchShipmentInfoSuccess(state, action) {
      state.shipmentInfo = action.payload;
      state.loadingStatus.shipmentInfo = 'COMPLETE';
    },
    fetchShipmentInfoFailed(state, action) {
      state.loadingStatus.shipmentInfo = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    updateShipmentStarted(state) {
      state.loadingStatus.updating = 'PENDING';
    },
    updateShipmentSuccess(state) {
      state.loadingStatus.updating = 'COMPLETE';
      state.isUserRedirectedToBrowse = true;
    },
    updateShipmentFailed(state, action) {
      state.loadingStatus.updating = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getClientsStarted(state) {
      state.loadingStatus.clients = 'PENDING';
    },
    getClientsSuccess(state, action) {
      state.clients = action.payload;
      state.loadingStatus.clients = 'COMPLETE';
    },
    getClientsFailed(state, action) {
      state.loadingStatus.clients = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getWarehousesStarted(state) {
      state.loadingStatus.warehouses = 'PENDING';
    },
    getWarehousesSuccess(state, action) {
      state.warehouses = action.payload;
      state.loadingStatus.warehouses = 'COMPLETE';
    },
    getWarehousesFailed(state, action) {
      state.loadingStatus.warehouses = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getSkusStarted(state) {
      state.loadingStatus.skus = 'PENDING';
    },
    getSkusSuccess(state, action) {
      state.skus = action.payload;
      state.loadingStatus.skus = 'COMPLETE';
    },
    getSkusFailed(state, action) {
      state.loadingStatus.skus = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    resetState() {
      return initialState;
    },
  },
});

export const {
  fetchShipmentInfoStarted,
  fetchShipmentInfoSuccess,
  fetchShipmentInfoFailed,
  updateShipmentStarted,
  updateShipmentSuccess,
  updateShipmentFailed,
  getClientsStarted,
  getClientsSuccess,
  getClientsFailed,
  getWarehousesStarted,
  getWarehousesSuccess,
  getWarehousesFailed,
  getSkusStarted,
  getSkusSuccess,
  getSkusFailed,
  resetState,
} = shipmentEditSlice.actions;

export default shipmentEditSlice.reducer;

export const fetchShipmentInfo = (shipmentId) => async (dispatch) => {
  dispatch(fetchShipmentInfoStarted());
  const result = await getShipment(shipmentId);

  if (result.status === 200) {
    dispatch(fetchShipmentInfoSuccess(result.shipmentInfo));
  } else {
    dispatch(fetchShipmentInfoFailed({ message: result.error }));
  }
};

export const updateShipment =
  (shipmentId, shipmentInfo) => async (dispatch) => {
    dispatch(updateShipmentStarted());
    const result = await updateShipmentInfo(shipmentId, shipmentInfo);

    if (result.status === 200) {
      dispatch(updateShipmentSuccess());
    } else {
      dispatch(updateShipmentFailed(result.error));
    }
  };

export const fetchClients = () => async (dispatch) => {
  dispatch(getClientsStarted());
  const result = await getClients();

  if (result.status === 200) {
    dispatch(getClientsSuccess(result.clients));
  } else {
    dispatch(getClientsFailed(result.error));
  }
};

export const fetchWarehouses = () => async (dispatch) => {
  dispatch(getWarehousesStarted());
  const result = await getWarehouses();

  if (result.status === 200) {
    dispatch(getWarehousesSuccess(result.warehouses));
  } else {
    dispatch(getWarehousesFailed(result.error));
  }
};

export const fetchSkus = () => async (dispatch) => {
  dispatch(getSkusStarted());
  const result = await getSkus('NOT_SPECIFIED');

  if (result.status === 200) {
    dispatch(getSkusSuccess(result.skus));
  } else {
    dispatch(getSkusFailed(result.error));
  }
};

export const initiateStateReset = () => async (dispatch) => {
  dispatch(resetState());
};
