import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import GetAppIcon from '@material-ui/icons/GetApp';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';

const DocumentsTab = ({ documents, deleteDocument }) => {
  const getFormattedFileSize = (fileSize) => {
    return numeral(fileSize / 1048576).format('0,0.00');
  };

  return (
    <Card>
      <CardContent>
        <List>
          {documents.map((info) => (
            <ListItem key={info.id}>
              <ListItemText
                primary={info.description}
                secondary={`${getFormattedFileSize(
                  info.fileSize
                )} mb | Upload date: ${
                  info.date
                    ? moment(info.date.toDate()).format('DD/MM/YYYY')
                    : 'Unknown'
                }`}
              />
              <ListItemSecondaryAction>
                <Tooltip title="Download">
                  <IconButton
                    edge="end"
                    onClick={() => window.open(info.url, '_blank')}
                  >
                    <GetAppIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    edge="end"
                    onClick={() => {
                      deleteDocument(info);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

DocumentsTab.defaultProps = {
  documents: [],
  deleteDocument: () => {},
};

DocumentsTab.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.shape({})),
  deleteDocument: PropTypes.func,
};

export default DocumentsTab;
