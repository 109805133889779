import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AgencySelector from './components/AgencySelector';
import SalesPersonSelector from './components/SalesPersonSelector';
import StatusSelector from './components/StatusSelector';

const useStyles = makeStyles((theme) => ({
  results: {
    margin: theme.spacing(1),
    color: grey[800],
  },
  separator: {
    width: theme.spacing(1),
  },
  wrapperButtons: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  wrapperRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
}));

const FiltersBar = ({
  searchText,
  changeSearchText,
  salesPerson,
  changeSalesPerson,
  salesPeople,
  agency,
  changeAgency,
  agencies,
  statusId,
  changeStatus,
  userType,
  dealCount,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapperRoot}>
      <TextField
        placeholder="Search"
        value={searchText}
        onChange={(e) => changeSearchText(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <Typography className={classes.results} variant="body1" component="span">
        {`${dealCount} ${dealCount === 1 ? 'result' : 'results'} displayed`}
      </Typography>
      <div className={classes.wrapperButtons}>
        {userType !== 'SALES_AGENT' && (
          <SalesPersonSelector
            salesPersonId={salesPerson}
            changeSalesPerson={changeSalesPerson}
            salesPeople={salesPeople}
          />
        )}
        {userType === 'ADMIN' && <div className={classes.separator} />}
        {userType === 'ADMIN' && (
          <AgencySelector
            agencyId={agency}
            changeAgency={changeAgency}
            agencies={agencies}
          />
        )}
        <div className={classes.separator} />
        <StatusSelector statusId={statusId} changeStatus={changeStatus} />
      </div>
    </div>
  );
};

FiltersBar.defaultProps = {
  searchText: '',
  changeSearchText: (newValue) => `Search text was changed to '${newValue}'`,
  salesPerson: 'All Salespeople',
  changeSalesPerson: (newValue) => `Sales person was changed to '${newValue}'`,
  salesPeople: [
    {
      id: 1,
      name: 'John Doe',
    },
    {
      id: 2,
      name: 'Jane Doe',
    },
  ],
  agency: 'All Agencies',
  changeAgency: (newValue) => `Agency was changed to '${newValue}'`,
  agencies: [
    {
      id: 1,
      name: 'Madeup Agency',
    },
    {
      id: 2,
      name: 'Imaginary Inc.',
    },
  ],
  statusId: 'ALL',
  changeStatus: (newValue) => `Status was changed to '${newValue}'`,
  userType: 'ADMIN',
  dealCount: 1,
};

FiltersBar.propTypes = {
  searchText: PropTypes.string,
  changeSearchText: PropTypes.func,
  salesPerson: PropTypes.string,
  changeSalesPerson: PropTypes.func,
  salesPeople: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  agency: PropTypes.string,
  changeAgency: PropTypes.func,
  agencies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  statusId: PropTypes.string,
  changeStatus: PropTypes.func,
  userType: PropTypes.string,
  dealCount: PropTypes.number,
};

export default FiltersBar;
