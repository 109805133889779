import { makeStyles } from '@material-ui/core/styles';
import {
  CircularProgress,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import allStyles from '../../../../../utils/styles';

const useStyles = makeStyles(allStyles);

const SettingsCard = ({
  minUnitsAvailable,
  maxCostPrice,
  minRetailPrice,
  sohPercentMadeAvailable,
  maxWeight,
  maxLongestSide,
  minMargin,
  vatOption,
  isLoading,
}) => {
  const classes = useStyles();

  const getPrettyVatOption = () => {
    switch (vatOption) {
      case 'EXCLUDED':
        return 'Exclusive';
      case 'INCLUDED':
        return 'Inclusive';
      default:
        return 'Unknown';
    }
  };

  return (
    <TableContainer component={Paper}>
      {isLoading ? (
        <div className={classes.loadingWrapper}>
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Minimum Units Available</TableCell>
              <TableCell>{numeral(minUnitsAvailable).format('0,0')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Maximum Cost Price</TableCell>
              <TableCell>
                {`R ${numeral(maxCostPrice).format('0,0')}`}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Minimum Retail Price</TableCell>
              <TableCell>
                {`R ${numeral(minRetailPrice).format('0,0')}`}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Percentage of SOH Made Available</TableCell>
              <TableCell>
                {`${numeral(sohPercentMadeAvailable).format('0,0')}%`}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Maximum Weight</TableCell>
              <TableCell>{`${numeral(maxWeight).format('0,0')} kg`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Maximum Length of Longest Side</TableCell>
              <TableCell>
                {`${numeral(maxLongestSide).format('0,0')} cm `}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Minimum Margin</TableCell>
              <TableCell>{`${numeral(minMargin).format('0,0')}%`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>VAT in Cost Price</TableCell>
              <TableCell>{getPrettyVatOption(vatOption)}</TableCell>
            </TableRow>
          </TableHead>
        </Table>
      )}
    </TableContainer>
  );
};

SettingsCard.defaultProps = {
  isLoading: false,
  minUnitsAvailable: 5,
  maxCostPrice: 10000,
  minRetailPrice: 120,
  sohPercentMadeAvailable: 50,
  maxWeight: 50,
  maxLongestSide: 300,
  minMargin: 20,
  vatOption: 'Exclusive',
};

SettingsCard.propTypes = {
  isLoading: PropTypes.bool,
  minUnitsAvailable: PropTypes.number,
  maxCostPrice: PropTypes.number,
  minRetailPrice: PropTypes.number,
  sohPercentMadeAvailable: PropTypes.number,
  maxWeight: PropTypes.number,
  maxLongestSide: PropTypes.number,
  minMargin: PropTypes.number,
  vatOption: PropTypes.string,
};

export default SettingsCard;
