/* eslint-disable react/jsx-props-no-spreading */
import _ from 'lodash';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { green, red, yellow } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import numeral from 'numeral';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const headerStyle = {
  backgroundColor: '#171415',
};

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.common.black,
  },
  loadingOverlay: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: fade(theme.palette.common.black, 0.8),
  },
  statusAssigned: {
    color: theme.palette.common.black,
    backgroundColor: red[500],
  },
  statusAvailable: {
    color: theme.palette.common.black,
    backgroundColor: green[500],
  },
  statusReserved: {
    color: theme.palette.common.black,
    backgroundColor: yellow[500],
  },
  wrapperContent: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

const ProductsTable = ({
  isLoading,
  products,
  editProduct,
  viewProduct,
  pageSize,
  initialPage,
  updatePageSize,
  updateInitialPage,
}) => {
  const classes = useStyles();

  const formatNumber = (value, currencySymbol, type = 'float') => {
    if (value === null) {
      return '-';
    }
    if (type === 'integer') {
      return `${currencySymbol} ${numeral(value).format('0,0')}`;
    }
    return `${currencySymbol} ${numeral(value).format('0,0.00')}`;
  };

  const getPrettyStatus = (status) => {
    switch (status) {
      case 'AVAILABLE':
        return (
          <Chip
            icon={<DoneIcon className={classes.icon} />}
            label="Available"
            className={classes.statusAvailable}
          />
        );
      case 'RESERVED':
        return (
          <Chip
            icon={<AutorenewIcon className={classes.icon} />}
            label="Reserved"
            className={classes.statusReserved}
          />
        );
      case 'ASSIGNED':
        return (
          <Chip
            icon={<ClearIcon className={classes.icon} />}
            label="Assigned"
            className={classes.statusAssigned}
          />
        );
      default:
        return (
          <Chip
            icon={<DoneIcon className={classes.icon} />}
            label="Active"
            className={classes.statusActive}
          />
        );
    }
  };

  const getColumns = () => {
    const columns = [
      {
        title: 'Status',
        field: 'status',
        render: (rowData) => getPrettyStatus(rowData.status),
      },
      {
        title: 'Super Dealer',
        field: 'superDealerName',
        render: (rowData) =>
          rowData.superDealerName ? rowData.superDealerName : '-',
        editable: false,
      },
      { title: 'Supplier', field: 'supplierName', editable: false },
      { title: 'Sourcing Agent', field: 'productSourcerName', editable: false },
      {
        title: 'Product',
        field: 'description',
        render: (rowData) => {
          return (
            <Tooltip title={rowData.description} aria-label="add" interactive>
              <Typography variant="body2" component="span">
                {_.truncate(rowData.description)}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        title: 'Cost Price',
        field: 'costPrice',
        render: (rowData) => (
          <span>{formatNumber(rowData.costPrice, 'R')}</span>
        ),
      },
      {
        title: 'Wholesale Price',
        field: 'wholesalePrice',
        render: (rowData) => (
          <span>{formatNumber(rowData.wholesalePrice, 'R')}</span>
        ),
      },
      {
        title: 'Retail Price',
        field: 'retailPrice',
        render: (rowData) => {
          return (
            <span>{formatNumber(rowData.retailPrice, 'R', 'integer')}</span>
          );
        },
      },
      {
        title: 'Creation Date',
        field: 'creationDate',
        hidden: true,
        export: true,
      },
    ];

    return columns;
  };

  return (
    <div className={classes.wrapperContent}>
      <MaterialTable
        title=""
        columns={getColumns()}
        data={products}
        isLoading={isLoading}
        onChangeRowsPerPage={updatePageSize}
        onChangePage={updateInitialPage}
        options={{
          pageSize,
          initialPage,
          headerStyle,
          actionsColumnIndex: -1,
          search: false,
          grouping: false,
          draggable: false,
          exportButton: true,
          exportAllData: true,
          exportFileName: 'Products',
        }}
        actions={[
          {
            icon: 'keyboard_arrow_right',
            tooltip: 'View product info',
            onClick: (event, rowData) => viewProduct(rowData.id),
          },
          {
            icon: 'edit',
            tooltip: 'Edit product details',
            onClick: (event, rowData) => editProduct(rowData.id),
          },
        ]}
        components={{
          OverlayLoading: () => (
            <div className={classes.loadingOverlay}>
              <CircularProgress color="primary" size={32} />
            </div>
          ),
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'No products to display',
          },
        }}
      />
    </div>
  );
};

ProductsTable.defaultProps = {
  isLoading: false,
  products: [],
  editProduct: (id) => console.log(`Edit details for ${id}`),
  viewProduct: (id) => console.log(`SKU viewed: ${id}`),
  pageSize: 5,
  initialPage: 0,
  updatePageSize: (newPageSize) =>
    console.log(`Page size updated to: ${newPageSize}`),
  updateInitialPage: (newInitialPage) =>
    console.log(`Initial page updated to: ${newInitialPage}`),
};

ProductsTable.propTypes = {
  isLoading: PropTypes.bool,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      surname: PropTypes.string,
      email: PropTypes.string,
      phoneNumber: PropTypes.string,
      title: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  editProduct: PropTypes.func,
  viewProduct: PropTypes.func,
  pageSize: PropTypes.number,
  initialPage: PropTypes.number,
  updatePageSize: PropTypes.func,
  updateInitialPage: PropTypes.func,
};

export default ProductsTable;
