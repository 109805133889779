/* eslint-disable react/jsx-wrap-multilines */
import { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import SkuConfirmationTable from './components/SkuConfirmationTable';

const CompleteShipmentDialog = ({
  isOpen,
  completeShipment,
  shipmentSkus,
  closeDialog,
}) => {
  const [confirmedSkus, setConfirmedSkus] = useState([]);

  useEffect(() => {
    setConfirmedSkus([]);
  }, [isOpen]);

  return (
    <Dialog
      onClose={closeDialog}
      aria-labelledby="confirm-stock-quantities-dialog-title"
      open={isOpen}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle id="confirm-stock-quantities-title">
        Confirm Stock Quantities
      </DialogTitle>
      <DialogContent>
        <SkuConfirmationTable
          skus={shipmentSkus}
          confirmedSkus={confirmedSkus}
          updateConfirmedQuantities={setConfirmedSkus}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          Cancel
        </Button>
        <Button
          disabled={confirmedSkus.length !== shipmentSkus.length}
          onClick={() =>
            completeShipment(
              confirmedSkus
                .map((info) => ({
                  sku: info.sku,
                  tsin: info.tsin,
                  childAsin: info.childAsin,
                  product: info.product,
                  expectedUnits: info.expectedUnits,
                  receivedUnits: info.receivedUnits,
                }))
                .filter((info) => info.expectedUnits !== info.receivedUnits)
            )
          }
          color="secondary"
          variant="contained"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CompleteShipmentDialog.defaultProps = {
  isOpen: false,
  completeShipment: (discrepancies) =>
    console.log(
      'The shipment is ready to be marked completed with the following discrepancies:',
      discrepancies
    ),
  closeDialog: () => console.log('The dialog was closed.'),
  shipmentSkus: [
    {
      childAsin: '',
      sku: 'FUT-RST-0101',
      tsin: 75308402,
      units: 2,
      product: 'Futurity Health - Reishi Tincture 50ml',
      costPrice: 86.25,
    },
  ],
};

CompleteShipmentDialog.propTypes = {
  isOpen: PropTypes.bool,
  completeShipment: PropTypes.func,
  closeDialog: PropTypes.func,
  shipmentSkus: PropTypes.arrayOf(
    PropTypes.shape({
      childAsin: PropTypes.string,
      sku: PropTypes.string,
      tsin: PropTypes.number,
      units: PropTypes.number,
      product: PropTypes.string,
      costPrice: PropTypes.number,
    })
  ),
};

export default CompleteShipmentDialog;
