/* eslint-disable no-nested-ternary */
import { CSVLink } from 'react-csv';
import { makeStyles } from '@material-ui/core/styles';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import GetAppIcon from '@material-ui/icons/GetApp';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';
import { fetchPresalesSuppliers, updateSku, fetchSkus } from './slice';
import allStyles from '../../../utils/styles';
import ContentWrapper from '../../../components/ContentWrapper';
import PageHeader from '../../../components/PageHeader';
import IncompletePresalesSKUs from './components/IncompletePresaleSKUs';
import CompletePresalesSKUs from '../../../components/CompletePresaleSKUs';
import SearchBar from '../../../components/SearchBar';
import {
  updateSubmission,
  updateCompletionStatus,
  createSkuCSV,
  getFilteredSkus,
  getFormattedSkus,
  getCompletedSkus,
  getIncompletedSkus,
} from './logic';
import theme from '../../../utils/theme';

const useStyles = makeStyles(allStyles);

const breadcrumbs = [
  {
    label: 'Submissions',
    link: '/presales/submissions',
  },
  {
    label: 'Add',
    link: '/presales/submissions/add',
  },
];

const SubmissionsAdd = () => {
  // General hooks
  const classes = useStyles();
  const history = useHistory();
  const csvLink = useRef();
  const dispatch = useDispatch();

  // State hooks
  const [isLoading, setIsLoading] = useState(false);
  const [supplierId, setSupplierId] = useState('N/A');
  const [toggleSkuStatus, setToggleSkuStatus] = useState('');
  const [skuSearchText, setSkuSearchText] = useState('');
  const [filteredSkus, setFilteredSkus] = useState([]);
  const [completedPresalesSkus, setCompletedPresalesSkus] = useState([]);
  const [incompletedPresalesSkus, setIncompletedPresalesSkus] = useState([]);
  const [selectedPresalesSkus, setSelectedPresalesSkus] = useState([]);
  const [activeStep, setActiveStep] = useState(0);

  const [validation, setValidation] = useState({
    isErrorShowing: false,
    message: '',
    errorStep: 0,
  });

  // Selectors
  const supplierLoadingStatus = useSelector(
    (state) => state.presalesSubmissionsAdd.loadingStatus.suppliers
  );

  const presalesSkus = useSelector(
    (state) => state.presalesSubmissionsAdd.presalesSkus
  );

  const suppliers = useSelector(
    (state) => state.presalesSubmissionsAdd.suppliers
  );

  const skusLoadingStatus = useSelector(
    (state) => state.presalesSubmissionsAdd.loadingStatus.presalesSkus
  );

  // Effects
  useEffect(() => {
    dispatch(fetchPresalesSuppliers());
    dispatch(fetchSkus());
  }, []);

  useEffect(() => {
    updateCompletionStatus(filteredSkus, updateSku, dispatch);
    setCompletedPresalesSkus(getCompletedSkus(filteredSkus));
    setIncompletedPresalesSkus(getIncompletedSkus(filteredSkus));
    setToggleSkuStatus('complete');
  }, [filteredSkus]);

  useEffect(() => {
    setFilteredSkus(getFilteredSkus(presalesSkus, skuSearchText, supplierId));
  }, [presalesSkus, skuSearchText, supplierId]);

  useEffect(() => {
    if (
      supplierLoadingStatus === 'PENDING' ||
      skusLoadingStatus === 'PENDING'
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [supplierLoadingStatus, skusLoadingStatus]);

  const onNextClick = () => {
    switch (activeStep) {
      case 0:
        if (
          selectedPresalesSkus.length === 0 ||
          toggleSkuStatus === 'incomplete'
        ) {
          setValidation({
            isErrorShowing: true,
            errorStep: 0,
            message: 'Please select at least one completed SKU.',
          });
        } else {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }

        break;
      case 1:
        setActiveStep((prevActiveStep) => prevActiveStep + 1);

        break;
      case 2:
        history.goBack();
        break;
      default:
        console.log('Unknown step');
        break;
    }
  };

  const handleToggleChange = (e, value) => {
    setToggleSkuStatus(value);
  };

  const displaySkuTable = () => {
    if (toggleSkuStatus === 'complete') {
      return (
        <CompletePresalesSKUs
          skus={getFormattedSkus(completedPresalesSkus, selectedPresalesSkus)}
          updateSelectedProducts={setSelectedPresalesSkus}
        />
      );
    }
    return (
      <IncompletePresalesSKUs
        skus={getFormattedSkus(incompletedPresalesSkus, selectedPresalesSkus)}
        updateSelectedProducts={setSelectedPresalesSkus}
      />
    );
  };
  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <div>
            <Typography variant="h4" gutterBottom>
              Select SKUs for Submission
            </Typography>
            <div className={classes.wrapperStep}>
              <Typography variant="body1">
                Please select the Presale Supplier of SKUs to submit.
              </Typography>
              <div className={classes.separator} />
              <FormControl className={classes.formControl}>
                <Select
                  labelId="presale-supplier-select-label"
                  id="presale-supplier-select"
                  required
                  value={supplierId}
                  onChange={(e) => {
                    setSupplierId(e.target.value);
                  }}
                >
                  <MenuItem value="N/A">Not Applicable</MenuItem>
                  {suppliers.map((supplierInfo) => (
                    <MenuItem
                      key={supplierInfo.id}
                      value={supplierInfo.id}
                      code={supplierInfo.code}
                    >
                      {supplierInfo.name}
                    </MenuItem>
                  ))}
                </Select>

                <div className={classes.separator} />
              </FormControl>
              <div className={classes.separator} />
              <Typography variant="body1">
                Please select all SKUs for submission.
              </Typography>
              <div>
                <Alert className={classes.alert} severity="warning">
                  Only SKUs with all completed information are shown.
                </Alert>
              </div>
              <div className={classes.separator} />
              <ToggleButtonGroup
                value={toggleSkuStatus}
                className={classes.toggleButtonsGroup}
                labelId="presale-sku-status-select-label"
                id="presale-sku-status-select"
                onChange={handleToggleChange}
                exclusive
              >
                <ToggleButton
                  className={theme.overrides.MuiToggleButton}
                  value="complete"
                >
                  Complete SKUs
                </ToggleButton>
                <ToggleButton
                  className={theme.overrides.MuiToggleButton}
                  value="incomplete"
                >
                  Incomplete SKUs
                </ToggleButton>
              </ToggleButtonGroup>
              <SearchBar
                searchText={skuSearchText}
                changeSearchText={setSkuSearchText}
                skuCount={filteredSkus.length}
              />
              {displaySkuTable()}
              <div className={classes.separator} />
              {validation.isErrorShowing && validation.errorStep === 0 && (
                <Alert className={classes.alert} severity="error">
                  {validation.message}
                </Alert>
              )}
              <div className={classes.separator} />
            </div>
          </div>
        );
      case 1:
        return (
          <div>
            <Typography variant="body1">
              Please download the CSV file below and check if all SKU
              information is correct.
            </Typography>

            <div className={classes.separator} />
            <Button
              startIcon={<GetAppIcon />}
              color="secondary"
              variant="outlined"
              fullWidth
              onClick={() => csvLink.current.link.click()}
            >
              Download SKU Submission Summary
            </Button>
            <CSVLink
              className={classes.hidden}
              ref={csvLink}
              filename="completed-sku-information.csv"
              data={createSkuCSV(selectedPresalesSkus)}
            >
              Download template
            </CSVLink>
            <div className={classes.separator} />
          </div>
        );
      case 2:
        return (
          <div>
            <Alert className={classes.alert} severity="success">
              All presales SKUs have been submitted.
            </Alert>

            {updateSubmission(selectedPresalesSkus, updateSku, dispatch)}
            <div className={classes.separator} />
          </div>
        );
      default:
        return 'Unknown stepIndex';
    }
  };

  return (
    <div>
      <PageHeader
        isLoading={isLoading}
        title="SKU Submissions"
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Submit SKU"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
      />
      <ContentWrapper>
        <Paper className={classes.wrapperWizard}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Stepper activeStep={activeStep} orientation="vertical">
                <Step>
                  <StepLabel>Select SKUs</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Download SKU Summary</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Confirm Submission </StepLabel>
                </Step>
              </Stepper>
            </Grid>
            <Grid item xs={9}>
              {getStepContent()}
              <div className={classes.actionsBar}>
                <Button
                  disabled={activeStep === 0 || isLoading}
                  onClick={() => setActiveStep(activeStep - 1)}
                >
                  Previous
                </Button>
                <Button
                  disabled={isLoading}
                  variant="contained"
                  color="secondary"
                  onClick={onNextClick}
                >
                  {activeStep === 0
                    ? 'Next'
                    : activeStep === 1
                    ? 'Submit'
                    : 'Done'}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </ContentWrapper>
    </div>
  );
};

export default SubmissionsAdd;
