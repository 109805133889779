import { connectHits, connectStateResults } from 'react-instantsearch-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Hit from './components/Hit';
import EmptyViewMessage from '../../../../../components/EmptyViewMessage';
import HitsPagination from '../../../../../components/HitsPagination';
import SearchStats from '../../../../../components/SearchStats';

const Results = connectStateResults(({ isSearchStalled, searchResults }) => {
  if (isSearchStalled) {
    return (
      <Grid item xs={12} container justifyContent="center">
        <CircularProgress color="secondary" />
      </Grid>
    );
  }

  if (searchResults.nbHits === 0) {
    return (
      <EmptyViewMessage
        heading="No SKUs found"
        message="Please adjust the filters or search query."
      />
    );
  }

  return <div />;
});

const Hits = ({ hits, updateDisabledStatus }) => {
  return (
    <Grid container spacing={3}>
      {hits.length > 0 && (
        <Grid item xs={12}>
          <Grid container spacing={1} justifyContent="center">
            <HitsPagination />
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        <SearchStats />
      </Grid>
      <Results />
      {hits.map((hit) => {
        return (
          <Grid key={hit.objectID} item xs={12}>
            <Hit
              title={hit.supplierDescription}
              buyboxOwner={hit['tailgating.buyBoxOwner']}
              eolSku={hit.eolSku}
              tsin={hit.tsin}
              supplier={hit.supplierName}
              status={hit.status}
              primaryImageUrl={hit.primaryImageLink}
              takealotListingLink={hit.takealotListingLink}
              listingPrice={hit['tailgating.sellingPrice']}
              bundledUnits={hit.bundledUnits}
              costPricePerUnit={hit['pricing.cost']}
              costPrice={hit['pricing.totalCost']}
              fulfilmentFee={hit['takealotFees.fulfilmentFeeExVat']}
              successFee={hit['takealotFees.successFeePercentageExVat']}
              handlingFee={hit['takealotFees.handlingFeeIncVat']}
              retailPrice={hit['pricing.retail']}
              manuallyDisabled={hit.manuallyDisabled}
              updateDisabledStatus={(newStatus) =>
                updateDisabledStatus(hit.eolSku, newStatus)
              }
            />
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <Grid container spacing={1} justifyContent="center">
          <HitsPagination />
        </Grid>
      </Grid>
    </Grid>
  );
};

Hits.defaultProps = {
  updateDisabledStatus: (eolSku, newStatus) =>
    console.log(
      `${eolSku} had its manually disabled status changed to ${newStatus}.`
    ),
  hits: [],
};

Hits.propTypes = {
  updateDisabledStatus: PropTypes.func,
  hits: PropTypes.arrayOf(
    PropTypes.shape({
      supplierDescription: PropTypes.string,
      objectID: PropTypes.string,
      status: PropTypes.string,
      eolSku: PropTypes.string,
      supplierName: PropTypes.string,
      primaryImageLink: PropTypes.string,
      takealotListingLink: PropTypes.string,
      manuallyDisabled: PropTypes.bool,
      'pricing.totalCost': PropTypes.number,
      'pricing.retail': PropTypes.number,
      'tailgating.buyBoxOwner': PropTypes.string,
      'tailgating.sellingPrice': PropTypes.number,
    })
  ),
};
export default connectHits(Hits);
