/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { updateTakealotProducts } from '../../../api/presalesAPI';

const initialState = {
  failedSkus: [],
  updateLoadingCount: {
    total: 0,
    success: 0,
    failed: 0,
  },
  feedback: {
    isOpen: false,
    type: undefined,
    message: '',
  },
};

const presalesProductsTakealotUpdateSlice = createSlice({
  name: 'presalesProductsTakealotUpdate',
  initialState,
  reducers: {
    updateSuccess(state) {
      state.updateLoadingCount.success += 1;
    },
    updateFailed(state, action) {
      state.updateLoadingCount.failed += 1;
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    skusToUpdate(state, action) {
      state.failedSkus = [];
      state.updateLoadingCount.total = action.payload;
      state.updateLoadingCount.success = 0;
      state.updateLoadingCount.failed = 0;
    },
    resetState() {
      return initialState;
    },
  },
});

export const { updateSuccess, updateFailed, resetState, skusToUpdate } =
  presalesProductsTakealotUpdateSlice.actions;

export default presalesProductsTakealotUpdateSlice.reducer;

export const updateInfo = (reportDataBatch) => async (dispatch) => {
  const result = await updateTakealotProducts(reportDataBatch);

  if (result.status === 200) {
    dispatch(updateSuccess());
  } else {
    dispatch(updateFailed({ message: result.error }));
  }
};

export const setTotalSkusToUpdate = (total) => (dispatch) => {
  dispatch(skusToUpdate(total));
};

export const initiateStateReset = () => async (dispatch) => {
  dispatch(resetState());
};
