import { green, red, yellow } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import {
  changeClientStatus,
  deleteClientDocument,
  fetchClientDocuments,
  fetchClientInfo,
} from './slice';
import BillingInfoCard from './components/BillingInfoCard';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import ContentWrapper from '../../../components/ContentWrapper';
import DocumentsTab from '../../../components/DocumentsTab';
import EmptyViewMessage from '../../../components/EmptyViewMessage';
import LogisticsCard from './components/LogisticsCard';
import MiscellaneousCard from './components/MiscellaneousCard';
import PageHeader from '../../../components/PageHeader';
import UnderConstruction from '../../../components/UnderConstruction';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.common.black,
  },
  loadingWrapper: {
    padding: theme.spacing(4),
    textAlign: 'center',
  },
  pdfButtonInner: {
    display: 'flex',
    alignItems: 'center',
  },
  pdfButtonOuter: {
    color: theme.palette.common.white,
    textDecoration: 'none',
  },
  seperator: {
    height: theme.spacing(3),
  },
  seperatorButtons: {
    height: theme.spacing(1),
  },
  statusActive: {
    width: '100%',
    color: theme.palette.common.black,
    backgroundColor: green[500],
  },
  statusTradeOut: {
    width: '100%',
    color: theme.palette.common.black,
    backgroundColor: yellow[500],
  },
  statusTerminated: {
    width: '100%',
    color: theme.palette.common.black,
    backgroundColor: red[500],
  },
  statusWrapper: {
    width: '100%',
    textAlign: 'center',
    padding: theme.spacing(2),
  },
  tabs: {
    marginBottom: theme.spacing(4),
  },
  totalsWrapper: {
    padding: theme.spacing(4),
  },
}));

const breadcrumbs = [
  {
    label: 'Customer Relationship Management',
    link: '/crm',
  },
  {
    label: 'Clients',
    link: '/crm/clients',
  },
  {
    label: 'Browse',
    link: '/crm/clients/browse',
  },
];

const ClientsView = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { clientId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('DOCUMENTS');
  const [isTerminationDialogOpen, setIsTerminationDialogOpen] = useState(false);
  const [isTradeOutDialogOpen, setIsTradeOutDialogOpen] = useState(false);

  const clientInfo = useSelector((state) => state.clientsView.clientInfo);
  const documents = useSelector((state) => state.clientsView.documents);
  const clientInfoLoadingStatus = useSelector(
    (state) => state.clientsView.loadingStatus.clientInfo
  );
  const clientStatusLoadingStatus = useSelector(
    (state) => state.clientsView.loadingStatus.clientStatus
  );
  const documentLoadingStatus = useSelector(
    (state) => state.clientsView.loadingStatus.documents
  );
  const [isDeleteDocumentDialogOpen, setIsDeleteDocumentDialogOpen] =
    useState(false);
  const [documentToBeDeleted, setDocumentToBeDeleted] = useState({
    documentPath: '',
    documentId: '',
  });
  const deleteDocumentLoadingStatus = useSelector(
    (state) => state.clientsView.loadingStatus.deleteImage
  );
  useEffect(() => {
    dispatch(fetchClientInfo(clientId));
    dispatch(fetchClientDocuments(clientId));
  }, []);

  useEffect(() => {
    if (
      clientInfoLoadingStatus === 'PENDING' ||
      clientStatusLoadingStatus === 'PENDING' ||
      deleteDocumentLoadingStatus === 'PENDING' ||
      documentLoadingStatus === 'PENDING'
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [
    clientInfoLoadingStatus,
    clientStatusLoadingStatus,
    deleteDocumentLoadingStatus,
    documentLoadingStatus,
  ]);

  const getStatusChip = (status) => {
    switch (status) {
      case 'TRADE_OUT':
        return (
          <Chip
            icon={<MeetingRoomIcon className={classes.icon} />}
            label="Trade Out"
            className={classes.statusTradeOut}
          />
        );
      case 'TERMINATED':
        return (
          <Chip
            icon={<ClearIcon className={classes.icon} />}
            label="Terminated"
            className={classes.statusTerminated}
          />
        );
      default:
        return (
          <Chip
            icon={<DoneIcon className={classes.icon} />}
            label="Active"
            className={classes.statusActive}
          />
        );
    }
  };

  return (
    <div>
      <PageHeader
        title="Client Details"
        subtitle={isLoading ? 'Loading...' : clientInfo.name}
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Client"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
      />
      {isLoading && <LinearProgress color="secondary" />}
      <ContentWrapper>
        {!isLoading && clientInfo.id === 'Loading' ? (
          <EmptyViewMessage
            heading="Info Failed to Load"
            message="Please check your internet connection and reload the page."
          />
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <div className={classes.statusWrapper}>
                {getStatusChip(clientInfo.status)}
              </div>
              <div>
                <Button
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  startIcon={<EditIcon />}
                  onClick={() => history.push(`/crm/clients/edit/${clientId}`)}
                >
                  Edit details
                </Button>
                <div className={classes.seperatorButtons} />
                {clientInfo.status === 'HIGH_PRIORITY' && (
                  <>
                    <Button
                      fullWidth
                      variant="outlined"
                      color="secondary"
                      startIcon={<MeetingRoomIcon />}
                      onClick={() => setIsTradeOutDialogOpen(true)}
                    >
                      Client to trade out
                    </Button>
                    <div className={classes.seperatorButtons} />
                  </>
                )}
                {clientInfo.status !== 'TERMINATED' && (
                  <>
                    <Button
                      fullWidth
                      variant="outlined"
                      color="secondary"
                      startIcon={<ClearIcon />}
                      onClick={() => setIsTerminationDialogOpen(true)}
                    >
                      Terminate client
                    </Button>
                    <div className={classes.seperatorButtons} />
                  </>
                )}
                <>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="secondary"
                    startIcon={<NoteAddIcon />}
                    onClick={() =>
                      history.push(`/crm/clients/upload-documents/${clientId}`)
                    }
                  >
                    Upload Documents
                  </Button>
                  <div className={classes.seperator} />
                </>
              </div>
              <BillingInfoCard
                billingInfo={clientInfo.billingInfo}
                isLoading={clientInfoLoadingStatus === 'PENDING'}
                onMissingInfoClick={() =>
                  history.push(`/crm/clients/edit/${clientId}`)
                }
              />
              <div className={classes.seperator} />
              <LogisticsCard
                isLoading={clientInfoLoadingStatus === 'PENDING'}
                onMissingInfoClick={() =>
                  history.push(`/crm/clients/edit/${clientId}`)
                }
                leadTime={clientInfo.leadTime}
              />
              <div className={classes.seperator} />
              <MiscellaneousCard
                isLoading={clientInfoLoadingStatus === 'PENDING'}
                onMissingInfoClick={() =>
                  history.push(`/crm/clients/edit/${clientId}`)
                }
                clientInfo={clientInfo}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={9}>
              {!isLoading && (
                <div>
                  <AppBar position="static" className={classes.tabs}>
                    <Tabs
                      value={activeTab}
                      onChange={(e, newValue) => setActiveTab(newValue)}
                      aria-label="client tabs"
                    >
                      <Tab label="Documents" value="DOCUMENTS" />
                    </Tabs>
                  </AppBar>
                  {activeTab === 'DOCUMENTS' ? (
                    <DocumentsTab
                      documents={documents}
                      deleteDocument={(documentInfo) => {
                        setIsDeleteDocumentDialogOpen(true);
                        setDocumentToBeDeleted({
                          documentPath: documentInfo.reference,
                          documentId: documentInfo.id,
                        });
                      }}
                    />
                  ) : (
                    <UnderConstruction />
                  )}
                </div>
              )}
            </Grid>
          </Grid>
        )}
      </ContentWrapper>
      <ConfirmationDialog
        isOpen={isTerminationDialogOpen}
        message="Once the client is marked terminated, they will no longer be considered a client within the EasyOnline system."
        closeDialog={() => setIsTerminationDialogOpen(false)}
        confirmAction={() =>
          dispatch(changeClientStatus(clientId, 'TERMINATED'))
        }
      />
      <ConfirmationDialog
        isOpen={isTradeOutDialogOpen}
        message="Once a client is set to trade out, they will only be contacted with monthly statements of sales. Once their products no longer have stock, they will be considered terminated."
        closeDialog={() => setIsTradeOutDialogOpen(false)}
        confirmAction={() =>
          dispatch(changeClientStatus(clientId, 'TRADE_OUT'))
        }
      />
      <ConfirmationDialog
        isOpen={isDeleteDocumentDialogOpen}
        message="Are you sure you want to delete this document?"
        closeDialog={() => {
          setIsDeleteDocumentDialogOpen(false);
          setDocumentToBeDeleted({
            documentPath: '',
            documentId: '',
          });
        }}
        confirmAction={() =>
          dispatch(
            deleteClientDocument(
              clientId,
              documentToBeDeleted.documentPath,
              documentToBeDeleted.documentId
            )
          )
        }
      />
    </div>
  );
};

export default ClientsView;
