import { CSVLink } from 'react-csv';
import { CSVReader } from 'react-papaparse';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import GetAppIcon from '@material-ui/icons/GetApp';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import numeral from 'numeral';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {
  bulkStockUpdate,
  fetchPresalesSuppliers,
  setTotalSkusToUpdate,
} from './slice';
import allStyles from '../../../utils/styles';
import ContentWrapper from '../../../components/ContentWrapper';
import PageHeader from '../../../components/PageHeader';

const SKU_BUNDLE_SIZES = [1, 2, 3, 4, 5, 6, 8, 10];
const AVERAGE_EOL_MARGIN = 0.2;
const AVERAGE_SUCCESS_FEE = 0.12;
const AVERAGE_HANDLING_FEE = 15;
const AVERAGE_FULFILMENT_FEE = 15;

const useStyles = makeStyles(allStyles);

const breadcrumbs = [
  {
    label: 'Pipeline',
    link: '/presales/pipeline',
  },
  {
    label: 'Browse',
    link: '/presales/pipeline/view',
  },
];

const SkuBulkUpload = () => {
  const classes = useStyles();
  const history = useHistory();
  const fileUploadRef = useRef();
  const csvLink = useRef();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [supplierId, setSupplierId] = useState('N/A');
  const [supplierName, setSupplierName] = useState('Not Applicable');
  const [supplierCode, setSupplierCode] = useState('EOL');
  const [minBundlePrice, setMinBundlePrice] = useState(80);
  const [maxBundlePrice, setMaxBundlePrice] = useState(1000);
  const [validation, setValidation] = useState({
    isErrorShowing: false,
    message: '',
    errorStep: 0,
  });
  const [activeStep, setActiveStep] = useState(0);
  const [reportData, setReportData] = useState([]);
  const [uploadError, setUploadError] = useState({
    isError: false,
    message: '',
  });

  const supplierLoadingStatus = useSelector(
    (state) => state.presalesSkuUpload.loadingStatus.suppliers
  );
  const uploadLoadingCountTotal = useSelector(
    (state) => state.presalesSkuUpload.bulkStockUploadLoadingCount.total
  );
  const uploadLoadingCountFailed = useSelector(
    (state) => state.presalesSkuUpload.bulkStockUploadLoadingCount.failed
  );
  const uploadLoadingCountSuccess = useSelector(
    (state) => state.presalesSkuUpload.bulkStockUploadLoadingCount.success
  );
  const suppliers = useSelector((state) => state.presalesSkuUpload.suppliers);

  useEffect(() => {
    dispatch(fetchPresalesSuppliers());
  }, []);

  useEffect(() => {
    setValidation({
      isErrorShowing: false,
      message: '',
      errorStep: 0,
    });
    setUploadError({
      isError: false,
      message: '',
    });
  }, [reportData]);

  useEffect(() => {
    if (supplierLoadingStatus === 'PENDING') {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [supplierLoadingStatus]);

  useEffect(() => {
    if (fileUploadRef.current) {
      fileUploadRef.current.removeFile();
    }
  }, [activeStep]);

  useEffect(() => {
    if (activeStep !== 1) return;

    if (minBundlePrice >= maxBundlePrice) {
      setValidation({
        isErrorShowing: true,
        errorStep: 1,
        message: 'Min bundle price must be less than max bundle price',
      });
    } else {
      setValidation({
        isErrorShowing: false,
        errorStep: 0,
        message: '',
      });
    }
  }, [minBundlePrice, maxBundlePrice]);

  const checkFields = (fields, expectedFields) => {
    let isCorrect = true;
    fields.forEach((field, index) => {
      if (field !== expectedFields[index]) {
        isCorrect = false;
      }
    });
    return isCorrect;
  };

  console.log(reportData);

  const getLoadingValue = () => {
    if (uploadLoadingCountTotal === 0) {
      return -1;
    }
    return Math.round(
      ((uploadLoadingCountSuccess + uploadLoadingCountFailed) /
        uploadLoadingCountTotal) *
        100
    );
  };

  const skuLoadingValue = getLoadingValue();

  const formatSkuUpload = (sku, bundledUnits) => {
    const formattedBundleSku = `000${bundledUnits * sku.moq}`.slice(-4);

    return {
      ...sku,
      bundledUnits: bundledUnits * sku.moq,
      supplierDescription: `${sku.supplierDescription}${
        bundledUnits * sku.moq === 1 ? '' : ` - ${bundledUnits * sku.moq} Pack`
      }`,
      eolSku: `${supplierCode}-${sku.supplierSku}-${formattedBundleSku}`,
      supplierId,
      supplierName,
      barcode: null,
      copy: {
        title: null,
        subtitle: null,
        description: null,
        whatsInTheBox: null,
      },
      primaryImageLink: null,
      productInfoLink: null,
      categories: {
        division: null,
        department: null,
        mainCategory: null,
        lowestCategory: null,
      },
      measurements: {
        width: null,
        length: null,
        height: null,
        weight: null,
        longestSide: null,
      },
      pricing: {
        cost: sku.pricing.cost,
        retail: Number(sku.pricing.retail * bundledUnits),
        totalCost: Number(sku.pricing.cost * bundledUnits * sku.moq),
        margin: AVERAGE_EOL_MARGIN,
      },
      stock: {
        reflected: 0,
        available: 0,
      },
      status: 'MISSING_INFO',
      manuallyDisabled: false,
      submissionDate: null,
      approvalDate: null,
      takealotListingLink: null,
      onboardingStatuses: {
        measurementsComplete: false,
        copyComplete: false,
        categoriesComplete: false,
        imagesComplete: false,
        barcodesComplete: false,
      },
    };
  };

  const isBelowRecommendedMinPrice = () => {
    return minBundlePrice < 80;
  };

  const generateFormattedSkus = () => {
    const skusToUpdate = [];

    reportData.forEach((info) => {
      const skus = SKU_BUNDLE_SIZES.filter((bundledUnits) => {
        const price = info.pricing.retail * bundledUnits;

        if (bundledUnits === 1) {
          return price >= minBundlePrice;
        }
        return price >= minBundlePrice && price <= maxBundlePrice;
      }).map((bundledUnits) => formatSkuUpload(info, bundledUnits));

      skusToUpdate.push(...skus);
    });

    return skusToUpdate;
  };

  const getTotalSupplierSkus = () => {
    return reportData.length;
  };

  const getTotalPotentialSkus = () => {
    let totalCount = 0;

    reportData.forEach((info) => {
      const bundles = SKU_BUNDLE_SIZES.filter((bundledUnits) => {
        const price = info.pricing.retail * bundledUnits;

        if (bundledUnits === 1) {
          return price >= minBundlePrice;
        }
        return price >= minBundlePrice && price <= maxBundlePrice;
      });

      totalCount += bundles.length;
    });

    return totalCount;
  };

  const getRetailPrice = (costPrice, moq) => {
    return (
      (costPrice * moq + AVERAGE_HANDLING_FEE + AVERAGE_FULFILMENT_FEE * 1.15) /
      (1 - AVERAGE_EOL_MARGIN - AVERAGE_SUCCESS_FEE * 1.15)
    );
  };

  const formatSkuUploads = (data) => {
    const expectedFields = [
      'Supplier SKU',
      'Brand',
      'Supplier Description',
      'Cost Price',
      'MOQ',
    ];

    const fields = data[0].data;
    const isFieldsCorrect = checkFields(fields, expectedFields);

    if (isFieldsCorrect) {
      const formattedData = data
        .filter(
          (lineItem, index) =>
            index < data.length && index > 0 && lineItem.data[0] !== ''
        )
        .map((lineItem) => {
          const unformattedData = lineItem.data;

          return {
            supplierSku: unformattedData[0],
            brand: unformattedData[1] === '' ? null : unformattedData[1],
            supplierDescription: unformattedData[2],
            pricing: {
              cost: numeral(unformattedData[3]).value(),
              retail: getRetailPrice(
                numeral(unformattedData[3]).value(),
                numeral(unformattedData[4]).value()
              ),
            },
            moq: numeral(unformattedData[4]).value(),
          };
        });

      setReportData(formattedData);
    } else {
      setUploadError({
        isError: true,
        message:
          'Incorrect report uploaded. Please upload a valid presales SKU file.',
      });
    }
  };

  const handleFileLoad = (data) => {
    formatSkuUploads(data);
  };

  const handleError = (err) => {
    console.log(err);
  };

  const onNextClick = () => {
    switch (activeStep) {
      case 0:
        if (reportData.length <= 0) {
          setUploadError({
            isError: true,
            message: 'File uploaded does not contain any SKU information.',
          });
        } else if (supplierId === '' || supplierId === 'N/A') {
          setUploadError({
            isError: true,
            message: 'Please select a supplier.',
          });
        } else {
          setUploadError({
            isError: false,
            message: '',
          });

          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        break;
      case 1:
        if (minBundlePrice >= maxBundlePrice) {
          setValidation({
            isErrorShowing: true,
            message: 'Max. bundle price must be larger than min. bundle price.',
            errorStep: 0,
          });
        } else {
          setUploadError({
            isError: false,
            message: '',
          });

          const skusToUpdate = generateFormattedSkus();
          dispatch(setTotalSkusToUpdate(skusToUpdate.length));
          skusToUpdate.forEach((info) => dispatch(bulkStockUpdate(info)));
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        break;
      case 2:
        history.push('/presales/pipeline/view');
        break;
      default:
        console.log('Unknown step');
        break;
    }
  };

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <div>
            <Typography variant="h4" gutterBottom>
              Upload SKU Bulk File
            </Typography>
            <div className={classes.wrapperStep}>
              <Typography variant="body1">
                Please select the supplier:
              </Typography>
              <div className={classes.separator} />
              <FormControl className={classes.formControl}>
                <InputLabel shrink required id="presale-supplier-select-label">
                  Suppliers
                </InputLabel>
                <Select
                  labelId="presale-supplier-select-label"
                  id="presale-supplier-select"
                  required
                  value={supplierId}
                  onChange={(e, newValue) => {
                    setSupplierId(e.target.value);
                    setSupplierName(newValue.props.children);

                    if (newValue.props.code) {
                      setSupplierCode(newValue.props.code);
                    }
                  }}
                >
                  <MenuItem value="N/A">Not Applicable</MenuItem>
                  {suppliers.map((supplierInfo) => (
                    <MenuItem
                      key={supplierInfo.id}
                      value={supplierInfo.id}
                      code={supplierInfo.code}
                    >
                      {supplierInfo.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className={classes.separator} />
              <Typography variant="body1">
                Please download the template file and add details for presales
                SKUs:
              </Typography>
              <div className={classes.separator} />
              <Button
                startIcon={<GetAppIcon />}
                color="secondary"
                variant="outlined"
                fullWidth
                onClick={() => csvLink.current.link.click()}
              >
                Download template
              </Button>
              <CSVLink
                className={classes.hidden}
                ref={csvLink}
                filename="new-SKU-bulk-upload.csv"
                data={[
                  [
                    'Supplier SKU',
                    'Brand',
                    'Supplier Description',
                    'Cost Price',
                    'MOQ',
                  ],
                ]}
              >
                Download template
              </CSVLink>
              <div className={classes.separator} />
              <CSVReader
                ref={fileUploadRef}
                onDrop={handleFileLoad}
                onError={handleError}
                addRemoveButton
              >
                <span>Drop completed template here or click to upload.</span>
              </CSVReader>
              <div className={classes.separator} />
              {uploadError.isErrorShowing && uploadError.errorStep === 0 && (
                <Alert className={classes.alert} severity="error">
                  {uploadError.message}
                </Alert>
              )}
              {uploadError.isError && (
                <Alert className={classes.alert} severity="error">
                  {uploadError.message}
                </Alert>
              )}
            </div>
          </div>
        );
      case 1:
        return (
          <div>
            <Typography variant="h4" gutterBottom>
              Select Price Breakpoints
            </Typography>
            <div className={classes.wrapperStep}>
              <Typography variant="body1">
                Please set the desired minimum and maximum prices for SKU
                bundles:
              </Typography>
              <div className={classes.separator} />
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    required
                    fullWidth
                    label="Minimum Price"
                    placeholder="eg. R 80"
                    type="number"
                    value={minBundlePrice}
                    onChange={(e) =>
                      setMinBundlePrice(parseFloat(e.target.value))
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required
                    fullWidth
                    label="Maximum Price"
                    placeholder="eg. R 1,000"
                    type="number"
                    value={maxBundlePrice}
                    onChange={(e) =>
                      setMaxBundlePrice(parseFloat(e.target.value))
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
              <div className={classes.separator} />
              {validation.isErrorShowing && validation.errorStep === 1 && (
                <Alert className={classes.alert} severity="error">
                  {validation.message}
                </Alert>
              )}
              {isBelowRecommendedMinPrice() && (
                <Alert className={classes.alert} severity="warning">
                  This value may not account for Takealot fees.
                </Alert>
              )}
              <div className={classes.separator} />
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    {`Total Supplier SKUs: ${numeral(
                      getTotalSupplierSkus()
                    ).format('0,0')}`}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="body1">
                    {`Total Potential SKUs: ${numeral(
                      getTotalPotentialSkus()
                    ).format('0,0')}`}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </div>
        );
      case 2:
        return (
          <div>
            {skuLoadingValue < 100 && skuLoadingValue !== -1 && (
              <div>
                <LinearProgress
                  color="secondary"
                  className={classes.loadingIndicator}
                  variant="determinate"
                  value={skuLoadingValue}
                />
                <Typography component="p" variant="subtitle1" align="center">
                  Creating new SKUs...
                </Typography>
              </div>
            )}
            {skuLoadingValue >= 100 && uploadLoadingCountFailed === 0 && (
              <div>
                <Alert className={classes.alert} severity="success">
                  All new presales SKUs have been created.
                </Alert>
              </div>
            )}
            {skuLoadingValue >= 100 && uploadLoadingCountFailed > 0 && (
              <div>
                <Alert className={classes.alert} severity="warning">
                  {`SKU bundles were created for some SKUs, but ${uploadLoadingCountFailed} SKU/s failed to update.`}
                </Alert>
              </div>
            )}
            <div className={classes.separator} />
          </div>
        );
      default:
        return 'Unknown stepIndex';
    }
  };

  return (
    <div>
      <PageHeader
        isLoading={isLoading}
        title="SKU Bulk Upload"
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Bulk Upload"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
      />
      <ContentWrapper>
        <Paper className={classes.wrapperWizard}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Stepper activeStep={activeStep} orientation="vertical">
                <Step>
                  <StepLabel>Complete Template</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Select Pricing Breakpoints</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Upload</StepLabel>
                </Step>
              </Stepper>
            </Grid>
            <Grid item xs={9}>
              {getStepContent()}
              <div className={classes.actionsBar}>
                <Button
                  disabled={activeStep === 0 || isLoading || activeStep === 2}
                  onClick={() => setActiveStep(activeStep - 1)}
                >
                  Previous
                </Button>
                <Button
                  disabled={
                    isLoading ||
                    (skuLoadingValue < 100 && skuLoadingValue !== -1)
                  }
                  variant="contained"
                  color="secondary"
                  onClick={onNextClick}
                >
                  {activeStep !== 2 ? 'Next' : 'Done'}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </ContentWrapper>
    </div>
  );
};

export default SkuBulkUpload;
