import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import ClearIcon from '@material-ui/icons/Clear';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  actionBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formControl: {
    flexGrow: 1,
  },
  media: {
    backgroundSize: 'contain',
    height: 180,
    margin: theme.spacing(4),
  },
  root: {
    backgroundColor: theme.palette.common.white,
  },
  select: {
    icon: {
      color: theme.palette.common.black,
    },
  },
  separator: {
    width: theme.spacing(1),
  },
  imageName: {
    color: theme.palette.common.black,
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
  },
}));

const ImagePreviewCard = ({
  tag,
  setTag,
  imageLink,
  removeImage,
  isLoading,
  localImageName,
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <Typography variant="body1" gutterBottom className={classes.imageName}>
        {localImageName}
      </Typography>
      <CardMedia className={classes.media} image={imageLink} />
      <CardActions className={classes.actionBar}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          size="small"
          disabled={isLoading}
        >
          <InputLabel id="tag-select-outlined-label">Tag</InputLabel>
          <Select
            labelId="tag-select-outlined-label"
            id="tag-select-outlined"
            value={tag}
            onChange={(e) => setTag(e.target.value)}
            label="Tag"
            className={classes.select}
          >
            <MenuItem value="STOCK">Stock</MenuItem>
            <MenuItem value="RAW">RAW</MenuItem>
            <MenuItem value="PRODUCT">Product</MenuItem>
            <MenuItem value="LIFESTYLE">Lifestyle</MenuItem>
            <MenuItem value="INFOGRAPHIC">Infographic</MenuItem>
            <MenuItem value="OTHER">Other</MenuItem>
          </Select>
        </FormControl>
        <div className={classes.separator} />
        <Button
          onClick={removeImage}
          startIcon={<ClearIcon />}
          size="small"
          color="primary"
          disabled={isLoading}
        >
          Remove
        </Button>
      </CardActions>
    </Card>
  );
};

ImagePreviewCard.defaultProps = {
  tag: 'STOCK',
  setTag: (newTag) => console.log(`Tag was updated to ${newTag}`),
  imageLink: '',
  removeImage: () => console.log('Image was removed.'),
  isLoading: false,
  localImageName: '',
};

ImagePreviewCard.propTypes = {
  tag: PropTypes.oneOf([
    'STOCK',
    'PRODUCT',
    'RAW',
    'INFOGRAPHIC',
    'LIFESTYLE',
    'OTHER',
  ]),
  setTag: PropTypes.func,
  imageLink: PropTypes.string,
  removeImage: PropTypes.func,
  isLoading: PropTypes.bool,
  localImageName: PropTypes.string,
};

export default ImagePreviewCard;
