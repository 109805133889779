import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import EmptyViewMessage from '../../../../../components/EmptyViewMessage';
import SkusTable from './components/SkusTable';
import FiltersBar from '../FiltersBar';

const useStyles = makeStyles((theme) => ({
  seperator: {
    height: theme.spacing(3),
  },
}));

const DiscrepanciesTab = ({
  discrepancies,
  platform,
  shipmentStatus,
  discrepanciesSearchText,
  setDiscrepanciesSearch,
}) => {
  const classes = useStyles();
  const history = useHistory();

  if (shipmentStatus !== 'COMPLETE') {
    return (
      <div className={classes.root}>
        <EmptyViewMessage
          heading="Shipment Not Complete"
          message="Discrepancies are only logged once a shipment is marked complete."
        />
      </div>
    );
  }

  return (
    <div>
      <FiltersBar
        searchText={discrepanciesSearchText}
        changeSearchText={setDiscrepanciesSearch}
        skuCount={discrepancies.length}
      />
      {discrepancies.length === 0 ? (
        <div className={classes.root}>
          <EmptyViewMessage
            heading="No Discrepancies"
            message="All SKUs in shipment arrived at the destination with the expected stock."
          />
        </div>
      ) : (
        <SkusTable
          platform={platform}
          skus={discrepancies.map((info) => {
            let unfrozenSku = {};
            unfrozenSku = {
              sku: info.sku,
              tsin: info.tsin,
              childAsin: info.childAsin,
              product: info.product,
              expectedUnits: info.expectedUnits,
              receivedUnits: info.receivedUnits,
            };
            return unfrozenSku;
          })}
          viewSku={(sku) => history.push(`/listings/basic-info/browse/${sku}`)}
        />
      )}
    </div>
  );
};

DiscrepanciesTab.defaultProps = {
  discrepancies: [
    {
      sku: 'ABC-123-UNME',
      tsin: 123456,
      childAsin: 'R2D2C3PO',
      product: 'Nike - Yeezy Boost - M',
      expectedUnits: 10,
      receivedUnits: 8,
    },
  ],
  platform: 'TAKEALOT',
  shipmentStatus: 'COMPLETE',
  discrepanciesSearchText: '',
  setDiscrepanciesSearch: () => {},
};

DiscrepanciesTab.propTypes = {
  discrepancies: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string,
      tsin: PropTypes.number,
      childAsin: PropTypes.string,
      product: PropTypes.string,
      expectedUnits: PropTypes.number,
      receivedUnits: PropTypes.number,
    })
  ),
  platform: PropTypes.oneOf(['AMAZON', 'TAKEALOT', 'BOTH']),
  shipmentStatus: PropTypes.string,
  discrepanciesSearchText: PropTypes.string,
  setDiscrepanciesSearch: PropTypes.func,
};

export default DiscrepanciesTab;
