import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  forgotPasswordLink: {
    color: grey[700],
    textTransform: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  headingMain: {
    color: theme.palette.common.white,
    textAlign: 'center',
  },
  headingSubtitle: {
    color: grey[700],
  },
  wrapperFields: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginBottom: theme.spacing(1),
    },
  },
  wrapperForgotPassword: {
    textAlign: 'right',
  },
  wrapperForm: {
    height: '66%',
    maxWidth: 480,
    padding: `${theme.spacing(2)}px ${theme.spacing(8)}px`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& > *': {
      margin: `${theme.spacing(2)}px 0`,
    },
  },
  wrapperHeadings: {
    '& > *': {
      marginBottom: theme.spacing(1),
    },
  },
}));

const LoginForm = ({ isLoading, goToForgotPassword, logIn }) => {
  const classes = useStyles();
  const [mounted, setMounted] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
    };
  }, []);

  return (
    <Slide direction="left" in={mounted} onExited={goToForgotPassword}>
      <form
        className={classes.wrapperForm}
        onSubmit={(e) => {
          e.preventDefault();
          logIn(email, password);
          setEmail('');
          setPassword('');
        }}
        noValidate
      >
        <div className={classes.wrapperHeadings}>
          <Typography
            variant="h3"
            component="h2"
            className={classes.headingMain}
          >
            Welcome
          </Typography>
          <Typography
            variant="caption"
            component="h3"
            className={classes.headingSubtitle}
          >
            If you are an employee at EasyOnline, enter your details below to
            log in.
          </Typography>
        </div>
        <div className={classes.wrapperFields}>
          <TextField
            autoComplete="username"
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            autoComplete="current-password"
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className={classes.wrapperForgotPassword}>
            <Button
              className={classes.forgotPasswordLink}
              onClick={() => setMounted(false)}
            >
              Forgot password?
            </Button>
          </div>
        </div>
        <Button
          disabled={isLoading}
          type="submit"
          variant="contained"
          color="secondary"
          size="large"
          fullWidth
        >
          {isLoading ? <CircularProgress size={28} /> : 'Login'}
        </Button>
      </form>
    </Slide>
  );
};

LoginForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  goToForgotPassword: PropTypes.func.isRequired,
  logIn: PropTypes.func.isRequired,
};

export default LoginForm;
