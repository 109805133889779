import _ from 'lodash';

const getFormattedRequests = (
  stockRequests,
  searchQuery = '',
  statusFilter = 'ALL'
) => {
  return stockRequests
    .map((info) => {
      return {
        id: info.id,
        fromId: info.fromId,
        fromName: info.fromName,
        toId: info.toId,
        toName: info.toName,
        units: info.totalUnits,
        status: info.status,
        clientName: info.clientName,
        creationDate: info.creationDate || '-',
        isFlaggedWithAdjustments:
          info.status !== 'COMPLETE' &&
          info.status !== 'CANCELLED' &&
          info.adjustments &&
          info.adjustments.length > 0,
      };
    })
    .filter((info) => {
      if (searchQuery !== '') {
        const caseInsensitiveSearch = _.toLower(searchQuery);
        const caseInsensitiveFromName = _.toLower(info.fromName);
        const caseInsensitiveToName = _.toLower(info.toName);
        const caseInsensitiveClientName = _.toLower(info.clientName);

        if (caseInsensitiveFromName.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveToName.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveClientName.includes(caseInsensitiveSearch)) {
          return true;
        }

        return false;
      }

      return true;
    })
    .filter((info) => {
      if (statusFilter !== 'ALL') {
        return info.status === statusFilter;
      }
      return true;
    })
    .sort((a, b) => {
      if (a.creationDate < b.creationDate) return +1;
      if (a.creationDate > b.creationDate) return -1;
      if (a.clientName < b.clientName) return -1;
      if (a.clientName > b.clientName) return +1;
      return 0;
    });
};

export default getFormattedRequests;
