import { useState } from 'react';
import Button from '@material-ui/core/Button';
import ImageIcon from '@material-ui/icons/Image';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';

const ImagesSelector = ({ imagesFilter, changeImagesFilter }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const getPrettyFilter = (filter) => {
    switch (filter) {
      case 'ALL':
        return 'All';
      case 'NOT_STARTED':
        return 'Not Started';
      case 'AWAITING_CLIENT_IMAGES':
        return 'Awaiting Client Images';
      case 'SHOOTING':
        return 'Shooting';
      case 'EDITING':
        return 'Editing';
      case 'COMPLETED':
        return 'Completed';
      default:
        return 'Custom';
    }
  };

  return (
    <>
      <Button
        aria-controls="images-menu"
        aria-haspopup="true"
        size="large"
        startIcon={<ImageIcon />}
        onClick={openMenu}
      >
        {getPrettyFilter(imagesFilter)}
      </Button>
      <Menu
        id="images-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem
          onClick={() => {
            changeImagesFilter('ALL');
            closeMenu();
          }}
        >
          All Statuses
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeImagesFilter('NOT_STARTED');
            closeMenu();
          }}
        >
          Not Started
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeImagesFilter('AWAITING_CLIENT_IMAGES');
            closeMenu();
          }}
        >
          Awaiting Client Images
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeImagesFilter('SHOOTING');
            closeMenu();
          }}
        >
          Shooting
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeImagesFilter('EDITING');
            closeMenu();
          }}
        >
          Editing
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeImagesFilter('COMPLETED');
            closeMenu();
          }}
        >
          Completed
        </MenuItem>
      </Menu>
    </>
  );
};

ImagesSelector.defaultProps = {
  imagesFilter: 'ALL',
  changeImagesFilter: (newFilter) =>
    console.log('User changes the images filter to:', newFilter),
};

ImagesSelector.propTypes = {
  imagesFilter: PropTypes.string,
  changeImagesFilter: PropTypes.func,
};

export default ImagesSelector;
