/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  getShipment,
  getDocuments,
  deleteDocument,
  saveShipmentAdjustments,
} from '../../../api/logisticsAPI';

const initialState = {
  shipmentInfo: {
    skus: [],
  },
  documents: [],
  loadingStatus: {
    saveAdjustments: 'NOT_STARTED',
    shipmentInfo: 'NOT_STARTED',
    documents: 'NOT_STARTED',
    deleteDocument: 'NOT_STARTED',
  },
  feedback: {
    isOpen: false,
    type: undefined,
    message: '',
  },
};

const shipmentsViewSlice = createSlice({
  name: 'shipmentsView',
  initialState,
  reducers: {
    fetchShipmentInfoStarted(state) {
      state.loadingStatus.shipmentInfo = 'PENDING';
    },
    fetchShipmentInfoSuccess(state, action) {
      state.shipmentInfo = action.payload;
      state.loadingStatus.shipmentInfo = 'COMPLETE';
    },
    fetchShipmentInfoFailed(state, action) {
      state.loadingStatus.shipmentInfo = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    saveAdjustmentsStarted(state) {
      state.loadingStatus.saveAdjustments = 'PENDING';
    },
    saveAdjustmentsSuccess(state, action) {
      state.shipmentInfo.adjustments = action.payload.adjustments;
      state.shipmentInfo.skus = Object.values(
        action.payload.adjustedShipmentSkus
      );
      state.shipmentInfo.totalUnits += action.payload.totalDifference;
      state.loadingStatus.saveAdjustments = 'COMPLETE';
    },
    saveAdjustmentsFailed(state, action) {
      state.loadingStatus.saveAdjustments = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getDocumentsStarted(state) {
      state.loadingStatus.documents = 'PENDING';
    },
    getDocumentsSuccess(state, action) {
      state.documents = action.payload;
      state.loadingStatus.documents = 'COMPLETE';
    },
    getDocumentsFailed(state, action) {
      state.loadingStatus.documents = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    deleteDocumentStarted(state) {
      state.loadingStatus.deleteDocument = 'PENDING';
    },
    deleteDocumentSuccess(state, action) {
      state.loadingStatus.deleteDocument = 'COMPLETE';
      state.documents = state.documents.filter(
        (documentInfo) => documentInfo.id !== action.payload
      );
    },
    deleteDocumentFailed(state, action) {
      state.loadingStatus.deleteDocument = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
  },
});

export const {
  fetchShipmentInfoStarted,
  fetchShipmentInfoSuccess,
  fetchShipmentInfoFailed,
  saveAdjustmentsStarted,
  saveAdjustmentsSuccess,
  saveAdjustmentsFailed,
  getDocumentsSuccess,
  getDocumentsFailed,
  getDocumentsStarted,
  deleteDocumentFailed,
  deleteDocumentStarted,
  deleteDocumentSuccess,
} = shipmentsViewSlice.actions;

export default shipmentsViewSlice.reducer;

export const fetchShipmentInfo = (shipmentId) => async (dispatch) => {
  dispatch(fetchShipmentInfoStarted());
  const result = await getShipment(shipmentId);

  if (result.status === 200) {
    dispatch(fetchShipmentInfoSuccess(result.shipmentInfo));
  } else {
    dispatch(fetchShipmentInfoFailed({ message: result.error }));
  }
};

export const applyAdjustments =
  (id, adjustments, shipmentSkus) => async (dispatch) => {
    dispatch(saveAdjustmentsStarted());
    let totalDifference = 0;
    const adjustedShipmentSkus = shipmentSkus.reduce((total, current) => {
      const relevantAdjustment = adjustments.find(
        (info) => info.sku === current.sku
      );

      if (relevantAdjustment) {
        totalDifference +=
          relevantAdjustment.adjustedUnits - relevantAdjustment.requestedUnits;

        return {
          ...total,
          [current.sku]: {
            ...current,
            units: relevantAdjustment.adjustedUnits,
          },
        };
      }
      return {
        ...total,
        [current.sku]: current,
      };
    }, {});

    const result = await saveShipmentAdjustments(
      id,
      adjustments,
      adjustedShipmentSkus,
      totalDifference
    );

    if (result.status === 200) {
      dispatch(
        saveAdjustmentsSuccess({
          id,
          adjustments,
          adjustedShipmentSkus,
          totalDifference,
        })
      );
    } else {
      dispatch(saveAdjustmentsFailed(result.error));
    }
  };

export const fetchDocuments = (shipmentId) => async (dispatch) => {
  dispatch(getDocumentsStarted());
  const result = await getDocuments(shipmentId);

  if (result.status === 200) {
    dispatch(getDocumentsSuccess(result.documents));
  } else {
    dispatch(getDocumentsFailed(result.error));
  }
};

export const deleteSupplierDocument =
  (shipmentId, documentPath, documentId) => async (dispatch) => {
    dispatch(deleteDocumentStarted());
    const result = await deleteDocument(shipmentId, documentPath, documentId);

    if (result.status === 200) {
      dispatch(deleteDocumentSuccess(documentId));
    } else {
      dispatch(deleteDocumentFailed(result.error));
    }
  };
