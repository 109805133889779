/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/forbid-prop-types */
import { makeStyles } from '@material-ui/core/styles';
import numeral from 'numeral';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  loadingWrapper: {
    padding: theme.spacing(8),
    textAlign: 'center',
  },
}));

const StockInfo = ({
  batchSize,
  measurements,
  warehouses,
  units,
  isLoading,
  onMissingInfoClick,
}) => {
  const classes = useStyles();
  const { weight, length, width, height } = measurements;

  const formatNumber = (value, type = 'float') => {
    if (value === null) {
      return '-';
    }
    if (type === 'integer') {
      return `${numeral(value).format('0,0')}`;
    }
    return `${numeral(value).format('0,0.00')}`;
  };

  return (
    <>
      {isLoading ? (
        <div className={classes.loadingWrapper}>
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <List
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  Measurements
                </ListSubheader>
              }
            >
              <ListItem>
                <ListItemText
                  primary={!weight ? 'Missing Info' : formatNumber(weight)}
                  secondary="Weight (kg)"
                />
                {!weight && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={onMissingInfoClick}
                    >
                      <HelpOutlineIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={!length ? 'Missing Info' : formatNumber(length)}
                  secondary="Length (cm)"
                />
                {!length && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={onMissingInfoClick}
                    >
                      <HelpOutlineIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={!width ? 'Missing Info' : formatNumber(width)}
                  secondary="Width (cm)"
                />
                {!width && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={onMissingInfoClick}
                    >
                      <HelpOutlineIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={!height ? 'Missing Info' : formatNumber(height)}
                  secondary="Height (cm)"
                />
                {!height && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={onMissingInfoClick}
                    >
                      <HelpOutlineIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={6}>
            <List
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  Stock Quantities
                </ListSubheader>
              }
            >
              {warehouses.map((warehouse) => (
                <ListItem key={warehouse.id}>
                  <ListItemText
                    primary={
                      units[warehouse.id] === undefined
                        ? 'Missing Info'
                        : formatNumber(units[warehouse.id], 'integer')
                    }
                    secondary={warehouse.name}
                  />
                  {units[warehouse.id] === undefined && (
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="edit"
                        onClick={onMissingInfoClick}
                      >
                        <HelpOutlineIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={6}>
            <List
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  Replenishment Details
                </ListSubheader>
              }
            >
              <ListItem>
                <ListItemText
                  primary={batchSize}
                  secondary="Order Batch Size"
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      )}
    </>
  );
};

StockInfo.defaultProps = {
  batchSize: 5,
  measurements: {},
  warehouses: [],
  units: {},
  isLoading: false,
  onMissingInfoClick: () => console.log('Missing info button was clicked.'),
};

StockInfo.propTypes = {
  batchSize: PropTypes.number,
  measurements: {
    weight: PropTypes.number,
    length: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
  },
  warehouses: [],
  units: PropTypes.object,
  isLoading: PropTypes.bool,
  onMissingInfoClick: PropTypes.func,
};

export default StockInfo;
