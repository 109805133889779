import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import numeral from 'numeral';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';
import {
  createProduct,
  fetchSuppliers,
  initiateStateReset,
  setTotalProductsToCreate,
} from './slice';
import takealotCategoryData from './takealotCategories';
import ContentWrapper from '../../../components/ContentWrapper';
import PageHeader from '../../../components/PageHeader';
import AdditionTable from './components/AdditionTable';
import CategoryTable from './components/CategoryTable';
import PricingTable from './components/PricingTable';
import WeightAndDimensionsTable from './components/WeightAndDimensionsTable';

const useStyles = makeStyles((theme) => ({
  actionsBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  alert: {
    marginBottom: theme.spacing(3),
  },
  separator: {
    height: theme.spacing(2),
  },
  wrapperStep: {
    padding: `${theme.spacing(4)}px 0px`,
  },
  wrapperWizard: {
    width: '100%',
    padding: theme.spacing(4),
  },
}));

const breadcrumbs = [
  {
    label: 'Sales',
    link: '/sales',
  },
  {
    label: 'Products',
    link: '/sales/products',
  },
];

const SourcedProductsAdd = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [products, setProducts] = useState([]);
  const [validation, setValidation] = useState({
    isErrorShowing: false,
    message: '',
    errorStep: 0,
  });

  const suppliers = useSelector((state) => state.sourcedProductsAdd.suppliers);
  const productCreationCount = useSelector(
    (state) => state.sourcedProductsAdd.productCreationCount
  );
  const loadingStatusSuppliers = useSelector(
    (state) => state.sourcedProductsAdd.loadingStatus.suppliers
  );
  const userInfo = useSelector((state) => state.account.userInfo);

  useEffect(() => {
    dispatch(fetchSuppliers());
  }, []);

  useEffect(() => {
    if (
      productCreationCount.total >
        productCreationCount.success + productCreationCount.failed ||
      loadingStatusSuppliers === 'PENDING'
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [productCreationCount, loadingStatusSuppliers]);

  useEffect(() => {
    if (
      productCreationCount.total > 0 &&
      productCreationCount.total ===
        productCreationCount.success + productCreationCount.failed
    ) {
      dispatch(initiateStateReset());
      setIsLoading(true);
      history.goBack();
    }
  }, [productCreationCount]);

  const updateProducts = (newProducts) => {
    setProducts(newProducts);
  };

  const isAllWeightAndDimensionsComplete = () => {
    let isComplete = true;

    products.forEach((product) => {
      if (
        product.weight <= 0 ||
        product.height <= 0 ||
        product.length <= 0 ||
        product.width <= 0
      ) {
        isComplete = false;
      }
    });

    return isComplete;
  };

  const isAllCategoriesSelected = () => {
    let isComplete = true;

    products.forEach((product) => {
      if (!product.department || !product.category || !product.subCategory) {
        isComplete = false;
      } else if (
        product.department.length === 0 ||
        product.category.length === 0 ||
        product.subCategory.length === 0
      ) {
        isComplete = false;
      }
    });

    return isComplete;
  };

  const isPricingComplete = () => {
    let isComplete = true;

    products.forEach((product) => {
      if (
        (product.wholesalePrice <= 0 && product.retailPrice <= 0) ||
        product.wholesalePrice < 0 ||
        product.retailPrice < 0
      ) {
        isComplete = false;
      }
    });

    return isComplete;
  };

  const getSizeCategory = (volume) => {
    if (volume <= 35000) {
      return 'Standard';
    }
    if (volume <= 130000) {
      return 'Large';
    }
    if (volume <= 200000) {
      return 'Oversize';
    }
    if (volume <= 545000) {
      return 'Bulky';
    }
    return 'Extra Bulky';
  };

  const getWeightCategory = (weight) => {
    if (weight <= 7) {
      return 'Light';
    }
    if (weight <= 25) {
      return 'Heavy';
    }
    if (weight <= 40) {
      return 'Heavy Plus';
    }
    return 'Very Heavy';
  };

  const getFulfilmentFee = (sizeCategory, weightCategory) => {
    switch (sizeCategory) {
      case 'Standard':
        switch (weightCategory) {
          case 'Light':
            return 32;
          case 'Heavy':
            return 36;
          case 'Heavy Plus':
            return 85;
          case 'Very Heavy':
            return 85;
          default:
            return 0;
        }
      case 'Large':
        switch (weightCategory) {
          case 'Light':
            return 42;
          case 'Heavy':
            return 46;
          case 'Heavy Plus':
            return 85;
          case 'Very Heavy':
            return 95;
          default:
            return 0;
        }
      case 'Oversize':
        switch (weightCategory) {
          case 'Light':
            return 95;
          case 'Heavy':
            return 105;
          case 'Heavy Plus':
            return 125;
          case 'Very Heavy':
            return 100;
          default:
            return 0;
        }
      case 'Bulky':
        switch (weightCategory) {
          case 'Light':
            return 95;
          case 'Heavy':
            return 115;
          case 'Heavy Plus':
            return 130;
          case 'Very Heavy':
            return 140;
          default:
            return 0;
        }
      case 'Extra Bulky':
        switch (weightCategory) {
          case 'Light':
            return 220;
          case 'Heavy':
            return 220;
          case 'Heavy Plus':
            return 270;
          case 'Very Heavy':
            return 325;
          default:
            return 0;
        }
      default:
        return 0;
    }
  };

  const calculateRetailPrice = (flatFees, wholesalePrice, margins) => {
    return (flatFees + wholesalePrice) / (1 - margins);
  };

  const getSuccessFeePercentage = (department, category, subCategory) => {
    const relevantSubCategory = takealotCategoryData.find(
      (data) =>
        data.department === department &&
        data.category === category &&
        data.subCategory === subCategory
    );
    return relevantSubCategory.successFee || 0;
  };

  const updateBasedOnWholesalePricing = (newProducts, id) => {
    const newProductsWithPricing = newProducts.map((productInfo) => {
      const wholesalePrice = numeral(productInfo.wholesalePrice).value();

      if (productInfo.id !== id || !wholesalePrice) {
        return productInfo;
      }
      const {
        length,
        width,
        height,
        weight,
        department,
        category,
        subCategory,
      } = productInfo;
      const volume = length * width * height;
      const insurancePercentage = 0.0075;
      const handlingFee = 5;

      let costPrice =
        numeral(productInfo.costPrice).value() || wholesalePrice / 2;

      if (costPrice >= wholesalePrice) {
        costPrice = wholesalePrice / 2;
      }

      const sizeCategory = getSizeCategory(volume);
      const weightCategory = getWeightCategory(weight);
      const fulfilmentFee = getFulfilmentFee(sizeCategory, weightCategory);
      const successFeePercentage =
        getSuccessFeePercentage(department, category, subCategory) * 1.15;
      const fulfilmentFeeVat = fulfilmentFee * 1.15;
      const easyonlineMarginPercentage = 0.1;
      const totalMargin = successFeePercentage + easyonlineMarginPercentage;

      const insuranceFee = costPrice * insurancePercentage;
      const actualRetailPrice = Math.ceil(
        calculateRetailPrice(
          fulfilmentFeeVat + insuranceFee + handlingFee,
          wholesalePrice,
          totalMargin
        )
      );
      const pricing = {
        wholesalePrice,
        costPrice: costPrice === 0 ? wholesalePrice / 2 : costPrice,
        retailPrice: actualRetailPrice,
      };

      return {
        ...productInfo,
        costPrice: pricing.costPrice.toFixed(2),
        wholesalePrice: pricing.wholesalePrice.toFixed(2),
        retailPrice: pricing.retailPrice.toFixed(0),
      };
    });
    setProducts(newProductsWithPricing);
  };

  const updateBasedOnCostPricing = (newProducts, id) => {
    const newProductsWithPricing = newProducts.map((productInfo) => {
      const costPrice = numeral(productInfo.costPrice).value();

      if (productInfo.id !== id || !costPrice) {
        return productInfo;
      }
      const {
        length,
        width,
        height,
        weight,
        department,
        category,
        subCategory,
      } = productInfo;
      const volume = length * width * height;
      const insurancePercentage = 0.0075;
      const handlingFee = 5;

      let wholesalePrice =
        numeral(productInfo.wholesalePrice).value() || costPrice * 2;

      if (wholesalePrice <= costPrice) {
        wholesalePrice = costPrice * 2;
      }

      const sizeCategory = getSizeCategory(volume);
      const weightCategory = getWeightCategory(weight);
      const fulfilmentFee = getFulfilmentFee(sizeCategory, weightCategory);
      const successFeePercentage =
        getSuccessFeePercentage(department, category, subCategory) * 1.15;
      const fulfilmentFeeVat = fulfilmentFee * 1.15;
      const easyonlineMarginPercentage = 0.1;
      const totalMargin = successFeePercentage + easyonlineMarginPercentage;

      const insuranceFee = costPrice * insurancePercentage;
      const calculatedWholesalePrice =
        wholesalePrice === 0 ? costPrice * 2 : wholesalePrice;
      const actualRetailPrice = Math.ceil(
        calculateRetailPrice(
          fulfilmentFeeVat + insuranceFee + handlingFee,
          calculatedWholesalePrice,
          totalMargin
        )
      );
      const pricing = {
        costPrice,
        wholesalePrice: calculatedWholesalePrice,
        retailPrice: actualRetailPrice,
      };

      return {
        ...productInfo,
        costPrice: pricing.costPrice.toFixed(2),
        wholesalePrice: pricing.wholesalePrice.toFixed(2),
        retailPrice: pricing.retailPrice.toFixed(0),
      };
    });
    setProducts(newProductsWithPricing);
  };

  const cleanProducts = () => {
    const creationDate = new Date();
    const productSourcerId = userInfo.uid;
    const productSourcerName = `${userInfo.name} ${userInfo.surname}`;

    return products.map((productInfo) => {
      const {
        description,
        length,
        width,
        height,
        weight,
        department,
        category,
        subCategory,
        costPrice,
        wholesalePrice,
        retailPrice,
        supplierId,
        supplierName,
        measurementType,
      } = productInfo;
      const volume = length * width * height;
      const insurancePercentage = 0.0075;
      const handlingFee = 5;

      const sizeCategory = getSizeCategory(volume);
      const weightCategory = getWeightCategory(weight);
      const fulfilmentFee = getFulfilmentFee(sizeCategory, weightCategory);
      const fulfilmentFeeVat = fulfilmentFee * 1.15;
      const successFeePercentage = getSuccessFeePercentage(
        department,
        category,
        subCategory
      );
      const easyonlineMarginPercentage = 0.1;
      const totalMargin = successFeePercentage + easyonlineMarginPercentage;
      const successFee = parseFloat(retailPrice) * successFeePercentage;
      const successFeeVat =
        parseFloat(retailPrice) * successFeePercentage * 1.15;
      const easyonlineFee = parseFloat(retailPrice) * 0.1;
      const insuranceFee = parseFloat(costPrice) * insurancePercentage;

      return {
        creationDate,
        productSourcerId,
        productSourcerName,
        supplierId,
        supplierName,
        description,
        status: 'AVAILABLE',
        stockInfo: {
          volume,
          isGiven: measurementType === 'ACTUAL',
          weight: parseFloat(weight),
          height: parseFloat(height),
          length: parseFloat(length),
          width: parseFloat(width),
        },
        categories: {
          department,
          category,
          subCategory,
          size: sizeCategory,
          weight: weightCategory,
        },
        margins: {
          takealotSuccessFee: successFeePercentage,
          easyonlineMargin: easyonlineMarginPercentage,
          total: totalMargin,
        },
        fees: {
          handlingFee,
          fulfilmentFee,
          fulfilmentFeeVat,
          successFee,
          successFeeVat,
          easyonlineFee,
          insuranceFee,
        },
        pricing: {
          costPrice: parseFloat(costPrice),
          wholesalePrice: parseFloat(wholesalePrice),
          retailPrice: parseFloat(retailPrice),
        },
      };
    });
  };

  const onNextClick = () => {
    switch (activeStep) {
      case 0:
        if (products.length === 0) {
          setValidation({
            isErrorShowing: true,
            message: 'Please add at least 1 product to continue.',
            errorStep: 0,
          });
        } else {
          setValidation({
            isErrorShowing: false,
            message: '',
            errorStep: 0,
          });
          setActiveStep(1);
        }
        break;
      case 1:
        if (!isAllWeightAndDimensionsComplete()) {
          setValidation({
            isErrorShowing: true,
            message: 'Please specify the measurements for all products.',
            errorStep: 1,
          });
        } else {
          setValidation({
            isErrorShowing: false,
            message: '',
            errorStep: 1,
          });
          setActiveStep(2);
        }
        break;
      case 2:
        if (!isAllCategoriesSelected()) {
          setValidation({
            isErrorShowing: true,
            message:
              'Select the appropriate department, category and sub-category for all products.',
            errorStep: 2,
          });
        } else {
          setValidation({
            isErrorShowing: false,
            message: '',
            errorStep: 2,
          });
          setActiveStep(3);
        }
        break;
      case 3:
        if (!isPricingComplete()) {
          setValidation({
            isErrorShowing: true,
            message: 'Please enter valid prices for all products.',
            errorStep: 3,
          });
        } else {
          const cleanedProducts = cleanProducts();

          dispatch(setTotalProductsToCreate(cleanedProducts.length));
          cleanedProducts.map((productInfo) =>
            dispatch(createProduct(productInfo))
          );
          setValidation({
            isErrorShowing: false,
            message: '',
            errorStep: 0,
          });
        }
        break;
      default:
        console.log('Unknown step');
        break;
    }
  };

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <div className={classes.wrapperStep}>
            <Typography variant="h4" gutterBottom>
              Add products with basic details
            </Typography>
            <Typography variant="body1">
              Please add all desired products and the associated supplier.
            </Typography>
            <div className={classes.separator} />
            {validation.isErrorShowing && validation.errorStep === 0 && (
              <Alert className={classes.alert} severity="error">
                {validation.message}
              </Alert>
            )}
            <AdditionTable
              suppliers={suppliers}
              products={products}
              updateProducts={updateProducts}
            />
          </div>
        );
      case 1:
        return (
          <div className={classes.wrapperStep}>
            <Typography variant="h4" gutterBottom>
              Enter measurements
            </Typography>
            <Typography variant="body1">
              Please enter the packaged weight and dimensions for each product.
            </Typography>
            <div className={classes.separator} />
            {validation.isErrorShowing && validation.errorStep === 1 && (
              <Alert className={classes.alert} severity="error">
                {validation.message}
              </Alert>
            )}
            <WeightAndDimensionsTable
              products={products}
              updateProducts={updateProducts}
            />
          </div>
        );
      case 2:
        return (
          <div className={classes.wrapperStep}>
            <Typography variant="h4" gutterBottom>
              Enter product categories
            </Typography>
            <Typography variant="body1">
              Please select the department, category and sub-category for each
              product so that we can accurately calculate Takealot fees.
            </Typography>
            <div className={classes.separator} />
            {validation.isErrorShowing && validation.errorStep === 2 && (
              <Alert className={classes.alert} severity="error">
                {validation.message}
              </Alert>
            )}
            <CategoryTable
              categoryData={takealotCategoryData}
              products={products}
              updateProducts={updateProducts}
            />
          </div>
        );
      case 3:
        return (
          <div className={classes.wrapperStep}>
            <Typography variant="h4" gutterBottom>
              Enter pricing details
            </Typography>
            <Typography variant="body1">
              Please enter the desired pricing on Takealot for each product.
            </Typography>
            <div className={classes.separator} />
            {validation.isErrorShowing && validation.errorStep === 3 && (
              <Alert className={classes.alert} severity="error">
                {validation.message}
              </Alert>
            )}
            <PricingTable
              products={products}
              updateProducts={updateProducts}
              updateBasedOnCostPricing={(id) =>
                updateBasedOnCostPricing(products, id)
              }
              updateBasedOnWholesalePricing={(id) =>
                updateBasedOnWholesalePricing(products, id)
              }
            />
          </div>
        );
      default:
        return <div />;
    }
  };

  return (
    <div>
      <PageHeader
        isLoading={isLoading}
        title="Product Creation"
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Add"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
      />
      <ContentWrapper>
        <Paper className={classes.wrapperWizard}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Stepper activeStep={activeStep} orientation="vertical">
                <Step>
                  <StepLabel>Products</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Measurements</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Categories</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Pricing</StepLabel>
                </Step>
              </Stepper>
            </Grid>
            <Grid item xs={9}>
              {getStepContent()}
              <div className={classes.actionsBar}>
                <Button
                  disabled={activeStep === 0 || isLoading}
                  onClick={() => setActiveStep(activeStep - 1)}
                >
                  Previous
                </Button>
                <Button
                  disabled={isLoading}
                  variant="contained"
                  color="secondary"
                  onClick={onNextClick}
                >
                  {activeStep !== 3 ? 'Next' : 'Add Products'}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </ContentWrapper>
    </div>
  );
};

export default SourcedProductsAdd;
