import { connectHits, connectStateResults } from 'react-instantsearch-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Hit from './components/Hit';
import EmptyViewMessage from '../../../../../components/EmptyViewMessage';
import HitsPagination from '../../../../../components/HitsPagination';
import SearchStats from '../../../../../components/SearchStats';

const Results = connectStateResults(({ isSearchStalled, searchResults }) => {
  if (isSearchStalled) {
    return (
      <Grid item xs={12} container justifyContent="center">
        <CircularProgress color="secondary" />
      </Grid>
    );
  }

  if (searchResults.nbHits === 0) {
    return (
      <EmptyViewMessage
        heading="No suppliers found"
        message="Please adjust the filters or search query."
      />
    );
  }

  return <div />;
});

const Hits = ({ goToLink, hits }) => {
  return (
    <Grid container spacing={3}>
      {hits.length > 0 && (
        <Grid item xs={12}>
          <Grid container spacing={1} justifyContent="center">
            <HitsPagination />
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        <SearchStats />
      </Grid>
      <Results />
      {hits.map((hit) => {
        return (
          <Grid key={hit.objectID} item xs={12} md={6}>
            <Hit
              title={hit.name}
              status={hit.status}
              code={hit.code}
              goToSupplier={() =>
                goToLink(`/presales/suppliers/browse/${hit.objectID}`)
              }
            />
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <Grid container spacing={1} justifyContent="center">
          <HitsPagination />
        </Grid>
      </Grid>
    </Grid>
  );
};

Hits.defaultProps = {
  goToLink: (path) => console.log(`User navigated to: ${path}`),
  hits: [],
};

Hits.propTypes = {
  goToLink: PropTypes.func,
  hits: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      objectID: PropTypes.string,
      status: PropTypes.string,
      code: PropTypes.string,
    })
  ),
};

export default connectHits(Hits);
