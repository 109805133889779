/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  getSku,
  updateSku,
  getCopyTemplate,
  createNewCopy,
} from '../../../api/listingsAPI';
import { getWarehouses } from '../../../api/logisticsAPI';

const initialState = {
  clients: [],
  warehousesList: [],
  isUserRedirectedToBrowse: false,
  skuInfo: {
    sku: '',
    amazon: {
      status: 'INACTIVE',
    },
    takealot: {
      status: 'INACTIVE',
    },
  },
  templateInfo: {
    subtitle: '',
    description: '',
  },
  loadingStatus: {
    update: 'NOT_STARTED',
    warehouses: 'NOT_STARTED',
    skuInfo: 'NOT_STARTED',
    template: 'NOT_STARTED',
    copyCreation: 'NOT_STARTED',
  },
  feedback: {
    isOpen: false,
    type: undefined,
    message: '',
  },
};

const listingsCopyWritingSlice = createSlice({
  name: 'listingsCopyWriting',
  initialState,
  reducers: {
    updateSkuStarted(state) {
      state.loadingStatus.update = 'PENDING';
    },
    updateSkuSuccess(state) {
      state.isUserRedirectedToBrowse = true;
      state.loadingStatus.update = 'COMPLETE';
    },
    updateSkuFailed(state, action) {
      state.loadingStatus.update = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getSkuStarted(state) {
      state.loadingStatus.skuInfo = 'PENDING';
    },
    getSkuSuccess(state, action) {
      state.loadingStatus.skuInfo = 'COMPLETE';
      state.skuInfo = action.payload;
    },
    getSkuFailed(state, action) {
      state.loadingStatus.skuInfo = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getWarehousesStarted(state) {
      state.loadingStatus.warehouses = 'PENDING';
    },
    getWarehousesSuccess(state, action) {
      state.warehousesList = action.payload;
      state.loadingStatus.warehouses = 'COMPLETE';
    },
    getWarehousesFailed(state, action) {
      state.loadingStatus.warehouses = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    createCopyStarted(state) {
      state.loadingStatus.copyCreation = 'PENDING';
    },
    createCopySuccess(state) {
      state.loadingStatus.copyCreation = 'COMPLETE';
      state.isUserRedirectedToBrowse = true;
    },
    createCopyFailed(state, action) {
      state.loadingStatus.copyCreation = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getTemplateStarted(state) {
      state.loadingStatus.template = 'PENDING';
    },
    getTemplateSuccess(state, action) {
      state.loadingStatus.template = 'COMPLETE';
      state.templateInfo = action.payload;
    },
    getTemplateFailed(state) {
      state.loadingStatus.template = 'NOT_FOUND';
    },
    resetState() {
      return initialState;
    },
  },
});

export const {
  updateSkuStarted,
  updateSkuSuccess,
  updateSkuFailed,
  getSkuStarted,
  getSkuSuccess,
  getSkuFailed,
  getWarehousesStarted,
  getWarehousesSuccess,
  getWarehousesFailed,
  createCopyStarted,
  createCopySuccess,
  createCopyFailed,
  getTemplateStarted,
  getTemplateSuccess,
  getTemplateFailed,
  resetState,
} = listingsCopyWritingSlice.actions;

export default listingsCopyWritingSlice.reducer;

export const changeSku = (sku, newInfo) => async (dispatch) => {
  dispatch(updateSkuStarted());
  const result = await updateSku(sku, newInfo);

  if (result.status === 200) {
    dispatch(updateSkuSuccess({ sku, newInfo }));
  } else {
    dispatch(updateSkuFailed(result.error));
  }
};

export const fetchSkuInfo = (sku) => async (dispatch) => {
  dispatch(getSkuStarted());
  const result = await getSku(sku);

  if (result.status === 200) {
    dispatch(getSkuSuccess(result.skuInfo));
  } else {
    dispatch(getSkuFailed(result.error));
  }
};

export const fetchWarehouses = () => async (dispatch) => {
  dispatch(getWarehousesStarted());
  const result = await getWarehouses();

  if (result.status === 200) {
    dispatch(getWarehousesSuccess(result.warehouses));
  } else {
    dispatch(getWarehousesFailed(result.error));
  }
};

export const initiateStateReset = () => async (dispatch) => {
  dispatch(resetState());
};

export const createCopy = (copyInfo) => async (dispatch) => {
  dispatch(createCopyStarted());

  const result = await createNewCopy(copyInfo);
  if (result.status === 200) {
    dispatch(createCopySuccess());
  } else {
    dispatch(createCopyFailed(result.error));
  }
};

export const fetchTemplate =
  (department, category, subcategory) => async (dispatch) => {
    dispatch(getTemplateStarted());

    const result = await getCopyTemplate(department, category, subcategory);
    if (result.status === 200) {
      dispatch(getTemplateSuccess(result.templateData));
    } else {
      dispatch(getTemplateFailed());
    }
  };
