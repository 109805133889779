/* eslint-disable react/jsx-props-no-spreading */
import { fade } from '@material-ui/core/styles/colorManipulator';
import { green, red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';

const headerStyle = {
  backgroundColor: '#171415',
};

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.common.black,
  },
  loadingOverlay: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: fade(theme.palette.common.black, 0.8),
  },
  statusUploaded: {
    color: theme.palette.common.black,
    backgroundColor: green[500],
  },
  statusNotUploaded: {
    color: theme.palette.common.black,
    backgroundColor: red[500],
  },
  wrapperContent: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

const TalealotReportsTable = ({
  isLoading,
  uploadReport,
  selectedDate,
  reportSummary,
}) => {
  const classes = useStyles();

  const getStatusChip = (status) => {
    switch (status) {
      case 'UPLOADED':
        return (
          <Chip
            icon={<DoneIcon className={classes.icon} />}
            label="Uploaded"
            className={classes.statusUploaded}
          />
        );
      case 'NOT_UPLOADED':
        return (
          <Chip
            icon={<ClearIcon className={classes.icon} />}
            label="Not Uploaded"
            className={classes.statusNotUploaded}
          />
        );
      default:
        return (
          <Chip
            icon={<DoneIcon className={classes.icon} />}
            label="Active"
            className={classes.statusUploaded}
          />
        );
    }
  };

  const getColumns = () => {
    const columns = [
      { title: 'Report', field: 'name' },
      {
        title: 'Status',
        field: 'status',
        render: (rowData) => getStatusChip(rowData.status),
      },
    ];

    return columns;
  };

  const getReports = () => {
    return [
      {
        id: 'takealot-product-sales',
        name: 'Product Sales',
        status: reportSummary[
          `${moment(selectedDate).format('DD')}-takealot-product-sales`
        ]
          ? 'UPLOADED'
          : 'NOT_UPLOADED',
      },
    ];
  };

  return (
    <div className={classes.wrapperContent}>
      <MaterialTable
        title="Takealot Reports"
        columns={getColumns()}
        data={getReports()}
        isLoading={isLoading}
        options={{
          headerStyle,
          actionsColumnIndex: -1,
          search: false,
          grouping: false,
          draggable: false,
          exportButton: false,
          paging: false,
        }}
        actions={[
          {
            icon: 'cloud_upload',
            tooltip: 'Upload report',
            onClick: (event, rowData) => uploadReport(rowData.id),
          },
        ]}
        components={{
          OverlayLoading: () => (
            <div className={classes.loadingOverlay}>
              <CircularProgress color="primary" size={32} />
            </div>
          ),
        }}
        localization={{
          header: {
            actions: '',
          },
        }}
      />
    </div>
  );
};

TalealotReportsTable.defaultProps = {
  isLoading: false,
  uploadReport: (reportId) => console.log(`Report uploaded: ${reportId}`),
  selectedDate: new Date(),
  reportSummary: {},
};

TalealotReportsTable.propTypes = {
  isLoading: PropTypes.bool,
  uploadReport: PropTypes.func,
  selectedDate: PropTypes.instanceOf(Date),
  // eslint-disable-next-line react/forbid-prop-types
  reportSummary: PropTypes.object,
};

export default TalealotReportsTable;
