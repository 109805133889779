import { connectRange } from 'react-instantsearch-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import DateFnsUtils from '@date-io/moment';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import moment from 'moment';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const DateNavigator = ({
  dateSelected,
  changeDateSelected,
  refine,
  min,
  max,
}) => {
  const classes = useStyles();

  const [isIncrementButtonDisabled, setIsIncrementButtonDisabled] =
    useState(true);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  useEffect(() => {
    const todaysDate = moment();
    const minDate = Math.round(
      moment(dateSelected).startOf('day').toDate().getTime() / 1000
    );
    const maxDate = Math.round(
      moment(dateSelected).endOf('day').toDate().getTime() / 1000
    );

    setIsIncrementButtonDisabled(
      moment(dateSelected).isSame(todaysDate, 'day')
    );
    refine({
      min: Math.max(min, minDate),
      max: Math.min(max, maxDate),
    });
  }, [dateSelected, min, max]);

  const decrementDate = () => {
    const prevDay = moment(dateSelected).subtract(1, 'day').toDate();
    changeDateSelected(prevDay);
  };

  const incrementDate = () => {
    const nextDay = moment(dateSelected).add(1, 'day').toDate();
    changeDateSelected(nextDay);
  };

  const getDateLabel = () => {
    const todaysDate = moment();
    if (moment(dateSelected).isSame(todaysDate, 'day')) {
      return 'Today';
    }
    return moment(dateSelected).format('D MMM YYYY');
  };

  const toggleIsDatePickerOpen = () => {
    setIsDatePickerOpen(!isDatePickerOpen);
  };

  return (
    <div className={classes.wrapper}>
      <ButtonGroup size="large" color="secondary">
        <Button disabled={isIncrementButtonDisabled} onClick={incrementDate}>
          <KeyboardArrowLeftIcon />
        </Button>
        <Button onClick={toggleIsDatePickerOpen}>{getDateLabel()}</Button>
        <Button onClick={decrementDate}>
          <KeyboardArrowRightIcon />
        </Button>
      </ButtonGroup>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          variant="dialog"
          open={isDatePickerOpen}
          onOpen={() => setIsDatePickerOpen(true)}
          onClose={() => setIsDatePickerOpen(false)}
          format="D MMM yyyy"
          value={dateSelected}
          onChange={changeDateSelected}
          maxDate={new Date()}
          TextFieldComponent={() => null}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

DateNavigator.defaultProps = {
  dateSelected: new Date(),
  changeDateSelected: (newDate) =>
    console.log(`Date was changed to: ${newDate.toString()}`),
  refine: (itemValue) => console.log(`User refined the range by ${itemValue}`),
  min: 0,
  max: 100,
};

DateNavigator.propTypes = {
  dateSelected: PropTypes.instanceOf(Date),
  changeDateSelected: PropTypes.func,
  refine: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
};

export default connectRange(DateNavigator);
