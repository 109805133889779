import moment from 'moment';
import numeral from 'numeral';
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import logo from '../../img/logo-white.png';
import roboto from '../../fonts/Roboto-Regular.ttf';
import robotoBold from '../../fonts/Roboto-Bold.ttf';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: roboto,
    },
    {
      src: robotoBold,
      fontWeight: 'bold',
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    padding: 50,
    paddingBottom: 70,
    lineHeight: 1.4,
    fontFamily: 'Roboto',
  },
  header: {
    flexDirection: 'row',
    backgroundColor: '#171415',
    width: '100%',
    padding: 20,
  },
  headerTitle: {
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    marginTop: 12,
    color: '#f36438',
    fontSize: 16,
  },
  headerInfo: {
    width: '60%',
    fontSize: 10,
    color: '#fcf9f8',
    textAlign: 'right',
  },
  logoWrapper: {
    width: '40%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: 120,
    height: 'auto',
  },
  section: {
    width: '100%',
    textAlign: 'center',
    color: '#595959',
    fontSize: 10,
    paddingVertical: 24,
  },
  sectionTitle: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginBottom: 12,
    backgroundColor: '#584b53',
    color: '#fcf9f8',
    padding: 9,
    fontSize: 10,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    fontSize: 9,
  },
  tableSales: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderBottomWidth: 0,
    fontSize: 9,
  },
  oddRow: {
    margin: 'auto',
    flexDirection: 'row',
    backgroundColor: '#f3f3f3',
  },
  evenRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  titleRow: {
    fontFamily: 'Roboto',
    margin: 'auto',
    width: '100%',
    flexDirection: 'row',
    textAlign: 'left',
    fontWeight: 'bold',
    paddingVertical: 8,
    paddingHorizontal: 14,
    borderWidth: 1,
    backgroundColor: '#595959',
    color: '#fcf9f8',
  },
  tableHeader: {
    fontFamily: 'Roboto',
    margin: 'auto',
    flexDirection: 'row',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  tableFooter: {
    fontFamily: 'Roboto',
    margin: 'auto',
    flexDirection: 'row',
    fontWeight: 'bold',
  },
  page1Col1: {
    fontFamily: 'Roboto',
    width: '30%',
    fontWeight: 'bold',
    borderStyle: 'solid',
    textAlign: 'right',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
  },
  page1Col2: {
    width: '70%',
    borderStyle: 'solid',
    textAlign: 'left',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
  },
  billingCol1: {
    width: '30%',
    fontWeight: 'bold',
    borderStyle: 'solid',
    textAlign: 'right',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  billingCol2: {
    width: '70%',
    borderStyle: 'solid',
    textAlign: 'left',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  salesCol1: {
    width: '25%',
    borderStyle: 'solid',
    textAlign: 'left',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  salesCol2: {
    width: '33%',
    borderStyle: 'solid',
    textAlign: 'left',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  salesCol3: {
    width: '10%',
    borderStyle: 'solid',
    textAlign: 'center',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  salesCol4: {
    width: '15%',
    borderStyle: 'solid',
    textAlign: 'right',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  salesCol5: {
    width: '17%',
    borderStyle: 'solid',
    textAlign: 'right',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  termRow: {
    margin: 'auto',
    flexDirection: 'row',
    alignItems: 'center',
  },
  standardTermCol1: {
    width: '5%',
    textAlign: 'right',
    fontWeight: 'bold',
    paddingVertical: 2,
    paddingHorizontal: 4,
  },
  standardTermCol2: {
    width: '95%',
    textAlign: 'left',
    paddingVertical: 2,
    paddingHorizontal: 4,
    fontSize: 8,
  },
  indentedTermCol1: {
    width: '10%',
    textAlign: 'right',
    fontWeight: 'bold',
    paddingVertical: 2,
    paddingHorizontal: 4,
  },
  indentedTermCol2: {
    width: '90%',
    textAlign: 'left',
    paddingVertical: 2,
    paddingHorizontal: 4,
    fontSize: 8,
  },
  listingsCol1: {
    width: '5%',
    borderStyle: 'solid',
    textAlign: 'center',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  listingsCol2: {
    width: '30%',
    borderStyle: 'solid',
    textAlign: 'left',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  listingsCol3: {
    width: '10%',
    borderStyle: 'solid',
    textAlign: 'center',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  listingsCol4: {
    width: '10%',
    borderStyle: 'solid',
    textAlign: 'center',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  listingsCol5: {
    width: '15%',
    borderStyle: 'solid',
    textAlign: 'right',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  listingsCol6: {
    width: '15%',
    borderStyle: 'solid',
    textAlign: 'right',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  listingsCol7: {
    width: '15%',
    borderStyle: 'solid',
    textAlign: 'right',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  listingsFinalRow: {
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 0,
    borderTopWidth: 1,
    height: 0,
  },
  listingsHeaderCol1: {
    width: '5%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  listingsHeaderCol2: {
    width: '30%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  listingsHeaderCol3: {
    width: '10%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  listingsHeaderCol4: {
    width: '10%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  listingsHeaderCol5: {
    width: '15%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  listingsHeaderCol6: {
    width: '15%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  listingsHeaderCol7: {
    width: '15%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  headerCol1: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  headerCol2: {
    width: '33%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  headerCol3: {
    width: '10%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  headerCol4: {
    width: '15%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  headerCol5: {
    width: '17%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  footerRow1Col1: {
    fontSize: 10,
    width: '83%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 2,
    textAlign: 'right',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  footerRow1Col2: {
    fontSize: 10,
    width: '17%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    textAlign: 'right',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  footerRow2Col1: {
    fontSize: 10,
    width: '83%',
    borderWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    textAlign: 'right',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  footerRow2Col2: {
    fontSize: 10,
    width: '17%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    textAlign: 'right',
    backgroundColor: '#f3f3f3',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  footerRow3Col1: {
    fontSize: 11,
    width: '83%',
    borderWidth: 0,
    paddingVertical: 4,
    paddingHorizontal: 4,
    textAlign: 'right',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  footerRow3Col2: {
    fontSize: 11,
    width: '17%',
    borderWidth: 1,
    borderTopWidth: 0,
    paddingVertical: 4,
    paddingHorizontal: 4,
    textAlign: 'right',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  finePrint: {
    paddingHorizontal: 48,
    fontSize: 8,
    marginBottom: 12,
  },
});

const TakealotProposal = ({
  businessName,
  creationDate,
  setupFees,
  listings,
  subTotal,
  vat,
  total,
}) => {
  const formattedCreationDate = moment(creationDate).format('D MMMM YYYY');

  return (
    <Document
      title="Takealot Proposal"
      creator="EasyOnline Pty Ltd"
      subject={`Takealot Proposal for ${businessName} (${moment(
        creationDate
      ).format('DD/MM/YYYY')})`}
    >
      <Page wrap size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.logoWrapper}>
            <Image src={logo} style={styles.logo} />
          </View>
          <View style={styles.headerInfo}>
            <Text style={styles.headerTitle}>Takealot Proposal</Text>
            <Text>{`Issued on ${formattedCreationDate}`}</Text>
          </View>
        </View>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Section 1: Setup Fees</Text>
          <Text style={styles.finePrint}>
            {`This Proposal incorporates the agreement for services entered between EasyOnline Pty Ltd and ${businessName}, ('the customer'), dated ${formattedCreationDate}.`}
          </Text>
          <View style={styles.tableSales}>
            <View style={styles.tableHeader}>
              <View style={styles.headerCol1}>
                <Text>Item</Text>
              </View>
              <View style={styles.headerCol2}>
                <Text>Description</Text>
              </View>
              <View style={styles.headerCol3}>
                <Text>Qty</Text>
              </View>
              <View style={styles.headerCol4}>
                <Text>Unit Price</Text>
              </View>
              <View style={styles.headerCol5}>
                <Text>Total</Text>
              </View>
            </View>
            {setupFees.map((fee, index) => (
              <View
                key={`${fee.name}-${fee.total}`}
                style={index % 2 === 0 ? styles.oddRow : styles.evenRow}
              >
                <View style={styles.salesCol1}>
                  <Text>{fee.name}</Text>
                </View>
                <View style={styles.salesCol2}>
                  <Text>{fee.description}</Text>
                </View>
                <View style={styles.salesCol3}>
                  <Text>{numeral(fee.quantity).format('0,0')}</Text>
                </View>
                <View style={styles.salesCol4}>
                  <Text>{`R ${numeral(fee.unitPrice).format('0,0.00')}`}</Text>
                </View>
                <View style={styles.salesCol5}>
                  <Text>{`R ${numeral(fee.total).format('0,0.00')}`}</Text>
                </View>
              </View>
            ))}
            <View style={styles.tableFooter}>
              <View style={styles.footerRow1Col1}>
                <Text>Subtotal</Text>
              </View>
              <View style={styles.footerRow1Col2}>
                <Text>{`R ${numeral(subTotal).format('0,0.00')}`}</Text>
              </View>
            </View>
            <View style={styles.tableFooter}>
              <View style={styles.footerRow2Col1}>
                <Text>15% VAT</Text>
              </View>
              <View style={styles.footerRow2Col2}>
                <Text>{`R ${numeral(vat).format('0,0.00')}`}</Text>
              </View>
            </View>
            <View style={styles.tableFooter}>
              <View style={styles.footerRow3Col1}>
                <Text>Total Due</Text>
              </View>
              <View style={styles.footerRow3Col2}>
                <Text>{`R ${numeral(total).format('0,0.00')}`}</Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Section 2: Billing Info</Text>
          <Text style={styles.finePrint}>
            If you are satisfied with this proposal and would like to proceed,
            please settle the &lsquo;Total Due&rsquo; in Section 1 using the
            banking details below. Thank you for your business!
          </Text>
          <View style={styles.tableSales}>
            <View style={styles.oddRow}>
              <View style={styles.billingCol1}>
                <Text>Beneficiary Name</Text>
              </View>
              <View style={styles.billingCol2}>
                <Text>EASYONLINE(PTY)LTD</Text>
              </View>
            </View>
            <View style={styles.evenRow}>
              <View style={styles.billingCol1}>
                <Text>Beneficiary Account Number</Text>
              </View>
              <View style={styles.billingCol2}>
                <Text>6288 899 4053</Text>
              </View>
            </View>
            <View style={styles.oddRow}>
              <View style={styles.billingCol1}>
                <Text>Bank Name</Text>
              </View>
              <View style={styles.billingCol2}>
                <Text>First National Bank</Text>
              </View>
            </View>
            <View style={styles.evenRow}>
              <View style={styles.billingCol1}>
                <Text>Branch</Text>
              </View>
              <View style={styles.billingCol2}>
                <Text>Enterprise Banking</Text>
              </View>
            </View>
            <View style={styles.oddRow}>
              <View style={styles.billingCol1}>
                <Text>Branch Code</Text>
              </View>
              <View style={styles.billingCol2}>
                <Text>250 655</Text>
              </View>
            </View>
            <View style={styles.evenRow}>
              <View style={styles.billingCol1}>
                <Text>Send Proof of Payment To</Text>
              </View>
              <View style={styles.billingCol2}>
                <Text>finance@easyonline.io</Text>
              </View>
            </View>
            <View style={styles.listingsFinalRow} />
          </View>
          <Text style={styles.finePrint} />
          <Text style={styles.finePrint}>
            Below are our company details if needed:
          </Text>
          <View style={styles.tableSales}>
            <View style={styles.oddRow}>
              <View style={styles.billingCol1}>
                <Text>Registration Number</Text>
              </View>
              <View style={styles.billingCol2}>
                <Text>2019/227600/07</Text>
              </View>
            </View>
            <View style={styles.evenRow}>
              <View style={styles.billingCol1}>
                <Text>VAT Number</Text>
              </View>
              <View style={styles.billingCol2}>
                <Text>4620291122</Text>
              </View>
            </View>
            <View style={styles.oddRow}>
              <View style={styles.billingCol1}>
                <Text>Telephone Number</Text>
              </View>
              <View style={styles.billingCol2}>
                <Text>+27 11 730 8247</Text>
              </View>
            </View>
            <View style={styles.evenRow}>
              <View style={styles.billingCol1}>
                <Text>Physical Address</Text>
              </View>
              <View style={styles.billingCol2}>
                <Text>
                  Unit 10, Sundowner Plaza, Taurus Road, Sundowner, 2161
                </Text>
              </View>
            </View>
            <View style={styles.listingsFinalRow} />
          </View>
        </View>
        <View style={styles.section} break>
          <Text style={styles.sectionTitle}>Section 3: SKU Information</Text>
          <Text style={styles.finePrint}>
            The table below provides details of the agreed wholesale price paid
            to the customer after each unit sold. All prices below include VAT.
          </Text>
          <View style={styles.tableSales}>
            <View style={styles.tableHeader}>
              <View style={styles.listingsHeaderCol1} />
              <View style={styles.listingsHeaderCol2}>
                <Text>SKU Description</Text>
              </View>
              <View style={styles.listingsHeaderCol3}>
                <Text>Images Bought</Text>
              </View>
              <View style={styles.listingsHeaderCol4}>
                <Text>Initial Units</Text>
              </View>
              <View style={styles.listingsHeaderCol5}>
                <Text>Cost Price</Text>
              </View>
              <View style={styles.listingsHeaderCol6}>
                <Text>Wholesale Price</Text>
              </View>
              <View style={styles.listingsHeaderCol7}>
                <Text>Retail Price</Text>
              </View>
            </View>
            {listings.map((listing, index) => (
              <View
                key={`${listing.name}-${listing.retailPrice}`}
                style={index % 2 === 0 ? styles.oddRow : styles.evenRow}
              >
                <View style={styles.listingsCol1}>
                  <Text>{index + 1}</Text>
                </View>
                <View style={styles.listingsCol2}>
                  <Text>{listing.description}</Text>
                </View>
                <View style={styles.listingsCol3}>
                  <Text>{listing.photos}</Text>
                </View>
                <View style={styles.listingsCol4}>
                  <Text>{listing.units}</Text>
                </View>
                <View style={styles.listingsCol5}>
                  <Text>
                    {`R ${numeral(listing.pricing.costPrice).format('0,0.00')}`}
                  </Text>
                </View>
                <View style={styles.listingsCol6}>
                  <Text>
                    {`R ${numeral(listing.pricing.wholesalePrice).format(
                      '0,0.00'
                    )}`}
                  </Text>
                </View>
                <View style={styles.listingsCol7}>
                  <Text>
                    {`R ${numeral(listing.pricing.retailPrice).format(
                      '0,0.00'
                    )}`}
                  </Text>
                </View>
              </View>
            ))}
            <View style={styles.listingsFinalRow} />
          </View>
        </View>
        <View style={styles.section} break>
          <Text style={styles.sectionTitle}>Section 3: Terms & Conditions</Text>
          <View style={styles.termRow}>
            <View style={styles.standardTermCol1}>
              <Text>1.</Text>
            </View>
            <View style={styles.standardTermCol2}>
              <Text>
                Supplying your consignment stock to EasyOnline confirms
                acceptance of all terms and conditions listed below.
              </Text>
            </View>
          </View>
          <View style={styles.termRow}>
            <View style={styles.standardTermCol1}>
              <Text>2.</Text>
            </View>
            <View style={styles.standardTermCol2}>
              <Text>A single SKU constitutes a unique product.</Text>
            </View>
          </View>
          <View style={styles.termRow}>
            <View style={styles.indentedTermCol1}>
              <Text>a.</Text>
            </View>
            <View style={styles.indentedTermCol2}>
              <Text>
                Any differences in units are regarded as a separate SKU and
                charged accordingly.
              </Text>
            </View>
          </View>
          <View style={styles.termRow}>
            <View style={styles.indentedTermCol1}>
              <Text>b.</Text>
            </View>
            <View style={styles.indentedTermCol2}>
              <Text>
                When bundling products, any variation to the bundling
                configuration, including variations in size, will be regarded as
                a separate SKU and charged accordingly.
              </Text>
            </View>
          </View>
          <View style={styles.termRow}>
            <View style={styles.indentedTermCol1}>
              <Text>c.</Text>
            </View>
            <View style={styles.indentedTermCol2}>
              <Text>
                Upon receipt of your consignment stock, EasyOnline will do a SKU
                verification exercise. If SKUs do not match variation, size and
                weight as supplied during the costing phase the deal may be
                voided.
              </Text>
            </View>
          </View>
          <View style={styles.termRow}>
            <View style={styles.indentedTermCol1}>
              <Text>d.</Text>
            </View>
            <View style={styles.indentedTermCol2}>
              <Text>
                EasyOnline reserves the right to limit quantities sent per
                consignment.
              </Text>
            </View>
          </View>
          <View style={styles.termRow}>
            <View style={styles.indentedTermCol1}>
              <Text>e.</Text>
            </View>
            <View style={styles.indentedTermCol2}>
              <Text>
                EasyOnline determines the retail selling price and reserves the
                right to adjust the retail selling price, up or down, based on
                competition in the market in order to drive sales.
              </Text>
            </View>
          </View>
          <View style={styles.termRow}>
            <View style={styles.indentedTermCol1}>
              <Text>f.</Text>
            </View>
            <View style={styles.indentedTermCol2}>
              <Text>EasyOnline does not guarantee sales volumes.</Text>
            </View>
          </View>
          <View style={styles.termRow}>
            <View style={styles.standardTermCol1}>
              <Text>3.</Text>
            </View>
            <View style={styles.standardTermCol2}>
              <Text>
                If the customer paid for the item &quot;Product
                Photography&quot; EasyOnline will supply the customer with 1
                unique photographic image that complies with Takealot&apos;s
                standards per unit purchased.
              </Text>
            </View>
          </View>
          <View style={styles.termRow}>
            <View style={styles.standardTermCol1}>
              <Text>4.</Text>
            </View>
            <View style={styles.standardTermCol2}>
              <Text>
                Your inventory is insured from the time it leaves your premises
                in the event of loss, theft or damage only. As per Insurance
                Regulation the payout will be at the cost price (incl. VAT) per
                unit as detailed in Section 3.
              </Text>
            </View>
          </View>
          <View style={styles.termRow}>
            <View style={styles.standardTermCol1}>
              <Text>5.</Text>
            </View>
            <View style={styles.standardTermCol2}>
              <Text>
                Sales volumes are calculated on a monthly cycle according to
                standard calendar months. Payments will be processed a month in
                arrears.
              </Text>
            </View>
          </View>
          <View style={styles.termRow}>
            <View style={styles.indentedTermCol1}>
              <Text>a.</Text>
            </View>
            <View style={styles.indentedTermCol2}>
              <Text>
                The customer will be paid the wholesale price (incl. VAT), as
                detailed in Section 3 above, per unit sold.
              </Text>
            </View>
          </View>
          <View style={styles.termRow}>
            <View style={styles.indentedTermCol1}>
              <Text>b.</Text>
            </View>
            <View style={styles.indentedTermCol2}>
              <Text>
                EasyOnline will supply a statement of sales and will request the
                customer to raise an invoice accordingly.
              </Text>
            </View>
          </View>
          <View style={styles.termRow}>
            <View style={styles.standardTermCol1}>
              <Text>6.</Text>
            </View>
            <View style={styles.standardTermCol2}>
              <Text>
                Replenishment stock must be issued at the wholesale price, size
                and dimensions agreed upon in this Proposal. A change in the
                wholesale price, size or dimensions may render the product not
                viable. EasyOnline reserves the right to discontinue shipping
                should any of the aforementioned criteria change.
              </Text>
            </View>
          </View>
          <View style={styles.termRow}>
            <View style={styles.standardTermCol1}>
              <Text>7.</Text>
            </View>
            <View style={styles.standardTermCol2}>
              <Text>All fees paid to EasyOnline are non-refundable.</Text>
            </View>
          </View>
          <View style={styles.termRow}>
            <View style={styles.standardTermCol1}>
              <Text>8.</Text>
            </View>
            <View style={styles.standardTermCol2}>
              <Text>
                The courier costs for the transportation of replenishment stock
                will be deducted from the customer&apos;s monthly statement of
                sales.
              </Text>
            </View>
          </View>
          <View style={styles.termRow}>
            <View style={styles.standardTermCol1}>
              <Text>9.</Text>
            </View>
            <View style={styles.standardTermCol2}>
              <Text>
                This Proposal is available for approval by the customer via
                their signature for 14 days from date of issue. If this Proposal
                is not signed within that 14 day period, a new proposal must be
                issued.
              </Text>
            </View>
          </View>
          <View style={styles.termRow}>
            <View style={styles.standardTermCol1}>
              <Text>10.</Text>
            </View>
            <View style={styles.standardTermCol2}>
              <Text>
                The customer can cancel with 30 days written notice. Any unsold
                units will be sent back to the customer.
              </Text>
            </View>
          </View>
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

TakealotProposal.defaultProps = {
  businessName: 'Madeup Inc',
  creationDate: new Date(),
  setupFees: [
    {
      description: '',
      name: 'SKU Setup Fees',
      quantity: 2,
      total: 2000,
      unitPrice: 1000,
    },
    {
      description: '',
      name: 'Product Photography',
      quantity: 5,
      total: 750,
      unitPrice: 150,
    },
  ],
  listings: [
    {
      name: 'Sweater - Blue (XL)',
      units: 8,
      costPrice: 45,
      wholesalePrice: 90,
      retailPrice: 156,
    },
    {
      name: 'Shorts - Pink (XS)',
      units: 8,
      costPrice: 20,
      wholesalePrice: 70,
      retailPrice: 99,
    },
    {
      name: 'Mens Perfume (200ml)',
      units: 8,
      costPrice: 100,
      wholesalePrice: 150,
      retailPrice: 199,
    },
  ],
  subTotal: 2750,
  vat: 412.5,
  total: 3162.5,
};

TakealotProposal.propTypes = {
  businessName: PropTypes.string,
  creationDate: PropTypes.instanceOf(Date),
  setupFees: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      name: PropTypes.string,
      quantity: PropTypes.number,
      total: PropTypes.number,
      unitPrice: PropTypes.number,
    })
  ),
  listings: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      units: PropTypes.number,
      costPrice: PropTypes.number,
      wholesalePrice: PropTypes.number,
      retailPrice: PropTypes.number,
    })
  ),
  subTotal: PropTypes.number,
  vat: PropTypes.number,
  total: PropTypes.number,
};

export default TakealotProposal;
