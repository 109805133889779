import { firestore } from '../utils/firebase';

export const createNewDeal = async (dealInfo, proposalInfo) => {
  const batch = firestore.batch();
  const newDealRef = firestore.collection('deals').doc();
  const dealProposalRef = firestore
    .collection('deals')
    .doc(newDealRef.id)
    .collection('proposals')
    .doc('version-1');

  batch.set(newDealRef, dealInfo);
  batch.set(dealProposalRef, proposalInfo);

  if (dealInfo.dealType === 'SUPER_DEALER') {
    proposalInfo.listingInfo.forEach((info) => {
      const productRef = firestore.collection('sourcedProducts').doc(info.id);
      batch.update(productRef, {
        status: 'RESERVED',
      });
    });
  }

  return batch
    .commit()
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const createNewProposal = async (
  dealId,
  dealInfo,
  proposalInfo,
  removedProducts
) => {
  const batch = firestore.batch();
  const dealRef = firestore.collection('deals').doc(dealId);
  const dealProposalRef = firestore
    .collection('deals')
    .doc(dealId)
    .collection('proposals')
    .doc(`version-${proposalInfo.versionNumber}`);

  batch.update(dealRef, dealInfo);
  batch.set(dealProposalRef, proposalInfo);

  if (dealInfo.dealType === 'SUPER_DEALER') {
    proposalInfo.listingInfo.forEach((info) => {
      const productRef = firestore.collection('sourcedProducts').doc(info.id);
      batch.update(productRef, {
        status: 'RESERVED',
      });
    });
    removedProducts.forEach((info) => {
      const productRef = firestore.collection('sourcedProducts').doc(info.id);
      batch.update(productRef, {
        status: 'AVAILABLE',
      });
    });
  }

  return batch
    .commit()
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const markDealDeleted = async (
  dealId,
  dealType = 'NEW',
  listingInfo
) => {
  const batch = firestore.batch();
  const dealRef = firestore.collection('deals').doc(dealId);

  batch.update(dealRef, {
    status: 'DELETED',
    lastUpdated: new Date(),
  });

  if (dealType === 'SUPER_DEALER') {
    listingInfo.forEach((info) => {
      const productRef = firestore.collection('sourcedProducts').doc(info.id);
      batch.update(productRef, {
        status: 'AVAILABLE',
      });
    });
  }

  return batch
    .commit()
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const markDealFinalized = async (
  dealId,
  finalizedVersion,
  dealValue,
  skuCount,
  dealType,
  assignedProducts,
  removedProducts,
  finalBatchCode
) => {
  const batch = firestore.batch();
  const dealRef = firestore.collection('deals').doc(dealId);

  batch.update(dealRef, {
    finalizedVersion,
    dealValue,
    skuCount,
    status: 'FINALIZED',
    lastUpdated: new Date(),
    batchCode: finalBatchCode,
  });

  if (dealType === 'SUPER_DEALER') {
    assignedProducts.forEach((info) => {
      const productRef = firestore.collection('sourcedProducts').doc(info.id);
      batch.update(productRef, {
        status: 'ASSIGNED',
      });
    });
    removedProducts.forEach((info) => {
      const productRef = firestore.collection('sourcedProducts').doc(info.id);
      batch.update(productRef, {
        status: 'AVAILABLE',
      });
    });
  }

  return batch
    .commit()
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const markDealLost = async (dealId, dealType = 'NEW', listingInfo) => {
  const batch = firestore.batch();
  const dealRef = firestore.collection('deals').doc(dealId);

  batch.update(dealRef, {
    status: 'LOST',
    lastUpdated: new Date(),
  });

  if (dealType === 'SUPER_DEALER') {
    listingInfo.forEach((info) => {
      const productRef = firestore.collection('sourcedProducts').doc(info.id);
      batch.update(productRef, {
        status: 'AVAILABLE',
      });
    });
  }

  return batch
    .commit()
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const getDeal = async (dealId) => {
  const dealRef = firestore.collection('deals').doc(dealId);

  return dealRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        const dealInfo = { id: doc.id, ...doc.data() };
        const creationDate = dealInfo.creationDate.toDate().toISOString();
        const lastUpdated = dealInfo.lastUpdated.toDate().toISOString();

        return {
          info: {
            ...dealInfo,
            creationDate,
            lastUpdated,
          },
          status: 200,
        };
      }
      return {
        dealRef,
        status: 500,
      };
    })
    .catch((error) => {
      return {
        dealRef,
        error,
        status: 500,
      };
    });
};

export const getDeals = async (
  dateType,
  startDate,
  endDate,
  isAgent,
  userId
) => {
  let dealsRef = firestore.collection('deals');

  if (dateType === 'LAST_UPDATED') {
    dealsRef = dealsRef
      .where('lastUpdated', '>=', startDate)
      .where('lastUpdated', '<=', endDate)
      .orderBy('lastUpdated', 'desc');
  } else {
    dealsRef = dealsRef
      .where('creationDate', '>=', startDate)
      .where('creationDate', '<=', endDate)
      .orderBy('creationDate', 'desc');
  }

  return dealsRef
    .get()
    .then((querySnapshot) => {
      const deals = [];
      querySnapshot.forEach((doc) => {
        const dealInfo = { id: doc.id, ...doc.data() };
        const creationDate = dealInfo.creationDate.toDate().toISOString();
        const lastUpdated = dealInfo.lastUpdated.toDate().toISOString();

        if (dealInfo.status !== 'DELETED') {
          if (isAgent && dealInfo.salesPersonId === userId) {
            deals.push({
              ...dealInfo,
              creationDate,
              lastUpdated,
            });
          } else if (!isAgent) {
            deals.push({
              ...dealInfo,
              creationDate,
              lastUpdated,
            });
          }
        }
      });
      return {
        deals,
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const getDealProposals = async (dealId) => {
  const dealProposalsRef = firestore
    .collection('deals')
    .doc(dealId)
    .collection('proposals');

  return dealProposalsRef
    .get()
    .then((querySnapshot) => {
      const proposals = [];
      querySnapshot.forEach((doc) => {
        const proposalInfo = { id: doc.id, ...doc.data() };
        const creationDate = proposalInfo.creationDate.toDate().toISOString();

        proposals.push({
          creationDate,
          businessName: proposalInfo.businessName,
          id: proposalInfo.id,
          platform: proposalInfo.type,
          versionNumber: proposalInfo.versionNumber,
          status: proposalInfo.status,
          setupFees: proposalInfo.setupFees,
          listingInfo: proposalInfo.listingInfo,
          subTotal: proposalInfo.subTotal,
          vat: proposalInfo.vat,
          total: proposalInfo.total,
        });
      });
      return {
        proposals,
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const getDealInvoices = async (dealId) => {
  const dealInvoicesRef = firestore
    .collection('deals')
    .doc(dealId)
    .collection('invoices');

  return dealInvoicesRef
    .get()
    .then((querySnapshot) => {
      const invoices = [];
      querySnapshot.forEach((doc) => {
        const invoiceInfo = { id: doc.id, ...doc.data() };
        invoices.push({
          id: invoiceInfo.id,
          creationDate: invoiceInfo.creationDate.toDate().toISOString(),
          number: invoiceInfo.invoiceNumber,
          status: invoiceInfo.status,
        });
      });
      return {
        invoices,
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const getSalesAgents = async () => {
  const salesAgentsRef = firestore
    .collection('users')
    .where('type', '==', 'SALES_AGENT');

  return salesAgentsRef
    .get()
    .then((querySnapshot) => {
      const salesAgents = [];
      querySnapshot.forEach((doc) => {
        const agentInfo = doc.data();
        const relevantAgentInfo = {
          id: doc.id,
          name: `${agentInfo.name} ${agentInfo.surname}`,
          agencyId: agentInfo.agencyId || null,
          agencyName: agentInfo.agencyName || null,
        };
        salesAgents.push(relevantAgentInfo);
      });
      return {
        salesAgents,
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const getAgencies = async () => {
  const agencyRef = firestore.collection('agencies');

  return agencyRef
    .get()
    .then((querySnapshot) => {
      const agencies = [];
      querySnapshot.forEach((doc) => {
        const agencyInfo = doc.data();
        const relevantAgencyInfo = {
          id: doc.id,
          name: agencyInfo.name,
        };
        agencies.push(relevantAgencyInfo);
      });
      return {
        agencies,
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const getSourcedProducts = async (status = 'ALL', supplier = 'ALL') => {
  let sourcedProductsRef = firestore.collection('sourcedProducts');

  if (status !== 'ALL') {
    sourcedProductsRef = sourcedProductsRef.where('status', '==', status);
  }

  if (supplier !== 'ALL') {
    sourcedProductsRef = sourcedProductsRef.where('supplierId', '==', supplier);
  }

  return sourcedProductsRef
    .get()
    .then((querySnapshot) => {
      const products = [];
      querySnapshot.forEach((doc) => {
        const productInfo = doc.data();
        const creationDate = productInfo.creationDate.toDate().toISOString();
        const relevantProductInfo = {
          ...productInfo,
          creationDate,
          id: doc.id,
        };
        products.push(relevantProductInfo);
      });
      return {
        products,
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const getSourcedProduct = async (id) => {
  const productRef = firestore.collection('sourcedProducts').doc(id);

  return productRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        const productInfo = doc.data();
        return {
          productInfo: {
            id,
            ...productInfo,
            creationDate: productInfo.creationDate.toDate().toString(),
          },
          status: 200,
        };
      }
      return {
        id,
        status: 500,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const createSourcedProduct = async (productInfo) => {
  const newProductRef = firestore.collection('sourcedProducts').doc();

  return newProductRef
    .set(productInfo)
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const updateSourcedProduct = async (id, newInfo) => {
  const productRef = firestore.collection('sourcedProducts').doc(id);

  if (newInfo.creationDate) {
    return productRef
      .update({
        ...newInfo,
        creationDate: new Date(newInfo.creationDate),
      })
      .then(() => {
        return {
          status: 200,
        };
      })
      .catch((error) => {
        return {
          error,
          status: 500,
        };
      });
  }
  return productRef
    .update(newInfo)
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const deleteSourcedProduct = async (productId) => {
  const dealRef = firestore.collection('sourcedProducts').doc(productId);

  return dealRef
    .delete()
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};
