import { useEffect, useState } from 'react';
import _ from 'lodash';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {
  changeProduct,
  fetchProductInfo,
  fetchSuppliers,
  initiateStateReset,
} from './slice';
import ContentWrapper from '../../../components/ContentWrapper';
import PageHeader from '../../../components/PageHeader';
import takealotCategoryData from './takealotCategories';

const useStyles = makeStyles((theme) => ({
  actionsBar: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  alert: {
    marginBottom: theme.spacing(3),
  },
  disabled: {
    color: 'rgba(255, 255, 255, 0.8)',
  },
  formControl: {
    width: '100%',
  },
  formWrapper: {
    padding: `${theme.spacing(4)}px ${theme.spacing(1)}px`,
  },
  section: {
    margin: `${theme.spacing(6)}px 0`,
  },
  sectionHeading: {
    color: grey[600],
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  separator: {
    height: theme.spacing(2),
  },
  wrapperStep: {
    padding: `${theme.spacing(4)}px 0px`,
  },
  wrapperWizard: {
    width: '100%',
    padding: theme.spacing(4),
  },
}));

const breadcrumbs = [
  {
    label: 'Sales',
    link: '/sales',
  },
  {
    label: 'Products',
    link: '/sales/products',
  },
];

const SourcedProductsEdit = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);

  const [description, setDescription] = useState('');
  const [supplierId, setSupplierId] = useState('');
  const [department, setDepartment] = useState('');
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [sizeCategory, setSizeCategory] = useState('');
  const [weightCategory, setWeightCategory] = useState('');
  const [fees, setFees] = useState({
    easyonlineFee: 0,
    fulfilmentFee: 0,
    fulfilmentFeeVat: 0,
    handlingFee: 0,
    insuranceFee: 0,
    successFee: 0,
    successFeeVat: 0,
  });
  const [margins, setMargins] = useState({
    easyonlineMargin: 0,
    takealotSuccessFee: 0,
    total: 0,
  });
  const [measurementType, setMeasurementType] = useState('');
  const [weight, setWeight] = useState('');
  const [length, setLength] = useState('');
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');
  const [wholesalePrice, setWholesalePrice] = useState('');
  const [retailPrice, setRetailPrice] = useState('');
  const [costPrice, setCostPrice] = useState('');

  const [errors, setErrors] = useState({
    categories: false,
    measurements: false,
  });

  const suppliersLoadingStatus = useSelector(
    (state) => state.sourcedProductsEdit.loadingStatus.suppliers
  );
  const updateLoadingStatus = useSelector(
    (state) => state.sourcedProductsEdit.loadingStatus.update
  );
  const productInfoLoadingStatus = useSelector(
    (state) => state.sourcedProductsEdit.loadingStatus.productInfo
  );
  const isUserRedirectedToBrowse = useSelector(
    (state) => state.sourcedProductsEdit.isUserRedirectedToBrowse
  );
  const productInfo = useSelector(
    (state) => state.sourcedProductsEdit.productInfo
  );
  const suppliers = useSelector((state) => state.sourcedProductsEdit.suppliers);

  useEffect(() => {
    dispatch(fetchProductInfo(id));
    if (suppliersLoadingStatus !== 'COMPLETE') {
      dispatch(fetchSuppliers());
    }
  }, []);

  useEffect(() => {
    if (
      updateLoadingStatus === 'PENDING' ||
      productInfoLoadingStatus === 'PENDING' ||
      suppliersLoadingStatus === 'PENDING'
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [updateLoadingStatus, productInfoLoadingStatus, suppliersLoadingStatus]);

  useEffect(() => {
    if (isUserRedirectedToBrowse) {
      dispatch(initiateStateReset());
      setIsLoading(true);
      history.goBack();
    }
  }, [isUserRedirectedToBrowse]);

  useEffect(() => {
    setDescription(productInfo.description);
    setSupplierId(productInfo.supplierId);
    setFees(productInfo.fees);
    setMargins(productInfo.margins);
    if (productInfo.categories) {
      setDepartment(productInfo.categories.department);
      setCategory(productInfo.categories.category);
      setSubCategory(productInfo.categories.subCategory);
      setSizeCategory(productInfo.categories.size);
      setWeightCategory(productInfo.categories.weight);
    }
    if (productInfo.stockInfo) {
      setMeasurementType(
        productInfo.stockInfo.isGiven ? 'ACTUAL' : 'ESTIMATED'
      );
      setWeight(productInfo.stockInfo.weight);
      setLength(productInfo.stockInfo.length);
      setWidth(productInfo.stockInfo.width);
      setHeight(productInfo.stockInfo.height);
    }
    if (productInfo.pricing) {
      setWholesalePrice(productInfo.pricing.wholesalePrice);
      setRetailPrice(productInfo.pricing.retailPrice);
      setCostPrice(productInfo.pricing.costPrice);
    }
  }, [productInfo]);

  useEffect(() => {
    if (department && category && subCategory) {
      // eslint-disable-next-line no-use-before-define
      updateBasedOnWholesalePricing(wholesalePrice);
    }
  }, [weight, length, width, height, department, category, subCategory]);

  const getDepartments = () => {
    return _.uniq(takealotCategoryData.map((data) => data.department))
      .filter((data) => data !== '')
      .sort((departmentA, departmentB) => {
        if (departmentA > departmentB) return +1;
        if (departmentA < departmentB) return -1;
        return 0;
      });
  };

  const getCategories = () => {
    return _.uniq(
      takealotCategoryData
        .filter((data) => data.department === department)
        .map((data) => data.category)
    ).sort((categoryA, categoryB) => {
      if (categoryA > categoryB) return +1;
      if (categoryA < categoryB) return -1;
      return 0;
    });
  };

  const getSubCategories = () => {
    return _.uniq(
      takealotCategoryData
        .filter(
          (data) => data.department === department && data.category === category
        )
        .map((data) => data.subCategory)
    ).sort((subCategoryA, subCategoryB) => {
      if (subCategoryA > subCategoryB) return +1;
      if (subCategoryA < subCategoryB) return -1;
      return 0;
    });
  };

  const getSizeCategory = (volume) => {
    if (volume <= 35000) {
      return 'Standard';
    }
    if (volume <= 130000) {
      return 'Large';
    }
    if (volume <= 200000) {
      return 'Oversize';
    }
    if (volume <= 545000) {
      return 'Bulky';
    }
    return 'Extra Bulky';
  };

  const getWeightCategory = () => {
    if (weight <= 7) {
      return 'Light';
    }
    if (weight <= 25) {
      return 'Heavy';
    }
    if (weight <= 40) {
      return 'Heavy Plus';
    }
    return 'Very Heavy';
  };

  const getFulfilmentFee = (givenSizeCategory, givenWeightCategory) => {
    switch (givenSizeCategory) {
      case 'Standard':
        switch (givenWeightCategory) {
          case 'Light':
            return 32;
          case 'Heavy':
            return 36;
          case 'Heavy Plus':
            return 85;
          case 'Very Heavy':
            return 85;
          default:
            return 0;
        }
      case 'Large':
        switch (givenWeightCategory) {
          case 'Light':
            return 42;
          case 'Heavy':
            return 46;
          case 'Heavy Plus':
            return 85;
          case 'Very Heavy':
            return 95;
          default:
            return 0;
        }
      case 'Oversize':
        switch (givenWeightCategory) {
          case 'Light':
            return 95;
          case 'Heavy':
            return 105;
          case 'Heavy Plus':
            return 125;
          case 'Very Heavy':
            return 100;
          default:
            return 0;
        }
      case 'Bulky':
        switch (givenWeightCategory) {
          case 'Light':
            return 95;
          case 'Heavy':
            return 115;
          case 'Heavy Plus':
            return 130;
          case 'Very Heavy':
            return 140;
          default:
            return 0;
        }
      case 'Extra Bulky':
        switch (givenWeightCategory) {
          case 'Light':
            return 220;
          case 'Heavy':
            return 220;
          case 'Heavy Plus':
            return 270;
          case 'Very Heavy':
            return 325;
          default:
            return 0;
        }
      default:
        return 0;
    }
  };

  const calculateRetailPrice = (
    flatFees,
    givenMargins,
    givenWholesalePrice
  ) => {
    return (flatFees + givenWholesalePrice) / (1 - givenMargins);
  };

  const getSuccessFeePercentage = () => {
    const relevantSubCategory = takealotCategoryData.find(
      (data) =>
        data.department === department &&
        data.category === category &&
        data.subCategory === subCategory
    );
    return relevantSubCategory.successFee || 0;
  };

  const updateBasedOnWholesalePricing = (newWholesalePrice) => {
    const volume = length * width * height;
    const insurancePercentage = 0.0075;
    const handlingFee = 5;

    const tempSizeCategory = getSizeCategory(volume);
    const tempWeightCategory = getWeightCategory(weight);
    const fulfilmentFee = getFulfilmentFee(sizeCategory, weightCategory);
    const successFeePercentage =
      getSuccessFeePercentage(department, category, subCategory) * 1.15;
    const fulfilmentFeeVat = fulfilmentFee * 1.15;
    const easyonlineMarginPercentage = 0.1;
    const totalMargin = successFeePercentage + easyonlineMarginPercentage;

    const insuranceFee = costPrice * insurancePercentage;
    const newRetailPrice = Math.ceil(
      calculateRetailPrice(
        fulfilmentFeeVat + insuranceFee + handlingFee,
        totalMargin,
        parseFloat(newWholesalePrice)
      )
    );

    setFees({
      fulfilmentFee,
      fulfilmentFeeVat,
      handlingFee,
      insuranceFee,
      easyonlineFee: newRetailPrice * easyonlineMarginPercentage,
      successFee: newRetailPrice * successFeePercentage,
      successFeeVat: newRetailPrice * successFeePercentage * 1.15,
    });
    setMargins({
      easyonlineMargin: easyonlineMarginPercentage,
      takealotSuccessFee: successFeePercentage,
      total: totalMargin,
    });
    setSizeCategory(tempSizeCategory);
    setWeightCategory(tempWeightCategory);
    setRetailPrice(newRetailPrice);
  };

  const updateBasedOnCostPricing = (newCostPrice) => {
    const volume = length * width * height;
    const insurancePercentage = 0.0075;
    const handlingFee = 5;

    const tempSizeCategory = getSizeCategory(volume);
    const tempWeightCategory = getWeightCategory(weight);
    const fulfilmentFee = getFulfilmentFee(sizeCategory, weightCategory);
    const successFeePercentage =
      getSuccessFeePercentage(department, category, subCategory) * 1.15;
    const fulfilmentFeeVat = fulfilmentFee * 1.15;
    const easyonlineMarginPercentage = 0.1;
    const totalMargin = successFeePercentage + easyonlineMarginPercentage;

    const insuranceFee = parseFloat(newCostPrice) * insurancePercentage;
    const newRetailPrice = Math.ceil(
      calculateRetailPrice(
        fulfilmentFeeVat + insuranceFee + handlingFee,
        totalMargin,
        parseFloat(newCostPrice * 2)
      )
    );

    setFees({
      fulfilmentFee,
      fulfilmentFeeVat,
      handlingFee,
      insuranceFee,
      easyonlineFee: newRetailPrice * easyonlineMarginPercentage,
      successFee: newRetailPrice * successFeePercentage,
      successFeeVat: newRetailPrice * successFeePercentage * 1.15,
    });
    setMargins({
      easyonlineMargin: easyonlineMarginPercentage,
      takealotSuccessFee: successFeePercentage,
      total: totalMargin,
    });
    setSizeCategory(tempSizeCategory);
    setWeightCategory(tempWeightCategory);
    setRetailPrice(newRetailPrice);
  };

  const checkIsInfoComplete = () => {
    const isCategoriesComplete =
      department.length > 0 && category.length > 0 && subCategory.length > 0;
    const isMeasurementsComplete =
      weight > 0 && length > 0 && width > 0 && height > 0;

    setErrors({
      categories: !isCategoriesComplete,
      measurements: !isMeasurementsComplete,
    });

    return {
      categories: isCategoriesComplete,
      measurements: isMeasurementsComplete,
    };
  };

  const getSupplierName = (givenSupplierId) => {
    const relevantSupplier = suppliers.find(
      (supplierInfo) => supplierInfo.id === givenSupplierId
    );

    if (relevantSupplier) {
      return relevantSupplier.name;
    }

    return 'Unknown';
  };

  return (
    <div>
      <PageHeader
        isLoading={isLoading}
        title="Edit Product"
        subtitle={isLoading ? 'Loading...' : description}
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Edit"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
      />
      <ContentWrapper>
        <Paper className={classes.wrapperWizard}>
          <div className={classes.section}>
            <Typography
              className={classes.sectionHeading}
              variant="h6"
              component="h5"
            >
              Basic Info
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  label="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id="supplier-select-label">Supplier</InputLabel>
                  <Select
                    labelId="supplier-select-label"
                    id="supplier-select"
                    label="Supplier"
                    value={supplierId}
                    onChange={(e) => {
                      setSupplierId(e.target.value);
                    }}
                  >
                    {suppliers.map((supplierInfo) => (
                      <MenuItem key={supplierInfo.id} value={supplierInfo.id}>
                        {supplierInfo.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>
          <div className={classes.section}>
            <Typography
              className={classes.sectionHeading}
              variant="h6"
              component="h5"
            >
              Categories
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id="department-select-label">
                    Department
                  </InputLabel>
                  <Select
                    labelId="department-select-label"
                    id="department-select"
                    label="Department"
                    value={department}
                    onChange={(e) => {
                      setDepartment(e.target.value);
                      setCategory(undefined);
                      setSubCategory(undefined);
                    }}
                  >
                    {getDepartments().map((departmentItem) => (
                      <MenuItem
                        key={`department-${departmentItem}`}
                        value={departmentItem}
                      >
                        {departmentItem}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id="category-select-label">Category</InputLabel>
                  <Select
                    labelId="category-select-label"
                    id="category-select"
                    disabled={department === undefined}
                    value={category}
                    onChange={(e) => {
                      setCategory(e.target.value);
                      setSubCategory(undefined);
                    }}
                  >
                    {getCategories().map((categoryItem) => (
                      <MenuItem
                        key={`category-${categoryItem}`}
                        value={categoryItem}
                      >
                        {categoryItem}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id="subcategory-select-label">
                    Sub-Category
                  </InputLabel>
                  <Select
                    labelId="subcategory-select-label"
                    id="subcategory-select"
                    disabled={category === undefined}
                    value={subCategory}
                    onChange={(e) => setSubCategory(e.target.value)}
                  >
                    {getSubCategories().map((categoryItem) => (
                      <MenuItem
                        key={`category-${categoryItem}`}
                        value={categoryItem}
                      >
                        {categoryItem}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>
          <div className={classes.section}>
            <Typography
              className={classes.sectionHeading}
              variant="h6"
              component="h5"
            >
              Measurements
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id="measurements-type-select-label">
                    Measurements Type
                  </InputLabel>
                  <Select
                    labelId="measurements-type-select-label"
                    id="measurements-type-select"
                    fullWidth
                    value={measurementType}
                    onChange={(e) => {
                      setMeasurementType(e.target.value);
                      if (e.target.value === 'ESTIMATED') {
                        setWeight(1);
                        setLength(1);
                        setWidth(1);
                        setHeight(1);
                      }
                    }}
                  >
                    <MenuItem value="ESTIMATED">Estimated</MenuItem>
                    <MenuItem value="ACTUAL">Actual</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  disabled={measurementType === 'ESTIMATED'}
                  label="Weight"
                  InputProps={{
                    classes: {
                      disabled: classes.disabled,
                    },
                    endAdornment: (
                      <InputAdornment
                        classes={{
                          positionEnd: classes.adornment,
                        }}
                        disableTypography
                        position="end"
                      >
                        kg
                      </InputAdornment>
                    ),
                  }}
                  type="number"
                  min={0}
                  value={weight}
                  onChange={(e) => setWeight(parseFloat(e.target.value))}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  disabled={measurementType === 'ESTIMATED'}
                  label="Length"
                  InputProps={{
                    classes: {
                      disabled: classes.disabled,
                    },
                    endAdornment: (
                      <InputAdornment
                        classes={{
                          positionEnd: classes.adornment,
                        }}
                        disableTypography
                        position="end"
                      >
                        cm
                      </InputAdornment>
                    ),
                  }}
                  min={0}
                  type="number"
                  value={length}
                  onChange={(e) => setLength(parseFloat(e.target.value))}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  disabled={measurementType === 'ESTIMATED'}
                  label="Width"
                  InputProps={{
                    classes: {
                      disabled: classes.disabled,
                    },
                    endAdornment: (
                      <InputAdornment
                        classes={{
                          positionEnd: classes.adornment,
                        }}
                        disableTypography
                        position="end"
                      >
                        cm
                      </InputAdornment>
                    ),
                  }}
                  min={0}
                  type="number"
                  value={width}
                  onChange={(e) => setWidth(parseFloat(e.target.value))}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  disabled={measurementType === 'ESTIMATED'}
                  label="Height"
                  InputProps={{
                    classes: {
                      disabled: classes.disabled,
                    },
                    endAdornment: (
                      <InputAdornment
                        classes={{
                          positionEnd: classes.adornment,
                        }}
                        disableTypography
                        position="end"
                      >
                        cm
                      </InputAdornment>
                    ),
                  }}
                  min={0}
                  type="number"
                  value={height}
                  onChange={(e) => setHeight(parseFloat(e.target.value))}
                />
              </Grid>
            </Grid>
          </div>
          <div className={classes.section}>
            <Typography
              className={classes.sectionHeading}
              variant="h6"
              component="h5"
            >
              Prices
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  label="Supplier Cost Price"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        classes={{
                          positionStart: classes.adornment,
                        }}
                        disableTypography
                        position="start"
                      >
                        R
                      </InputAdornment>
                    ),
                  }}
                  type="number"
                  value={costPrice}
                  onChange={(e) => {
                    const isInfoComplete = checkIsInfoComplete();

                    if (
                      isInfoComplete.categories &&
                      isInfoComplete.measurements
                    ) {
                      setWholesalePrice(parseFloat(e.target.value) * 2);
                      setCostPrice(parseFloat(e.target.value));
                      updateBasedOnCostPricing(parseFloat(e.target.value));
                    }
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  label="Wholesale Price"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        classes={{
                          positionStart: classes.adornment,
                        }}
                        disableTypography
                        position="start"
                      >
                        R
                      </InputAdornment>
                    ),
                  }}
                  type="number"
                  value={wholesalePrice}
                  onChange={(e) => {
                    const isInfoComplete = checkIsInfoComplete();

                    if (
                      isInfoComplete.categories &&
                      isInfoComplete.measurements
                    ) {
                      setWholesalePrice(parseFloat(e.target.value));
                      updateBasedOnWholesalePricing(parseFloat(e.target.value));
                    }
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  disabled
                  fullWidth
                  label="Markup"
                  InputProps={{
                    classes: {
                      disabled: classes.disabled,
                    },
                    endAdornment: (
                      <InputAdornment
                        classes={{
                          positionStart: classes.adornment,
                        }}
                        disableTypography
                        position="end"
                      >
                        %
                      </InputAdornment>
                    ),
                  }}
                  type="number"
                  value={Math.round(
                    ((wholesalePrice - costPrice) / costPrice) * 100
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  disabled
                  label="Retail Price"
                  InputProps={{
                    classes: {
                      disabled: classes.disabled,
                    },
                    startAdornment: (
                      <InputAdornment
                        classes={{
                          positionStart: classes.adornment,
                        }}
                        disableTypography
                        position="start"
                      >
                        R
                      </InputAdornment>
                    ),
                  }}
                  type="number"
                  value={retailPrice}
                />
              </Grid>
            </Grid>
          </div>
          {errors.categories && (
            <Alert className={classes.alert} severity="error">
              Please select a department, category and sub-category for this
              SKU.
            </Alert>
          )}
          {errors.measurements && (
            <Alert className={classes.alert} severity="error">
              Please specify the weight, length, width and height of this SKU.
            </Alert>
          )}
          <div className={classes.actionsBar}>
            <Button
              disabled={isLoading || errors.categories || errors.measurements}
              variant="contained"
              color="secondary"
              onClick={() =>
                dispatch(
                  changeProduct(id, {
                    ...productInfo,
                    description,
                    fees,
                    margins,
                    supplierId,
                    supplierName: getSupplierName(supplierId),
                    categories: {
                      department,
                      category,
                      subCategory,
                      size: sizeCategory,
                      weight: weightCategory,
                    },
                    pricing: {
                      costPrice,
                      wholesalePrice,
                      retailPrice,
                    },
                    stockInfo: {
                      isGiven: measurementType === 'ACTUAL',
                      weight,
                      length,
                      width,
                      height,
                    },
                  })
                )
              }
            >
              Update
            </Button>
          </div>
        </Paper>
      </ContentWrapper>
    </div>
  );
};

export default SourcedProductsEdit;
