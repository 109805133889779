import { CSVReader } from 'react-papaparse';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import numeral from 'numeral';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';
import {
  setTotalSkusToUpdate,
  fetchSupplierInfo,
  updatePricingInfo,
  initiateStateReset,
} from './slice';
import ContentWrapper from '../../../components/ContentWrapper';
import PageHeader from '../../../components/PageHeader';
import PreviewTable from './components/PreviewTable';
import allStyles from '../../../utils/styles';

const useStyles = makeStyles(allStyles);

const breadcrumbs = [
  {
    label: 'Presales',
    link: '/presales',
  },
  {
    label: 'Suppliers',
    link: '/presales/suppliers',
  },
  {
    label: 'Browse',
    link: '/presales/suppliers/browse',
  },
];

const UpdatePricing = () => {
  const classes = useStyles();
  const history = useHistory();
  const fileUploadRef = useRef();
  const dispatch = useDispatch();
  const { supplierId } = useParams();

  const [csvColumns, setCsvColumns] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [supplierSkuIndex, setSupplierSkuIndex] = useState('');
  const [costPerUnitIndex, setCostPerUnitIndex] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [validation, setValidation] = useState({
    isErrorShowing: false,
    message: '',
    errorStep: 0,
  });

  const updateLoadingCountTotal = useSelector(
    (state) =>
      state.presalesSuppliersUpdatePricing.pricingUpdateLoadingCount.total
  );
  const updateLoadingCountFailed = useSelector(
    (state) =>
      state.presalesSuppliersUpdatePricing.pricingUpdateLoadingCount.failed
  );
  const updateLoadingCountSuccess = useSelector(
    (state) =>
      state.presalesSuppliersUpdatePricing.pricingUpdateLoadingCount.success
  );
  const failedPricingSkus = useSelector(
    (state) => state.presalesSuppliersUpdatePricing.failedPricingSkus
  );
  const supplierInfo = useSelector(
    (state) => state.presalesSuppliersUpdatePricing.supplierInfo
  );
  const supplierInfoLoadingStatus = useSelector(
    (state) => state.presalesSuppliersUpdatePricing.loadingStatus.supplierInfo
  );
  const isUserRedirectedToBrowse = useSelector(
    (state) => state.presalesSuppliersUpdatePricing.isUserRedirectedToBrowse
  );

  useEffect(() => {
    dispatch(fetchSupplierInfo(supplierId));
  }, []);

  useEffect(() => {
    setCostPerUnitIndex(supplierInfo.pricingColumns.costPerUnitIndex);
    setSupplierSkuIndex(supplierInfo.pricingColumns.supplierSkuPricingIndex);
  }, [supplierInfo]);

  useEffect(() => {
    if (supplierInfoLoadingStatus === 'PENDING') {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [supplierInfoLoadingStatus]);

  useEffect(() => {
    if (isUserRedirectedToBrowse) {
      dispatch(initiateStateReset());
      setIsLoading(true);
      history.goBack();
    }
  }, [isUserRedirectedToBrowse]);

  useEffect(() => {
    if (fileUploadRef.current) {
      fileUploadRef.current.removeFile();
    }
  }, [activeStep]);

  const formatPricingUpdate = (data) => {
    const formattedData = data
      .filter((_, index) => index > 0 && index < data.length - 1)
      .map((item) => item.data);

    setCsvColumns(data[0].data);
    setCsvData(formattedData);
  };

  const handleFileLoad = (data) => {
    formatPricingUpdate(data);
  };

  const handleError = (err) => {
    console.log(err);
  };

  const updatePresaleSkuPricing = () => {
    csvData.forEach((item) => {
      dispatch(
        updatePricingInfo(item[supplierSkuIndex], {
          supplierSku: item[supplierSkuIndex],
          supplierName: supplierInfo.name,
          costPerUnit: numeral(item[costPerUnitIndex]).value(),
        })
      );
    });
  };

  const getLoadingValue = () => {
    if (updateLoadingCountTotal === 0) {
      return -1;
    }
    return Math.round(
      ((updateLoadingCountSuccess + updateLoadingCountFailed) /
        updateLoadingCountTotal) *
        100
    );
  };

  const skuLoadingValue = getLoadingValue(
    updateLoadingCountTotal,
    updateLoadingCountSuccess,
    updateLoadingCountFailed
  );

  const onNextClick = () => {
    switch (activeStep) {
      case 0:
        if (csvColumns.length === 0) {
          setValidation({
            isErrorShowing: true,
            errorStep: 0,
            message: 'Please upload a CSV file',
          });
        } else {
          setValidation({
            isErrorShowing: false,
            message: '',
            errorStep: 1,
          });

          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        break;
      case 1:
        dispatch(setTotalSkusToUpdate(csvData.length));
        updatePresaleSkuPricing();
        setValidation({
          isErrorShowing: false,
          message: '',
          errorStep: 2,
        });
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        break;
      case 2:
        history.goBack();
        break;
      default:
        console.log('Unknown step');
        break;
    }
  };

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <div className={classes.wrapperStep}>
            <Typography variant="h4" gutterBottom>
              Upload CSV for Pricing Update
            </Typography>
            <div className={classes.separator} />
            <Typography variant="body1">
              Please upload a CSV document containing the updated cost per unit
              along with the Supplier SKU for each product.
            </Typography>
            <div className={classes.separator} />
            <CSVReader
              ref={fileUploadRef}
              onDrop={handleFileLoad}
              onError={handleError}
              addRemoveButton
            >
              <span>Drop CSV file here or click to upload.</span>
            </CSVReader>
            {validation.isErrorShowing && validation.errorStep === 0 && (
              <Alert className={classes.alert} severity="error">
                {validation.message}
              </Alert>
            )}
          </div>
        );
      case 1:
        return (
          <div className={classes.wrapperStep}>
            <Typography variant="h4" gutterBottom>
              Confirm Pricing Information
            </Typography>

            <div className={classes.separator} />
            <Typography variant="body1">
              Please examine the preview table below to confirm that the correct
              columns have been selected. The selected columns can be changed in
              Update Pricing Settings.
            </Typography>

            <div className={classes.separator} />
            <PreviewTable
              csvData={csvData}
              supplierSkuIndex={supplierSkuIndex}
              costPerUnitIndex={costPerUnitIndex}
            />
          </div>
        );
      case 2:
        return (
          <div>
            {skuLoadingValue < 100 && skuLoadingValue !== -1 && (
              <div>
                <LinearProgress
                  color="secondary"
                  className={classes.loadingIndicator}
                  variant="determinate"
                  value={skuLoadingValue}
                />
                <Typography component="p" variant="subtitle1" align="center">
                  Updating SKUs...
                </Typography>
              </div>
            )}
            {skuLoadingValue >= 100 && updateLoadingCountFailed === 0 && (
              <div>
                <Alert className={classes.alert} severity="success">
                  All Supplier SKUs have been updated.
                </Alert>
              </div>
            )}
            {skuLoadingValue >= 100 && updateLoadingCountFailed > 0 && (
              <div>
                <Alert className={classes.alert} severity="warning">
                  {`${updateLoadingCountFailed} Supplier SKUs failed to update. ${updateLoadingCountSuccess} Supplier SKUs successfully updated.`}
                </Alert>
                <div className={classes.separator} />
                <Typography component="p" variant="subtitle1">
                  Failed Supplier SKUs:
                </Typography>
                <List>
                  {failedPricingSkus.map((failedInfo) => (
                    <ListItem>
                      <ListItemText primary={failedInfo.sku} />
                    </ListItem>
                  ))}
                </List>
              </div>
            )}
            <div className={classes.separator} />
          </div>
        );
      default:
        return <div />;
    }
  };

  return (
    <div>
      <PageHeader
        isLoading={isLoading}
        title="Update Pricing Settings"
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Edit Settings"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
      />
      <ContentWrapper>
        <Paper className={classes.wrapperWizard}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Stepper activeStep={activeStep} orientation="vertical">
                <Step>
                  <StepLabel>Upload CSV</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Preview Columns</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Update Pricing</StepLabel>
                </Step>
              </Stepper>
            </Grid>
            <Grid item xs={9}>
              {getStepContent()}
              <div className={classes.actionsBar}>
                <Button
                  disabled={activeStep === 0 || isLoading}
                  onClick={() => setActiveStep(activeStep - 1)}
                >
                  Previous
                </Button>
                <Button
                  disabled={isLoading}
                  variant="contained"
                  color="secondary"
                  onClick={onNextClick}
                >
                  {activeStep !== 2 ? 'Next' : 'Done'}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </ContentWrapper>
    </div>
  );
};

export default UpdatePricing;
