/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  getShipments,
  getWarehouses,
  updateShipmentStatus,
  flagShipmentCompleted,
} from '../../../api/logisticsAPI';

const initialState = {
  shipments: [],
  warehousesList: [],
  loadingStatus: {
    shipments: 'NOT_STARTED',
    updateShipments: 'NOT_STARTED',
    warehouses: 'NOT_STARTED',
  },
  pageSize: 5,
  initialPage: 0,
};

const logisticsShipmentSlice = createSlice({
  name: 'logisticsShipment',
  initialState,
  reducers: {
    getShipmentsStarted(state) {
      state.loadingStatus.shipments = 'PENDING';
    },
    getShipmentsSuccess(state, action) {
      state.shipments = action.payload;
      state.loadingStatus.shipments = 'COMPLETE';
    },
    getShipmentsFailed(state, action) {
      state.loadingStatus.shipments = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    updateShipmentStarted(state) {
      state.loadingStatus.updateShipment = 'PENDING';
    },
    updateShipmentSuccess(state, action) {
      state.shipments = state.shipments.map((info) => {
        if (info.id === action.payload.id) {
          return {
            ...info,
            status: action.payload.status,
          };
        }
        return info;
      });
      state.loadingStatus.updateShipment = 'COMPLETE';
    },
    updateShipmentFailed(state, action) {
      state.loadingStatus.updateShipment = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    markCompletedStarted(state) {
      state.loadingStatus.updateShipment = 'PENDING';
    },
    markCompletedSuccess(state, action) {
      state.shipments = state.shipments.map((info) => {
        if (info.id === action.payload.id) {
          return {
            ...info,
            status: 'COMPLETE',
            discrepancies: action.payload.discrepancies,
          };
        }
        return info;
      });
      state.loadingStatus.updateShipment = 'COMPLETE';
    },
    markCompletedFailed(state, action) {
      state.loadingStatus.updateShipment = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getWarehousesStarted(state) {
      state.loadingStatus.warehouses = 'PENDING';
    },
    getWarehousesSuccess(state, action) {
      state.warehousesList = action.payload;
      state.loadingStatus.warehouses = 'COMPLETE';
    },
    getWarehousesFailed(state, action) {
      state.loadingStatus.warehouses = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    changePageSize(state, action) {
      state.pageSize = action.payload;
    },
    changeInitialPage(state, action) {
      state.initialPage = action.payload;
    },
  },
});

export const {
  getShipmentsStarted,
  getShipmentsSuccess,
  getShipmentsFailed,
  updateShipmentStarted,
  updateShipmentSuccess,
  updateShipmentFailed,
  markCompletedStarted,
  markCompletedSuccess,
  markCompletedFailed,
  getWarehousesStarted,
  getWarehousesSuccess,
  getWarehousesFailed,
  changePageSize,
  changeInitialPage,
} = logisticsShipmentSlice.actions;

export const fetchShipments = () => async (dispatch) => {
  dispatch(getShipmentsStarted());
  const result = await getShipments();

  if (result.status === 200) {
    dispatch(getShipmentsSuccess(result.shipments));
  } else {
    dispatch(getShipmentsFailed(result.error));
  }
};

export const changeShipmentStatus = (id, status) => async (dispatch) => {
  dispatch(updateShipmentStarted());
  const result = await updateShipmentStatus(id, status);

  if (result.status === 200) {
    dispatch(updateShipmentSuccess({ id, status }));
  } else {
    dispatch(updateShipmentFailed(result.error));
  }
};

export const markShipmentCompleted =
  (id, discrepancies) => async (dispatch) => {
    dispatch(markCompletedStarted());
    const result = await flagShipmentCompleted(id, discrepancies);

    if (result.status === 200) {
      dispatch(markCompletedSuccess({ id, discrepancies }));
    } else {
      dispatch(markCompletedFailed(result.error));
    }
  };

export const fetchWarehouses = () => async (dispatch) => {
  dispatch(getWarehousesStarted());
  const result = await getWarehouses();

  if (result.status === 200) {
    dispatch(getWarehousesSuccess(result.warehouses));
  } else {
    dispatch(getWarehousesFailed(result.error));
  }
};

export const updatePageSize = (newPageSize) => (dispatch) => {
  dispatch(changePageSize(newPageSize));
};

export const updateInitialPage = (newInitialPage) => (dispatch) => {
  dispatch(changeInitialPage(newInitialPage));
};

export default logisticsShipmentSlice.reducer;
