import moment from 'moment';
import { firestore } from '../utils/firebase';

export const createNewListing = async (sku, info) => {
  const newListingRef = firestore.collection('skus').doc(sku);

  return newListingRef
    .set(info)
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const createNewClient = async (clientId, info) => {
  const newClientRef = firestore.collection('clients').doc(clientId);

  return newClientRef
    .set(info)
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const createNewStatementReport = async (reportInfo) => {
  const reportId = `${moment(reportInfo.startDate).format('YYYYMMDD')}-${moment(
    reportInfo.endDate
  ).format('YYYYMMDD')}`;
  const newReportRef = firestore.collection('statementReports').doc(reportId);

  return newReportRef
    .set(reportInfo)
    .then(() => {
      return {
        reportInfo: {
          ...reportInfo,
          reportId,
          startDate: reportInfo.startDate.toISOString(),
          endDate: reportInfo.endDate.toISOString(),
        },
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const finalizeAmazonStatement = async (reportId, statementId) => {
  const statementRef = firestore
    .collection('statementReports')
    .doc(reportId)
    .collection('amazonStatements')
    .doc(statementId);

  return statementRef
    .update({
      status: 'FINALIZED',
    })
    .then(() => {
      return {
        reportId,
        statementId,
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        reportId,
        statementId,
        status: 500,
      };
    });
};
export const finalizeTakealotStatement = async (reportId, statementId) => {
  const statementRef = firestore
    .collection('statementReports')
    .doc(reportId)
    .collection('takealotStatements')
    .doc(statementId);

  return statementRef
    .update({
      status: 'FINALIZED',
    })
    .then(() => {
      return {
        reportId,
        statementId,
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        reportId,
        statementId,
        status: 500,
      };
    });
};

export const addAmazonStatementToReport = async (
  reportId,
  statementId,
  statementInfo
) => {
  const newStatementRef = firestore
    .collection('statementReports')
    .doc(reportId)
    .collection('amazonStatements')
    .doc(statementId);

  return newStatementRef
    .set(statementInfo)
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        statementInfo,
        status: 500,
      };
    });
};

export const addTakealotStatementToReport = async (
  reportId,
  statementId,
  statementInfo
) => {
  const newStatementRef = firestore
    .collection('statementReports')
    .doc(reportId)
    .collection('takealotStatements')
    .doc(statementId);

  return newStatementRef
    .set(statementInfo)
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        statementInfo,
        status: 500,
      };
    });
};

export const getStatementReports = async () => {
  const statementReportsRef = firestore.collection('statementReports');

  return statementReportsRef
    .get()
    .then((querySnapshot) => {
      const statementReports = [];
      querySnapshot.forEach((doc) => {
        const statementReportInfo = doc.data();
        statementReports.push({
          ...statementReportInfo,
          id: doc.id,
          startDate: statementReportInfo.startDate.toDate().toISOString(),
          endDate: statementReportInfo.endDate.toDate().toISOString(),
        });
      });
      return {
        statementReports,
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const getAmazonStatements = async (reportId) => {
  const statementsRef = firestore
    .collection('statementReports')
    .doc(reportId)
    .collection('amazonStatements');

  return statementsRef
    .get()
    .then((querySnapshot) => {
      const statements = [];
      querySnapshot.forEach((doc) => {
        const statementInfo = doc.data();
        statements.push(statementInfo);
      });
      return {
        statements,
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const getTakealotStatements = async (reportId) => {
  const statementsRef = firestore
    .collection('statementReports')
    .doc(reportId)
    .collection('takealotStatements');

  return statementsRef
    .get()
    .then((querySnapshot) => {
      const statements = [];
      querySnapshot.forEach((doc) => {
        const statementInfo = doc.data();
        statements.push(statementInfo);
      });
      return {
        statements,
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const updatePricing = async (
  reportId,
  clientId,
  statementUpdates,
  reportUpdates,
  platform
) => {
  const reportRef = firestore.collection('statementReports').doc(reportId);
  const statementRef = firestore
    .collection('statementReports')
    .doc(reportId)
    .collection(`${platform}Statements`)
    .doc(clientId);
  const batch = firestore.batch();
  batch.update(reportRef, reportUpdates);
  batch.update(statementRef, statementUpdates);

  return batch
    .commit()
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const loadNewAdjustment = async (
  reportId,
  clientId,
  statementUpdates,
  reportUpdates,
  platform
) => {
  const reportRef = firestore.collection('statementReports').doc(reportId);
  const statementRef = firestore
    .collection('statementReports')
    .doc(reportId)
    .collection(`${platform}Statements`)
    .doc(clientId);
  const batch = firestore.batch();
  batch.update(reportRef, reportUpdates);
  batch.update(statementRef, statementUpdates);

  return batch
    .commit()
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const deleteAdjustment = async (
  reportId,
  clientId,
  statementUpdates,
  reportUpdates,
  platform
) => {
  const reportRef = firestore.collection('statementReports').doc(reportId);
  const statementRef = firestore
    .collection('statementReports')
    .doc(reportId)
    .collection(`${platform}Statements`)
    .doc(clientId);
  const batch = firestore.batch();
  batch.update(reportRef, reportUpdates);
  batch.update(statementRef, statementUpdates);

  return batch
    .commit()
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};
