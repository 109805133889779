/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { sendPasswordResetEmail, signIn } from '../../api/authAPI';

const initialState = {
  loadingStatus: {
    signIn: 'NOT_STARTED',
    passwordReset: 'NOT_STARTED',
  },
  uid: null,
  goToPortal: false,
  feedback: {
    isOpen: false,
    type: undefined,
    message: '',
  },
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginStarted(state) {
      state.loadingStatus.signIn = 'PENDING';
    },
    loginSuccess(state, action) {
      state.uid = action.payload;
      state.loadingStatus.signIn = 'COMPLETE';
      state.goToPortal = true;
    },
    loginFailed(state, action) {
      state.loadingStatus.signIn = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    sendPasswordResetEmailStarted(state) {
      state.loadingStatus.passwordReset = 'PENDING';
    },
    sendPasswordResetEmailSuccess(state) {
      state.loadingStatus.passwordReset = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'success',
        message:
          'An email will be sent to you shortly with a link to reset your password.',
      };
    },
    sendPasswordResetEmailFailed(state, action) {
      state.loadingStatus.passwordReset = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    closeSnackbar(state) {
      state.feedback = initialState.feedback;
    },
    redirectedToPortal(state) {
      state.goToPortal = false;
    },
  },
});

export const {
  loginStarted,
  loginSuccess,
  loginFailed,
  sendPasswordResetEmailStarted,
  sendPasswordResetEmailSuccess,
  sendPasswordResetEmailFailed,
  closeSnackbar,
  redirectedToPortal,
} = loginSlice.actions;

export default loginSlice.reducer;

export const logIn = (email, password) => async (dispatch) => {
  dispatch(loginStarted());
  const result = await signIn(email, password);

  if (result.status === 200) {
    dispatch(loginSuccess(result.uid));
  } else {
    dispatch(loginFailed(result.error));
  }
};

export const resetPassword = (email) => async (dispatch) => {
  dispatch(sendPasswordResetEmailStarted());
  const result = await sendPasswordResetEmail(email);

  if (result.status === 200) {
    dispatch(sendPasswordResetEmailSuccess());
  } else {
    dispatch(sendPasswordResetEmailFailed(result.error));
  }
};
