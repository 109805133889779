/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getSourcedProducts } from '../../../api/salesAPI';

const initialState = {
  products: [],
  loadingStatus: {
    products: 'NOT_STARTED',
  },
  pageSize: 5,
  initialPage: 0,
};

const sourcedProductsBrowseSlice = createSlice({
  name: 'sourcedProductsBrowse',
  initialState,
  reducers: {
    getProductsStarted(state) {
      state.loadingStatus.products = 'PENDING';
    },
    getProductsSuccess(state, action) {
      state.products = action.payload;
      state.loadingStatus.products = 'COMPLETE';
    },
    getProductsFailed(state, action) {
      state.loadingStatus.products = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    changePageSize(state, action) {
      state.pageSize = action.payload;
    },
    changeInitialPage(state, action) {
      state.initialPage = action.payload;
    },
  },
});

export const {
  getProductsStarted,
  getProductsSuccess,
  getProductsFailed,
  changePageSize,
  changeInitialPage,
} = sourcedProductsBrowseSlice.actions;

export const fetchProducts = () => async (dispatch) => {
  dispatch(getProductsStarted());
  const result = await getSourcedProducts();

  if (result.status === 200) {
    dispatch(getProductsSuccess(result.products));
  } else {
    dispatch(getProductsFailed(result.error));
  }
};

export const updatePageSize = (newPageSize) => (dispatch) => {
  dispatch(changePageSize(newPageSize));
};

export const updateInitialPage = (newInitialPage) => (dispatch) => {
  dispatch(changeInitialPage(newInitialPage));
};

export default sourcedProductsBrowseSlice.reducer;
