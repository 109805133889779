import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';
import ContentWrapper from '../../../components/ContentWrapper';
import EmptyViewMessage from '../../../components/EmptyViewMessage';
import FiltersBar from './components/FiltersBar';
import PageHeader from '../../../components/PageHeader';
import RequestsTable from './components/RequestsTable';
import {
  changeRequestStatus,
  closeSnackbar,
  fetchRequests,
  updateInitialPage,
  updatePageSize,
} from './slice';
import getFormattedRequests from './logic';

const breadcrumbs = [
  {
    label: 'Customer Relationship Management',
    link: '/crm',
  },
  {
    label: 'Stock Requests',
    link: '/crm/stock-requests',
  },
];

const StockRequestsBrowse = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState('ALL');
  const [formattedRequests, setFormattedRequests] = useState([]);
  const [clientFilter, setClientFilter] = useState('ALL');
  const [clientOptions, setClientOptions] = useState([]);

  const stockRequests = useSelector(
    (state) => state.stockRequestsBrowse.stockRequests
  );
  const feedback = useSelector((state) => state.stockRequestsBrowse.feedback);
  const pageSize = useSelector((state) => state.stockRequestsBrowse.pageSize);
  const initialPage = useSelector(
    (state) => state.stockRequestsBrowse.initialPage
  );
  const stockRequestsLoadingStatus = useSelector(
    (state) => state.stockRequestsBrowse.loadingStatus.stockRequests
  );
  const updateStatusLoadingStatus = useSelector(
    (state) => state.stockRequestsBrowse.loadingStatus.updateStatus
  );

  useEffect(() => {
    if (stockRequestsLoadingStatus !== 'PENDING') {
      dispatch(fetchRequests());
    }
  }, []);

  useEffect(() => {
    if (
      stockRequestsLoadingStatus === 'PENDING' ||
      updateStatusLoadingStatus === 'PENDING'
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [stockRequestsLoadingStatus, updateStatusLoadingStatus]);

  useEffect(() => {
    setFormattedRequests(
      getFormattedRequests(stockRequests, searchQuery, statusFilter)
    );
  }, [stockRequests, searchQuery, statusFilter]);

  useEffect(() => {
    setClientOptions(
      _.uniq(stockRequests.map((info) => info.clientName)).sort()
    );
  }, [stockRequests, statusFilter]);

  return (
    <div>
      <PageHeader
        title="Stock Requests"
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Browse"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
      />
      {isLoading && <LinearProgress color="secondary" />}
      <ContentWrapper>
        {!isLoading && stockRequests.length === 0 ? (
          <EmptyViewMessage
            heading="No Stock Requests"
            message="Please adjust the filters to view them here."
          />
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FiltersBar
                searchText={searchQuery}
                changeSearchText={setSearchQuery}
                requestsCount={formattedRequests.length}
                statusFilter={statusFilter}
                changeStatusFilter={setStatusFilter}
                clientFilter={clientFilter}
                changeClientFilter={setClientFilter}
                clientOptions={clientOptions}
              />
              <RequestsTable
                isLoading={isLoading}
                requests={formattedRequests}
                viewRequest={(requestId) =>
                  history.push(`/crm/stock-requests/browse/${requestId}`)
                }
                changeRequestStatus={(requestId, status) =>
                  dispatch(changeRequestStatus(requestId, status))
                }
                pageSize={pageSize}
                initialPage={initialPage}
                updatePageSize={(newPageSize) =>
                  dispatch(updatePageSize(newPageSize))
                }
                updateInitialPage={(newInitialPage) =>
                  dispatch(updateInitialPage(newInitialPage))
                }
              />
            </Grid>
          </Grid>
        )}
      </ContentWrapper>
      <Snackbar
        open={feedback.isOpen}
        autoHideDuration={6000}
        onClose={() => dispatch(closeSnackbar())}
      >
        <Alert
          onClose={() => dispatch(closeSnackbar())}
          severity={feedback.type}
        >
          {feedback.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default StockRequestsBrowse;
