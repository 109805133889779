/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  getDeal,
  getDealProposals,
  getDealInvoices,
  markDealDeleted,
  markDealFinalized,
  markDealLost,
} from '../../../api/salesAPI';

const initialState = {
  isUserRedirectedToBrowse: false,
  isPageRefreshed: false,
  dealInfo: {
    businessName: 'Loading',
    dealValue: 0,
    status: 'UNKNOWN',
    platform: 'UNKNOWN',
  },
  proposals: [],
  invoices: [],
  loadingStatus: {
    dealInfo: 'NOT_STARTED',
    proposals: 'NOT_STARTED',
    invoices: 'NOT_STARTED',
    deletion: 'NOT_STARTED',
    finalization: 'NOT_STARTED',
    lost: 'NOT_STARTED',
  },
  feedback: {
    isOpen: false,
    type: undefined,
    message: '',
  },
};

const dealsViewSlice = createSlice({
  name: 'dealsView',
  initialState,
  reducers: {
    fetchDealInfoStarted(state) {
      state.loadingStatus.dealInfo = 'PENDING';
    },
    fetchDealInfoSuccess(state, action) {
      state.dealInfo = action.payload;
      state.loadingStatus.dealInfo = 'COMPLETE';
    },
    fetchDealInfoFailed(state, action) {
      state.loadingStatus.dealInfo = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    fetchProposalsStarted(state) {
      state.loadingStatus.proposals = 'PENDING';
    },
    fetchProposalsSuccess(state, action) {
      state.proposals = action.payload;
      state.loadingStatus.proposals = 'COMPLETE';
    },
    fetchProposalsFailed(state, action) {
      state.loadingStatus.proposals = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    fetchInvoicesStarted(state) {
      state.loadingStatus.invoices = 'PENDING';
    },
    fetchInvoicesSuccess(state, action) {
      state.invoices = action.payload;
      state.loadingStatus.invoices = 'COMPLETE';
    },
    fetchInvoicesFailed(state, action) {
      state.loadingStatus.invoices = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    deleteDealStarted(state) {
      state.loadingStatus.deletion = 'PENDING';
    },
    deleteDealSuccess(state) {
      state.loadingStatus.deletion = 'COMPLETE';
      state.isUserRedirectedToBrowse = true;
    },
    deleteDealFailed(state, action) {
      state.loadingStatus.deletion = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    finalizeDealStarted(state) {
      state.loadingStatus.finalization = 'PENDING';
    },
    finalizeDealSuccess(state) {
      state.loadingStatus.finalization = 'COMPLETE';
      state.isPageRefreshed = true;
    },
    finalizeDealFailed(state, action) {
      state.loadingStatus.finalization = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    lostDealStarted(state) {
      state.loadingStatus.lost = 'PENDING';
    },
    lostDealSuccess(state) {
      state.loadingStatus.lost = 'COMPLETE';
      state.isPageRefreshed = true;
    },
    lostDealFailed(state, action) {
      state.loadingStatus.lost = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    resetState() {
      return initialState;
    },
  },
});

export const {
  fetchDealInfoStarted,
  fetchDealInfoSuccess,
  fetchDealInfoFailed,
  fetchProposalsStarted,
  fetchProposalsSuccess,
  fetchProposalsFailed,
  fetchInvoicesStarted,
  fetchInvoicesSuccess,
  fetchInvoicesFailed,
  deleteDealStarted,
  deleteDealSuccess,
  deleteDealFailed,
  finalizeDealStarted,
  finalizeDealSuccess,
  finalizeDealFailed,
  lostDealStarted,
  lostDealSuccess,
  lostDealFailed,
  resetState,
} = dealsViewSlice.actions;

export default dealsViewSlice.reducer;

export const fetchDealInfo = (dealId) => async (dispatch) => {
  dispatch(fetchDealInfoStarted());
  const result = await getDeal(dealId);

  if (result.status === 200) {
    dispatch(fetchDealInfoSuccess(result.info));
  } else {
    dispatch(fetchDealInfoFailed(result.error));
  }
};

export const fetchProposals = (dealId) => async (dispatch) => {
  dispatch(fetchProposalsStarted());
  const result = await getDealProposals(dealId);

  if (result.status === 200) {
    dispatch(fetchProposalsSuccess(result.proposals));
  } else {
    dispatch(fetchProposalsFailed(result.error));
  }
};

export const fetchInvoices = (dealId) => async (dispatch) => {
  dispatch(fetchInvoicesStarted());
  const result = await getDealInvoices(dealId);

  if (result.status === 200) {
    dispatch(fetchInvoicesSuccess(result.invoices));
  } else {
    dispatch(fetchInvoicesFailed(result.error));
  }
};

export const deleteDeal =
  (dealId, dealType, listingInfo) => async (dispatch) => {
    dispatch(deleteDealStarted());
    const result = await markDealDeleted(dealId, dealType, listingInfo);

    if (result.status === 200) {
      dispatch(deleteDealSuccess());
    } else {
      dispatch(deleteDealFailed(result.error));
    }
  };

export const finalizeDeal =
  (
    dealId,
    finalizedVersion,
    dealValue,
    skuCount,
    dealType,
    assignedProducts,
    removedProducts,
    finalBatchCode
  ) =>
  async (dispatch) => {
    dispatch(finalizeDealStarted());
    const result = await markDealFinalized(
      dealId,
      finalizedVersion,
      dealValue,
      skuCount,
      dealType,
      assignedProducts,
      removedProducts,
      finalBatchCode
    );

    if (result.status === 200) {
      dispatch(finalizeDealSuccess());
    } else {
      dispatch(finalizeDealFailed(result.error));
    }
  };

export const lostDeal = (dealId, dealType, listingInfo) => async (dispatch) => {
  dispatch(lostDealStarted());
  const result = await markDealLost(dealId, dealType, listingInfo);

  if (result.status === 200) {
    dispatch(lostDealSuccess());
  } else {
    dispatch(lostDealFailed(result.error));
  }
};

export const initiateStateReset = () => async (dispatch) => {
  dispatch(resetState());
};
