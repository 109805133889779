import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  buttonSpacing: {
    marginRight: theme.spacing(2),
  },
  wrapper: {
    textAlign: 'center',
    margin: theme.spacing(6),
  },
}));

const PlatformSelector = ({ selectPlatform }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Button
        className={classes.buttonSpacing}
        color="secondary"
        variant="contained"
        size="large"
        onClick={() => selectPlatform('amazon')}
      >
        Amazon Statements
      </Button>
      <Button
        color="secondary"
        variant="contained"
        size="large"
        onClick={() => selectPlatform('takealot')}
      >
        Takealot Statements
      </Button>
    </div>
  );
};

PlatformSelector.defaultProps = {
  selectPlatform: (platform) =>
    console.log(`Users selected the platform "${platform}"`),
};

PlatformSelector.propTypes = {
  selectPlatform: PropTypes.func,
};

export default PlatformSelector;
