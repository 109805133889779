/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  checkIfWaybillAdded,
  getStockRequests,
  updateShipmentStatus,
} from '../../../api/logisticsAPI';

const initialState = {
  stockRequests: [],
  loadingStatus: {
    stockRequests: 'NOT_STARTED',
    updateStatus: 'NOT_STARTED',
  },
  pageSize: 5,
  initialPage: 0,
  feedback: {
    isOpen: false,
    type: 'error',
    message: '',
  },
};

const stockRequestsBrowseSlice = createSlice({
  name: 'stockRequestsBrowse',
  initialState,
  reducers: {
    getStockRequestsStarted(state) {
      state.loadingStatus.stockRequests = 'PENDING';
    },
    getStockRequestsSuccess(state, action) {
      state.stockRequests = action.payload;
      state.loadingStatus.stockRequests = 'COMPLETE';
    },
    getStockRequestsFailed(state, action) {
      state.loadingStatus.stockRequests = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload,
      };
    },
    updateStatusStarted(state) {
      state.loadingStatus.updateStatus = 'PENDING';
    },
    updateStatusSuccess(state, action) {
      state.stockRequests = state.stockRequests.map((info) => {
        if (info.id === action.payload.id) {
          return {
            ...info,
            status: action.payload.status,
          };
        }
        return info;
      });
      state.loadingStatus.updateStatus = 'COMPLETE';
    },
    updateStatusFailed(state, action) {
      state.loadingStatus.updateStatus = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload,
      };
    },
    closeFeedback(state) {
      state.feedback = initialState.feedback;
    },
    changePageSize(state, action) {
      state.pageSize = action.payload;
    },
    changeInitialPage(state, action) {
      state.initialPage = action.payload;
    },
  },
});

export const {
  getStockRequestsStarted,
  getStockRequestsSuccess,
  getStockRequestsFailed,
  updateStatusStarted,
  updateStatusSuccess,
  updateStatusFailed,
  closeFeedback,
  changePageSize,
  changeInitialPage,
} = stockRequestsBrowseSlice.actions;

export const fetchRequests = () => async (dispatch) => {
  dispatch(getStockRequestsStarted());
  const result = await getStockRequests();

  if (result.status === 200) {
    dispatch(getStockRequestsSuccess(result.stockRequests));
  } else {
    dispatch(getStockRequestsFailed(result.error));
  }
};

export const changeRequestStatus = (id, status) => async (dispatch) => {
  dispatch(updateStatusStarted());
  const result1 = await checkIfWaybillAdded(id);
  console.log(result1);
  if (result1.isWaybillUploaded) {
    const result2 = await updateShipmentStatus(id, status);
    if (result2.status === 200) {
      dispatch(updateStatusSuccess({ id, status }));
    } else {
      dispatch(updateStatusFailed(result2.error));
    }
  } else {
    dispatch(
      updateStatusFailed(
        'This request requires a waybill to first be uploaded before updating the status.'
      )
    );
  }
};

export const closeSnackbar = () => (dispatch) => {
  dispatch(closeFeedback());
};

export const updatePageSize = (newPageSize) => (dispatch) => {
  dispatch(changePageSize(newPageSize));
};

export const updateInitialPage = (newInitialPage) => (dispatch) => {
  dispatch(changeInitialPage(newInitialPage));
};

export default stockRequestsBrowseSlice.reducer;
