/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  createReplenishmentShipments,
  getReplenishmentList,
  getReplenishmentSkus,
  updateReplenishmentList,
  updateReplenishmentUnits,
} from '../../../api/logisticsAPI';

const initialState = {
  searchQuery: '',
  destinationFilter: 'TAKEALOT',
  formattedSkus: [],
  clientFilter: 'ALL',
  clientOptions: [],
  warningsFilter: 'ALL',
  replenishmentList: {
    id: '',
  },
  replenishmentSkus: [],
  loadingStatus: {
    replenishmentList: 'NOT_STARTED',
    replenishmentSkus: 'NOT_STARTED',
    updateUnitsToSend: 'NOT_STARTED',
    createShipments: 'NOT_STARTED',
  },
  pageSize: 5,
  initialPage: 0,
  feedback: {
    isOpen: false,
    type: 'success',
    message: '',
  },
};
const logisticsReplenishmentsSlice = createSlice({
  name: 'logisticsReplenishments',
  initialState,
  reducers: {
    getReplenishmentsStarted(state) {
      state.loadingStatus.replenishmentList = 'PENDING';
    },
    getReplenishmentsSuccess(state, action) {
      state.replenishmentList = action.payload;
      state.loadingStatus.replenishmentList = 'COMPLETE';
    },
    getReplenishmentsFailed(state) {
      state.loadingStatus.replenishmentList = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message:
          'Failed to load replenishments. Please check your internet and try again.',
      };
    },
    getReplenishmentSkusStarted(state) {
      state.loadingStatus.replenishmentSkus = 'PENDING';
    },
    getReplenishmentSkusSuccess(state, action) {
      state.replenishmentSkus = action.payload;
      state.loadingStatus.replenishmentSkus = 'COMPLETE';
    },
    getReplenishmentSkusFailed(state) {
      state.loadingStatus.replenishmentSkus = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message:
          'Failed to load SKUs. Please check your internet connection and try again.',
      };
    },
    updateReplenishmentUnitsStarted(state) {
      state.loadingStatus.updateUnitsToSend = 'PENDING';
    },
    updateReplenishmentUnitsSuccess(state, action) {
      const { destination, sku, newUnits, skuInfo } = action.payload;
      const skuIndex = state.replenishmentSkus.findIndex((info) => {
        if (destination === 'EASYONLINE') {
          return info.sku === sku && info.destination === 'EASYONLINE';
        }
        if (destination === 'TAKEALOTJHB') {
          return (
            info.sku === sku &&
            info.destination === 'TAKEALOT' &&
            info.dc === 'JHB'
          );
        }
        if (destination === 'TAKEALOTCPT') {
          return (
            info.sku === sku &&
            info.destination === 'TAKEALOT' &&
            info.dc === 'CPT'
          );
        }

        return info.sku === sku && info.destination === 'AMAZON';
      });

      if (
        (destination === 'TAKEALOTJHB' || destination === 'TAKEALOTCPT') &&
        skuIndex === -1
      ) {
        let dc = null;
        let { toId, toName } = skuInfo;

        if (destination === 'TAKEALOTJHB') {
          dc = 'JHB';
          toId = 'ktwmpFt2DUXU1wsC59EJ';
          toName = 'Takealot JHB';
        } else if (destination === 'TAKEALOTCPT') {
          dc = 'CPT';
          toId = 't6NDHxRtKUiOhmv64ptz';
          toName = 'Takealot CPT';
        }

        state.replenishmentSkus.push({
          ...skuInfo,
          dc,
          toId,
          toName,
          destination: destination === 'EASYONLINE' ? 'EASYONLINE' : 'TAKEALOT',
          unitsToSend: newUnits,
        });
      } else {
        state.replenishmentSkus[skuIndex].unitsToSend = newUnits;
      }

      state.loadingStatus.updateUnitsToSend = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'success',
        message: 'Units were successfully updated.',
      };
    },
    updateReplenishmentUnitsFailed(state) {
      state.loadingStatus.updateUnitsToSend = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message:
          'Failed to update units. Please check your internet and try again.',
      };
    },
    setToZeroStarted(state) {
      state.loadingStatus.updateUnitsToSend = 'PENDING';
    },
    setToZeroSuccess(state) {
      // eslint-disable-next-line no-restricted-globals
      location.reload();

      state.loadingStatus.updateUnitsToSend = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'success',
        message: 'Units were successfully updated.',
      };
    },
    setToZeroFailed(state) {
      state.loadingStatus.updateUnitsToSend = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message:
          'Failed to update units. Please check your internet and try again.',
      };
    },
    createShipmentsStarted(state) {
      state.loadingStatus.createShipments = 'PENDING';
    },
    createShipmentsSuccess(state) {
      state.replenishmentList.isActioned = true;
      state.loadingStatus.createShipments = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'success',
        message: 'Shipments were successfully created.',
      };
    },
    createShipmentsFailed(state) {
      state.loadingStatus.createShipments = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message:
          'Failed to create shipments. Please check your internet and try again.',
      };
    },
    changePageSize(state, action) {
      state.pageSize = action.payload;
    },
    changeInitialPage(state, action) {
      state.initialPage = action.payload;
    },
    loadNewRecommendationsStarted(state) {
      state.loadingStatus.replenishmentList = 'PENDING';
    },
    loadNewRecommendationsSuccess(state) {
      state.loadingStatus.replenishmentList = 'COMPLETE';
    },
    loadNewRecommendationsFailed(state) {
      state.loadingStatus.replenishmentList = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message:
          'Failed to load new replenishments. Please check your internet and try again.',
      };
    },
    closeFeedback(state) {
      state.feedback = initialState.feedback;
    },
    changeSearchQuery(state, action) {
      state.searchQuery = action.payload;
    },
    changeDestinationFilter(state, action) {
      state.destinationFilter = action.payload;
    },
    changeFormattedSkus(state, action) {
      state.formattedSkus = action.payload;
    },
    changeClientFilter(state, action) {
      state.clientFilter = action.payload;
    },
    changeClientOptions(state, action) {
      state.clientOptions = action.payload;
    },
    changeWarningsFilter(state, action) {
      state.warningsFilter = action.payload;
    },
  },
});

export const {
  getReplenishmentsStarted,
  getReplenishmentsSuccess,
  getReplenishmentsFailed,
  getReplenishmentSkusStarted,
  getReplenishmentSkusSuccess,
  getReplenishmentSkusFailed,
  updateReplenishmentUnitsStarted,
  updateReplenishmentUnitsSuccess,
  updateReplenishmentUnitsFailed,
  createShipmentsStarted,
  createShipmentsSuccess,
  createShipmentsFailed,
  changeInitialPage,
  changePageSize,
  loadNewRecommendationsStarted,
  loadNewRecommendationsSuccess,
  loadNewRecommendationsFailed,
  setToZeroStarted,
  setToZeroSuccess,
  setToZeroFailed,
  closeFeedback,
  changeSearchQuery,
  changeDestinationFilter,
  changeFormattedSkus,
  changeClientFilter,
  changeClientOptions,
  changeWarningsFilter,
} = logisticsReplenishmentsSlice.actions;

export const fetchReplenishments = (destination) => async (dispatch) => {
  dispatch(getReplenishmentsStarted());
  const result = await getReplenishmentList(destination);

  if (result.status === 200) {
    dispatch(getReplenishmentsSuccess(result.replenishmentList));
  } else {
    dispatch(getReplenishmentsFailed(result.error));
  }
};

export const fetchReplenishmentSkus =
  (replenishmentId, destination) => async (dispatch) => {
    dispatch(getReplenishmentSkusStarted());
    const result = await getReplenishmentSkus(replenishmentId, destination);

    if (result.status === 200) {
      dispatch(getReplenishmentSkusSuccess(result.skus));
    } else {
      dispatch(getReplenishmentSkusFailed(result.error));
    }
  };

export const updateUnitsToSend =
  (replenishmentId, destination, sku, newUnits, skuInfo) =>
  async (dispatch) => {
    dispatch(updateReplenishmentUnitsStarted());
    const result = await updateReplenishmentUnits(
      replenishmentId,
      destination,
      sku,
      newUnits,
      skuInfo
    );

    if (result.status === 200) {
      dispatch(
        updateReplenishmentUnitsSuccess({ sku, newUnits, destination, skuInfo })
      );
    } else {
      dispatch(updateReplenishmentUnitsFailed(result.error));
    }
  };

export const setUnitsToZero =
  (replenishmentId, destination, skus) => async (dispatch) => {
    dispatch(setToZeroStarted());

    let results = [];
    if (destination === 'EASYONLINE') {
      results = await Promise.all(
        skus.map((info) =>
          updateReplenishmentUnits(
            replenishmentId,
            destination,
            info.sku,
            0,
            info
          )
        )
      );
    } else {
      results = await Promise.all([
        ...skus.map((info) =>
          updateReplenishmentUnits(
            replenishmentId,
            'TAKEALOTJHB',
            info.sku,
            0,
            info
          )
        ),
        ...skus.map((info) =>
          updateReplenishmentUnits(
            replenishmentId,
            'TAKEALOTCPT',
            info.sku,
            0,
            info
          )
        ),
      ]);
    }

    const isSuccessful = results.find((info) => info.status === 500) !== -1;
    if (isSuccessful) {
      dispatch(setToZeroSuccess({ skus, destination }));
    } else {
      dispatch(setToZeroFailed(results.error));
    }
  };

export const createShipments =
  (shipments, replenishmentId) => async (dispatch) => {
    dispatch(createShipmentsStarted());
    const result = await createReplenishmentShipments(
      shipments,
      replenishmentId
    );

    if (result.status === 200) {
      dispatch(createShipmentsSuccess());
    } else {
      dispatch(createShipmentsFailed(result.error));
    }
  };

export const updatePageSize = (newPageSize) => (dispatch) => {
  dispatch(changePageSize(newPageSize));
};

export const updateInitialPage = (newInitialPage) => (dispatch) => {
  dispatch(changeInitialPage(newInitialPage));
};

export const loadNewRecommendations = () => async (dispatch) => {
  dispatch(loadNewRecommendationsStarted());
  const result = await updateReplenishmentList();

  if (result.status === 200) {
    dispatch(loadNewRecommendationsSuccess());
    dispatch(fetchReplenishments());
  } else {
    dispatch(loadNewRecommendationsFailed(result.error));
  }
};

export const closeFeedbackSnackbar = () => (dispatch) => {
  dispatch(closeFeedback());
};

export const setSearchQuery = (newSearchQuery) => (dispatch) => {
  dispatch(changeSearchQuery(newSearchQuery));
};

export const setDestinationFilter = (newDestinationFilter) => (dispatch) => {
  dispatch(changeDestinationFilter(newDestinationFilter));
};

export const setFormattedSkus = (newFormattedSkus) => (dispatch) => {
  dispatch(changeFormattedSkus(newFormattedSkus));
};

export const setClientFilter = (newClientFilter) => (dispatch) => {
  dispatch(changeClientFilter(newClientFilter));
};

export const setClientOptions = (newClientOptions) => (dispatch) => {
  dispatch(changeClientOptions(newClientOptions));
};

export const setWarningsFilter = (newWarningsFilter) => (dispatch) => {
  dispatch(changeWarningsFilter(newWarningsFilter));
};

export default logisticsReplenishmentsSlice.reducer;
