import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { Redirect, Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import React from 'react';
import ReactDOM from 'react-dom';
import store from './utils/store';
import theme from './utils/theme';
import App from './App';
import Login from './pages/Login';
import 'fontsource-roboto';

const history = createBrowserHistory();
const version = '2.14.0';

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router history={history}>
        <Switch>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/login/forgot-password">
            <Login />
          </Route>
          <Route path="/">
            <App version={version} />
          </Route>
          <Redirect to="/" />
        </Switch>
      </Router>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);
