import { connectPagination } from 'react-instantsearch-dom';
import Pagination from '@material-ui/lab/Pagination';
import PropTypes from 'prop-types';

const HitsPagination = ({ currentRefinement, nbPages, refine }) => {
  return (
    <Pagination
      page={currentRefinement}
      count={nbPages}
      showFirstButton
      showLastButton
      color="primary"
      onChange={(e, page) => refine(page)}
    />
  );
};

HitsPagination.defaultProps = {
  currentRefinement: 2,
  nbPages: 10,
  refine: (newRefinement) =>
    console.log(`User changed the page to ${newRefinement}`),
};

HitsPagination.propTypes = {
  currentRefinement: PropTypes.number,
  nbPages: PropTypes.number,
  refine: PropTypes.func,
};

export default connectPagination(HitsPagination);
