import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {
  changeSku,
  checkIfBarcodeExists,
  checkIfSkuExists,
  fetchSkuInfo,
  fetchSuppliers,
  fetchWarehouses,
  initiateStateReset,
} from './slice';
import ContentWrapper from '../../../components/ContentWrapper';
import PageHeader from '../../../components/PageHeader';

const useStyles = makeStyles((theme) => ({
  actionsBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  alert: {
    marginBottom: theme.spacing(3),
  },
  formControl: {
    width: '100%',
  },
  formWrapper: {
    padding: `${theme.spacing(4)}px ${theme.spacing(1)}px`,
  },
  separator: {
    height: theme.spacing(2),
  },
  wrapperStep: {
    padding: `${theme.spacing(4)}px 0px`,
  },
  wrapperWizard: {
    width: '100%',
    padding: theme.spacing(4),
  },
}));

const breadcrumbs = [
  {
    label: 'Listings',
    link: '/listings',
  },
  {
    label: 'Basic Info',
    link: '/listings/basic-info',
  },
];

const ListingsEdit = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { sku } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const [brand, setBrand] = useState('');
  const [productName, setProductName] = useState('');
  const [variation, setVariation] = useState('');
  const [supplementaryInfoLink, setSupplementaryInfoLink] = useState('');
  const [weight, setWeight] = useState(null);
  const [length, setLength] = useState(null);
  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);
  const [batchSize, setBatchSize] = useState(5);
  const [stockQuantities, setStockQuantities] = useState({});
  const [stockImagesLink, setStockImagesLink] = useState('');
  const [originalTakealotSku, setOriginalTakealotSku] = useState('');
  const [takealotSku, setTakealotSku] = useState('');
  const [originalTakealotBarcode, setOriginalTakealotBarcode] = useState('');
  const [takealotBarcode, setTakealotBarcode] = useState('');
  const [takealotCostPriceRands, setTakealotCostPriceRands] = useState(0);
  const [takealotThirtyDaySales, setTakealotThirtyDaySales] = useState(0);
  const [takealotOnboarding, setTakealotOnboarding] = useState({});
  const [takealotOfferUrl, setTakealotOfferUrl] = useState('');
  const [takealotTsin, setTakealotTsin] = useState('');
  const [takealotRetailPriceRands, setTakealotRetailPriceRands] = useState('');
  const [takealotStatus, setTakealotStatus] = useState('ONBOARDING');
  const [takealotWholesalePriceRands, setTakealotWholesalePriceRands] =
    useState('');
  const [originalAmazonBarcode, setOriginalAmazonBarcode] = useState('');
  const [amazonBarcode, setAmazonBarcode] = useState('');
  const [amazonChildAsin, setAmazonChildAsin] = useState('');
  const [amazonFnsku, setAmazonFnsku] = useState('');
  const [amazonLogisticsCostRands, setAmazonLogisticsCostRands] = useState('');
  const [amazonParentAsin, setAmazonParentAsin] = useState('');
  const [amazonPaymentCalculation, setAmazonPaymentCalculation] =
    useState('WHOLESALE');
  const [amazonRetailPriceDollars, setAmazonRetailPriceDollars] = useState('');
  const [originalAmazonSku, setOriginalAmazonSku] = useState('');
  const [amazonSku, setAmazonSku] = useState('');
  const [amazonStatus, setAmazonStatus] = useState('INACTIVE');
  const [amazonWholesalePriceRands, setAmazonWholesalePriceRands] =
    useState('');
  const [selectedSupplierId, setSelectedSupplierId] = useState('');

  const [validation, setValidation] = useState({
    isErrorShowing: false,
    message: '',
    errorStep: 0,
  });

  const suppliers = useSelector((state) => state.listingsEdit.suppliersList);

  const warehousesLoadingStatus = useSelector(
    (state) => state.listingsEdit.loadingStatus.warehouses
  );
  const updateLoadingStatus = useSelector(
    (state) => state.listingsEdit.loadingStatus.update
  );
  const skuInfoLoadingStatus = useSelector(
    (state) => state.listingsEdit.loadingStatus.skuInfo
  );
  const skuCheckLoadingStatus = useSelector(
    (state) => state.listingsEdit.loadingStatus.skuCheck
  );
  const barcodeCheckLoadingStatus = useSelector(
    (state) => state.listingsEdit.loadingStatus.barcodeCheck
  );
  const isUserRedirectedToBrowse = useSelector(
    (state) => state.listingsEdit.isUserRedirectedToBrowse
  );

  const suppliersLoadingStatus = useSelector(
    (state) => state.listingsEdit.loadingStatus.suppliers
  );
  const skuInfo = useSelector((state) => state.listingsEdit.skuInfo);
  const skuExists = useSelector((state) => state.listingsEdit.skuExists);
  const barcodeExists = useSelector(
    (state) => state.listingsEdit.barcodeExists
  );

  useEffect(() => {
    dispatch(fetchSkuInfo(sku));
    if (warehousesLoadingStatus !== 'COMPLETE') {
      dispatch(fetchWarehouses());
    }
    if (suppliersLoadingStatus !== 'COMPLETE') {
      dispatch(fetchSuppliers());
    }
  }, []);

  useEffect(() => {
    setBrand(skuInfo.brand);
    setProductName(skuInfo.productName);
    setVariation(skuInfo.variation);
    setSupplementaryInfoLink(skuInfo.supplementaryInfoLink || '');
    setWeight(skuInfo.measurements ? skuInfo.measurements.weight : null);
    setLength(skuInfo.measurements ? skuInfo.measurements.length : null);
    setWidth(skuInfo.measurements ? skuInfo.measurements.width : null);
    setHeight(skuInfo.measurements ? skuInfo.measurements.height : null);
    setBatchSize(skuInfo.batchSize ? skuInfo.batchSize : 5);
    setStockQuantities(skuInfo.stockQuantities || {});
    setStockImagesLink(skuInfo.stockImagesLink);
    setOriginalTakealotSku(skuInfo.takealot.sku);
    setTakealotSku(skuInfo.takealot.sku);
    setOriginalTakealotBarcode(skuInfo.takealot.barcode);
    setTakealotBarcode(skuInfo.takealot.barcode);
    setTakealotCostPriceRands(skuInfo.takealot.costPriceRands);
    setTakealotOnboarding(skuInfo.takealot.onboarding);
    setTakealotThirtyDaySales(skuInfo.takealot.thirtyDaySales);
    setTakealotOfferUrl(skuInfo.takealot.offerUrl);
    setTakealotTsin(skuInfo.takealot.tsin);
    setTakealotRetailPriceRands(skuInfo.takealot.retailPriceRands);
    setTakealotStatus(skuInfo.takealot.status);
    setTakealotWholesalePriceRands(skuInfo.takealot.wholesalePriceRands);
    setOriginalAmazonBarcode(skuInfo.amazon.barcode);
    setAmazonBarcode(skuInfo.amazon.barcode);
    setAmazonChildAsin(skuInfo.amazon.childAsin);
    setAmazonFnsku(skuInfo.amazon.fnsku);
    setAmazonLogisticsCostRands(skuInfo.amazon.logisticsCostRands);
    setAmazonParentAsin(skuInfo.amazon.parentAsin);
    setAmazonPaymentCalculation(skuInfo.amazon.paymentCalculation);
    setAmazonRetailPriceDollars(skuInfo.amazon.retailPriceDollars);
    setOriginalAmazonSku(skuInfo.amazon.sku);
    setAmazonSku(skuInfo.amazon.sku);
    setAmazonStatus(skuInfo.amazon.status);
    setAmazonWholesalePriceRands(skuInfo.amazon.wholesalePriceRands);
    setSelectedSupplierId(
      skuInfo.supplierId ? skuInfo.supplierId : skuInfo.clientId
    );
  }, [skuInfo]);

  useEffect(() => {
    if (
      updateLoadingStatus === 'PENDING' ||
      skuInfoLoadingStatus === 'PENDING' ||
      warehousesLoadingStatus === 'PENDING' ||
      skuCheckLoadingStatus === 'PENDING' ||
      barcodeCheckLoadingStatus === 'PENDING' ||
      suppliersLoadingStatus === 'PENDING'
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [
    updateLoadingStatus,
    skuInfoLoadingStatus,
    warehousesLoadingStatus,
    skuCheckLoadingStatus,
    barcodeCheckLoadingStatus,
    suppliersLoadingStatus,
  ]);

  useEffect(() => {
    if (isUserRedirectedToBrowse) {
      dispatch(initiateStateReset());
      setIsLoading(true);
      history.goBack();
    }
  }, [isUserRedirectedToBrowse]);

  const getSkuName = () => {
    if (variation === 'N/A') {
      return `${brand} - ${productName}`;
    }
    return `${brand} - ${productName} (${variation})`;
  };

  const getSupplierName = (supplierId) => {
    const supplierInfo = suppliers.find((info) => info.id === supplierId);
    if (supplierInfo === undefined) {
      if (supplierId === skuInfo.clientId) {
        return skuInfo.clientName;
      }
      return '';
    }
    return supplierInfo.name;
  };

  const onNextClick = () => {
    switch (activeStep) {
      case 0:
        if (brand === '' || productName === '' || variation === '') {
          setValidation({
            isErrorShowing: true,
            message: 'Please complete all required fields.',
            errorStep: 0,
          });
        } else {
          setValidation({
            isErrorShowing: false,
            message: '',
            errorStep: 0,
          });
          setActiveStep(1);
        }
        break;
      case 1:
        setValidation({
          isErrorShowing: false,
          message: '',
          errorStep: 1,
        });
        setActiveStep(2);
        break;
      case 2:
        if (
          (takealotStatus === 'ACTIVE' &&
            (takealotBarcode === '' ||
              takealotSku === '' ||
              takealotWholesalePriceRands === '' ||
              takealotRetailPriceRands === '')) ||
          barcodeExists ||
          skuExists
        ) {
          setValidation({
            isErrorShowing: true,
            message: 'Please complete all required fields.',
            errorStep: 2,
          });
        } else {
          setValidation({
            isErrorShowing: false,
            message: '',
            errorStep: 2,
          });
          setActiveStep(3);
        }
        break;
      case 3:
        if (
          (amazonStatus === 'ACTIVE' &&
            (amazonSku === '' ||
              amazonSku === '' ||
              amazonBarcode === '' ||
              amazonLogisticsCostRands === '' ||
              amazonWholesalePriceRands === '' ||
              amazonRetailPriceDollars === '')) ||
          barcodeExists ||
          skuExists
        ) {
          setValidation({
            isErrorShowing: true,
            message: 'Please complete all required fields.',
            errorStep: 3,
          });
        } else {
          dispatch(
            changeSku(sku, {
              brand,
              productName,
              variation,
              stockImagesLink,
              supplementaryInfoLink,
              stockQuantities,
              batchSize,
              clientId: skuInfo.clientId,
              clientName: skuInfo.clientName,
              supplierId: selectedSupplierId,
              supplierName: getSupplierName(selectedSupplierId),
              measurements: {
                weight,
                length,
                width,
                height,
              },
              takealot: {
                barcode: takealotBarcode,
                costPriceRands: parseFloat(takealotCostPriceRands),
                offerUrl: takealotOfferUrl,
                onboarding: takealotOnboarding,
                paymentCalculation: 'WHOLESALE',
                retailPriceRands: parseInt(takealotRetailPriceRands, 10),
                sku: takealotSku,
                tsin: takealotTsin,
                status: takealotStatus,
                thirtyDaySales: takealotThirtyDaySales,
                wholesalePrice: parseFloat(takealotWholesalePriceRands),
                wholesalePriceRands: parseFloat(takealotWholesalePriceRands),
              },
              amazon: {
                barcode: amazonBarcode,
                childAsin: amazonChildAsin,
                fnsku: amazonFnsku,
                logisticsCostRands: parseFloat(amazonLogisticsCostRands),
                parentAsin: amazonParentAsin,
                paymentCalculation: amazonPaymentCalculation,
                retailPriceDollars: parseFloat(amazonRetailPriceDollars),
                sku: amazonSku,
                status: amazonStatus,
                wholesalePrice: parseFloat(amazonWholesalePriceRands),
                wholesalePriceRands: parseFloat(amazonWholesalePriceRands),
              },
            })
          );
          setValidation({
            isErrorShowing: false,
            message: '',
            errorStep: 3,
          });
        }
        break;
      default:
        console.log('Unknown step');
        break;
    }
  };

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <div className={classes.wrapperStep}>
            <Typography variant="h4" gutterBottom>
              Enter SKU&apos;s identifying information
            </Typography>
            <Typography variant="body1">
              Please complete all fields below.
            </Typography>
            <form className={classes.formWrapper}>
              <FormControl className={classes.formControl} required>
                <InputLabel shrink id="supplier-select-label">
                  Supplier
                </InputLabel>
                <Select
                  labelId="supplier-select-label"
                  id="supplier-select"
                  value={selectedSupplierId}
                  onChange={(e) => setSelectedSupplierId(e.target.value)}
                >
                  {suppliers
                    .filter((supplierInfo) => supplierInfo.name !== '')
                    .sort((a, b) => {
                      if (a.name > b.name) return +1;
                      if (a.name < b.name) return -1;
                      return 0;
                    })
                    .map((supplierInfo) => (
                      <MenuItem key={supplierInfo.id} value={supplierInfo.id}>
                        {supplierInfo.name}
                      </MenuItem>
                    ))}
                  <MenuItem key={skuInfo.clientId} value={skuInfo.clientId}>
                    {skuInfo.clientName}
                  </MenuItem>
                </Select>
              </FormControl>
              <div className={classes.separator} />
              <TextField
                required
                label="Brand"
                fullWidth
                placeholder="Yeezy"
                value={brand}
                onChange={(e) => setBrand(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <div className={classes.separator} />
              <TextField
                required
                label="Product Name"
                fullWidth
                placeholder="Boost"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <div className={classes.separator} />
              <TextField
                required
                label="Variation"
                fullWidth
                placeholder="Medium"
                value={variation}
                onChange={(e) => setVariation(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <div className={classes.separator} />
              <TextField
                label="Supplementary Info Link"
                fullWidth
                value={supplementaryInfoLink}
                onChange={(e) => setSupplementaryInfoLink(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </form>
            <div className={classes.separator} />
            {validation.isErrorShowing && validation.errorStep === 0 && (
              <Alert className={classes.alert} severity="error">
                {validation.message}
              </Alert>
            )}
          </div>
        );
      case 1:
        return (
          <div className={classes.wrapperStep}>
            <Typography variant="h4" gutterBottom>
              Enter SKU&apos;s stock information
            </Typography>
            <Typography variant="body1">
              Please complete all fields below.
            </Typography>
            <form className={classes.formWrapper}>
              <TextField
                label="Weight (kg)"
                fullWidth
                value={weight}
                onChange={(e) =>
                  setWeight(
                    Number.isNaN(parseFloat(e.target.value))
                      ? null
                      : parseFloat(e.target.value)
                  )
                }
                type="number"
                min={0}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <div className={classes.separator} />
              <TextField
                label="Length (cm)"
                fullWidth
                value={length}
                onChange={(e) =>
                  setLength(
                    Number.isNaN(parseFloat(e.target.value))
                      ? null
                      : parseFloat(e.target.value)
                  )
                }
                type="number"
                min={0}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <div className={classes.separator} />
              <TextField
                label="Width (cm)"
                fullWidth
                value={width}
                onChange={(e) =>
                  setWidth(
                    Number.isNaN(parseFloat(e.target.value))
                      ? null
                      : parseFloat(e.target.value)
                  )
                }
                type="number"
                min={0}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <div className={classes.separator} />
              <TextField
                label="Height (cm)"
                fullWidth
                value={height}
                onChange={(e) =>
                  setHeight(
                    Number.isNaN(parseFloat(e.target.value))
                      ? null
                      : parseFloat(e.target.value)
                  )
                }
                type="number"
                min={0}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <div className={classes.separator} />
              <TextField
                label="Order Batch Size"
                fullWidth
                value={batchSize}
                onChange={(e) =>
                  setBatchSize(
                    Number.isNaN(parseInt(e.target.value, 10))
                      ? null
                      : parseInt(e.target.value, 10)
                  )
                }
                type="number"
                min={1}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <div className={classes.separator} />
              <TextField
                label="Stock Images Link"
                fullWidth
                value={stockImagesLink}
                onChange={(e) => setStockImagesLink(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </form>
            <div className={classes.separator} />
            {validation.isErrorShowing && validation.errorStep === 1 && (
              <Alert className={classes.alert} severity="error">
                {validation.message}
              </Alert>
            )}
          </div>
        );
      case 2:
        return (
          <div className={classes.wrapperStep}>
            <Typography variant="h4" gutterBottom>
              Enter the SKU&apos;s Takealot info
            </Typography>
            <Typography variant="body1">
              If relevant, please enter all info related to trading this SKU on
              Takealot.
            </Typography>
            <div className={classes.separator} />
            <TextField
              label="SKU"
              fullWidth
              required
              value={takealotSku}
              onChange={(e) => setTakealotSku(e.target.value)}
              onBlur={(e) => {
                if (originalTakealotSku !== e.target.value) {
                  dispatch(checkIfSkuExists(e.target.value));
                } else {
                  dispatch(checkIfSkuExists('*'));
                }
              }}
              InputLabelProps={{
                shrink: true,
              }}
              error={skuExists}
              helperText={skuExists ? 'This SKU code is taken.' : ''}
            />
            <div className={classes.separator} />
            <TextField
              label="Barcode"
              fullWidth
              required
              value={takealotBarcode}
              onChange={(e) => setTakealotBarcode(e.target.value)}
              onBlur={(e) => {
                if (originalTakealotBarcode !== e.target.value) {
                  dispatch(checkIfBarcodeExists(e.target.value));
                } else {
                  dispatch(checkIfSkuExists('*'));
                }
              }}
              InputLabelProps={{
                shrink: true,
              }}
              error={barcodeExists}
              helperText={barcodeExists ? 'This barcode is taken.' : ''}
            />
            <div className={classes.separator} />
            {validation.isErrorShowing && validation.errorStep === 2 && (
              <Alert className={classes.alert} severity="error">
                {validation.message}
              </Alert>
            )}
          </div>
        );
      case 3:
        return (
          <div className={classes.wrapperStep}>
            <Typography variant="h4" gutterBottom>
              Enter the SKU&apos;s Amazon info
            </Typography>
            <Typography variant="body1">
              If relevant, please enter all info related to trading this SKU on
              Amazon.
            </Typography>
            <div className={classes.separator} />
            <FormControl className={classes.formControl} required>
              <InputLabel shrink id="amazon-status-select-label">
                Status
              </InputLabel>
              <Select
                labelId="amazon-status-select-label"
                id="amazon-status-select"
                value={amazonStatus}
                onChange={(e) => setAmazonStatus(e.target.value)}
              >
                <MenuItem value="ACTIVE">Active</MenuItem>
                <MenuItem value="INACTIVE">Inactive</MenuItem>
              </Select>
            </FormControl>
            <div className={classes.separator} />
            {amazonStatus === 'ACTIVE' && (
              <>
                <TextField
                  label="SKU"
                  fullWidth
                  value={amazonSku}
                  required
                  onChange={(e) => setAmazonSku(e.target.value)}
                  onBlur={(e) => {
                    if (originalAmazonSku !== e.target.value) {
                      dispatch(checkIfSkuExists(e.target.value));
                    } else {
                      dispatch(checkIfSkuExists('*'));
                    }
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={skuExists}
                  helperText={skuExists ? 'This SKU code is taken.' : ''}
                />
                <div className={classes.separator} />
                <TextField
                  label="Barcode"
                  fullWidth
                  required
                  value={amazonBarcode}
                  onChange={(e) => setAmazonBarcode(e.target.value)}
                  onBlur={(e) => {
                    if (originalAmazonBarcode !== e.target.value) {
                      dispatch(checkIfBarcodeExists(e.target.value));
                    } else {
                      dispatch(checkIfSkuExists('*'));
                    }
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={barcodeExists}
                  helperText={barcodeExists ? 'This barcode is taken.' : ''}
                />
                <div className={classes.separator} />
                <TextField
                  label="FNSKU"
                  fullWidth
                  value={amazonFnsku}
                  onChange={(e) => setAmazonFnsku(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <div className={classes.separator} />
                <TextField
                  label="Child ASIN"
                  fullWidth
                  value={amazonChildAsin}
                  onChange={(e) => setAmazonChildAsin(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <div className={classes.separator} />
                <TextField
                  label="Parent ASIN"
                  fullWidth
                  value={amazonParentAsin}
                  onChange={(e) => setAmazonParentAsin(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <div className={classes.separator} />
                <FormControl className={classes.formControl} required>
                  <InputLabel
                    shrink
                    id="amazon-payment-calculation-select-label"
                  >
                    Payment Calculation
                  </InputLabel>
                  <Select
                    labelId="amazon-payment-calculation-select-label"
                    id="amazon-payment-calculation-select"
                    value={amazonPaymentCalculation}
                    onChange={(e) =>
                      setAmazonPaymentCalculation(e.target.value)
                    }
                  >
                    <MenuItem value="WHOLESALE">Wholesale Price Only</MenuItem>
                    <MenuItem value="WHOLESALE_LOGISTICS">
                      Wholesale Price + Logistics Cost
                    </MenuItem>
                  </Select>
                </FormControl>
                <div className={classes.separator} />
                <TextField
                  label="Logistics Cost"
                  fullWidth
                  required
                  value={amazonLogisticsCostRands}
                  onChange={(e) => setAmazonLogisticsCostRands(e.target.value)}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <div className={classes.separator} />
              </>
            )}
            {validation.isErrorShowing && validation.errorStep === 3 && (
              <Alert className={classes.alert} severity="error">
                {validation.message}
              </Alert>
            )}
          </div>
        );
      default:
        return <div />;
    }
  };

  return (
    <div>
      <PageHeader
        isLoading={isLoading}
        title="Edit SKU"
        subtitle={isLoading ? 'Loading...' : getSkuName()}
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Edit"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
      />
      <ContentWrapper>
        <Paper className={classes.wrapperWizard}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Stepper activeStep={activeStep} orientation="vertical">
                <Step>
                  <StepLabel>Basic Info</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Stock Info</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Takealot</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Amazon</StepLabel>
                </Step>
              </Stepper>
            </Grid>
            <Grid item xs={9}>
              {getStepContent()}
              <div className={classes.actionsBar}>
                <Button
                  disabled={activeStep === 0 || isLoading}
                  onClick={() => setActiveStep(activeStep - 1)}
                >
                  Previous
                </Button>
                <Button
                  disabled={isLoading}
                  variant="contained"
                  color="secondary"
                  onClick={onNextClick}
                >
                  {activeStep !== 3 ? 'Next' : 'Update'}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </ContentWrapper>
    </div>
  );
};

export default ListingsEdit;
