import _ from 'lodash';
import moment from 'moment';

export const getFormattedProducts = (
  products,
  searchQuery,
  productSourcerFilter,
  supplierFilter,
  statusFilter
) => {
  return products
    .map((info) => {
      return {
        id: info.id,
        status: info.status,
        description: info.description,
        creationDate: moment(info.creationDate).format('MM/DD/YYYY'),
        productSourcerName: info.productSourcerName,
        supplierName: info.supplierName,
        superDealerName: info.superDealerName,
        costPrice: info.pricing.costPrice,
        wholesalePrice: info.pricing.wholesalePrice,
        retailPrice: info.pricing.retailPrice,
      };
    })
    .filter((info) => {
      if (searchQuery !== '') {
        const caseInsensitiveSearch = _.toLower(searchQuery);
        const caseInsensitiveDescription = _.toLower(info.description);
        const caseInsensitiveProductSourcer = _.toLower(
          info.productSourcerName
        );
        const caseInsensitiveSupplier = _.toLower(info.supplierName);
        const caseInsensitiveSuperDealerName = _.toLower(info.superDealerName);
        const caseInsensitiveStatus = _.toLower(info.status);

        if (caseInsensitiveDescription.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveProductSourcer.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveSupplier.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveSuperDealerName.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveStatus.includes(caseInsensitiveSearch)) {
          return true;
        }

        return false;
      }

      return true;
    })
    .filter((info) => {
      if (productSourcerFilter !== 'ALL') {
        return info.productSourcerName === productSourcerFilter;
      }
      return true;
    })
    .filter((info) => {
      if (supplierFilter !== 'ALL') {
        return info.supplierName === supplierFilter;
      }
      return true;
    })
    .filter((info) => {
      if (statusFilter !== 'ALL') {
        return info.status === statusFilter;
      }
      return true;
    })
    .sort((a, b) => {
      if (a.supplierName < b.supplierName) return +1;
      if (a.supplierName > b.supplierName) return -1;
      if (a.productSourcerName < b.productSourcerName) return -1;
      if (a.productSourcerName > b.productSourcerName) return +1;
      if (a.description < b.description) return -1;
      if (a.description > b.description) return +1;
      return 0;
    });
};

export const getSuppliers = (products) => {
  return _.uniq(products.map((info) => info.supplierName)).sort();
};

export const getProductSourcers = (products) => {
  return _.uniq(products.map((info) => info.productSourcerName)).sort();
};

export const findSku = (sku, skus) => {
  const skuInfo = skus.find((info) => info.sku === sku);
  return skuInfo;
};

export const getProductName = (sku, skus) => {
  const skuInfo = skus.find((info) => info.sku === sku);
  if (skuInfo) {
    return skuInfo.variation === 'N/A'
      ? `${skuInfo.brand} - ${skuInfo.productName}`
      : `${skuInfo.brand} - ${skuInfo.productName} (${skuInfo.variation})`;
  }
  return 'Unknown';
};
