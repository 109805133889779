const categories = [
  {
    department: 'Baby',
    category: 'Baby Equipment & Furniture',
    subCategory: 'Activity',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Baby',
    category: 'Baby Equipment & Furniture',
    subCategory: 'Baths & Bathing',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Baby',
    category: 'Baby Equipment & Furniture',
    subCategory: 'Beds & Bedding',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Baby',
    category: 'Baby Equipment & Furniture',
    subCategory: 'Car & Travel Equipment',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Baby',
    category: 'Baby Equipment & Furniture',
    subCategory: 'Changing',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Baby',
    category: 'Baby Equipment & Furniture',
    subCategory: 'Cots, Prams & Carriers',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Baby',
    category: 'Baby Equipment & Furniture',
    subCategory: 'Decor',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Baby',
    category: 'Baby Equipment & Furniture',
    subCategory: 'Feeding & Nursing Equipment',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Baby',
    category: 'Baby Equipment & Furniture',
    subCategory: 'Gadgets & Kits',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Baby',
    category: 'Baby Equipment & Furniture',
    subCategory: 'No Cat',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Baby',
    category: 'Baby Equipment & Furniture',
    subCategory: 'Potty Training',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Baby',
    category: 'Baby Equipment & Furniture',
    subCategory: 'Safety Equipment',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Baby',
    category: 'Baby Equipment & Furniture',
    subCategory: 'Teethers & Dummies',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Baby',
    category: 'Baby Food & Nutrition',
    subCategory: 'Baby Food',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Baby',
    category: 'Baby Food & Nutrition',
    subCategory: 'Bottle Feeding',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Baby',
    category: 'Baby Food & Nutrition',
    subCategory: 'No Cat',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Baby',
    category: 'Baby Food & Nutrition',
    subCategory: 'Nursing',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Baby',
    category: 'Mom & Baby Care',
    subCategory: 'Hair Care',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Baby',
    category: 'Mom & Baby Care',
    subCategory: 'Nail Clippers & Scissors',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Baby',
    category: 'Mom & Baby Care',
    subCategory: 'Nasal Care',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Baby',
    category: 'Mom & Baby Care',
    subCategory: 'No Cat',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Baby',
    category: 'Mom & Baby Care',
    subCategory: 'Oral Care',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Baby',
    category: 'Mom & Baby Care',
    subCategory: 'Skin Care',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Baby',
    category: 'Nappies & Changing',
    subCategory: 'Liners',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Baby',
    category: 'Nappies & Changing',
    subCategory: 'Nappies',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Baby',
    category: 'Nappies & Changing',
    subCategory: 'Nappy Bags',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Baby',
    category: 'Nappies & Changing',
    subCategory: 'No Cat',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Baby',
    category: 'Nappies & Changing',
    subCategory: 'Wipes',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Baby',
    category: 'No Cat',
    subCategory: 'No Cat',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Beauty',
    category: 'Body Care',
    subCategory: 'Bags & Cases',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Beauty',
    category: 'Body Care',
    subCategory: 'Exfoliators & Scrubs',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Beauty',
    category: 'Body Care',
    subCategory: 'Moisturisers & Creams',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Beauty',
    category: 'Body Care',
    subCategory: 'No Cat',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Beauty',
    category: 'Body Care',
    subCategory: 'Sets',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Beauty',
    category: 'Body Care',
    subCategory: 'Soap & Body Wash',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Beauty',
    category: 'Body Care',
    subCategory: 'Tanning products',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Beauty',
    category: 'Body Care',
    subCategory: 'Tools',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Beauty',
    category: 'Body Care',
    subCategory: 'Treatments',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Beauty',
    category: 'Fragrance',
    subCategory: 'Body Sprays',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Beauty',
    category: 'Fragrance',
    subCategory: 'Cologne',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Beauty',
    category: 'Fragrance',
    subCategory: 'Eau de Parfum',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Beauty',
    category: 'Fragrance',
    subCategory: 'Eau de Toilette',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Beauty',
    category: 'Fragrance',
    subCategory: 'No Cat',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Beauty',
    category: 'Fragrance',
    subCategory: 'Sets',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Beauty',
    category: 'Cosmetics',
    subCategory: 'Eyes',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Beauty',
    category: 'Cosmetics',
    subCategory: 'Face',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Beauty',
    category: 'Cosmetics',
    subCategory: 'Lips',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Beauty',
    category: 'Cosmetics',
    subCategory: 'Nails',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Beauty',
    category: 'Cosmetics',
    subCategory: 'No Cat',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Beauty',
    category: 'Cosmetics',
    subCategory: 'Sets',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Beauty',
    category: 'Cosmetics',
    subCategory: 'Tools',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Beauty',
    category: 'Hair Care',
    subCategory: 'Conditioner',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Beauty',
    category: 'Hair Care',
    subCategory: 'Electrical Beauty',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Beauty',
    category: 'Hair Care',
    subCategory: 'Extensions, Wigs & Accessories',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Beauty',
    category: 'Hair Care',
    subCategory: 'Hair Colour',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Beauty',
    category: 'Hair Care',
    subCategory: 'No Cat',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Beauty',
    category: 'Hair Care',
    subCategory: 'Shampoo',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Beauty',
    category: 'Hair Care',
    subCategory: 'Styling Products',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Beauty',
    category: 'Hair Care',
    subCategory: 'Styling Tools',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Beauty',
    category: 'Hair Care',
    subCategory: 'Treatments',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Beauty',
    category: 'No Cat',
    subCategory: 'No Cat',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Beauty',
    category: 'Skin Care',
    subCategory: 'Cleansers',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Beauty',
    category: 'Skin Care',
    subCategory: 'Exfoliator & Scrubs',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Beauty',
    category: 'Skin Care',
    subCategory: 'Masks',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Beauty',
    category: 'Skin Care',
    subCategory: 'Moisturisers',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Beauty',
    category: 'Skin Care',
    subCategory: 'No Cat',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Beauty',
    category: 'Skin Care',
    subCategory: 'Sets',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Beauty',
    category: 'Skin Care',
    subCategory: 'Toners',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Beauty',
    category: 'Skin Care',
    subCategory: 'Tools',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Beauty',
    category: 'Skin Care',
    subCategory: 'Treatments',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Books',
    category: 'Academic',
    subCategory: 'No Cat',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Books',
    category: 'Academic',
    subCategory: 'School & Education',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Books',
    category: 'Academic',
    subCategory: 'Text Books',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Books',
    category: 'No Cat',
    subCategory: 'No Cat',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Books',
    category: 'Trade Books',
    subCategory: 'Children’s Books',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Books',
    category: 'Trade Books',
    subCategory: 'Fiction',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Books',
    category: 'Trade Books',
    subCategory: 'No Cat',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Books',
    category: 'Trade Books',
    subCategory: 'Non-Fiction',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Cameras',
    category: 'Action Cams & Drones',
    subCategory: 'Action Cameras',
    successFee: 0.05,
    type: 'FLAT',
  },
  {
    department: 'Cameras',
    category: 'Action Cams & Drones',
    subCategory: 'Camera Accessories',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Cameras',
    category: 'Action Cams & Drones',
    subCategory: 'Drone Accessories',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Cameras',
    category: 'Action Cams & Drones',
    subCategory: 'Drones',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Cameras',
    category: 'Action Cams & Drones',
    subCategory: 'No Cat',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Cameras',
    category: 'Binoculars & Scopes',
    subCategory: 'Accessories',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Cameras',
    category: 'Binoculars & Scopes',
    subCategory: 'Binoculars',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Cameras',
    category: 'Binoculars & Scopes',
    subCategory: 'Microscopes',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Cameras',
    category: 'Binoculars & Scopes',
    subCategory: 'Monoculars',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Cameras',
    category: 'Binoculars & Scopes',
    subCategory: 'No Cat',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Cameras',
    category: 'Binoculars & Scopes',
    subCategory: 'Range Finders',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Cameras',
    category: 'Binoculars & Scopes',
    subCategory: 'Spotting Scopes',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Cameras',
    category: 'Binoculars & Scopes',
    subCategory: 'Telescopes',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Cameras',
    category: 'Cameras & Lenses',
    subCategory: 'Camera Accessories',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Cameras',
    category: 'Cameras & Lenses',
    subCategory: 'Cameras',
    successFee: 0.04,
    type: 'FLAT',
  },
  {
    department: 'Cameras',
    category: 'Cameras & Lenses',
    subCategory: 'Lens Accessories',
    successFee: 0.07,
    type: 'FLAT',
  },
  {
    department: 'Cameras',
    category: 'Cameras & Lenses',
    subCategory: 'Lenses',
    successFee: 0.04,
    type: 'FLAT',
  },
  {
    department: 'Cameras',
    category: 'No Cat',
    subCategory: 'No Cat',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Cameras',
    category: 'Video Cameras',
    subCategory: 'Video Camera Accessories',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Cameras',
    category: 'Video Cameras',
    subCategory: 'Video Cameras',
    successFee: 0.06,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Accessories',
    subCategory: 'Cables & Plugs',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Accessories',
    subCategory: 'Deep Cycle Batteries',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Accessories',
    subCategory: 'Electrical Accessories',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Accessories',
    subCategory: 'Emergency & Survival',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Accessories',
    subCategory: 'Generators',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Accessories',
    subCategory: 'Health & Hygiene',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Accessories',
    subCategory: 'Navigation & GPS devices',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Accessories',
    subCategory: 'No Cat',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Accessories',
    subCategory: 'Power Conversions',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Accessories',
    subCategory: 'Rifle & Related Gear',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Accessories',
    subCategory: 'Ropes & Cords',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Camping Furniture',
    subCategory: 'Chairs',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Camping Furniture',
    subCategory: 'Covers',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Camping Furniture',
    subCategory: 'Cupboards',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Camping Furniture',
    subCategory: 'General',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Camping Furniture',
    subCategory: 'Laundry & Washlines',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Camping Furniture',
    subCategory: 'No Cat',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Camping Furniture',
    subCategory: 'Portable Toilets',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Camping Furniture',
    subCategory: 'Shower',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Camping Furniture',
    subCategory: 'Tables',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Camping Furniture',
    subCategory: 'Washing Machines',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Fishing Equipment',
    subCategory: 'Apparel & Footwear',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Fishing Equipment',
    subCategory: 'Bate',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Fishing Equipment',
    subCategory: 'No Cat',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Fishing Equipment',
    subCategory: 'Reels & Rods',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Fishing Equipment',
    subCategory: 'Tackle Boxes',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Hunting Apparel & Footwear',
    subCategory: 'No Cat',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Lighting & Gadgets',
    subCategory: 'Accessories',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Lighting & Gadgets',
    subCategory: 'Fans',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Lighting & Gadgets',
    subCategory: 'Headlamps',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Lighting & Gadgets',
    subCategory: 'No Cat',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Lighting & Gadgets',
    subCategory: 'Spot Lights',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Lighting & Gadgets',
    subCategory: 'Strips & Candles',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'No Cat',
    subCategory: 'No Cat',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Outdoor Kitchen',
    subCategory: 'Basins & Drain Racks',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Outdoor Kitchen',
    subCategory: 'Cast Iron',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Outdoor Kitchen',
    subCategory: 'Cookers & Grills',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Outdoor Kitchen',
    subCategory: 'Cookware & Cutlery',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Outdoor Kitchen',
    subCategory: 'Coolers & Refrigeration',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Outdoor Kitchen',
    subCategory: 'Gas Equipment',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Outdoor Kitchen',
    subCategory: 'No Cat',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Sleeping',
    subCategory: 'Airbeds, Mats & Stretchers',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Sleeping',
    subCategory: 'No Cat',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Sleeping',
    subCategory: 'Sleeping Bags',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Storage & Packs',
    subCategory: 'No Cat',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Storage & Packs',
    subCategory: 'Packing Solutions & Accessories',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Storage & Packs',
    subCategory: 'Storage Bags, Boxes & Lines',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Storage & Packs',
    subCategory: 'Water Storage & Sanitisation',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Tents & Shelters',
    subCategory: 'Gazebos & Shade',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Tents & Shelters',
    subCategory: 'Groundsheets',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Tents & Shelters',
    subCategory: 'No Cat',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Tents & Shelters',
    subCategory: 'Tent Accessories',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Tents & Shelters',
    subCategory: 'Tents',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Camping & Outdoor',
    category: 'Tents & Shelters',
    subCategory: 'Umbrellas',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Clothing & Footwear',
    category: 'Accessories',
    subCategory: 'Bags & Handbags',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Clothing & Footwear',
    category: 'Accessories',
    subCategory: 'Belts',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Clothing & Footwear',
    category: 'Accessories',
    subCategory: 'Eyewear',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Clothing & Footwear',
    category: 'Accessories',
    subCategory: 'Gloves & Mittens',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Clothing & Footwear',
    category: 'Accessories',
    subCategory: 'Hats & Caps',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Clothing & Footwear',
    category: 'Accessories',
    subCategory: 'No Cat',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Clothing & Footwear',
    category: 'Accessories',
    subCategory: 'Purses & Wallets',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Clothing & Footwear',
    category: 'Accessories',
    subCategory: 'Scarves',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Clothing & Footwear',
    category: 'Accessories',
    subCategory: 'Ties',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Clothing & Footwear',
    category: 'Clothing',
    subCategory: 'Baby Clothing',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Clothing & Footwear',
    category: 'Clothing',
    subCategory: 'Bottoms',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Clothing & Footwear',
    category: 'Clothing',
    subCategory: 'Dresses',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Clothing & Footwear',
    category: 'Clothing',
    subCategory: 'Jackets & Coats',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Clothing & Footwear',
    category: 'Clothing',
    subCategory: 'Knitwear',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Clothing & Footwear',
    category: 'Clothing',
    subCategory: 'No Cat',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Clothing & Footwear',
    category: 'Clothing',
    subCategory: 'Skirts & Shorts',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Clothing & Footwear',
    category: 'Clothing',
    subCategory: 'Sleepwear',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Clothing & Footwear',
    category: 'Clothing',
    subCategory: 'Swimwear',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Clothing & Footwear',
    category: 'Clothing',
    subCategory: 'Tops',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Clothing & Footwear',
    category: 'Clothing',
    subCategory: 'Underwear',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Clothing & Footwear',
    category: 'Footwear',
    subCategory: 'No Cat',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Clothing & Footwear',
    category: 'Jewellery and Watches',
    subCategory: 'Jewellery',
    successFee: 0.18,
    type: 'PRICE',
  },
  {
    department: 'Clothing & Footwear',
    category: 'Jewellery and Watches',
    subCategory: 'Watches',
    successFee: 0.18,
    type: 'PRICE',
  },
  {
    department: 'Clothing & Footwear',
    category: 'No Cat',
    subCategory: 'No Cat',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Computer Components',
    category: 'Accessories & Peripherals',
    subCategory: 'Accessories',
    successFee: 0.12,
    type: 'LEADTIME',
  },
  {
    department: 'Computer Components',
    category: 'Accessories & Peripherals',
    subCategory: 'No Cat',
    successFee: 0.12,
    type: 'LEADTIME',
  },
  {
    department: 'Computer Components',
    category: 'Accessories & Peripherals',
    subCategory: 'Peripherals',
    successFee: 0.12,
    type: 'LEADTIME',
  },
  {
    department: 'Computer Components',
    category: 'Components',
    subCategory: 'Cases',
    successFee: 0.08,
    type: 'LEADTIME',
  },
  {
    department: 'Computer Components',
    category: 'Components',
    subCategory: 'Cleaning & Repairs',
    successFee: 0.1,
    type: 'LEADTIME',
  },
  {
    department: 'Computer Components',
    category: 'Components',
    subCategory: 'Computer Memory',
    successFee: 0.08,
    type: 'LEADTIME',
  },
  {
    department: 'Computer Components',
    category: 'Components',
    subCategory: 'CPU',
    successFee: 0.08,
    type: 'LEADTIME',
  },
  {
    department: 'Computer Components',
    category: 'Components',
    subCategory: 'Fans & Cooling',
    successFee: 0.08,
    type: 'LEADTIME',
  },
  {
    department: 'Computer Components',
    category: 'Components',
    subCategory: 'Graphics Cards',
    successFee: 0.08,
    type: 'LEADTIME',
  },
  {
    department: 'Computer Components',
    category: 'Components',
    subCategory: 'Interface Cards',
    successFee: 0.08,
    type: 'LEADTIME',
  },
  {
    department: 'Computer Components',
    category: 'Components',
    subCategory: 'Motherboard',
    successFee: 0.08,
    type: 'LEADTIME',
  },
  {
    department: 'Computer Components',
    category: 'Components',
    subCategory: 'No Cat',
    successFee: 0.1,
    type: 'LEADTIME',
  },
  {
    department: 'Computer Components',
    category: 'Components',
    subCategory: 'Optical Drive',
    successFee: 0.08,
    type: 'LEADTIME',
  },
  {
    department: 'Computer Components',
    category: 'Components',
    subCategory: 'Power Supplies',
    successFee: 0.08,
    type: 'LEADTIME',
  },
  {
    department: 'Computer Components',
    category: 'Components',
    subCategory: 'Sound Card',
    successFee: 0.08,
    type: 'LEADTIME',
  },
  {
    department: 'Computer Components',
    category: 'Components',
    subCategory: 'Blank Media',
    successFee: 0.1,
    type: 'LEADTIME',
  },
  {
    department: 'Computer Components',
    category: 'Components',
    subCategory: 'Hard Drive',
    successFee: 0.1,
    type: 'LEADTIME',
  },
  {
    department: 'Computer Components',
    category: 'Components',
    subCategory: 'No Cat',
    successFee: 0.12,
    type: 'LEADTIME',
  },
  {
    department: 'Computer Components',
    category: 'Components',
    subCategory: 'USB Flash Devices',
    successFee: 0.15,
    type: 'LEADTIME',
  },
  {
    department: 'Computer Components',
    category: 'Networking',
    subCategory: 'Access Points & Routers',
    successFee: 0.1,
    type: 'LEADTIME',
  },
  {
    department: 'Computer Components',
    category: 'Networking',
    subCategory: 'Extenders',
    successFee: 0.1,
    type: 'LEADTIME',
  },
  {
    department: 'Computer Components',
    category: 'Networking',
    subCategory: 'Modems',
    successFee: 0.1,
    type: 'LEADTIME',
  },
  {
    department: 'Computer Components',
    category: 'Networking',
    subCategory: 'Network Adapters & Hardware',
    successFee: 0.1,
    type: 'LEADTIME',
  },
  {
    department: 'Computer Components',
    category: 'Networking',
    subCategory: 'No Cat',
    successFee: 0.1,
    type: 'LEADTIME',
  },
  {
    department: 'Computer Components',
    category: 'Networking',
    subCategory: 'Switches, Cables & Servers',
    successFee: 0.1,
    type: 'LEADTIME',
  },
  {
    department: 'Computers & Laptops',
    category: 'Computer Monitor',
    subCategory: 'Computer Monitors',
    successFee: 0.07,
    type: 'LEADTIME',
  },
  {
    department: 'Computers & Laptops',
    category: 'Computer Monitor',
    subCategory: 'Gaming Monitors',
    successFee: 0.07,
    type: 'LEADTIME',
  },
  {
    department: 'Computers & Laptops',
    category: 'Computer Monitor',
    subCategory: 'Large Format Displays',
    successFee: 0.07,
    type: 'LEADTIME',
  },
  {
    department: 'Computers & Laptops',
    category: 'Computer Monitor',
    subCategory: 'Monitor Cleaning',
    successFee: 0.07,
    type: 'LEADTIME',
  },
  {
    department: 'Computers & Laptops',
    category: 'Computer Monitor',
    subCategory: 'No Cat',
    successFee: 0.07,
    type: 'LEADTIME',
  },
  {
    department: 'Computers & Laptops',
    category: 'Accessories & Peripherals',
    subCategory: 'Accessories',
    successFee: 0.12,
    type: 'LEADTIME',
  },
  {
    department: 'Computers & Laptops',
    category: 'Accessories & Peripherals',
    subCategory: 'No Cat',
    successFee: 0.12,
    type: 'LEADTIME',
  },
  {
    department: 'Computers & Laptops',
    category: 'Accessories & Peripherals',
    subCategory: 'Peripherals',
    successFee: 0.12,
    type: 'LEADTIME',
  },
  {
    department: 'Computers & Laptops',
    category: 'Computer Monitor Accessories',
    subCategory: 'Monitor Mounts & Brackets',
    successFee: 0.1,
    type: 'LEADTIME',
  },
  {
    department: 'Computers & Laptops',
    category: 'Computer Monitor Accessories',
    subCategory: 'Monitor Risers',
    successFee: 0.1,
    type: 'LEADTIME',
  },
  {
    department: 'Computers & Laptops',
    category: 'Computer Monitor Accessories',
    subCategory: 'No Cat',
    successFee: 0.1,
    type: 'LEADTIME',
  },
  {
    department: 'Computers & Laptops',
    category: 'Desktop PC & Workstations',
    subCategory: 'All-in-One',
    successFee: 0.08,
    type: 'LEADTIME',
  },
  {
    department: 'Computers & Laptops',
    category: 'Desktop PC & Workstations',
    subCategory: 'Desktop Computers',
    successFee: 0.08,
    type: 'LEADTIME',
  },
  {
    department: 'Computers & Laptops',
    category: 'Desktop PC & Workstations',
    subCategory: 'Gaming PC',
    successFee: 0.07,
    type: 'LEADTIME',
  },
  {
    department: 'Computers & Laptops',
    category: 'Desktop PC & Workstations',
    subCategory: 'Mini PC',
    successFee: 0.07,
    type: 'LEADTIME',
  },
  {
    department: 'Computers & Laptops',
    category: 'Desktop PC & Workstations',
    subCategory: 'No Cat',
    successFee: 0.07,
    type: 'LEADTIME',
  },
  {
    department: 'Computers & Laptops',
    category: 'Desktop PC & Workstations',
    subCategory: 'Workstations',
    successFee: 0.07,
    type: 'LEADTIME',
  },
  {
    department: 'Computers & Laptops',
    category: 'Laptops',
    subCategory: 'No Cat',
    successFee: 0.06,
    type: 'LEADTIME',
  },
  {
    department: 'Computers & Laptops',
    category: 'Software',
    subCategory: 'Business & Office',
    successFee: 0.06,
    type: 'LEADTIME',
  },
  {
    department: 'Computers & Laptops',
    category: 'Software',
    subCategory: 'Design & Creative software',
    successFee: 0.06,
    type: 'LEADTIME',
  },
  {
    department: 'Computers & Laptops',
    category: 'Software',
    subCategory: 'Downloads',
    successFee: 0.06,
    type: 'LEADTIME',
  },
  {
    department: 'Computers & Laptops',
    category: 'Software',
    subCategory: 'Educational',
    successFee: 0.06,
    type: 'LEADTIME',
  },
  {
    department: 'Computers & Laptops',
    category: 'Software',
    subCategory: 'Multimedia',
    successFee: 0.06,
    type: 'LEADTIME',
  },
  {
    department: 'Computers & Laptops',
    category: 'Software',
    subCategory: 'No Cat',
    successFee: 0.06,
    type: 'LEADTIME',
  },
  {
    department: 'Computers & Laptops',
    category: 'Software',
    subCategory: 'Security & Anti-Virus',
    successFee: 0.06,
    type: 'LEADTIME',
  },
  {
    department: 'DIY & Automotive',
    category: 'Auto Parts & Accessories',
    subCategory: 'Accessories',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'DIY & Automotive',
    category: 'Auto Parts & Accessories',
    subCategory: 'Car electronics',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'DIY & Automotive',
    category: 'Auto Parts & Accessories',
    subCategory: 'Consumables',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'DIY & Automotive',
    category: 'Auto Parts & Accessories',
    subCategory: 'No Cat',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'DIY & Automotive',
    category: 'Auto Parts & Accessories',
    subCategory: 'Parts',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'DIY & Automotive',
    category: 'Auto Parts & Accessories',
    subCategory: 'Tyres',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'DIY & Automotive',
    category: 'Home Improvement',
    subCategory: 'Adhesives & Sealants',
    successFee: 0.11,
    type: 'FLAT',
  },
  {
    department: 'DIY & Automotive',
    category: 'Home Improvement',
    subCategory: 'Electrical',
    successFee: 0.11,
    type: 'FLAT',
  },
  {
    department: 'DIY & Automotive',
    category: 'Home Improvement',
    subCategory: 'Hardware',
    successFee: 0.11,
    type: 'FLAT',
  },
  {
    department: 'DIY & Automotive',
    category: 'Home Improvement',
    subCategory: 'Ladders & Step Tools',
    successFee: 0.11,
    type: 'FLAT',
  },
  {
    department: 'DIY & Automotive',
    category: 'Home Improvement',
    subCategory: 'No Cat',
    successFee: 0.11,
    type: 'FLAT',
  },
  {
    department: 'DIY & Automotive',
    category: 'Home Improvement',
    subCategory: 'Paint & Supplies',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'DIY & Automotive',
    category: 'Home Improvement',
    subCategory: 'Plumbing',
    successFee: 0.11,
    type: 'FLAT',
  },
  {
    department: 'DIY & Automotive',
    category: 'Home Improvement',
    subCategory: 'Safety & Security',
    successFee: 0.11,
    type: 'FLAT',
  },
  {
    department: 'DIY & Automotive',
    category: 'Home Improvement',
    subCategory: 'Solar Power',
    successFee: 0.11,
    type: 'FLAT',
  },
  {
    department: 'DIY & Automotive',
    category: 'Motorcycle Parts & Accessories',
    subCategory: 'Accessories',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'DIY & Automotive',
    category: 'Motorcycle Parts & Accessories',
    subCategory: 'Apparel',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'DIY & Automotive',
    category: 'Motorcycle Parts & Accessories',
    subCategory: 'Consumables',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'DIY & Automotive',
    category: 'Motorcycle Parts & Accessories',
    subCategory: 'Helmets',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'DIY & Automotive',
    category: 'Motorcycle Parts & Accessories',
    subCategory: 'No Cat',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'DIY & Automotive',
    category: 'Motorcycle Parts & Accessories',
    subCategory: 'Parts',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'DIY & Automotive',
    category: 'Motorcycle Parts & Accessories',
    subCategory: 'Tyres',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'DIY & Automotive',
    category: 'No Cat',
    subCategory: 'No Cat',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'DIY & Automotive',
    category: 'Tools & Machinery',
    subCategory: 'Accessories',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'DIY & Automotive',
    category: 'Tools & Machinery',
    subCategory: 'Hand Tools',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'DIY & Automotive',
    category: 'Tools & Machinery',
    subCategory: 'Measuring & Layout Tools',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'DIY & Automotive',
    category: 'Tools & Machinery',
    subCategory: 'No Cat',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'DIY & Automotive',
    category: 'Tools & Machinery',
    subCategory: 'Power Tools',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'DIY & Automotive',
    category: 'Tools & Machinery',
    subCategory: 'Storage',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'DIY & Automotive',
    category: 'Tools & Machinery',
    subCategory: 'Workwear & PPE',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Electronic Accessories',
    category: 'Cellular Accessories',
    subCategory: 'Accessory Kits',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Electronic Accessories',
    category: 'Cellular Accessories',
    subCategory: 'Batteries & Chargers',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Electronic Accessories',
    category: 'Cellular Accessories',
    subCategory: 'Cases & Covers',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Electronic Accessories',
    category: 'Cellular Accessories',
    subCategory: 'Cellphone Cables',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Electronic Accessories',
    category: 'Cellular Accessories',
    subCategory: 'Cellphone Headsets',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Electronic Accessories',
    category: 'Cellular Accessories',
    subCategory: 'Gadgets & Novelties',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Electronic Accessories',
    category: 'Cellular Accessories',
    subCategory: 'In-Car & Hands Free Kits',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Electronic Accessories',
    category: 'Cellular Accessories',
    subCategory: 'Memory Cards',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Electronic Accessories',
    category: 'Cellular Accessories',
    subCategory: 'Mounts & Stands',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Electronic Accessories',
    category: 'Cellular Accessories',
    subCategory: 'No Cat',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Electronic Accessories',
    category: 'Cellular Accessories',
    subCategory: 'Screen Protectors',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Electronic Accessories',
    category: 'Cellular Accessories',
    subCategory: 'Smartphone Photography',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Electronic Accessories',
    category: 'Cleaning & Repairs',
    subCategory: 'No Cat',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Electronic Accessories',
    category: 'Laptop Accessories',
    subCategory: 'Bags & Cases',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Electronic Accessories',
    category: 'Laptop Accessories',
    subCategory: 'Batteries',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Electronic Accessories',
    category: 'Laptop Accessories',
    subCategory: 'Chargers & Adapters',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Electronic Accessories',
    category: 'Laptop Accessories',
    subCategory: 'Cooling Pads',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Electronic Accessories',
    category: 'Laptop Accessories',
    subCategory: 'Covers & Sleeves',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Electronic Accessories',
    category: 'Laptop Accessories',
    subCategory: 'Docking Stations',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Electronic Accessories',
    category: 'Laptop Accessories',
    subCategory: 'Keyboard Covers',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Electronic Accessories',
    category: 'Laptop Accessories',
    subCategory: 'Memory Card Readers',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Electronic Accessories',
    category: 'Laptop Accessories',
    subCategory: 'Memory Cards',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Electronic Accessories',
    category: 'Laptop Accessories',
    subCategory: 'Mounts & Stands',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Electronic Accessories',
    category: 'Laptop Accessories',
    subCategory: 'No Cat',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Electronic Accessories',
    category: 'Laptop Accessories',
    subCategory: 'Power Banks',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Electronic Accessories',
    category: 'Laptop Accessories',
    subCategory: 'Screen Protection & Privacy',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Electronic Accessories',
    category: 'Laptop Accessories',
    subCategory: 'Security Locks',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Electronic Accessories',
    category: 'Laptop Accessories',
    subCategory: 'Skins & Decals',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Electronic Accessories',
    category: 'No Cat',
    subCategory: 'No Cat',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Electronic Accessories',
    category: 'Tablet & E-Reader Accessories',
    subCategory: 'E-Reader Accessories',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Electronic Accessories',
    category: 'Tablet & E-Reader Accessories',
    subCategory: 'Tablet Accessories',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Games',
    category: 'Consoles',
    subCategory: 'No Cat',
    successFee: 0.07,
    type: 'LEADTIME',
  },
  {
    department: 'Games',
    category: 'Game-Software',
    subCategory: 'No Cat',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Games',
    category: 'Gaming Accessories',
    subCategory: 'Controllers',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Games',
    category: 'Gaming Accessories',
    subCategory: 'Gaming Chair',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Games',
    category: 'Gaming Accessories',
    subCategory: 'Gaming Keyboard',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Games',
    category: 'Gaming Accessories',
    subCategory: 'Gaming Mouse',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Games',
    category: 'Gaming Accessories',
    subCategory: 'Gaming Mousepad',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Games',
    category: 'Gaming Accessories',
    subCategory: 'Headsets',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Games',
    category: 'Gaming Accessories',
    subCategory: 'No Cat',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Games',
    category: 'Gaming Accessories',
    subCategory: 'Protection',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Games',
    category: 'Merchandise',
    subCategory: 'No Cat',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Games',
    category: 'No Cat',
    subCategory: 'No Cat',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'Braais & Outdoor Cooking',
    subCategory: 'Braai Accessories',
    successFee: 0.13,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'Braais & Outdoor Cooking',
    subCategory: 'Braai',
    successFee: 0.13,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'Braais & Outdoor Cooking',
    subCategory: 'Fire Pits',
    successFee: 0.13,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'Braais & Outdoor Cooking',
    subCategory: 'Gas Tanks & Cannisters',
    successFee: 0.13,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'Braais & Outdoor Cooking',
    subCategory: 'Grids & Grills',
    successFee: 0.13,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'Braais & Outdoor Cooking',
    subCategory: 'No Cat',
    successFee: 0.13,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'Braais & Outdoor Cooking',
    subCategory: 'Picnic Baskets & Accessories',
    successFee: 0.13,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'Braais & Outdoor Cooking',
    subCategory: 'Pizza Ovens & Tools',
    successFee: 0.13,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'Braais & Outdoor Cooking',
    subCategory: 'Potjie Pots',
    successFee: 0.13,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'Braais & Outdoor Cooking',
    subCategory: 'Racks & Attachments',
    successFee: 0.13,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'Braais & Outdoor Cooking',
    subCategory: 'Rotisseries & Skewers',
    successFee: 0.13,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'Equipment',
    subCategory: 'Borehole Pumps',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'Equipment',
    subCategory: 'Garden Sheds & Storage',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'Equipment',
    subCategory: 'Garden Tools',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'Equipment',
    subCategory: 'Hoses, Connectors & Accessories',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'Equipment',
    subCategory: 'House Numbers & Signs',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'Equipment',
    subCategory: 'Irrigation - Sprinklers & Pumps',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'Equipment',
    subCategory: 'Irrigation Fittings, Parts & Accessories',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'Equipment',
    subCategory: 'Lawn & Garden Safety Equipment',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'Equipment',
    subCategory: 'Lawn Mowers & Power Tools',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'Equipment',
    subCategory: 'No Cat',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'Equipment',
    subCategory: 'Ponds & Fountains',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'Equipment',
    subCategory: 'Water Tanks & Pillows',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'Garden & Outdoor Consumables',
    subCategory: 'Dustbins & Garbage',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'Garden & Outdoor Consumables',
    subCategory: 'Gardening',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'Garden & Outdoor Consumables',
    subCategory: 'No Cat',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'Garden & Outdoor Consumables',
    subCategory: 'Pool Cleaning Consumables',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'Garden & Outdoor Consumables',
    subCategory: 'Wood & Charcoal',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'No Cat',
    subCategory: 'No Cat',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'Patio',
    subCategory: 'Fireplaces',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'Patio',
    subCategory: 'No Cat',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'Patio',
    subCategory: 'Outdoor Lighting & Accessories',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'Patio',
    subCategory: 'Outdoor Mats',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'Patio',
    subCategory: 'Outdoor Recreation Equipment',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'Patio',
    subCategory: 'Patio Decor & Accessories',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'Patio',
    subCategory: 'Patio Furniture',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'Patio',
    subCategory: 'Patio Heaters',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'Patio',
    subCategory: 'Pots & Ornaments',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'Pool & Spa',
    subCategory: 'Inflatable Spa',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'Pool & Spa',
    subCategory: 'No Cat',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'Pool & Spa',
    subCategory: 'Pool Cleaners & Accessories',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'Pool & Spa',
    subCategory: 'Pool Lighting',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'Pool & Spa',
    subCategory: 'Pool Nets & Covers',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'Pool & Spa',
    subCategory: 'Pool Safety Equipment',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'Pool & Spa',
    subCategory: 'Swimming Aids & Inflatables',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Garden, Pool & Patio',
    category: 'Pool & Spa',
    subCategory: 'Swimming Pools',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Health',
    category: 'Health Care Devices',
    subCategory: 'Health Monitors & Tests',
    successFee: 0.11,
    type: 'FLAT',
  },
  {
    department: 'Health',
    category: 'Health Care Devices',
    subCategory: 'Humidifiers & Purifiers',
    successFee: 0.11,
    type: 'FLAT',
  },
  {
    department: 'Health',
    category: 'Health Care Devices',
    subCategory: 'Miscellaneous Equipment',
    successFee: 0.11,
    type: 'FLAT',
  },
  {
    department: 'Health',
    category: 'Health Care Devices',
    subCategory: 'No Cat',
    successFee: 0.11,
    type: 'FLAT',
  },
  {
    department: 'Health',
    category: 'Health Care Devices',
    subCategory: 'Respiratory Care',
    successFee: 0.11,
    type: 'FLAT',
  },
  {
    department: 'Health',
    category: 'Health Care Devices',
    subCategory: 'Smoking Alternatives',
    successFee: 0.11,
    type: 'FLAT',
  },
  {
    department: 'Health',
    category: 'Health FMCG',
    subCategory: 'First Aid',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Health',
    category: 'Health FMCG',
    subCategory: 'Health Food',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Health',
    category: 'Health FMCG',
    subCategory: 'Medicine & Treatments',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Health',
    category: 'Health FMCG',
    subCategory: 'No Cat',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Health',
    category: 'Health FMCG',
    subCategory: 'Sexual Health',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Health',
    category: 'Health FMCG',
    subCategory: 'Sports Nutrition',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Health',
    category: 'Health FMCG',
    subCategory: 'Vitamins & Supplements',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Health',
    category: 'Personal Care Devices',
    subCategory: 'Miscellaneous Equipment',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Health',
    category: 'Personal Care Devices',
    subCategory: 'No Cat',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Health',
    category: 'Personal Care Devices',
    subCategory: 'Oral Care',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Health',
    category: 'Personal Care Devices',
    subCategory: 'Shaving & Hair Removal Equipment',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Health',
    category: 'Personal Care FMCG',
    subCategory: 'Deodorant',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Health',
    category: 'Personal Care FMCG',
    subCategory: 'Ear & Eye Care',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Health',
    category: 'Personal Care FMCG',
    subCategory: 'Feminine Care',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Health',
    category: 'Personal Care FMCG',
    subCategory: 'Lip & Skin Care',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Health',
    category: 'Personal Care FMCG',
    subCategory: 'No Cat',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Health',
    category: 'Personal Care FMCG',
    subCategory: 'Oral Care',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Health',
    category: 'Personal Care FMCG',
    subCategory: 'Shaving & Hair Removal',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Health',
    category: 'Personal Care FMCG',
    subCategory: 'Shaving & Hair Removal Equipment',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Health',
    category: 'Personal Care FMCG',
    subCategory: 'Sun Protection & Care',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Bathroom',
    subCategory: 'Bathroom Accessories',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Bathroom',
    subCategory: 'Bathroom Storage',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Bathroom',
    subCategory: 'Linen & Towels',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Bathroom',
    subCategory: 'No Cat',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Bedroom',
    subCategory: 'Bedroom Accessories',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Bedroom',
    subCategory: 'Bedroom Storage',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Bedroom',
    subCategory: 'Linen & Sheets',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Dining & Entertaining',
    subCategory: 'Bar Accessories',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Dining & Entertaining',
    subCategory: 'Crockery',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Dining & Entertaining',
    subCategory: 'Cutlery',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Dining & Entertaining',
    subCategory: 'Glassware',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Dining & Entertaining',
    subCategory: 'No Cat',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Dining & Entertaining',
    subCategory: 'Serveware',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Furniture',
    subCategory: 'Bathroom Furniture',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Furniture',
    subCategory: 'Bedroom Furniture',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Furniture',
    subCategory: 'Children’s Bedroom Furniture',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Furniture',
    subCategory: 'Dining Room Furniture',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Furniture',
    subCategory: 'Hallway Furniture',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Furniture',
    subCategory: 'Kitchen Furniture',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Furniture',
    subCategory: 'Living Room Furniture',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Furniture',
    subCategory: 'No Cat',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Furniture',
    subCategory: 'Study & Home Office Furniture',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Home Decor',
    subCategory: 'Barometers & Weather Stations',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Home Decor',
    subCategory: 'Candles & Holders',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Home Decor',
    subCategory: 'Curtains',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Home Decor',
    subCategory: 'Decorative Bowls & Plates',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Home Decor',
    subCategory: 'Decorative Cushions & Scatters',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Home Decor',
    subCategory: 'Diffusers & Refills',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Home Decor',
    subCategory: 'Home Storage & Collapsable Boxes',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Home Decor',
    subCategory: 'Lamps & Lighting',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Home Decor',
    subCategory: 'No Cat',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Home Decor',
    subCategory: 'Ornaments',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Home Decor',
    subCategory: 'Picture Frames & Photo Frames',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Home Decor',
    subCategory: 'Table Linen & Decor',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Home Decor',
    subCategory: 'Vases & Planters',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Home Decor',
    subCategory: 'Wall Art',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Kitchen',
    subCategory: 'Bakeware',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Kitchen',
    subCategory: 'Catering & Hospitality equipment',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Kitchen',
    subCategory: 'Cleaning & Bins',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Kitchen',
    subCategory: 'Cookware',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Kitchen',
    subCategory: 'Food Preparation',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Kitchen',
    subCategory: 'Kitchen Accessories',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Kitchen',
    subCategory: 'Kitchen Organisation & Utensils',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Kitchen',
    subCategory: 'Kitchen Scissors & Knives',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Kitchen',
    subCategory: 'Kitchen Storage',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Kitchen',
    subCategory: 'No Cat',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Kitchen',
    subCategory: 'Plasticware',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Kitchen',
    subCategory: 'Washing & Laundry',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Kitchen',
    subCategory: 'Water Filters & Bottles',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'No Cat',
    subCategory: 'No Cat',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Office Accessories',
    subCategory: 'Clocks',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Office Accessories',
    subCategory: 'Drawers',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Office Accessories',
    subCategory: 'Floor & Desk Mats',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Office Accessories',
    subCategory: 'Hat & Coat Stand',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Office Accessories',
    subCategory: 'No Cat',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Office Accessories',
    subCategory: 'Office Lighting',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Office Accessories',
    subCategory: 'Clocks',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Office Accessories',
    subCategory: 'Drawers',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Office Accessories',
    subCategory: 'Floor & Desk Mats',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Office Accessories',
    subCategory: 'Hat & Coat Stand',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Office Accessories',
    subCategory: 'No Cat',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Office Accessories',
    subCategory: 'Office Lighting',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Office Accessories',
    subCategory: 'Office Planters',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Office Accessories',
    subCategory: 'Waste Paper Bins',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Large Appliances',
    category: 'Dishwashers',
    subCategory: 'No Cat',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Large Appliances',
    category: 'Fridges & Freezers',
    subCategory: 'Combination',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Large Appliances',
    category: 'Fridges & Freezers',
    subCategory: 'Freezers',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Large Appliances',
    category: 'Fridges & Freezers',
    subCategory: 'Fridges',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Large Appliances',
    category: 'Fridges & Freezers',
    subCategory: 'Mini Fridges',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Large Appliances',
    category: 'Fridges & Freezers',
    subCategory: 'No Catt',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Large Appliances',
    category: 'Microwaves & Cookers',
    subCategory: 'Induction Cookers',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Large Appliances',
    category: 'Microwaves & Cookers',
    subCategory: 'Microwaves',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Large Appliances',
    category: 'Microwaves & Cookers',
    subCategory: 'Mini Ovens',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Large Appliances',
    category: 'Microwaves & Cookers',
    subCategory: 'No Cat',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Large Appliances',
    category: 'Parts & Accessories',
    subCategory: 'Accessories',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Large Appliances',
    category: 'Stoves & Ovens',
    subCategory: 'Freestanding Stoves & Cookers',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Large Appliances',
    category: 'Stoves & Ovens',
    subCategory: 'Integrated',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Large Appliances',
    category: 'Stoves & Ovens',
    subCategory: 'Ovens',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Large Appliances',
    category: 'Washing & Drying',
    subCategory: 'No Cat',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Large Appliances',
    category: 'Washing & Drying',
    subCategory: 'Tumble Dryers',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Large Appliances',
    category: 'Washing & Drying',
    subCategory: 'Washer Dryers',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Large Appliances',
    category: 'Washing & Drying',
    subCategory: 'Washing Machines',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: '',
    category: '',
    subCategory: '',
    successFee: '',
    type: '',
  },
  {
    department: 'Liquor',
    category: 'Beer',
    subCategory: 'Beer',
    successFee: 0.07,
    type: 'FLAT',
  },
  {
    department: 'Liquor',
    category: 'Beer',
    subCategory: 'Cider',
    successFee: 0.07,
    type: 'FLAT',
  },
  {
    department: 'Liquor',
    category: 'Beer',
    subCategory: 'Craft Beer',
    successFee: 0.07,
    type: 'FLAT',
  },
  {
    department: 'Liquor',
    category: 'Beer',
    subCategory: 'No Cat',
    successFee: 0.07,
    type: 'FLAT',
  },
  {
    department: 'Liquor',
    category: 'No Cat',
    subCategory: 'No Cat',
    successFee: 0.07,
    type: 'FLAT',
  },
  {
    department: 'Liquor',
    category: 'Non-Alcoholic',
    subCategory: 'Bar Snacks',
    successFee: 0.07,
    type: 'FLAT',
  },
  {
    department: 'Liquor',
    category: 'Non-Alcoholic',
    subCategory: 'Carbonated Soft Drinks',
    successFee: 0.07,
    type: 'FLAT',
  },
  {
    department: 'Liquor',
    category: 'Non-Alcoholic',
    subCategory: 'Cordials & Concentrates',
    successFee: 0.07,
    type: 'FLAT',
  },
  {
    department: 'Liquor',
    category: 'Non-Alcoholic',
    subCategory: 'Energy Drinks',
    successFee: 0.07,
    type: 'FLAT',
  },
  {
    department: 'Liquor',
    category: 'Non-Alcoholic',
    subCategory: 'Fruit Juices & Iced Tea',
    successFee: 0.07,
    type: 'FLAT',
  },
  {
    department: 'Liquor',
    category: 'Non-Alcoholic',
    subCategory: 'No Cat',
    successFee: 0.07,
    type: 'FLAT',
  },
  {
    department: 'Liquor',
    category: 'Non-Alcoholic',
    subCategory: 'Water',
    successFee: 0.07,
    type: 'FLAT',
  },
  {
    department: 'Liquor',
    category: 'Premixes',
    subCategory: 'No Cat',
    successFee: 0.07,
    type: 'FLAT',
  },
  {
    department: 'Liquor',
    category: 'Spirits',
    subCategory: 'Brandy',
    successFee: 0.07,
    type: 'FLAT',
  },
  {
    department: 'Liquor',
    category: 'Spirits',
    subCategory: 'Cognac',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Liquor',
    category: 'Spirits',
    subCategory: 'Gift Packs',
    successFee: 0.07,
    type: 'FLAT',
  },
  {
    department: 'Liquor',
    category: 'Spirits',
    subCategory: 'Gift sets',
    successFee: 0.07,
    type: 'FLAT',
  },
  {
    department: 'Liquor',
    category: 'Spirits',
    subCategory: 'Gin',
    successFee: 0.07,
    type: 'FLAT',
  },
  {
    department: 'Liquor',
    category: 'Spirits',
    subCategory: 'Liqueurs & Aperitifs',
    successFee: 0.07,
    type: 'FLAT',
  },
  {
    department: 'Liquor',
    category: 'Spirits',
    subCategory: 'Rum',
    successFee: 0.07,
    type: 'FLAT',
  },
  {
    department: 'Liquor',
    category: 'Spirits',
    subCategory: 'Tequila',
    successFee: 0.07,
    type: 'FLAT',
  },
  {
    department: 'Liquor',
    category: 'Spirits',
    subCategory: 'Vodka',
    successFee: 0.07,
    type: 'FLAT',
  },
  {
    department: 'Liquor',
    category: 'Spirits',
    subCategory: 'Whiskey',
    successFee: 0.07,
    type: 'FLAT',
  },
  {
    department: 'Liquor',
    category: 'Wine',
    subCategory: 'Champagne & Sparkling Wine',
    successFee: 0.07,
    type: 'FLAT',
  },
  {
    department: 'Liquor',
    category: 'Wine',
    subCategory: 'Fortified & Dessert Wines',
    successFee: 0.07,
    type: 'FLAT',
  },
  {
    department: 'Liquor',
    category: 'Wine',
    subCategory: 'Gifting',
    successFee: 0.07,
    type: 'FLAT',
  },
  {
    department: 'Liquor',
    category: 'Wine',
    subCategory: 'Red',
    successFee: 0.07,
    type: 'FLAT',
  },
  {
    department: 'Liquor',
    category: 'Wine',
    subCategory: 'Rose',
    successFee: 0.07,
    type: 'FLAT',
  },
  {
    department: 'Liquor',
    category: 'Wine',
    subCategory: 'White',
    successFee: 0.07,
    type: 'FLAT',
  },
  {
    department: 'Luggage & Travel',
    category: 'Handbags & Wallets',
    subCategory: 'Handbags',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Luggage & Travel',
    category: 'Handbags & Wallets',
    subCategory: 'No Cat',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Luggage & Travel',
    category: 'Handbags & Wallets',
    subCategory: 'Wallets & Purses',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Luggage & Travel',
    category: 'Luggage',
    subCategory: 'Backpacks & Duffels',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Luggage & Travel',
    category: 'Luggage',
    subCategory: 'Business & Student',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Luggage & Travel',
    category: 'Luggage',
    subCategory: 'No Cat',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Luggage & Travel',
    category: 'Luggage',
    subCategory: 'Suitcases',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Luggage & Travel',
    category: 'Travel Accessories',
    subCategory: 'No Cat',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Mobile',
    category: 'Cell Phones',
    subCategory: 'No Cat',
    successFee: 0.09,
    type: 'LEADTIME',
  },
  {
    department: 'Mobile',
    category: 'No Cat',
    subCategory: 'No Cat',
    successFee: 0.09,
    type: 'LEADTIME',
  },
  {
    department: 'Mobile',
    category: 'Tablets & E-readers',
    subCategory: 'No Cat',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Music & DVD',
    category: 'Merchandise',
    subCategory: 'No Cat',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Music & DVD',
    category: 'Movies',
    subCategory: 'No Cat',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Music & DVD',
    category: 'Music',
    subCategory: 'No Cat',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Music & DVD',
    category: 'No Cat',
    subCategory: 'No Cat',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Music & DVD',
    category: 'TV Series',
    subCategory: 'No Cat',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Musical Instruments',
    category: 'Accessories',
    subCategory: 'Bags & Cases',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Musical Instruments',
    category: 'Accessories',
    subCategory: 'Cables',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Musical Instruments',
    category: 'Accessories',
    subCategory: 'No Cat',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Musical Instruments',
    category: 'Accessories',
    subCategory: 'Pedals',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Musical Instruments',
    category: 'Accessories',
    subCategory: 'Practice Pads',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Musical Instruments',
    category: 'Accessories',
    subCategory: 'Racks, Mounts & Stands',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Musical Instruments',
    category: 'Accessories',
    subCategory: 'Stands',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Musical Instruments',
    category: 'Accessories',
    subCategory: 'Straps',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Musical Instruments',
    category: 'Accessories',
    subCategory: 'Strings',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Musical Instruments',
    category: 'Accessories',
    subCategory: 'Tuners',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Musical Instruments',
    category: 'Instruments',
    subCategory: 'Accordions',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Musical Instruments',
    category: 'Instruments',
    subCategory: 'Classical',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Musical Instruments',
    category: 'Instruments',
    subCategory: 'Dj & Lighting',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Musical Instruments',
    category: 'Instruments',
    subCategory: 'Dj Equipment & Production',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Musical Instruments',
    category: 'Instruments',
    subCategory: 'Drums & Percussion',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Musical Instruments',
    category: 'Instruments',
    subCategory: 'Guitars',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Musical Instruments',
    category: 'Instruments',
    subCategory: 'Harmonicas',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Musical Instruments',
    category: 'Instruments',
    subCategory: 'Keyboards',
    successFee: 0.09,
    type: 'FLAT',
  },
  {
    department: 'Musical Instruments',
    category: 'Instruments',
    subCategory: 'Melodica',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Musical Instruments',
    category: 'Instruments',
    subCategory: 'No Cat',
    successFee: 0.09,
    type: 'FLAT',
  },
  {
    department: 'Musical Instruments',
    category: 'No Cat',
    subCategory: 'No Cat',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Sound & Stage',
    subCategory: 'Amplifiers',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Sound & Stage',
    subCategory: 'Audio Interfaces',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Sound & Stage',
    subCategory: 'Audio Recording',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Sound & Stage',
    subCategory: 'Equalizers',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Sound & Stage',
    subCategory: 'Lighting',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Sound & Stage',
    subCategory: 'Microphone Systems',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Sound & Stage',
    subCategory: 'Midi Controllers',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Sound & Stage',
    subCategory: 'No Cat',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Sound & Stage',
    subCategory: 'Pa Systems',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Sound & Stage',
    subCategory: 'Receivers',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Sound & Stage',
    subCategory: 'Studio Equipment & Monitors',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Sound & Stage',
    subCategory: 'Studio Monitors',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Sound & Stage',
    subCategory: 'Subwoofers',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Homeware',
    category: 'Sound & Stage',
    subCategory: 'Synthesizers',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'No Department',
    category: 'No Cat',
    subCategory: 'No Cat',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Non-Perishable',
    category: 'Food & Beverages',
    subCategory: 'No Cat',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Non-Perishable',
    category: 'Groceries',
    subCategory: 'No Cat',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Non-Perishable',
    category: 'Household Cleaning',
    subCategory: 'No Cat',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Non-Perishable',
    category: 'No Cat',
    subCategory: 'No Cat',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'Office',
    category: 'Home & Office Electronics',
    subCategory: 'Calculators',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Office',
    category: 'Home & Office Electronics',
    subCategory: 'Label Makers',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Office',
    category: 'Home & Office Electronics',
    subCategory: 'Laminators',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Office',
    category: 'Home & Office Electronics',
    subCategory: 'No Cat',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Office',
    category: 'Home & Office Electronics',
    subCategory: 'Phone Accessories',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Office',
    category: 'Home & Office Electronics',
    subCategory: 'Phone Peripherals',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Office',
    category: 'Home & Office Electronics',
    subCategory: 'Phones & Voip',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Office',
    category: 'Home & Office Electronics',
    subCategory: 'Point-Of-Sale Equipment',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Office',
    category: 'Home & Office Electronics',
    subCategory: 'Projector Accessories',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Office',
    category: 'Home & Office Electronics',
    subCategory: 'Projectors',
    successFee: 0.07,
    type: 'FLAT',
  },
  {
    department: 'Office',
    category: 'Home & Office Electronics',
    subCategory: 'Shredders',
    successFee: 0.07,
    type: 'FLAT',
  },
  {
    department: 'Office',
    category: 'Home & Office Electronics',
    subCategory: 'Trimmers & Guillotines',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Office',
    category: 'Home & Office Electronics',
    subCategory: 'Voice Recorders & Accessories',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Office',
    category: 'No Cat',
    subCategory: 'No Cat',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Office',
    category: 'Office Consumables',
    subCategory: 'Cleaning supplies',
    successFee: 0.15,
    type: 'LEADTIME',
  },
  {
    department: 'Office',
    category: 'Office Consumables',
    subCategory: 'Filaments',
    successFee: 0.15,
    type: 'LEADTIME',
  },
  {
    department: 'Office',
    category: 'Office Consumables',
    subCategory: 'Ink',
    successFee: 0.15,
    type: 'LEADTIME',
  },
  {
    department: 'Office',
    category: 'Office Consumables',
    subCategory: 'Laminating Supplies',
    successFee: 0.15,
    type: 'LEADTIME',
  },
  {
    department: 'Office',
    category: 'Office Consumables',
    subCategory: 'No Cat',
    successFee: 0.15,
    type: 'LEADTIME',
  },
  {
    department: 'Office',
    category: 'Office Consumables',
    subCategory: 'Printing Paper',
    successFee: 0.15,
    type: 'LEADTIME',
  },
  {
    department: 'Office',
    category: 'Office Consumables',
    subCategory: 'Ribbons',
    successFee: 0.15,
    type: 'LEADTIME',
  },
  {
    department: 'Office',
    category: 'Office Consumables',
    subCategory: 'Tapes',
    successFee: 0.15,
    type: 'LEADTIME',
  },
  {
    department: 'Office',
    category: 'Office Consumables',
    subCategory: 'Toner',
    successFee: 0.15,
    type: 'LEADTIME',
  },
  {
    department: 'Office',
    category: 'Printers, Scanner, Copier',
    subCategory: 'Copier',
    successFee: 0.07,
    type: 'FLAT',
  },
  {
    department: 'Office',
    category: 'Printers, Scanner, Copier',
    subCategory: 'No Cat',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Office',
    category: 'Printers, Scanner, Copier',
    subCategory: 'Printer Accessories',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Office',
    category: 'Printers, Scanner, Copier',
    subCategory: 'Printers',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Office',
    category: 'Printers, Scanner, Copier',
    subCategory: 'Scanner Accessories',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Office',
    category: 'Printers, Scanner, Copier',
    subCategory: 'Scanners',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Office Furniture',
    category: 'Office Furniture & Storage',
    subCategory: 'Board & Easels',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Office Furniture',
    category: 'Office Furniture & Storage',
    subCategory: 'Cabinets',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Office Furniture',
    category: 'Office Furniture & Storage',
    subCategory: 'Chairs & Seating',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Office Furniture',
    category: 'Office Furniture & Storage',
    subCategory: 'Ergonomics',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Office Furniture',
    category: 'Office Furniture & Storage',
    subCategory: 'No Cat',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Office Furniture',
    category: 'Office Furniture & Storage',
    subCategory: 'Storage & Shelving',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Office Furniture',
    category: 'Office Furniture & Storage',
    subCategory: 'Tables & Desks',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Pets',
    category: 'Equipment & Accessories',
    subCategory: 'Apparel',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Pets',
    category: 'Equipment & Accessories',
    subCategory: 'Beds & Blankets',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Pets',
    category: 'Equipment & Accessories',
    subCategory: 'Cages, Carriers & Accessories',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Pets',
    category: 'Equipment & Accessories',
    subCategory: 'Collars, Harnesses & Leads',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Pets',
    category: 'Equipment & Accessories',
    subCategory: 'Doors & Flaps',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Pets',
    category: 'Equipment & Accessories',
    subCategory: 'Feeders & Bowls',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Pets',
    category: 'Equipment & Accessories',
    subCategory: 'First Aid',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Pets',
    category: 'Equipment & Accessories',
    subCategory: 'Grooming',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Pets',
    category: 'Equipment & Accessories',
    subCategory: 'Litter & Accessories',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Pets',
    category: 'Equipment & Accessories',
    subCategory: 'No Cat',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Pets',
    category: 'Equipment & Accessories',
    subCategory: 'Toys',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Pets',
    category: 'Food & Treats',
    subCategory: 'Dry Food',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Pets',
    category: 'Food & Treats',
    subCategory: 'No Cat',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Pets',
    category: 'Food & Treats',
    subCategory: 'Treats',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Pets',
    category: 'Food & Treats',
    subCategory: 'Vet Food',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Pets',
    category: 'Food & Treats',
    subCategory: 'Wet Food',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Pets',
    category: 'No Cat',
    subCategory: 'No Cat',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Pets',
    category: 'Pet Care Supplies',
    subCategory: 'Apparel',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Pets',
    category: 'Pet Care Supplies',
    subCategory: 'Beds & Blankets',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Pets',
    category: 'Pet Care Supplies',
    subCategory: 'Cages, Carriers & Accessories',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Pets',
    category: 'Pet Care Supplies',
    subCategory: 'Collars, Harnesses & Leads',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Pets',
    category: 'Pet Care Supplies',
    subCategory: 'Doors & Flaps',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Pets',
    category: 'Pet Care Supplies',
    subCategory: 'Feeders & Bowls',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Pets',
    category: 'Pet Care Supplies',
    subCategory: 'First Aid',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Pets',
    category: 'Pet Care Supplies',
    subCategory: 'Grooming',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Pets',
    category: 'Pet Care Supplies',
    subCategory: 'Litter & Accessories',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Pets',
    category: 'Pet Care Supplies',
    subCategory: 'No Cat',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Pets',
    category: 'Pet Care Supplies',
    subCategory: 'Toys',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Small Appliances',
    category: 'S. Appliance Acc. & Consumables',
    subCategory: 'Cleaning & Detergent Supplies',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Small Appliances',
    category: 'S. Appliance Acc. & Consumables',
    subCategory: 'Coffee & Beverage Supplies',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Small Appliances',
    category: 'S. Appliance Acc. & Consumables',
    subCategory: 'No Cat',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Small Appliances',
    category: 'Small Household Appliances',
    subCategory: 'Cleaning Appliances',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Small Appliances',
    category: 'Small Household Appliances',
    subCategory: 'Heating, Cooling and Air Quality',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Small Appliances',
    category: 'Small Household Appliances',
    subCategory: 'No Cat',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Small Appliances',
    category: 'Small Household Appliances',
    subCategory: 'Small Household Appliance Parts',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Small Appliances',
    category: 'Small Kitchen Appliances',
    subCategory: 'Coffee Machine Accessories',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Small Appliances',
    category: 'Small Kitchen Appliances',
    subCategory: 'Coffee Machines',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Small Appliances',
    category: 'Small Kitchen Appliances',
    subCategory: 'Cooking Appliances',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Small Appliances',
    category: 'Small Kitchen Appliances',
    subCategory: 'Food Preparation Appliances',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Small Appliances',
    category: 'Small Kitchen Appliances',
    subCategory: 'French Presses',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Small Appliances',
    category: 'Small Kitchen Appliances',
    subCategory: 'No Cat',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Small Appliances',
    category: 'Small Kitchen Appliances',
    subCategory: 'Specialty Appliances',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Small Appliances',
    category: 'Small Kitchen Appliances',
    subCategory: 'Tea Makers',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Small Appliances',
    category: 'Small Kitchen Appliances',
    subCategory: 'Toasters & Kettles',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Smart Home & Connected Living',
    category: 'GPS Devices',
    subCategory: 'No Cat',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Smart Home & Connected Living',
    category: 'Home Security',
    subCategory: 'Access Control',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Smart Home & Connected Living',
    category: 'Home Security',
    subCategory: 'CCTV Kits',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Smart Home & Connected Living',
    category: 'Home Security',
    subCategory: 'Home Security Accessories',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Smart Home & Connected Living',
    category: 'Home Security',
    subCategory: 'Intercoms',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Smart Home & Connected Living',
    category: 'Home Security',
    subCategory: 'Monitoring & Recording',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Smart Home & Connected Living',
    category: 'Home Security',
    subCategory: 'No Cat',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Smart Home & Connected Living',
    category: 'Home Security',
    subCategory: 'Security Cameras',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Smart Home & Connected Living',
    category: 'No Cat',
    subCategory: 'No Cat',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Smart Home & Connected Living',
    category: 'Smart Accessories',
    subCategory: 'Cables',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Smart Home & Connected Living',
    category: 'Smart Accessories',
    subCategory: 'Cases',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Smart Home & Connected Living',
    category: 'Smart Accessories',
    subCategory: 'Charging Devices',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Smart Home & Connected Living',
    category: 'Smart Accessories',
    subCategory: 'Docking Stations',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Smart Home & Connected Living',
    category: 'Smart Accessories',
    subCategory: 'No Cat',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Smart Home & Connected Living',
    category: 'Smart Accessories',
    subCategory: 'Screen Protectors',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Smart Home & Connected Living',
    category: 'Smart Accessories',
    subCategory: 'Straps & Bands',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Smart Home & Connected Living',
    category: 'Smart Audio Technology',
    subCategory: 'No Cat',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Smart Home & Connected Living',
    category: 'Smart Energy Solutions',
    subCategory: 'Inverters',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Smart Home & Connected Living',
    category: 'Smart Energy Solutions',
    subCategory: 'Smart Plugs',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Smart Home & Connected Living',
    category: 'Smart Energy Solutions',
    subCategory: 'Solar Products',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Smart Home & Connected Living',
    category: 'Smart Energy Solutions',
    subCategory: 'Surge Protectors',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Smart Home & Connected Living',
    category: 'Smart Energy Solutions',
    subCategory: 'Uninterrupted Power Supply (UPS)',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Smart Home & Connected Living',
    category: 'Smart Home & Appliance',
    subCategory: 'No Cat',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Smart Home & Connected Living',
    category: 'Smart Home & Appliance',
    subCategory: 'Thermostats',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Smart Home & Connected Living',
    category: 'Smart Lighting',
    subCategory: 'Dimmers & Switches',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Smart Home & Connected Living',
    category: 'Smart Lighting',
    subCategory: 'LED Downlights',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Smart Home & Connected Living',
    category: 'Smart Lighting',
    subCategory: 'LED Lights',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Smart Home & Connected Living',
    category: 'Smart Lighting',
    subCategory: 'LED Spotlights',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Smart Home & Connected Living',
    category: 'Smart Lighting',
    subCategory: 'Sensors',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Smart Home & Connected Living',
    category: 'Wearable',
    subCategory: 'Fitness & Activity Smart Watches',
    successFee: 0.15,
    type: 'PRICE',
  },
  {
    department: 'Smart Home & Connected Living',
    category: 'Wearable',
    subCategory: 'Health Tracker Devices',
    successFee: 0.15,
    type: 'PRICE',
  },
  {
    department: 'Smart Home & Connected Living',
    category: 'Wearable',
    subCategory: 'No Cat',
    successFee: 0.09,
    type: 'FLAT',
  },
  {
    department: 'Smart Home & Connected Living',
    category: 'Wearable',
    subCategory: 'Security Tracker Devices',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Sport',
    category: 'Accessories',
    subCategory: 'Eyewear',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Sport',
    category: 'Accessories',
    subCategory: 'Fan Merchandise',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Sport',
    category: 'Accessories',
    subCategory: 'Game Room & Action Sports',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Sport',
    category: 'Accessories',
    subCategory: 'Individual Sports',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Sport',
    category: 'Accessories',
    subCategory: 'No Cat',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Sport',
    category: 'Accessories',
    subCategory: 'Team Sports',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Sport',
    category: 'Clothing',
    subCategory: 'Accessories',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Sport',
    category: 'Clothing',
    subCategory: 'Bottoms',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Sport',
    category: 'Clothing',
    subCategory: 'Clothing set',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Sport',
    category: 'Clothing',
    subCategory: 'Gloves',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Sport',
    category: 'Clothing',
    subCategory: 'Jackets',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Sport',
    category: 'Clothing',
    subCategory: 'No Cat',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Sport',
    category: 'Clothing',
    subCategory: 'Socks',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Sport',
    category: 'Clothing',
    subCategory: 'Swimwear',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Sport',
    category: 'Clothing',
    subCategory: 'Tops',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Sport',
    category: 'Clothing',
    subCategory: 'Underwear',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Sport',
    category: 'Consumables',
    subCategory: 'Hydration',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Sport',
    category: 'Consumables',
    subCategory: 'No Cat',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Sport',
    category: 'Equipment',
    subCategory: 'Cycling',
    successFee: 0.13,
    type: 'FLAT',
  },
  {
    department: 'Sport',
    category: 'Equipment',
    subCategory: 'Fitness',
    successFee: 0.13,
    type: 'FLAT',
  },
  {
    department: 'Sport',
    category: 'Equipment',
    subCategory: 'Game Room & Action Sports',
    successFee: 0.13,
    type: 'FLAT',
  },
  {
    department: 'Sport',
    category: 'Equipment',
    subCategory: 'Individual Sports',
    successFee: 0.13,
    type: 'FLAT',
  },
  {
    department: 'Sport',
    category: 'Equipment',
    subCategory: 'No Cat',
    successFee: 0.13,
    type: 'FLAT',
  },
  {
    department: 'Sport',
    category: 'Equipment',
    subCategory: 'Team Sports',
    successFee: 0.13,
    type: 'FLAT',
  },
  {
    department: 'Sport',
    category: 'Footwear',
    subCategory: 'No Cat',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Sport',
    category: 'No Cat',
    subCategory: 'No Cat',
    successFee: 0.15,
    type: 'FLAT',
  },
  {
    department: 'Sport',
    category: 'Sport Technology',
    subCategory: 'No Cat',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Stationery',
    category: 'Adhesives',
    subCategory: 'No Cat',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Stationery',
    category: 'Art & Craft Supplies',
    subCategory: 'Brushes',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Stationery',
    category: 'Art & Craft Supplies',
    subCategory: 'Craft materials',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Stationery',
    category: 'Art & Craft Supplies',
    subCategory: 'Drawing & Illustration',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Stationery',
    category: 'Art & Craft Supplies',
    subCategory: 'Kids Arts & Crafts',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Stationery',
    category: 'Art & Craft Supplies',
    subCategory: 'Modeling',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Stationery',
    category: 'Art & Craft Supplies',
    subCategory: 'No Cat',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Stationery',
    category: 'Art & Craft Supplies',
    subCategory: 'Paints',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Stationery',
    category: 'Christmas',
    subCategory: 'Christmas decorations',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Stationery',
    category: 'Christmas',
    subCategory: 'Christmas equipment',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Stationery',
    category: 'Christmas',
    subCategory: 'Christmas wrapping & gifting, stationery',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Stationery',
    category: 'Christmas',
    subCategory: 'No Cat',
    successFee: 0.14,
    type: 'FLAT',
  },
  {
    department: 'Stationery',
    category: 'Files, Tags, Labelling & Binders',
    subCategory: 'Filing',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Stationery',
    category: 'Files, Tags, Labelling & Binders',
    subCategory: 'Filing organisers',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Stationery',
    category: 'Files, Tags, Labelling & Binders',
    subCategory: 'Labels',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Stationery',
    category: 'Files, Tags, Labelling & Binders',
    subCategory: 'No Cat',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Stationery',
    category: 'Files, Tags, Labelling & Binders',
    subCategory: 'Wallets & Envelopes',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Stationery',
    category: 'General Stationery',
    subCategory: 'No Cat',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Stationery',
    category: 'Gifting',
    subCategory: 'No Cat',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Stationery',
    category: 'No Cat',
    subCategory: 'No Cat',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Stationery',
    category: 'Paper',
    subCategory: 'No Cat',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Stationery',
    category: 'Scrapbooking & Papercraft',
    subCategory: 'No Cat',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Stationery',
    category: 'Writing & Drawing Tools',
    subCategory: 'Fine Writing',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Stationery',
    category: 'Writing & Drawing Tools',
    subCategory: 'Markers & Highlighters',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Stationery',
    category: 'Writing & Drawing Tools',
    subCategory: 'No Cat',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Stationery',
    category: 'Writing & Drawing Tools',
    subCategory: 'Pencils',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Stationery',
    category: 'Writing & Drawing Tools',
    subCategory: 'Pens',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'Toys',
    category: 'Games & Puzzles',
    subCategory: 'Board Games',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Toys',
    category: 'Games & Puzzles',
    subCategory: 'Card Games',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Toys',
    category: 'Games & Puzzles',
    subCategory: 'No Cat',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Toys',
    category: 'Games & Puzzles',
    subCategory: 'Puzzles',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Toys',
    category: 'Indoor Play',
    subCategory: 'Action Figurines',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Toys',
    category: 'Indoor Play',
    subCategory: 'Building & Construction',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Toys',
    category: 'Indoor Play',
    subCategory: 'Collectables',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Toys',
    category: 'Indoor Play',
    subCategory: 'Dolls & Accessories',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Toys',
    category: 'Indoor Play',
    subCategory: 'Fancy Dress',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Toys',
    category: 'Indoor Play',
    subCategory: 'Hobbies',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Toys',
    category: 'Indoor Play',
    subCategory: 'Kids Decor',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Toys',
    category: 'Indoor Play',
    subCategory: 'No Cat',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Toys',
    category: 'Indoor Play',
    subCategory: 'Party supplies',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Toys',
    category: 'Indoor Play',
    subCategory: 'Play sets',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Toys',
    category: 'Indoor Play',
    subCategory: 'Toddler',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Toys',
    category: 'No Cat',
    subCategory: 'No Cat',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Toys',
    category: 'Outdoor Play',
    subCategory: 'Balls & Swings',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Toys',
    category: 'Outdoor Play',
    subCategory: 'Foam Blasters And Bullets',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Toys',
    category: 'Outdoor Play',
    subCategory: 'Hobbies',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Toys',
    category: 'Outdoor Play',
    subCategory: 'No Cat',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Toys',
    category: 'Outdoor Play',
    subCategory: 'Playsets & Playground Equipment',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Toys',
    category: 'Outdoor Play',
    subCategory: 'Pool & Water Toys',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Toys',
    category: 'Outdoor Play',
    subCategory: 'Remote Control & Play Vehicles',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Toys',
    category: 'Outdoor Play',
    subCategory: 'Ride-Ons, Scooters & Skates',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Toys',
    category: 'Smart Toys',
    subCategory: 'Early Childhood Development Toys',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Toys',
    category: 'Smart Toys',
    subCategory: 'Electronic AI Toys',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Toys',
    category: 'Smart Toys',
    subCategory: 'No Cat',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'Toys',
    category: 'Smart Toys',
    subCategory: 'STEM',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'TV & Audio',
    category: 'Accessories',
    subCategory: 'Adapters',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'TV & Audio',
    category: 'Accessories',
    subCategory: 'Brackets',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'TV & Audio',
    category: 'Accessories',
    subCategory: 'Cable & Accessories',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'TV & Audio',
    category: 'Accessories',
    subCategory: 'Cleaning',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'TV & Audio',
    category: 'Accessories',
    subCategory: 'Lamps',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'TV & Audio',
    category: 'Accessories',
    subCategory: 'No Cat',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'TV & Audio',
    category: 'Accessories',
    subCategory: 'Remotes',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'TV & Audio',
    category: 'Accessories',
    subCategory: 'Stands',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'TV & Audio',
    category: 'Audio',
    subCategory: 'Amplifiers',
    successFee: 0.07,
    type: 'FLAT',
  },
  {
    department: 'TV & Audio',
    category: 'Audio',
    subCategory: 'Headphones & Headsets',
    successFee: 0.12,
    type: 'PRICE',
  },
  {
    department: 'TV & Audio',
    category: 'Audio',
    subCategory: 'Hi-Fi related',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'TV & Audio',
    category: 'Audio',
    subCategory: 'Home Audio',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'TV & Audio',
    category: 'Audio',
    subCategory: 'Microphones',
    successFee: 0.07,
    type: 'FLAT',
  },
  {
    department: 'TV & Audio',
    category: 'Audio',
    subCategory: 'MP3 players',
    successFee: 0.07,
    type: 'FLAT',
  },
  {
    department: 'TV & Audio',
    category: 'Audio',
    subCategory: 'No Cat',
    successFee: 0.07,
    type: 'FLAT',
  },
  {
    department: 'TV & Audio',
    category: 'Audio',
    subCategory: 'Radio',
    successFee: 0.06,
    type: 'FLAT',
  },
  {
    department: 'TV & Audio',
    category: 'Audio',
    subCategory: 'Speakers',
    successFee: 0.08,
    type: 'FLAT',
  },
  {
    department: 'TV & Audio',
    category: 'No Cat',
    subCategory: 'No Cat',
    successFee: 0.12,
    type: 'FLAT',
  },
  {
    department: 'TV & Audio',
    category: 'TV',
    subCategory: 'No Cat',
    successFee: 0.1,
    type: 'PRICE',
  },
  {
    department: 'TV & Audio',
    category: 'Video',
    subCategory: 'Blu-ray Disc Players',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'TV & Audio',
    category: 'Video',
    subCategory: 'Decoders',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'TV & Audio',
    category: 'Video',
    subCategory: 'DVD Players',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'TV & Audio',
    category: 'Video',
    subCategory: 'Home Theatre Systems',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'TV & Audio',
    category: 'Video',
    subCategory: 'Projectors',
    successFee: 0.1,
    type: 'FLAT',
  },
  {
    department: 'TV & Audio',
    category: 'Video',
    subCategory: 'Streaming Media Players',
    successFee: 0.09,
    type: 'FLAT',
  },
];

export default categories;
