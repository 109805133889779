/* eslint-disable react/jsx-wrap-multilines */
import { makeStyles } from '@material-ui/core/styles';
import numeral from 'numeral';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import CardHeader from '@material-ui/core/CardHeader';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility';

const useStyles = makeStyles((theme) => ({
  avatarActive: {
    backgroundColor: theme.palette.common.white,
  },
  avatarInactive: {
    backgroundColor: theme.palette.secondary.light,
  },
  actionBar: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  formControl: {
    flexGrow: 1,
  },
  media: {
    backgroundSize: 'contain',
    height: 180,
    margin: theme.spacing(2),
  },
  separator: {
    width: theme.spacing(1),
  },
}));

const ImagePreviewCard = ({
  tag,
  fileSize,
  imageLink,
  deleteImage,
  isLoading,
  showLinkCopyFeedback,
  adjustImageOrder,
  isEditingListingOrder,
  listingOrder,
}) => {
  const classes = useStyles();

  const getPrettyTag = () => {
    switch (tag) {
      case 'STOCK':
        return 'Stock';
      case 'RAW':
        return 'RAW';
      case 'PRODUCT':
        return 'Product';
      case 'INFOGRAPHIC':
        return 'Infographic';
      case 'LIFESTYLE':
        return 'Lifestyle';
      case 'OTHER':
        return 'Other';
      default:
        return 'Unknown';
    }
  };

  const getFormattedFileSize = () => {
    return numeral(fileSize / 1048576).format('0,0.00');
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar
            aria-label="listing position"
            className={
              listingOrder ? classes.avatarActive : classes.avatarInactive
            }
          >
            {listingOrder || '-'}
          </Avatar>
        }
        title={getPrettyTag(tag)}
        subheader={`${getFormattedFileSize(fileSize)} mb`}
      />
      <CardMedia className={classes.media} image={imageLink} />
      {isEditingListingOrder ? (
        <CardActions className={classes.actionBar}>
          <TextField
            value={listingOrder}
            onChange={(e) => {
              if (e.target.value === '') {
                adjustImageOrder(null);
              } else {
                adjustImageOrder(parseInt(e.target.value, 10));
              }
            }}
            placeholder="Order"
            type="number"
          />
        </CardActions>
      ) : (
        <CardActions className={classes.actionBar}>
          <Tooltip title="View image">
            <IconButton
              onClick={() => window.open(imageLink, '_blank')}
              size="small"
              disabled={isLoading}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Copy link">
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(imageLink);
                showLinkCopyFeedback();
              }}
              size="small"
              disabled={isLoading}
            >
              <FileCopyIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete image">
            <IconButton onClick={deleteImage} size="small" disabled={isLoading}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </CardActions>
      )}
    </Card>
  );
};

ImagePreviewCard.defaultProps = {
  tag: 'STOCK',
  imageLink: '',
  fileSize: 12000000,
  deleteImage: () => console.log('Image was removed.'),
  isLoading: false,
  showLinkCopyFeedback: () => console.log('Image was copied to the clipboard.'),
  adjustImageOrder: (newOrder) =>
    console.log(`Image order was changed to ${newOrder}`),
  isEditingListingOrder: true,
  listingOrder: 1,
};

ImagePreviewCard.propTypes = {
  tag: PropTypes.oneOf([
    'STOCK',
    'RAW',
    'PRODUCT',
    'INFOGRAPHIC',
    'LIFESTYLE',
    'OTHER',
  ]),
  fileSize: PropTypes.number,
  imageLink: PropTypes.string,
  deleteImage: PropTypes.func,
  isLoading: PropTypes.bool,
  showLinkCopyFeedback: PropTypes.func,
  adjustImageOrder: PropTypes.func,
  isEditingListingOrder: PropTypes.bool,
  listingOrder: PropTypes.number,
};

export default ImagePreviewCard;
