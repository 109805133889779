import { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import { fetchAnalyticsData, fetchSkuInfo } from './slice';
import {
  changeDateRange,
  getFormattedListing,
  getLineGraphLabels,
  getLineGraphPageViews,
  getLineGraphRevenue,
  getLineGraphUnitsSold,
  getMetricTotals,
  getProductName,
} from './logic';
import ContentWrapper from '../../../components/ContentWrapper';
import EmptyViewMessage from '../../../components/EmptyViewMessage';
import FiltersBar from './components/FiltersBar';
import PageHeader from '../../../components/PageHeader';
import MetricsLineGraph from './components/MetricsLineGraph';
import TotalsBar from '../../../components/TotalsBar';

const breadcrumbs = [
  {
    label: 'Listings',
    link: '/listings',
  },
  {
    label: 'Analytics',
    link: '/listings/analytics',
  },
  {
    label: 'Dashboard',
    link: '/listings/analytics/dashboard',
  },
];

const ListingsAnalyticsSku = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { sku } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [platformFilter, setPlatformFilter] = useState('TAKEALOT');
  const [rangeFilter, setRangeFilter] = useState('PAST_7_DAYS');
  const [startDate, setStartDate] = useState(
    moment().subtract(8, 'days').startOf('day').toDate()
  );
  const [endDate, setEndDate] = useState(
    moment().subtract(1, 'days').endOf('day').toDate()
  );
  const [formattedSku, setFormattedSku] = useState({
    dailyData: [],
  });

  const skuInfo = useSelector((state) => state.listingsAnalyticsSku.skuInfo);
  const analyticsData = useSelector(
    (state) => state.listingsAnalyticsSku.analyticsData
  );
  const skuInfoLoadingStatus = useSelector(
    (state) => state.listingsAnalyticsSku.loadingStatus.skuInfo
  );
  const analyticsDataLoadingStatus = useSelector(
    (state) => state.listingsAnalyticsSku.loadingStatus.analyticsData
  );

  useEffect(() => {
    dispatch(fetchSkuInfo(sku));
  }, []);

  useEffect(() => {
    dispatch(fetchAnalyticsData(platformFilter, startDate, endDate));
  }, [platformFilter, startDate, endDate]);

  useEffect(() => {
    setFormattedSku(
      getFormattedListing(skuInfo, analyticsData, platformFilter)
    );
  }, [skuInfo, analyticsData, platformFilter]);

  useEffect(() => {
    if (
      skuInfoLoadingStatus === 'PENDING' ||
      analyticsDataLoadingStatus === 'PENDING'
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [skuInfoLoadingStatus, analyticsDataLoadingStatus]);

  useEffect(() => {
    changeDateRange(rangeFilter, setStartDate, setEndDate);
  }, [rangeFilter]);

  console.log(formattedSku);
  return (
    <div>
      <PageHeader
        title="SKU Analytics"
        subtitle={
          skuInfoLoadingStatus === 'COMPLETE'
            ? getProductName(skuInfo)
            : 'Loading...'
        }
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="SKU"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
      />
      {isLoading && <LinearProgress color="secondary" />}
      <ContentWrapper>
        {!isLoading && skuInfo.sku === 'LOADING' ? (
          <Grid container spaacing={3}>
            <Grid item xs={12}>
              <Paper>
                <EmptyViewMessage
                  heading="No Internet Connection"
                  message="Please check your internet connection and reload."
                />
              </Paper>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FiltersBar
                startDate={startDate}
                changeStartDate={(newDate) =>
                  setStartDate(moment(newDate).startOf('day').toDate())
                }
                endDate={endDate}
                changeEndDate={(newDate) =>
                  setEndDate(moment(newDate).endOf('day').toDate())
                }
                rangeFilter={rangeFilter}
                changeRangeFilter={setRangeFilter}
                platformFilter={platformFilter}
                changePlatformFilter={(newPlatform) =>
                  setPlatformFilter(newPlatform)
                }
              />
            </Grid>
            {!isLoading && (
              <Grid item xs={12}>
                <TotalsBar totals={getMetricTotals(formattedSku)} />
              </Grid>
            )}
            <Grid item xs={12} md={12}>
              <MetricsLineGraph
                isLoading={isLoading}
                labels={getLineGraphLabels(startDate, endDate)}
                pageViews={getLineGraphPageViews(formattedSku)}
                revenue={getLineGraphRevenue(formattedSku)}
                unitsSold={getLineGraphUnitsSold(formattedSku)}
              />
            </Grid>
          </Grid>
        )}
      </ContentWrapper>
    </div>
  );
};

export default ListingsAnalyticsSku;
