import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '@material-ui/core/Link';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import ActionBar from '../ActionBar';

const useStyles = makeStyles((theme) => ({
  backIcon: {
    marginRight: theme.spacing(1),
  },
  currentPageBreadcrumb: {
    color: theme.palette.primary.light,
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(4),
  },
  headingSubtitle: {
    marginTop: theme.spacing(1),
    color: grey[600],
  },
  headingTitle: {
    color: theme.palette.primary.light,
  },
  mainTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapperButtons: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const PageHeader = ({
  isLoading,
  title,
  subtitle,
  currentPageBreadcrumb,
  breadcrumbs,
  goToLink,
  goBack,
  actions,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = (e, link) => {
    e.preventDefault();
    goToLink(link);
  };

  return (
    <div>
      <div className={classes.header}>
        <div className={classes.mainTitleWrapper}>
          <div className={classes.breadcrumbs}>
            <Breadcrumbs aria-label="breadcrumb">
              {breadcrumbs.map((breadcrumb) => (
                <Link
                  key={breadcrumb.label}
                  color="inherit"
                  href="/"
                  onClick={(e) => handleClick(e, breadcrumb.link)}
                >
                  {breadcrumb.label}
                </Link>
              ))}
              <Typography className={classes.currentPageBreadcrumb}>
                {currentPageBreadcrumb}
              </Typography>
            </Breadcrumbs>
            <Typography
              className={classes.headingTitle}
              component="h2"
              variant="h3"
            >
              {title}
            </Typography>
            {subtitle.length > 0 && (
              <Typography
                className={classes.headingSubtitle}
                component="h3"
                variant="h5"
              >
                {subtitle}
              </Typography>
            )}
          </div>
        </div>
        <div className={classes.wrapperButtons}>
          {history.length > 0 && (
            <Button variant="outlined" color="secondary" onClick={goBack}>
              <ArrowBackIosIcon className={classes.backIcon} />
              Back
            </Button>
          )}
          <ActionBar actions={actions} />
        </div>
      </div>
      {isLoading && <LinearProgress />}
    </div>
  );
};

PageHeader.defaultProps = {
  isLoading: false,
  title: 'Title of Page',
  subtitle: '',
  currentPageBreadcrumb: 'Title',
  breadcrumbs: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Section',
      link: '/section',
    },
  ],
  goToLink: (link) => console.log('User rerouted to:', link),
  goBack: () => console.log('User navigated to previous page.'),
  actions: [],
};

PageHeader.propTypes = {
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  currentPageBreadcrumb: PropTypes.string,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      link: PropTypes.string,
    })
  ),
  goToLink: PropTypes.func,
  goBack: PropTypes.func,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.node,
      onClick: PropTypes.func,
      type: PropTypes.string,
      config: PropTypes.shape(),
    })
  ),
};

export default PageHeader;
