import { CSVLink } from 'react-csv';
import { CSVReader } from 'react-papaparse';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useRef, useState } from 'react';
import numeral from 'numeral';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import GetAppIcon from '@material-ui/icons/GetApp';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';
import SkuConfirmationTable from './components/SkuConfirmationTable';

const useStyles = makeStyles((theme) => ({
  alert: {
    marginTop: theme.spacing(3),
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    padding: theme.spacing(4),
  },
  explanationWrapper: {
    color: grey[700],
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  loadingIndicator: {
    marginBottom: theme.spacing(2),
  },
  hidden: {
    display: 'none',
  },
}));

const getExpectedFields = () => {
  return ['SKU', 'TSIN', 'EasyOnline', 'Amazon US'];
};

const getSteps = () => {
  return ['Upload changes file', 'Confirm changes', 'Updates'];
};

const getStepExplanation = (stepIndex) => {
  switch (stepIndex) {
    case 0:
      return 'Download the template file and add stock quantities for specific SKUs / TSINs.';
    case 1:
      return 'Please confirm the new quantities.';
    case 2:
      return '';
    default:
      return 'Unknown stepIndex';
  }
};

const getLoadingValue = (loadingCount) => {
  if (loadingCount.total === 0) {
    return -1;
  }
  return Math.round(
    ((loadingCount.success + loadingCount.failed) / loadingCount.total) * 100
  );
};

const BulkUpdateDialog = ({
  isOpen,
  bulkStockUpdate,
  bulkStockUploadLoadingCount,
  setTotalSkusToUpdate,
  failedBulkSkus,
  closeDialog,
}) => {
  const classes = useStyles();
  const steps = getSteps();

  const fileUploadRef = useRef();
  const csvLink = useRef();

  const [activeStep, setActiveStep] = useState(0);
  const [uploadData, setUploadData] = useState([]);
  const [uploadError, setUploadError] = useState({
    isError: false,
    message: '',
  });
  const [isNextEnabled, setIsNextEnabled] = useState(false);
  const skuLoadingValue = getLoadingValue(bulkStockUploadLoadingCount);

  useEffect(() => {
    if (!isOpen) {
      setActiveStep(0);
      setIsNextEnabled(false);
      setUploadError({
        isError: false,
        message: '',
      });
    }
  }, [isOpen]);

  useEffect(() => {
    if (fileUploadRef.current) {
      fileUploadRef.current.removeFile();
    }
  }, [activeStep]);

  const checkFields = (fields, expectedFields) => {
    let isCorrect = true;
    fields.forEach((field, index) => {
      if (field !== expectedFields[index]) {
        isCorrect = false;
      }
    });
    return isCorrect;
  };

  const formatStockUpdates = (data) => {
    const fields = data[0].data;
    const isFieldsCorrect = checkFields(fields, getExpectedFields());

    if (isFieldsCorrect) {
      setUploadError({
        isError: false,
        message: '',
      });

      const updates = data
        .map((row, index) => {
          if (index < 1) {
            return false;
          }

          const values = row.data;
          if (values.length !== fields.length) {
            return false;
          }

          if (values[0] === '' && values[1] === '') {
            return false;
          }

          return {
            sku: values[0],
            tsin: values[1].length === 0 ? null : numeral(values[1]).value(),
            easyOnline:
              values[2].length === 0 ? null : numeral(values[2]).value(),
            amazonUs:
              values[3].length === 0 ? null : numeral(values[3]).value(),
          };
        })
        .filter((rowInfo) => rowInfo !== false)
        .filter((rowInfo) => rowInfo.sku !== '' || rowInfo.tsin !== null)
        .filter(
          (rowInfo) => rowInfo.easyOnline !== null || rowInfo.amazonUs !== null
        )
        .sort((rowA, rowB) => {
          if (rowA < rowB) return -1;
          if (rowA > rowB) return +1;
          return 0;
        });

      setUploadData(updates);
      setIsNextEnabled(true);
    } else {
      setUploadError({
        isError: true,
        message:
          'Incorrect report uploaded. Please download the template file and edit that file.',
      });
    }
  };

  const handleFileLoad = (data) => {
    formatStockUpdates(data);
  };

  const handleError = (err) => {
    console.log(err);
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <Grid container justify="space-between" spacing={2}>
            <Grid item xs={12}>
              <Button
                startIcon={<GetAppIcon />}
                color="secondary"
                variant="outlined"
                fullWidth
                onClick={() => csvLink.current.link.click()}
              >
                Download template
              </Button>
              <CSVLink
                className={classes.hidden}
                ref={csvLink}
                filename="bulk-stock-update.csv"
                data={[['SKU', 'TSIN', 'EasyOnline', 'Amazon US']]}
              >
                Download template
              </CSVLink>
            </Grid>
            <Grid item xs={12}>
              <CSVReader
                ref={fileUploadRef}
                onDrop={handleFileLoad}
                onError={handleError}
                addRemoveButton
              >
                <span>Drop completed template here or click to upload.</span>
              </CSVReader>
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Grid container justify="space-around">
            <SkuConfirmationTable skus={uploadData} />
          </Grid>
        );
      case 2:
        return (
          <div>
            {skuLoadingValue < 100 && skuLoadingValue !== -1 && (
              <div>
                <LinearProgress
                  color="secondary"
                  className={classes.loadingIndicator}
                  variant="determinate"
                  value={skuLoadingValue}
                />
                <Typography component="p" variant="subtitle1" align="center">
                  Updating stock quantities...
                </Typography>
              </div>
            )}
            {skuLoadingValue >= 100 && failedBulkSkus.length === 0 && (
              <div>
                <Alert className={classes.alert} severity="success">
                  All stock quantities have been updated.
                </Alert>
              </div>
            )}
            {skuLoadingValue >= 100 && failedBulkSkus.length > 0 && (
              <div>
                <Alert className={classes.alert} severity="warning">
                  {`Stock values were updated for some SKUs, but ${failedBulkSkus.length} SKU/s failed to update. The failed SKUs/TSINs are:`}
                </Alert>
                <List>
                  {failedBulkSkus.map((failedInfo) => (
                    <ListItem key={failedInfo.code}>
                      <ListItemText
                        primary={failedInfo.code}
                        secondary={failedInfo.type}
                      />
                    </ListItem>
                  ))}
                </List>
              </div>
            )}
          </div>
        );
      default:
        return 'Unknown stepIndex';
    }
  };

  const handleNext = () => {
    if (activeStep === 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      const skusToUpdate = uploadData.filter(
        (info) => info.sku.length > 0 || info.tsin > 0
      );
      setTotalSkusToUpdate(skusToUpdate.length);
      skusToUpdate.forEach((info) => {
        if (info.sku.length > 0) {
          bulkStockUpdate(info.sku, info.easyOnline, info.amazonUs, 'SKU');
        } else if (info.tsin > 0) {
          bulkStockUpdate(info.tsin, info.easyOnline, info.amazonUs, 'TSIN');
        }
      });
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  return (
    <Dialog open={isOpen} maxWidth="md" fullWidth>
      <DialogTitle>Bulk Update</DialogTitle>
      <DialogContent className={classes.contentWrapper}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <DialogContentText className={classes.explanationWrapper}>
          {getStepExplanation(activeStep)}
        </DialogContentText>
        {getStepContent(activeStep)}
        {uploadError.isError && (
          <Alert className={classes.alert} severity="error">
            {uploadError.message}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={activeStep === steps.length - 1}
          onClick={closeDialog}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          disabled={activeStep !== 1}
          onClick={() => setActiveStep(activeStep - 1)}
          variant="contained"
          color="secondary"
        >
          Back
        </Button>
        <Button
          disabled={uploadError.isError || !isNextEnabled}
          onClick={activeStep === steps.length - 1 ? closeDialog : handleNext}
          variant="contained"
          color="secondary"
        >
          {activeStep === steps.length - 1 ? 'Done' : 'Next'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

BulkUpdateDialog.defaultProps = {
  isOpen: true,
  bulkStockUpdate: (code, easyOnlineQty, amazonUsQty, type) =>
    console.log(
      `Product with code ${code} and type ${type} was updated with EasyOnline quantity ${easyOnlineQty} and Amazon US quantity ${amazonUsQty}.`
    ),
  bulkStockUploadLoadingCount: {
    total: 0,
    success: 0,
    failed: 0,
  },
  setTotalSkusToUpdate: (total) =>
    console.log(`Total SKUs to be updated are ${total}.`),
  failedBulkSkus: [],
  closeDialog: () => console.log('Bulk update report dialog was closed.'),
};

BulkUpdateDialog.propTypes = {
  isOpen: PropTypes.bool,
  bulkStockUpdate: PropTypes.func,
  bulkStockUploadLoadingCount: PropTypes.shape({
    total: PropTypes.number,
    success: PropTypes.number,
    failed: PropTypes.number,
  }),
  setTotalSkusToUpdate: PropTypes.func,
  failedBulkSkus: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  closeDialog: PropTypes.func,
};

export default BulkUpdateDialog;
