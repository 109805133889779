/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getAnalyticsData, getSkus } from '../../../api/listingsAPI';

const initialState = {
  skusList: [],
  analyticsData: [],
  loadingStatus: {
    skus: 'NOT_STARTED',
    analyticsData: 'NOT_STARTED',
  },
  pageSize: 5,
  initialPage: 0,
};

const listingsAnalyticsSlice = createSlice({
  name: 'listingsAnalytics',
  initialState,
  reducers: {
    getListingsStarted(state) {
      state.loadingStatus.skus = 'PENDING';
    },
    getListingsSuccess(state, action) {
      state.skusList = action.payload;
      state.loadingStatus.skus = 'COMPLETE';
    },
    getListingsFailed(state, action) {
      state.loadingStatus.skus = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getAnalyticsDataStarted(state) {
      state.loadingStatus.analyticsData = 'PENDING';
    },
    getAnalyticsDataSuccess(state, action) {
      state.analyticsData = action.payload;
      state.loadingStatus.analyticsData = 'COMPLETE';
    },
    getAnalyticsDataFailed(state, action) {
      state.loadingStatus.analyticsData = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    changePageSize(state, action) {
      state.pageSize = action.payload;
    },
    changeInitialPage(state, action) {
      state.initialPage = action.payload;
    },
  },
});

export const {
  getListingsStarted,
  getListingsSuccess,
  getListingsFailed,
  getAnalyticsDataStarted,
  getAnalyticsDataSuccess,
  getAnalyticsDataFailed,
  changePageSize,
  changeInitialPage,
} = listingsAnalyticsSlice.actions;

export const fetchSkus = (platform) => async (dispatch) => {
  dispatch(getListingsStarted());
  const result = await getSkus(platform);

  if (result.status === 200) {
    dispatch(getListingsSuccess(result.skus));
  } else {
    dispatch(getListingsFailed(result.error));
  }
};

export const fetchAnalyticsData =
  (platform, startDate, endDate) => async (dispatch) => {
    dispatch(getAnalyticsDataStarted());
    const result = await getAnalyticsData(platform, startDate, endDate);

    if (result.status === 200) {
      dispatch(getAnalyticsDataSuccess(result.reportData));
    } else {
      dispatch(getAnalyticsDataFailed(result.error));
    }
  };

export const updatePageSize = (newPageSize) => (dispatch) => {
  dispatch(changePageSize(newPageSize));
};

export const updateInitialPage = (newInitialPage) => (dispatch) => {
  dispatch(changeInitialPage(newInitialPage));
};

export default listingsAnalyticsSlice.reducer;
