/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getSourcedProduct, updateSourcedProduct } from '../../../api/salesAPI';
import { getSuppliers } from '../../../api/suppliersAPI';

const initialState = {
  suppliers: [],
  isUserRedirectedToBrowse: false,
  productInfo: {},
  loadingStatus: {
    update: 'NOT_STARTED',
    suppliers: 'NOT_STARTED',
    productInfo: 'NOT_STARTED',
  },
  feedback: {
    isOpen: false,
    type: undefined,
    message: '',
  },
};

const sourcedProductsEditSlice = createSlice({
  name: 'sourcedProductsEdit',
  initialState,
  reducers: {
    updateProductStarted(state) {
      state.loadingStatus.update = 'PENDING';
    },
    updateProductSuccess(state) {
      state.isUserRedirectedToBrowse = true;
      state.loadingStatus.update = 'COMPLETE';
    },
    updateProductFailed(state, action) {
      state.loadingStatus.update = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getProductStarted(state) {
      state.loadingStatus.productInfo = 'PENDING';
    },
    getProductSuccess(state, action) {
      state.loadingStatus.productInfo = 'COMPLETE';
      state.productInfo = action.payload;
    },
    getProductFailed(state, action) {
      state.loadingStatus.productInfo = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getSuppliersStarted(state) {
      state.loadingStatus.suppliers = 'PENDING';
    },
    getSuppliersSuccess(state, action) {
      state.suppliers = action.payload;
      state.loadingStatus.suppliers = 'COMPLETE';
    },
    getSuppliersFailed(state, action) {
      state.loadingStatus.suppliers = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    resetState() {
      return initialState;
    },
  },
});

export const {
  updateProductStarted,
  updateProductSuccess,
  updateProductFailed,
  getProductStarted,
  getProductSuccess,
  getProductFailed,
  getSuppliersStarted,
  getSuppliersSuccess,
  getSuppliersFailed,
  resetState,
} = sourcedProductsEditSlice.actions;

export default sourcedProductsEditSlice.reducer;

export const changeProduct = (id, newInfo) => async (dispatch) => {
  dispatch(updateProductStarted());
  const result = await updateSourcedProduct(id, newInfo);

  if (result.status === 200) {
    dispatch(updateProductSuccess({ id, newInfo }));
  } else {
    dispatch(updateProductFailed(result.error));
  }
};

export const fetchProductInfo = (id) => async (dispatch) => {
  dispatch(getProductStarted());
  const result = await getSourcedProduct(id);

  if (result.status === 200) {
    dispatch(getProductSuccess(result.productInfo));
  } else {
    dispatch(getProductFailed(result.error));
  }
};

export const fetchSuppliers = () => async (dispatch) => {
  dispatch(getSuppliersStarted());
  const result = await getSuppliers();

  if (result.status === 200) {
    dispatch(getSuppliersSuccess(result.suppliers));
  } else {
    dispatch(getSuppliersFailed(result.error));
  }
};

export const initiateStateReset = () => async (dispatch) => {
  dispatch(resetState());
};
