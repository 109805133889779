/* eslint-disable react/jsx-wrap-multilines */
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import AssessmentIcon from '@material-ui/icons/Assessment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  activeSection: {
    color: theme.palette.secondary.main,
  },
  inactiveSection: {
    color: grey[700],
  },
  listItem: {
    paddingLeft: theme.spacing(3),
  },
}));

const SystemMaintenanceNav = ({ isOpen, activeSectionLink, goToLink }) => {
  const classes = useStyles();

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader disableSticky component="div" id="nested-list-subheader">
          {isOpen ? 'System Maintenance' : ''}
        </ListSubheader>
      }
      className={classes.root}
    >
      <ListItem
        className={classes.listItem}
        button
        onClick={() => goToLink('/system-maintenance/reports')}
      >
        <ListItemIcon
          className={
            activeSectionLink.includes('/system-maintenance/reports')
              ? classes.activeSection
              : classes.inactiveSection
          }
        >
          <AssessmentIcon />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            className: activeSectionLink.includes('/system-maintenance/reports')
              ? classes.activeSection
              : classes.inactiveSection,
          }}
          primary="Reports"
        />
      </ListItem>
    </List>
  );
};

SystemMaintenanceNav.defaultProps = {
  activeSectionLink: '/system-maintenance/reports',
  goToLink: (link) => console.log('User navigated to:', link),
  isOpen: true,
};

SystemMaintenanceNav.propTypes = {
  activeSectionLink: PropTypes.string,
  goToLink: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default SystemMaintenanceNav;
