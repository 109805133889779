/* eslint-disable react/prop-types */
import { fade } from '@material-ui/core/styles/colorManipulator';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';

const headerStyle = {
  backgroundColor: '#A7A5A5',
  color: '#171415',
};

const useStyles = makeStyles((theme) => ({
  loadingOverlay: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: fade(theme.palette.common.black, 0.8),
  },
  wrapperContent: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

const SkuConfirmationTable = ({
  skus,
  confirmedSkus,
  updateConfirmedQuantities,
}) => {
  const classes = useStyles();
  const [receivedUnits, setReceivedUnits] = useState({});

  useEffect(() => {
    setReceivedUnits(
      skus.reduce((total, current) => {
        return {
          ...total,
          [current.sku]: current.units,
        };
      }, {})
    );
  }, [skus]);

  useEffect(() => {
    updateConfirmedQuantities(
      confirmedSkus.map((info) => ({
        ...info,
        receivedUnits: receivedUnits[info.sku],
      }))
    );
  }, [receivedUnits]);

  const columns = [
    {
      title: 'SKU',
      field: 'sku',
      editable: 'never',
    },
    {
      title: 'TSIN',
      field: 'tsin',
      editable: 'never',
      render: (rowData) => (rowData.tsin === '' ? '-' : rowData.tsin),
    },
    {
      title: 'ASIN',
      field: 'childAsin',
      editable: 'never',
      render: (rowData) => (rowData.childAsin === '' ? '-' : rowData.childAsin),
    },
    {
      title: 'Product',
      field: 'product',
      editable: 'never',
    },
    {
      title: 'Shipped Units',
      field: 'expectedUnits',
      type: 'numeric',
      editable: 'never',
    },
    {
      title: 'Received Units',
      field: 'receivedUnits',
      type: 'numeric',
    },
  ];

  const checkIfSelected = (sku) => {
    const relevantSku = confirmedSkus.find(
      (selectedSkuInfo) => selectedSkuInfo.sku === sku
    );

    if (relevantSku) {
      return true;
    }
    return false;
  };

  const getTableData = () => {
    return skus.map((info, index) => ({
      sku: info.sku,
      tsin: info.tsin,
      childAsin: info.childAsin,
      product: info.product,
      expectedUnits: info.units,
      receivedUnits: receivedUnits[info.sku],
      tableData: {
        id: index,
        checked: checkIfSelected(info.sku),
      },
    }));
  };

  return (
    <div className={classes.wrapperContent}>
      <MaterialTable
        columns={columns}
        data={getTableData()}
        options={{
          headerStyle,
          actionsColumnIndex: -1,
          sorting: false,
          grouping: false,
          draggable: false,
          search: false,
          showTitle: false,
          toolbar: false,
          selection: true,
        }}
        components={{
          OverlayLoading: () => (
            <div className={classes.loadingOverlay}>
              <CircularProgress color="primary" size={32} />
            </div>
          ),
        }}
        editable={{
          onRowUpdate: (newData) =>
            new Promise((resolve) => {
              setReceivedUnits({
                ...receivedUnits,
                [newData.sku]: newData.receivedUnits,
              });
              resolve();
            }),
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'No skus in shipment.',
          },
        }}
        onSelectionChange={(rows) => updateConfirmedQuantities(rows)}
      />
    </div>
  );
};

SkuConfirmationTable.defaultProps = {
  skus: [],
  confirmedSkus: [],
  updateConfirmedQuantities: (rows) =>
    console.log('Users selected the following rows:', rows),
};

SkuConfirmationTable.propTypes = {
  skus: PropTypes.arrayOf(
    PropTypes.shape({
      childAsin: PropTypes.string,
      sku: PropTypes.string,
      tsin: PropTypes.number,
      units: PropTypes.number,
      product: PropTypes.string,
      costPrice: PropTypes.number,
    })
  ),
  confirmedSkus: PropTypes.arrayOf(
    PropTypes.shape({
      childAsin: PropTypes.string,
      sku: PropTypes.string,
      tsin: PropTypes.number,
      units: PropTypes.number,
      product: PropTypes.string,
      costPrice: PropTypes.number,
      tableData: {
        id: PropTypes.number,
        checked: PropTypes.bool,
      },
    })
  ),
  updateConfirmedQuantities: PropTypes.func,
};

export default SkuConfirmationTable;
