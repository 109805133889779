import { fade } from '@material-ui/core/styles/colorManipulator';

const getStyles = (theme) => ({
  icon: {
    color: theme.palette.common.black,
  },
  link: {
    color: theme.palette.common.black,
  },
  loadingOverlay: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: fade(theme.palette.common.black, 0.8),
  },
  wrapperContent: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
});

export default getStyles;
