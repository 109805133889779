/* eslint-disable react/jsx-props-no-spreading */
import { fade } from '@material-ui/core/styles/colorManipulator';
import { green, red, yellow } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import MaterialTable from 'material-table';
import Link from '@material-ui/core/Link';
import PropTypes from 'prop-types';

const headerStyle = {
  backgroundColor: '#171415',
};

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.common.black,
  },
  link: {
    color: theme.palette.common.white,
  },
  loadingOverlay: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: fade(theme.palette.common.black, 0.8),
  },
  statusActive: {
    color: theme.palette.common.black,
    backgroundColor: green[500],
  },
  statusInactive: {
    color: theme.palette.common.black,
    backgroundColor: red[500],
  },
  statusOnboarding: {
    color: theme.palette.common.black,
    backgroundColor: yellow[500],
  },
  wrapperContent: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

const SkusTable = ({
  isLoading,
  skus,
  viewSku,
  changeBasicInfo,
  platform,
  pageSize,
  initialPage,
  updatePageSize,
  updateInitialPage,
}) => {
  const classes = useStyles();

  const getPrettyStatus = (status) => {
    switch (status) {
      case 'ONBOARDING':
        return (
          <Chip
            icon={<AutorenewIcon className={classes.icon} />}
            label="Onboarding"
            className={classes.statusOnboarding}
          />
        );
      case 'BUYABLE':
        return (
          <Chip
            icon={<DoneIcon className={classes.icon} />}
            label="Buyable"
            className={classes.statusActive}
          />
        );
      case 'NOT_BUYABLE':
        return (
          <Chip
            icon={<ErrorOutlineIcon className={classes.icon} />}
            label="Not Buyable"
            className={classes.statusOnboarding}
          />
        );
      case 'ACTIVE':
        return (
          <Chip
            icon={<DoneIcon className={classes.icon} />}
            label="Active"
            className={classes.statusActive}
          />
        );
      case 'DISABLED_BY_SELLER':
        return (
          <Chip
            icon={<ClearIcon className={classes.icon} />}
            label="Disabled by Seller"
            className={classes.statusInactive}
          />
        );
      case 'DISABLED_BY_TAKEALOT':
        return (
          <Chip
            icon={<ClearIcon className={classes.icon} />}
            label="Disabled by Takealot"
            className={classes.statusInactive}
          />
        );
      case 'INACTIVE':
        return (
          <Chip
            icon={<ClearIcon className={classes.icon} />}
            label="Inactive"
            className={classes.statusInactive}
          />
        );
      default:
        return (
          <Chip
            icon={<DoneIcon className={classes.icon} />}
            label="Active"
            className={classes.statusActive}
          />
        );
    }
  };

  const getColumns = () => {
    const columns = [
      { title: 'SKU', field: 'sku', editable: false },
      {
        title: platform === 'AMAZON' ? 'ASIN' : 'TSIN',
        field: platform === 'AMAZON' ? 'asin' : 'tsin',
      },
      { title: 'Client', field: 'clientName', editable: false },
      {
        title: 'Supplier',
        field: 'supplierName',
        editable: false,
        render: (rowData) =>
          rowData.supplierName ? rowData.supplierName : rowData.clientName,
      },
      { title: 'Brand', field: 'brand' },
      { title: 'Product Name', field: 'productName' },
      { title: 'Variation', field: 'variation' },
      {
        title: 'Offer URL',
        field: 'offerUrl',
        editable: false,
        render: (rowData) =>
          rowData.offerUrl ? (
            <Button
              variant="contained"
              color="primary"
              component={Link}
              href={rowData.offerUrl}
              target="_blank"
              rel="noreferrer"
              className={classes.link}
            >
              View
            </Button>
          ) : (
            '-'
          ),
      },
      {
        title: 'Status',
        field: 'status',
        editable: false,
        render: (rowData) => getPrettyStatus(rowData.status),
      },
      {
        title: 'Creation Date',
        field: 'creationDate',
        hidden: true,
        export: true,
      },
      {
        title: 'Barcode',
        field: 'barcode',
        hidden: true,
        export: true,
      },
      {
        title: 'Primary Image URL',
        field: 'imageLink',
        hidden: true,
        export: true,
      },
    ];

    return columns;
  };

  return (
    <div className={classes.wrapperContent}>
      <MaterialTable
        title=""
        columns={getColumns()}
        data={skus}
        isLoading={isLoading}
        onChangeRowsPerPage={updatePageSize}
        onChangePage={updateInitialPage}
        options={{
          pageSize,
          initialPage,
          headerStyle,
          actionsColumnIndex: -1,
          search: false,
          grouping: false,
          draggable: false,
          exportButton: true,
          exportAllData: true,
          exportFileName: 'Listings',
        }}
        editable={{
          onRowUpdate: (newData) =>
            new Promise((resolve) => {
              changeBasicInfo(
                platform === 'AMAZON' ? newData.asin : newData.tsin,
                newData.brand,
                newData.productName,
                newData.variation === '' ? 'N/A' : newData.variation,
                newData.stockImagesLink || '',
                newData.status,
                newData.sku
              );
              resolve();
            }),
        }}
        actions={[
          {
            icon: 'keyboard_arrow_right',
            tooltip: 'View SKU info',
            onClick: (event, rowData) => viewSku(rowData.sku),
          },
        ]}
        components={{
          OverlayLoading: () => (
            <div className={classes.loadingOverlay}>
              <CircularProgress color="primary" size={32} />
            </div>
          ),
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'No listings to display',
          },
        }}
      />
    </div>
  );
};

SkusTable.defaultProps = {
  isLoading: false,
  skus: [],
  viewSku: (sku) => console.log(`SKU viewed: ${sku}`),
  changeBasicInfo: (sku) => console.log(`SKU edited: ${sku}`),
  platform: 'AMAZON',
  pageSize: 5,
  initialPage: 0,
  updatePageSize: (newPageSize) =>
    console.log(`Page size updated to: ${newPageSize}`),
  updateInitialPage: (newInitialPage) =>
    console.log(`Initial page updated to: ${newInitialPage}`),
};

SkusTable.propTypes = {
  isLoading: PropTypes.bool,
  skus: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      brand: PropTypes.string,
      clientId: PropTypes.string,
      clientName: PropTypes.string,
      productName: PropTypes.string,
      variation: PropTypes.string,
      stockImagesLink: PropTypes.string,
      status: PropTypes.oneOf(['ACTIVE', 'INACTIVE']),
    })
  ),
  viewSku: PropTypes.func,
  changeBasicInfo: PropTypes.func,
  platform: PropTypes.string,
  pageSize: PropTypes.number,
  initialPage: PropTypes.number,
  updatePageSize: PropTypes.func,
  updateInitialPage: PropTypes.func,
};

export default SkusTable;
