/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import MaterialTable from 'material-table';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

const headerStyle = {
  backgroundColor: '#A7A5A5',
  color: '#171415',
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    backgroundColor: grey[900],
  },
  input: {
    color: theme.palette.common.white,
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  formControl: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  loadingOverlay: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: fade(theme.palette.common.black, 0.8),
  },
  seperator: {
    height: theme.spacing(2),
  },
  wrapperContent: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

const AdditionTable = ({ suppliers, products, updateProducts }) => {
  const classes = useStyles();
  const [idCounter, setIdCounter] = useState(0);
  const [description, setDescription] = useState('');
  const [supplierId, setSupplierId] = useState('');

  useEffect(() => {
    if (suppliers.length > 0) {
      setSupplierId(suppliers[0].id);
    }
  }, [suppliers]);

  const getSupplierName = (desiredSupplierId) => {
    const relevantSupplier = suppliers.find(
      (supplierInfo) => supplierInfo.id === desiredSupplierId
    );

    if (relevantSupplier) {
      return relevantSupplier.name;
    }
    return 'Unknown';
  };

  const columns = [
    {
      title: 'Description',
      field: 'description',
      render: (rowData) => (
        <TextField
          value={rowData.description}
          placeholder="e.g. Sweater - Blue (XL)"
          onChange={(e) => {
            updateProducts(
              products.map((productInfo) => {
                if (productInfo.id === rowData.id) {
                  return {
                    ...productInfo,
                    description: e.target.value,
                  };
                }
                return productInfo;
              })
            );
          }}
        />
      ),
    },
    {
      title: 'Supplier',
      field: 'supplier',
      render: (rowData) => (
        <Select
          fullWidth
          value={rowData.supplierId}
          onChange={(e) => {
            updateProducts(
              products.map((productInfo) => {
                if (productInfo.id === rowData.id) {
                  return {
                    ...productInfo,
                    supplierId: e.target.value,
                    supplierName: getSupplierName(e.target.value),
                  };
                }
                return productInfo;
              })
            );
          }}
        >
          {suppliers.map((supplierInfo) => (
            <MenuItem key={supplierInfo.id} value={supplierInfo.id}>
              {supplierInfo.name}
            </MenuItem>
          ))}
        </Select>
      ),
    },
  ];

  const addProduct = (e) => {
    e.preventDefault();
    if (description.length > 0) {
      setIdCounter(idCounter + 1);
      updateProducts([
        ...products,
        {
          supplierId,
          supplierName: getSupplierName(supplierId),
          description,
          id: idCounter,
          weight: 1,
          length: 1,
          width: 1,
          height: 1,
          department: '',
          category: '',
          subCategory: '',
          wholesalePrice: '',
          costPrice: '',
          retailPrice: '',
          successFee: '',
          measurementType: 'ESTIMATED',
        },
      ]);
      setDescription('');
    }
  };

  return (
    <div className={classes.wrapperContent}>
      <Paper component="form" className={classes.root}>
        <FormControl className={classes.formControl}>
          <Select
            value={supplierId}
            onChange={(e) => setSupplierId(e.target.value)}
          >
            {suppliers.map((supplierInfo) => (
              <MenuItem key={supplierInfo.id} value={supplierInfo.id}>
                {supplierInfo.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Divider className={classes.divider} orientation="vertical" />
        <InputBase
          className={classes.input}
          placeholder="e.g. Sweater - Blue (XL)"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <IconButton type="submit" onClick={addProduct}>
          <AddIcon />
        </IconButton>
      </Paper>
      <div className={classes.seperator} />
      <MaterialTable
        columns={columns}
        data={products}
        options={{
          headerStyle,
          sorting: false,
          grouping: false,
          draggable: false,
          search: false,
          showTitle: false,
          toolbar: false,
          paging: false,
        }}
        editable={{
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                const dataDelete = [...products];
                const index = oldData.tableData.id;
                dataDelete.splice(index, 1);
                updateProducts([...dataDelete]);
                resolve();
              }, 0);
            }),
        }}
        components={{
          OverlayLoading: () => (
            <div className={classes.loadingOverlay}>
              <CircularProgress color="primary" size={32} />
            </div>
          ),
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'Products not yet added.',
          },
        }}
      />
    </div>
  );
};

AdditionTable.defaultProps = {
  suppliers: [],
  products: [],
  updateProducts: (newProducts) =>
    console.log('Products were updated to:', newProducts),
};

AdditionTable.propTypes = {
  suppliers: PropTypes.arrayOf(PropTypes.shape()),
  products: PropTypes.arrayOf(PropTypes.shape()),
  updateProducts: PropTypes.func,
};

export default AdditionTable;
