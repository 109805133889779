/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  suppliersList: [],
  loadingStatus: {
    suppliers: 'NOT_STARTED',
  },
  pageSize: 5,
  initialPage: 0,
};

const pipelineViewSlice = createSlice({
  name: 'pipelineView',
  initialState,
  reducers: {
    changePageSize(state, action) {
      state.pageSize = action.payload;
    },
    changeInitialPage(state, action) {
      state.initialPage = action.payload;
    },
  },
});

export const { changePageSize, changeInitialPage } = pipelineViewSlice.actions;

export const updatePageSize = (newPageSize) => (dispatch) => {
  dispatch(changePageSize(newPageSize));
};

export const updateInitialPage = (newInitialPage) => (dispatch) => {
  dispatch(changeInitialPage(newInitialPage));
};

export default pipelineViewSlice.reducer;
