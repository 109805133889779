import _ from 'lodash';
import moment from 'moment';

export const getFormattedListings = (
  skus,
  clientFilter,
  searchQuery,
  weeksOutstandingFilter = 'ALL',
  stockInfoFilter = 'ALL',
  copywritingFilter = 'ALL',
  imagesFilter = 'ALL',
  submissionFilter = 'ALL'
) => {
  return skus
    .map((info) => {
      return {
        sku: info.sku,
        clientName: info.clientName,
        brand: info.brand,
        productName: info.productName,
        variation: info.variation,
        creationDate: info.creationDate || '-',
        onboarding: info.onboarding,
      };
    })
    .filter((info) => {
      if (searchQuery !== '') {
        const caseInsensitiveSearch = _.toLower(searchQuery);
        const caseInsensitiveSku = _.toLower(info.sku);
        const caseInsensitiveVariation = _.toLower(info.variation);
        const caseInsensitiveProductName = _.toLower(info.productName);
        const caseInsensitiveClientName = _.toLower(info.clientName);
        const caseInsensitiveBrand = _.toLower(info.brand);

        if (caseInsensitiveSku.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveVariation.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveProductName.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveClientName.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveBrand.includes(caseInsensitiveSearch)) {
          return true;
        }

        return false;
      }

      return true;
    })
    .filter((info) => {
      if (clientFilter !== 'ALL') {
        return info.clientName === clientFilter;
      }
      return true;
    })
    .filter((info) => {
      let returned = true;
      if (weeksOutstandingFilter !== 'ALL') {
        const daysOutstanding =
          info.creationDate === '-'
            ? 100
            : moment().diff(info.creationDate, 'days');
        switch (weeksOutstandingFilter) {
          case '2_WEEKS':
            returned = returned && daysOutstanding <= 14;
            break;
          case '3_WEEKS':
            returned =
              returned && daysOutstanding > 14 && daysOutstanding <= 21;
            break;
          case '4_WEEKS':
            returned = returned && daysOutstanding > 21;
            break;
          default:
            returned = returned && true;
            break;
        }
      }
      if (stockInfoFilter !== 'ALL') {
        returned =
          returned && info.onboarding.statuses.stockInfo === stockInfoFilter;
      }
      if (copywritingFilter !== 'ALL') {
        returned =
          returned &&
          info.onboarding.statuses.copywriting === copywritingFilter;
      }
      if (imagesFilter !== 'ALL') {
        returned = returned && info.onboarding.statuses.images === imagesFilter;
      }
      if (submissionFilter !== 'ALL') {
        returned =
          returned && info.onboarding.statuses.submission === submissionFilter;
      }
      return returned;
    })
    .sort((a, b) => {
      if (a.creationDate < b.creationDate) return +1;
      if (a.creationDate > b.creationDate) return -1;
      if (a.clientName < b.clientName) return -1;
      if (a.clientName > b.clientName) return +1;
      if (a.brand < b.brand) return -1;
      if (a.brand > b.brand) return +1;
      if (a.productName < b.productName) return -1;
      if (a.productName > b.productName) return +1;
      if (a.variation < b.variation) return -1;
      if (a.variation > b.variation) return +1;
      return 0;
    });
};

export const getProductName = (sku, skus) => {
  const skuInfo = skus.find((info) => info.sku === sku);
  if (skuInfo) {
    return skuInfo.variation === 'N/A'
      ? `${skuInfo.brand} - ${skuInfo.productName}`
      : `${skuInfo.brand} - ${skuInfo.productName} (${skuInfo.variation})`;
  }
  return 'Unknown';
};

export const getWeeksOutstandingSkuCounts = (skus, clientFilter) => {
  return skus
    .filter((info) => {
      if (clientFilter !== 'ALL') {
        return info.clientName === clientFilter;
      }
      return true;
    })
    .reduce(
      (totals, current) => {
        const daysOutstanding =
          current.creationDate === null
            ? 100
            : moment().diff(current.creationDate, 'days');

        let weeksOutstandingIndex = 0;
        if (daysOutstanding <= 14) weeksOutstandingIndex = 0;
        else if (daysOutstanding > 14 && daysOutstanding <= 21)
          weeksOutstandingIndex = 1;
        else weeksOutstandingIndex = 2;

        const newTotals = [...totals];
        newTotals[weeksOutstandingIndex] += 1;
        return newTotals;
      },
      [0, 0, 0]
    );
};

export const getStockInfoCounts = (skus, clientFilter) => {
  return skus
    .filter((info) => {
      if (clientFilter !== 'ALL') {
        return info.clientName === clientFilter;
      }
      return true;
    })
    .reduce(
      (totals, current) => {
        switch (current.onboarding.statuses.stockInfo) {
          case 'NO_STOCK': {
            const newTotals = [...totals];
            newTotals[0] += 1;
            return newTotals;
          }
          case 'MEASURING': {
            const newTotals = [...totals];
            newTotals[1] += 1;
            return newTotals;
          }
          case 'TAKING_STOCK_PHOTOS': {
            const newTotals = [...totals];
            newTotals[2] += 1;
            return newTotals;
          }
          case 'COMPLETED': {
            const newTotals = [...totals];
            newTotals[3] += 1;
            return newTotals;
          }
          default:
            return totals;
        }
      },
      [0, 0, 0, 0]
    );
};

export const getCopywritingCounts = (skus, clientFilter) => {
  return skus
    .filter((info) => {
      if (clientFilter !== 'ALL') {
        return info.clientName === clientFilter;
      }
      return true;
    })
    .reduce(
      (totals, current) => {
        switch (current.onboarding.statuses.copywriting) {
          case 'NOT_STARTED': {
            const newTotals = [...totals];
            newTotals[0] += 1;
            return newTotals;
          }
          case 'DRAFTING': {
            const newTotals = [...totals];
            newTotals[1] += 1;
            return newTotals;
          }
          case 'REVIEWING': {
            const newTotals = [...totals];
            newTotals[2] += 1;
            return newTotals;
          }
          case 'COMPLETED': {
            const newTotals = [...totals];
            newTotals[3] += 1;
            return newTotals;
          }
          default:
            return totals;
        }
      },
      [0, 0, 0, 0]
    );
};

export const getImagesCounts = (skus, clientFilter) => {
  return skus
    .filter((info) => {
      if (clientFilter !== 'ALL') {
        return info.clientName === clientFilter;
      }
      return true;
    })
    .reduce(
      (totals, current) => {
        switch (current.onboarding.statuses.images) {
          case 'NOT_STARTED': {
            const newTotals = [...totals];
            newTotals[0] += 1;
            return newTotals;
          }
          case 'AWAITING_CLIENT_IMAGES': {
            const newTotals = [...totals];
            newTotals[1] += 1;
            return newTotals;
          }
          case 'SHOOTING': {
            const newTotals = [...totals];
            newTotals[2] += 1;
            return newTotals;
          }
          case 'EDITING': {
            const newTotals = [...totals];
            newTotals[3] += 1;
            return newTotals;
          }
          case 'COMPLETED': {
            const newTotals = [...totals];
            newTotals[4] += 1;
            return newTotals;
          }
          default:
            return totals;
        }
      },
      [0, 0, 0, 0, 0]
    );
};

export const getSubmissionCounts = (skus, clientFilter) => {
  return skus
    .filter((info) => {
      if (clientFilter !== 'ALL') {
        return info.clientName === clientFilter;
      }
      return true;
    })
    .reduce(
      (totals, current) => {
        switch (current.onboarding.statuses.submission) {
          case 'NOT_STARTED': {
            const newTotals = [...totals];
            newTotals[0] += 1;
            return newTotals;
          }
          case 'IN_REVIEW': {
            const newTotals = [...totals];
            newTotals[1] += 1;
            return newTotals;
          }
          case 'REJECTED': {
            const newTotals = [...totals];
            newTotals[2] += 1;
            return newTotals;
          }
          case 'APPROVED': {
            const newTotals = [...totals];
            newTotals[3] += 1;
            return newTotals;
          }
          default:
            return totals;
        }
      },
      [0, 0, 0, 0]
    );
};

export const findSku = (sku, skus) => {
  const skuInfo = skus.find((info) => info.sku === sku);
  return skuInfo;
};
