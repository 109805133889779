/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { deleteSourcedProduct, getSourcedProduct } from '../../../api/salesAPI';

const initialState = {
  isUserRedirectedToBrowse: false,
  productInfo: {
    id: 'Loading',
  },
  loadingStatus: {
    productInfo: 'NOT_STARTED',
    deleteProduct: 'NOT_STARTED',
  },
  feedback: {
    isOpen: false,
    type: undefined,
    message: '',
  },
};

const sourcedProductsViewSlice = createSlice({
  name: 'sourcedProductsView',
  initialState,
  reducers: {
    fetchProductInfoStarted(state) {
      state.loadingStatus.productInfo = 'PENDING';
    },
    fetchProductInfoSuccess(state, action) {
      state.productInfo = action.payload;
      state.loadingStatus.productInfo = 'COMPLETE';
    },
    fetchProductInfoFailed(state, action) {
      state.loadingStatus.productInfo = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    deleteProductStarted(state) {
      state.loadingStatus.deleteProduct = 'PENDING';
    },
    deleteProductSuccess(state) {
      state.loadingStatus.deleteProduct = 'COMPLETE';
      state.isUserRedirectedToBrowse = true;
    },
    deleteProductFailed(state, action) {
      state.loadingStatus.deleteProduct = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    resetState() {
      return initialState;
    },
  },
});

export const {
  fetchProductInfoStarted,
  fetchProductInfoSuccess,
  fetchProductInfoFailed,
  deleteProductStarted,
  deleteProductSuccess,
  deleteProductFailed,
  resetState,
} = sourcedProductsViewSlice.actions;

export default sourcedProductsViewSlice.reducer;

export const fetchProductInfo = (id) => async (dispatch) => {
  dispatch(fetchProductInfoStarted());
  const result = await getSourcedProduct(id);

  if (result.status === 200) {
    dispatch(fetchProductInfoSuccess(result.productInfo));
  } else {
    dispatch(fetchProductInfoFailed(result.error));
  }
};

export const deleteProduct = (id) => async (dispatch) => {
  dispatch(deleteProductStarted());
  const result = await deleteSourcedProduct(id);

  if (result.status === 200) {
    dispatch(deleteProductSuccess());
  } else {
    dispatch(deleteProductFailed(result.error));
  }
};

export const initiateStateReset = () => async (dispatch) => {
  dispatch(resetState());
};
