import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  loadingWrapper: {
    padding: theme.spacing(8),
    textAlign: 'center',
  },
}));

const BasicInfoCard = ({
  name,
  contactPerson,
  contactNumber,
  websiteUrl,
  isLoading,
  onMissingInfoClick,
}) => {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader title="Supplier Info" />
      <Divider />
      <CardContent>
        {isLoading ? (
          <div className={classes.loadingWrapper}>
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <List>
            <ListItem>
              <ListItemText primary={name} secondary="Name" />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  contactPerson.length === 0 ? 'Missing Info' : contactPerson
                }
                secondary="Contact Person"
              />
              {contactPerson.length === 0 && (
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={onMissingInfoClick}
                  >
                    <HelpOutlineIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  contactNumber.length === 0 ? 'Missing Info' : contactNumber
                }
                secondary="Contact Number"
              />
              {contactNumber.length === 0 && (
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={onMissingInfoClick}
                  >
                    <HelpOutlineIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
            <ListItem>
              <ListItemText
                primary={websiteUrl.length === 0 ? 'Missing Info' : websiteUrl}
                secondary="Website Url"
              />
              {websiteUrl.length === 0 && (
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={onMissingInfoClick}
                  >
                    <HelpOutlineIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          </List>
        )}
      </CardContent>
    </Card>
  );
};

BasicInfoCard.defaultProps = {
  isLoading: false,
  name: 'Supplier Example',
  contactPerson: 'John Doe',
  contactNumber: '+27 11 222 3333',
  websiteUrl: 'https://www.supplierexample.com/',
  onMissingInfoClick: () => console.log('Missing info icon was clicked.'),
};

BasicInfoCard.propTypes = {
  isLoading: PropTypes.bool,
  name: PropTypes.string,
  contactPerson: PropTypes.string,
  contactNumber: PropTypes.string,
  websiteUrl: PropTypes.string,
  onMissingInfoClick: PropTypes.func,
};

export default BasicInfoCard;
