import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import GetAppIcon from '@material-ui/icons/GetApp';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import moment from 'moment';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  buttonIcon: {
    marginRight: theme.spacing(2),
  },
  downloadButton: {
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'none',
    },
  },
  text: {
    color: theme.palette.secondary.light,
  },
  title: {
    color: theme.palette.primary.light,
  },
}));

const Report = ({
  startDate,
  endDate,
  supplierFilter,
  statusFilter,
  requestedBy,
  status,
  requestDate,
  reportType,
  dcFilter,
  downloadLink,
}) => {
  const classes = useStyles();

  const getButtonContents = () => {
    if (status === 'PENDING') {
      return (
        <>
          <CircularProgress size={18} className={classes.buttonIcon} />
          <span> Pending</span>
        </>
      );
    }
    return (
      <>
        <GetAppIcon className={classes.buttonIcon} />
        <span> Download</span>
      </>
    );
  };

  const getPrettySupplierName = () => {
    if (supplierFilter === 'Unknown') {
      return 'All Suppliers';
    }
    return supplierFilter;
  };

  const getPrettyReportType = () => {
    if (reportType === 'PURCHASE_ORDERS') {
      return 'Purchase Orders';
    }
    return 'Takealot Orders';
  };

  const getPrettyStatus = () => {
    if (statusFilter === 'ALL') {
      return 'All Statuses';
    }

    switch (statusFilter) {
      case 'PREPARING_FOR_CUSTOMER':
        return 'Preparing for Customer';
      case 'SHIPPED_TO_CUSTOMER':
        return 'Shipped to Customer';
      case 'INTER_DC_TRANSFER':
        return 'Inter DC Transfer';
      case 'NEW_LEAD_TIME_ORDER':
        return 'New Lead Time Order';
      case 'DRAFT_SHIPMENT':
        return 'Draft Shipment';
      case 'CONFIRMED_SHIPMENT':
        return 'Confirmed Shipment';
      case 'SHIPPED_SHIPMENT':
        return 'Shipped Shipment';
      case 'CHECKED_IN_SHIPMENT':
        return 'Checked In Shipment';
      case 'RECEIVED_SHIPMENT':
        return 'Received Shipment';
      case 'CANCELLED_BY_CUSTOMER':
        return 'Cancelled by Customer';
      case 'CANCELLED_BY_SELLER':
        return 'Cancelled by Seller';
      case 'CANCELLED_BY_TAKEALOT':
        return 'Cancelled by Takealot';
      case 'CANCELLED_LATE_DELIVERY':
        return 'Cancelled - Late Delivery';
      case 'CANCELLED_INBOUND_EXCEPTION':
        return 'Cancelled - Inbound Exception';
      case 'RETURNED':
        return 'Returned';
      case 'NEW_DROP_SHIP_ORDER':
        return 'New Drop Ship Order';
      case 'IN_PROGRESS_DROP_SHIP_ORDER':
        return 'In Progress Drop Ship Order';
      case 'READY_TO_COLLECT_DROP_SHIP_ORDER':
        return 'Ready To Collect Drop Ship Order';
      case 'COLLECTED_DROP_SHIP_ORDER':
        return 'Collected Drop Ship Order';
      default:
        return status;
    }
  };

  const getPrettyDcFilter = () => {
    switch (dcFilter) {
      case 'BOTH':
        return 'Both DCs';
      case 'CPT':
        return 'CPT';
      case 'JHB':
        return 'JHB';
      default:
        return 'Both DCs';
    }
  };

  return (
    <Card>
      <CardContent>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography
              gutterBottom
              className={classes.text}
              variant="body2"
              component="p"
            >
              {`${getPrettyReportType()} | ${getPrettySupplierName()} | ${getPrettyStatus()} | ${getPrettyDcFilter()}`}
            </Typography>
            <Typography
              gutterBottom
              variant="h5"
              component="h2"
              className={classes.title}
            >
              {`${moment(startDate).format('DD/MM/YY')} - ${moment(
                endDate
              ).format('DD/MM/YY')}`}
            </Typography>
            <Typography className={classes.text} variant="body2" component="p">
              {`Requested by ${requestedBy} on ${moment(requestDate).format(
                'D MMMM YYYY, h:mm A'
              )}`}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              className={classes.downloadButton}
              color="secondary"
              variant="contained"
              disabled={status === 'PENDING'}
              target="_blank"
              rel="noreferrer"
              component={Link}
              href={downloadLink}
            >
              {getButtonContents()}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

Report.defaultProps = {
  startDate: new Date('08/10/2022'),
  endDate: new Date('08/22/2022'),
  supplierFilter: 'All Suppliers',
  statusFilter: 'New Leadtime Order',
  requestedBy: 'Mace Windu',
  status: 'PENDING',
  requestDate: new Date(),
  reportType: 'TAKEALOT_ORDERS',
  dcFilter: 'BOTH',
  downloadLink: '',
};

Report.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  supplierFilter: PropTypes.string,
  statusFilter: PropTypes.string,
  requestedBy: PropTypes.string,
  status: PropTypes.oneOf(['PENDING', 'COMPLETE']),
  requestDate: PropTypes.instanceOf(Date),
  reportType: PropTypes.string,
  dcFilter: PropTypes.string,
  downloadLink: PropTypes.string,
};

export default Report;
