import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import StatusSelector from './components/StatusSelector';
import TypeSelector from './components/TypeSelector';

const useStyles = makeStyles((theme) => ({
  results: {
    margin: theme.spacing(1),
    color: grey[800],
  },
  separator: {
    width: theme.spacing(1),
  },
  wrapperButtons: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  wrapperRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
}));

const FiltersBar = ({
  searchText,
  changeSearchText,
  statusId,
  typeId,
  changeStatus,
  changeType,
  clientCount,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapperRoot}>
      <TextField
        placeholder="Search"
        value={searchText}
        onChange={(e) => changeSearchText(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <Typography className={classes.results} variant="body1" component="span">
        {`${clientCount} ${clientCount === 1 ? 'result' : 'results'} displayed`}
      </Typography>
      <div className={classes.wrapperButtons}>
        <div className={classes.separator} />
        <TypeSelector typeId={typeId} changeType={changeType} />
        <StatusSelector statusId={statusId} changeStatus={changeStatus} />
      </div>
    </div>
  );
};

FiltersBar.defaultProps = {
  searchText: '',
  changeSearchText: (newValue) => `Search text was changed to '${newValue}'`,
  statusId: 'ALL',
  typeId: 'ALL',
  changeStatus: (newValue) => `Status was changed to '${newValue}'`,
  changeType: (newValue) => `Type was change to '${newValue}'`,
  clientCount: 1,
};

FiltersBar.propTypes = {
  searchText: PropTypes.string,
  changeSearchText: PropTypes.func,
  statusId: PropTypes.oneOf([
    'ALL',
    'MISSING_INFO',
    'ACTIVE',
    'TRADE_OUT',
    'TERMINATED',
  ]),
  typeId: PropTypes.oneOf(['ALL', 'STANDARD', 'SUPER_DEALER']),
  changeStatus: PropTypes.func,
  changeType: PropTypes.func,
  clientCount: PropTypes.number,
};

export default FiltersBar;
