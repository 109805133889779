/* eslint-disable react/jsx-props-no-spreading */
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {
  fetchShipmentInfo,
  resetState,
  setTotalDocumentUploadCount,
  uploadNewDocument,
} from './slice';
import ContentWrapper from '../../../components/ContentWrapper';
import DocumentPreviewCard from './DocumentPreviewCard';
import PageHeader from '../../../components/PageHeader';

const useStyles = makeStyles((theme) => ({
  actionsBar: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  wrapperWizard: {
    width: '100%',
    padding: theme.spacing(4),
  },
  loadingWrapper: {
    padding: theme.spacing(4),
    width: '100%',
    textAlign: 'center',
  },
  formControl: {
    width: '100%',
  },
  formWrapper: {
    padding: `${theme.spacing(4)}px ${theme.spacing(1)}px`,
  },
  alert: {
    marginBottom: theme.spacing(3),
  },
  dropZone: {
    padding: theme.spacing(4),
    textAlign: 'center',
    width: '100%',
  },
  separator: {
    height: theme.spacing(2),
  },
  wrapperStep: {
    padding: `${theme.spacing(4)}px 0px`,
  },
}));

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#171415',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  marginTop: 24,
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const breadcrumbs = [
  {
    label: 'Logistics',
    link: '/logistics',
  },
  {
    label: 'Shipments',
    link: '/logistics/shipments',
  },
];

const ShipmentsDocumentUpload = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { shipmentId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [tags, setTags] = useState([]);
  const [description, setDescription] = useState([]);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop: (acceptedDocuments) => {
      setDocuments([...documents, ...acceptedDocuments]);
    },
  });
  const shipmentInfoLoadingStatus = useSelector(
    (state) => state.shipmentsDocumentsUpload.loadingStatus.shipmentInfo
  );
  const documentLoadingCounts = useSelector(
    (state) => state.shipmentsDocumentsUpload.documentUploadCounts
  );
  const shipmentInfo = useSelector(
    (state) => state.shipmentsDocumentsUpload.shipmentInfo
  );
  const uploadTransfers = useSelector(
    (state) => state.shipmentsDocumentsUpload.uploadTransfers
  );
  const userInfo = useSelector((state) => state.account.userInfo);

  useEffect(() => {
    dispatch(fetchShipmentInfo(shipmentId));
  }, []);

  useEffect(() => {
    setTags([...tags, ...documents.map(() => 'OTHER')]);
    setDescription([...description, ...documents.map((file) => file.name)]);
  }, [documents]);

  useEffect(() => {
    if (
      shipmentInfoLoadingStatus === 'PENDING' ||
      documentLoadingCounts.total >
        documentLoadingCounts.success + documentLoadingCounts.failed
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [documentLoadingCounts, shipmentInfoLoadingStatus]);

  useEffect(() => {
    if (
      documentLoadingCounts.total > 0 &&
      documentLoadingCounts.total ===
        documentLoadingCounts.success + documentLoadingCounts.failed
    ) {
      dispatch(resetState());
      setIsLoading(true);
      history.goBack();
    }
  }, [documentLoadingCounts]);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const getProgress = () => {
    const totalBytes = Object.values(uploadTransfers).reduce(
      (total, current) => {
        return total + current.totalBytes;
      },
      0
    );
    const transferredBytes = Object.values(uploadTransfers).reduce(
      (total, current) => {
        return total + current.transferredBytes;
      },
      0
    );
    if (totalBytes === 0) {
      return 0;
    }
    return Math.round((transferredBytes / totalBytes) * 100);
  };

  const validateDescriptions = () => {
    let validity = true;
    description.forEach((desc, index) => {
      if (documents[index])
        if (desc < 1) {
          validity = false;
        }
    });
    return validity;
  };

  return (
    <div>
      <PageHeader
        isLoading={isLoading}
        title="Upload Documents"
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Documents"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
      />
      <ContentWrapper>
        <Paper className={classes.wrapperWizard}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom>
                {isLoading
                  ? 'Loading...'
                  : `Documents for shipment ${shipmentInfo.id}`}
              </Typography>
              <Typography variant="body1" gutterBottom>
                You can assign tags to documents for easier identification
                later.
              </Typography>
              <div className="container">
                {documentLoadingCounts.total === 0 ? (
                  <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    <p>
                      Drag &rsquo;n&lsquo; drop some files here, or click to
                      select files
                    </p>
                  </div>
                ) : (
                  <div className={classes.loadingWrapper}>
                    <Box position="relative" display="inline-flex">
                      <CircularProgress
                        value={getProgress()}
                        size={64}
                        variant="determinate"
                        color="secondary"
                      />
                      <Box
                        top={0}
                        left={0}
                        bottom={0}
                        right={0}
                        position="absolute"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography variant="body1" component="div">
                          {`${getProgress()}%`}
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                )}
              </div>
            </Grid>
            {documents.map((doc, index) => (
              <Grid key={doc.preview} item xs={3}>
                <DocumentPreviewCard
                  tag={tags[index]}
                  setTag={(newTag) =>
                    setTags(
                      tags.map((tag, tagIndex) => {
                        if (tagIndex === index) {
                          return newTag;
                        }
                        return tag;
                      })
                    )
                  }
                  removeDoc={() => {
                    setDocuments(
                      documents.filter(
                        (filterDoc, filterIndex) => filterIndex !== index
                      )
                    );
                    setTags(
                      tags.filter(
                        (filterTag, filterIndex) => filterIndex !== index
                      )
                    );
                    setDescription(
                      description.filter(
                        (filterDescription, filterIndex) =>
                          filterIndex !== index
                      )
                    );
                  }}
                  isLoading={isLoading}
                  description={description[index]}
                  setDescription={(newDescription) =>
                    setDescription(
                      description.map((desc, descriptionIndex) => {
                        if (descriptionIndex === index) {
                          return newDescription;
                        }
                        return desc;
                      })
                    )
                  }
                  documentName={documents[index].name}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <div className={classes.actionsBar}>
                <Button
                  disabled={isLoading}
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    if (validateDescriptions()) {
                      dispatch(setTotalDocumentUploadCount(documents.length));
                      documents.map((doc, index) =>
                        dispatch(
                          uploadNewDocument(
                            shipmentInfo.id,
                            doc,
                            tags[index],
                            description[index],
                            userInfo.uid,
                            `${userInfo.name} ${userInfo.surname}`
                          )
                        )
                      );
                    }
                  }}
                >
                  Upload
                </Button>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </ContentWrapper>
    </div>
  );
};

export default ShipmentsDocumentUpload;
