import { fade } from '@material-ui/core/styles/colorManipulator';
import { makeStyles } from '@material-ui/core/styles';
import numeral from 'numeral';
import CircularProgress from '@material-ui/core/CircularProgress';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';

const headerStyle = {
  backgroundColor: '#171415',
};

const useStyles = makeStyles((theme) => ({
  loadingOverlay: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: fade(theme.palette.common.black, 0.8),
  },
  separator: {
    height: theme.spacing(8),
  },
  wrapperContent: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

const StatementViewTables = ({
  lineItems,
  fixPricing,
  removeAdjustment,
  isFinalized,
}) => {
  const classes = useStyles();
  const unknownPricing = lineItems.filter(
    (info) => info.discountStatus === 'UNKNOWN'
  );
  const standardSales = lineItems.filter(
    (info) => info.discountStatus === 'STANDARD' && info.type === 'SALE'
  );
  const discountedSales = lineItems.filter(
    (info) => info.discountStatus === 'DISCOUNTED' && info.type === 'SALE'
  );
  const standardRefunds = lineItems.filter(
    (info) => info.discountStatus === 'STANDARD' && info.type === 'REFUND'
  );
  const discountedRefunds = lineItems.filter(
    (info) => info.discountStatus === 'DISCOUNTED' && info.type === 'REFUND'
  );
  const adjustments = lineItems.filter((info) => info.type === 'ADJUSTMENT');

  const columns = [
    {
      title: 'Code',
      field: 'code',
      render: (rowData) => {
        if (rowData.type === 'ADJUSTMENT') {
          return '-';
        }
        return rowData.code;
      },
    },
    {
      title: 'Name',
      field: 'name',
    },
    {
      title: 'Units',
      field: 'units',
      type: 'numeric',
      render: (rowData) => {
        if (rowData.type === 'ADJUSTMENT') {
          return '-';
        }
        return numeral(rowData.units).format('0,00');
      },
    },
    {
      title: 'Per Unit',
      field: 'perUnit',
      type: 'numeric',
      render: (rowData) => {
        if (rowData.type === 'ADJUSTMENT') {
          return '-';
        }
        return `R ${numeral(rowData.perUnit).format('0,00.00')}`;
      },
    },
    {
      title: 'Total',
      field: 'total',
      type: 'numeric',
      render: (rowData) => `R ${numeral(rowData.total).format('0,00.00')}`,
    },
    {
      title: 'Margin',
      field: 'margin',
      type: 'numeric',
      render: (rowData) => {
        if (rowData.type === 'REFUND' || rowData.type === 'ADJUSTMENT') {
          return '-';
        }
        return `${numeral(rowData.margin * 100).format('0,00.00')}%`;
      },
    },
    {
      title: 'Discount',
      field: 'discount',
      type: 'numeric',
      render: (rowData) => {
        if (
          rowData.type === 'ADJUSTMENT' ||
          rowData.discountStatus === 'STANDARD'
        ) {
          return '-';
        }
        if (rowData.expectedRetail === null || rowData.expectedRetail === 0) {
          return '?';
        }
        return `${numeral(
          ((Math.abs(rowData.expectedRetail) - Math.abs(rowData.actualRetail)) /
            Math.abs(rowData.expectedRetail)) *
            100
        ).format('0,00.00')}%`;
      },
    },
  ];

  return (
    <div className={classes.wrapperContent}>
      {unknownPricing.length > 0 && (
        <MaterialTable
          className={classes.wrapperTable}
          columns={columns}
          data={unknownPricing}
          title={`Pricing Issues (${unknownPricing.length})`}
          options={{
            headerStyle,
            grouping: false,
            draggable: false,
          }}
          components={{
            OverlayLoading: () => (
              <div className={classes.loadingOverlay}>
                <CircularProgress color="primary" size={32} />
              </div>
            ),
          }}
          localization={{
            header: {
              actions: '',
            },
            body: {
              emptyDataSourceMessage: 'No statement reports to display',
            },
          }}
          actions={
            isFinalized
              ? []
              : [
                  {
                    icon: 'build',
                    tooltip: 'Fix',
                    onClick: (event, rowData) => {
                      fixPricing(rowData);
                    },
                  },
                ]
          }
        />
      )}
      {adjustments.length > 0 && (
        <MaterialTable
          columns={columns}
          data={adjustments}
          title={`Adjustments (${adjustments.length})`}
          options={{
            headerStyle,
            grouping: false,
            draggable: false,
          }}
          components={{
            OverlayLoading: () => (
              <div className={classes.loadingOverlay}>
                <CircularProgress color="primary" size={32} />
              </div>
            ),
          }}
          localization={{
            header: {
              actions: '',
            },
            body: {
              emptyDataSourceMessage: 'No statement reports to display',
            },
          }}
          actions={
            isFinalized
              ? []
              : [
                  {
                    icon: 'delete',
                    tooltip: 'Remove',
                    onClick: (event, rowData) => {
                      removeAdjustment(rowData.index);
                    },
                  },
                ]
          }
        />
      )}
      {standardSales.length > 0 && (
        <MaterialTable
          columns={columns}
          data={standardSales}
          title={`Standard Sales (${standardSales.length})`}
          options={{
            headerStyle,
            grouping: false,
            draggable: false,
          }}
          components={{
            OverlayLoading: () => (
              <div className={classes.loadingOverlay}>
                <CircularProgress color="primary" size={32} />
              </div>
            ),
          }}
          localization={{
            header: {
              actions: '',
            },
            body: {
              emptyDataSourceMessage: 'No statement reports to display',
            },
          }}
        />
      )}
      {discountedSales.length > 0 && (
        <MaterialTable
          columns={columns}
          data={discountedSales}
          title={`Discounted Sales (${discountedSales.length})`}
          options={{
            headerStyle,
            grouping: false,
            draggable: false,
          }}
          components={{
            OverlayLoading: () => (
              <div className={classes.loadingOverlay}>
                <CircularProgress color="primary" size={32} />
              </div>
            ),
          }}
          localization={{
            header: {
              actions: '',
            },
            body: {
              emptyDataSourceMessage: 'No statement reports to display',
            },
          }}
        />
      )}
      {standardRefunds.length > 0 && (
        <MaterialTable
          columns={columns}
          data={standardRefunds}
          title={`Standard Refunds (${standardRefunds.length})`}
          options={{
            headerStyle,
            grouping: false,
            draggable: false,
          }}
          components={{
            OverlayLoading: () => (
              <div className={classes.loadingOverlay}>
                <CircularProgress color="primary" size={32} />
              </div>
            ),
          }}
          localization={{
            header: {
              actions: '',
            },
            body: {
              emptyDataSourceMessage: 'No statement reports to display',
            },
          }}
        />
      )}
      {discountedRefunds.length > 0 && (
        <MaterialTable
          columns={columns}
          data={discountedRefunds}
          title={`Discounted Refunds (${discountedRefunds.length})`}
          options={{
            headerStyle,
            grouping: false,
            draggable: false,
          }}
          components={{
            OverlayLoading: () => (
              <div className={classes.loadingOverlay}>
                <CircularProgress color="primary" size={32} />
              </div>
            ),
          }}
          localization={{
            header: {
              actions: '',
            },
            body: {
              emptyDataSourceMessage: 'No statement reports to display',
            },
          }}
        />
      )}
    </div>
  );
};

StatementViewTables.defaultProps = {
  lineItems: [],
  fixPricing: (lineItem) => console.log('Fix pricing for:', lineItem),
  removeAdjustment: (index) =>
    console.log('Adjustment deleted with index', index),
  isFinalized: false,
};

StatementViewTables.propTypes = {
  lineItems: PropTypes.arrayOf(PropTypes.shape()),
  fixPricing: PropTypes.func,
  removeAdjustment: PropTypes.func,
  isFinalized: PropTypes.bool,
};

export default StatementViewTables;
