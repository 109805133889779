import _ from 'lodash';
import firebase from 'firebase/app';
import numeral from 'numeral';
import { firestore, storage, functions } from '../utils/firebase';

export const getWarehouses = async () => {
  const warehousesRef = firestore.collection('warehouses');

  return warehousesRef
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.length === 0) {
        return {
          status: 500,
        };
      }
      const warehouses = [];
      querySnapshot.forEach((doc) => {
        const warehouseInfo = doc.data();
        warehouses.push({
          id: doc.id,
          ...warehouseInfo,
        });
      });
      return {
        warehouses,
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const updateStockInfo = async (
  sku,
  oldStockInfo,
  newStockInfo,
  userInfo
) => {
  const batch = firestore.batch();
  const listingRef = firestore.collection('skus').doc(sku);

  _.toPairs(newStockInfo).forEach(([warehouseId, newQuantity]) => {
    const oldQuantity = oldStockInfo[warehouseId] || 0;
    const diff = newQuantity - oldQuantity;

    if (diff !== 0) {
      const currentTimestamp = new Date(Date.now()).toString();
      const stockChangeRef = firestore
        .collection('skus')
        .doc(sku)
        .collection('stockChanges')
        .doc(currentTimestamp);
      batch.set(stockChangeRef, {
        warehouseId,
        date: new Date(currentTimestamp),
        userName: `${userInfo.name} ${userInfo.surname}`,
        userId: userInfo.uid,
        change: diff,
      });
      batch.update(listingRef, {
        stockQuantities: newStockInfo,
      });
    }
  });

  return batch
    .commit()
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const getShipments = async () => {
  const shipmentsRef = firestore.collection('shipments');

  return shipmentsRef
    .get()
    .then((querySnapshot) => {
      const shipments = [];
      querySnapshot.forEach((doc) => {
        const shipmentInfo = { id: doc.id, ...doc.data() };
        const creationDate = shipmentInfo.creationDate.toDate().toISOString();
        const lastUpdated = shipmentInfo.lastUpdated.toDate().toISOString();

        shipments.push({
          ...shipmentInfo,
          creationDate,
          lastUpdated,
        });
      });
      return {
        shipments,
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const getStockRequests = async () => {
  const stockRequestsRef = firestore
    .collection('shipments')
    .where('status', '!=', 'PREPARING')
    .where('fromId', '==', 'CLIENT');

  return stockRequestsRef
    .get()
    .then((querySnapshot) => {
      const stockRequests = [];
      querySnapshot.forEach((doc) => {
        const shipmentInfo = { id: doc.id, ...doc.data() };
        const creationDate = shipmentInfo.creationDate.toDate().toISOString();
        const lastUpdated = shipmentInfo.lastUpdated.toDate().toISOString();

        stockRequests.push({
          ...shipmentInfo,
          creationDate,
          lastUpdated,
        });
      });
      return {
        stockRequests,
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const saveShipmentInfo = async (shipmentId, shipmentInfo) => {
  const batch = firestore.batch();
  let shipmentRef = firestore.collection('shipments').doc();
  if (shipmentId !== '') {
    shipmentRef = firestore.collection('shipments').doc(shipmentId);
  }
  batch.set(shipmentRef, shipmentInfo);

  return batch
    .commit()
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const updateShipmentInfo = async (shipmentId, shipmentInfo) => {
  const shipmentRef = firestore.collection('shipments').doc(shipmentId);

  return shipmentRef
    .update(shipmentInfo)
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const updateShipmentStatus = async (shipmentId, status) => {
  const shipmentRef = firestore.collection('shipments').doc(shipmentId);

  return shipmentRef
    .update({ status, lastUpdated: new Date() })
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const flagShipmentCompleted = async (shipmentId, discrepancies) => {
  const shipmentRef = firestore.collection('shipments').doc(shipmentId);

  return shipmentRef
    .update({
      discrepancies,
      status: 'COMPLETE',
      lastUpdated: new Date(),
    })
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const saveShipmentAdjustments = async (
  shipmentId,
  adjustments,
  adjustedShipmentSkus,
  totalDifference
) => {
  const shipmentRef = firestore.collection('shipments').doc(shipmentId);

  return shipmentRef
    .update({
      adjustments,
      skus: adjustedShipmentSkus,
      lastUpdated: new Date(),
      totalUnits: firebase.firestore.FieldValue.increment(totalDifference),
    })
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const getShipment = async (shipmentId) => {
  const shipmentRef = firestore.collection('shipments').doc(shipmentId);

  return shipmentRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        const shipmentInfo = { id: doc.id, ...doc.data() };
        const creationDate = shipmentInfo.creationDate.toDate().toISOString();
        const lastUpdated = shipmentInfo.lastUpdated.toDate().toISOString();

        return {
          shipmentInfo: {
            ...shipmentInfo,
            skus: _.values(shipmentInfo.skus),
            creationDate,
            lastUpdated,
          },
          status: 200,
        };
      }

      return {
        shipmentRef,
        status: 500,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const uploadDocuments = async (
  shipmentId,
  doc,
  tag,
  description,
  uploaderId,
  uploaderName,
  updateUploadBytes,
  uploadSuccessful,
  uploadFailed
) => {
  const storageRef = storage.ref();
  const shipmentDocumentRef = firestore
    .collection('shipments')
    .doc(shipmentId)
    .collection('documents')
    .doc();
  const newFileRef = storageRef.child(
    `shipments/${shipmentId}/documents/${shipmentDocumentRef.id}`
  );
  const uploadTask = newFileRef.put(doc);
  uploadTask.on(
    'state_changed',
    (snapshot) => {
      updateUploadBytes(
        shipmentDocumentRef.id,
        snapshot.totalBytes,
        snapshot.bytesTransferred
      );
    },
    () => {
      uploadFailed();
    },
    async () => {
      return shipmentDocumentRef
        .set({
          tag,
          description,
          reference: newFileRef.fullPath,
          fileSize: doc.size,
          uploaderId,
          uploaderName,
        })
        .then(() => {
          uploadSuccessful();
        })
        .catch((error) => {
          uploadFailed();
          console.log(error);
        });
    }
  );
};

export const getDocuments = async (shipmentId) => {
  const documentsRef = firestore
    .collection('shipments')
    .doc(shipmentId)
    .collection('documents');
  const storageRef = storage.ref();

  return documentsRef
    .get()
    .then(async (querySnapshot) => {
      if (querySnapshot.length === 0) {
        return {
          status: 500,
        };
      }

      const documentRefs = [];
      const documents = [];

      querySnapshot.forEach(async (doc) => {
        const documentInfo = doc.data();
        const documentRef = storageRef.child(documentInfo.reference);
        documentRefs.push(documentRef);
        documents.push({ ...documentInfo, id: doc.id });
      });

      await Promise.all(
        documentRefs.map((documentRef, index) =>
          documentRef.getDownloadURL().then((url) => {
            documents[index] = {
              ...documents[index],
              url,
            };
          })
        )
      );

      return {
        documents,
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const deleteDocument = async (shipmentId, documentPath, documentId) => {
  const storageRef = storage.ref();
  const shipmentDocumentRef = firestore
    .collection('shipments')
    .doc(shipmentId)
    .collection('documents')
    .doc(documentId);
  const documentRef = storageRef.child(documentPath);

  await documentRef.delete();

  return shipmentDocumentRef
    .delete()
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        error,
        status: 500,
      };
    });
};

export const getReplenishmentList = async () => {
  const replenishmentsRef = firestore
    .collection('replenishmentRecommendations')
    .orderBy('date')
    .limitToLast(1);

  return replenishmentsRef
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.length === 0) {
        return {
          status: 500,
        };
      }

      let replenishmentList = {};

      querySnapshot.forEach((doc) => {
        const replenishmentInfo = doc.data();
        replenishmentList = {
          ...replenishmentInfo,
          id: doc.id,
          date: replenishmentInfo.date.toDate().toISOString(),
        };
      });

      return {
        replenishmentList,
        status: 200,
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        error,
        status: 500,
      };
    });
};

export const getReplenishmentSkus = async (
  replenishmentListId,
  destination
) => {
  const skusRef = firestore
    .collection('replenishmentRecommendations')
    .doc(replenishmentListId)
    .collection('skus')
    .where('destination', '==', destination);

  return skusRef
    .get()
    .then((querySnapshot) => {
      const skus = [];

      querySnapshot.forEach((doc) => {
        const skuInfo = doc.data();
        skus.push(skuInfo);
      });

      return {
        skus,
        status: 200,
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        error,
        status: 500,
      };
    });
};

export const updateReplenishmentUnits = async (
  replenishmentId,
  destination,
  sku,
  newUnits,
  skuInfo
) => {
  const replenishmentRef = firestore
    .collection('replenishmentRecommendations')
    .doc(replenishmentId)
    .collection('skus')
    .doc(`${destination}-${sku}`);

  let dc = null;

  if (destination === 'TAKEALOTJHB') {
    dc = 'JHB';
  } else if (destination === 'TAKEALOTCPT') {
    dc = 'CPT';
  }

  const {
    childAsin,
    clientId,
    clientName,
    costPrice,
    inStock,
    inboundUnits,
    product,
    recentSales,
    recommendedUnits,
    tsin,
  } = skuInfo;

  return replenishmentRef
    .set({
      childAsin,
      clientId,
      clientName,
      costPrice,
      inStock,
      inboundUnits,
      product,
      recentSales,
      recommendedUnits,
      tsin,
      sku,
      dc,
      destination: destination === 'EASYONLINE' ? 'EASYONLINE' : 'TAKEALOT',
      unitsToSend: newUnits,
    })
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const createReplenishmentShipments = async (
  shipments,
  replenishmentId
) => {
  try {
    const shipmentsRef = firestore.collection('shipments');
    const replenishmentListRef = firestore
      .collection('replenishmentRecommendations')
      .doc(replenishmentId);
    await Promise.all(shipments.map((info) => shipmentsRef.doc().set(info)));
    await replenishmentListRef.update({
      isActioned: true,
    });

    return {
      status: 200,
    };
  } catch (error) {
    return {
      error,
      status: 500,
    };
  }
};

export const checkIfWaybillAdded = async (shipmentId) => {
  const documentsRef = firestore
    .collection('shipments')
    .doc(shipmentId)
    .collection('documents')
    .where('tag', '==', 'WAYBILL');

  return documentsRef
    .get()
    .then(async (querySnapshot) => {
      if (querySnapshot.empty) {
        return {
          isWaybillUploaded: false,
          status: 200,
        };
      }

      return {
        isWaybillUploaded: true,
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const updateReplenishmentList = async () => {
  const triggerReplenishmentListUpdate = functions.httpsCallable(
    'replenishments-getReplenishmentList'
  );

  return triggerReplenishmentListUpdate()
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const bulkUpdate = async (
  code,
  easyOnlineQty,
  amazonUsQty,
  codeType,
  userInfo
) => {
  const warehouseCodes = {
    easyOnline: '5tI7NvUkhMtemnqcirGy',
    amazonUs: 'baGsBI92BgjNEB9r0tPT',
  };

  if (codeType === 'SKU') {
    const skuRef = firestore
      .collection('skus')
      .where('takealot.sku', '==', code);

    const skuInfo = await skuRef.get().then(async (querySnapshot) => {
      let info = {};

      querySnapshot.forEach((doc) => {
        info = doc.data();
      });

      return info;
    });

    if (skuInfo.stockQuantities === undefined) {
      return {
        code,
        type: codeType,
        status: 500,
      };
    }

    const originalEasyOnlineQty =
      skuInfo.stockQuantities[warehouseCodes.easyOnline];
    const originalAmazonUsQty =
      skuInfo.stockQuantities[warehouseCodes.amazonUs];

    const batch = firestore.batch();
    const specificSkuRef = firestore.collection('skus').doc(skuInfo.sku);

    if (easyOnlineQty !== null) {
      console.log(easyOnlineQty);
      const currentTime = new Date().toISOString();
      const stockChangeRef = firestore
        .collection('skus')
        .doc(skuInfo.sku)
        .collection('stockChanges')
        .doc(`${currentTime}-eol`);

      batch.set(stockChangeRef, {
        warehouseId: warehouseCodes.easyOnline,
        date: new Date(currentTime),
        userName: `${userInfo.name} ${userInfo.surname}`,
        userId: userInfo.uid,
        change:
          numeral(easyOnlineQty).value() -
          numeral(originalEasyOnlineQty).value(),
      });
    }

    if (amazonUsQty !== null) {
      console.log(amazonUsQty);
      const currentTime = new Date().toISOString();
      const stockChangeRef = firestore
        .collection('skus')
        .doc(skuInfo.sku)
        .collection('stockChanges')
        .doc(`${currentTime}-aus`);

      batch.set(stockChangeRef, {
        warehouseId: warehouseCodes.amazonUs,
        date: new Date(currentTime),
        userName: `${userInfo.name} ${userInfo.surname}`,
        userId: userInfo.uid,
        change:
          numeral(amazonUsQty).value() - numeral(originalAmazonUsQty).value(),
      });
    }

    batch.update(specificSkuRef, {
      stockQuantities: {
        ...skuInfo.stockQuantities,
        [warehouseCodes.easyOnline]:
          easyOnlineQty >= 0
            ? numeral(easyOnlineQty).value()
            : numeral(originalEasyOnlineQty).value(),
        [warehouseCodes.amazonUs]:
          amazonUsQty >= 0
            ? numeral(amazonUsQty).value()
            : numeral(originalAmazonUsQty).value(),
      },
    });

    return batch
      .commit()
      .then(() => {
        return {
          status: 200,
        };
      })
      .catch(() => {
        return {
          code,
          type: codeType,
          status: 500,
        };
      });
  }

  const skuRef = firestore
    .collection('skus')
    .where('takealot.tsin', '==', code);

  const skuInfo = await skuRef.get().then(async (querySnapshot) => {
    let info = {};

    querySnapshot.forEach((doc) => {
      info = doc.data();
    });

    return info;
  });

  if (skuInfo.stockQuantities === undefined) {
    return {
      code,
      type: codeType,
      status: 500,
    };
  }

  const originalEasyOnlineQty =
    skuInfo.stockQuantities[warehouseCodes.easyOnline];
  const originalAmazonUsQty = skuInfo.stockQuantities[warehouseCodes.amazonUs];

  const batch = firestore.batch();
  const specificSkuRef = firestore.collection('skus').doc(skuInfo.sku);

  if (easyOnlineQty !== null) {
    console.log(easyOnlineQty);
    const currentTime = new Date().toISOString();
    const stockChangeRef = firestore
      .collection('skus')
      .doc(skuInfo.sku)
      .collection('stockChanges')
      .doc(`${currentTime}-eol`);

    batch.set(stockChangeRef, {
      warehouseId: warehouseCodes.easyOnline,
      date: new Date(currentTime),
      userName: `${userInfo.name} ${userInfo.surname}`,
      userId: userInfo.uid,
      change:
        numeral(easyOnlineQty).value() - numeral(originalEasyOnlineQty).value(),
    });
  }

  if (amazonUsQty !== null) {
    console.log(amazonUsQty);
    const currentTime = new Date().toISOString();
    const stockChangeRef = firestore
      .collection('skus')
      .doc(skuInfo.sku)
      .collection('stockChanges')
      .doc(`${currentTime}-aus`);

    batch.set(stockChangeRef, {
      warehouseId: warehouseCodes.amazonUs,
      date: new Date(currentTime),
      userName: `${userInfo.name} ${userInfo.surname}`,
      userId: userInfo.uid,
      change:
        numeral(amazonUsQty).value() - numeral(originalAmazonUsQty).value(),
    });
  }

  batch.update(specificSkuRef, {
    stockQuantities: {
      ...skuInfo.stockQuantities,
      [warehouseCodes.easyOnline]:
        easyOnlineQty >= 0
          ? numeral(easyOnlineQty).value()
          : numeral(originalEasyOnlineQty).value(),
      [warehouseCodes.amazonUs]:
        amazonUsQty >= 0
          ? numeral(amazonUsQty).value()
          : numeral(originalAmazonUsQty).value(),
    },
  });

  return batch
    .commit()
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch(() => {
      return {
        code,
        type: codeType,
        status: 500,
      };
    });
};
