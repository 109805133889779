import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';
import AddIcon from '@material-ui/icons/Add';
import {
  changeQueryStatus,
  closeSnackbar,
  fetchQueries,
  updateInitialPage,
  updatePageSize,
} from './slice';
import ContentWrapper from '../../../components/ContentWrapper';
import FiltersBar from './components/FiltersBar';
import getFormattedQueries from './logic';
import PageHeader from '../../../components/PageHeader';
import QueriesTable from './components/QueriesTable';

const breadcrumbs = [
  {
    label: 'Customer Relationship Management',
    link: '/crm',
  },
  {
    label: 'Queries',
    link: '/crm/queries',
  },
];

const ClientsQueriesBrowse = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState('ALL');
  const [clientFilter, setClientFilter] = useState('ALL');
  const [clientOptions, setClientOptions] = useState([]);
  const [formattedQueries, setFormattedQueries] = useState([]);
  const [typesFilter, setTypesFilter] = useState('ALL');
  const [assigneeFilter, setAssigneeFilter] = useState('ALL');
  const [assigneeOptions, setAssigneeOptions] = useState([]);

  const queries = useSelector((state) => state.clientsQueriesBrowse.queries);
  const feedback = useSelector((state) => state.clientsQueriesBrowse.feedback);
  const pageSize = useSelector((state) => state.clientsQueriesBrowse.pageSize);

  const initialPage = useSelector(
    (state) => state.clientsQueriesBrowse.initialPage
  );
  const queriesLoadingStatus = useSelector(
    (state) => state.clientsQueriesBrowse.loadingStatus.queries
  );
  const updateStatusLoadingStatus = useSelector(
    (state) => state.clientsQueriesBrowse.loadingStatus.updateStatus
  );

  useEffect(() => {
    if (queriesLoadingStatus !== 'PENDING') {
      dispatch(fetchQueries());
    }
  }, []);

  useEffect(() => {
    if (
      queriesLoadingStatus === 'PENDING' ||
      updateStatusLoadingStatus === 'PENDING'
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [queriesLoadingStatus, updateStatusLoadingStatus]);

  useEffect(() => {
    setFormattedQueries(
      getFormattedQueries(
        queries,
        searchQuery,
        statusFilter,
        typesFilter,
        clientFilter,
        assigneeFilter
      )
    );
  }, [
    queries,
    searchQuery,
    statusFilter,
    typesFilter,
    clientFilter,
    assigneeFilter,
  ]);

  useEffect(() => {
    setClientOptions(_.uniq(queries.map((info) => info.clientName)).sort());
    setAssigneeOptions(_.uniq(queries.map((info) => info.assigneeName)).sort());
  }, [queries, statusFilter]);

  return (
    <div>
      <PageHeader
        title="Queries"
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Browse"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
        actions={[
          {
            label: 'Add new query',
            icon: <AddIcon />,
            onClick: () => history.push('/crm/queries/add'),
          },
        ]}
      />
      {isLoading && <LinearProgress color="secondary" />}
      <ContentWrapper>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FiltersBar
              searchText={searchQuery}
              changeSearchText={setSearchQuery}
              requestsCount={formattedQueries.length}
              statusFilter={statusFilter}
              changeStatusFilter={setStatusFilter}
              clientFilter={clientFilter}
              changeClientFilter={setClientFilter}
              clientOptions={clientOptions}
              assigneeFilter={assigneeFilter}
              changeAssigneeFilter={setAssigneeFilter}
              assigneeOptions={assigneeOptions}
              typesFilter={typesFilter}
              changeTypeFilter={setTypesFilter}
            />
            <QueriesTable
              isLoading={isLoading}
              queries={formattedQueries}
              viewQuery={(queryId) =>
                history.push(`/crm/queries/browse/${queryId}`)
              }
              changeQueryStatus={(queryId, status) =>
                dispatch(changeQueryStatus(queryId, status))
              }
              pageSize={pageSize}
              initialPage={initialPage}
              updatePageSize={(newPageSize) =>
                dispatch(updatePageSize(newPageSize))
              }
              updateInitialPage={(newInitialPage) =>
                dispatch(updateInitialPage(newInitialPage))
              }
            />
          </Grid>
        </Grid>
      </ContentWrapper>
      <Snackbar
        open={feedback.isOpen}
        autoHideDuration={6000}
        onClose={() => dispatch(closeSnackbar())}
      >
        <Alert
          onClose={() => dispatch(closeSnackbar())}
          severity={feedback.type}
        >
          {feedback.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ClientsQueriesBrowse;
