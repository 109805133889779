import { fade, makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MaterialTable from 'material-table';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import red from '@material-ui/core/colors/red';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FiltersBar from '../FiltersBar';

const headerStyle = {
  backgroundColor: '#A7A5A5',
  color: '#171415',
};

const useStyles = makeStyles((theme) => ({
  loadingOverlay: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: fade(theme.palette.common.black, 0.8),
  },
  wrapperContent: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
  separator: {
    height: theme.spacing(2),
  },
  formControl: {
    width: '100%',
  },
  notifyImages: {
    color: red[400],
  },
}));

const ListingInfo = ({
  skus,
  updateSkus,
  updateField,
  setUpdateField,
  note,
  setNote,
  setSearchText,
  searchText,
}) => {
  const classes = useStyles();
  const getFieldList = () => {
    return [
      {
        value: 'NONE_SELECTED',
        label: 'Please select',
      },
      {
        value: 'TITLE',
        label: 'Title',
      },
      {
        value: 'SUBTITLE',
        label: 'Subtitle',
      },
      {
        value: 'DESCRIPTION',
        label: 'Description',
      },
      {
        value: 'BRAND',
        label: 'Brand',
      },
      {
        value: 'IMAGES',
        label: 'Images',
      },
    ].map((info) => (
      <MenuItem key={info.value} value={info.value}>
        {info.label}
      </MenuItem>
    ));
  };

  const columns = [
    {
      title: 'SKU',
      field: 'sku',
      editable: 'never',
    },
    {
      title: 'TSIN',
      field: 'tsin',
      editable: 'never',
    },
    {
      title: 'Product',
      field: 'product',
      editable: 'never',
    },
    {
      title: 'Change To',
      field: 'changeTo',
      hidden: updateField === 'IMAGES',
      render: (rowData) => {
        return (
          <TextField
            value={rowData.changeTo}
            onChange={(e) => {
              const changeTo = e.target.value;
              // eslint-disable-next-line react/prop-types
              const newSkus = skus.map((skuInfo) => {
                if (skuInfo.sku === rowData.sku) {
                  return {
                    ...skuInfo,
                    changeTo,
                  };
                }

                return skuInfo;
              });
              updateSkus(newSkus);
            }}
            type="string"
          />
        );
      },
    },
  ];

  return (
    <div className={classes.wrapperContent}>
      <Typography variant="h4" gutterBottom>
        Specify requirements
      </Typography>
      <Typography variant="body1">
        Please select the field to update and enter the new value for each SKU.
      </Typography>
      <div className={classes.separator} />
      <FormControl className={classes.formControl}>
        <InputLabel shrink id="field-update-select-label">
          Field
        </InputLabel>
        <Select
          labelId="field-update-select-label"
          id="field-update-select"
          value={updateField}
          onChange={(e) => setUpdateField(e.target.value)}
        >
          {getFieldList()}
        </Select>
      </FormControl>
      <div className={classes.separator} />
      {updateField === 'IMAGES' && (
        <Typography className={classes.notifyImages}>
          Please upload the images for the respective SKUs after the query is
          created.
        </Typography>
      )}
      <div className={classes.separator} />
      <TextField
        required
        label="General notes"
        fullWidth
        multiline
        rows={5}
        value={note}
        onChange={(e) => setNote(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <div className={classes.separator} />
      <FiltersBar
        searchText={searchText}
        changeSearchText={setSearchText}
        skuCount={skus.length}
      />
      <MaterialTable
        columns={columns}
        data={skus}
        options={{
          headerStyle,
          sorting: false,
          grouping: false,
          draggable: false,
          search: false,
          showTitle: false,
          toolbar: false,
        }}
        components={{
          OverlayLoading: () => (
            <div className={classes.loadingOverlay}>
              <CircularProgress color="primary" size={32} />
            </div>
          ),
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'SKUs not yet selected.',
          },
        }}
      />
    </div>
  );
};

ListingInfo.defaultProps = {
  skus: [],
  updateSkus: (skus) => console.log('Skus were updated to:', skus),
  updateField: 'NONE_SELECTED',
  setUpdateField: () => {},
  note: '',
  setNote: (newNote) => console.log('Note updated to:', newNote),
  searchText: '',
  setSearchText: () => {},
};

ListingInfo.propTypes = {
  skus: PropTypes.arrayOf(PropTypes.shape()),
  updateSkus: PropTypes.func,
  updateField: PropTypes.oneOf([
    'NONE_SELECTED',
    'IMAGES',
    'TITLE',
    'DESCRIPTION',
    'SUBTITLE',
    'BRAND',
  ]),
  setUpdateField: PropTypes.func,
  note: PropTypes.string,
  setNote: PropTypes.func,
  searchText: PropTypes.string,
  setSearchText: PropTypes.func,
};

export default ListingInfo;
