import { combineReducers } from 'redux';
import accountSlice from '../pages/Account/slice';
import clientsAddSlice from '../pages/CRM/ClientsAdd/slice';
import clientsBrowseSlice from '../pages/CRM/ClientsBrowse/slice';
import clientsDocumentsUploadSlice from '../pages/CRM/ClientsDocumentsUpload/slice';
import clientsEditSlice from '../pages/CRM/ClientsEdit/slice';
import clientsQueriesBrowseSlice from '../pages/CRM/ClientsQueriesBrowse/slice';
import clientsQueryDocumentsUploadSlice from '../pages/CRM/ClientsQueryDocumentsUpload/slice';
import clientsQueryEditSlice from '../pages/CRM/ClientsQueryEdit/slice';
import clientsQueryImageUploadSlice from '../pages/CRM/ClientsQueryImageUpload/slice';
import clientsQueryViewSlice from '../pages/CRM/ClientsQueryView/slice';
import clientsViewSlice from '../pages/CRM/ClientsView/slice';
import dealsAdjustmentsSlice from '../pages/Sales/DealsAdjustments/slice';
import dealsCreateSlice from '../pages/Sales/DealsCreate/slice';
import dealsSlice from '../pages/Sales/DealsBrowse/slice';
import dealsViewSlice from '../pages/Sales/DealsView/slice';
import financeStatementsSlice from '../pages/Finances/FinanceStatements/slice';
import listingsAddSlice from '../pages/Listings/ListingsAdd/slice';
import listingsAdjustPricingSlice from '../pages/Listings/ListingsAdjustPricing/slice';
import listingsAnalyticsSkuSlice from '../pages/Listings/ListingsAnalyticsSku/slice';
import listingsAnalyticsSlice from '../pages/Listings/ListingsAnalytics/slice';
import listingsBasicInfoSlice from '../pages/Listings/ListingsBasicInfo/slice';
import listingsCopyDashboardSlice from '../pages/Listings/ListingsCopyDashboard/slice';
import listingsCopyWritingSlice from '../pages/Listings/ListingsCopyWriting/slice';
import listingsEditSlice from '../pages/Listings/ListingsEdit/slice';
import listingsImageUploadSlice from '../pages/Listings/ListingsImageUpload/slice';
import listingsOnboardingSlice from '../pages/Listings/ListingsOnboarding/slice';
import listingsStockInfoSlice from '../pages/Listings/ListingsStockInfo/slice';
import listingsViewSlice from '../pages/Listings/ListingsView/slice';
import loginSlice from '../pages/Login/slice';
import logisticsReplenishmentsSlice from '../pages/Logistics/LogisticsReplenishments/slice';
import logisticsShipmentSlice from '../pages/Logistics/LogisticsShipment/slice';
import logisticsStorageSlice from '../pages/Logistics/LogisticsStorage/slice';
import maintenanceReportsSlice from '../pages/MaintenanceReports/slice';
import queryAddSlice from '../pages/CRM/ClientsQueryAdd/slice';
import presalesListingEditsAddSlice from '../pages/Presales/ListingEditsAdd/slice';
import presalesListingEditsBrowseSlice from '../pages/Presales/ListingEditsBrowse/slice';
import presalesPipelineViewSlice from '../pages/Presales/PipelineView/slice';
import presalesProductsBrowseSlice from '../pages/Presales/ProductsBrowse/slice';
import presalesProductsTakealotUpdateSlice from '../pages/Presales/ProductsTakealotUpdate/slice';
import presalesReportsBrowseSlice from '../pages/Presales/ReportsBrowse/slice';
import presalesOrdersBrowseSlice from '../pages/Presales/OrdersBrowse/slice';
import presalesSubmissionsAddSlice from '../pages/Presales/SubmissionsAdd/slice';
import presalesSubmissionsBrowseSlice from '../pages/Presales/SubmissionsBrowse/slice';
import presalesSuppliersAddSlice from '../pages/Presales/SuppliersAdd/slice';
import presalesSuppliersEditSlice from '../pages/Presales/SuppliersEdit/slice';
import presalesSuppliersUpdatePricingSlice from '../pages/Presales/SuppliersUpdatePricing/slice';
import presalesSuppliersUpdatePricingSettingsSlice from '../pages/Presales/SuppliersUpdatePricingSettings/slice';
import presalesSuppliersUpdateStockSlice from '../pages/Presales/SuppliersUpdateStock/slice';
import presalesSuppliersUpdateStockSettingsSlice from '../pages/Presales/SuppliersUpdateStockSettings/slice';
import presalesSuppliersBrowseSlice from '../pages/Presales/SuppliersBrowse/slice';
import presalesSuppliersDocumentsUploadSlice from '../pages/Presales/SuppliersDocumentsUpload/slice';
import presalesSuppliersViewSlice from '../pages/Presales/SuppliersView/slice';
import presalesSkuUploadSlice from '../pages/Presales/SkuBulkUpload/slice';
import presalesUpdatesBrowseSlice from '../pages/Presales/UpdatesBrowse/slice';
import shipmentAddSlice from '../pages/Logistics/ShipmentAdd/slice';
import shipmentEditSlice from '../pages/Logistics/ShipmentEdit/slice';
import shipmentsDocumentsUploadSlice from '../pages/Logistics/ShipmentsDocumentsUpload/slice';
import shipmentsViewSlice from '../pages/Logistics/ShipmentsView/slice';
import sourcedProductsAddSlice from '../pages/Sales/SourcedProductsAdd/slice';
import sourcedProductsBrowseSlice from '../pages/Sales/SourcedProductsBrowse/slice';
import sourcedProductsEditSlice from '../pages/Sales/SourcedProductsEdit/slice';
import sourcedProductsViewSlice from '../pages/Sales/SourcedProductsView/slice';
import stockRequestsBrowseSlice from '../pages/CRM/StockRequestsBrowse/slice';
import stockRequestsDocumentsUploadSlice from '../pages/CRM/StockRequestsDocumentsUpload/slice';
import stockRequestsViewSlice from '../pages/CRM/StockRequestsView/slice';
import suppliersBrowseSlice from '../pages/Sales/SuppliersBrowse/slice';
import suppliersDocumentsUploadSlice from '../pages/Sales/SuppliersDocumentsUpload/slice';
import suppliersEditSlice from '../pages/Sales/SuppliersEdit/slice';
import suppliersViewSlice from '../pages/Sales/SuppliersView/slice';
import usersAddSlice from '../pages/Users/UsersAdd/slice';
import usersBrowseSlice from '../pages/Users/UsersBrowse/slice';
import usersViewSlice from '../pages/Users/UsersView/slice';

const rootReducer = combineReducers({
  account: accountSlice,
  clientsAdd: clientsAddSlice,
  clientsBrowse: clientsBrowseSlice,
  clientsDocumentsUpload: clientsDocumentsUploadSlice,
  clientsEdit: clientsEditSlice,
  clientsQueriesBrowse: clientsQueriesBrowseSlice,
  clientsQueryDocumentsUpload: clientsQueryDocumentsUploadSlice,
  clientsQueryEdit: clientsQueryEditSlice,
  clientsQueryImageUpload: clientsQueryImageUploadSlice,
  clientsQueryView: clientsQueryViewSlice,
  clientsView: clientsViewSlice,
  deals: dealsSlice,
  dealsAdjustments: dealsAdjustmentsSlice,
  dealsCreate: dealsCreateSlice,
  dealsView: dealsViewSlice,
  financeStatements: financeStatementsSlice,
  listingsAdd: listingsAddSlice,
  listingsAdjustPricing: listingsAdjustPricingSlice,
  listingsAnalytics: listingsAnalyticsSlice,
  listingsAnalyticsSku: listingsAnalyticsSkuSlice,
  listingsBasicInfo: listingsBasicInfoSlice,
  listingsCopyDashboard: listingsCopyDashboardSlice,
  listingsCopyWriting: listingsCopyWritingSlice,
  listingsEdit: listingsEditSlice,
  listingsImageUpload: listingsImageUploadSlice,
  listingsOnboarding: listingsOnboardingSlice,
  listingsStockInfo: listingsStockInfoSlice,
  listingsView: listingsViewSlice,
  login: loginSlice,
  logisticsReplenishments: logisticsReplenishmentsSlice,
  logisticsShipment: logisticsShipmentSlice,
  logisticsStorage: logisticsStorageSlice,
  maintenanceReports: maintenanceReportsSlice,
  queryAdd: queryAddSlice,
  presalesListingEditsAdd: presalesListingEditsAddSlice,
  presalesListingEditsBrowse: presalesListingEditsBrowseSlice,
  presalesOrdersBrowse: presalesOrdersBrowseSlice,
  presalesPipelineView: presalesPipelineViewSlice,
  presalesProductsBrowse: presalesProductsBrowseSlice,
  presalesProductsTakealotUpdate: presalesProductsTakealotUpdateSlice,
  presalesReportsBrowse: presalesReportsBrowseSlice,
  presalesSkuUpload: presalesSkuUploadSlice,
  presalesSubmissionsAdd: presalesSubmissionsAddSlice,
  presalesSubmissionsBrowse: presalesSubmissionsBrowseSlice,
  presalesSuppliersAdd: presalesSuppliersAddSlice,
  presalesSuppliersBrowse: presalesSuppliersBrowseSlice,
  presalesSuppliersDocumentsUpload: presalesSuppliersDocumentsUploadSlice,
  presalesSuppliersEdit: presalesSuppliersEditSlice,
  presalesSuppliersUpdatePricing: presalesSuppliersUpdatePricingSlice,
  presalesSuppliersUpdatePricingSettings:
    presalesSuppliersUpdatePricingSettingsSlice,
  presalesSuppliersUpdateStock: presalesSuppliersUpdateStockSlice,
  presalesSuppliersUpdateStockSettings:
    presalesSuppliersUpdateStockSettingsSlice,
  presalesSuppliersView: presalesSuppliersViewSlice,
  presalesUpdatesBrowse: presalesUpdatesBrowseSlice,
  shipmentAdd: shipmentAddSlice,
  shipmentEdit: shipmentEditSlice,
  shipmentsDocumentsUpload: shipmentsDocumentsUploadSlice,
  shipmentsView: shipmentsViewSlice,
  sourcedProductsAdd: sourcedProductsAddSlice,
  sourcedProductsBrowse: sourcedProductsBrowseSlice,
  sourcedProductsEdit: sourcedProductsEditSlice,
  sourcedProductsView: sourcedProductsViewSlice,
  stockRequestsBrowse: stockRequestsBrowseSlice,
  stockRequestsDocumentsUpload: stockRequestsDocumentsUploadSlice,
  stockRequestsView: stockRequestsViewSlice,
  suppliersBrowse: suppliersBrowseSlice,
  suppliersDocumentsUpload: suppliersDocumentsUploadSlice,
  suppliersEdit: suppliersEditSlice,
  suppliersView: suppliersViewSlice,
  usersAdd: usersAddSlice,
  usersBrowse: usersBrowseSlice,
  usersView: usersViewSlice,
});

export default rootReducer;
