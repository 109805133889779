import { useState } from 'react';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';

const ClientSelector = ({
  clientFilter,
  changeClientFilter,
  clientOptions,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const getPrettyFilter = (filter) => {
    if (filter === 'ALL') {
      return 'All Clients';
    }
    return filter;
  };

  return (
    <>
      <Button
        aria-controls="clients-menu"
        aria-haspopup="true"
        size="large"
        startIcon={<BusinessCenterIcon />}
        onClick={openMenu}
      >
        {getPrettyFilter(clientFilter)}
      </Button>
      <Menu
        id="clients-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem
          onClick={() => {
            changeClientFilter('ALL');
            closeMenu();
          }}
        >
          All
        </MenuItem>
        {clientOptions.map((option) => (
          <MenuItem
            key={`filter-option-${option}`}
            onClick={() => {
              changeClientFilter(option);
              closeMenu();
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

ClientSelector.defaultProps = {
  clientFilter: 'ALL',
  changeClientFilter: (newFilter) =>
    console.log('User changes the client filter to:', newFilter),
  clientOptions: [],
};

ClientSelector.propTypes = {
  clientFilter: PropTypes.string,
  changeClientFilter: PropTypes.func,
  clientOptions: PropTypes.arrayOf(PropTypes.string),
};

export default ClientSelector;
