import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CopywritingSelector from './components/CopywritingSelector';
import ImagesSelector from './components/ImagesSelector';
import StockInfoSelector from './components/StockInfoSelector';
import SubmissionSelector from './components/SubmissionSelector';
import WeeksOutstandingSelector from './components/WeeksOutstandingSelector';

const useStyles = makeStyles((theme) => ({
  buttonStack: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  results: {
    margin: theme.spacing(1),
    color: grey[500],
  },
  separator: {
    width: theme.spacing(1),
  },
  wrapperButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  wrapperRoot: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
}));

const FiltersBar = ({
  searchText,
  changeSearchText,
  listingCount,
  copywritingFilter,
  changeCopywritingFilter,
  imagesFilter,
  changeImagesFilter,
  stockInfoFilter,
  changeStockInfoFilter,
  submissionFilter,
  changeSubmissionFilter,
  weeksOutstandingFilter,
  changeWeeksOutstandingFilter,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapperRoot}>
      <TextField
        placeholder="Search"
        value={searchText}
        onChange={(e) => changeSearchText(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <Typography className={classes.results} variant="body1" component="span">
        {`${listingCount} ${
          listingCount === 1 ? 'result' : 'results'
        } displayed`}
      </Typography>
      <div className={classes.buttonStack}>
        <div className={classes.wrapperButtons}>
          <WeeksOutstandingSelector
            weeksOutstandingFilter={weeksOutstandingFilter}
            changeWeeksOutstandingFilter={changeWeeksOutstandingFilter}
          />
        </div>
        <div className={classes.wrapperButtons}>
          <StockInfoSelector
            stockInfoFilter={stockInfoFilter}
            changeStockInfoFilter={changeStockInfoFilter}
          />
          <div className={classes.separator} />
          <CopywritingSelector
            copywritingFilter={copywritingFilter}
            changeCopywritingFilter={changeCopywritingFilter}
          />
          <div className={classes.separator} />
          <ImagesSelector
            imagesFilter={imagesFilter}
            changeImagesFilter={changeImagesFilter}
          />
          <div className={classes.separator} />
          <SubmissionSelector
            submissionFilter={submissionFilter}
            changeSubmissionFilter={changeSubmissionFilter}
          />
        </div>
      </div>
    </div>
  );
};

FiltersBar.defaultProps = {
  searchText: '',
  changeSearchText: (newValue) => `Search text was changed to '${newValue}'`,
  listingCount: 1,
  copywritingFilter: 'ALL',
  changeCopywritingFilter: (newFilter) =>
    console.log('User changes the copywriting filter to:', newFilter),
  imagesFilter: 'ALL',
  changeImagesFilter: (newFilter) =>
    console.log('User changes the images filter to:', newFilter),
  stockInfoFilter: 'ALL',
  changeStockInfoFilter: (newFilter) =>
    console.log('User changes the stock info filter to:', newFilter),
  submissionFilter: 'ALL',
  changeSubmissionFilter: (newFilter) =>
    console.log('User changes the submission filter to:', newFilter),
  weeksOutstandingFilter: 'ALL',
  changeWeeksOutstandingFilter: (newFilter) =>
    console.log('User changes the weeks outstanding filter to:', newFilter),
};

FiltersBar.propTypes = {
  searchText: PropTypes.string,
  changeSearchText: PropTypes.func,
  listingCount: PropTypes.number,
  copywritingFilter: PropTypes.string,
  changeCopywritingFilter: PropTypes.func,
  imagesFilter: PropTypes.string,
  changeImagesFilter: PropTypes.func,
  stockInfoFilter: PropTypes.string,
  changeStockInfoFilter: PropTypes.func,
  submissionFilter: PropTypes.string,
  changeSubmissionFilter: PropTypes.func,
  weeksOutstandingFilter: PropTypes.string,
  changeWeeksOutstandingFilter: PropTypes.func,
};

export default FiltersBar;
