import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import EmptyViewMessage from '../../../../../components/EmptyViewMessage';
import SkusTable from './components/SkusTable';
import FiltersBar from '../FiltersBar';

const useStyles = makeStyles((theme) => ({
  seperator: {
    height: theme.spacing(3),
  },
}));

const UnitsTab = ({ skus, platform, skuSearchText, setSKUSearch }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div>
      <FiltersBar
        searchText={skuSearchText}
        changeSearchText={setSKUSearch}
        skuCount={skus.length}
      />
      {skus.length === 0 ? (
        <div className={classes.root}>
          <EmptyViewMessage
            heading="No SKUs"
            message="Please edit the shipment to include SKUs."
          />
        </div>
      ) : (
        <SkusTable
          platform={platform}
          skus={skus.map((skuInfo) => {
            let unfrozenSku = {};
            unfrozenSku = {
              sku: skuInfo.sku,
              tsin: skuInfo.tsin,
              childAsin: skuInfo.childAsin,
              product: skuInfo.product,
              units: skuInfo.units,
              costPrice: skuInfo.costPrice,
              totalInsuranceValue: skuInfo.costPrice * skuInfo.units,
            };
            return unfrozenSku;
          })}
          viewSku={(sku) => history.push(`/listings/basic-info/browse/${sku}`)}
        />
      )}
    </div>
  );
};

UnitsTab.defaultProps = {
  skus: [],
  platform: 'TAKEALOT',
  skuSearchText: '',
  setSKUSearch: () => {},
};

UnitsTab.propTypes = {
  skus: PropTypes.arrayOf(PropTypes.shape({})),
  platform: PropTypes.oneOf(['AMAZON', 'TAKEALOT']),
  skuSearchText: PropTypes.string,
  setSKUSearch: PropTypes.func,
};

export default UnitsTab;
