import { useEffect, useState } from 'react';
import _ from 'lodash';
import { green, orange, red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import DateFnsUtils from '@date-io/moment';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  dayWithDotContainer: {
    position: 'relative',
  },
  completeDot: {
    position: 'absolute',
    height: 0,
    width: 0,
    border: '3px solid',
    borderRadius: 4,
    borderColor: green[500],
    right: '50%',
    transform: 'translateX(1px)',
    top: '80%',
  },
  incompleteDot: {
    position: 'absolute',
    height: 0,
    width: 0,
    border: '3px solid',
    borderRadius: 4,
    borderColor: orange[500],
    right: '50%',
    transform: 'translateX(1px)',
    top: '80%',
  },
  notStartedDot: {
    position: 'absolute',
    height: 0,
    width: 0,
    border: '3px solid',
    borderRadius: 4,
    borderColor: red[500],
    right: '50%',
    transform: 'translateX(1px)',
    top: '80%',
  },
  separator: {
    width: theme.spacing(1),
  },
  wrapperButtons: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  wrapperRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
  },
}));

const DatePicker = ({
  date,
  changeDate,
  reportSummary,
  reloadReportSummaries,
}) => {
  const classes = useStyles();

  const [selectedMonth, setSelectedMonth] = useState(date);
  const [completeDays, setCompleteDays] = useState([1, 2, 15]);
  const [incompleteDays, setIncompleteDays] = useState([1, 2, 15]);

  useEffect(() => {
    const newCompleteDays = [];
    const newIncompleteDays = [];

    const takealotProductSalesDays = {};
    const amazonDetailPageSalesAndTrafficByChildItemDays = {};

    _.keys(reportSummary).forEach((dateIdCode) => {
      const day = parseInt(dateIdCode.slice(0, 2), 10);
      const reportId = dateIdCode.slice(3);

      switch (reportId) {
        case 'takealot-product-sales':
          takealotProductSalesDays[day] = true;
          break;
        case 'amazon-detail-page-sales-and-traffic-by-child-item':
          amazonDetailPageSalesAndTrafficByChildItemDays[day] = true;
          break;
        default:
          console.log(reportId);
      }
    });

    _.times(moment(selectedMonth).daysInMonth(), (index) => {
      let timesFound = 0;
      if (takealotProductSalesDays[index + 1]) timesFound += 1;
      if (amazonDetailPageSalesAndTrafficByChildItemDays[index + 1])
        timesFound += 1;

      if (timesFound === 2) {
        newCompleteDays.push(index + 1);
      } else if (timesFound === 1) {
        newIncompleteDays.push(index + 1);
      }
    });
    setCompleteDays(newCompleteDays);
    setIncompleteDays(newIncompleteDays);
  }, [reportSummary]);

  const handleMonthChange = async (newDate) => {
    setSelectedMonth(newDate);
    await reloadReportSummaries(moment(newDate).format('MMYYYY'));
  };

  return (
    <div className={classes.wrapperRoot}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          variant="static"
          format="MM/DD/YYYY"
          id="date-picker"
          value={date}
          onMonthChange={handleMonthChange}
          renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
            const isComplete = completeDays.includes(day.date());
            const isIncomplete = incompleteDays.includes(day.date());

            if (day.isAfter(moment().subtract(1, 'day')) || !isInCurrentMonth) {
              return dayComponent;
            }
            if (isComplete) {
              return (
                <div className={classes.dayWithDotContainer}>
                  {dayComponent}
                  <div className={classes.completeDot} />
                </div>
              );
            }
            if (isIncomplete) {
              return (
                <div className={classes.dayWithDotContainer}>
                  {dayComponent}
                  <div className={classes.incompleteDot} />
                </div>
              );
            }
            return (
              <div className={classes.dayWithDotContainer}>
                {dayComponent}
                <div className={classes.notStartedDot} />
              </div>
            );
          }}
          onChange={(newDate) => changeDate(newDate.toDate())}
          maxDate={moment().subtract(1, 'day').toDate()}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

DatePicker.defaultProps = {
  date: new Date(),
  changeDate: (newValue) => `Date was changed to '${newValue}'`,
  reloadReportSummaries: (newValue) => `Month was changed to '${newValue}'`,
  reportSummary: {
    '01-takealot-product-sales': true,
  },
};

DatePicker.propTypes = {
  date: PropTypes.instanceOf(Date),
  changeDate: PropTypes.func,
  reloadReportSummaries: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  reportSummary: PropTypes.object,
};

export default DatePicker;
