import { useState } from 'react';
import Button from '@material-ui/core/Button';
import CategoryIcon from '@material-ui/icons/ViewHeadline';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';

const MetadataSelector = ({ metadataType, changeMetadataType, options }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const getMetadataLabel = (id) => {
    const metadataInfo = options.find((info) => info.id === id);
    if (!metadataInfo) {
      return 'Unknown';
    }

    return metadataInfo.label;
  };

  return (
    <>
      <Button
        aria-controls="metadata-menu"
        aria-haspopup="true"
        size="large"
        startIcon={<CategoryIcon />}
        onClick={openMenu}
      >
        {getMetadataLabel(metadataType)}
      </Button>
      <Menu
        id="metadata-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {options
          .map((info) => info)
          .map((info) => (
            <MenuItem
              key={info.id}
              onClick={() => {
                changeMetadataType(info.id);
                closeMenu();
              }}
            >
              {info.label}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

MetadataSelector.defaultProps = {
  metadataType: 'ALL',
  changeMetadataType: (newValue) =>
    `Metadata type was changed to '${newValue}'`,
  options: [
    {
      id: 'ALL',
      label: 'All Metadata',
    },
    {
      id: 'BARCODES',
      label: 'Barcodes',
    },
    {
      id: 'CATEGORIES',
      label: 'Categories',
    },
    {
      id: 'COPY',
      label: 'Copy',
    },
    {
      id: 'IMAGES',
      label: 'Images',
    },
    {
      id: 'MEASUREMENTS',
      label: 'Measurements',
    },
  ],
};

MetadataSelector.propTypes = {
  metadataType: PropTypes.oneOf([
    'ALL',
    'BARCODES',
    'CATEGORIES',
    'COPY',
    'MEASUREMENTS',
    'IMAGES',
  ]),
  changeMetadataType: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};

export default MetadataSelector;
