/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  getPriceChanges,
  getSkus,
  updateAmazonPrice,
  updateSku,
  updateTakealotPrice,
} from '../../../api/listingsAPI';

const initialState = {
  skus: [],
  loadingStatus: {
    skus: 'NOT_STARTED',
    priceChanges: 'NOT_STARTED',
    updateSku: 'NOT_STARTED',
  },
  pageSize: 5,
  initialPage: 0,
};

const listingsAdjustPricingSlice = createSlice({
  name: 'listingsAdjustPricing',
  initialState,
  reducers: {
    getSkusStarted(state) {
      state.loadingStatus.skus = 'PENDING';
    },
    getSkusSuccess(state, action) {
      state.skus = action.payload;
      state.loadingStatus.skus = 'COMPLETE';
    },
    getSkusFailed(state, action) {
      state.loadingStatus.skus = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getPriceChangesStarted(state) {
      state.loadingStatus.priceChanges = 'PENDING';
    },
    getPriceChangesSuccess(state, action) {
      state.priceChanges = action.payload;
      state.loadingStatus.priceChanges = 'COMPLETE';
    },
    getPriceChangesFailed(state, action) {
      state.loadingStatus.priceChanges = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    updateSkuStarted(state) {
      state.loadingStatus.updateSku = 'PENDING';
    },
    updateSkuSuccess(state, action) {
      state.skus = state.skus.map((info) => {
        if (info.sku === action.payload.sku) {
          return action.payload.newInfo;
        }
        return info;
      });
      state.loadingStatus.updateSku = 'COMPLETE';
    },
    updateSkuFailed(state, action) {
      state.loadingStatus.updateSku = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    changePageSize(state, action) {
      state.pageSize = action.payload;
    },
    changeInitialPage(state, action) {
      state.initialPage = action.payload;
    },
  },
});

export const {
  getSkusStarted,
  getSkusSuccess,
  getSkusFailed,
  getPriceChangesStarted,
  getPriceChangesSuccess,
  getPriceChangesFailed,
  updateSkuStarted,
  updateSkuSuccess,
  updateSkuFailed,
  changePageSize,
  changeInitialPage,
} = listingsAdjustPricingSlice.actions;

export const fetchSkus =
  (platform = 'NOT_SPECIFIED') =>
  async (dispatch) => {
    dispatch(getSkusStarted());
    const result = await getSkus(platform);

    if (result.status === 200) {
      dispatch(getSkusSuccess(result.skus));
    } else {
      dispatch(getSkusFailed(result.error));
    }
  };

export const fetchPriceChanges = (sku, platform) => async (dispatch) => {
  dispatch(getPriceChangesStarted());
  const result = await getPriceChanges(sku, platform);

  if (result.status === 200) {
    dispatch(getPriceChangesSuccess(result.changes));
  } else {
    dispatch(getPriceChangesFailed(result.error));
  }
};

export const changeSku = (sku, newInfo) => async (dispatch) => {
  dispatch(updateSkuStarted());
  const result = await updateSku(sku, newInfo);

  if (result.status === 200) {
    dispatch(updateSkuSuccess({ sku, newInfo }));
  } else {
    dispatch(updateSkuFailed(result.error));
  }
};

export const changeAmazonSku =
  (sku, oldInfo, newInfo, userInfo) => async (dispatch) => {
    dispatch(updateSkuStarted());
    const result1 = await updateSku(sku, newInfo);
    const result2 = await updateAmazonPrice(
      sku,
      oldInfo.amazon.wholesalePriceRands,
      oldInfo.amazon.retailPriceDollars,
      newInfo.amazon.wholesalePriceRands,
      newInfo.amazon.retailPriceDollars,
      userInfo
    );

    if (result1.status === 200 && result2.status === 200) {
      dispatch(updateSkuSuccess({ sku, newInfo }));
    } else if (result1.status === 500) {
      dispatch(updateSkuFailed(result1.error));
    } else {
      dispatch(updateSkuFailed(result2.error));
    }
  };

export const changeTakealotSku =
  (sku, oldInfo, newInfo, dateType, dateTime, userInfo) => async (dispatch) => {
    dispatch(updateSkuStarted());
    const result1 = await updateSku(sku, newInfo);
    const result2 = await updateTakealotPrice(
      sku,
      oldInfo.takealot.wholesalePriceRands,
      oldInfo.takealot.retailPriceRands,
      newInfo.takealot.wholesalePriceRands,
      newInfo.takealot.retailPriceRands,
      dateType,
      dateTime,
      userInfo
    );

    if (result1.status === 200 && result2.status === 200) {
      dispatch(updateSkuSuccess({ sku, newInfo }));
    } else if (result1.status === 500) {
      dispatch(updateSkuFailed(result1.error));
    } else {
      dispatch(updateSkuFailed(result2.error));
    }
  };

export const updatePageSize = (newPageSize) => (dispatch) => {
  dispatch(changePageSize(newPageSize));
};

export const updateInitialPage = (newInitialPage) => (dispatch) => {
  dispatch(changeInitialPage(newInitialPage));
};

export default listingsAdjustPricingSlice.reducer;
