import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  loadingWrapper: {
    padding: theme.spacing(8),
    textAlign: 'center',
  },
}));

const DealInfoCard = ({
  businessName,
  salesPerson,
  creationDate,
  lastUpdated,
  isLoading,
}) => {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader title="Details" />
      <Divider />
      <CardContent>
        {isLoading ? (
          <div className={classes.loadingWrapper}>
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <List>
            <ListItem>
              <ListItemText primary={businessName} secondary="Business Name" />
            </ListItem>
            <ListItem>
              <ListItemText primary={salesPerson} secondary="Sales Person" />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={moment(creationDate).format('D MMMM YYYY')}
                secondary="Creation Date"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={moment(lastUpdated).format('D MMMM YYYY')}
                secondary="Last Updated"
              />
            </ListItem>
          </List>
        )}
      </CardContent>
    </Card>
  );
};

DealInfoCard.defaultProps = {
  businessName: 'Madeup Inc',
  salesPerson: 'Jane Doe',
  creationDate: new Date(),
  lastUpdated: new Date(),
  isLoading: false,
};

DealInfoCard.propTypes = {
  businessName: PropTypes.string,
  salesPerson: PropTypes.string,
  creationDate: PropTypes.instanceOf(Date),
  lastUpdated: PropTypes.instanceOf(Date),
  isLoading: PropTypes.bool,
};

export default DealInfoCard;
