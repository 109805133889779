import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import moment from 'moment';
import RefreshIcon from '@material-ui/icons/Refresh';
import {
  closeFeedbackSnackbar,
  createShipments,
  fetchReplenishments,
  fetchReplenishmentSkus,
  loadNewRecommendations,
  updateInitialPage,
  updatePageSize,
  updateUnitsToSend,
  setSearchQuery,
  setDestinationFilter,
  setFormattedSkus,
  setClientFilter,
  setClientOptions,
  setWarningsFilter,
  setUnitsToZero,
} from './slice';
import { getSkus, getShipments, getTotals } from './logic';
import ContentWrapper from '../../../components/ContentWrapper';
import CreateShipmentsDialog from './components/CreateShipmentsDialog';
import EmptyViewMessage from '../../../components/EmptyViewMessage';
import FeedbackSnackbar from '../../../components/FeedbackSnackbar';
import getStyles from './styles';
import PageHeader from '../../../components/PageHeader';
import ReplenishmentFilterBar from './components/ReplenishmentFilterBar';
import ReplenishmentsTable from './components/ReplenishmentsTable';
import TotalsBar from '../../../components/TotalsBar';

const useStyles = makeStyles(getStyles);

const breadcrumbs = [
  {
    label: 'Logistics',
    link: '/logistics',
  },
  {
    label: 'Replenishments',
    link: '/logistics/replenishments',
  },
];

const LogisticsReplenishments = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedSkus, setSelectedSkus] = useState([]);
  const [isCreateShipmentsDialogOpen, setIsCreateShipmentsDialogOpen] =
    useState(false);

  const userInfo = useSelector((state) => state.account.userInfo);
  const replenishmentsList = useSelector(
    (state) => state.logisticsReplenishments.replenishmentList
  );
  const replenishmentSkus = useSelector(
    (state) => state.logisticsReplenishments.replenishmentSkus
  );
  const pageSize = useSelector(
    (state) => state.logisticsReplenishments.pageSize
  );
  const initialPage = useSelector(
    (state) => state.logisticsReplenishments.initialPage
  );
  const feedback = useSelector(
    (state) => state.logisticsReplenishments.feedback
  );
  const replenishmentLoadingStatus = useSelector(
    (state) => state.logisticsReplenishments.loadingStatus.replenishmentList
  );
  const replenishmentSkusLoadingStatus = useSelector(
    (state) => state.logisticsReplenishments.loadingStatus.replenishmentSkus
  );
  const updateUnitsToSendLoadingStatus = useSelector(
    (state) => state.logisticsReplenishments.loadingStatus.updateUnitsToSend
  );
  const createShipmentsLoadingStatus = useSelector(
    (state) => state.logisticsReplenishments.loadingStatus.createShipments
  );
  const searchQuery = useSelector(
    (state) => state.logisticsReplenishments.searchQuery
  );
  const destinationFilter = useSelector(
    (state) => state.logisticsReplenishments.destinationFilter
  );
  const formattedSkus = useSelector(
    (state) => state.logisticsReplenishments.formattedSkus
  );
  const clientFilter = useSelector(
    (state) => state.logisticsReplenishments.clientFilter
  );
  const clientOptions = useSelector(
    (state) => state.logisticsReplenishments.clientOptions
  );
  const warningsFilter = useSelector(
    (state) => state.logisticsReplenishments.warningsFilter
  );

  useEffect(() => {
    if (replenishmentLoadingStatus !== 'PENDING') {
      dispatch(fetchReplenishments(destinationFilter));
    }
  }, [destinationFilter]);

  useEffect(() => {
    if (
      replenishmentSkusLoadingStatus !== 'PENDING' &&
      replenishmentsList.id !== ''
    ) {
      dispatch(
        fetchReplenishmentSkus(replenishmentsList.id, destinationFilter)
      );
    }
  }, [replenishmentsList.id, destinationFilter]);

  useEffect(() => {
    if (
      replenishmentLoadingStatus === 'PENDING' ||
      replenishmentSkusLoadingStatus === 'PENDING' ||
      updateUnitsToSendLoadingStatus === 'PENDING' ||
      createShipmentsLoadingStatus === 'PENDING'
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [
    replenishmentLoadingStatus,
    replenishmentSkusLoadingStatus,
    updateUnitsToSendLoadingStatus,
    createShipmentsLoadingStatus,
  ]);

  useEffect(() => {
    if (replenishmentLoadingStatus === 'COMPLETE')
      dispatch(
        setFormattedSkus(getSkus(replenishmentSkus, searchQuery, clientFilter))
      );
  }, [replenishmentSkus, searchQuery, clientFilter]);

  useEffect(() => {
    dispatch(
      setClientOptions(
        _.uniq(
          getSkus(replenishmentSkus, '', 'ALL').map((info) => info.clientName)
        ).sort()
      )
    );
  }, [replenishmentSkus]);

  return (
    <div>
      <PageHeader
        title="Replenishments"
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Browse"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
        actions={[
          {
            label: 'Load new recommendations',
            icon: <RefreshIcon />,
            onClick: () => dispatch(loadNewRecommendations()),
          },
          {
            label: 'Create Shipments',
            icon: <AddIcon />,
            onClick: () => setIsCreateShipmentsDialogOpen(true),
          },
        ]}
      />
      {isLoading && <LinearProgress color="secondary" />}
      <ContentWrapper>
        <Grid container spacing={3}>
          {replenishmentLoadingStatus === 'COMPLETE' && (
            <Alert severity="info" className={classes.alert}>
              {`This list was created on ${moment(
                replenishmentsList.date
              ).format('D MMMM YYYY')} (${moment(
                replenishmentsList.date
              ).fromNow()}).`}
            </Alert>
          )}
          {replenishmentsList.isActioned && (
            <Alert severity="warning" className={classes.alert}>
              Shipments have been created based on this replenishment list.
              Please check the Shipments section before creating new shipments.
            </Alert>
          )}
          {!isLoading && (
            <Grid item xs={12}>
              <TotalsBar totals={getTotals(formattedSkus, destinationFilter)} />
            </Grid>
          )}
          {!isLoading && selectedSkus.length > 0 && <Grid item xs={9} />}
          {!isLoading && selectedSkus.length > 0 && (
            <Grid item xs={3}>
              <Button
                fullWidth
                variant="contained"
                onClick={() =>
                  dispatch(
                    setUnitsToZero(
                      replenishmentsList.id,
                      destinationFilter,
                      selectedSkus
                    )
                  )
                }
              >
                Set selected to zero
              </Button>
            </Grid>
          )}
          <Grid item xs={12}>
            <ReplenishmentFilterBar
              searchText={searchQuery}
              changeSearchText={(newSearchQuery) =>
                dispatch(setSearchQuery(newSearchQuery))
              }
              replenishmentCount={formattedSkus.length}
              destinationFilter={destinationFilter}
              changeDestinationFilter={(newDestinationFilter) => {
                dispatch(setDestinationFilter(newDestinationFilter));
                dispatch(setSearchQuery(''));
                dispatch(setClientFilter('ALL'));
                dispatch(setWarningsFilter('ALL'));
              }}
              clientFilter={clientFilter}
              changeClientFilter={(newClientFilter) =>
                dispatch(setClientFilter(newClientFilter))
              }
              clientOptions={clientOptions}
              warningsFilter={warningsFilter}
              changeWarningsFilter={(newWarningsFilter) =>
                dispatch(setWarningsFilter(newWarningsFilter))
              }
            />
            {!isLoading && Object.keys(replenishmentsList).length === 0 && (
              <EmptyViewMessage
                heading="No Replenishments Available"
                message="Please adjust the filters."
              />
            )}
            {((!isLoading &&
              Object.keys(replenishmentsList).length !== 0 &&
              replenishmentSkusLoadingStatus !== 'NOT_STARTED') ||
              updateUnitsToSendLoadingStatus === 'PENDING') && (
              <ReplenishmentsTable
                warningsFilter={warningsFilter}
                destinationFilter={destinationFilter}
                skus={formattedSkus}
                isLoading={isLoading}
                selectedSkus={selectedSkus}
                updateSelectedSkus={setSelectedSkus}
                updatePageSize={(newPageSize) =>
                  dispatch(updatePageSize(newPageSize))
                }
                updateInitialPage={(newInitialPage) =>
                  dispatch(updateInitialPage(newInitialPage))
                }
                pageSize={pageSize}
                initialPage={initialPage}
                changeUnits={(destination, sku, unitsToSend, skuInfo) =>
                  dispatch(
                    updateUnitsToSend(
                      replenishmentsList.id,
                      destination,
                      sku,
                      unitsToSend,
                      skuInfo
                    )
                  )
                }
                viewSku={(sku) =>
                  history.push(`/listings/basic-info/browse/${sku}`)
                }
              />
            )}
          </Grid>
        </Grid>
      </ContentWrapper>
      <CreateShipmentsDialog
        shipments={getShipments(
          replenishmentSkus,
          userInfo.uid,
          `${userInfo.name} ${userInfo.surname}`,
          destinationFilter
        )}
        isOpen={isCreateShipmentsDialogOpen}
        createShipments={(selectedShipments) => {
          dispatch(createShipments(selectedShipments, replenishmentsList.id));
          setIsCreateShipmentsDialogOpen(false);
        }}
        closeDialog={() => setIsCreateShipmentsDialogOpen(false)}
      />
      <FeedbackSnackbar
        isOpen={feedback.isOpen}
        type={feedback.type}
        message={feedback.message}
        closeSnackbar={() => dispatch(closeFeedbackSnackbar())}
      />
    </div>
  );
};

export default LogisticsReplenishments;
