/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  getSupplier,
  uploadDocuments,
} from '../../../api/presalesSuppliersAPI';

const initialState = {
  supplierInfo: {},
  loadingStatus: {
    supplierInfo: 'NOT_STARTED',
  },
  uploadTransfers: {},
  documentUploadCounts: {
    total: 0,
    success: 0,
    failed: 0,
  },
  feedback: {
    isOpen: false,
    type: undefined,
    message: '',
  },
};

const presalesSuppliersDocumentsUploadSlice = createSlice({
  name: 'presalesSuppliersDocumentsUpload',
  initialState,
  reducers: {
    setTotalDocumentUploadCount(state, action) {
      state.documentUploadCounts.total = action.payload;
    },
    uploadDocumentSuccess(state) {
      state.documentUploadCounts.success += 1;
    },
    uploadDocumentFailed(state) {
      state.documentUploadCounts.failed += 1;
    },
    fetchSupplierInfoStarted(state) {
      state.loadingStatus.supplierInfo = 'PENDING';
    },
    fetchSupplierInfoSuccess(state, action) {
      state.supplierInfo = action.payload;
      state.loadingStatus.supplierInfo = 'COMPLETE';
    },
    fetchSupplierInfoFailed(state, action) {
      state.loadingStatus.supplierInfo = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    updateUploadBytes(state, action) {
      state.uploadTransfers = {
        ...state.uploadTransfers,
        [action.payload.docId]: {
          totalBytes: action.payload.totalBytes,
          transferredBytes: action.payload.transferredBytes,
        },
      };
    },
    resetUploadBytes(state) {
      state.uploadTotalBytes = 0;
      state.uploadTransferredBytes = 0;
    },
    resetState() {
      return initialState;
    },
  },
});

export const {
  setTotalDocumentUploadCount,
  uploadDocumentSuccess,
  uploadDocumentFailed,
  fetchSupplierInfoSuccess,
  fetchSupplierInfoFailed,
  fetchSupplierInfoStarted,
  resetState,
  updateUploadBytes,
  resetUploadBytes,
} = presalesSuppliersDocumentsUploadSlice.actions;

export default presalesSuppliersDocumentsUploadSlice.reducer;

export const uploadNewDocument =
  (clientId, doc, tag, description, uploaderId, uploaderName) =>
  async (dispatch) => {
    await uploadDocuments(
      clientId,
      doc,
      tag,
      description,
      uploaderId,
      uploaderName,
      (docId, totalBytes, transferredBytes) =>
        dispatch(updateUploadBytes({ docId, totalBytes, transferredBytes })),
      () => dispatch(uploadDocumentSuccess()),
      () => dispatch(uploadDocumentFailed())
    );
    dispatch(resetUploadBytes());
  };

export const fetchSupplierInfo = (supplierId) => async (dispatch) => {
  dispatch(fetchSupplierInfoStarted());
  const result = await getSupplier(supplierId);

  if (result.status === 200) {
    dispatch(fetchSupplierInfoSuccess(result.supplierInfo));
  } else {
    dispatch(fetchSupplierInfoFailed({ message: result.error }));
  }
};
