/* eslint-disable react/prop-types */
import { fade } from '@material-ui/core/styles/colorManipulator';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';

const headerStyle = {
  backgroundColor: '#A7A5A5',
  color: '#171415',
};

const useStyles = makeStyles((theme) => ({
  loadingOverlay: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: fade(theme.palette.common.black, 0.8),
  },
  wrapperContent: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

const SkuConfirmationTable = ({ skus }) => {
  const classes = useStyles();

  const columns = [
    {
      title: 'SKU',
      field: 'sku',
      align: 'center',
      render: (rowData) => (rowData.sku === '' ? '' : rowData.sku),
    },
    {
      title: 'TSIN',
      field: 'tsin',
      align: 'center',
      render: (rowData) => (rowData.tsin === null ? '' : rowData.tsin),
    },
    {
      title: 'EasyOnline',
      field: 'easyOnline',
      type: 'numeric',
      align: 'center',
    },
    {
      title: 'Amazon US',
      field: 'amazonUs',
      type: 'numeric',
      align: 'center',
    },
  ];

  const getTableData = () => {
    return skus.map((info) => ({
      sku: info.sku,
      tsin: info.tsin,
      easyOnline: info.easyOnline,
      amazonUs: info.amazonUs,
    }));
  };

  return (
    <div className={classes.wrapperContent}>
      <MaterialTable
        columns={columns}
        data={getTableData()}
        options={{
          headerStyle,
          actionsColumnIndex: -1,
          sorting: false,
          grouping: false,
          draggable: false,
          search: false,
          showTitle: false,
          toolbar: false,
        }}
        components={{
          OverlayLoading: () => (
            <div className={classes.loadingOverlay}>
              <CircularProgress color="primary" size={32} />
            </div>
          ),
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'No skus uploaded.',
          },
        }}
      />
    </div>
  );
};

SkuConfirmationTable.defaultProps = {
  skus: [],
};

SkuConfirmationTable.propTypes = {
  skus: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string,
      tsin: PropTypes.number,
      easyOnline: PropTypes.number,
      amazonUs: PropTypes.number,
    })
  ),
};

export default SkuConfirmationTable;
