/* eslint-disable react/prop-types */
import { fade, makeStyles } from '@material-ui/core';
import MaterialTable from 'material-table';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

const headerStyle = {
  backgroundColor: '#A7A5A5',
  color: '#171415',
};

const useStyles = makeStyles((theme) => ({
  loadingOverlay: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: fade(theme.palette.common.black, 0.8),
  },
  wrapperContent: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

const SKUUnitTable = ({ skus, platform, updateSkus }) => {
  const classes = useStyles();

  const columns = [
    {
      title: 'SKU',
      field: 'sku',
      editable: 'never',
    },
    {
      title: platform === 'TAKEALOT' ? 'TSIN' : 'Child ASIN',
      field: platform === 'TAKEALOT' ? 'tsin' : 'childAsin',
      editable: 'never',
    },
    {
      title: 'Product',
      field: 'product',
      editable: 'never',
    },
    {
      title: 'Units',
      field: 'units',
      render: (rowData) => {
        return (
          <TextField
            value={rowData.units}
            onChange={(e) => {
              updateSkus(
                skus.map((skuInfo) => {
                  const units = Number.isNaN(parseFloat(e.target.value))
                    ? 0
                    : parseFloat(e.target.value);

                  if (skuInfo.sku === rowData.sku) {
                    return {
                      ...skuInfo,
                      units,
                    };
                  }

                  return skuInfo;
                })
              );
            }}
            min={0}
            type="number"
          />
        );
      },
    },
  ];

  return (
    <div className={classes.wrapperContent}>
      <MaterialTable
        columns={columns}
        data={skus}
        options={{
          headerStyle,
          sorting: false,
          grouping: false,
          draggable: false,
          search: false,
          showTitle: false,
          toolbar: false,
          paging: false,
        }}
        components={{
          OverlayLoading: () => (
            <div className={classes.loadingOverlay}>
              <CircularProgress color="primary" size={32} />
            </div>
          ),
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'SKUs not yet selected.',
          },
        }}
      />
    </div>
  );
};

SKUUnitTable.defaultProps = {
  skus: [],
  platform: 'TAKEALOT',
  updateSkus: (skus) => console.log('Skus were updated to:', skus),
};

SKUUnitTable.propTypes = {
  skus: PropTypes.arrayOf(PropTypes.shape()),
  platform: PropTypes.oneOf(['AMAZON', 'TAKEALOT']),
  updateSkus: PropTypes.func,
};

export default SKUUnitTable;
