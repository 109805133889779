import { useEffect, useState } from 'react';
import _ from 'lodash';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {
  fetchSkuInfo,
  fetchWarehouses,
  initiateStateReset,
  fetchTemplate,
  createCopy,
} from './slice';
import { getSkuName, getLastVersion } from './logic';
import takealotCategoryData from './takealotCategories';
import ContentWrapper from '../../../components/ContentWrapper';
import PageHeader from '../../../components/PageHeader';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const useStyles = makeStyles((theme) => ({
  actionsBar: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  actionButtonSeparator: {
    width: theme.spacing(2),
  },
  alert: {
    marginBottom: theme.spacing(3),
  },
  editor: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1),
  },
  editorWrapper: {
    marginTop: theme.spacing(3),
  },
  formControl: {
    width: '100%',
  },
  formWrapper: {
    padding: `${theme.spacing(4)}px ${theme.spacing(1)}px`,
  },
  helperText: {
    color: theme.palette.primary.light,
  },
  link: {
    color: theme.palette.common.white,
    '&:hover': {
      textDecoration: 'none',
    },
  },
  separator: {
    height: theme.spacing(2),
  },
  wrapperStep: {
    padding: `${theme.spacing(4)}px 0px`,
  },
  wrapperWizard: {
    width: '100%',
    padding: theme.spacing(4),
  },
}));

const breadcrumbs = [
  {
    label: 'Listings',
    link: '/listings',
  },
  {
    label: 'Copy Writing',
    link: '/listings/copy-writing',
  },
];

const ListingsCopyWriting = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { sku } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const [descriptionEditorState, setDescriptionEditorState] = useState(
    EditorState.createEmpty()
  );
  const [whatsInTheBoxEditorState, setWhatsInTheBoxEditorState] = useState(
    EditorState.createEmpty()
  );
  const [platform, setPlatform] = useState('TAKEALOT');
  const [department, setDepartment] = useState('');
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [description, setDescription] = useState('');
  const [rawDescription, setRawDescription] = useState('');
  const [whatsInTheBox, setWhatsInTheBox] = useState('');
  const [rawWhatsInTheBox, setRawWhatsInTheBox] = useState('');
  const [supplementaryInfoLink, setSupplementaryInfoLink] = useState('');
  const [errorText, setErrorText] = useState({
    title: '',
    subtitle: '',
  });
  const [fieldValid, setfieldValid] = useState({
    title: false,
    subtitle: false,
  });

  const [validation, setValidation] = useState({
    isErrorShowing: false,
    message: '',
    errorStep: 0,
  });
  const [copyType, setCopyType] = useState('NEW');
  const [copyExists, setCopyExists] = useState(false);
  const [lastCopy, setLastCopy] = useState('');
  const [copyVersion, setCopyVersion] = useState(1);
  const warehousesLoadingStatus = useSelector(
    (state) => state.listingsCopyWriting.loadingStatus.warehouses
  );
  const updateLoadingStatus = useSelector(
    (state) => state.listingsCopyWriting.loadingStatus.update
  );
  const skuInfoLoadingStatus = useSelector(
    (state) => state.listingsCopyWriting.loadingStatus.skuInfo
  );
  const isUserRedirectedToBrowse = useSelector(
    (state) => state.listingsCopyWriting.isUserRedirectedToBrowse
  );
  const skuInfo = useSelector((state) => state.listingsCopyWriting.skuInfo);
  const templateInfo = useSelector(
    (state) => state.listingsCopyWriting.templateInfo
  );
  const userInfo = useSelector((state) => state.account.userInfo);
  const templateLoadingStatus = useSelector(
    (state) => state.listingsCopyWriting.loadingStatus.template
  );
  const copyLoadingStatus = useSelector(
    (state) => state.listingsCopyWriting.copyCreation
  );
  const copies = useSelector((state) => state.listingsView.copiesList);

  const fieldValidation = (name, { length }) => {
    let minCount = 0;
    let maxCount;
    if (name === 'title') {
      minCount = 65;
      maxCount = 75;
    } else {
      minCount = 90;
      maxCount = 120;
    }

    if (length < minCount) {
      setfieldValid((prevState) => ({
        ...prevState,
        [name]: false,
      }));
      setErrorText((prevState) => ({
        ...prevState,
        [name]: ` (must be at least ${minCount} characters)`,
      }));
    } else if (length === maxCount) {
      setErrorText((prevState) => ({
        ...prevState,
        [name]: ' (maximum number of characters reached)',
      }));
    } else {
      setErrorText((prevState) => ({
        ...prevState,
        [name]: '',
      }));
      setfieldValid((prevState) => ({
        ...prevState,
        [name]: true,
      }));
    }
  };

  useEffect(() => {
    dispatch(fetchSkuInfo(sku));
    if (warehousesLoadingStatus !== 'COMPLETE') {
      dispatch(fetchWarehouses());
    }
    if (copies.length > 0) {
      setCopyExists(true);
      setLastCopy(getLastVersion(copies));
    }
  }, []);

  useEffect(() => {
    setDescription(
      draftToHtml(convertToRaw(descriptionEditorState.getCurrentContent()))
    );
    setRawDescription(
      descriptionEditorState.getCurrentContent().getPlainText()
    );
  }, [descriptionEditorState]);

  useEffect(() => {
    setWhatsInTheBox(
      draftToHtml(convertToRaw(whatsInTheBoxEditorState.getCurrentContent()))
    );
    setRawWhatsInTheBox(
      whatsInTheBoxEditorState.getCurrentContent().getPlainText()
    );
  }, [whatsInTheBoxEditorState]);

  useEffect(() => {
    if (!copyExists) {
      if (platform === 'AMAZON' && skuInfo.amazon) {
        if (skuInfo.amazon.categories) {
          setDepartment(skuInfo.amazon.categories.department);
          setCategory(skuInfo.amazon.categories.category);
          setSubCategory(skuInfo.amazon.categories.subCategory);
        }
      } else if (skuInfo.takealot && skuInfo.takealot.categories) {
        setDepartment(skuInfo.takealot.categories.department);
        setCategory(skuInfo.takealot.categories.category);
        setSubCategory(skuInfo.takealot.categories.subCategory);
      } else {
        setDepartment('');
        setCategory('');
        setSubCategory('');
      }
      setTitle(
        getSkuName(skuInfo.brand, skuInfo.productName, skuInfo.variation)
      );
      fieldValidation('title', title);
    }
    setSupplementaryInfoLink(skuInfo.supplementaryInfoLink || '');
  }, [skuInfo]);

  useEffect(() => {
    if (!copyExists) {
      const blocksFromHtml = htmlToDraft(
        '<p>Intro paragraphs here</p><p>Key Features:</p><ul><li>Highlight important points...</li></ul><p>Another Section:</p><ul><li>Explain product details...</li></ul>'
      );
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      setDescriptionEditorState(EditorState.createWithContent(contentState));
    }
  }, [skuInfo]);

  useEffect(() => {
    if (!copyExists) {
      const blocksFromHtml = htmlToDraft(
        `<ul><li>1x ${skuInfo.productName}</li></ul>`
      );
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      setWhatsInTheBoxEditorState(EditorState.createWithContent(contentState));
    }
  }, [skuInfo]);

  useEffect(() => {
    if (
      updateLoadingStatus === 'PENDING' ||
      skuInfoLoadingStatus === 'PENDING' ||
      warehousesLoadingStatus === 'PENDING' ||
      templateLoadingStatus === 'PENDING' ||
      copyLoadingStatus === 'PENDING'
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [
    updateLoadingStatus,
    skuInfoLoadingStatus,
    warehousesLoadingStatus,
    templateLoadingStatus,
    copyLoadingStatus,
  ]);

  useEffect(() => {
    if (isUserRedirectedToBrowse) {
      dispatch(initiateStateReset());
      history.push(`/listings/basic-info/browse/${sku}`);
    }
  }, [isUserRedirectedToBrowse]);

  useEffect(() => {
    if (templateLoadingStatus === 'COMPLETE' && !copyExists) {
      setSubtitle(templateInfo.subtitle);
      fieldValidation('subtitle', templateInfo.subtitle);
      const { contentBlocks, entityMap } = htmlToDraft(
        templateInfo.description
      );
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      setDescriptionEditorState(EditorState.createWithContent(contentState));
      setCopyType('TEMPLATE');
    }
  }, [templateInfo]);

  useEffect(() => {
    if (lastCopy !== '') {
      setCategory(lastCopy.categories.category);
      setSubCategory(lastCopy.categories.subCategory);
      setDepartment(lastCopy.categories.department);
      setTitle(lastCopy.copy.title);
      fieldValidation('title', lastCopy.copy.subtitle);
      setSubtitle(lastCopy.copy.subtitle);
      fieldValidation('subtitle', lastCopy.copy.subtitle);
      const { contentBlocks, entityMap } = htmlToDraft(
        lastCopy.copy.description
      );
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      setDescriptionEditorState(EditorState.createWithContent(contentState));
      setCopyType(lastCopy.type);
      setCopyVersion(lastCopy.version + 1);
      setActiveStep(1);
    }
  }, [lastCopy]);

  useEffect(() => {
    if (lastCopy !== '') {
      const { contentBlocks, entityMap } = htmlToDraft(
        lastCopy.copy.whatsInTheBox
      );
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      setWhatsInTheBoxEditorState(EditorState.createWithContent(contentState));
    }
  }, [lastCopy]);

  useEffect(() => {
    if (
      department &&
      department.length !== 0 &&
      category &&
      category.length !== 0 &&
      subCategory &&
      subCategory.length !== 0 &&
      !copyExists
    ) {
      if (!copyExists) {
        dispatch(fetchTemplate(department, category, subCategory));
      }
      const blocksFromHtml = htmlToDraft(
        '<p>Intro paragraphs here</p><p>Key Features:</p><ul><li>Highlight important points...</li></ul><p>Another Section:</p><ul><li>Explain product details...</li></ul>'
      );
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      setDescriptionEditorState(EditorState.createWithContent(contentState));
      setSubtitle('');
      setCopyType('NEW');
    }
  }, [department, category, subCategory]);

  const getDepartments = () => {
    return _.uniq(takealotCategoryData.map((data) => data.department))
      .filter((data) => data !== '')
      .sort((departmentA, departmentB) => {
        if (departmentA > departmentB) return +1;
        if (departmentA < departmentB) return -1;
        return 0;
      });
  };

  const getCategories = () => {
    return _.uniq(
      takealotCategoryData
        .filter((data) => data.department === department)
        .map((data) => data.category)
    ).sort((categoryA, categoryB) => {
      if (categoryA > categoryB) return +1;
      if (categoryA < categoryB) return -1;
      return 0;
    });
  };

  const getSubCategories = () => {
    return _.uniq(
      takealotCategoryData
        .filter(
          (data) => data.department === department && data.category === category
        )
        .map((data) => data.subCategory)
    ).sort((subCategoryA, subCategoryB) => {
      if (subCategoryA > subCategoryB) return +1;
      if (subCategoryA < subCategoryB) return -1;
      return 0;
    });
  };

  const onFieldChange = (event) => {
    const { id, value } = event.target;
    if (id === 'title') {
      setTitle(value);
    } else if (id === 'subtitle') {
      setSubtitle(value);
    }
    fieldValidation(id, value);
  };

  const onNextClick = () => {
    switch (activeStep) {
      case 0:
        if (
          platform === undefined ||
          department === undefined ||
          category === undefined ||
          subCategory === undefined
        ) {
          setValidation({
            isErrorShowing: true,
            message: 'Please complete all required fields.',
            errorStep: 0,
          });
        } else {
          setValidation({
            isErrorShowing: false,
            message: '',
            errorStep: 0,
          });
          setActiveStep(1);
        }
        break;
      case 1:
        if (
          title === '' ||
          fieldValid.title === false ||
          subtitle === '' ||
          fieldValid.subtitle === false ||
          rawDescription === '' ||
          description === '' ||
          rawWhatsInTheBox === '' ||
          whatsInTheBox === ''
        ) {
          setValidation({
            isErrorShowing: true,
            message: 'Please complete all required fields.',
            errorStep: 1,
          });
        } else {
          const currentTime = new Date();
          dispatch(
            createCopy({
              creationDate: currentTime,
              copywriterId:
                lastCopy === '' ? userInfo.uid : lastCopy.copywriterId,
              copywriterName:
                lastCopy === ''
                  ? `${userInfo.name} ${userInfo.surname}`
                  : lastCopy.copywriterName,
              sku,
              status: 'AWAITING_REVIEW',
              type: copyType,
              version: copyVersion,
              categories: {
                category,
                department,
                subCategory,
              },
              copy: {
                title,
                subtitle,
                description,
                whatsInTheBox,
              },
            })
          );
          setValidation({
            isErrorShowing: false,
            message: '',
            errorStep: 1,
          });
        }
        break;
      default:
        console.log('Unknown step');
        break;
    }
  };

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <div className={classes.wrapperStep}>
            <Typography variant="h4" gutterBottom>
              Enter basic details
            </Typography>
            <Typography variant="body1">
              Please select the platform and category information.
            </Typography>
            <form className={classes.formWrapper}>
              <FormControl className={classes.formControl} required>
                <InputLabel shrink id="platform-select-label">
                  Platform
                </InputLabel>
                <Select
                  labelId="platform-select-label"
                  id="platform-select"
                  value={platform}
                  onChange={(e) => setPlatform(e.target.value)}
                >
                  <MenuItem value="TAKEALOT">Takealot</MenuItem>
                </Select>
              </FormControl>
              <div className={classes.separator} />
              <FormControl className={classes.formControl} required>
                <InputLabel id="department-select-label">Department</InputLabel>
                <Select
                  labelId="department-select-label"
                  id="department-select"
                  label="Department"
                  value={department}
                  onChange={(e) => {
                    setDepartment(e.target.value);
                    setCategory(undefined);
                    setSubCategory(undefined);
                  }}
                >
                  {getDepartments().map((departmentItem) => (
                    <MenuItem
                      key={`department-${departmentItem}`}
                      value={departmentItem}
                    >
                      {departmentItem}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className={classes.separator} />
              <FormControl className={classes.formControl} required>
                <InputLabel id="category-select-label">Category</InputLabel>
                <Select
                  labelId="category-select-label"
                  id="category-select"
                  disabled={department === undefined}
                  value={category}
                  onChange={(e) => {
                    setCategory(e.target.value);
                    setSubCategory(undefined);
                  }}
                >
                  {getCategories().map((categoryItem) => (
                    <MenuItem
                      key={`category-${categoryItem}`}
                      value={categoryItem}
                    >
                      {categoryItem}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className={classes.separator} />
              <FormControl className={classes.formControl} required>
                <InputLabel id="subcategory-select-label">
                  Sub-Category
                </InputLabel>
                <Select
                  labelId="subcategory-select-label"
                  id="subcategory-select"
                  disabled={category === undefined}
                  value={subCategory}
                  onChange={(e) => setSubCategory(e.target.value)}
                >
                  {getSubCategories().map((categoryItem) => (
                    <MenuItem
                      key={`category-${categoryItem}`}
                      value={categoryItem}
                    >
                      {categoryItem}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className={classes.separator} />
            </form>
            {copyType === 'NEW' ? (
              <Alert className={classes.alert} severity="info">
                New copy to be drafted.
              </Alert>
            ) : (
              <Alert className={classes.alert} severity="success">
                Using pre-existing copy as starting point.
              </Alert>
            )}
            {validation.isErrorShowing && validation.errorStep === 0 && (
              <Alert className={classes.alert} severity="error">
                {validation.message}
              </Alert>
            )}
          </div>
        );
      case 1:
        return (
          <div className={classes.wrapperStep}>
            <Typography variant="h4" gutterBottom>
              Draft listing copy
            </Typography>
            <Typography variant="body1">
              Please complete all fields below.
            </Typography>
            <form className={classes.formWrapper}>
              <TextField
                required
                id="title"
                label="Title"
                fullWidth
                value={title}
                onChange={(e) => onFieldChange(e)}
                InputLabelProps={{
                  shrink: true,
                }}
                error={!fieldValid.title}
                helperText={`${title.length} characters ${errorText.title}`}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
                inputProps={{ maxLength: 75 }}
              />
              <div className={classes.separator} />
              <TextField
                required
                id="subtitle"
                label="Subtitle"
                fullWidth
                value={subtitle}
                onChange={(e) => onFieldChange(e)}
                InputLabelProps={{
                  shrink: true,
                }}
                error={!fieldValid.subtitle}
                helperText={`${subtitle.length} characters ${errorText.subtitle}`}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
                inputProps={{
                  maxLength: 120,
                  pattern: '.{0,120}',
                }}
              />
              <div className={classes.separator} />
              <FormControl className={classes.formControl} required>
                <InputLabel id="description-input-label">
                  Description
                </InputLabel>
                <Editor
                  labelId="description-input-label"
                  id="description-input"
                  editorState={descriptionEditorState}
                  wrapperClassName={classes.editorWrapper}
                  editorClassName={classes.editor}
                  onEditorStateChange={setDescriptionEditorState}
                  toolbar={{
                    options: ['list', 'history'],
                  }}
                />
                <FormHelperText
                  className={classes.helperText}
                  id="description-input-helper-text"
                >
                  {`${rawDescription.length} characters`}
                </FormHelperText>
              </FormControl>
              <div className={classes.separator} />
              <FormControl className={classes.formControl} required>
                <InputLabel id="whats-in-the-box-input-label">
                  What&apos;s In The Box?
                </InputLabel>
                <Editor
                  labelId="whats-in-the-box-input-label"
                  id="whats-in-the-box-input"
                  editorState={whatsInTheBoxEditorState}
                  wrapperClassName={classes.editorWrapper}
                  editorClassName={classes.editor}
                  onEditorStateChange={setWhatsInTheBoxEditorState}
                  toolbar={{
                    options: ['list', 'history'],
                  }}
                />
                <FormHelperText
                  className={classes.helperText}
                  id="whats-in-the-box-input-helper-text"
                >
                  {`${rawWhatsInTheBox.length} characters`}
                </FormHelperText>
              </FormControl>
            </form>
            <div className={classes.separator} />
            {validation.isErrorShowing && validation.errorStep === 1 && (
              <Alert className={classes.alert} severity="error">
                {validation.message}
              </Alert>
            )}
          </div>
        );
      default:
        return <div />;
    }
  };

  if (history.action === 'POP') {
    history.goBack();
  }

  return (
    <div>
      <PageHeader
        isLoading={isLoading}
        title="Draft Copy"
        subtitle={
          isLoading
            ? 'Loading...'
            : getSkuName(skuInfo.brand, skuInfo.productName, skuInfo.variation)
        }
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Draft"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
      />
      <ContentWrapper>
        <Paper className={classes.wrapperWizard}>
          <Grid container spacing={3}>
            {!copyExists && (
              <Grid item xs={3}>
                <Stepper activeStep={activeStep} orientation="vertical">
                  <Step>
                    <StepLabel>Details</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>Draft</StepLabel>
                  </Step>
                </Stepper>
              </Grid>
            )}
            <Grid item xs={copyExists ? 12 : 9}>
              {getStepContent()}
              <div className={classes.actionsBar}>
                <Button
                  disabled={supplementaryInfoLink.length === 0}
                  component={Link}
                  href={supplementaryInfoLink}
                  target="_blank"
                  rel="noreferrer"
                  className={classes.link}
                >
                  View supplementary info
                </Button>
                <div className={classes.actionButtonSeparator} />
                <Button
                  disabled={activeStep === 0 || isLoading}
                  onClick={() => setActiveStep(activeStep - 1)}
                >
                  Previous
                </Button>
                <div className={classes.actionButtonSeparator} />
                <Button
                  disabled={isLoading}
                  variant="contained"
                  color="secondary"
                  onClick={onNextClick}
                >
                  {activeStep !== 1 ? 'Next' : 'Save'}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </ContentWrapper>
    </div>
  );
};

export default ListingsCopyWriting;
