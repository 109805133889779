import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  chipNeutral: {
    marginLeft: theme.spacing(1),
    color: grey[400],
    borderColor: grey[400],
  },
  divider: {
    margin: '24px 0',
  },
  media: {
    minHeight: 140,
    height: '100%',
    backgroundColor: grey[800],
  },
  text: {
    color: theme.palette.secondary.light,
  },
  title: {
    color: theme.palette.primary.light,
  },
  separator: {
    height: theme.spacing(3),
  },
  subheading: {
    color: theme.palette.secondary.light,
    textAlign: 'center',
  },
  valueText: {
    color: theme.palette.primary.light,
    textAlign: 'center',
    fontSize: '1.6rem',
    marginLeft: theme.spacing(2),
  },
}));

const Hit = ({
  title,
  eolSku,
  orderId,
  shipmentId,
  shipmentName,
  dc,
  status,
  supplier,
  primaryImageUrl,
  takealotListingLink,
  quantity,
  bundledUnits,
  costPrice,
  retailPrice,
}) => {
  const classes = useStyles();

  const getStatusChip = () => {
    switch (status) {
      case 'SHIPPED_TO_CUSTOMER':
        return (
          <Chip
            variant="outlined"
            size="small"
            label="Shipped to Customer"
            className={classes.chipNeutral}
          />
        );
      case 'INTER_DC_TRANSFER':
        return (
          <Chip
            variant="outlined"
            size="small"
            label="Inter DC Transfer"
            className={classes.chipNeutral}
          />
        );
      case 'SHIPPED_SHIPMENT':
        return (
          <Chip
            variant="outlined"
            size="small"
            label="Shipped Shipment"
            className={classes.chipNeutral}
          />
        );
      case 'CHECKED_IN_SHIPMENT':
        return (
          <Chip
            variant="outlined"
            size="small"
            label="Checked In Shipment"
            className={classes.chipNeutral}
          />
        );
      case 'RECEIVED_SHIPMENT':
        return (
          <Chip
            variant="outlined"
            size="small"
            label="Received Shipment"
            className={classes.chipNeutral}
          />
        );
      case 'CANCELLED_BY_CUSTOMER':
        return (
          <Chip
            variant="outlined"
            size="small"
            label="Cancelled by Customer"
            className={classes.chipNeutral}
          />
        );
      case 'CANCELLED_BY_SELLER':
        return (
          <Chip
            variant="outlined"
            size="small"
            label="Cancelled by Seller"
            className={classes.chipNeutral}
          />
        );
      case 'CANCELLED_BY_TAKEALOT':
        return (
          <Chip
            variant="outlined"
            size="small"
            label="Cancelled by Takealot"
            className={classes.chipNeutral}
          />
        );
      case 'CANCELLED_LATE_DELIVERY':
        return (
          <Chip
            variant="outlined"
            size="small"
            label="Cancelled - Late Delivery"
            className={classes.chipNeutral}
          />
        );
      case 'CANCELLED_INBOUND_EXCEPTION':
        return (
          <Chip
            variant="outlined"
            size="small"
            label="Cancelled - Inbound Exception"
            className={classes.chipNeutral}
          />
        );
      case 'RETURNED':
        return (
          <Chip
            variant="outlined"
            size="small"
            label="Returned"
            className={classes.chipNeutral}
          />
        );
      case 'READY_TO_COLLECT_DROP_SHIP_ORDER':
        return (
          <Chip
            variant="outlined"
            size="small"
            label="Ready to Collect Drop Ship Order"
            className={classes.chipNeutral}
          />
        );
      case 'COLLECTED_DROP_SHIP_ORDER':
        return (
          <Chip
            variant="outlined"
            size="small"
            label="Collected Drop Ship Order"
            className={classes.chipNeutral}
          />
        );
      case 'CONFIRMED_SHIPMENT':
        return (
          <Chip
            variant="outlined"
            size="small"
            label="Confirmed Shipment"
            className={classes.chipNeutral}
          />
        );
      case 'PREPARING_FOR_CUSTOMER':
        return (
          <Chip
            variant="outlined"
            size="small"
            label="Preparing for Customer"
            className={classes.chipNeutral}
          />
        );
      case 'DRAFT_SHIPMENT':
        return (
          <Chip
            variant="outlined"
            size="small"
            label="Draft Shipment"
            className={classes.chipNeutral}
          />
        );
      case 'IN_PROGRESS_DROP_SHIP_ORDER':
        return (
          <Chip
            variant="outlined"
            size="small"
            label="In Progress Drop Ship Order"
            className={classes.chipNeutral}
          />
        );
      case 'NEW_LEAD_TIME_ORDER':
        return (
          <Chip
            variant="outlined"
            size="small"
            label="New Lead Time Order"
            className={classes.chipNeutral}
          />
        );
      case 'NEW_DROP_SHIP_ORDER':
        return (
          <Chip
            variant="outlined"
            size="small"
            label="New Drop Ship Order"
            className={classes.chipNeutral}
          />
        );
      default:
        return <Chip variant="outlined" size="small" label="Basic" />;
    }
  };

  return (
    <Card className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <CardMedia
            className={classes.media}
            image={primaryImageUrl || 'No image'}
            title={title}
          />
        </Grid>
        <Grid item xs={9}>
          <CardContent>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography
                  className={classes.text}
                  variant="body2"
                  component="p"
                >
                  {eolSku}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  className={classes.text}
                  variant="body2"
                  component="p"
                >
                  {orderId}
                </Typography>
              </Grid>
            </Grid>
            <Typography
              gutterBottom
              variant="h5"
              component="h2"
              className={classes.title}
            >
              {title}
            </Typography>
            <div className={classes.separator} />
            <Grid
              container
              spacing={1}
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                className={classes.text}
                variant="body2"
                component="p"
              >
                {supplier}
              </Typography>
              <div>
                {getStatusChip()}
                <Chip
                  variant="outlined"
                  size="small"
                  label={dc}
                  className={classes.chipNeutral}
                />
              </div>
            </Grid>
            <Divider className={classes.divider} />
            <Grid
              container
              spacing={1}
              alignItems="center"
              justifyContent="space-around"
            >
              <Grid item xs={4}>
                <Grid
                  container
                  spacing={1}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <div>
                    <Typography
                      variant="body1"
                      component="p"
                      className={classes.valueText}
                    >
                      {quantity * bundledUnits}
                    </Typography>
                    <Typography
                      className={classes.subheading}
                      variant="body2"
                      component="p"
                    >
                      Total Bundled Units
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid
                  container
                  spacing={1}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <div>
                    <Typography
                      variant="body1"
                      component="p"
                      className={classes.valueText}
                    >
                      {`R ${numeral(costPrice).format('0,0.00')}`}
                    </Typography>
                    <Typography
                      className={classes.subheading}
                      variant="body2"
                      component="p"
                    >
                      Total Purchase Cost
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid
                  container
                  spacing={1}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <div>
                    <Typography
                      variant="body1"
                      component="p"
                      className={classes.valueText}
                    >
                      {`R ${numeral(retailPrice).format('0,0')}`}
                    </Typography>
                    <Typography
                      className={classes.subheading}
                      variant="body2"
                      component="p"
                    >
                      Total Revenue
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <div className={classes.separator} />
            <Grid
              container
              spacing={1}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item>
                <Typography
                  className={classes.text}
                  variant="body2"
                  component="p"
                >
                  {shipmentId !== null ? `${shipmentName} (${shipmentId})` : ''}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  size="small"
                  variant="outlined"
                  component={Link}
                  href={takealotListingLink}
                  disabled={
                    takealotListingLink === null || takealotListingLink === ''
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  {takealotListingLink === null || takealotListingLink === ''
                    ? 'No listing'
                    : 'View listing'}
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
};

Hit.defaultProps = {
  title: 'Example SKU',
  eolSku: 'EOL-EXAMPLE-0001',
  orderId: '12345',
  shipmentId: null,
  shipmentName: null,
  dc: 'JHB',
  supplier: 'Example SKU',
  primaryImageUrl: '',
  takealotListingLink: '',
  status: 'MISSING_INFO',
  quantity: 1,
  bundledUnits: 3,
  costPrice: 10,
  retailPrice: 100,
};

Hit.propTypes = {
  orderId: PropTypes.string,
  title: PropTypes.string,
  eolSku: PropTypes.string,
  shipmentId: PropTypes.string,
  shipmentName: PropTypes.string,
  supplier: PropTypes.string,
  status: PropTypes.string,
  dc: PropTypes.string,
  primaryImageUrl: PropTypes.string,
  takealotListingLink: PropTypes.string,
  quantity: PropTypes.number,
  bundledUnits: PropTypes.number,
  costPrice: PropTypes.number,
  retailPrice: PropTypes.number,
};

export default Hit;
