import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import TagSelector from './components/TagSelector';

const useStyles = makeStyles((theme) => ({
  results: {
    margin: theme.spacing(1),
    color: grey[800],
  },
  separator: {
    width: theme.spacing(1),
  },
  wrapperButtons: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  wrapperRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
}));

const FiltersBar = ({
  tagFilter,
  changeTagFilter,
  isEditingListingOrder,
  changeIsEditingListingOrder,
  updateListingOrders,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapperRoot}>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          changeIsEditingListingOrder(!isEditingListingOrder);
          if (isEditingListingOrder) {
            updateListingOrders();
          }
        }}
      >
        {isEditingListingOrder ? 'Save changes' : 'Set listing images'}
      </Button>
      <div className={classes.wrapperButtons}>
        <TagSelector tagFilter={tagFilter} changeTagFilter={changeTagFilter} />
      </div>
    </div>
  );
};

FiltersBar.defaultProps = {
  tagFilter: 'ALL',
  changeTagFilter: (newFilter) =>
    console.log('User changes the tag filter to:', newFilter),
  isEditingListingOrder: false,
  changeIsEditingListingOrder: (newValue) =>
    console.log('Editing status changed to:', newValue),
  updateListingOrders: () =>
    console.log('Listing order changes have been saved.'),
};

FiltersBar.propTypes = {
  tagFilter: PropTypes.string,
  changeTagFilter: PropTypes.func,
  isEditingListingOrder: PropTypes.bool,
  changeIsEditingListingOrder: PropTypes.func,
  updateListingOrders: PropTypes.func,
};

export default FiltersBar;
