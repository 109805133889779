import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { createUser, fetchAgencies, initiateStateReset } from './slice';
import ContentWrapper from '../../../components/ContentWrapper';
import PageHeader from '../../../components/PageHeader';

const useStyles = makeStyles((theme) => ({
  actionsBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  alert: {
    marginBottom: theme.spacing(3),
  },
  formControl: {
    minWidth: 200,
  },
  formWrapper: {
    padding: `${theme.spacing(4)}px ${theme.spacing(1)}px`,
  },
  separator: {
    height: theme.spacing(2),
  },
  wrapperStep: {
    padding: `${theme.spacing(4)}px 0px`,
  },
  wrapperWizard: {
    width: '100%',
    padding: theme.spacing(4),
  },
}));

const breadcrumbs = [
  {
    label: 'User Management',
    link: '/user-management',
  },
  {
    label: 'Staff',
    link: '/user-management/staff',
  },
];

const UsersAdd = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loginEmail, setLoginEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordReentry, setPasswordReentry] = useState('');
  const [agencyId, setAgencyId] = useState('N/A');
  const [agencyName, setAgencyName] = useState('Not Applicable');
  const [userType, setUserType] = useState('');
  const [title, setTitle] = useState('');
  const [validation, setValidation] = useState({
    isErrorShowing: false,
    message: '',
    errorStep: 0,
  });

  const creationLoadingStatus = useSelector(
    (state) => state.usersAdd.loadingStatus.creation
  );
  const agenciesLoadingStatus = useSelector(
    (state) => state.usersAdd.loadingStatus.agencies
  );
  const isUserRedirectedToBrowse = useSelector(
    (state) => state.usersAdd.isUserRedirectedToBrowse
  );
  const agencies = useSelector((state) => state.usersAdd.agencies);
  const userInfo = useSelector((state) => state.account.userInfo);

  useEffect(() => {
    dispatch(fetchAgencies());
    if (userInfo.agencyId) {
      setAgencyId(userInfo.agencyId);
      setAgencyName(userInfo.agencyName);
    }
  }, []);

  useEffect(() => {
    if (
      creationLoadingStatus === 'PENDING' ||
      agenciesLoadingStatus === 'PENDING'
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [creationLoadingStatus, agenciesLoadingStatus]);

  useEffect(() => {
    if (isUserRedirectedToBrowse) {
      dispatch(initiateStateReset());
      setIsLoading(true);
      history.goBack();
    }
  }, [isUserRedirectedToBrowse]);

  const formatUser = () => {
    const user = {
      name,
      surname,
      email,
      phoneNumber,
      title,
      signature: {
        showPersonalNumber: phoneNumber.length > 0,
      },
      type: userType,
    };

    if (agencyId !== 'N/A') {
      user.agentId = agencyId;
      user.agencyName = agencyName;
    }

    return user;
  };

  const validateEmail = () => {
    return String(email)
      .toLowerCase()
      .match(/^(\S+)@(\S+)(\.[a-z0-9]{2,})+$/);
  };

  const onNextClick = () => {
    switch (activeStep) {
      case 0:
        if (name === '' || surname === '' || email === '') {
          setValidation({
            isErrorShowing: true,
            message: 'Please complete all required fields.',
            errorStep: 0,
          });
        } else if (!validateEmail()) {
          setValidation({
            isErrorShowing: true,
            message: 'The user must use a valid email address.',
            errorStep: 0,
          });
        } else {
          setValidation({
            isErrorShowing: false,
            message: '',
            errorStep: 0,
          });
          setActiveStep(1);
        }
        break;
      case 1:
        if (loginEmail === '') {
          setValidation({
            isErrorShowing: true,
            message: 'Please enter an email address for this user.',
            errorStep: 1,
          });
        } else if (password === '') {
          setValidation({
            isErrorShowing: true,
            message: 'Please enter a password for the user.',
            errorStep: 1,
          });
        } else if (password.length < 6) {
          setValidation({
            isErrorShowing: true,
            message: 'Please enter a password with more than 6 characters.',
            errorStep: 1,
          });
        } else if (password !== passwordReentry) {
          setValidation({
            isErrorShowing: true,
            message: 'The passwords do not match.',
            errorStep: 1,
          });
        } else {
          setValidation({
            isErrorShowing: false,
            message: '',
            errorStep: 0,
          });
          setActiveStep(2);
        }
        break;
      case 2:
        if (userType === '') {
          setValidation({
            isErrorShowing: true,
            message: 'Please select a role for the user.',
            errorStep: 2,
          });
        } else if (title === '') {
          setValidation({
            isErrorShowing: true,
            message: "Please enter the user's job title.",
            errorStep: 2,
          });
        } else {
          dispatch(
            createUser(formatUser(), {
              password,
              email: loginEmail,
            })
          );
          setValidation({
            isErrorShowing: false,
            message: '',
            errorStep: 0,
          });
        }
        break;
      default:
        console.log('Unknown step');
        break;
    }
  };

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <div className={classes.wrapperStep}>
            <Typography variant="h4" gutterBottom>
              Enter user info
            </Typography>
            <Typography variant="body1">
              Please enter the basic personal details for this new user.
            </Typography>
            <form className={classes.formWrapper}>
              <TextField
                required
                label="First Name"
                fullWidth
                placeholder="Jane"
                value={name}
                onChange={(e) => setName(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <div className={classes.separator} />
              <TextField
                required
                label="Surname"
                fullWidth
                placeholder="Doe"
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <div className={classes.separator} />
              <TextField
                required
                label="Email"
                fullWidth
                placeholder="jane@easyonline.io"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={(e) => {
                  if (loginEmail === '') {
                    setLoginEmail(e.target.value);
                  }
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <div className={classes.separator} />
              <TextField
                label="Phone Number"
                fullWidth
                placeholder="+27 82 333 4444"
                type="telephone"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </form>
            <div className={classes.separator} />
            {validation.isErrorShowing && validation.errorStep === 0 && (
              <Alert className={classes.alert} severity="error">
                {validation.message}
              </Alert>
            )}
          </div>
        );
      case 1:
        return (
          <div className={classes.wrapperStep}>
            <Typography variant="h4" gutterBottom>
              Enter the user&apos;s password
            </Typography>
            <Typography variant="body1">
              Please enter the password that the user will use to log in to
              their account.
            </Typography>
            <div className={classes.separator} />
            <TextField
              required
              label="Login Email"
              fullWidth
              type="loginEmail"
              value={loginEmail}
              onChange={(e) => setLoginEmail(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <div className={classes.separator} />
            <TextField
              required
              label="Password"
              fullWidth
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <div className={classes.separator} />
            <TextField
              required
              label="Re-enter Password"
              fullWidth
              type="password"
              value={passwordReentry}
              onChange={(e) => setPasswordReentry(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <div className={classes.separator} />
            {validation.isErrorShowing && validation.errorStep === 1 && (
              <Alert className={classes.alert} severity="error">
                {validation.message}
              </Alert>
            )}
          </div>
        );
      case 2:
        return (
          <div className={classes.wrapperStep}>
            <Typography variant="h4" gutterBottom>
              Select roles and permissions
            </Typography>
            <Typography variant="body1">
              Please select this new user&apos;s role within EasyOnline.
            </Typography>
            {!userInfo.agencyId && <div className={classes.separator} />}
            {!userInfo.agencyId && (
              <FormControl className={classes.formControl}>
                <InputLabel shrink id="agency-select-label">
                  Agency
                </InputLabel>
                <Select
                  labelId="agency-select-label"
                  id="agency-select"
                  value={agencyId}
                  onChange={(e, newValue) => {
                    setAgencyId(e.target.value);
                    setAgencyName(newValue.props.children);
                  }}
                >
                  <MenuItem value="N/A">Not Applicable</MenuItem>
                  {agencies.map((agencyInfo) => (
                    <MenuItem key={agencyInfo.id} value={agencyInfo.id}>
                      {agencyInfo.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <div className={classes.separator} />
            <FormControl className={classes.formControl}>
              <InputLabel shrink id="user-type-select-label">
                Role
              </InputLabel>
              <Select
                labelId="user-type-select-label"
                id="user-type-select"
                value={userType}
                onChange={(e, newValue) => {
                  setUserType(e.target.value);
                  setTitle(newValue.props.children);
                }}
              >
                {userInfo.type === 'ADMIN' && (
                  <MenuItem value="ADMIN">Administrator</MenuItem>
                )}
                <MenuItem value="SALES_AGENT">Sales Agent</MenuItem>
                <MenuItem value="SALES_MANAGER">Sales Manager</MenuItem>
                <MenuItem value="LEADS_AGENT">Leads Agent</MenuItem>
                <MenuItem value="LEADS_MANAGER">Leads Manager</MenuItem>
              </Select>
            </FormControl>
            <div className={classes.separator} />
            <TextField
              required
              label="Job Title"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <div className={classes.separator} />
            {validation.isErrorShowing && validation.errorStep === 2 && (
              <Alert className={classes.alert} severity="error">
                {validation.message}
              </Alert>
            )}
          </div>
        );
      default:
        return <div />;
    }
  };

  return (
    <div>
      <PageHeader
        isLoading={isLoading}
        title="Add New Staff Member"
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Add"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
      />
      <ContentWrapper>
        <Paper className={classes.wrapperWizard}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Stepper activeStep={activeStep} orientation="vertical">
                <Step>
                  <StepLabel>Personal Details</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Password</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Roles & Permissions</StepLabel>
                </Step>
              </Stepper>
            </Grid>
            <Grid item xs={9}>
              {getStepContent()}
              <div className={classes.actionsBar}>
                <Button
                  disabled={activeStep === 0 || isLoading}
                  onClick={() => setActiveStep(activeStep - 1)}
                >
                  Previous
                </Button>
                <Button
                  disabled={isLoading}
                  variant="contained"
                  color="secondary"
                  onClick={onNextClick}
                >
                  {activeStep !== 2 ? 'Next' : 'Create'}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </ContentWrapper>
    </div>
  );
};

export default UsersAdd;
