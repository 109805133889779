import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import DcSelector from './components/DcSelector';
import RangeSelector from './components/RangeSelector';
import StatusSelector from './components/StatusSelector';
import SupplierSelector from './components/SupplierSelector';
import TypeSelector from './components/TypeSelector';

const useStyles = makeStyles((theme) => ({
  seperator: {
    width: theme.spacing(2),
  },
  wrapper: {
    backgroundColor: grey[800],
    borderRadius: 8,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
}));

const ReportActionBar = ({
  dateType,
  changeDateType,
  startDate,
  changeStartDate,
  endDate,
  changeEndDate,
  dcFilter,
  changeDcFilter,
  statusId,
  changeStatusId,
  supplier,
  changeSupplier,
  suppliers,
  reportType,
  changeReportType,
  isLoading,
  requestReport,
}) => {
  const classes = useStyles();

  return (
    <Grid container spacing={4} alignItems="center" className={classes.wrapper}>
      <Grid item>
        <Button
          size="large"
          variant="contained"
          disabled={isLoading}
          onClick={requestReport}
        >
          Request report
        </Button>
      </Grid>
      <Divider orientation="vertical" flexItem />
      <Grid item>
        <Grid container direction="row">
          <RangeSelector
            rangeId={dateType}
            changeRange={changeDateType}
            startDate={startDate}
            changeStartDate={changeStartDate}
            endDate={endDate}
            changeEndDate={changeEndDate}
          />
        </Grid>
        <Grid container direction="row">
          <TypeSelector
            typeFilter={reportType}
            changeTypeFilter={changeReportType}
          />
          <div className={classes.seperator} />
          <SupplierSelector
            supplier={supplier}
            changeSupplier={changeSupplier}
            supplierOptions={suppliers}
          />
          <div className={classes.seperator} />
          <StatusSelector
            statusFilter={statusId}
            changeStatusFilter={changeStatusId}
          />
          <div className={classes.seperator} />
          <DcSelector dcFilter={dcFilter} changeDcFilter={changeDcFilter} />
        </Grid>
      </Grid>
    </Grid>
  );
};

ReportActionBar.defaultProps = {
  dateType: 'TODAY',
  changeDateType: (newDateType) =>
    console.log(`Date type was changed to: ${newDateType}`),
  startDate: new Date(),
  changeStartDate: (newDate) =>
    console.log(`Start date was changed to: ${newDate}`),
  endDate: new Date(),
  changeEndDate: (newDate) =>
    console.log(`End date was changed to: ${newDate}`),
  dcFilter: 'BOTH',
  changeDcFilter: (newDcFilter) =>
    console.log(`DC filter was changed to: ${newDcFilter}`),
  statusId: 'ALL',
  changeStatusId: (newStatus) =>
    console.log(`Status was changed to: ${newStatus}`),
  supplier: 'ALL',
  changeSupplier: (newSupplier) =>
    console.log(`Supplier was changed to: ${newSupplier}`),
  suppliers: [],
  reportType: 'ALL',
  changeReportType: (newReportType) =>
    console.log(`Report type was changed to: ${newReportType}`),
  isLoading: false,
  requestReport: () => console.log('New orders report requested.'),
};

ReportActionBar.propTypes = {
  dateType: PropTypes.string,
  changeDateType: PropTypes.func,
  startDate: PropTypes.instanceOf(Date),
  changeStartDate: PropTypes.func,
  endDate: PropTypes.instanceOf(Date),
  changeEndDate: PropTypes.func,
  supplier: PropTypes.string,
  changeSupplier: PropTypes.func,
  dcFilter: PropTypes.string,
  changeDcFilter: PropTypes.func,
  statusId: PropTypes.string,
  changeStatusId: PropTypes.func,
  suppliers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  reportType: PropTypes.string,
  changeReportType: PropTypes.func,
  isLoading: PropTypes.bool,
  requestReport: PropTypes.func,
};

export default ReportActionBar;
