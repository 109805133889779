import _ from 'lodash';
import { firestore, storage } from '../utils/firebase';

export const createNewClient = async (clientId, info) => {
  const batch = firestore.batch();
  let newClientRef = firestore.collection('clients').doc();
  if (clientId !== null) {
    newClientRef = firestore.collection('clients').doc(clientId);
  }
  batch.set(newClientRef, info);

  if (info.type === 'SUPER_DEALER') {
    const superDealerSummaryRef = firestore
      .collection('superDealerSummary')
      .doc('codes');
    batch.update(superDealerSummaryRef, {
      count: parseInt(info.dealerCode, 10),
      [`dealers.${info.dealerCode}`]: {
        clientName: info.name,
        clientId: newClientRef.id,
      },
    });
  }

  return batch
    .commit()
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const updateClientInfo = async (clientId, newInfo) => {
  const clientRef = firestore.collection('clients').doc(clientId);

  return clientRef
    .update(newInfo)
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const updateClientStatus = async (clientId, status) => {
  const clientRef = firestore.collection('clients').doc(clientId);

  return clientRef
    .update({
      status,
    })
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const getClient = async (clientId) => {
  const clientRef = firestore.collection('clients').doc(clientId);

  return clientRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        const clientInfo = { id: doc.id, ...doc.data() };
        return {
          clientInfo,
          status: 200,
        };
      }
      return {
        clientId,
        status: 500,
      };
    })
    .catch((error) => {
      return {
        clientId,
        error,
        status: 500,
      };
    });
};

export const getClients = async () => {
  const clientsRef = firestore.collection('clients');

  return clientsRef
    .get()
    .then((querySnapshot) => {
      const clients = [];
      querySnapshot.forEach((doc) => {
        const clientInfo = doc.data();
        clients.push({
          id: doc.id,
          ...clientInfo,
        });
      });
      return {
        clients,
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const getSuperDealerSummary = async () => {
  const superDealerSummaryRef = firestore
    .collection('superDealerSummary')
    .doc('codes');

  return superDealerSummaryRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        const summary = doc.data();
        return {
          summary,
          status: 200,
        };
      }
      return {
        status: 500,
        error: {
          message: 'Document does not exist.',
        },
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const uploadDocuments = async (
  clientId,
  doc,
  tag,
  description,
  uploaderId,
  uploaderName,
  updateUploadBytes,
  uploadSuccessful,
  uploadFailed
) => {
  const storageRef = storage.ref();
  const clientDocumentRef = firestore
    .collection('clients')
    .doc(clientId)
    .collection('documents')
    .doc();
  const newFileRef = storageRef.child(
    `clients/${clientId}/documents/${clientDocumentRef.id}`
  );
  const uploadTask = newFileRef.put(doc);
  uploadTask.on(
    'state_changed',
    (snapshot) => {
      updateUploadBytes(
        clientDocumentRef.id,
        snapshot.totalBytes,
        snapshot.bytesTransferred
      );
    },
    () => {
      uploadFailed();
    },
    async () => {
      return clientDocumentRef
        .set({
          tag,
          description,
          reference: newFileRef.fullPath,
          fileSize: doc.size,
          uploaderId,
          uploaderName,
        })
        .then(() => {
          uploadSuccessful();
        })
        .catch((error) => {
          uploadFailed();
          console.log(error);
        });
    }
  );
};

export const getDocuments = async (clientId) => {
  const documentsRef = firestore
    .collection('clients')
    .doc(clientId)
    .collection('documents');
  const storageRef = storage.ref();

  return documentsRef
    .get()
    .then(async (querySnapshot) => {
      if (querySnapshot.length === 0) {
        return {
          status: 500,
        };
      }

      const documentRefs = [];
      const documents = [];

      querySnapshot.forEach(async (doc) => {
        const documentInfo = doc.data();
        const documentRef = storageRef.child(documentInfo.reference);
        documentRefs.push(documentRef);
        documents.push({ ...documentInfo, id: doc.id });
      });

      await Promise.all(
        documentRefs.map((documentRef, index) =>
          documentRef.getDownloadURL().then((url) => {
            documents[index] = {
              ...documents[index],
              url,
            };
          })
        )
      );

      return {
        documents,
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const deleteDocument = async (clientId, documentPath, documentId) => {
  const storageRef = storage.ref();
  const clientDocumentRef = firestore
    .collection('clients')
    .doc(clientId)
    .collection('documents')
    .doc(documentId);
  const documentRef = storageRef.child(documentPath);

  await documentRef.delete();

  return clientDocumentRef
    .delete()
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        error,
        status: 500,
      };
    });
};

export const getQuery = async (queryId) => {
  const queryRef = firestore.collection('clientRequests').doc(queryId);

  return queryRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        const queryInfo = { id: doc.id, ...doc.data() };
        const requestDate = queryInfo.requestDate.toDate().toISOString();
        const completionDate = queryInfo.completionDate
          ? queryInfo.completionDate.toDate().toISOString()
          : '';
        const startDate = queryInfo.startDate
          ? queryInfo.startDate.toDate().toISOString()
          : '';
        const endDate = queryInfo.endDate
          ? queryInfo.endDate.toDate().toISOString()
          : '';

        return {
          queryInfo: {
            ...queryInfo,
            requestDate,
            completionDate,
            startDate,
            endDate,
            skus: _.values(queryInfo.skus),
          },
          status: 200,
        };
      }
      return {
        queryRef,
        status: 500,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const getQueries = async () => {
  const queriesRef = firestore
    .collection('clientRequests')
    .orderBy('requestDate');

  return queriesRef
    .get()
    .then((querySnapshot) => {
      const queries = [];
      querySnapshot.forEach((doc) => {
        const queryInfo = { id: doc.id, ...doc.data() };
        const requestDate = queryInfo.requestDate.toDate().toISOString();

        queries.push({
          ...queryInfo,
          skus: _.values(queryInfo.skus),
          requestDate,
        });
      });

      return {
        queries,
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const updateQueryStatus = async (queryId, status) => {
  const queryRef = firestore.collection('clientRequests').doc(queryId);
  let completionDate;
  if (status === 'COMPLETED') {
    completionDate = new Date(Date.now());
  } else {
    completionDate = null;
  }
  return queryRef
    .update({ status, completionDate })
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const getQueryDocuments = async (id) => {
  const documentsRef = firestore
    .collection('clientRequests')
    .doc(id)
    .collection('documents');
  const storageRef = storage.ref();

  return documentsRef
    .get()
    .then(async (querySnapshot) => {
      if (querySnapshot.length === 0) {
        return {
          status: 500,
        };
      }

      const documentRefs = [];
      const documents = [];

      querySnapshot.forEach(async (doc) => {
        const documentInfo = doc.data();
        const documentRef = storageRef.child(documentInfo.reference);
        documentRefs.push(documentRef);
        documents.push({ ...documentInfo, id: doc.id });
      });

      await Promise.all(
        documentRefs.map((documentRef, index) =>
          documentRef.getDownloadURL().then((url) => {
            documents[index] = {
              ...documents[index],
              url,
            };
          })
        )
      );

      return {
        documents,
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const getQueryImages = async (id) => {
  const imagesRef = firestore
    .collection('clientRequests')
    .doc(id)
    .collection('images');
  const storageRef = storage.ref();

  return imagesRef
    .get()
    .then(async (querySnapshot) => {
      if (querySnapshot.length === 0) {
        return {
          status: 500,
        };
      }

      const imageRefs = [];
      const images = [];

      querySnapshot.forEach(async (doc) => {
        const imageInfo = doc.data();
        const imageRef = storageRef.child(imageInfo.reference);

        imageRefs.push(imageRef);
        images.push({ ...imageInfo, id: doc.id });
      });

      await Promise.all(
        imageRefs.map((imageRef, index) =>
          imageRef.getDownloadURL().then((url) => {
            images[index] = {
              ...images[index],
              url,
            };
          })
        )
      );
      return {
        images,
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const deleteQueryDocument = async (
  queryId,
  documentPath,
  documentId
) => {
  const storageRef = storage.ref();
  const queryDocumentRef = firestore
    .collection('clientRequests')
    .doc(queryId)
    .collection('documents')
    .doc(documentId);
  const documentRef = storageRef.child(documentPath);

  await documentRef.delete();

  return queryDocumentRef
    .delete()
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        error,
        status: 500,
      };
    });
};

export const deleteQueryImage = async (queryId, imagePath, imageId) => {
  const storageRef = storage.ref();
  const queryImageRef = firestore
    .collection('clientRequests')
    .doc(queryId)
    .collection('images')
    .doc(imageId);
  const imageRef = storageRef.child(imagePath);

  await imageRef.delete();

  return queryImageRef
    .delete()
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        error,
        status: 500,
      };
    });
};

export const addQuery = async (queryInfo) => {
  const batch = firestore.batch();
  const queryRef = firestore.collection('clientRequests').doc();
  batch.set(queryRef, queryInfo);

  return batch
    .commit()
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        error,
        status: 500,
      };
    });
};

export const updateQueryInfo = async (queryId, queryInfo) => {
  const queryRef = firestore.collection('clientRequests').doc(queryId);

  return queryRef
    .update(queryInfo)
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        error,
        status: 500,
      };
    });
};

export const uploadQueryImage = async (
  file,
  queryId,
  updateUploadBytes,
  uploadSucessful,
  uploadFailed
) => {
  const storageRef = storage.ref();
  const queryImageRef = firestore
    .collection('clientRequests')
    .doc(queryId)
    .collection('images')
    .doc();
  const newFileRef = storageRef.child(`queries/${queryId}/${file.name}`);

  const uploadTask = newFileRef.put(file);
  const date = new Date(Date.now());
  uploadTask.on(
    'state_changed',
    (snapshot) => {
      updateUploadBytes(
        queryImageRef.id,
        snapshot.totalBytes,
        snapshot.bytesTransferred
      );
    },
    () => {
      uploadFailed();
    },
    async () => {
      return queryImageRef
        .set({
          reference: newFileRef.fullPath,
          fileSize: file.size,
          date,
        })
        .then(() => {
          uploadSucessful();
        })
        .catch(() => {
          uploadFailed();
        });
    }
  );
};

export const uploadQueryDocuments = async (
  queryId,
  doc,
  description,
  uploaderId,
  uploaderName,
  date,
  updateUploadBytes,
  uploadSuccessful,
  uploadFailed
) => {
  const storageRef = storage.ref();
  const queryDocumentRef = firestore
    .collection('clientRequests')
    .doc(queryId)
    .collection('documents')
    .doc();
  const newFileRef = storageRef.child(
    `queries/${queryId}/documents/${doc.name}`
  );
  const uploadTask = newFileRef.put(doc);
  uploadTask.on(
    'state_changed',
    (snapshot) => {
      updateUploadBytes(
        queryDocumentRef.id,
        snapshot.totalBytes,
        snapshot.bytesTransferred
      );
    },
    () => {
      uploadFailed();
    },
    async () => {
      return queryDocumentRef
        .set({
          description,
          reference: newFileRef.fullPath,
          fileSize: doc.size,
          uploaderId,
          uploaderName,
          date,
        })
        .then(() => {
          uploadSuccessful();
        })
        .catch((error) => {
          uploadFailed();
          console.log(error);
        });
    }
  );
};
