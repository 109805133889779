/* eslint-disable react/jsx-wrap-multilines */
import { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import SkuAdjustmentsTable from './components/SkuAdjustmentsTable';

const RequestAdjustmentsDialog = ({
  isOpen,
  applyAdjustments,
  shipmentSkus,
  adjustments,
  closeDialog,
}) => {
  const [adjustedSkus, setAdjustedSkus] = useState([]);

  useEffect(() => {
    setAdjustedSkus(
      shipmentSkus.map((info) => {
        const relevantAdjustment = adjustments.find(
          (adjustmentInfo) => adjustmentInfo.sku === info.sku
        );

        if (relevantAdjustment) {
          return {
            ...relevantAdjustment,
          };
        }

        return {
          sku: info.sku,
          tsin: info.tsin,
          childAsin: info.childAsin,
          product: info.product,
          requestedUnits: info.units,
          adjustedUnits: info.units,
        };
      })
    );
  }, [isOpen]);

  return (
    <Dialog
      onClose={closeDialog}
      aria-labelledby="request-adjustments-dialog-title"
      open={isOpen}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle id="request-adjustments-title">
        Adjust Stock Quantities
      </DialogTitle>
      <DialogContent>
        <SkuAdjustmentsTable
          adjustedSkus={adjustedSkus}
          updateAdjustedQuantities={setAdjustedSkus}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() =>
            applyAdjustments(
              adjustedSkus
                .map((info) => ({
                  sku: info.sku,
                  tsin: info.tsin,
                  childAsin: info.childAsin,
                  product: info.product,
                  requestedUnits: info.requestedUnits,
                  adjustedUnits: info.adjustedUnits,
                }))
                .filter((info) => info.requestedUnits !== info.adjustedUnits),
              shipmentSkus
            )
          }
          color="secondary"
          variant="contained"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

RequestAdjustmentsDialog.defaultProps = {
  isOpen: false,
  applyAdjustments: (adjustments, shipmentSkus) =>
    console.log(
      'The following adjustments were made:',
      adjustments,
      shipmentSkus
    ),
  closeDialog: () => console.log('The dialog was closed.'),
  adjustments: [],
  shipmentSkus: [
    {
      childAsin: '',
      sku: 'FUT-RST-0101',
      tsin: 75308402,
      units: 2,
      product: 'Futurity Health - Reishi Tincture 50ml',
      costPrice: 86.25,
    },
  ],
};

RequestAdjustmentsDialog.propTypes = {
  isOpen: PropTypes.bool,
  applyAdjustments: PropTypes.func,
  closeDialog: PropTypes.func,
  adjustments: PropTypes.arrayOf(
    PropTypes.shape({
      childAsin: PropTypes.string,
      sku: PropTypes.string,
      tsin: PropTypes.number,
      product: PropTypes.string,
      requestedUnits: PropTypes.number,
      adjustedUnits: PropTypes.number,
    })
  ),
  shipmentSkus: PropTypes.arrayOf(
    PropTypes.shape({
      childAsin: PropTypes.string,
      sku: PropTypes.string,
      tsin: PropTypes.number,
      units: PropTypes.number,
      product: PropTypes.string,
      costPrice: PropTypes.number,
    })
  ),
};

export default RequestAdjustmentsDialog;
