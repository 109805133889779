const getStyles = (theme) => ({
  alert: {
    margin: theme.spacing(2),
    textAlign: 'center',
    flex: 1,
  },
  flex: {
    flexGrow: 1,
  },
});

export default getStyles;
