import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import numeral from 'numeral';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  bottomTextWrapper: {
    flexGrow: 1,
  },
  bottomWrapper: {
    width: '100%',
    display: 'flex',
  },
  dateText: {
    marginTop: theme.spacing(1),
    fontSize: '0.8rem',
    textAlign: 'right',
    color: grey[800],
  },
  dealTypeText: {
    marginTop: theme.spacing(1),
    fontSize: '0.8rem',
    textAlign: 'left',
    color: grey[800],
  },
  statusAvatar: {
    backgroundColor: theme.palette.secondary.light,
  },
  summaryTitle: {
    color: theme.palette.secondary.light,
    fontSize: '0.8rem',
  },
  summaryValue: {
    color: theme.palette.primary.light,
    fontSize: '1rem',
  },
  wrapperSummaries: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
  wrapperSummary: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const DealCard = ({
  dealName,
  dealType,
  salesPerson,
  dealValue,
  platform,
  status,
  goToDeal,
  isSalesPersonDisplayed,
  creationDate,
  lastUpdated,
  dateType,
}) => {
  const classes = useStyles();

  const getStatusText = () => {
    switch (status) {
      case 'FINALIZED':
        return 'Successfully Closed';
      case 'LOST':
        return 'Lost';
      case 'AWAITING_INVOICE':
        return 'Awaiting Invoice';
      case 'AWAITING_PROSPECT_RESPONSE':
        return 'Negotiating';
      case 'AWAITING_ASSESSMENT':
        return 'Awaiting Assessment';
      default:
        return 'Unknown';
    }
  };

  const getPlatformText = () => {
    switch (platform) {
      case 'AMAZON':
        return 'Amazon';
      case 'TAKEALOT':
        return 'Takealot';
      case 'AMAZON_TAKEALOT':
        return 'Amazon & Takealot';
      default:
        return 'Unknown';
    }
  };

  const getStatusAvatar = () => {
    switch (status) {
      case 'AWAITING_INVOICE':
      case 'AWAITING_PROSPECT_RESPONSE':
      case 'AWAITING_ASSESSMENT':
        return (
          <Avatar className={classes.statusAvatar}>
            <AutorenewIcon />
          </Avatar>
        );
      case 'FINALIZED':
        return (
          <Avatar className={classes.statusAvatar}>
            <DoneIcon />
          </Avatar>
        );
      case 'LOST':
        return (
          <Avatar className={classes.statusAvatar}>
            <ClearIcon />
          </Avatar>
        );
      default:
        return <Avatar />;
    }
  };

  const getDateTypeText = () => {
    switch (dateType) {
      case 'LAST_UPDATED':
        return 'Last Updated';
      case 'CREATED':
        return 'Created';
      default:
        return 'Unknown';
    }
  };

  const getDealTypeText = () => {
    switch (dealType) {
      case 'NEW':
        return 'New Prospect';
      case 'ADD_ON':
        return 'Existing Client';
      case 'SUPER_DEALER':
        return 'Super Dealer';
      default:
        return '';
    }
  };

  const getDateText = () => {
    if (dateType === 'LAST_UPDATED') {
      return moment(lastUpdated).format('D MMM YYYY');
    }

    return moment(creationDate).format('D MMM YYYY');
  };

  return (
    <Card>
      <CardActionArea onClick={goToDeal}>
        <CardHeader
          avatar={getStatusAvatar(status)}
          title={dealName}
          subheader={getStatusText()}
        />
        <CardContent>
          <div className={classes.wrapperSummaries}>
            {isSalesPersonDisplayed && (
              <div className={classes.wrapperSummary}>
                <Typography variant="h6" className={classes.summaryValue}>
                  {salesPerson}
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.summaryTitle}
                >
                  Sales Person
                </Typography>
              </div>
            )}
            <div className={classes.wrapperSummary}>
              <Typography variant="h6" className={classes.summaryValue}>
                {dealValue === null
                  ? 'Unknown'
                  : `R ${numeral(dealValue).format('0,0.00')}`}
              </Typography>
              <Typography variant="subtitle1" className={classes.summaryTitle}>
                Deal Value
              </Typography>
            </div>
            <div className={classes.wrapperSummary}>
              <Typography variant="h6" className={classes.summaryValue}>
                {getPlatformText()}
              </Typography>
              <Typography variant="subtitle1" className={classes.summaryTitle}>
                Platform
              </Typography>
            </div>
            <div className={classes.bottomWrapper}>
              <div className={classes.bottomTextWrapper}>
                <Typography className={classes.dealTypeText} variant="body1">
                  {getDealTypeText(dealType)}
                </Typography>
              </div>
              <div className={classes.bottomTextWrapper}>
                <Typography className={classes.dateText} variant="body1">
                  {`${getDateTypeText()}: ${getDateText()}`}
                </Typography>
              </div>
            </div>
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

DealCard.defaultProps = {
  dealName: 'Madeup Inc (Add-On)',
  dealType: 'ADD_ON',
  salesPerson: 'Jane Doe',
  dealValue: 15261,
  platform: 'AMAZON_TAKEALOT',
  status: 'AWAITING_INVOICE',
  goToDeal: () => console.log('User navigated to the deal'),
  isSalesPersonDisplayed: true,
  creationDate: new Date().toString(),
  lastUpdated: new Date().toString(),
  dateType: 'LAST_UPDATED',
};

DealCard.propTypes = {
  dealName: PropTypes.string,
  dealType: PropTypes.oneOf(['UNKNOWN', 'NEW', 'ADD_ON', 'SUPER_DEALER']),
  salesPerson: PropTypes.string,
  dealValue: PropTypes.number,
  platform: PropTypes.oneOf(['AMAZON', 'TAKEALOT', 'AMAZON_TAKEALOT']),
  status: PropTypes.oneOf([
    'AWAITING_INVOICE',
    'AWAITING_PROSPECT_RESPONSE',
    'FINALIZED',
    'LOST',
  ]),
  goToDeal: PropTypes.func,
  isSalesPersonDisplayed: PropTypes.bool,
  creationDate: PropTypes.string,
  lastUpdated: PropTypes.string,
  dateType: PropTypes.oneOf(['LAST_UPDATED', 'CREATED']),
};

export default DealCard;
