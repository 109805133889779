/* eslint-disable react/jsx-props-no-spreading */
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { fetchPresalesSuppliers, requestPresalesReport } from './slice';
import { firestore } from '../../../utils/firebase';
import ContentWrapper from '../../../components/ContentWrapper';
import PageHeader from '../../../components/PageHeader';
import ReportsActionBar from './components/ReportsActionBar';
import RequestedReports from './components/RequestedReports';

const breadcrumbs = [
  {
    label: 'Presales',
    link: '/presales',
  },
  {
    label: 'Reports',
    link: '/presales/reports',
  },
];

const ReportsBrowse = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [reportType, setReportType] = useState('PRICING_STOCK');
  const [supplier, setSupplier] = useState('ALL');
  const [reports, setReports] = useState([]);

  const suppliers = useSelector(
    (state) => state.presalesReportsBrowse.suppliers
  );
  const suppliersLoadingStatus = useSelector(
    (state) => state.presalesReportsBrowse.loadingStatus.suppliers
  );
  const requestReportLoadingStatus = useSelector(
    (state) => state.presalesReportsBrowse.loadingStatus.requestReport
  );
  const userInfo = useSelector((state) => state.account.userInfo);

  useEffect(() => {
    dispatch(fetchPresalesSuppliers());

    const reportsRef = firestore
      .collection('presalesReports')
      .orderBy('requestDate', 'desc')
      .limit(20);

    const unsubscribeTemplates = reportsRef.onSnapshot((querySnapshot) => {
      const result = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          requestDate: data.requestDate.toDate(),
        };
      });

      setReports(result);
    });

    return () => {
      unsubscribeTemplates();
    };
  }, []);

  useEffect(() => {
    if (
      suppliersLoadingStatus === 'PENDING' ||
      requestReportLoadingStatus === 'PENDING'
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [suppliersLoadingStatus, requestReportLoadingStatus]);

  useEffect(() => {
    if (requestReportLoadingStatus === 'COMPLETE') {
      setSupplier('ALL');
      setReportType('PRICING_STOCK');
    }
  }, [requestReportLoadingStatus]);

  const getSupplierName = () => {
    const supplierInfo = suppliers.find((info) => info.id === supplier);
    if (!supplierInfo) {
      return 'Unknown';
    }

    return supplierInfo.name;
  };

  return (
    <div>
      <PageHeader
        title="Reports"
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Browse"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
      />
      {isLoading && <LinearProgress color="secondary" />}
      <ContentWrapper>
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <ReportsActionBar
              supplier={supplier}
              changeSupplier={setSupplier}
              suppliers={suppliers}
              reportType={reportType}
              changeReportType={setReportType}
              isLoading={isLoading}
              requestReport={() =>
                dispatch(
                  requestPresalesReport(
                    userInfo.uid,
                    `${userInfo.name} ${userInfo.surname}`,
                    supplier,
                    getSupplierName(),
                    reportType
                  )
                )
              }
            />
            <RequestedReports reports={reports} />
          </Grid>
        </Grid>
      </ContentWrapper>
    </div>
  );
};
export default ReportsBrowse;
