import { useState } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';

const DateTypeSelector = ({ dateTypeId, changeDateType, dateTypes }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const getDateTypeName = (id) => {
    const dateTypeInfo = dateTypes.find((info) => info.id === id);
    if (!dateTypeInfo) {
      return 'Unknown';
    }

    return dateTypeInfo.name;
  };

  return (
    <>
      <Button
        aria-controls="date-types-menu"
        aria-haspopup="true"
        size="large"
        onClick={openMenu}
      >
        {getDateTypeName(dateTypeId)}
      </Button>
      <Menu
        id="date-types-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {dateTypes
          .map((info) => info)
          .map((info) => (
            <MenuItem
              key={info.id}
              onClick={() => {
                changeDateType(info.id);
                closeMenu();
              }}
            >
              {info.name}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

DateTypeSelector.defaultProps = {
  dateTypeId: 'NOW',
  changeDateType: (newValue) => `Date type was changed to '${newValue}'`,
  dateTypes: [
    {
      id: 'NOW',
      name: 'Update now',
    },
    {
      id: 'LATER',
      name: 'Update at specific time',
    },
  ],
};

DateTypeSelector.propTypes = {
  dateTypeId: PropTypes.string,
  changeDateType: PropTypes.func,
  dateTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
};

export default DateTypeSelector;
