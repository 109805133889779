import _ from 'lodash';

const getFormattedSkus = (skus, queryInfo, searchText) => {
  let changeDetail = 'OTHER';

  if (queryInfo.requestType === 'LISTING_CHANGE') {
    changeDetail = queryInfo.fieldChange;
  } else if (queryInfo.requestType === 'PRICE_CHANGE') {
    changeDetail = queryInfo.priceToChange;
  }

  return skus
    .map((info) => {
      if (queryInfo.requestType === 'PRODUCT_MISMATCH') {
        changeDetail = info.mismatchType;
      }

      return {
        changeDetail,
        sku: info.sku,
        changeTo: info.changeTo,
        product: info.product,
        tsin: info.tsin,
      };
    })
    .filter((skuInfo) => {
      if (searchText !== '') {
        const caseInsensitiveSearch = _.toLower(searchText);
        const caseInsensitiveSku = _.toLower(skuInfo.sku);
        const caseInsensitiveTsin = _.toLower(skuInfo.tsin);
        const caseInsensitiveClientName = _.toLower(skuInfo.product);

        if (caseInsensitiveSku.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveTsin.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveClientName.includes(caseInsensitiveSearch)) {
          return true;
        }

        return false;
      }

      return true;
    });
};

export default getFormattedSkus;
