import _ from 'lodash';
import numeral from 'numeral';

export const getTotals = (skus, destinationFilter) => {
  let toOptions = [];

  if (destinationFilter === 'EASYONLINE') {
    toOptions = ['EasyOnline'];
  } else if (destinationFilter === 'AMAZON') {
    toOptions = ['Amazon US'];
  } else if (destinationFilter === 'TAKEALOT') {
    toOptions = ['Takealot JHB', 'Takealot CPT'];
  }

  const toTotals = toOptions.map((to) => {
    const total = skus.reduce((unitsRequired, currentSku) => {
      if (currentSku.toName === to) {
        return unitsRequired + currentSku.unitsToSend;
      }
      return unitsRequired;
    }, 0);

    return {
      value: numeral(total).format('0,0'),
      label: `Units to ${to}`,
    };
  });

  return [
    {
      value: numeral(_.uniq(skus.map((info) => info.sku)).length).format('0,0'),
      label: 'SKUs',
    },
    ...toTotals,
  ];
};

export const getSkus = (replenishmentSkus, searchQuery, clientFilter) => {
  const skus = replenishmentSkus.map((info) => {
    if (info.dc === 'JHB') {
      return {
        fromName: 'EasyOnline',
        fromId: '5tI7NvUkhMtemnqcirGy',
        toName: 'Takealot JHB',
        toId: 'ktwmpFt2DUXU1wsC59EJ',
        batchSize: info.batchSize || 5,
        ...info,
      };
    }
    if (info.dc === 'CPT') {
      return {
        fromName: 'EasyOnline',
        fromId: '5tI7NvUkhMtemnqcirGy',
        toName: 'Takealot CPT',
        toId: 't6NDHxRtKUiOhmv64ptz',
        batchSize: info.batchSize || 5,
        ...info,
      };
    }
    return {
      fromName: info.clientName,
      fromId: info.clientId,
      toName: 'EasyOnline',
      toId: '5tI7NvUkhMtemnqcirGy',
      batchSize: info.batchSize || 5,
      ...info,
    };
  });

  return skus
    .filter((info) => {
      if (searchQuery !== '') {
        const caseInsensitiveSearch = _.toLower(searchQuery);
        const caseInsensitiveProduct = _.toLower(info.product);
        const caseInsensitiveSku = _.toLower(info.sku);
        const caseInsensitiveAsin = _.toLower(info.asin);
        const caseInsensitiveTsin = _.toLower(info.tsin);
        const caseInsensitiveFrom = _.toLower(info.fromName);
        const caseInsensitiveTo = _.toLower(info.toName);

        if (caseInsensitiveProduct.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveSku.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveAsin.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveTsin.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveFrom.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveTo.includes(caseInsensitiveSearch)) {
          return true;
        }

        return false;
      }

      return true;
    })
    .filter((info) => {
      if (clientFilter !== 'ALL') {
        return info.clientName === clientFilter;
      }
      return true;
    })
    .sort((skuA, skuB) => {
      if (skuA.fromName > skuB.fromName) return +1;
      if (skuA.fromName < skuB.fromName) return -1;
      if (skuA.toName > skuB.toName) return +1;
      if (skuA.toName < skuB.toName) return -1;
      if (skuA.product > skuB.product) return +1;
      if (skuA.product < skuB.product) return -1;
      return 0;
    });
};

export const getShipments = (
  replenishmentSkus,
  userId,
  userName,
  destination
) => {
  const shipments = [];
  const currentDate = new Date();

  if (destination === 'TAKEALOT') {
    const takealotJhbSkus = {};
    let totalTakealotJhbSkus = 0;
    let totalTakealotJhbUnits = 0;

    const takealotCptSkus = {};
    let totalTakealotCptSkus = 0;
    let totalTakealotCptUnits = 0;

    replenishmentSkus
      .filter((info) => info.unitsToSend > 0)
      .forEach((info) => {
        if (info.dc === 'JHB') {
          takealotJhbSkus[info.sku] = {
            childAsin: info.childAsin,
            product: info.product,
            sku: info.sku,
            tsin: info.tsin,
            units: info.unitsToSend,
            costPrice: info.costPrice,
          };
          totalTakealotJhbSkus += 1;
          totalTakealotJhbUnits += info.unitsToSend;
        } else if (info.dc === 'CPT') {
          takealotCptSkus[info.sku] = {
            childAsin: info.childAsin,
            product: info.product,
            sku: info.sku,
            tsin: info.tsin,
            units: info.unitsToSend,
            costPrice: info.costPrice,
          };
          totalTakealotCptSkus += 1;
          totalTakealotCptUnits += info.unitsToSend;
        }
      });

    shipments.push({
      clientId: '',
      clientName: '',
      cost: 0,
      creationDate: currentDate,
      creatorId: userId,
      creatorName: userName,
      fromId: '5tI7NvUkhMtemnqcirGy',
      fromName: 'EasyOnline',
      lastUpdated: currentDate,
      skus: takealotJhbSkus,
      status: 'PREPARING',
      toId: 'ktwmpFt2DUXU1wsC59EJ',
      toName: 'Takealot JHB',
      totalSkus: totalTakealotJhbSkus,
      totalUnits: totalTakealotJhbUnits,
    });

    shipments.push({
      clientId: '',
      clientName: '',
      cost: 0,
      creationDate: currentDate,
      creatorId: userId,
      creatorName: userName,
      fromId: '5tI7NvUkhMtemnqcirGy',
      fromName: 'EasyOnline',
      lastUpdated: currentDate,
      skus: takealotCptSkus,
      status: 'PREPARING',
      toId: 't6NDHxRtKUiOhmv64ptz',
      toName: 'Takealot CPT',
      totalSkus: totalTakealotCptSkus,
      totalUnits: totalTakealotCptUnits,
    });
  } else if (destination === 'EASYONLINE') {
    const skusByClient = replenishmentSkus
      .filter((info) => info.destination === 'EASYONLINE')
      .reduce((total, current) => {
        if (current.unitsToSend > 0) {
          if (total[current.clientId]) {
            return {
              ...total,
              [current.clientId]: {
                clientName: current.clientName,
                totalSkus: total[current.clientId].totalSkus + 1,
                totalUnits:
                  total[current.clientId].totalUnits + current.unitsToSend,
                skus: {
                  ...total[current.clientId].skus,
                  [current.sku]: {
                    childAsin: current.childAsin,
                    product: current.product,
                    sku: current.sku,
                    tsin: current.tsin,
                    units: current.unitsToSend,
                    costPrice: current.costPrice,
                  },
                },
              },
            };
          }
          return {
            ...total,
            [current.clientId]: {
              clientName: current.clientName,
              totalSkus: 1,
              totalUnits: current.unitsToSend,
              skus: {
                [current.sku]: {
                  childAsin: current.childAsin,
                  product: current.product,
                  sku: current.sku,
                  tsin: current.tsin,
                  units: current.unitsToSend,
                  costPrice: current.costPrice,
                },
              },
            },
          };
        }
        return total;
      }, {});

    _.toPairs(skusByClient).forEach(([clientId, clientInfo]) => {
      shipments.push({
        clientId,
        clientName: clientInfo.clientName,
        cost: 0,
        creationDate: currentDate,
        creatorId: userId,
        creatorName: userName,
        fromId: 'CLIENT',
        fromName: '',
        lastUpdated: currentDate,
        skus: clientInfo.skus,
        status: 'PREPARING',
        toId: '5tI7NvUkhMtemnqcirGy',
        toName: 'EasyOnline',
        totalSkus: clientInfo.totalSkus,
        totalUnits: clientInfo.totalUnits,
      });
    });
  }

  return shipments.sort((skuA, skuB) => {
    if (skuA.clientName > skuB.clientName) return +1;
    if (skuA.clientName < skuB.clientName) return -1;
    return 0;
  });
};
