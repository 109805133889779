import { makeStyles } from '@material-ui/core/styles';
import EmptyViewMessage from '../EmptyViewMessage';

const useStyles = makeStyles((theme) => ({
  logo: {
    margin: theme.spacing(3),
  },
  wrapperContent: {
    width: 200,
    height: 'auto',
    textAlign: 'center',
  },
  wrapperRoot: {
    height: '100vh',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const LoadingScreen = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapperRoot}>
      <div className={classes.wrapperContent}>
        <EmptyViewMessage
          heading="No Internet"
          message="Please check internet connection and reload the page."
        />
      </div>
    </div>
  );
};

export default LoadingScreen;
