import _ from 'lodash';
import numeral from 'numeral';

export const getProductName = (sku, skus) => {
  const skuInfo = skus.find((info) => info.sku === sku);
  if (skuInfo) {
    return skuInfo.variation === 'N/A'
      ? `${skuInfo.brand} - ${skuInfo.productName}`
      : `${skuInfo.brand} - ${skuInfo.productName} (${skuInfo.variation})`;
  }
  return 'Unknown';
};

export const getFormattedListings = (
  skus,
  warehouses,
  searchQuery,
  clientFilter,
  platformFilter,
  statusFilter
) => {
  return skus
    .map((info) => {
      const skuInfo = {
        sku: info.sku,
        barcode:
          platformFilter === 'AMAZON'
            ? info.amazon.barcode
            : info.takealot.barcode,
        asin: info.amazon.childAsin,
        tsin: info.takealot.tsin,
        clientName: info.clientName,
        brand: info.brand,
        productName: info.productName,
        variation: info.variation,
        product: getProductName(info.sku, skus),
        thirtyDaySales: info.takealot.thirtyDaySales,
        status:
          platformFilter === 'AMAZON'
            ? info.amazon.status
            : info.takealot.status,
        creationDate: info.creationDate || '-',
      };

      warehouses.forEach((warehouse) => {
        if (info.stockQuantities) {
          skuInfo[warehouse.id] = info.stockQuantities[warehouse.id];
        } else {
          skuInfo[warehouse.id] = null;
        }
      });

      return skuInfo;
    })
    .filter((info) => {
      if (searchQuery !== '') {
        const caseInsensitiveSearch = _.toLower(searchQuery);
        const caseInsensitiveSku = _.toLower(info.sku);
        const caseInsensitiveAsin = _.toLower(info.asin);
        const caseInsensitiveTsin = _.toLower(info.tsin);
        const caseInsensitiveVariation = _.toLower(info.variation);
        const caseInsensitiveProductName = _.toLower(info.productName);
        const caseInsensitiveClientName = _.toLower(info.clientName);
        const caseInsensitiveBrand = _.toLower(info.brand);
        const caseInsensitiveBarcode = _.toLower(info.barcode);

        if (caseInsensitiveSku.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveAsin.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveTsin.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveVariation.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveProductName.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveClientName.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveBrand.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveBarcode.includes(caseInsensitiveSearch)) {
          return true;
        }

        return false;
      }

      return true;
    })
    .filter((info) => {
      if (clientFilter !== 'ALL') {
        return info.clientName === clientFilter;
      }
      return true;
    })
    .filter((info) => {
      if (statusFilter !== 'ALL') {
        return info.status === statusFilter;
      }
      return true;
    })
    .sort((a, b) => {
      if (a.creationDate < b.creationDate) return +1;
      if (a.creationDate > b.creationDate) return -1;
      if (a.clientName < b.clientName) return -1;
      if (a.clientName > b.clientName) return +1;
      if (a.brand < b.brand) return -1;
      if (a.brand > b.brand) return +1;
      if (a.productName < b.productName) return -1;
      if (a.productName > b.productName) return +1;
      if (a.variation < b.variation) return -1;
      if (a.variation > b.variation) return +1;
      return 0;
    });
};

export const getWarehouseTotals = (skus, warehouses, platformFilter) => {
  return warehouses
    .filter((warehouse) => {
      if (platformFilter === 'AMAZON') {
        return (
          warehouse.name !== 'Takealot JHB' && warehouse.name !== 'Takealot CPT'
        );
      }
      return warehouse.name !== 'Amazon US';
    })
    .map((warehouse) => {
      const total = skus.reduce((totalUnits, currentSku) => {
        if (currentSku[warehouse.id]) {
          return totalUnits + currentSku[warehouse.id];
        }
        return totalUnits;
      }, 0);

      return {
        value: numeral(total).format('0,0'),
        label: warehouse.name,
      };
    });
};

export const findSku = (sku, skus) => {
  const skuInfo = skus.find((info) => info.sku === sku);
  return skuInfo;
};
