import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import numeral from 'numeral';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  loadingWrapper: {
    padding: theme.spacing(8),
    textAlign: 'center',
  },
}));

const BasicInfoCard = ({
  creationDate,
  status,
  fromWarehouse,
  toWarehouse,
  totalInsuranceValue,
  totalUnits,
  totalSkus,
  cost,
  isLoading,
  onMissingInfoClick,
}) => {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader title="Shipment Info" />
      <Divider />
      <CardContent>
        {isLoading ? (
          <div className={classes.loadingWrapper}>
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <List>
            <ListItem>
              <ListItemText
                primary={moment(creationDate).format('D MMMM YYYY')}
                secondary="Creation Date"
              />
            </ListItem>
            <ListItem>
              <ListItemText primary={fromWarehouse} secondary="From" />
            </ListItem>
            <ListItem>
              <ListItemText primary={toWarehouse} secondary="To" />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={`R ${numeral(totalInsuranceValue).format('0,0.00')}`}
                secondary="Total Insurance Value"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={numeral(totalSkus).format('0,0')}
                secondary="Total SKUs"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={numeral(totalUnits).format('0,0')}
                secondary="Total Units"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  cost === 0
                    ? 'Missing Info'
                    : `R ${numeral(cost).format('0,0.00')}`
                }
                secondary="Cost"
              />
              {cost === 0 &&
                status !== 'CANCELLED' &&
                status !== 'COMPLETE' && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={onMissingInfoClick}
                    >
                      <HelpOutlineIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
            </ListItem>
          </List>
        )}
      </CardContent>
    </Card>
  );
};

BasicInfoCard.defaultProps = {
  isLoading: false,
  status: 'DELAYED',
  creationDate: moment().toDate().toISOString(),
  fromWarehouse: 'EasyOnline',
  toWarehouse: 'Takealot JHB',
  totalInsuranceValue: 128,
  totalUnits: 3,
  totalSkus: 63,
  cost: 0,
  onMissingInfoClick: () => console.log('Missing info icon was clicked.'),
};

BasicInfoCard.propTypes = {
  isLoading: PropTypes.bool,
  status: PropTypes.string,
  creationDate: PropTypes.string,
  fromWarehouse: PropTypes.string,
  toWarehouse: PropTypes.string,
  totalInsuranceValue: PropTypes.number,
  totalSkus: PropTypes.number,
  totalUnits: PropTypes.number,
  cost: PropTypes.number,
  onMissingInfoClick: PropTypes.func,
};

export default BasicInfoCard;
