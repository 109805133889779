/* eslint-disable react/prop-types */
import { fade } from '@material-ui/core/styles/colorManipulator';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import MaterialTable from 'material-table';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

const headerStyle = {
  backgroundColor: '#A7A5A5',
  color: '#171415',
};

const useStyles = makeStyles((theme) => ({
  loadingOverlay: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: fade(theme.palette.common.black, 0.8),
  },
  wrapperContent: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

const WeightAndDimensionsTable = ({ products, updateProducts }) => {
  const classes = useStyles();

  const columns = [
    {
      title: 'Description',
      field: 'description',
      editable: 'never',
    },
    {
      title: 'Type',
      field: 'measurementType',
      render: (rowData) => (
        <Select
          fullWidth
          value={rowData.measurementType}
          onChange={(e) => {
            updateProducts(
              products.map((productInfo) => {
                if (productInfo.id === rowData.id) {
                  if (e.target.value === 'ESTIMATED') {
                    return {
                      ...productInfo,
                      weight: 1,
                      height: 1,
                      length: 1,
                      width: 1,
                      measurementType: e.target.value,
                    };
                  }
                  return {
                    ...productInfo,
                    measurementType: e.target.value,
                  };
                }
                return productInfo;
              })
            );
          }}
        >
          <MenuItem value="ESTIMATED">Estimated</MenuItem>
          <MenuItem value="ACTUAL">Actual</MenuItem>
        </Select>
      ),
    },
    {
      title: 'Weight',
      field: 'weight',
      render: (rowData) => (
        <TextField
          InputProps={{
            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
          }}
          type="number"
          value={rowData.weight}
          disabled={rowData.measurementType === 'ESTIMATED'}
          onChange={(e) => {
            updateProducts(
              products.map((productInfo) => {
                if (productInfo.id === rowData.id) {
                  return {
                    ...productInfo,
                    weight: e.target.value,
                  };
                }
                return productInfo;
              })
            );
          }}
        />
      ),
    },
    {
      title: 'Length',
      field: 'length',
      render: (rowData) => (
        <TextField
          InputProps={{
            endAdornment: <InputAdornment position="end">cm</InputAdornment>,
          }}
          type="number"
          value={rowData.length}
          disabled={rowData.measurementType === 'ESTIMATED'}
          onChange={(e) => {
            updateProducts(
              products.map((productInfo) => {
                if (productInfo.id === rowData.id) {
                  return {
                    ...productInfo,
                    length: e.target.value,
                  };
                }
                return productInfo;
              })
            );
          }}
        />
      ),
    },
    {
      title: 'Width',
      field: 'width',
      render: (rowData) => (
        <TextField
          InputProps={{
            endAdornment: <InputAdornment position="end">cm</InputAdornment>,
          }}
          type="number"
          value={rowData.width}
          disabled={rowData.measurementType === 'ESTIMATED'}
          onChange={(e) => {
            updateProducts(
              products.map((productInfo) => {
                if (productInfo.id === rowData.id) {
                  return {
                    ...productInfo,
                    width: e.target.value,
                  };
                }
                return productInfo;
              })
            );
          }}
        />
      ),
    },
    {
      title: 'Height',
      field: 'height',
      render: (rowData) => (
        <TextField
          InputProps={{
            endAdornment: <InputAdornment position="end">cm</InputAdornment>,
          }}
          type="number"
          value={rowData.height}
          disabled={rowData.measurementType === 'ESTIMATED'}
          onChange={(e) => {
            updateProducts(
              products.map((productInfo) => {
                if (productInfo.id === rowData.id) {
                  return {
                    ...productInfo,
                    height: e.target.value,
                  };
                }
                return productInfo;
              })
            );
          }}
        />
      ),
    },
  ];

  return (
    <div className={classes.wrapperContent}>
      <MaterialTable
        columns={columns}
        data={products}
        options={{
          headerStyle,
          sorting: false,
          grouping: false,
          draggable: false,
          search: false,
          showTitle: false,
          toolbar: false,
          paging: false,
        }}
        components={{
          OverlayLoading: () => (
            <div className={classes.loadingOverlay}>
              <CircularProgress color="primary" size={32} />
            </div>
          ),
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'Products not yet added.',
          },
        }}
      />
    </div>
  );
};

WeightAndDimensionsTable.defaultProps = {
  products: [],
  updateProducts: (newProducts) =>
    console.log('Products were updated to:', newProducts),
};

WeightAndDimensionsTable.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape()),
  updateProducts: PropTypes.func,
};

export default WeightAndDimensionsTable;
