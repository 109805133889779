import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
  },
  loadingIndicator: {
    color: theme.palette.common.white,
  },
  loadingWrapper: {
    textAlign: 'center',
    margin: theme.spacing(4),
  },
}));

const FinalizeDealDialog = ({
  isOpen,
  isLoading,
  closeDialog,
  versions,
  finalizeDeal,
}) => {
  const classes = useStyles();

  const [selectedVersion, setSelectedVersion] = useState('1');

  useEffect(() => {
    if (isOpen) {
      setSelectedVersion('1');
    }
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onClose={closeDialog}>
      <DialogTitle>Finalize Deal</DialogTitle>
      <DialogContent>
        {isLoading ? (
          <div className={classes.loadingWrapper}>
            <CircularProgress className={classes.loadingIndicator} />
          </div>
        ) : (
          <>
            <DialogContentText>
              Please select the version of the deal the client has decided to go
              with:
            </DialogContentText>
            <FormControl component="fieldset" className={classes.formControl}>
              <RadioGroup
                aria-label="version"
                name="version"
                value={selectedVersion}
                onChange={(e) => setSelectedVersion(e.target.value)}
              >
                {versions.map((versionNumber) => (
                  <FormControlLabel
                    value={versionNumber.toString()}
                    control={<Radio />}
                    label={`Version ${versionNumber}`}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={closeDialog}>
          Cancel
        </Button>
        <Button
          disabled={isLoading}
          variant="contained"
          color="secondary"
          onClick={() => finalizeDeal(parseInt(selectedVersion, 10))}
        >
          Finalize
        </Button>
      </DialogActions>
    </Dialog>
  );
};

FinalizeDealDialog.defaultProps = {
  isOpen: true,
  isLoading: false,
  closeDialog: () => console.log('Dialog was closed.'),
  versions: [1],
  finalizeDeal: (versionNumber) =>
    console.log(
      `Deal was finalized based on proposal Version ${versionNumber}.`
    ),
};

FinalizeDealDialog.propTypes = {
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  closeDialog: PropTypes.func,
  versions: PropTypes.arrayOf(PropTypes.number),
  finalizeDeal: PropTypes.func,
};

export default FinalizeDealDialog;
