export const getSkuName = (brand, productName, variation) => {
  if (variation === 'N/A') {
    return `${brand} - ${productName}`;
  }
  return `${brand} - ${productName} (${variation})`;
};

export const getLastVersion = (copies) => {
  let highestVesrion = 0;
  let lastCopy;
  if (copies.length > 0) {
    copies.map((copy) => {
      if (copy.version > highestVesrion) {
        highestVesrion = copy.version;
        lastCopy = copy;
      }
      return copy;
    });
    return lastCopy;
  }
  return '';
};
