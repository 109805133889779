const getStyles = (theme) => ({
  actionsBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  alert: {
    marginBottom: theme.spacing(3),
  },
  formControl: {
    width: '100%',
  },
  formWrapper: {
    padding: `${theme.spacing(4)}px ${theme.spacing(1)}px`,
  },
  separator: {
    height: theme.spacing(2),
  },
  wrapperStep: {
    padding: `${theme.spacing(4)}px 0px`,
  },
  wrapperWizard: {
    width: '100%',
    padding: theme.spacing(4),
  },
});

export default getStyles;
