import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import BarcodesToggle from './components/BarcodesToggle';
import CategoriesToggle from './components/CategoriesToggle';
import CopyToggle from './components/CopyToggle';
import ImagesToggle from './components/ImagesToggle';
import MeasurementsToggle from './components/MeasurementsToggle';

const useStyles = makeStyles((theme) => ({
  divider: {
    height: theme.spacing(2),
  },
}));

const OnboardingStatusRefinement = () => {
  const classes = useStyles();

  return (
    <div>
      <ListSubheader>Onboarding Statuses</ListSubheader>
      <MeasurementsToggle attribute="onboardingStatuses.measurementsComplete" />
      <div className={classes.divider} />
      <CopyToggle attribute="onboardingStatuses.copyComplete" />
      <div className={classes.divider} />
      <ImagesToggle attribute="onboardingStatuses.imagesComplete" />
      <div className={classes.divider} />
      <CategoriesToggle attribute="onboardingStatuses.categoriesComplete" />
      <div className={classes.divider} />
      <BarcodesToggle attribute="onboardingStatuses.barcodesComplete" />
    </div>
  );
};

OnboardingStatusRefinement.defaultProps = {};

OnboardingStatusRefinement.propTypes = {};

export default OnboardingStatusRefinement;
