import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import { closeNav, openNav } from '../../pages/Account/slice';
import AppBar from './components/AppBar';
import PresalesSidebar from './components/PresalesSidebar';
import StandardSideBar from './components/StandardSideBar';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    marginTop: theme.spacing(8),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  root: {
    display: 'flex',
    height: '100vh',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
}));

const Navigation = ({
  children,
  username,
  jobTitle,
  userType,
  signOut,
  version,
  isSuperAdmin,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const matches = useMediaQuery('(max-width:1280px)');
  const [view, setView] = useState(() => {
    const saved = localStorage.getItem('view');
    const initialValue = JSON.parse(saved);
    return initialValue || 'STANDARD';
  });

  const getPathWithoutParams = (path) => {
    const paramStartIndex = path.indexOf(':');
    if (paramStartIndex === -1) {
      return path;
    }
    return path.slice(0, paramStartIndex - 1);
  };

  const isNavOpen = useSelector((state) => state.account.isNavOpen);

  useEffect(() => {
    if (matches) {
      dispatch(closeNav());
    }
  }, [matches]);

  useEffect(() => {
    localStorage.setItem('view', JSON.stringify(view));
    const path = getPathWithoutParams(match.path);

    if (view === 'STANDARD') {
      if (path.includes('/presales')) {
        history.push('/sales/deals/browse');
      }
    } else if (!path.includes('/presales')) {
      history.push('/presales/products/browse');
    }
  }, [view]);

  return (
    <div className={classes.root}>
      <AppBar
        signOut={signOut}
        version={version}
        isOpen={isNavOpen}
        openDrawer={() => dispatch(openNav())}
        view={view}
        changeView={setView}
        userType={userType}
      />
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: isNavOpen,
          [classes.drawerClose]: !isNavOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: isNavOpen,
            [classes.drawerClose]: !isNavOpen,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={() => dispatch(closeNav())}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        {view === 'STANDARD' && (
          <StandardSideBar
            isOpen={isNavOpen}
            username={username}
            jobTitle={jobTitle}
            userType={userType}
            isSuperAdmin={isSuperAdmin}
            activeSectionLink={getPathWithoutParams(match.path)}
            goToLink={(link) => history.push(link)}
          />
        )}
        {view === 'PRESALES' && (
          <PresalesSidebar
            isOpen={isNavOpen}
            username={username}
            jobTitle={jobTitle}
            userType={userType}
            isSuperAdmin={isSuperAdmin}
            activeSectionLink={getPathWithoutParams(match.path)}
            goToLink={(link) => history.push(link)}
          />
        )}
      </Drawer>
      <Scrollbars>
        <main className={classes.content}>{children}</main>
      </Scrollbars>
    </div>
  );
};

Navigation.defaultProps = {
  children: <div />,
  version: '1.0.0',
  signOut: () => console.log('User has signed out.'),
  username: 'John Doe',
  jobTitle: 'Administrator',
  userType: 'ADMIN',
  isSuperAdmin: true,
};

Navigation.propTypes = {
  children: PropTypes.node,
  version: PropTypes.string,
  signOut: PropTypes.func,
  username: PropTypes.string,
  jobTitle: PropTypes.string,
  userType: PropTypes.string,
  isSuperAdmin: PropTypes.bool,
};

export default Navigation;
