import clsx from 'clsx';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar as MuiAppBar } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ViewSelector from './components/ViewSelector';
import logo from './images/logo.png';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  flexGrow: {
    flexGrow: 1,
  },
  hide: {
    display: 'none',
  },
  logo: {
    width: 140,
    height: 'auto',
    marginRight: theme.spacing(2),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  version: {
    marginRight: theme.spacing(2),
    color: grey[600],
    fontSize: 14,
  },
}));

const AppBar = ({
  signOut,
  version,
  isOpen,
  openDrawer,
  view,
  changeView,
  userType,
}) => {
  const classes = useStyles();

  return (
    <MuiAppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: isOpen,
      })}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={openDrawer}
          edge="start"
          className={clsx(classes.menuButton, {
            [classes.hide]: isOpen,
          })}
        >
          <MenuIcon />
        </IconButton>
        <img src={logo} alt="EasyOnline Logo" className={classes.logo} />
        {userType === 'ADMIN' && (
          <ViewSelector viewFilter={view} changeViewFilter={changeView} />
        )}
        <div className={classes.flexGrow} />
        <div>
          <Typography
            className={classes.version}
            variant="body1"
            component="span"
          >
            {`v${version}`}
          </Typography>
          <Button variant="outlined" color="secondary" onClick={signOut}>
            Log out
          </Button>
        </div>
      </Toolbar>
    </MuiAppBar>
  );
};

AppBar.defaultProps = {
  signOut: () => console.log('User has logged out.'),
  version: '1.0.0',
  isOpen: true,
  openDrawer: () => console.log('The drawer was opened.'),
  view: 'STANDARD',
  changeView: () => console.log('The view was changed.'),
  userType: 'SALES_AGENT',
};

AppBar.propTypes = {
  signOut: PropTypes.func,
  version: PropTypes.string,
  isOpen: PropTypes.bool,
  openDrawer: PropTypes.func,
  view: PropTypes.string,
  changeView: PropTypes.func,
  userType: PropTypes.string,
};

export default AppBar;
