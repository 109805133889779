import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import MaterialTable from 'material-table';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import allStyles from '../../../../../utils/styles';

const headerStyle = {
  backgroundColor: '#A7A5A5',
  color: '#171415',
};

const useStyles = makeStyles(allStyles);

const PreviewTable = ({ csvData, supplierSkuIndex, costPerUnitIndex }) => {
  const classes = useStyles();

  const formattedCsvData = () => {
    const result = csvData
      .filter((_, index) => index < 5)
      .map((item) => {
        return {
          supplierSku: item[supplierSkuIndex],
          costPerUnit: numeral(item[costPerUnitIndex]).value(),
        };
      });
    return result;
  };

  const columns = [
    {
      title: 'Supplier SKU',
      field: 'supplierSku',
      editable: 'never',
    },
    {
      title: 'Cost Per Unit',
      field: 'costPerUnit',
      editable: 'never',
    },
  ];

  return (
    <div className={classes.wrapperContent}>
      <MaterialTable
        columns={columns}
        data={formattedCsvData()}
        options={{
          headerStyle,
          sorting: false,
          grouping: false,
          draggable: false,
          search: false,
          showTitle: false,
          toolbar: false,
          selection: false,
        }}
        components={{
          OverlayLoading: () => (
            <div className={classes.loadingOverlay}>
              <CircularProgress color="primary" size={32} />
            </div>
          ),
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'No available products.',
          },
        }}
      />
    </div>
  );
};

PreviewTable.defaultProps = {
  csvData: [],
  supplierSkuIndex: 0,
  costPerUnitIndex: 0,
};

PreviewTable.propTypes = {
  csvData: PropTypes.arrayOf(PropTypes.shape()),
  supplierSkuIndex: PropTypes.number,
  costPerUnitIndex: PropTypes.number,
};

export default PreviewTable;
