/* eslint-disable react/jsx-wrap-multilines */
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClearIcon from '@material-ui/icons/Clear';
import Divider from '@material-ui/core/Divider';
import DoneIcon from '@material-ui/icons/Done';
import GetAppIcon from '@material-ui/icons/GetApp';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import ProposalDocument from '../../../../../components/ProposalDocument';

const useStyles = makeStyles((theme) => ({
  pdfButtonInner: {
    display: 'flex',
    alignItems: 'center',
  },
  pdfButtonOuter: {
    color: theme.palette.common.white,
    textDecoration: 'none',
  },
  statusAvatar: {
    backgroundColor: theme.palette.secondary.light,
  },
  statusFinalized: {
    backgroundColor: green[500],
  },
}));

const DocumentsCard = ({
  proposals,
  invoices,
  dealType,
  finalizedVersion,
  batchCode,
}) => {
  const classes = useStyles();

  const getPlatformText = (platform) => {
    switch (platform) {
      case 'AMAZON':
        return 'Amazon';
      case 'TAKEALOT':
        if (dealType === 'SUPER_DEALER') {
          return 'Takealot Product List';
        }
        return 'Takealot';
      case 'AMAZON_TAKEALOT':
        return 'Amazon & Takealot';
      default:
        return 'Unknown';
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case 'FINALIZED':
        return 'Finalized Version';
      case 'IN_PROGRESS':
        return 'In progress';
      case 'COMPLETE':
        return 'Ready';
      case 'CANCELLED':
        return 'Cancelled';
      default:
        return 'Unknown';
    }
  };

  const getStatusAvatar = (status) => {
    switch (status) {
      case 'FINALIZED':
        return (
          <Avatar className={classes.statusFinalized}>
            <DoneIcon />
          </Avatar>
        );
      case 'IN_PROGRESS':
        return (
          <Avatar className={classes.statusAvatar}>
            <AutorenewIcon />
          </Avatar>
        );
      case 'COMPLETE':
        return (
          <Avatar className={classes.statusAvatar}>
            <DoneIcon />
          </Avatar>
        );
      case 'CANCELLED':
        return (
          <Avatar className={classes.statusAvatar}>
            <ClearIcon />
          </Avatar>
        );
      default:
        return <Avatar />;
    }
  };

  return (
    <Card>
      <CardHeader title="Documents" />
      <Divider />
      <CardContent>
        <List>
          {invoices.map((info) => (
            <ListItem key={info.versionNumber}>
              <ListItemAvatar>
                {getStatusAvatar(
                  finalizedVersion === info.versionNumber
                    ? 'FINALIZED'
                    : info.status
                )}
              </ListItemAvatar>
              <ListItemText
                primary={
                  info.status === 'COMPLETE'
                    ? `Invoice #${info.number} (Requested on ${moment(
                        info.creationDate
                      ).format('d MMMM YYYY')})`
                    : `Invoice (Requested on ${moment(info.creationDate).format(
                        'd MMMM YYYY'
                      )})`
                }
                secondary={getStatusText(
                  finalizedVersion === info.versionNumber
                    ? 'FINALIZED'
                    : info.status
                )}
              />
              <ListItemSecondaryAction>
                <IconButton edge="end" />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
          {proposals
            .slice()
            .sort((infoA, infoB) => {
              if (infoA.versionNumber === finalizedVersion) return -1;
              if (infoA.versionNumber < infoB.versionNumber) return -1;
              if (infoA.versionNumber > infoB.versionNumber) return +1;
              return 0;
            })
            .map((info) => (
              <ListItem key={info.versionNumber}>
                <ListItemAvatar>
                  {getStatusAvatar(
                    finalizedVersion === info.versionNumber
                      ? 'FINALIZED'
                      : info.status
                  )}
                </ListItemAvatar>
                <ListItemText
                  primary={`Proposal - ${getPlatformText(
                    info.platform
                  )} (Version ${info.versionNumber})`}
                  secondary={getStatusText(
                    finalizedVersion === info.versionNumber
                      ? 'FINALIZED'
                      : info.status
                  )}
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end">
                    <PDFDownloadLink
                      className={classes.pdfButtonOuter}
                      document={
                        <ProposalDocument
                          proposal={info}
                          dealType={dealType}
                          batchCode={batchCode}
                        />
                      }
                      fileName={
                        dealType === 'SUPER_DEALER'
                          ? `[${
                              info.businessName
                            }] Super Dealer Product List - ${moment(
                              info.creationDate
                            ).format('DDMMYYYY')}.pdf`
                          : `[${
                              info.businessName
                            }] Takealot Proposal - ${moment(
                              info.creationDate
                            ).format('DDMMYYYY')}.pdf`
                      }
                    >
                      {({ loading }) => {
                        if (loading) {
                          return (
                            <CircularProgress color="secondary" size="small" />
                          );
                        }
                        return (
                          <div className={classes.pdfButtonInner}>
                            <GetAppIcon />
                          </div>
                        );
                      }}
                    </PDFDownloadLink>
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
        </List>
      </CardContent>
    </Card>
  );
};

DocumentsCard.defaultProps = {
  finalizedVersion: 1,
  dealType: 'NEW',
  proposals: [
    {
      id: '1234',
      platform: 'TAKEALOT',
      versionNumber: 2,
      status: 'IN_PROGRESS',
    },
    {
      id: '5678',
      platform: 'TAKEALOT',
      versionNumber: 1,
      status: 'CANCELLED',
    },
    {
      id: '9101',
      platform: 'AMAZON',
      versionNumber: 1,
      status: 'COMPLETE',
    },
  ],
  invoices: [
    {
      number: '1234',
      versionNumber: 2,
      status: 'IN_PROGRESS',
    },
    {
      number: '5678',
      versionNumber: 1,
      status: 'CANCELLED',
    },
    {
      number: '9101',
      versionNumber: 1,
      status: 'COMPLETE',
    },
  ],
  batchCode: '001012021',
};

DocumentsCard.propTypes = {
  finalizedVersion: PropTypes.number,
  dealType: PropTypes.oneOf(['ADD_ON', 'NEW', 'SUPER_DEALER']),
  proposals: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      platform: PropTypes.oneOf(['AMAZON', 'TAKEALOT']),
      versionNumber: PropTypes.number,
      status: PropTypes.oneOf(['IN_PROGRESS', 'COMPLETE', 'CANCELLED']),
    })
  ),
  invoices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      platform: PropTypes.oneOf(['AMAZON', 'TAKEALOT']),
      versionNumber: PropTypes.number,
      status: PropTypes.oneOf(['IN_PROGRESS', 'COMPLETE', 'CANCELLED']),
    })
  ),
  batchCode: PropTypes.string,
};

export default DocumentsCard;
