import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import BuildIcon from '@material-ui/icons/Build';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  description: {
    color: grey[700],
  },
  icon: {
    marginRight: theme.spacing(2),
    color: grey[700],
  },
  wrapperContent: {
    width: 460,
    textAlign: 'center',
  },
  wrapperHeading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.primary.dark}`,
    color: grey[700],
  },
  wrapperRoot: {
    width: '100%',
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const UnderConstruction = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapperRoot}>
      <div className={classes.wrapperContent}>
        <div className={classes.wrapperHeading}>
          <BuildIcon fontSize="large" className={classes.icon} />
          <Typography
            variant="h4"
            component="h2"
            className={classes.headingMain}
          >
            Under Construction
          </Typography>
        </div>
        <Typography
          variant="body1"
          component="p"
          className={classes.description}
        >
          {`This section's functionality is still in development. Check in
        soon for updates.`}
        </Typography>
      </div>
    </div>
  );
};

export default UnderConstruction;
