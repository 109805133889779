import { useState } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PeopleIcon from '@material-ui/icons/People';
import PropTypes from 'prop-types';

const AgencySelector = ({ agencyId, changeAgency, agencies }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const getAgencyName = (id) => {
    if (id === 'ALL') {
      return 'All Agencies';
    }

    const agencyInfo = agencies.find((info) => info.id === id);
    if (!agencyInfo) {
      return 'Unknown';
    }

    return agencyInfo.name;
  };

  return (
    <>
      <Button
        aria-controls="agencies-menu"
        aria-haspopup="true"
        size="large"
        startIcon={<PeopleIcon />}
        onClick={openMenu}
      >
        {getAgencyName(agencyId)}
      </Button>
      <Menu
        id="agencies-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem
          onClick={() => {
            changeAgency('ALL');
            closeMenu();
          }}
        >
          All
        </MenuItem>
        {agencies
          .map((info) => info)
          .sort((a, b) => {
            if (a.name > b.name) return +1;
            if (a.name < b.name) return -1;
            return 0;
          })
          .map((info) => (
            <MenuItem
              key={info.id}
              onClick={() => {
                changeAgency(info.id);
                closeMenu();
              }}
            >
              {info.name}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

AgencySelector.defaultProps = {
  agencyId: 'ALL',
  changeAgency: (newValue) => `Sales person was changed to '${newValue}'`,
  agencies: [
    {
      id: 1,
      name: 'Madeup Agency',
    },
    {
      id: 2,
      name: 'Imaginary Inc.',
    },
  ],
};

AgencySelector.propTypes = {
  agencyId: PropTypes.string,
  changeAgency: PropTypes.func,
  agencies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
};

export default AgencySelector;
