/* eslint-disable react/jsx-props-no-spreading */
import _ from 'lodash';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { green, grey, red, yellow } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import MaterialTable, { MTableHeader } from 'material-table';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import WarningIcon from '@material-ui/icons/Warning';

const headerStyle = {
  backgroundColor: '#171415',
};

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.common.black,
  },
  link: {
    color: theme.palette.common.black,
  },
  loadingOverlay: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: fade(theme.palette.common.black, 0.8),
  },
  statusActive: {
    color: theme.palette.common.black,
    backgroundColor: green[500],
  },
  statusInactive: {
    color: theme.palette.common.black,
    backgroundColor: red[500],
  },
  statusOnboarding: {
    color: theme.palette.common.black,
    backgroundColor: yellow[500],
  },
  tooltipWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  errorIcon: {
    marginRight: theme.spacing(1),
    color: red[600],
  },
  warningIcon: {
    marginRight: theme.spacing(1),
    color: yellow[500],
  },
  wrapperContent: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

const ReplenishmentsTable = ({
  destinationFilter,
  warningsFilter,
  skus,
  isLoading,
  updatePageSize,
  updateInitialPage,
  pageSize,
  initialPage,
  changeUnits,
  viewSku,
  updateSelectedSkus,
  selectedSkus,
}) => {
  const classes = useStyles();
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const [feedbackSeverity, setFeedbackSeverity] = useState('error');
  const [feedbackMessage, setFeedbackMessage] = useState('');

  const getColumns = () => {
    let columns = [
      {
        title: destinationFilter === 'AMAZON' ? 'ASIN' : 'TSIN',
        field: destinationFilter === 'AMAZON' ? 'childAsin' : 'tsin',
        editable: false,
        render: (rowData) => {
          if (rowData.status === 'NOT_BUYABLE') {
            return (
              <div className={classes.tooltipWrapper}>
                <Tooltip title="Not Buyable">
                  <WarningIcon className={classes.warningIcon} />
                </Tooltip>
                {destinationFilter === 'AMAZON'
                  ? rowData.childAsin
                  : rowData.tsin}
              </div>
            );
          }
          if (rowData.status === 'DISABLED_BY_SELLER') {
            return (
              <div className={classes.tooltipWrapper}>
                <Tooltip title="Disabled by Seller">
                  <WarningIcon className={classes.errorIcon} />
                </Tooltip>
                {destinationFilter === 'AMAZON'
                  ? rowData.childAsin
                  : rowData.tsin}
              </div>
            );
          }
          if (rowData.status === 'DISABLED_BY_TAKEALOT') {
            return (
              <div className={classes.tooltipWrapper}>
                <Tooltip title="Disabled by Takealot">
                  <WarningIcon className={classes.errorIcon} />
                </Tooltip>
                {destinationFilter === 'AMAZON'
                  ? rowData.childAsin
                  : rowData.tsin}
              </div>
            );
          }
          return destinationFilter === 'AMAZON'
            ? rowData.childAsin
            : rowData.tsin;
        },
      },
      {
        title: 'Client',
        field: 'clientName',
        hidden: true,
        export: true,
      },
      {
        title: 'Product',
        editable: false,
        field: 'product',
        render: (rowData) => {
          return (
            <Tooltip title={rowData.product} aria-label="add" interactive>
              <Typography variant="body2" component="span">
                {_.truncate(rowData.product)}
              </Typography>
            </Tooltip>
          );
        },
      },
    ];

    if (destinationFilter === 'AMAZON') {
      columns = [
        ...columns,
        {
          title: 'Recommended Units',
          field: 'recommendedUnits',
          type: 'numeric',
          editable: false,
          align: 'center',
          cellStyle: {
            borderLeft: `1px solid ${grey[700]}`,
          },
        },
        {
          title: 'Units to Send',
          field: 'unitsToSend',
          type: 'numeric',
          align: 'center',
        },
      ];
    } else if (destinationFilter === 'TAKEALOT') {
      columns = [
        ...columns,
        {
          title: '20 Day Sales',
          field: 'recentSales',
          type: 'numeric',
          align: 'center',
          editable: false,
          cellStyle: {
            borderLeft: `1px solid ${grey[700]}`,
          },
        },
        {
          title: 'CPT',
          field: 'inboundTakealotCpt',
          type: 'numeric',
          align: 'center',
          editable: false,
          cellStyle: {
            borderLeft: `1px solid ${grey[700]}`,
          },
        },
        {
          title: 'JHB',
          field: 'inboundTakealotJhb',
          type: 'numeric',
          align: 'center',
          editable: false,
        },
        {
          title: 'EasyOnline',
          field: 'inStockEasyOnline',
          type: 'numeric',
          align: 'center',
          editable: false,
          render: (rowData) => {
            if (
              rowData.inStockEasyOnline + 1 <
              rowData.jhbUnitsToSend + rowData.cptUnitsToSend
            ) {
              return (
                <div className={classes.tooltipWrapper}>
                  <Tooltip title="Not enough units at EasyOnline for replenishment.">
                    <WarningIcon className={classes.warningIcon} />
                  </Tooltip>
                  {rowData.inStockEasyOnline}
                </div>
              );
            }
            return rowData.inStockEasyOnline;
          },
          cellStyle: {
            borderLeft: `1px solid ${grey[700]}`,
          },
        },
        {
          title: 'CPT',
          field: 'cptInStock',
          type: 'numeric',
          align: 'center',
          editable: false,
        },
        {
          title: 'JHB',
          field: 'jhbInStock',
          type: 'numeric',
          editable: false,
          align: 'center',
        },
        {
          title: 'CPT',
          field: 'cptUnitsToSend',
          type: 'numeric',
          align: 'center',
          cellStyle: {
            borderLeft: `1px solid ${grey[700]}`,
          },
        },
        {
          title: 'JHB',
          field: 'jhbUnitsToSend',
          type: 'numeric',
          align: 'center',
          cellStyle: {
            borderRight: `1px solid ${grey[700]}`,
          },
        },
      ];
    } else if (destinationFilter === 'EASYONLINE') {
      columns = [
        ...columns,
        {
          title: 'From',
          field: 'fromName',
          editable: false,
        },
        {
          title: '30+ Day Sales',
          field: 'recentSales',
          type: 'numeric',
          align: 'center',
          editable: false,
          cellStyle: {
            borderLeft: `1px solid ${grey[700]}`,
          },
        },
        {
          title: 'Inbound Units',
          field: 'inboundUnits',
          type: 'numeric',
          align: 'center',
          editable: false,
        },
        {
          title: 'At EasyOnline',
          field: 'inStockEasyOnline',
          type: 'numeric',
          align: 'center',
          editable: false,
        },
        {
          title: 'Order Batch Size',
          field: 'batchSize',
          editable: false,
          align: 'center',
          cellStyle: {
            borderRight: `1px solid ${grey[700]}`,
          },
        },
        {
          title: 'Units to Send',
          field: 'unitsToSend',
          type: 'numeric',
          align: 'center',
        },
      ];
    }

    return columns;
  };

  const getTableData = () => {
    if (destinationFilter === 'TAKEALOT') {
      return skus
        .reduce((total, current) => {
          const foundIndex = total.findIndex(
            (info) => info.tsin === current.tsin
          );

          if (foundIndex === -1) {
            return [
              ...total,
              {
                clientName: current.clientName,
                tsin: current.tsin,
                costPrice: current.costPrice,
                childAsin: current.childAsin,
                clientId: current.clientId,
                sku: current.sku,
                status: current.status,
                product: current.product,
                recommendedUnits: current.recommendedUnits,
                inboundTakealotJhb: current.inboundUnits
                  ? current.inboundUnits.toTakealotJhb
                  : 0,
                inboundTakealotCpt: current.inboundUnits
                  ? current.inboundUnits.toTakealotCpt
                  : 0,
                inStock: current.inStock,
                inboundUnits: current.inboundUnits,
                recentSales: current.recentSales,
                inStockEasyOnline: current.inStock
                  ? current.inStock.easyOnline
                  : 0,
                jhbInStock: current.inStock ? current.inStock.takealotJhb : 0,
                cptInStock: current.inStock ? current.inStock.takealotCpt : 0,
                jhbUnitsToSend:
                  current.toName === 'Takealot JHB' ? current.unitsToSend : 0,
                cptUnitsToSend:
                  current.toName === 'Takealot CPT' ? current.unitsToSend : 0,
              },
            ];
          }

          const newTotal = [...total];
          newTotal[foundIndex] = {
            clientName: current.clientName,
            costPrice: current.costPrice,
            tsin: current.tsin,
            childAsin: current.childAsin,
            clientId: current.clientId,
            sku: current.sku,
            status: current.status,
            product: current.product,
            recommendedUnits: current.recommendedUnits,
            inboundTakealotJhb:
              newTotal[foundIndex].inboundTakealotJhb + current.inboundUnits
                ? current.inboundUnits.toTakealotJhb
                : 0,
            inboundTakealotCpt:
              newTotal[foundIndex].inboundTakealotCpt + current.inboundUnits
                ? current.inboundUnits.toTakealotCpt
                : 0,
            inStock: current.inStock,
            inboundUnits: current.inboundUnits,
            recentSales: newTotal[foundIndex].recentSales + current.recentSales,
            inStockEasyOnline: current.inStock ? current.inStock.easyOnline : 0,
            jhbInStock: current.inStock ? newTotal[foundIndex].jhbInStock : 0,
            cptInStock: current.inStock ? newTotal[foundIndex].cptInStock : 0,
            jhbUnitsToSend:
              current.toName === 'Takealot JHB'
                ? current.unitsToSend
                : newTotal[foundIndex].jhbUnitsToSend,
            cptUnitsToSend:
              current.toName === 'Takealot CPT'
                ? current.unitsToSend
                : newTotal[foundIndex].cptUnitsToSend,
          };

          return newTotal;
        }, [])
        .filter((info) => {
          if (warningsFilter !== 'ALL') {
            const { inStockEasyOnline, jhbUnitsToSend, cptUnitsToSend } = info;
            if (warningsFilter === 'WARNINGS_ONLY') {
              return inStockEasyOnline + 1 < jhbUnitsToSend + cptUnitsToSend;
            }
            if (warningsFilter === 'NO_WARNINGS') {
              return inStockEasyOnline + 1 >= jhbUnitsToSend + cptUnitsToSend;
            }
          }
          return true;
        })
        .map((info, index) => ({
          ...info,
          tableData: {
            id: index,
            checked:
              selectedSkus.findIndex(
                (selectedSku) => selectedSku.sku === info.sku
              ) !== -1,
          },
        }));
    }

    return skus.map((info, index) => {
      return {
        ...info,
        inboundUnits: info.inboundUnits ? info.inboundUnits.toEasyOnline : 0,
        inStockEasyOnline: info.inStock ? info.inStock.easyOnline : 0,
        tableData: {
          id: index,
          checked:
            selectedSkus.findIndex(
              (selectedSku) => selectedSku.sku === info.sku
            ) !== -1,
        },
      };
    });
  };

  return (
    <div className={classes.wrapperContent}>
      <MaterialTable
        title=""
        columns={getColumns()}
        data={getTableData(skus)}
        isLoading={isLoading}
        onChangeRowsPerPage={updatePageSize}
        onChangePage={updateInitialPage}
        options={{
          pageSize,
          initialPage,
          headerStyle,
          actionsColumnIndex: -1,
          search: false,
          grouping: false,
          draggable: false,
          exportButton: true,
          exportAllData: true,
          selection: true,
          exportFileName: 'Replenishment Recommendations',
          toolbar: true,
          maxBodyHeight: '600px',
        }}
        editable={{
          onRowUpdate: (newData) =>
            new Promise((resolve) => {
              if (destinationFilter === 'EASYONLINE') {
                if (newData.unitsToSend % newData.batchSize === 0) {
                  changeUnits(
                    'EASYONLINE',
                    newData.sku,
                    newData.unitsToSend,
                    skus.find((info) => info.sku === newData.sku)
                  );
                } else {
                  setFeedbackMessage(
                    'Units sent must be a multiple of the order batch size.'
                  );
                  setFeedbackSeverity('error');
                  setIsFeedbackOpen(true);
                }
              } else if (destinationFilter === 'TAKEALOT') {
                changeUnits(
                  'TAKEALOTJHB',
                  newData.sku,
                  newData.jhbUnitsToSend,
                  skus.find((info) => info.sku === newData.sku)
                );
                changeUnits(
                  'TAKEALOTCPT',
                  newData.sku,
                  newData.cptUnitsToSend,
                  skus.find((info) => info.sku === newData.sku)
                );
              } else {
                changeUnits(
                  'AMAZON',
                  newData.sku,
                  newData.unitsToSend,
                  skus.find((info) => info.sku === newData.sku)
                );
              }
              resolve();
            }),
        }}
        actions={[
          {
            icon: 'keyboard_arrow_right',
            tooltip: 'View SKU info',
            onClick: (event, rowData) => viewSku(rowData.sku),
          },
        ]}
        onSelectionChange={(rows) => updateSelectedSkus(rows)}
        components={{
          Header: (props) => (
            <>
              {destinationFilter === 'TAKEALOT' && (
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={4} />
                    <TableCell colSpan={2} align="center">
                      Inbound
                    </TableCell>
                    <TableCell colSpan={3} align="center">
                      In Stock
                    </TableCell>
                    <TableCell colSpan={2} align="center">
                      Units to Send
                    </TableCell>
                    <TableCell colSpan={1} />
                  </TableRow>
                </TableHead>
              )}
              <MTableHeader {...props} />
            </>
          ),
          OverlayLoading: () => (
            <div className={classes.loadingOverlay}>
              <CircularProgress color="primary" size={32} />
            </div>
          ),
        }}
      />
      <Snackbar
        open={isFeedbackOpen}
        autoHideDuration={6000}
        onClose={() => setIsFeedbackOpen(false)}
      >
        <Alert
          onClose={() => setIsFeedbackOpen(false)}
          severity={feedbackSeverity}
        >
          {feedbackMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

ReplenishmentsTable.defaultProps = {
  destinationFilter: 'TAKEALOT',
  warningsFilter: 'ALL',
  skus: [],
  isLoading: false,
  updatePageSize: () => {},
  updateInitialPage: () => {},
  pageSize: 5,
  initialPage: 0,
  changeUnits: () => {},
  viewSku: () => {},
  selectedSkus: [],
  updateSelectedSkus: () => {},
};

ReplenishmentsTable.propTypes = {
  destinationFilter: PropTypes.string,
  warningsFilter: PropTypes.string,
  skus: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string,
      asin: PropTypes.string,
      tsin: PropTypes.number,
      fromName: PropTypes.string,
      toName: PropTypes.string,
      recommendedUnits: PropTypes.number,
      unitsToSend: PropTypes.number,
    })
  ),
  isLoading: PropTypes.bool,
  updatePageSize: PropTypes.func,
  updateInitialPage: PropTypes.func,
  pageSize: PropTypes.number,
  initialPage: PropTypes.number,
  changeUnits: PropTypes.func,
  viewSku: PropTypes.func,
  selectedSkus: PropTypes.arrayOf({}),
  updateSelectedSkus: PropTypes.func,
};

export default ReplenishmentsTable;
