/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import firebase from 'firebase/app';
import numeral from 'numeral';
import moment from 'moment';
import { firestore, functions } from '../utils/firebase';

export const getPresalesSuppliers = async () => {
  const skuRef = firestore.collection('presalesSuppliers');
  return skuRef
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.length === 0) {
        return {
          status: 500,
        };
      }
      const result = querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });

      return {
        suppliers: result,
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const bulkUpdate = async (skuInfo) => {
  const safeSku = skuInfo.eolSku.replace(/\//g, '');
  const newPresalesSkuRef = firestore.collection('presalesSkus').doc(safeSku);

  return newPresalesSkuRef
    .set(skuInfo)
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      console.log('error', error);
      return {
        sku: skuInfo.supplierSku,
        eolSku: skuInfo.eolSku,
        status: 500,
      };
    });
};

export const updateSkuInfo = async (eolSku, newInfo) => {
  const skuRef = firestore
    .collection('presalesSkus')
    .where('eolSku', '==', eolSku);

  const skuInfo = await skuRef.get().then(async (querySnapshot) => {
    let info = {};

    querySnapshot.forEach((doc) => {
      info = { id: doc.id, ...doc.data() };
    });

    return info;
  });

  return firestore
    .collection('presalesSkus')
    .doc(skuInfo.id)
    .update(newInfo)
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
        sku: skuInfo.supplierSku,
        eolSku: skuInfo.eolSku,
      };
    });
};

export const getPresalesSkus = async () => {
  const skuRef = firestore.collection('presalesSkus');
  return skuRef
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.length === 0) {
        return {
          status: 500,
        };
      }
      const result = querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });

      return {
        skus: result,
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const getUpdateTemplates = async () => {
  const skuRef = firestore
    .collection('presalesUpdatesTemplates')
    .orderBy('requestDate', 'desc')
    .limit(20);

  return skuRef
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.length === 0) {
        return {
          status: 500,
        };
      }
      const result = querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });

      return {
        templates: result,
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const getPresalesSkusBySupplierSkus = async (supplierSku) => {
  const skuRef = firestore
    .collection('presalesSkus')
    .where('supplierSku', '==', supplierSku);
  return skuRef
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.length === 0) {
        return {
          status: 500,
        };
      }
      const result = querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });

      return {
        skus: result,
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const getPresalesSuppliersByName = async (supplierName) => {
  const skuRef = firestore
    .collection('presalesSuppliers')
    .where('name', '==', supplierName);
  return skuRef
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.length === 0) {
        return {
          status: 500,
        };
      }
      const result = querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });

      return {
        suppliers: result,
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const getPricingSkuToUpdate = async (skuData) => {
  const updatePricingInfo = functions.httpsCallable(
    'presales-updatePresaleSkuPricing'
  );

  return updatePricingInfo({
    supplierSku: skuData.supplierSku,
    supplierName: skuData.supplierName,
    costPerUnit: numeral(skuData.costPerUnit).value(),
  })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const getStockSkuToUpdate = async (skuData) => {
  const updatePricingInfo = functions.httpsCallable(
    'presales-updatePresaleSkuStock'
  );

  return updatePricingInfo({
    supplierSku: skuData.supplierSku,
    supplierName: skuData.supplierName,
    soh: numeral(skuData.soh).value(),
  })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const requestOffersUpdates = async () => {
  const requestUpdate = functions.httpsCallable(
    'presalesUpdates-generateBulkUpdate'
  );

  return requestUpdate()
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const rescrapeTakealotPricing = async () => {
  const startScraping = functions.httpsCallable(
    'tailgating-enqueuePriceScrapingTasksManually'
  );

  return startScraping()
    .then((response) => {
      console.log(response);
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const requestOrdersReport = async (
  startDate,
  endDate,
  requestedById,
  requestedByName,
  supplierId,
  supplierName,
  statusId,
  reportType,
  dcFilter
) => {
  const requestReport = functions.httpsCallable(
    'presalesMaintenanceReports-requestReport'
  );

  return requestReport({
    startDate,
    endDate,
    requestedById,
    requestedByName,
    supplierId,
    supplierName,
    statusId,
    reportType,
    dcFilter,
  })
    .then((response) => {
      console.log(response);
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const updateTakealotProducts = async (reportDataBatch) => {
  const startUpdate = functions.httpsCallable('presales-updateTakealotProduct');

  return startUpdate({ reportDataBatch })
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const requestUpdatesTemplate = async (
  requestedById,
  requestedByName,
  supplierId,
  supplierName,
  statusId,
  metadataType
) => {
  const requestTemplate = functions.httpsCallable(
    'presalesOnboarding-requestUpdatesTemplate'
  );

  return requestTemplate({
    requestedById,
    requestedByName,
    supplierId,
    supplierName,
    statusId,
    metadataType,
  })
    .then((response) => {
      console.log(response);
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const requestReport = async (
  requestedById,
  requestedByName,
  supplierId,
  supplierName,
  reportType
) => {
  const makeRequest = functions.httpsCallable(
    'presalesMaintenanceReports-requestReport'
  );

  return makeRequest({
    requestedById,
    requestedByName,
    supplierId,
    supplierName,
    reportType,
  })
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const requestSubmissionSummary = async (
  requestedById,
  requestedByName,
  supplierId,
  supplierName,
  statusId
) => {
  const requestSummary = functions.httpsCallable(
    'presalesOnboardingSubmissions-generateSubmissionSummary'
  );

  return requestSummary({
    requestedById,
    requestedByName,
    supplierId,
    supplierName,
    statusId,
  })
    .then((response) => {
      console.log(response);
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const createUpdatesSubmissionsReference = async (templateInfo) => {
  const newUpdatesTemplateRef = firestore
    .collection('presalesUpdatesSubmissions')
    .doc();
  return newUpdatesTemplateRef
    .set({
      ...templateInfo,
      downloadLink: `https://firebasestorage.googleapis.com/v0/b/presales-updates/o/onboarding%2F/o/submissions/%2F${newUpdatesTemplateRef.id}.csv?alt=media&token=${newUpdatesTemplateRef.id}`,
    })
    .then(() => {
      return newUpdatesTemplateRef.id;
    })
    .catch((error) => {
      throw error;
    });
};
const createUpdatesSubmissionReference = async (fileInfo) => {
  const newUpdatesSubmissionRef = firestore
    .collection('presalesUpdatesSubmissions')
    .doc();

  return newUpdatesSubmissionRef
    .set({
      ...fileInfo,
    })
    .then(() => {
      return newUpdatesSubmissionRef.id;
    })
    .catch((error) => {
      throw error;
    });
};
const createUpdateSubmissionsFile = async (submissionFile, fileInfo) => {
  try {
    const submissionRef = await createUpdatesSubmissionReference(fileInfo);
    const storageRef = firebase.app().storage('gs://presales-updates').ref();
    const fileRef = storageRef.child(
      `/onboarding/submissions/${submissionRef}`
    );

    await fileRef.put(submissionFile);
    return 200;
  } catch (error) {
    throw new Error(error);
  }
};
export const uploadPresaleSkuSubmission = async (submissionPath) => {
  const uploadUpdate = functions.httpsCallable(
    'presalesOnboarding-uploadUpdatesSubmission'
  );
  return uploadUpdate(submissionPath)
    .then((response) => {
      console.log(response);
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const uploadPresaleSkuUpdateSubmission = async (
  submissionFiles,
  submissionInfo
) => {
  try {
    await createUpdateSubmissionsFile(submissionFiles[0], submissionInfo);

    return 200;
  } catch (error) {
    throw new Error(error);
  }
};

export const getUpdateSubmissions = async () => {
  const subRef = firestore
    .collection('presalesUpdatesSubmissions')
    .where('allSkusCompleted', '==', false);
  return subRef
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.length === 0) {
        return {
          status: 500,
        };
      }
      const result = querySnapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
          submissionDate: doc.submissionDate,
        };
      });
      return {
        submissions: result,
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const getUpdateSubmissionSkus = async (referenceId) => {
  const skusRef = firestore
    .collection('presalesUpdatesSubmissions')
    .doc(referenceId)
    .collection('presaleSkus');

  return skusRef
    .get()
    .then(async (querySnapshot) => {
      const skus = [];

      querySnapshot.forEach(async (doc) => {
        skus.push({ id: doc.id, ...doc.data() });
      });

      return {
        skus,
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const updateSubmissionDocument = async (referenceId, updateInfo) => {
  const updateSubmissionRef = firestore
    .collection('presalesUpdatesSubmissions')
    .doc(referenceId);

  return updateSubmissionRef
    .update({
      ...updateInfo,
    })
    .then(() => {
      return 204;
    })
    .catch((error) => {
      throw error;
    });
};
