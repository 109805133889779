import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { fetchUserInfo } from './slice';
import ContentWrapper from '../../../components/ContentWrapper';
import EmptyViewMessage from '../../../components/EmptyViewMessage';
import PageHeader from '../../../components/PageHeader';
import RolesCard from './components/RolesCard';
import UnderConstruction from '../../../components/UnderConstruction';
import UserInfoCard from './components/UserInfoCard';

const useStyles = makeStyles((theme) => ({
  loadingWrapper: {
    padding: theme.spacing(4),
    textAlign: 'center',
  },
  pdfButtonInner: {
    display: 'flex',
    alignItems: 'center',
  },
  pdfButtonOuter: {
    color: theme.palette.common.white,
    textDecoration: 'none',
  },
  seperatorButtons: {
    height: theme.spacing(1),
  },
  seperator: {
    height: theme.spacing(3),
  },
  tabs: {
    marginBottom: theme.spacing(4),
  },
  totalsWrapper: {
    padding: theme.spacing(4),
  },
}));

const breadcrumbs = [
  {
    label: 'User Management',
    link: '/user-management',
  },
  {
    label: 'Staff',
    link: '/user-management/staff',
  },
  {
    label: 'Browse',
    link: '/user-management/staff/browse',
  },
];

const UsersView = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { userId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('LEADS');

  const userInfo = useSelector((state) => state.usersView.userInfo);
  const userInfoLoadingStatus = useSelector(
    (state) => state.usersView.loadingStatus.userInfo
  );

  useEffect(() => {
    dispatch(fetchUserInfo(userId));
  }, []);

  useEffect(() => {
    if (userInfoLoadingStatus === 'PENDING') {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [userInfoLoadingStatus]);

  return (
    <div>
      <PageHeader
        title="User Info"
        subtitle={
          isLoading ? 'Loading...' : `${userInfo.name} ${userInfo.surname}`
        }
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Member"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
      />
      {isLoading && <LinearProgress color="secondary" />}
      <ContentWrapper>
        {!isLoading && userInfo.id === 'Loading' ? (
          <EmptyViewMessage
            heading="Info Failed to Load"
            message="Please check your internet connection and reload the page."
          />
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <UserInfoCard
                userInfo={userInfo}
                isLoading={userInfoLoadingStatus === 'PENDING'}
              />
              <div className={classes.seperator} />
              <RolesCard
                roles={userInfo.roles ? userInfo.roles : [userInfo.type]}
                isLoading={userInfoLoadingStatus === 'PENDING'}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={9}>
              {!isLoading && (
                <div>
                  <AppBar position="static" className={classes.tabs}>
                    <Tabs
                      value={activeTab}
                      onChange={(e, newValue) => setActiveTab(newValue)}
                      aria-label="staff tabs"
                    >
                      <Tab label="Leads" value="LEADS" />
                      <Tab label="Deals" value="DEALS" />
                    </Tabs>
                  </AppBar>
                  <Paper>
                    <UnderConstruction />
                  </Paper>
                </div>
              )}
            </Grid>
          </Grid>
        )}
      </ContentWrapper>
    </div>
  );
};

export default UsersView;
