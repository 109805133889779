/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';
import {
  getPresalesSuppliers,
  requestSubmissionSummary,
  uploadPresaleSkuUpdateSubmission,
  getUpdateSubmissions,
  getUpdateSubmissionSkus,
  updateSubmissionDocument,
} from '../../../api/presalesAPI';

const initialState = {
  suppliers: [],
  submissions: [],
  subcollectionSkus: [],
  loadingStatus: {
    suppliers: 'NOT_STARTED',
    requestSummary: 'NOT_STARTED',
    updateSubmissions: 'NOT_STARTED',
    uploadSubmissions: 'NOT_STARTED',
    fetchSubmissions: 'NOT_STARTED',
    fetchSubmissionSubcollection: 'NOT_STARTED',
  },
};

const presalesSubmissionsBrowseSlice = createSlice({
  name: 'presalesSubmissionsBrowse',
  initialState,
  reducers: {
    fetchPresalesSuppliersStarted(state) {
      state.loadingStatus.suppliers = 'PENDING';
    },
    fetchPresalesSuppliersSuccess(state, action) {
      state.suppliers = action.payload;
      state.loadingStatus.suppliers = 'COMPLETE';
    },
    fetchPresalesSuppliersFailed(state) {
      state.loadingStatus.suppliers = 'COMPLETE';
    },
    requestSubmissionSummaryStarted(state) {
      state.loadingStatus.requestSummary = 'PENDING';
    },
    requestSubmissionSummarySuccess(state) {
      state.loadingStatus.requestSummary = 'COMPLETE';
    },
    requestSubmissionSummaryFailed(state) {
      state.loadingStatus.requestSummary = 'COMPLETE';
    },
    uploadUpdatesSubmissionsStarted(state) {
      state.loadingStatus.uploadSubmission = 'PENDING';
    },
    uploadUpdatesSubmissionsSuccess(state) {
      state.loadingStatus.uploadSubmission = 'COMPLETE';
    },
    uploadUpdatesSubmissionsFailed(state) {
      state.loadingStatus.uploadSubmission = 'COMPLETE';
    },
    updateSubmissionsStarted(state) {
      state.loadingStatus.updateSubmissions = 'PENDING';
    },
    updateSubmissionsSuccess(state) {
      state.loadingStatus.updateSubmissions = 'COMPLETE';
    },
    updateSubmissionsFailed(state) {
      state.loadingStatus.updateSubmissions = 'COMPLETE';
    },
    fetchSubmissionsStarted(state) {
      state.loadingStatus.fetchSubmission = 'PENDING';
    },
    fetchSubmissionsSuccess(state, action) {
      state.submissions = action.payload;
      state.loadingStatus.fetchSubmission = 'COMPLETE';
    },
    fetchSubmissionsFailed(state) {
      state.loadingStatus.fetchSubmission = 'COMPLETE';
    },
    fetchSubmissionSubcollectionStarted(state) {
      state.loadingStatus.fetchSubmissionSubcollection = 'PENDING';
    },
    fetchSubmissionSubcollectionSuccess(state, action) {
      state.subcollectionSkus = action.payload;
      state.loadingStatus.fetchSubmissionSubcollection = 'COMPLETE';
    },
    fetchSubmissionSubcollectionFailed(state) {
      state.loadingStatus.fetchSubmissionSubcollection = 'COMPLETE';
    },
  },
});

export const {
  fetchPresalesSuppliersStarted,
  fetchPresalesSuppliersSuccess,
  fetchPresalesSuppliersFailed,
  requestSubmissionSummaryStarted,
  requestSubmissionSummarySuccess,
  requestSubmissionSummaryFailed,
  updateSubmissionsStarted,
  updateSubmissionsSuccess,
  updateSubmissionsFailed,
  uploadUpdatesSubmissionsStarted,
  uploadUpdatesSubmissionsSuccess,
  uploadUpdatesSubmissionsFailed,
  fetchSubmissionsStarted,
  fetchSubmissionsSuccess,
  fetchSubmissionsFailed,
  fetchSubmissionSubcollectionStarted,
  fetchSubmissionSubcollectionSuccess,
  fetchSubmissionSubcollectionFailed,
} = presalesSubmissionsBrowseSlice.actions;

export const fetchPresalesSuppliers = () => async (dispatch) => {
  dispatch(fetchPresalesSuppliersStarted());
  const result = await getPresalesSuppliers();

  if (result.status === 200) {
    dispatch(fetchPresalesSuppliersSuccess(result.suppliers));
  } else {
    dispatch(fetchPresalesSuppliersFailed(result.error));
  }
};

export const fetchPresalesSubmissions = () => async (dispatch) => {
  dispatch(fetchSubmissionsStarted());
  const result = await getUpdateSubmissions();
  if (result.status === 200) {
    dispatch(fetchSubmissionsSuccess(result.submissions));
  } else {
    dispatch(fetchSubmissionsFailed(result.error));
  }
};

export const fetchPresalesSubmissionSubcollection =
  (referenceId) => async (dispatch) => {
    dispatch(fetchSubmissionSubcollectionStarted(referenceId));
    const result = await getUpdateSubmissionSkus();
    if (result.status === 200) {
      dispatch(fetchSubmissionSubcollectionSuccess(result.skus));
    } else {
      dispatch(fetchSubmissionSubcollectionFailed(result.error));
    }
  };

export const generateSubmissionSummary =
  (requestedById, requestedByName, supplierId, supplierName, statusId) =>
  async (dispatch) => {
    dispatch(requestSubmissionSummaryStarted());
    const result = await requestSubmissionSummary(
      requestedById,
      requestedByName,
      supplierId,
      supplierName,
      statusId
    );

    if (result.status === 200) {
      dispatch(requestSubmissionSummarySuccess());
    } else {
      dispatch(requestSubmissionSummaryFailed(result.error));
    }
  };

export const updateSubmissionFile =
  (referenceId, updateInfo) => async (dispatch) => {
    dispatch(updateSubmissionsStarted());
    const result = await updateSubmissionDocument(referenceId, updateInfo);

    if (result.status === 200) {
      dispatch(updateSubmissionsSuccess());
    } else {
      dispatch(updateSubmissionsFailed(result.error));
    }
  };

export const uploadSubmission =
  (submissionPath, submissionInfo) => async (dispatch) => {
    dispatch(uploadUpdatesSubmissionsStarted());
    const result = await uploadPresaleSkuUpdateSubmission(
      submissionPath,
      submissionInfo
    );

    if (result.status === 200) {
      dispatch(uploadUpdatesSubmissionsSuccess());
    } else {
      dispatch(uploadUpdatesSubmissionsFailed(result.error));
    }
  };

export default presalesSubmissionsBrowseSlice.reducer;
