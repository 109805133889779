import { useState } from 'react';
import Button from '@material-ui/core/Button';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';

const DcSelector = ({ dcFilter, changeDcFilter, options }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const getDcLabel = (id) => {
    const dcInfo = options.find((info) => info.id === id);
    if (!dcInfo) {
      return 'Unknown';
    }

    return dcInfo.label;
  };

  return (
    <>
      <Button
        aria-controls="dc-menu"
        aria-haspopup="true"
        size="large"
        startIcon={<LocalShippingIcon />}
        onClick={openMenu}
      >
        {getDcLabel(dcFilter)}
      </Button>
      <Menu
        id="dc-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {options
          .map((info) => info)
          .map((info) => (
            <MenuItem
              key={info.id}
              onClick={() => {
                changeDcFilter(info.id);
                closeMenu();
              }}
            >
              {info.label}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

DcSelector.defaultProps = {
  dcFilter: 'BOTH',
  changeDcFilter: (newValue) => `DC filter was changed to '${newValue}'`,
  options: [
    {
      id: 'BOTH',
      label: 'Both DCs',
    },
    {
      id: 'CPT',
      label: 'CPT',
    },
    {
      id: 'JHB',
      label: 'JHB',
    },
  ],
};

DcSelector.propTypes = {
  dcFilter: PropTypes.oneOf(['BOTH', 'CPT', 'JHB']),
  changeDcFilter: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};

export default DcSelector;
