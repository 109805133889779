import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import {
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/GetApp';
import moment from 'moment';
import PropTypes from 'prop-types';
import theme from '../../../../../utils/theme';

const useStyles = makeStyles(() => ({
  tableCell: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.background.default,
    borderColor: theme.palette.background.default,
    fontSize: '1rem',
    padding: 12,
  },
  tableHeadCell: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.background.default,
    borderColor: theme.palette.background.default,
    borderBottomColor: grey[800],
    borderBottomWidth: 4,
    fontSize: '1rem',
    padding: 12,
  },
}));

const RequestedTemplates = ({ updateTemplates }) => {
  const classes = useStyles();

  const getButtonContents = (status) => {
    if (status === 'PENDING') {
      return <DownloadIcon style={{ color: theme.palette.primary.light }} />;
    }
    return <DownloadIcon style={{ color: theme.palette.secondary.main }} />;
  };

  const getPrettySupplierName = (supplierFilter) => {
    if (supplierFilter === 'Unknown') {
      return 'All Suppliers';
    }
    return supplierFilter;
  };

  const getPrettyStatus = (statusFilter) => {
    if (statusFilter === 'ALL') {
      return 'All SKUs';
    }

    switch (statusFilter) {
      case 'INCOMPLETE_SKUS':
        return 'Incomplete SKUs';
      case 'COMPLETE_SKUS':
        return 'Complete SKUs';
      default:
        return 'All SKUs';
    }
  };

  const getPrettyMetadataType = (metadataType) => {
    switch (metadataType) {
      case 'ALL':
        return 'All Metadata';
      case 'BARCODES':
        return 'Barcodes';
      case 'CATEGORIES':
        return 'Categories';
      case 'COPY':
        return 'Copy';
      case 'IMAGES':
        return 'Images';
      case 'MEASUREMENTS':
        return 'Measurements';

      default:
        return 'All Metadata';
    }
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell className={classes.tableHeadCell}>Template Type</TableCell>
          <TableCell align="center" className={classes.tableHeadCell}>
            Request Date
          </TableCell>
          <TableCell align="center" className={classes.tableHeadCell}>
            Requested By
          </TableCell>
          <TableCell align="center" className={classes.tableHeadCell}>
            Supplier
          </TableCell>
          <TableCell align="center" className={classes.tableHeadCell}>
            SKU Status
          </TableCell>
          <TableCell align="center" className={classes.tableHeadCell}>
            Download File
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {updateTemplates.map((reportInfo) => (
          <TableRow key={reportInfo.id}>
            <TableCell className={classes.tableCell} align="left">
              {getPrettyMetadataType(reportInfo.metadataType)}
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              {moment(reportInfo.requestDate).format('MM/DD/YYYY')}
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              {reportInfo.requestedByName}
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              {getPrettySupplierName(reportInfo.supplierName)}
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              {getPrettyStatus(reportInfo.statusId)}
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              <IconButton
                disabled={reportInfo.status === 'PENDING'}
                component={Link}
                href={reportInfo.downloadLink}
              >
                {getButtonContents(reportInfo.status)}
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

RequestedTemplates.defaultProps = {
  updateTemplates: [],
};

RequestedTemplates.propTypes = {
  updateTemplates: PropTypes.arrayOf(
    PropTypes.shape({
      objectID: PropTypes.string,
    })
  ),
};

export default RequestedTemplates;
