/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  getQuery,
  getQueryDocuments,
  getQueryImages,
  deleteQueryImage,
  deleteQueryDocument,
} from '../../../api/clientsAPI';

const initialState = {
  queryInfo: {},
  documents: [],
  images: [],
  loadingStatus: {
    queryInfo: 'NOT_STARTED',
    images: 'NOT_STARTED',
    documents: 'NOT_STARTED',
    deleteDoc: 'NOT_STARTED',
    deleteImage: 'NOT_STARTED',
  },
  feedback: {
    isOpen: false,
    type: undefined,
    message: '',
  },
  pageSize: 5,
  initialPage: 0,
};

const clientsQueryViewSlice = createSlice({
  name: 'clientsQueryView',
  initialState,
  reducers: {
    fetchQueryInfoStarted(state) {
      state.loadingStatus.queryInfo = 'PENDING';
    },
    fetchQueryInfoSuccess(state, action) {
      state.queryInfo = action.payload;
      state.loadingStatus.queryInfo = 'COMPLETE';
    },
    fetchQueryInfoFailed(state, action) {
      state.loadingStatus.queryInfo = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    changePageSize(state, action) {
      state.pageSize = action.payload;
    },
    changeInitialPage(state, action) {
      state.initialPage = action.payload;
    },
    fetchImagesStarted(state) {
      state.loadingStatus.images = 'PENDING';
    },
    fetchImagesSuccess(state, action) {
      state.images = action.payload;
      state.loadingStatus.images = 'COMPLETE';
    },
    fetchImagesFailed(state, action) {
      state.loadingStatus.images = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getDocumentsStarted(state) {
      state.loadingStatus.documents = 'PENDING';
    },
    getDocumentsSuccess(state, action) {
      state.documents = action.payload;
      state.loadingStatus.documents = 'COMPLETE';
    },
    getDocumentsFailed(state, action) {
      state.loadingStatus.documents = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    deleteImageStarted(state) {
      state.loadingStatus.deleteImage = 'PENDING';
    },
    deleteImageSuccess(state, action) {
      state.loadingStatus.deleteImage = 'COMPLETE';
      state.images = state.images.filter(
        (imageInfo) => imageInfo.id !== action.payload
      );
    },
    deleteImageFailed(state, action) {
      state.loadingStatus.deleteImage = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    deleteDocumentStarted(state) {
      state.loadingStatus.deleteDoc = 'PENDING';
    },
    deleteDocumentSuccess(state, action) {
      state.loadingStatus.deleteDoc = 'COMPLETE';
      state.documents = state.documents.filter(
        (documentInfo) => documentInfo.id !== action.payload
      );
    },
    deleteDocumentFailed(state, action) {
      state.loadingStatus.deleteDoc = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
  },
});

export const {
  fetchQueryInfoSuccess,
  fetchQueryInfoFailed,
  fetchQueryInfoStarted,
  changePageSize,
  changeInitialPage,
  getDocumentsStarted,
  getDocumentsFailed,
  getDocumentsSuccess,
  fetchImagesSuccess,
  fetchImagesFailed,
  fetchImagesStarted,
  deleteImageStarted,
  deleteImageSuccess,
  deleteImageFailed,
  deleteDocumentSuccess,
  deleteDocumentStarted,
  deleteDocumentFailed,
} = clientsQueryViewSlice.actions;

export const fetchQueryInfo = (queryId) => async (dispatch) => {
  dispatch(fetchQueryInfoStarted());
  const result = await getQuery(queryId);
  if (result.status === 200) {
    dispatch(fetchQueryInfoSuccess(result.queryInfo));
  } else {
    dispatch(fetchQueryInfoFailed({ message: result.error }));
  }
};

export const updatePageSize = (newPageSize) => (dispatch) => {
  dispatch(changePageSize(newPageSize));
};

export const updateInitialPage = (newInitialPage) => (dispatch) => {
  dispatch(changeInitialPage(newInitialPage));
};

export const fetchDocuments = (queryId) => async (dispatch) => {
  dispatch(getDocumentsStarted());
  const result = await getQueryDocuments(queryId);

  if (result.status === 200) {
    dispatch(getDocumentsSuccess(result.documents));
  } else {
    dispatch(getDocumentsFailed(result.error));
  }
};

export const fetchImages = (queryId) => async (dispatch) => {
  dispatch(fetchImagesStarted());
  const result = await getQueryImages(queryId);

  if (result.status === 200) {
    dispatch(fetchImagesSuccess(result.images));
  } else {
    dispatch(fetchImagesFailed(result.error));
  }
};

export const deleteQuerysImage =
  (queryId, imagePath, imageId) => async (dispatch) => {
    dispatch(deleteImageStarted());
    const result = await deleteQueryImage(queryId, imagePath, imageId);

    if (result.status === 200) {
      dispatch(deleteImageSuccess(imageId));
    } else {
      dispatch(deleteImageFailed(result.error));
    }
  };

export const deleteQuerysDocument =
  (queryId, documentPath, documentId) => async (dispatch) => {
    dispatch(deleteDocumentStarted());
    const result = await deleteQueryDocument(queryId, documentPath, documentId);

    if (result.status === 200) {
      dispatch(deleteDocumentSuccess(documentId));
    } else {
      dispatch(deleteDocumentFailed(result.error));
    }
  };
export default clientsQueryViewSlice.reducer;
