/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getSupplier } from '../../../api/presalesSuppliersAPI';
import { getPricingSkuToUpdate, updateSkuInfo } from '../../../api/presalesAPI';

const initialState = {
  isUserRedirectedToBrowse: false,
  supplierInfo: {
    id: '',
    pricingColumns: {
      supplierSkuPricingIndex: 0,
      costPerUnitIndex: 0,
    },
  },
  presalesSkusToUpdate: [],
  failedPricingSkus: [],
  pricingUpdateLoadingCount: {
    total: 0,
    success: 0,
    failed: 0,
  },
  loadingStatus: {
    supplierInfo: 'NOT_STARTED',
    presalesSkusToUpdate: 'NOT_STARTED',
  },
  feedback: {
    isOpen: false,
    type: undefined,
    message: '',
  },
};

const presalesSuppliersUpdatePricingSlice = createSlice({
  name: 'presalesSuppliersUpdatePricing',
  initialState,
  reducers: {
    getSkusStarted(state) {
      state.loadingStatus.presalesSkusToUpdate = 'PENDING';
    },
    getSkusSuccess(state, action) {
      state.presalesSkusToUpdate = action.payload;
      state.loadingStatus.presalesSkusToUpdate = 'COMPLETE';
    },
    getSkusFailed(state, action) {
      state.loadingStatus.presalesSkusToUpdate = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    fetchSupplierInfoStarted(state) {
      state.loadingStatus.supplierInfo = 'PENDING';
    },
    fetchSupplierInfoSuccess(state, action) {
      state.supplierInfo = action.payload;
      state.loadingStatus.supplierInfo = 'COMPLETE';
    },
    fetchSupplierInfoFailed(state, action) {
      state.loadingStatus.supplierInfo = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    pricingSkusToUpdate(state, action) {
      state.failedPricingSkus = [];
      state.pricingUpdateLoadingCount.total = action.payload;
      state.pricingUpdateLoadingCount.success = 0;
      state.pricingUpdateLoadingCount.failed = 0;
    },

    pricingUpdateSuccess(state) {
      state.pricingUpdateLoadingCount.success += 1;
    },
    pricingUpdateFailed(state, action) {
      state.pricingUpdateLoadingCount.failed += 1;
      state.failedPricingSkus.push(action.payload);
    },
    resetState() {
      return initialState;
    },
  },
});

export const {
  updateSupplierStarted,
  updateSupplierSuccess,
  updateSupplierFailed,
  fetchSupplierInfoStarted,
  fetchSupplierInfoSuccess,
  fetchSupplierInfoFailed,
  pricingSkusToUpdate,
  pricingUpdateSuccess,
  pricingUpdateFailed,
  resetState,
} = presalesSuppliersUpdatePricingSlice.actions;

export default presalesSuppliersUpdatePricingSlice.reducer;

export const updatePricingInfo = (supplierSku, newInfo) => async (dispatch) => {
  const result = await getPricingSkuToUpdate(newInfo);
  if (result.data === 200) {
    dispatch(pricingUpdateSuccess());
  } else {
    dispatch(pricingUpdateFailed({ sku: supplierSku }));
  }
};

export const updateSku = (skuId, skuInfo) => async (dispatch) => {
  const result = await updateSkuInfo(skuId, skuInfo);
  if (result.status === 200) {
    dispatch(pricingUpdateSuccess());
  } else {
    dispatch(
      pricingUpdateFailed({
        eolSku: result.eolSku,
      })
    );
  }
};

export const fetchSupplierInfo = (supplierId) => async (dispatch) => {
  dispatch(fetchSupplierInfoStarted());
  const result = await getSupplier(supplierId);

  if (result.status === 200) {
    dispatch(fetchSupplierInfoSuccess(result.supplierInfo));
  } else {
    dispatch(fetchSupplierInfoFailed(result.error));
  }
};

export const setTotalSkusToUpdate = (total) => (dispatch) => {
  dispatch(pricingSkusToUpdate(total));
};

export const initiateStateReset = () => async (dispatch) => {
  dispatch(resetState());
};
