/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import SuperDealerProposal from './components/SuperDealerProposal';
import TakealotProposal from './components/TakealotProposal';

const ProposalDocument = ({ proposal, dealType, batchCode }) => {
  if (dealType === 'SUPER_DEALER') {
    return (
      <SuperDealerProposal
        businessName={proposal.businessName}
        creationDate={proposal.creationDate}
        listings={proposal.listingInfo}
        batchCode={batchCode}
      />
    );
  }
  return (
    <TakealotProposal
      businessName={proposal.businessName}
      creationDate={proposal.creationDate}
      subTotal={proposal.subTotal}
      vat={proposal.vat}
      total={proposal.total}
      setupFees={proposal.setupFees}
      listings={proposal.listingInfo}
    />
  );
};

ProposalDocument.defaultProps = {
  dealType: 'NEW',
  proposal: {},
  batchCode: '001012021',
};

ProposalDocument.propTypes = {
  dealType: PropTypes.oneOf(['ADD_ON', 'NEW', 'SUPER_DEALER']),
  proposal: PropTypes.shape({
    businessName: PropTypes.string,
    creationDate: PropTypes.instanceOf(Date),
  }),
  batchCode: PropTypes.string,
};

export default ProposalDocument;
