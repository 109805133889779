import firebase from 'firebase/app';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useHistory, Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { fetchUserInfo, logOut, updateUserId } from './pages/Account/slice';
import LoadingScreen from './components/LoadingScreen';
import Navigation from './components/Navigation';
import NoInternetScreen from './components/NoInternetScreen';
import routes from './utils/routes';
import 'firebase/auth';

const App = ({ version }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const userInfo = useSelector((state) => state.account.userInfo);
  const logOutLoadingStatus = useSelector(
    (state) => state.account.loadingStatus.logOut
  );
  const userInfoLoadingStatus = useSelector(
    (state) => state.account.loadingStatus.userInfo
  );

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        dispatch(updateUserId(user.uid));
      } else {
        history.push('/login');
      }
    });
  }, []);

  useEffect(() => {
    if (userInfo.uid !== null) {
      dispatch(fetchUserInfo(userInfo.uid));
    }
  }, [userInfo.uid]);

  useEffect(() => {
    if (userInfo.type === 'CLIENT') {
      dispatch(logOut());
    }
  }, [userInfo.type]);

  if (
    userInfo.uid === null ||
    logOutLoadingStatus === 'PENDING' ||
    userInfoLoadingStatus === 'PENDING'
  ) {
    return <LoadingScreen />;
  }

  if (userInfo.name === null && userInfoLoadingStatus === 'COMPLETE') {
    return <NoInternetScreen />;
  }

  return (
    <Switch>
      {routes.map((routeInfo) => (
        <Route key={routeInfo.path} exact path={routeInfo.path}>
          <Navigation
            version={version}
            username={`${userInfo.name} ${userInfo.surname}`}
            jobTitle={userInfo.title}
            userType={userInfo.type}
            signOut={() => dispatch(logOut())}
            isSuperAdmin={userInfo.isSuperAdmin}
          >
            {routeInfo.component}
          </Navigation>
        </Route>
      ))}
      <Redirect to="/dashboard" />
    </Switch>
  );
};

App.defaultProps = {
  version: '1.0.0',
};

App.propTypes = {
  version: PropTypes.string,
};

export default App;
