import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  description: {
    color: grey[700],
  },
  icon: {
    marginRight: theme.spacing(2),
    color: grey[700],
  },
  wrapperContent: {
    width: 460,
    textAlign: 'center',
  },
  wrapperHeading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.primary.dark}`,
    color: grey[700],
  },
  wrapperRoot: {
    width: '100%',
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const EmptyViewMessage = ({ heading, message }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapperRoot}>
      <div className={classes.wrapperContent}>
        <div className={classes.wrapperHeading}>
          <NotInterestedIcon fontSize="large" className={classes.icon} />
          <Typography
            variant="h4"
            component="h2"
            className={classes.headingMain}
          >
            {heading}
          </Typography>
        </div>
        <Typography
          variant="body1"
          component="p"
          className={classes.description}
        >
          {message}
        </Typography>
      </div>
    </div>
  );
};

EmptyViewMessage.defaultProps = {
  heading: 'No Results Found',
  message: 'Create a new item to view them here.',
};

EmptyViewMessage.propTypes = {
  heading: PropTypes.string,
  message: PropTypes.string,
};

export default EmptyViewMessage;
