import { useState } from 'react';
import StoreMallDirectoryIcon from '@material-ui/icons/StoreMallDirectory';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';

const SupplierSelector = ({
  supplierFilter,
  changeSupplierFilter,
  supplierOptions,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const getPrettyFilter = (filter) => {
    if (filter === 'ALL') {
      return 'All Suppliers';
    }
    return filter;
  };

  return (
    <>
      <Button
        aria-controls="clients-menu"
        aria-haspopup="true"
        size="large"
        startIcon={<StoreMallDirectoryIcon />}
        onClick={openMenu}
      >
        {getPrettyFilter(supplierFilter)}
      </Button>
      <Menu
        id="clients-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem
          onClick={() => {
            changeSupplierFilter('ALL');
            closeMenu();
          }}
        >
          All
        </MenuItem>
        {supplierOptions.map((option) => (
          <MenuItem
            key={`filter-option-${option}`}
            onClick={() => {
              changeSupplierFilter(option);
              closeMenu();
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

SupplierSelector.defaultProps = {
  supplierFilter: 'ALL',
  changeSupplierFilter: (newFilter) =>
    console.log('User changes the supplier filter to:', newFilter),
  supplierOptions: [],
};

SupplierSelector.propTypes = {
  supplierFilter: PropTypes.string,
  changeSupplierFilter: PropTypes.func,
  supplierOptions: PropTypes.arrayOf(PropTypes.string),
};

export default SupplierSelector;
