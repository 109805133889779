import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import ImageIcon from '@material-ui/icons/Image';
import LinearProgress from '@material-ui/core/LinearProgress';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import Snackbar from '@material-ui/core/Snackbar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import {
  deleteQuerysDocument,
  deleteQuerysImage,
  fetchDocuments,
  fetchImages,
  fetchQueryInfo,
  updateInitialPage,
  updatePageSize,
} from './slice';
import BasicInfoCard from './componenets/BasicInfoCard';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import ContentWrapper from '../../../components/ContentWrapper';
import DocumentsTab from './componenets/DocumentsTab';
import EmptyViewMessage from '../../../components/EmptyViewMessage';
import getFormattedSkus from './logic';
import ImagePreviewCard from './componenets/ImagesPreviewCard';
import PageHeader from '../../../components/PageHeader';
import PeopleCard from './componenets/PeopleCard';
import SkusTable from './componenets/SkusTable';
import FiltersBar from './componenets/FiltersBar';
import DatesCard from './componenets/DatesCard';

const useStyles = makeStyles((theme) => ({
  seperator: {
    height: theme.spacing(3),
  },
  seperatorButtons: {
    height: theme.spacing(1),
  },
}));

const breadcrumbs = [
  {
    label: 'Customer Relationship Management',
    link: '/crm',
  },
  {
    label: 'Queries',
    link: '/crm/queries',
  },
  {
    label: 'Browse',
    link: '/crm/queries/browse',
  },
];

const ClientsQueryView = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { queryId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('SKUS');
  const [imageToBeDeleted, setImageToBeDeleted] = useState({
    imagePath: '',
    imageId: '',
  });
  const [isDeleteImageDialogOpen, setIsDeleteImageDialogOpen] = useState(false);
  const [isDeleteDocumentDialogOpen, setIsDeleteDocumentDialogOpen] =
    useState(false);
  const [documentToBeDeleted, setDocumentToBeDeleted] = useState({
    documentPath: '',
    documentId: '',
  });
  const [searchText, setSearchText] = useState('');
  const [formattedSkus, setFormattedSkus] = useState([]);
  const deleteDocumentLoadingStatus = useSelector(
    (state) => state.clientsQueryView.loadingStatus.deleteDoc
  );
  const deleteImageLoadingStatus = useSelector(
    (state) => state.clientsQueryView.loadingStatus.deleteImage
  );
  const queryInfo = useSelector((state) => state.clientsQueryView.queryInfo);
  const pageSize = useSelector((state) => state.clientsQueryView.pageSize);
  const initialPage = useSelector(
    (state) => state.clientsQueryView.initialPage
  );
  const images = useSelector((state) => state.clientsQueryView.images);
  const documents = useSelector((state) => state.clientsQueryView.documents);
  const [isLinkCopiedFeedbackOpen, setIsLinkCopiedFeedbackOpen] =
    useState(false);
  const queryInfoLoadingStatus = useSelector(
    (state) => state.clientsQueryView.loadingStatus.queryInfo
  );
  const imagesLoadingStatus = useSelector(
    (state) => state.clientsQueryView.loadingStatus.images
  );
  const documentsLoadingStatus = useSelector(
    (state) => state.clientsQueryView.loadingStatus.documents
  );
  useEffect(() => {
    dispatch(fetchQueryInfo(queryId));
    dispatch(fetchImages(queryId));
    dispatch(fetchDocuments(queryId));
  }, []);

  useEffect(() => {
    if (queryInfo.skus) {
      setFormattedSkus(getFormattedSkus(queryInfo.skus, queryInfo, searchText));
    }
  }, [queryInfo, searchText]);

  useEffect(() => {
    if (
      queryInfoLoadingStatus === 'PENDING' ||
      imagesLoadingStatus === 'PENDING' ||
      documentsLoadingStatus === 'PENDING' ||
      deleteImageLoadingStatus === 'PENDING' ||
      deleteDocumentLoadingStatus === 'PENDING'
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [
    queryInfoLoadingStatus,
    imagesLoadingStatus,
    documentsLoadingStatus,
    deleteImageLoadingStatus,
    deleteDocumentLoadingStatus,
  ]);

  return (
    <div>
      <PageHeader
        title="Query Details"
        subtitle={
          isLoading ? 'Loading...' : `${queryInfo.clientName} | ${queryInfo.id}`
        }
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Query"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
      />
      {isLoading && <LinearProgress color="secondary" />}
      <ContentWrapper>
        {!isLoading && queryInfo.clientName === undefined ? (
          <EmptyViewMessage
            heading="Info Failed to Load"
            message="Please check your internet connection and reload the page."
          />
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              {queryInfo.status !== 'CANCELLED' &&
                queryInfo.status !== 'COMPLETED' && (
                  <div>
                    <Button
                      fullWidth
                      variant="outlined"
                      color="secondary"
                      startIcon={<ImageIcon />}
                      onClick={() =>
                        history.push(`/crm/queries/image-upload/${queryId}`)
                      }
                    >
                      Upload images
                    </Button>
                    <div className={classes.seperatorButtons} />
                    <Button
                      fullWidth
                      variant="outlined"
                      color="secondary"
                      startIcon={<NoteAddIcon />}
                      onClick={() =>
                        history.push(`/crm/queries/document-upload/${queryId}`)
                      }
                    >
                      Upload Documents
                    </Button>
                    <div className={classes.seperatorButtons} />
                    <Button
                      fullWidth
                      variant="outlined"
                      color="secondary"
                      startIcon={<EditIcon />}
                      onClick={() =>
                        history.push(`/crm/queries/edit/${queryId}`)
                      }
                    >
                      Edit details
                    </Button>
                  </div>
                )}
              {queryInfo.status !== 'CANCELLED' &&
                queryInfo.status !== 'COMPLETED' && (
                  <div className={classes.seperator} />
                )}
              <DatesCard
                startDate={queryInfo.startDate}
                endDate={queryInfo.endDate}
                requestDate={queryInfo.requestDate}
                completionDate={queryInfo.completionDate}
                isLoading={queryInfoLoadingStatus === 'PENDING'}
                requestType={queryInfo.requestType}
              />
              <div className={classes.seperator} />
              <PeopleCard
                assigneeName={queryInfo.assigneeName}
                creatorName={queryInfo.creatorName}
                isLoading={queryInfoLoadingStatus === 'PENDING'}
              />
              <div className={classes.seperator} />
              <BasicInfoCard
                notes={queryInfo.notes}
                vatIncluded={queryInfo.vatIncluded}
                requestType={queryInfo.requestType}
                status={queryInfo.status}
                skuCount={queryInfo.skus ? queryInfo.skus.length : 0}
                isLoading={queryInfoLoadingStatus === 'PENDING'}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={9}>
              {!isLoading && (
                <div>
                  <AppBar position="static">
                    <Tabs
                      value={activeTab}
                      onChange={(e, newValue) => setActiveTab(newValue)}
                      aria-label="queries tabs"
                    >
                      <Tab label="SKUs" value="SKUS" />
                      <Tab label="Documents" value="DOCUMENTS" />
                      <Tab label="Images" value="IMAGES" />
                    </Tabs>
                  </AppBar>
                  <div className={classes.seperator} />
                  {activeTab === 'SKUS' && (
                    <>
                      <FiltersBar
                        searchText={searchText}
                        changeSearchText={setSearchText}
                        skuCount={formattedSkus.length}
                      />
                      <SkusTable
                        isLoading={isLoading}
                        viewSkuInfo={(sku) =>
                          history.push(`/listings/basic-info/browse/${sku}`)
                        }
                        skus={formattedSkus}
                        pageSize={pageSize}
                        initialPage={initialPage}
                        updatePageSize={(newPageSize) =>
                          dispatch(updatePageSize(newPageSize))
                        }
                        updateInitialPage={(newInitialPage) =>
                          dispatch(updateInitialPage(newInitialPage))
                        }
                      />
                    </>
                  )}
                  {activeTab === 'DOCUMENTS' &&
                    (documents.length > 0 ? (
                      <DocumentsTab
                        documents={documents}
                        deleteDocument={(documentInfo) => {
                          setIsDeleteDocumentDialogOpen(true);
                          setDocumentToBeDeleted({
                            documentPath: documentInfo.reference,
                            documentId: documentInfo.id,
                          });
                        }}
                      />
                    ) : (
                      <EmptyViewMessage
                        heading="No Documents"
                        message="No documents associated with this query."
                      />
                    ))}
                  {activeTab === 'IMAGES' &&
                    (images.length > 0 ? (
                      <Grid container spacing={2}>
                        {images.map((imageInfo) => (
                          <Grid key={imageInfo.id} item xs={4}>
                            <ImagePreviewCard
                              fileSize={imageInfo.fileSize}
                              tag={imageInfo.tag}
                              imageLink={imageInfo.url}
                              showLinkCopyFeedback={() =>
                                setIsLinkCopiedFeedbackOpen(true)
                              }
                              isLoading={isLoading}
                              deleteImage={() => {
                                setIsDeleteImageDialogOpen(true);
                                setImageToBeDeleted({
                                  imagePath: imageInfo.reference,
                                  imageId: imageInfo.id,
                                });
                              }}
                              imageDate={imageInfo.date}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    ) : (
                      <EmptyViewMessage
                        heading="No images"
                        message="No images associated with this query."
                      />
                    ))}
                </div>
              )}
            </Grid>
          </Grid>
        )}
      </ContentWrapper>
      <Snackbar
        open={isLinkCopiedFeedbackOpen}
        autoHideDuration={3000}
        onClose={() => setIsLinkCopiedFeedbackOpen(false)}
      >
        <Alert
          onClose={() => setIsLinkCopiedFeedbackOpen(false)}
          variant="filled"
          elevation={6}
          severity="success"
        >
          Image link copied to clipboard.
        </Alert>
      </Snackbar>
      <ConfirmationDialog
        isOpen={isDeleteImageDialogOpen}
        message="Are you sure you want to delete this image?"
        closeDialog={() => {
          setIsDeleteImageDialogOpen(false);
          setImageToBeDeleted({
            imagePath: '',
            imageId: '',
          });
        }}
        confirmAction={() =>
          dispatch(
            deleteQuerysImage(
              queryId,
              imageToBeDeleted.imagePath,
              imageToBeDeleted.imageId
            )
          )
        }
      />
      <ConfirmationDialog
        isOpen={isDeleteDocumentDialogOpen}
        message="Are you sure you want to delete this document?"
        closeDialog={() => {
          setIsDeleteDocumentDialogOpen(false);
          setDocumentToBeDeleted({
            documentPath: '',
            documentId: '',
          });
        }}
        confirmAction={() =>
          dispatch(
            deleteQuerysDocument(
              queryId,
              documentToBeDeleted.documentPath,
              documentToBeDeleted.documentId
            )
          )
        }
      />
    </div>
  );
};

export default ClientsQueryView;
