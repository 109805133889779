/* eslint-disable react/jsx-props-no-spreading */
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Chip, MenuItem, Select } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import FormControl from '@material-ui/core/FormControl';
import MaterialTable from 'material-table';
import PauseIcon from '@material-ui/icons/Pause';
import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';
import PropTypes from 'prop-types';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import Tooltip from '@material-ui/core/Tooltip';
import WarningIcon from '@material-ui/icons/Warning';
import getStyles from './styles';

const headerStyle = {
  backgroundColor: '#171415',
};

const useStyles = makeStyles(getStyles);

const RequestsTable = ({
  isLoading,
  requests,
  viewRequest,
  changeRequestStatus,
  pageSize,
  initialPage,
  updatePageSize,
  updateInitialPage,
}) => {
  const classes = useStyles();

  const getPrettyStatus = (status) => {
    switch (status) {
      case 'COMPLETE':
        return (
          <Chip
            icon={<DoneIcon className={classes.icon} />}
            label="Complete"
            className={classes.statusCompleted}
          />
        );
      case 'REQUESTED':
        return (
          <Chip
            icon={<PhoneCallbackIcon className={classes.icon} />}
            label="Requested"
            className={classes.statusRequested}
          />
        );
      case 'PREPARING':
        return (
          <Chip
            icon={<AutorenewIcon className={classes.icon} />}
            label="Preparing"
            className={classes.statusPreparing}
          />
        );
      case 'COLLECTION_ARRANGED':
        return (
          <Chip
            icon={<ThumbUpIcon className={classes.icon} />}
            label="Collection Arranged"
            className={classes.statusCollectionArranged}
          />
        );
      case 'WAYBILL_CREATED':
        return (
          <Chip
            icon={<AssignmentTurnedInIcon className={classes.icon} />}
            label="Waybill Created"
            className={classes.statusWaybillCreated}
          />
        );
      case 'DELAYED':
        return (
          <Chip
            icon={<PauseIcon className={classes.icon} />}
            label="Delayed"
            className={classes.statusDelayed}
          />
        );
      case 'CANCELLED':
        return (
          <Chip
            icon={<ClearIcon className={classes.icon} />}
            label="Cancelled"
            className={classes.statusCancelled}
          />
        );
      case 'INBOUND':
        return (
          <Chip
            icon={<CallReceivedIcon className={classes.icon} />}
            label="Inbound"
            className={classes.statusInbound}
          />
        );
      default:
        return (
          <Chip
            icon={<DoneIcon className={classes.icon} />}
            label={status}
            className={classes.statusActive}
          />
        );
    }
  };

  const getStatusList = () => {
    return [
      {
        value: 'REQUESTED',
        label: 'Requested',
      },
      {
        value: 'WAYBILL_CREATED',
        label: 'Waybill Created',
      },
      {
        value: 'COLLECTION_ARRANGED',
        label: 'Collection Arranged',
      },
    ].map((info) => (
      <MenuItem key={info.value} value={info.value}>
        {info.label}
      </MenuItem>
    ));
  };

  const getColumns = () => {
    const columns = [
      {
        title: 'Request ID',
        field: 'id',
        editable: 'never',
      },
      {
        title: 'Creation Date',
        field: 'creationDate',
        editable: 'never',
        render: (rowData) => moment(rowData.creationDate).format('DD/MM/YYYY'),
      },
      {
        title: 'Client',
        field: 'fromName',
        editable: 'never',
        render: (rowData) =>
          rowData.fromId === 'CLIENT' ? rowData.clientName : rowData.fromName,
      },
      {
        title: 'Units',
        field: 'units',
        editable: 'never',
        align: 'center',
        render: (rowData) => {
          if (rowData.isFlaggedWithAdjustments) {
            return (
              <div className={classes.tooltipWrapper}>
                <Tooltip title="Client / supplier requested adjustments to stock quantities.">
                  <WarningIcon className={classes.warningIcon} />
                </Tooltip>
                {rowData.units}
              </div>
            );
          }
          return rowData.units;
        },
      },
      {
        title: 'Status',
        field: 'status',
        render: (rowData) => getPrettyStatus(rowData.status),
        editComponent: (row) => (
          <FormControl
            disabled={
              row.value !== 'REQUESTED' &&
              row.value !== 'WAYBILL_CREATED' &&
              row.value !== 'COLLECTION_ARRANGED'
            }
          >
            <Select
              labelId="status-select-disabled-label"
              id="status-select-disabled"
              value={row.value}
              onChange={(e) => row.onChange(e.target.value)}
            >
              {getStatusList()}
            </Select>
          </FormControl>
        ),
      },
    ];

    return columns;
  };

  return (
    <div className={classes.wrapperContent}>
      <MaterialTable
        title=""
        columns={getColumns()}
        data={requests}
        isLoading={isLoading}
        onChangeRowsPerPage={updatePageSize}
        onChangePage={updateInitialPage}
        options={{
          pageSize,
          initialPage,
          headerStyle,
          actionsColumnIndex: -1,
          search: false,
          grouping: false,
          draggable: false,
          exportButton: true,
          exportAllData: true,
          exportFileName: 'Stock Requests',
        }}
        editable={{
          onRowUpdate: (newData) =>
            new Promise((resolve) => {
              changeRequestStatus(newData.id, newData.status);
              resolve();
            }),
        }}
        actions={[
          {
            icon: 'keyboard_arrow_right',
            tooltip: 'View request info',
            onClick: (event, rowData) => viewRequest(rowData.id),
          },
        ]}
        components={{
          OverlayLoading: () => (
            <div className={classes.loadingOverlay}>
              <CircularProgress color="primary" size={32} />
            </div>
          ),
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'No requests to display',
          },
        }}
      />
    </div>
  );
};

RequestsTable.defaultProps = {
  isLoading: false,
  requests: [],
  viewRequest: (id) => console.log(`Request viewed: ${id}`),
  changeRequestStatus: (id) => console.log(`Request edited: ${id}`),
  pageSize: 5,
  initialPage: 0,
  updatePageSize: (newPageSize) =>
    console.log(`Page size updated to: ${newPageSize}`),
  updateInitialPage: (newInitialPage) =>
    console.log(`Initial page updated to: ${newInitialPage}`),
};

RequestsTable.propTypes = {
  isLoading: PropTypes.bool,
  requests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      fromName: PropTypes.string,
      toName: PropTypes.string,
      units: PropTypes.number,
      status: PropTypes.oneOf([
        'REQUESTED',
        'WAYBILL_CREATED',
        'COLLECTION_ARRANGED',
        'INBOUND',
        'DELAYED',
        'CANCELLED',
        'COMPLETE',
      ]),
      creationDate: PropTypes.string,
      isFlaggedWithAdjustments: PropTypes.bool,
    })
  ),
  viewRequest: PropTypes.func,
  changeRequestStatus: PropTypes.func,
  pageSize: PropTypes.number,
  initialPage: PropTypes.number,
  updatePageSize: PropTypes.func,
  updateInitialPage: PropTypes.func,
};

export default RequestsTable;
