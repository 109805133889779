/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { signOut } from '../../api/authAPI';
import { getUserInfo } from '../../api/userAPI';

const initialState = {
  userInfo: {
    uid: null,
    email: null,
    name: null,
    phoneNumber: null,
    surname: null,
    title: null,
    type: null,
    agencyId: null,
    agencyName: null,
    isSuperAdmin: false,
  },
  isNavOpen: true,
  loadingStatus: {
    logOut: 'NOT_STARTED',
    userInfo: 'NOT_STARTED',
  },
  feedback: {
    isOpen: false,
    type: undefined,
    message: '',
  },
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    logoutStarted(state) {
      state.loadingStatus.logOut = 'PENDING';
    },
    logoutSuccess(state, action) {
      state.uid = action.payload;
      state.loadingStatus.logOut = 'COMPLETE';
    },
    logoutFailed(state, action) {
      state.loadingStatus.logOut = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getUserInfoStarted(state) {
      state.loadingStatus.userInfo = 'PENDING';
    },
    getUserInfoSuccess(state, action) {
      state.userInfo.email = action.payload.email;
      state.userInfo.name = action.payload.name;
      state.userInfo.phoneNumber = action.payload.phoneNumber;
      state.userInfo.surname = action.payload.surname;
      state.userInfo.title = action.payload.title;
      state.userInfo.type = action.payload.type;
      state.userInfo.agencyId = action.payload.agencyId;
      state.userInfo.agencyName = action.payload.agencyName;
      state.userInfo.isSuperAdmin = action.payload.isSuperAdmin;
      state.loadingStatus.userInfo = 'COMPLETE';
    },
    getUserInfoFailed(state, action) {
      state.loadingStatus.userInfo = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    updateUserId(state, action) {
      state.userInfo.uid = action.payload;
    },
    openNav(state) {
      state.isNavOpen = true;
    },
    closeNav(state) {
      state.isNavOpen = false;
    },
  },
});

export const {
  logoutStarted,
  logoutSuccess,
  logoutFailed,
  getUserInfoStarted,
  getUserInfoSuccess,
  getUserInfoFailed,
  updateUserId,
  openNav,
  closeNav,
} = accountSlice.actions;

export default accountSlice.reducer;

export const logOut = () => async (dispatch) => {
  dispatch(logoutStarted());
  const result = await signOut();

  if (result.status === 200) {
    dispatch(logoutSuccess(result.uid));
  } else {
    dispatch(logoutFailed(result.error));
  }
};

export const fetchUserInfo = (uid) => async (dispatch) => {
  dispatch(getUserInfoStarted());
  const result = await getUserInfo(uid);

  if (result.status === 200) {
    dispatch(getUserInfoSuccess(result.userInfo));
  } else {
    dispatch(getUserInfoFailed(result.error));
  }
};
