/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { bulkUpdate } from '../../../api/presalesAPI';

const initialState = {
  products: [],
  loadingStatus: {
    products: 'NOT_STARTED',
  },
  failedBulkSkus: [],
  bulkStockUploadLoadingCount: {
    total: 0,
    success: 0,
    failed: 0,
  },
  pageSize: 5,
  initialPage: 0,
};

const presalesProductsBrowseSlice = createSlice({
  name: 'presalesProductsBrowse',
  initialState,
  reducers: {
    changePageSize(state, action) {
      state.pageSize = action.payload;
    },
    changeInitialPage(state, action) {
      state.initialPage = action.payload;
    },
    updateTotalSkusToUpdate(state, action) {
      state.failedBulkSkus = [];
      state.bulkStockUploadLoadingCount.total = action.payload;
      state.bulkStockUploadLoadingCount.success = 0;
      state.bulkStockUploadLoadingCount.failed = 0;
    },
    bulkStockUpdateSuccess(state) {
      state.bulkStockUploadLoadingCount.success += 1;
    },
    bulkStockUpdateFailed(state, action) {
      state.bulkStockUploadLoadingCount.failed += 1;
      state.failedBulkSkus.push(action.payload);
    },
  },
});

export const {
  changePageSize,
  changeInitialPage,
  updateTotalSkusToUpdate,
  bulkStockUpdateSuccess,
  bulkStockUpdateFailed,
} = presalesProductsBrowseSlice.actions;

export const updatePageSize = (newPageSize) => (dispatch) => {
  dispatch(changePageSize(newPageSize));
};

export const updateInitialPage = (newInitialPage) => (dispatch) => {
  dispatch(changeInitialPage(newInitialPage));
};

export const setTotalSkusToUpdate = (total) => (dispatch) => {
  dispatch(updateTotalSkusToUpdate(total));
};

export const bulkStockUpdate = (skuInfo) => async (dispatch) => {
  const result = await bulkUpdate(skuInfo);

  if (result.status === 200) {
    dispatch(bulkStockUpdateSuccess());
  } else {
    dispatch(
      bulkStockUpdateFailed({
        sku: result.sku,
        eolSku: result.eolSku,
      })
    );
  }
};

export default presalesProductsBrowseSlice.reducer;
