/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getAgencies, addNewUser } from '../../../api/userAPI';

const initialState = {
  isUserRedirectedToBrowse: false,
  agencies: [],
  loadingStatus: {
    creation: 'NOT_STARTED',
    agencies: 'NOT_STARTED',
  },
  feedback: {
    isOpen: false,
    type: undefined,
    message: '',
  },
};

const usersAddSlice = createSlice({
  name: 'usersAdd',
  initialState,
  reducers: {
    createUserStarted(state) {
      state.loadingStatus.creation = 'PENDING';
    },
    createUserSuccess(state) {
      state.loadingStatus.creation = 'COMPLETE';
      state.isUserRedirectedToBrowse = true;
    },
    createUserFailed(state, action) {
      state.loadingStatus.creation = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    fetchAgenciesStarted(state) {
      state.loadingStatus.agencies = 'PENDING';
    },
    fetchAgenciesSuccess(state, action) {
      state.agencies = action.payload;
      state.loadingStatus.agencies = 'COMPLETE';
    },
    fetchAgenciesFailed(state, action) {
      state.loadingStatus.agencies = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    resetState() {
      return initialState;
    },
  },
});

export const {
  createUserStarted,
  createUserSuccess,
  createUserFailed,
  fetchAgenciesStarted,
  fetchAgenciesSuccess,
  fetchAgenciesFailed,
  resetState,
} = usersAddSlice.actions;

export default usersAddSlice.reducer;

export const createUser = (userInfo, loginCredentials) => async (dispatch) => {
  dispatch(createUserStarted());
  const result = await addNewUser(userInfo, loginCredentials);

  if (result.status === 200) {
    dispatch(createUserSuccess());
  } else {
    dispatch(createUserFailed(result.error));
  }
};

export const fetchAgencies = () => async (dispatch) => {
  dispatch(fetchAgenciesStarted());
  const result = await getAgencies();

  if (result.status === 200) {
    dispatch(fetchAgenciesSuccess(result.agencies));
  } else {
    dispatch(fetchAgenciesFailed(result.error));
  }
};

export const initiateStateReset = () => async (dispatch) => {
  dispatch(resetState());
};
