import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import LockIcon from '@material-ui/icons/Lock';
import PropTypes from 'prop-types';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  forgotPasswordLink: {
    textAlign: 'right',
    color: grey[700],
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  headingMain: {
    color: theme.palette.common.white,
    textAlign: 'center',
  },
  headingSubtitle: {
    color: grey[700],
  },
  logo: {
    width: 140,
    height: 'auto',
  },
  wrapperFields: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginBottom: theme.spacing(1),
    },
  },
  wrapperForm: {
    height: '66%',
    maxWidth: 480,
    padding: `${theme.spacing(2)}px ${theme.spacing(8)}px`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& > *': {
      margin: `${theme.spacing(2)}px 0`,
    },
  },
  wrapperIcon: {
    textAlign: 'center',
    color: grey[700],
  },
  wrapperHeadings: {
    '& > *': {
      marginBottom: theme.spacing(1),
    },
  },
}));

const ForgotPasswordForm = ({ goToLogin, resetPassword, isLoading }) => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
    };
  }, []);

  return (
    <Slide direction="left" in={mounted} onExit={goToLogin}>
      <form
        className={classes.wrapperForm}
        onSubmit={(e) => {
          e.preventDefault();
          resetPassword(email);
          setEmail('');
        }}
        noValidate
      >
        <div className={classes.wrapperHeadings}>
          <div className={classes.wrapperIcon}>
            <LockIcon fontSize="large" />
          </div>
          <Typography
            variant="h3"
            component="h2"
            className={classes.headingMain}
          >
            Password Reset
          </Typography>
          <Typography
            variant="caption"
            component="h3"
            className={classes.headingSubtitle}
          >
            Please enter your email addres below to reset your password.
          </Typography>
        </div>
        <div className={classes.wrapperFields}>
          <TextField
            autoComplete="username"
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <Button
          disabled={isLoading}
          variant="contained"
          color="secondary"
          size="large"
          type="submit"
          fullWidth
        >
          {isLoading ? <CircularProgress size={28} /> : 'Send reset email'}
        </Button>
        <Button
          disabled={isLoading}
          variant="outlined"
          color="secondary"
          size="large"
          onClick={() => setMounted(false)}
          fullWidth
        >
          Back to Login
        </Button>
      </form>
    </Slide>
  );
};

ForgotPasswordForm.propTypes = {
  goToLogin: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ForgotPasswordForm;
