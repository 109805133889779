import { green, grey, red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  loadingIndicator: {
    color: theme.palette.common.white,
  },
  loadingWrapper: {
    textAlign: 'center',
    margin: theme.spacing(4),
  },
  negativeValue: {
    backgroundColor: red[300],
    color: theme.palette.common.black,
    fontWeight: 'bold',
    width: '100%',
  },
  noChangesText: {
    color: grey[700],
    textAlign: 'center',
    margin: theme.spacing(6),
  },
  positiveValue: {
    backgroundColor: green[300],
    color: theme.palette.common.black,
    fontWeight: 'bold',
    width: '100%',
  },
  tableCell: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    border: 'none',
  },
  tableWrapper: {
    minWidth: 400,
  },
}));

const StockHistoryDialog = ({
  isOpen,
  isLoading,
  closeDialog,
  sku,
  stockChanges,
}) => {
  const classes = useStyles();

  const filteredStockChanges = stockChanges.filter(
    (row) => row.warehouseId === '5tI7NvUkhMtemnqcirGy'
  );

  return (
    <Dialog open={isOpen} onClose={closeDialog}>
      <DialogTitle>{`Stock History [${sku}]`}</DialogTitle>
      <DialogContent>
        {isLoading ? (
          <div className={classes.loadingWrapper}>
            <CircularProgress className={classes.loadingIndicator} />
          </div>
        ) : (
          <>
            {filteredStockChanges.length === 0 && (
              <Typography
                variant="body1"
                component="p"
                className={classes.noChangesText}
              >
                No stock changes
              </Typography>
            )}
            <TableContainer>
              <Table
                className={classes.tableWrapper}
                aria-label="quantity changes table"
              >
                <TableBody>
                  {filteredStockChanges.map((row) => (
                    <TableRow key={row.date}>
                      <TableCell className={classes.tableCell}>
                        {row.change > 0 ? (
                          <Chip
                            label={`+${row.change}`}
                            className={classes.positiveValue}
                          />
                        ) : (
                          <Chip
                            label={row.change}
                            className={classes.negativeValue}
                          />
                        )}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.tableCell}
                      >
                        {row.userName}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {moment(row.date).format('D MMMM YYYY')}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

StockHistoryDialog.defaultProps = {
  isOpen: true,
  isLoading: false,
  sku: 'KAN-BOO-0101',
  closeDialog: () => console.log('Dialog was closed.'),
  stockChanges: [],
};

StockHistoryDialog.propTypes = {
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  sku: PropTypes.string,
  closeDialog: PropTypes.func,
  stockChanges: PropTypes.arrayOf(
    PropTypes.shape({
      change: PropTypes.number,
      date: PropTypes.string,
      userId: PropTypes.string,
      userName: PropTypes.string,
      warehouseId: PropTypes.string,
    })
  ),
};

export default StockHistoryDialog;
