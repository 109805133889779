import { useState } from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import allStyles from '../../utils/styles';

const useStyles = makeStyles(allStyles);

const SearchBar = ({ refine, defaultRefinement }) => {
  const classes = useStyles();
  const [searchText, setSearchText] = useState(defaultRefinement || '');

  const onChange = (newValue) => {
    setSearchText(newValue);
    refine(newValue);
  };

  return (
    <div className={classes.wrapperRoot}>
      <TextField
        className={classes.typesenseSearchBar}
        fullWidth
        placeholder="Search"
        value={searchText}
        onChange={(e) => onChange(e.target.value)}
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />

      <div className={classes.separator} />
    </div>
  );
};

SearchBar.defaultProps = {
  defaultRefinement: '',
  refine: (newValue) => `Search text was changed to '${newValue}'`,
};

SearchBar.propTypes = {
  defaultRefinement: PropTypes.string,
  refine: PropTypes.func,
};

export default connectSearchBox(SearchBar);
