import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import {
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/GetApp';
import moment from 'moment';
import PropTypes from 'prop-types';
import theme from '../../../../../utils/theme';

const useStyles = makeStyles(() => ({
  tableCell: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.background.default,
    borderColor: theme.palette.background.default,
    fontSize: '1rem',
    padding: 12,
  },
  tableHeadCell: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.background.default,
    borderColor: theme.palette.background.default,
    borderBottomColor: grey[800],
    borderBottomWidth: 4,
    fontSize: '1rem',
    padding: 12,
  },
}));

const RequestedReports = ({ reports }) => {
  const classes = useStyles();

  const getButtonContents = (status) => {
    if (status === 'PENDING') {
      return <DownloadIcon style={{ color: theme.palette.primary.light }} />;
    }
    return <DownloadIcon style={{ color: theme.palette.secondary.main }} />;
  };

  const getPrettySupplierName = (supplierFilter) => {
    if (supplierFilter === 'Unknown') {
      return 'All Suppliers';
    }
    return supplierFilter;
  };

  const getPrettyReportType = (reportType) => {
    switch (reportType) {
      case 'PRICING_STOCK':
        return 'Pricing & Stock';
      case 'CONTENT':
        return 'Content';
      default:
        return 'Pricing & Stock';
    }
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell className={classes.tableHeadCell}>Report Type</TableCell>
          <TableCell align="center" className={classes.tableHeadCell}>
            Request Date
          </TableCell>
          <TableCell align="center" className={classes.tableHeadCell}>
            Requested By
          </TableCell>
          <TableCell align="center" className={classes.tableHeadCell}>
            Supplier
          </TableCell>
          <TableCell align="center" className={classes.tableHeadCell}>
            Download File
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {reports.map((reportInfo) => (
          <TableRow key={reportInfo.id}>
            <TableCell className={classes.tableCell} align="left">
              {getPrettyReportType(reportInfo.reportType)}
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              {moment(reportInfo.requestDate).format('MM/DD/YYYY')}
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              {reportInfo.requestedByName}
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              {getPrettySupplierName(reportInfo.supplierName)}
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              <IconButton
                disabled={reportInfo.status === 'PENDING'}
                component={Link}
                href={reportInfo.downloadLink}
              >
                {getButtonContents(reportInfo.status)}
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

RequestedReports.defaultProps = {
  reports: [],
};

RequestedReports.propTypes = {
  reports: PropTypes.arrayOf(
    PropTypes.shape({
      objectID: PropTypes.string,
    })
  ),
};

export default RequestedReports;
