/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getSuppliers } from '../../../api/suppliersAPI';

const initialState = {
  suppliersList: [],
  loadingStatus: {
    suppliers: 'NOT_STARTED',
  },
  pageSize: 5,
  initialPage: 0,
};

const suppliersBrowseSlice = createSlice({
  name: 'suppliersBrowse',
  initialState,
  reducers: {
    getSuppliersStarted(state) {
      state.loadingStatus.suppliers = 'PENDING';
    },
    getSuppliersSuccess(state, action) {
      state.suppliersList = action.payload;
      state.loadingStatus.suppliers = 'COMPLETE';
    },
    getSuppliersFailed(state, action) {
      state.loadingStatus.suppliers = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    changePageSize(state, action) {
      state.pageSize = action.payload;
    },
    changeInitialPage(state, action) {
      state.initialPage = action.payload;
    },
  },
});

export const {
  getSuppliersStarted,
  getSuppliersSuccess,
  getSuppliersFailed,
  changePageSize,
  changeInitialPage,
} = suppliersBrowseSlice.actions;

export const fetchSuppliers = (platform) => async (dispatch) => {
  dispatch(getSuppliersStarted());
  const result = await getSuppliers(platform);

  if (result.status === 200) {
    dispatch(getSuppliersSuccess(result.suppliers));
  } else {
    dispatch(getSuppliersFailed(result.error));
  }
};

export const updatePageSize = (newPageSize) => (dispatch) => {
  dispatch(changePageSize(newPageSize));
};

export const updateInitialPage = (newInitialPage) => (dispatch) => {
  dispatch(changeInitialPage(newInitialPage));
};

export default suppliersBrowseSlice.reducer;
