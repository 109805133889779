export const handleError = (err) => {
  console.log(err);
};

export const getLoadingValue = (
  updateLoadingCountTotal,
  updateLoadingCountSuccess,
  updateLoadingCountFailed
) => {
  if (updateLoadingCountTotal === 0) {
    return -1;
  }
  return Math.round(
    ((updateLoadingCountSuccess + updateLoadingCountFailed) /
      updateLoadingCountTotal) *
      100
  );
};
