import { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import {
  fetchAnalyticsData,
  fetchSkus,
  updateInitialPage,
  updatePageSize,
} from './slice';
import {
  applyExtraFilters,
  changeDateRange,
  getConversionRateSkuCounts,
  getFormattedListings,
  getLineGraphLabels,
  getLineGraphPageViews,
  getLineGraphRevenue,
  getLineGraphUnitsSold,
  getMetricTotals,
  getPageViewsSkuCounts,
} from './logic';
import ContentWrapper from '../../../components/ContentWrapper';
import ConversionRateDoughnutGraph from './components/ConversionRateDoughnutGraph';
import EmptyViewMessage from '../../../components/EmptyViewMessage';
import FiltersBar from './components/FiltersBar';
import PageHeader from '../../../components/PageHeader';
import PageViewsDoughnutGraph from './components/PageViewsDoughnutGraph';
import SecondaryFiltersBar from './components/SecondaryFiltersBar';
import SkusTable from './components/SkusTable';
import MetricsLineGraph from './components/MetricsLineGraph';
import TotalsBar from '../../../components/TotalsBar';

const breadcrumbs = [
  {
    label: 'Listings',
    link: '/listings',
  },
  {
    label: 'Analytics',
    link: '/listings/analytics',
  },
];

const ListingsAnalytics = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [platformFilter, setPlatformFilter] = useState('TAKEALOT');
  const [clientFilter, setClientFilter] = useState('ALL');
  const [brandFilter, setBrandFilter] = useState('ALL');
  const [searchFilter, setSearchFilter] = useState('');
  const [pageViewsFilter, setPageViewFilter] = useState('ALL');
  const [conversionRateFilter, setConversionRateFilter] = useState('ALL');
  const [rangeFilter, setRangeFilter] = useState('PAST_7_DAYS');
  const [startDate, setStartDate] = useState(
    moment().subtract(8, 'days').startOf('day').toDate()
  );
  const [endDate, setEndDate] = useState(
    moment().subtract(1, 'days').endOf('day').toDate()
  );
  const [filteredSkus, setFilteredSkus] = useState([]);
  const [extraFilteredSkus, setExtraFilteredSkus] = useState([]);

  const skus = useSelector((state) => state.listingsAnalytics.skusList);
  const pageSize = useSelector((state) => state.listingsBasicInfo.pageSize);
  const initialPage = useSelector(
    (state) => state.listingsBasicInfo.initialPage
  );
  const analyticsData = useSelector(
    (state) => state.listingsAnalytics.analyticsData
  );
  const skusLoadingStatus = useSelector(
    (state) => state.listingsAnalytics.loadingStatus.skus
  );
  const analyticsDataLoadingStatus = useSelector(
    (state) => state.listingsAnalytics.loadingStatus.analyticsData
  );

  useEffect(() => {
    if (skusLoadingStatus !== 'PENDING') {
      dispatch(fetchSkus(platformFilter));
      setClientFilter('ALL');
      setBrandFilter('ALL');
    }
  }, [platformFilter]);

  useEffect(() => {
    dispatch(fetchAnalyticsData(platformFilter, startDate, endDate));
  }, [platformFilter, startDate, endDate]);

  useEffect(() => {
    setFilteredSkus(
      getFormattedListings(
        skus,
        analyticsData,
        platformFilter,
        clientFilter,
        brandFilter
      )
    );
  }, [skus, analyticsData, platformFilter, clientFilter, brandFilter]);

  useEffect(() => {
    setExtraFilteredSkus(
      applyExtraFilters(
        filteredSkus,
        searchFilter,
        pageViewsFilter,
        conversionRateFilter
      )
    );
  }, [filteredSkus, searchFilter, pageViewsFilter, conversionRateFilter]);

  useEffect(() => {
    if (
      skusLoadingStatus === 'PENDING' ||
      analyticsDataLoadingStatus === 'PENDING'
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [skusLoadingStatus, analyticsDataLoadingStatus]);

  useEffect(() => {
    changeDateRange(rangeFilter, setStartDate, setEndDate);
  }, [rangeFilter]);

  const getClients = () => {
    return _.uniq(skus.map((info) => info.clientName)).sort();
  };

  const getBrands = () => {
    return _.uniq(
      skus
        .filter((info) => {
          if (clientFilter === 'ALL') {
            return true;
          }
          return info.clientName === clientFilter;
        })
        .map((info) => info.brand)
    ).sort();
  };

  return (
    <div>
      <PageHeader
        title="Analytics Dashboard"
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Dashboard"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
      />
      {isLoading && <LinearProgress color="secondary" />}
      <ContentWrapper>
        {!isLoading && skus.length === 0 ? (
          <Grid container spaacing={3}>
            <Grid item xs={12}>
              <Paper>
                <EmptyViewMessage
                  heading="No Internet Connection"
                  message="Please check your internet connection and reload."
                />
              </Paper>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FiltersBar
                startDate={startDate}
                changeStartDate={(newDate) =>
                  setStartDate(moment(newDate).startOf('day').toDate())
                }
                endDate={endDate}
                changeEndDate={(newDate) =>
                  setEndDate(moment(newDate).endOf('day').toDate())
                }
                rangeFilter={rangeFilter}
                changeRangeFilter={setRangeFilter}
                platformFilter={platformFilter}
                changePlatformFilter={(newPlatform) => {
                  setPlatformFilter(newPlatform);
                  setClientFilter('ALL');
                  setPageViewFilter('ALL');
                  setConversionRateFilter('ALL');
                }}
                clientFilter={clientFilter}
                changeClientFilter={(newClient) => {
                  setClientFilter(newClient);
                  setBrandFilter('ALL');
                  setPageViewFilter('ALL');
                  setConversionRateFilter('ALL');
                }}
                clientOptions={getClients()}
                brandFilter={brandFilter}
                changeBrandFilter={(newBrand) => {
                  setBrandFilter(newBrand);
                  setPageViewFilter('ALL');
                  setConversionRateFilter('ALL');
                }}
                brandOptions={getBrands()}
              />
            </Grid>
            {!isLoading && (
              <Grid item xs={12}>
                <TotalsBar
                  totals={getMetricTotals(filteredSkus, platformFilter)}
                />
              </Grid>
            )}
            <Grid item xs={12} md={12}>
              <MetricsLineGraph
                isLoading={isLoading}
                platformFilter={platformFilter}
                labels={getLineGraphLabels(startDate, endDate)}
                pageViews={getLineGraphPageViews(
                  filteredSkus,
                  startDate,
                  endDate
                )}
                revenue={getLineGraphRevenue(filteredSkus, startDate, endDate)}
                unitsSold={getLineGraphUnitsSold(
                  filteredSkus,
                  startDate,
                  endDate
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <PageViewsDoughnutGraph
                isLoading={isLoading}
                skuCounts={getPageViewsSkuCounts(
                  filteredSkus,
                  moment(endDate).diff(startDate, 'days')
                )}
                rangeInDays={moment(endDate).diff(startDate, 'days')}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ConversionRateDoughnutGraph
                isLoading={isLoading}
                skuCounts={getConversionRateSkuCounts(filteredSkus)}
              />
            </Grid>
            <Grid item xs={12}>
              <SecondaryFiltersBar
                searchText={searchFilter}
                changeSearchText={setSearchFilter}
                listingCount={extraFilteredSkus.length}
                conversionRateFilter={conversionRateFilter}
                changeConversionRateFilter={setConversionRateFilter}
                pageViewsFilter={pageViewsFilter}
                changePageViewsFilter={setPageViewFilter}
                rangeInDays={moment(endDate).diff(startDate, 'days')}
              />
            </Grid>
            <Grid item xs={12}>
              <SkusTable
                isLoading={isLoading}
                skus={extraFilteredSkus}
                platformFilter={platformFilter}
                viewSku={(sku) =>
                  history.push(`/listings/analytics/dashboard/${sku}`)
                }
                pageSize={pageSize}
                initialPage={initialPage}
                updatePageSize={(newPageSize) =>
                  dispatch(updatePageSize(newPageSize))
                }
                updateInitialPage={(newInitialPage) =>
                  dispatch(updateInitialPage(newInitialPage))
                }
              />
            </Grid>
          </Grid>
        )}
      </ContentWrapper>
    </div>
  );
};

export default ListingsAnalytics;
