import _ from 'lodash';

export const getFilteredSkus = (skus, searchText, supplierId) => {
  return (skus || [])
    .filter((skuInfo) => {
      if (supplierId === 'N/A') {
        return true;
      }

      return skuInfo.supplierId === supplierId;
    })
    .filter((skuInfo) => {
      if (searchText !== '') {
        const caseInsensitiveSearch = _.toLower(searchText);
        const caseInsensitiveEolSku = _.toLower(skuInfo.eolSku);
        const caseInsensitiveSupplierDescription = _.toLower(
          skuInfo.supplierDescription
        );

        if (caseInsensitiveEolSku.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (
          caseInsensitiveSupplierDescription.includes(caseInsensitiveSearch)
        ) {
          return true;
        }

        return false;
      }

      return true;
    });
};

export const checkFields = (fields, expectedFields) => {
  let isCorrect = true;
  fields.forEach((field, index) => {
    if (field !== expectedFields[index]) {
      isCorrect = false;
    }
  });
  return isCorrect;
};

export const checkIfSelected = (sku, selectedPresalesSkus) => {
  const relevantSku = selectedPresalesSkus.find(
    (selectedSkuInfo) => selectedSkuInfo.eolSku === sku
  );

  if (relevantSku) {
    return true;
  }

  return false;
};

export const getFormattedSkus = (filteredSkus, selectedPresalesSkus) => {
  const filt = filteredSkus.map((sku, index) => ({
    skuId: sku.id,
    supplierId: sku.supplierId,
    eolSku: sku.eolSku,
    supplierDescription: sku.supplierDescription,
    isUpdated: !!sku.takealotListingLink,
    takealotListingLink: sku.takealotListingLink,
    tableData: {
      id: index,
      checked: checkIfSelected(sku.eolSku, selectedPresalesSkus),
    },
  }));
  return filt;
};

export const handleError = (err) => {
  console.log(err);
};

export const createlistingEditCSV = (selectedPresalesSkus) => {
  const csvData = [['SKU', 'Takealot Listing Link']];

  selectedPresalesSkus.forEach((skuInformation) => {
    csvData.push([skuInformation.eolSku, '', '', '', '']);
  });
  return csvData;
};

export const getLoadingValue = (
  updateLoadingCountTotal,
  updateLoadingCountSuccess,
  updateLoadingCountFailed
) => {
  if (updateLoadingCountTotal === 0) {
    return -1;
  }
  return Math.round(
    ((updateLoadingCountSuccess + updateLoadingCountFailed) /
      updateLoadingCountTotal) *
      100
  );
};
