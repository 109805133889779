/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  getDeal,
  getDealProposals,
  createNewProposal,
  getSalesAgents,
  getSourcedProducts,
} from '../../../api/salesAPI';

const initialState = {
  isUserRedirectedToDeal: false,
  dealInfo: {
    businessName: 'Loading',
    dealValue: 0,
    status: 'UNKNOWN',
    platform: 'UNKNOWN',
  },
  proposals: [],
  products: [],
  loadingStatus: {
    dealInfo: 'NOT_STARTED',
    proposals: 'NOT_STARTED',
    creation: 'NOT_STARTED',
    salesAgents: 'NOT_STARTED',
    products: 'NOT_STARTED',
  },
  feedback: {
    isOpen: false,
    type: undefined,
    message: '',
  },
};

const dealsAdjustmentsSlice = createSlice({
  name: 'dealsAdjustments',
  initialState,
  reducers: {
    fetchDealInfoStarted(state) {
      state.loadingStatus.dealInfo = 'PENDING';
    },
    fetchDealInfoSuccess(state, action) {
      state.dealInfo = action.payload;
      state.loadingStatus.dealInfo = 'COMPLETE';
    },
    fetchDealInfoFailed(state, action) {
      state.loadingStatus.dealInfo = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    fetchProposalsStarted(state) {
      state.loadingStatus.proposals = 'PENDING';
    },
    fetchProposalsSuccess(state, action) {
      state.proposals = action.payload;
      state.loadingStatus.proposals = 'COMPLETE';
    },
    fetchProposalsFailed(state, action) {
      state.loadingStatus.proposals = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    createProposalStarted(state) {
      state.loadingStatus.creation = 'PENDING';
    },
    createProposalSuccess(state) {
      state.loadingStatus.creation = 'COMPLETE';
      state.isUserRedirectedToDeal = true;
    },
    createProposalFailed(state, action) {
      state.loadingStatus.creation = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getSalesAgentsStarted(state) {
      state.loadingStatus.salesAgents = 'PENDING';
    },
    getSalesAgentsSuccess(state, action) {
      state.salesAgents = action.payload;
      state.loadingStatus.salesAgents = 'COMPLETE';
    },
    getSalesAgentsFailed(state, action) {
      state.loadingStatus.salesAgents = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getProductsStarted(state) {
      state.loadingStatus.products = 'PENDING';
    },
    getProductsSuccess(state, action) {
      state.products = action.payload;
      state.loadingStatus.products = 'COMPLETE';
    },
    getProductsFailed(state, action) {
      state.loadingStatus.products = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    resetState() {
      return initialState;
    },
  },
});

export const {
  fetchDealInfoStarted,
  fetchDealInfoSuccess,
  fetchDealInfoFailed,
  fetchProposalsStarted,
  fetchProposalsSuccess,
  fetchProposalsFailed,
  createProposalStarted,
  createProposalSuccess,
  createProposalFailed,
  getSalesAgentsStarted,
  getSalesAgentsSuccess,
  getSalesAgentsFailed,
  getProductsStarted,
  getProductsSuccess,
  getProductsFailed,
  resetState,
} = dealsAdjustmentsSlice.actions;

export default dealsAdjustmentsSlice.reducer;

export const fetchDealInfo = (dealId) => async (dispatch) => {
  dispatch(fetchDealInfoStarted());
  const result = await getDeal(dealId);

  if (result.status === 200) {
    dispatch(fetchDealInfoSuccess(result.info));
  } else {
    dispatch(fetchDealInfoFailed(result.error));
  }
};

export const fetchProposals = (dealId) => async (dispatch) => {
  dispatch(fetchProposalsStarted());
  const result = await getDealProposals(dealId);

  if (result.status === 200) {
    dispatch(fetchProposalsSuccess(result.proposals));
  } else {
    dispatch(fetchProposalsFailed(result.error));
  }
};

export const createProposal =
  (dealId, dealInfo, proposalInfo, removedProducts) => async (dispatch) => {
    dispatch(createProposalStarted());
    const result = await createNewProposal(
      dealId,
      dealInfo,
      proposalInfo,
      removedProducts
    );

    if (result.status === 200) {
      dispatch(createProposalSuccess());
    } else {
      dispatch(createProposalFailed(result.error));
    }
  };

export const fetchSalesAgents = () => async (dispatch) => {
  dispatch(getSalesAgentsStarted());
  const result = await getSalesAgents();

  if (result.status === 200) {
    dispatch(getSalesAgentsSuccess(result.salesAgents));
  } else {
    dispatch(getSalesAgentsFailed(result.error));
  }
};

export const fetchProducts = () => async (dispatch) => {
  dispatch(getProductsStarted());
  const result = await getSourcedProducts('AVAILABLE');

  if (result.status === 200) {
    dispatch(getProductsSuccess(result.products));
  } else {
    dispatch(getProductsFailed(result.error));
  }
};

export const initiateStateReset = () => async (dispatch) => {
  dispatch(resetState());
};
