/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getClient, uploadDocuments } from '../../../api/clientsAPI';

const initialState = {
  clientInfo: {},
  loadingStatus: {
    clientInfo: 'NOT_STARTED',
  },
  uploadTransfers: {},
  documentUploadCounts: {
    total: 0,
    success: 0,
    failed: 0,
  },
  feedback: {
    isOpen: false,
    type: undefined,
    message: '',
  },
};

const clientsDocumentsUploadSlice = createSlice({
  name: 'clientsDocumentsUpload',
  initialState,
  reducers: {
    setTotalDocumentUploadCount(state, action) {
      state.documentUploadCounts.total = action.payload;
    },
    uploadDocumentSuccess(state) {
      state.documentUploadCounts.success += 1;
    },
    uploadDocumentFailed(state) {
      state.documentUploadCounts.failed += 1;
    },
    fetchClientInfoStarted(state) {
      state.loadingStatus.clientInfo = 'PENDING';
    },
    fetchClientInfoSuccess(state, action) {
      state.clientInfo = action.payload;
      state.loadingStatus.clientInfo = 'COMPLETE';
    },
    fetchClientInfoFailed(state, action) {
      state.loadingStatus.clientInfo = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    updateUploadBytes(state, action) {
      state.uploadTransfers = {
        ...state.uploadTransfers,
        [action.payload.docId]: {
          totalBytes: action.payload.totalBytes,
          transferredBytes: action.payload.transferredBytes,
        },
      };
    },
    resetUploadBytes(state) {
      state.uploadTotalBytes = 0;
      state.uploadTransferredBytes = 0;
    },
    resetState() {
      return initialState;
    },
  },
});

export const {
  setTotalDocumentUploadCount,
  uploadDocumentSuccess,
  uploadDocumentFailed,
  fetchClientInfoSuccess,
  fetchClientInfoFailed,
  fetchClientInfoStarted,
  resetState,
  updateUploadBytes,
  resetUploadBytes,
} = clientsDocumentsUploadSlice.actions;

export default clientsDocumentsUploadSlice.reducer;

export const uploadNewDocument =
  (clientId, doc, tag, description, uploaderId, uploaderName) =>
  async (dispatch) => {
    await uploadDocuments(
      clientId,
      doc,
      tag,
      description,
      uploaderId,
      uploaderName,
      (docId, totalBytes, transferredBytes) =>
        dispatch(updateUploadBytes({ docId, totalBytes, transferredBytes })),
      () => dispatch(uploadDocumentSuccess()),
      () => dispatch(uploadDocumentFailed())
    );
    dispatch(resetUploadBytes());
  };

export const fetchClientInfo = (clientId) => async (dispatch) => {
  dispatch(fetchClientInfoStarted());
  const result = await getClient(clientId);

  if (result.status === 200) {
    dispatch(fetchClientInfoSuccess(result.clientInfo));
  } else {
    dispatch(fetchClientInfoFailed({ message: result.error }));
  }
};
