import { useState } from 'react';
import Button from '@material-ui/core/Button';
import CreateIcon from '@material-ui/icons/Create';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';

const CopywriterSelector = ({
  copywriterFilter,
  changeCopywriterFilter,
  copywriterOptions,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const getPrettyFilter = (filter) => {
    if (filter === 'ALL') {
      return 'All Copywriters';
    }
    return filter;
  };

  return (
    <>
      <Button
        aria-controls="copywriters-menu"
        aria-haspopup="true"
        size="large"
        startIcon={<CreateIcon />}
        onClick={openMenu}
      >
        {getPrettyFilter(copywriterFilter)}
      </Button>
      <Menu
        id="copywriters-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem
          onClick={() => {
            changeCopywriterFilter('ALL');
            closeMenu();
          }}
        >
          All
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeCopywriterFilter('Unassigned');
            closeMenu();
          }}
        >
          Unassigned
        </MenuItem>
        {copywriterOptions.map((option) => (
          <MenuItem
            key={`filter-option-${option}`}
            onClick={() => {
              changeCopywriterFilter(option);
              closeMenu();
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

CopywriterSelector.defaultProps = {
  copywriterFilter: 'ALL',
  changeCopywriterFilter: (newFilter) =>
    console.log('User changes the copywriter filter to:', newFilter),
  copywriterOptions: [],
};

CopywriterSelector.propTypes = {
  copywriterFilter: PropTypes.string,
  changeCopywriterFilter: PropTypes.func,
  copywriterOptions: PropTypes.arrayOf(PropTypes.string),
};

export default CopywriterSelector;
