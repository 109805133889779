import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  buttonSpacing: {
    marginRight: theme.spacing(2),
  },
  pdfButtonInner: {
    display: 'flex',
    alignItems: 'center',
  },
  pdfButtonOuter: {
    color: theme.palette.common.black,
    textDecoration: 'none',
  },
}));

const ActionBar = ({ actions }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      {actions.map((action, index) => {
        if (action.type === 'pdfDownload') {
          return (
            <Button
              key={`action-bar-button-${action.label}`}
              className={index === actions.length ? {} : classes.buttonSpacing}
              color="secondary"
              variant="contained"
              size="large"
            >
              <PDFDownloadLink
                className={classes.pdfButtonOuter}
                document={action.config.document}
                fileName={action.config.fileName}
              >
                {({ loading }) => {
                  if (loading) {
                    return 'Loading...';
                  }
                  return (
                    <div className={classes.pdfButtonInner}>
                      {action.icon}
                      {action.label}
                    </div>
                  );
                }}
              </PDFDownloadLink>
            </Button>
          );
        }

        return (
          <Button
            key={`action-bar-button-${action.label}`}
            className={index === actions.length ? {} : classes.buttonSpacing}
            color="secondary"
            variant="contained"
            size="large"
            onClick={action.onClick}
          >
            {action.icon}
            {action.label}
          </Button>
        );
      })}
    </div>
  );
};

ActionBar.defaultProps = {
  actions: [],
};

ActionBar.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.node,
      onClick: PropTypes.func,
    })
  ),
};

export default ActionBar;
