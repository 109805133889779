/* eslint-disable react/jsx-props-no-spreading */
import { makeStyles } from '@material-ui/core/styles';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import GavelIcon from '@material-ui/icons/Gavel';
import FormControl from '@material-ui/core/FormControl';
import MaterialTable from 'material-table';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import getStyles from './styles';

const headerStyle = {
  backgroundColor: '#171415',
};

const useStyles = makeStyles(getStyles);

const QueriesTable = ({
  isLoading,
  queries,
  viewQuery,
  changeQueryStatus,
  pageSize,
  initialPage,
  updatePageSize,
  updateInitialPage,
}) => {
  const classes = useStyles();

  const getPrettyStatus = (status) => {
    switch (status) {
      case 'COMPLETED':
        return (
          <Chip
            icon={<DoneIcon className={classes.icon} />}
            label="Completed"
            className={classes.statusCompleted}
          />
        );
      case 'NOT_STARTED':
        return (
          <Chip
            icon={<NotificationsActiveIcon className={classes.icon} />}
            label="Not Started"
            className={classes.statusNotStarted}
          />
        );
      case 'IN_PROGRESS':
        return (
          <Chip
            icon={<AutorenewIcon className={classes.icon} />}
            label="In Progress"
            className={classes.statusInProgress}
          />
        );
      case 'CANCELLED':
        return (
          <Chip
            icon={<ClearIcon className={classes.icon} />}
            label="Cancelled"
            className={classes.statusCancelled}
          />
        );
      case 'AWAITING_APPROVAL':
        return (
          <Chip
            icon={<GavelIcon className={classes.icon} />}
            label="Awaiting Approval"
            className={classes.statusAwaitingApproval}
          />
        );
      default:
        return (
          <Chip
            icon={<DoneIcon className={classes.icon} />}
            label={status}
            className={classes.statusActive}
          />
        );
    }
  };

  const getPrettyType = (type) => {
    switch (type) {
      case 'LISTING_CHANGE':
        return 'Listing Edit';
      case 'PRICE_CHANGE':
        return 'Price Change';
      case 'PRODUCT_MISMATCH':
        return 'Received Product Mismatch';
      default:
        return 'Miscellanious';
    }
  };

  const getStatusList = () => {
    return [
      {
        value: 'NOT_STARTED',
        label: 'Not Started',
      },
      {
        value: 'IN_PROGRESS',
        label: 'In Progress',
      },
      {
        value: 'AWAITING_APPROVAL',
        label: 'Awaiting Approval',
      },
      {
        value: 'CANCELLED',
        label: 'Cancelled',
      },
      {
        value: 'COMPLETED',
        label: 'Completed',
      },
    ].map((info) => (
      <MenuItem key={info.value} value={info.value}>
        {info.label}
      </MenuItem>
    ));
  };

  const getColumns = () => {
    const columns = [
      {
        title: 'Query ID',
        field: 'id',
        editable: 'never',
      },
      {
        title: 'Request Date',
        field: 'requestDate',
        editable: 'never',
        render: (rowData) => moment(rowData.requestDate).format('DD/MM/YYYY'),
      },
      {
        title: 'Client',
        field: 'clientName',
        editable: 'never',
      },
      {
        title: 'SKUs',
        field: 'units',
        editable: 'never',
        align: 'center',
        render: (rowData) => rowData.skus.length,
      },
      {
        title: 'Assigned To',
        field: 'assigneeName',
        editable: 'never',
      },
      {
        title: 'Type',
        field: 'requestType',
        editable: 'never',
        render: (rowData) => getPrettyType(rowData.requestType),
      },
      {
        title: 'Status',
        field: 'status',
        align: 'center',
        render: (rowData) => getPrettyStatus(rowData.status),
        editComponent: (row) => (
          <FormControl>
            <Select
              labelId="status-select-disabled-label"
              id="status-select-disabled"
              value={row.value}
              onChange={(e) => row.onChange(e.target.value)}
            >
              {getStatusList()}
            </Select>
          </FormControl>
        ),
      },
    ];

    return columns;
  };

  return (
    <div className={classes.wrapperContent}>
      <MaterialTable
        title=""
        columns={getColumns()}
        data={queries}
        isLoading={isLoading}
        onRowsPerPageChange={updatePageSize}
        onPageChange={updateInitialPage}
        options={{
          pageSize,
          initialPage,
          headerStyle,
          actionsColumnIndex: -1,
          search: false,
          grouping: false,
          draggable: false,
          exportButton: true,
          exportAllData: true,
          exportFileName: 'Queries',
        }}
        editable={{
          onRowUpdate: (newData) =>
            new Promise((resolve) => {
              changeQueryStatus(newData.id, newData.status);
              resolve();
            }),
        }}
        actions={[
          {
            icon: 'keyboard_arrow_right',
            tooltip: 'View query info',
            onClick: (event, rowData) => viewQuery(rowData.id),
          },
        ]}
        components={{
          OverlayLoading: () => (
            <div className={classes.loadingOverlay}>
              <CircularProgress color="primary" size={32} />
            </div>
          ),
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'No queries to display',
          },
        }}
      />
    </div>
  );
};

QueriesTable.defaultProps = {
  isLoading: false,
  queries: [],
  viewQuery: (id) => console.log(`Query viewed: ${id}`),
  changeQueryStatus: (id) => console.log(`Query edited: ${id}`),
  pageSize: 5,
  initialPage: 0,
  updatePageSize: (newPageSize) =>
    console.log(`Page size updated to: ${newPageSize}`),
  updateInitialPage: (newInitialPage) =>
    console.log(`Initial page updated to: ${newInitialPage}`),
};

QueriesTable.propTypes = {
  isLoading: PropTypes.bool,
  queries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      fromName: PropTypes.string,
      toName: PropTypes.string,
      units: PropTypes.number,
      status: PropTypes.oneOf([
        'NOT_STARTED',
        'IN_PROGRESS',
        'COMPLETED',
        'CANCELLED',
        'AWAITING_APPROVAL',
      ]),
      creationDate: PropTypes.string,
      requestType: PropTypes.oneOf([
        'LISTING_CHANGE',
        'PRICE_CHANGE',
        'MISCELLANEOUS',
        'PRODUCT_MISMATCH',
      ]),
    })
  ),
  viewQuery: PropTypes.func,
  changeQueryStatus: PropTypes.func,
  pageSize: PropTypes.number,
  initialPage: PropTypes.number,
  updatePageSize: PropTypes.func,
  updateInitialPage: PropTypes.func,
};

export default QueriesTable;
