import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';

export const getFormattedListing = (
  skuInfo,
  analyticsData,
  platformFilter = 'TAKEALOT'
) => {
  const dailyData = analyticsData.map((allAnalytics) => {
    const skuAnalytics = allAnalytics.find((data) => {
      if (platformFilter === 'TAKEALOT') {
        return skuInfo.takealot && data.tsin === skuInfo.takealot.tsin;
      }
      return skuInfo.amazon && skuInfo.amazon.childAsin === data.childAsin;
    });
    if (skuAnalytics) {
      return skuAnalytics;
    }
    return {};
  });

  const unitsSold = dailyData.reduce((total, currentDay) => {
    if (currentDay.unitsSold) {
      return total + currentDay.unitsSold;
    }
    return total;
  }, 0);

  const revenue = dailyData.reduce((total, currentDay) => {
    if (currentDay.totalSalesRand) {
      return total + currentDay.revenue;
    }
    return total;
  }, 0);
  const pageViews = dailyData.reduce((total, currentDay) => {
    if (currentDay.pageViews) {
      return total + currentDay.pageViews;
    }
    return total;
  }, 0);
  const conversionRate = pageViews === 0 ? null : unitsSold / pageViews;

  return {
    sku: skuInfo.sku,
    tsin: skuInfo.takealot.tsin,
    asin: skuInfo.amazon.asin,
    barcode: skuInfo.takealot.barcode,
    clientName: skuInfo.clientName,
    brand: skuInfo.brand,
    productName: skuInfo.productName,
    variation: skuInfo.variation,
    creationDate: skuInfo.creationDate || '-',
    unitsSold,
    conversionRate,
    pageViews,
    revenue,
    dailyData,
  };
};

export const getProductName = (skuInfo) => {
  return skuInfo.variation === 'N/A'
    ? `${skuInfo.brand} - ${skuInfo.productName}`
    : `${skuInfo.brand} - ${skuInfo.productName} (${skuInfo.variation})`;
};

export const changeDateRange = (
  rangeFilter,
  changeStartDate,
  changeEndDate
) => {
  if (rangeFilter === 'THIS_MONTH') {
    changeStartDate(moment().startOf('month').toDate());
    changeEndDate(moment().subtract(1, 'days').endOf('day').toDate());
  } else if (rangeFilter === 'THIS_WEEK') {
    changeStartDate(moment().startOf('week').toDate());
    changeEndDate(moment().subtract(1, 'days').endOf('day').toDate());
  } else if (rangeFilter === 'PAST_7_DAYS') {
    changeStartDate(moment().subtract(8, 'days').toDate());
    changeEndDate(moment().subtract(1, 'days').endOf('day').toDate());
  } else if (rangeFilter === 'PAST_30_DAYS') {
    changeStartDate(moment().subtract(31, 'days').toDate());
    changeEndDate(moment().subtract(1, 'days').endOf('day').toDate());
  }
};

export const getMetricTotals = (formattedSku) => {
  return [
    {
      value: `R ${numeral(formattedSku.revenue).format('0,0')}`,
      label: 'Revenue',
    },
    {
      value: numeral(formattedSku.unitsSold).format('0,0'),
      label: 'Units Sold',
    },
    {
      value: numeral(formattedSku.pageViews).format('0,0'),
      label: 'Page Views',
    },
    {
      value: `${
        formattedSku.pageViews === 0
          ? 0
          : Math.round((formattedSku.unitsSold / formattedSku.pageViews) * 100)
      } %`,
      label: 'Conversion Rate',
    },
  ];
};

export const getLineGraphLabels = (startDate, endDate) => {
  const yesterdaysDateString = moment().subtract(1, 'days').format('DDMMYYYY');
  return _.times(moment(endDate).diff(startDate, 'days')).map((index) => {
    const dateString = moment(startDate)
      .add(index + 1, 'days')
      .format('DDMMYYYY');
    if (dateString === yesterdaysDateString) {
      return 'Yesterday';
    }
    return moment(startDate)
      .add(index + 1, 'days')
      .format('DD/MM/YYYY');
  });
};

export const getLineGraphRevenue = (formattedSku) => {
  return formattedSku.dailyData.map((data) => data.revenue);
};

export const getLineGraphUnitsSold = (formattedSku) => {
  return formattedSku.dailyData.map((data) => data.unitsSold);
};

export const getLineGraphPageViews = (formattedSku) => {
  return formattedSku.dailyData.map((data) => data.pageViews);
};
