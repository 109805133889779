const assembleTypesenseServerConfig = () => {
  const TYPESENSE_SERVER_CONFIG = {
    apiKey: 'eujUF4TuGtlbgia95XhHm9dEcGOCDaub',
    nodes: [
      {
        host: '2mpwu83tleb6xdysp-1.a1.typesense.net',
        port: '443',
        protocol: 'https',
      },
    ],
    numRetries: 8,
    connectionTimeoutSeconds: 1,
  };

  return TYPESENSE_SERVER_CONFIG;
};

export default assembleTypesenseServerConfig;
