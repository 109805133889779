import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import FolderIcon from '@material-ui/icons/Folder';
import PropTypes from 'prop-types';
import theme from '../../../../../utils/theme';

const useStyles = makeStyles(() => ({
  tableCell: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.background.default,
    borderColor: theme.palette.background.default,
    fontSize: '1rem',
    padding: 12,
  },
  tableHeadCell: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.background.default,
    borderColor: theme.palette.background.default,
    borderBottomColor: grey[800],
    borderBottomWidth: 4,
    fontSize: '1rem',
    padding: 12,
  },
}));

const Submissions = ({ submissionInfo }) => {
  const classes = useStyles();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell className={classes.tableHeadCell}> </TableCell>
          <TableCell className={classes.tableHeadCell}>Submission ID</TableCell>
          <TableCell align="center" className={classes.tableHeadCell}>
            Loadsheet
          </TableCell>
          <TableCell align="center" className={classes.tableHeadCell}>
            Submission Date
          </TableCell>
          <TableCell align="center" className={classes.tableHeadCell}>
            Submission Status
          </TableCell>
          <TableCell align="center" className={classes.tableHeadCell}>
            Approved SKUs
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {submissionInfo.map((submissionSkuInfo) => (
          <TableRow key={submissionSkuInfo.id}>
            <TableCell className={classes.tableCell}>
              <FolderIcon />
            </TableCell>
            <TableCell className={classes.tableCell} align="left">
              {submissionSkuInfo.name}
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              {submissionSkuInfo.dataType}
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              {submissionSkuInfo.submissionDate}
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              {submissionSkuInfo.percentage === 100 ? (
                'Complete'
              ) : (
                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                  <CircularProgress
                    style={{ color: theme.palette.secondary.main }}
                    size={55}
                    variant="determinate"
                    value={submissionSkuInfo.percentage}
                  />
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography
                      variant="body2"
                      component="div"
                      color={theme.palette.common.white}
                    >
                      {`${Math.round(submissionSkuInfo.percentage)}%`}
                    </Typography>
                  </Box>
                </Box>
              )}
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              {`${submissionSkuInfo.successfulSkus} of ${submissionSkuInfo.totalSkus} approved`}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

Submissions.defaultProps = {
  submissionInfo: [],
};

Submissions.propTypes = {
  submissionInfo: PropTypes.arrayOf(
    PropTypes.shape({
      objectID: PropTypes.string,
    })
  ),
};

export default Submissions;
