/* eslint-disable react/jsx-wrap-multilines */
import { makeStyles } from '@material-ui/core/styles';
import numeral from 'numeral';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  loadingWrapper: {
    padding: theme.spacing(8),
    textAlign: 'center',
  },
}));

const TakealotInfo = ({ takealotInfo, isLoading, onMissingInfoClick }) => {
  const classes = useStyles();
  const { barcode, tsin, retailPriceRands, sku, wholesalePriceRands } =
    takealotInfo;

  const formatNumber = (value, currencySymbol, type = 'float') => {
    if (value === null) {
      return '-';
    }
    if (type === 'integer') {
      return `${currencySymbol} ${numeral(value).format('0,0')}`;
    }
    return `${currencySymbol} ${numeral(value).format('0,0.00')}`;
  };

  return (
    <>
      {isLoading ? (
        <div className={classes.loadingWrapper}>
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <List
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  Codes
                </ListSubheader>
              }
            >
              <ListItem>
                <ListItemText
                  primary={!sku ? 'Missing Info' : sku}
                  secondary="SKU"
                />
                {!sku && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={onMissingInfoClick}
                    >
                      <HelpOutlineIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={!barcode ? 'Missing Info' : barcode}
                  secondary="Barcode"
                />
                {!barcode && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={onMissingInfoClick}
                    >
                      <HelpOutlineIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={!tsin ? 'Missing Info' : tsin}
                  secondary="TSIN"
                />
                {!tsin && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={onMissingInfoClick}
                    >
                      <HelpOutlineIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={6}>
            <List
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  Pricing & Payments
                </ListSubheader>
              }
            >
              <ListItem>
                <ListItemText
                  primary={
                    !wholesalePriceRands
                      ? 'Missing Info'
                      : formatNumber(wholesalePriceRands, 'R')
                  }
                  secondary="Wholesale Price"
                />
                {!wholesalePriceRands && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={onMissingInfoClick}
                    >
                      <HelpOutlineIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    !retailPriceRands
                      ? 'Missing Info'
                      : formatNumber(retailPriceRands, 'R', 'integer')
                  }
                  secondary="Retail Price"
                />
                {!retailPriceRands && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={onMissingInfoClick}
                    >
                      <HelpOutlineIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            </List>
          </Grid>
        </Grid>
      )}
    </>
  );
};

TakealotInfo.defaultProps = {
  takealotInfo: {
    sku: 'SKU-PRO-0101',
    barcode: '12345',
    tsin: '12345',
    retailPriceRands: 200,
    wholesalePriceRands: 100,
  },
  isLoading: false,
  onMissingInfoClick: () => console.log('Missing info button was clicked.'),
};

TakealotInfo.propTypes = {
  takealotInfo: PropTypes.shape({
    sku: PropTypes.string,
    barcode: PropTypes.string,
    tsin: PropTypes.string,
    retailPriceRands: PropTypes.number,
    wholesalePriceRands: PropTypes.number,
  }),
  isLoading: PropTypes.bool,
  onMissingInfoClick: PropTypes.func,
};

export default TakealotInfo;
