/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { uploadQueryDocuments } from '../../../api/clientsAPI';

const initialState = {
  loadingStatus: {
    clientInfo: 'NOT_STARTED',
  },
  uploadTransfers: {},
  documentUploadCounts: {
    total: 0,
    success: 0,
    failed: 0,
  },
  feedback: {
    isOpen: false,
    type: undefined,
    message: '',
  },
};

const clientsDocumentsUploadSlice = createSlice({
  name: 'clientsDocumentsUpload',
  initialState,
  reducers: {
    setTotalDocumentUploadCount(state, action) {
      state.documentUploadCounts.total = action.payload;
    },
    uploadDocumentSuccess(state) {
      state.documentUploadCounts.success += 1;
    },
    uploadDocumentFailed(state) {
      state.documentUploadCounts.failed += 1;
    },
    updateUploadBytes(state, action) {
      state.uploadTransfers = {
        ...state.uploadTransfers,
        [action.payload.docId]: {
          totalBytes: action.payload.totalBytes,
          transferredBytes: action.payload.transferredBytes,
        },
      };
    },
    resetUploadBytes(state) {
      state.uploadTotalBytes = 0;
      state.uploadTransferredBytes = 0;
    },
    resetState() {
      return initialState;
    },
  },
});

export const {
  setTotalDocumentUploadCount,
  uploadDocumentSuccess,
  uploadDocumentFailed,
  resetState,
  updateUploadBytes,
  resetUploadBytes,
} = clientsDocumentsUploadSlice.actions;

export default clientsDocumentsUploadSlice.reducer;

export const uploadNewDocument =
  (queryId, doc, description, uploaderId, uploaderName, date) =>
  async (dispatch) => {
    await uploadQueryDocuments(
      queryId,
      doc,
      description,
      uploaderId,
      uploaderName,
      date,
      (docId, totalBytes, transferredBytes) =>
        dispatch(updateUploadBytes({ docId, totalBytes, transferredBytes })),
      () => dispatch(uploadDocumentSuccess()),
      () => dispatch(uploadDocumentFailed())
    );
    dispatch(resetUploadBytes());
  };
