import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { grey, orange } from '@material-ui/core/colors';
import Avatar from '@material-ui/core/Avatar';
import { Line } from 'react-chartjs-2';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import Skeleton from '@material-ui/lab/Skeleton';
import TimelineIcon from '@material-ui/icons/Timeline';

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.secondary.light,
  },
  formControl: {
    minWidth: 200,
    margin: theme.spacing(2),
  },
  loadingRect: {
    height: 300,
    backgroundColor: grey[900],
  },
}));

const MetricsLineGraph = ({
  isLoading,
  labels,
  revenue,
  unitsSold,
  pageViews,
  platformFilter,
}) => {
  const classes = useStyles();

  const [metricSelected, setMetricSelected] = useState('REVENUE');

  const getDatasetLabel = () => {
    switch (metricSelected) {
      case 'REVENUE':
        return platformFilter === 'TAKEALOT' ? 'Rands' : 'US Dollars';
      case 'UNITS_SOLD':
        return 'Units';
      case 'PAGE_VIEWS':
        return 'Views';
      case 'CONVERSION_RATE':
        return 'Rate';
      default:
        return 'Unknown';
    }
  };

  const getDataset = () => {
    switch (metricSelected) {
      case 'REVENUE':
        return revenue;
      case 'UNITS_SOLD':
        return unitsSold;
      case 'PAGE_VIEWS':
        return pageViews;
      case 'CONVERSION_RATE':
        return unitsSold.map((units, index) => {
          if (pageViews[index] > 0) {
            return (units / pageViews[index]) * 100;
          }
          return null;
        });
      default:
        return revenue;
    }
  };

  const data = {
    labels,
    datasets: [
      {
        label: getDatasetLabel(),
        data: getDataset(),
        fill: false,
        backgroundColor: orange[700],
        borderColor: `${orange[700]}33`,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <Card>
      <CardHeader
        avatar={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <Avatar className={classes.avatar}>
            <TimelineIcon />
          </Avatar>
        }
        title="Performance Over Time"
        action={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <FormControl className={classes.formControl}>
            <Select
              id="metric-select"
              value={metricSelected}
              onChange={(e) => setMetricSelected(e.target.value)}
            >
              <MenuItem value="REVENUE">Revenue</MenuItem>
              <MenuItem value="UNITS_SOLD">Units Sold</MenuItem>
              <MenuItem value="PAGE_VIEWS">Page Views</MenuItem>
              <MenuItem value="CONVERSION_RATE">Conversion Rate</MenuItem>
            </Select>
          </FormControl>
        }
      />
      <CardContent>
        {isLoading ? (
          <Skeleton variant="rect" className={classes.loadingRect} />
        ) : (
          <div>
            <Line height={300} data={data} options={options} />
          </div>
        )}
      </CardContent>
    </Card>
  );
};

MetricsLineGraph.defaultProps = {
  isLoading: true,
  labels: [
    '25/06/2021',
    '26/06/2021',
    '27/06/2021',
    '28/06/2021',
    '29/06/2021',
    '30/06/2021',
    'Yesterday',
  ],
  revenue: [3000, 1800, 4050, 450, 13950, 4800, 300],
  unitsSold: [20, 12, 27, 3, 93, 32, 2],
  pageViews: [200, 51, 447, 111, 827, 219, 57],
  platformFilter: 'TAKEALOT',
};

MetricsLineGraph.propTypes = {
  isLoading: PropTypes.bool,
  labels: PropTypes.arrayOf(PropTypes.string),
  revenue: PropTypes.arrayOf(PropTypes.number),
  unitsSold: PropTypes.arrayOf(PropTypes.number),
  pageViews: PropTypes.arrayOf(PropTypes.number),
  platformFilter: PropTypes.string,
};

export default MetricsLineGraph;
