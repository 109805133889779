import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
  changeShipmentStatus,
  fetchShipments,
  fetchWarehouses,
  markShipmentCompleted,
  updateInitialPage,
  updatePageSize,
} from './slice';
import {
  getFormattedShipments,
  getShipmentToMarkCompleted,
  getWarehouseTotals,
} from './logic';
import CompleteShipmentDialog from './components/CompleteShipmentDialog';
import ContentWrapper from '../../../components/ContentWrapper';
import EmptyViewMessage from '../../../components/EmptyViewMessage';
import FiltersBar from './components/FiltersBar';
import PageHeader from '../../../components/PageHeader';
import ShipmentsTable from './components/ShipmentsTable';
import TotalsBar from '../../../components/TotalsBar';

const breadcrumbs = [
  {
    label: 'Logistics',
    link: '/logistics',
  },
  {
    label: 'Shipment',
    link: '/logistics/shipment',
  },
];

const ShipmentsBasicInfo = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState('ALL');
  const [shipmentIdToMarkComplete, setShipmentIdToMarkComplete] = useState('');
  const [formattedShipments, setFormattedShipments] = useState([]);
  const [isCompleteShipmentDialogOpen, setIsCompleteShipmentDialogOpen] =
    useState(false);

  const shipments = useSelector((state) => state.logisticsShipment.shipments);
  const warehouses = useSelector(
    (state) => state.logisticsShipment.warehousesList
  );
  const pageSize = useSelector((state) => state.logisticsShipment.pageSize);
  const initialPage = useSelector(
    (state) => state.logisticsShipment.initialPage
  );
  const shipmentsLoadingStatus = useSelector(
    (state) => state.logisticsShipment.loadingStatus.shipments
  );
  const updateShipmentLoadingStatus = useSelector(
    (state) => state.logisticsShipment.loadingStatus.updateShipment
  );
  const warehousesLoadingStatus = useSelector(
    (state) => state.logisticsShipment.loadingStatus.warehouses
  );

  useEffect(() => {
    if (shipmentsLoadingStatus !== 'PENDING') {
      dispatch(fetchShipments());
    }
    if (warehousesLoadingStatus !== 'PENDING') {
      dispatch(fetchWarehouses());
    }
  }, []);

  useEffect(() => {
    if (
      shipmentsLoadingStatus === 'PENDING' ||
      updateShipmentLoadingStatus === 'PENDING' ||
      warehousesLoadingStatus === 'PENDING'
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [
    shipmentsLoadingStatus,
    updateShipmentLoadingStatus,
    warehousesLoadingStatus,
  ]);

  useEffect(() => {
    setFormattedShipments(
      getFormattedShipments(shipments, searchQuery, statusFilter)
    );
  }, [shipments, searchQuery, statusFilter]);

  return (
    <div>
      <PageHeader
        title="Shipments"
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Browse"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
        actions={[
          {
            label: 'Add New Shipment',
            icon: <AddIcon />,
            onClick: () => history.push('/logistics/shipments/add'),
          },
        ]}
      />
      {isLoading && <LinearProgress color="secondary" />}
      <ContentWrapper>
        {!isLoading && shipments.length === 0 ? (
          <EmptyViewMessage
            heading="No Shipments"
            message="Please adjust the filters or add new shipments to view them here."
          />
        ) : (
          <Grid container spacing={3}>
            {!isLoading && (
              <Grid item xs={12}>
                <TotalsBar
                  totals={getWarehouseTotals(formattedShipments, warehouses)}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <FiltersBar
                searchText={searchQuery}
                changeSearchText={setSearchQuery}
                shipmentCount={formattedShipments.length}
                statusFilter={statusFilter}
                changeStatusFilter={setStatusFilter}
              />
              <ShipmentsTable
                isLoading={isLoading}
                shipments={formattedShipments}
                viewShipment={(shipmentId) =>
                  history.push(`/logistics/shipments/browse/${shipmentId}`)
                }
                changeShipmentInfo={(shipmentId, status) => {
                  if (status === 'COMPLETE') {
                    setIsCompleteShipmentDialogOpen(true);
                    setShipmentIdToMarkComplete(shipmentId);
                  } else {
                    dispatch(changeShipmentStatus(shipmentId, status));
                  }
                }}
                pageSize={pageSize}
                initialPage={initialPage}
                updatePageSize={(newPageSize) =>
                  dispatch(updatePageSize(newPageSize))
                }
                updateInitialPage={(newInitialPage) =>
                  dispatch(updateInitialPage(newInitialPage))
                }
              />
            </Grid>
          </Grid>
        )}
      </ContentWrapper>
      <CompleteShipmentDialog
        isOpen={isCompleteShipmentDialogOpen}
        completeShipment={(discrepancies) => {
          dispatch(
            markShipmentCompleted(shipmentIdToMarkComplete, discrepancies)
          );
          setIsCompleteShipmentDialogOpen(false);
        }}
        shipmentSkus={getShipmentToMarkCompleted(
          shipmentIdToMarkComplete,
          shipments
        )}
        closeDialog={() => setIsCompleteShipmentDialogOpen(false)}
      />
    </div>
  );
};

export default ShipmentsBasicInfo;
