import { fade } from '@material-ui/core/styles/colorManipulator';
import { green, red, orange, yellow, blue } from '@material-ui/core/colors';

const getStyles = (theme) => ({
  icon: {
    color: theme.palette.common.black,
  },
  link: {
    color: theme.palette.common.black,
  },
  loadingOverlay: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: fade(theme.palette.common.black, 0.8),
  },
  statusCompleted: {
    width: '100%',
    color: theme.palette.common.black,
    backgroundColor: green[500],
  },
  statusNotStarted: {
    width: '100%',
    color: theme.palette.common.black,
    backgroundColor: orange[500],
  },
  statusInProgress: {
    width: '100%',
    color: theme.palette.common.black,
    backgroundColor: yellow[500],
  },
  statusCancelled: {
    width: '100%',
    color: theme.palette.common.black,
    backgroundColor: red[500],
  },
  statusAwaitingApproval: {
    width: '100%',
    color: theme.palette.common.black,
    backgroundColor: blue[500],
  },
  wrapperContent: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
  type: {
    width: '100%',
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
  },
});

export default getStyles;
