/* eslint-disable react/jsx-wrap-multilines */
import { makeStyles } from '@material-ui/core/styles';
import numeral from 'numeral';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  loadingWrapper: {
    padding: theme.spacing(8),
    textAlign: 'center',
  },
}));

const AmazonInfo = ({ amazonInfo, isLoading, onMissingInfoClick }) => {
  const classes = useStyles();
  const {
    sku,
    barcode,
    fnsku,
    childAsin,
    parentAsin,
    paymentCalculation,
    logisticsCostRands,
    retailPriceDollars,
    wholesalePriceRands,
  } = amazonInfo;

  const formatNumber = (value, currencySymbol, type = 'float') => {
    if (value === null) {
      return '-';
    }
    if (type === 'integer') {
      return `${currencySymbol} ${numeral(value).format('0,0')}`;
    }
    return `${currencySymbol} ${numeral(value).format('0,0.00')}`;
  };

  const getPrettyPaymentCalculationText = () => {
    switch (paymentCalculation) {
      case 'WHOLESALE':
        return 'Wholesale Price Only';
      case 'WHOLESALE_LOGISTICS':
        return 'Wholesale Price + Logistics Cost';
      default:
        return paymentCalculation;
    }
  };

  return (
    <>
      {isLoading ? (
        <div className={classes.loadingWrapper}>
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <List
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  Codes
                </ListSubheader>
              }
            >
              <ListItem>
                <ListItemText
                  primary={!sku ? 'Missing Info' : sku}
                  secondary="SKU"
                />
                {!sku && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={onMissingInfoClick}
                    >
                      <HelpOutlineIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={!barcode ? 'Missing Info' : barcode}
                  secondary="Barcode"
                />
                {!barcode && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={onMissingInfoClick}
                    >
                      <HelpOutlineIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={!fnsku ? 'Missing Info' : fnsku}
                  secondary="FNSKU"
                />
                {!fnsku && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={onMissingInfoClick}
                    >
                      <HelpOutlineIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={!childAsin ? 'Missing Info' : childAsin}
                  secondary="Child ASIN"
                />
                {!childAsin && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={onMissingInfoClick}
                    >
                      <HelpOutlineIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={!parentAsin ? 'Missing Info' : parentAsin}
                  secondary="Parent ASIN"
                />
                {!parentAsin && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={onMissingInfoClick}
                    >
                      <HelpOutlineIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={6}>
            <List
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  Pricing & Payments
                </ListSubheader>
              }
            >
              <ListItem>
                <ListItemText
                  primary={
                    !paymentCalculation
                      ? 'Missing Info'
                      : getPrettyPaymentCalculationText()
                  }
                  secondary="Logistics Calculation"
                />
                {!paymentCalculation && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={onMissingInfoClick}
                    >
                      <HelpOutlineIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    !logisticsCostRands
                      ? 'Missing Info'
                      : formatNumber(logisticsCostRands, 'R')
                  }
                  secondary="Logistics Cost"
                />
                {!logisticsCostRands && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={onMissingInfoClick}
                    >
                      <HelpOutlineIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    !wholesalePriceRands
                      ? 'Missing Info'
                      : formatNumber(wholesalePriceRands, 'R')
                  }
                  secondary="Wholesale Price"
                />
                {!wholesalePriceRands && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={onMissingInfoClick}
                    >
                      <HelpOutlineIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    !retailPriceDollars
                      ? 'Missing Info'
                      : formatNumber(retailPriceDollars, '$')
                  }
                  secondary="Retail Price"
                />
                {!retailPriceDollars && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={onMissingInfoClick}
                    >
                      <HelpOutlineIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            </List>
          </Grid>
        </Grid>
      )}
    </>
  );
};

AmazonInfo.defaultProps = {
  amazonInfo: {
    sku: 'SKU-PRO-0101',
    barcode: '12345',
    fnsku: 'XY-ZY-10283',
    childAsin: 'ABCDASYD',
    parentAsin: 'ABCDASXD',
    paymentCalculation: 'WHOLESALE_LOGISTICS',
    logisticsCostRands: 200,
    retailPriceDollars: 19.99,
    wholesalePriceRands: 100,
  },
  isLoading: false,
  onMissingInfoClick: () => console.log('Missing info button was clicked.'),
};

AmazonInfo.propTypes = {
  amazonInfo: PropTypes.shape({
    sku: PropTypes.string,
    barcode: PropTypes.string,
    fnsku: PropTypes.string,
    childAsin: PropTypes.string,
    parentAsin: PropTypes.string,
    paymentCalculation: PropTypes.oneOf(['WHOLESALE', 'WHOLESALE_LOGISTICS']),
    logisticsCostRands: PropTypes.number,
    retailPriceDollars: PropTypes.number,
    wholesalePriceRands: PropTypes.number,
  }),
  isLoading: PropTypes.bool,
  onMissingInfoClick: PropTypes.func,
};

export default AmazonInfo;
