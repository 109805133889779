import firebase from 'firebase/app';
import firebaseConfig from './firebaseConfig';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const functions = firebase.functions();
const firestore = firebase.firestore();
const storage = firebase.storage();

// if (process.env.NODE_ENV === 'development') {
//   auth.useEmulator('http://localhost:9099');
//   functions.useEmulator('localhost', 5001);
//   firestore.useEmulator('localhost', 8080);
//   storage.useEmulator('localhost', 9199);
// }

export { firestore, auth, functions, storage };
