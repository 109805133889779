import { useEffect, useState } from 'react';
import { green, grey, red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import numeral from 'numeral';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  loadingIndicator: {
    color: theme.palette.common.white,
  },
  loadingWrapper: {
    textAlign: 'center',
    margin: theme.spacing(4),
  },
  noChangesText: {
    color: grey[700],
    textAlign: 'center',
    margin: theme.spacing(6),
  },
  priceDecrease: {
    backgroundColor: red[300],
    color: theme.palette.common.black,
    fontWeight: 'bold',
    width: '100%',
  },
  priceIncrease: {
    backgroundColor: green[300],
    color: theme.palette.common.black,
    fontWeight: 'bold',
    width: '100%',
  },
  priceSame: {
    backgroundColor: grey[700],
    color: theme.palette.common.black,
    fontWeight: 'bold',
    width: '100%',
  },
  tableCell: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    border: 'none',
  },
  tableHeader: {
    color: theme.palette.primary.light,
    textAlign: 'center',
  },
  tableWrapper: {
    minWidth: 400,
  },
}));

const PriceHistoryDialog = ({
  isOpen,
  isLoading,
  closeDialog,
  sku,
  priceChanges,
}) => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState('HISTORY');

  const filteredPriceChanges = priceChanges.filter((row) => {
    if (activeTab === 'HISTORY') {
      return moment().isAfter(moment(row.date));
    }
    return moment().isBefore(moment(row.date));
  });

  useEffect(() => {
    if (isOpen) {
      setActiveTab('HISTORY');
    }
  }, [isOpen]);

  const getPriceChangeChipStyle = (oldPrice, newPrice) => {
    if (oldPrice < newPrice) {
      return classes.priceIncrease;
    }
    if (oldPrice > newPrice) {
      return classes.priceDecrease;
    }
    return classes.priceSame;
  };

  return (
    <Dialog maxWidth open={isOpen} onClose={closeDialog}>
      <DialogTitle>{`Price Changes [${sku}]`}</DialogTitle>
      <DialogContent>
        {isLoading ? (
          <div className={classes.loadingWrapper}>
            <CircularProgress className={classes.loadingIndicator} />
          </div>
        ) : (
          <>
            <Tabs
              centered
              value={activeTab}
              onChange={(e, newValue) => setActiveTab(newValue)}
              aria-label="price changes tabs"
            >
              <Tab label="History" value="HISTORY" />
              <Tab label="Upcoming" value="UPCOMING" />
            </Tabs>
            {filteredPriceChanges.length === 0 ? (
              <Typography
                variant="body1"
                component="p"
                className={classes.noChangesText}
              >
                No price changes
              </Typography>
            ) : (
              <TableContainer>
                <Table
                  className={classes.tableWrapper}
                  aria-label="price changes table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHeader}>
                        Wholesale Price
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        Retail Price
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        Platform
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        Changed By
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        Date
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredPriceChanges.map((row) => (
                      <TableRow key={row.date}>
                        <TableCell className={classes.tableCell}>
                          <Chip
                            label={`R${numeral(row.oldWholesalePrice).format(
                              '0,0.00'
                            )} → R${numeral(row.newWholesalePrice).format(
                              '0,0.00'
                            )}`}
                            className={getPriceChangeChipStyle(
                              row.oldWholesalePrice,
                              row.newWholesalePrice
                            )}
                          />
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <Chip
                            label={`${
                              row.platform === 'AMAZON' ? '$' : 'R'
                            }${numeral(row.oldRetailPrice).format(
                              row.platform === 'AMAZON' ? '0,0.00' : '0,0'
                            )} → ${
                              row.platform === 'AMAZON' ? '$' : 'R'
                            }${numeral(row.newRetailPrice).format(
                              row.platform === 'AMAZON' ? '0,0.00' : '0,0'
                            )}`}
                            className={getPriceChangeChipStyle(
                              row.oldRetailPrice,
                              row.newRetailPrice
                            )}
                          />
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {row.platform === 'AMAZON' ? 'Amazon' : 'Takealot'}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.tableCell}
                        >
                          {row.userName}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {moment(row.date).format('D MMMM YYYY')}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

PriceHistoryDialog.defaultProps = {
  isOpen: true,
  isLoading: false,
  sku: 'KAN-BOO-0101',
  closeDialog: () => console.log('Dialog was closed.'),
  priceChanges: [],
};

PriceHistoryDialog.propTypes = {
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  sku: PropTypes.string,
  closeDialog: PropTypes.func,
  priceChanges: PropTypes.arrayOf(
    PropTypes.shape({
      oldWholesalePrice: PropTypes.number,
      oldRetailPrice: PropTypes.number,
      newWholesalePrice: PropTypes.number,
      newRetailPrice: PropTypes.number,
      platform: PropTypes.string,
      date: PropTypes.string,
      userId: PropTypes.string,
      userName: PropTypes.string,
    })
  ),
};

export default PriceHistoryDialog;
