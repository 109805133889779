/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  getPresalesSuppliers,
  updateSkuInfo,
  getPresalesSkus,
} from '../../../api/presalesAPI';

const initialState = {
  isUserRedirectedToBrowse: false,
  suppliers: [],
  presalesSkus: [],
  loadingStatus: {
    creation: 'NOT_STARTED',
    agencies: 'NOT_STARTED',
    presalesSkus: 'NOT_STARTED',
    updating: 'NOT_STARTED',
  },
  failedListingEditSkus: [],
  listingEditUpdateLoadingCount: {
    total: 0,
    success: 0,
    failed: 0,
  },
  feedback: {
    isOpen: false,
    type: undefined,
    message: '',
  },
};

const presalesListingEditsAddSlice = createSlice({
  name: 'presalesListingEditAdd',
  initialState,
  reducers: {
    fetchPresalesSuppliersStarted(state) {
      state.loadingStatus.suppliers = 'PENDING';
    },
    fetchPresalesSuppliersSuccess(state, action) {
      state.suppliers = action.payload;
      state.loadingStatus.suppliers = 'COMPLETE';
    },
    fetchPresalesSuppliersFailed(state, action) {
      state.loadingStatus.suppliers = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    listingEditSkusToUpdate(state, action) {
      state.failedListingEditSkus = [];
      state.listingEditUpdateLoadingCount.total = action.payload;
      state.listingEditUpdateLoadingCount.success = 0;
      state.listingEditUpdateLoadingCount.failed = 0;
    },

    listingEditUpdateSuccess(state) {
      state.listingEditUpdateLoadingCount.success += 1;
    },
    listingEditUpdateFailed(state, action) {
      state.listingEditUpdateLoadingCount.failed += 1;
      const sku = state.presalesSkus.find(
        (item) => item.eolSku === action.payload.eolSku
      );
      state.failedListingEditSkus.push(sku);
    },
    getSkusStarted(state) {
      state.loadingStatus.presalesSkus = 'PENDING';
    },
    getSkusSuccess(state, action) {
      state.presalesSkus = action.payload;
      state.loadingStatus.presalesSkus = 'COMPLETE';
    },
    getSkusFailed(state, action) {
      state.loadingStatus.presalesSkus = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    resetState() {
      return initialState;
    },
  },
});

export const {
  fetchPresalesSuppliersStarted,
  fetchPresalesSuppliersSuccess,
  fetchPresalesSuppliersFailed,
  listingEditSkusToUpdate,
  listingEditUpdateSuccess,
  listingEditUpdateFailed,
  getSkusStarted,
  getSkusSuccess,
  getSkusFailed,
  resetState,
} = presalesListingEditsAddSlice.actions;

export default presalesListingEditsAddSlice.reducer;

export const fetchPresalesSuppliers = () => async (dispatch) => {
  dispatch(fetchPresalesSuppliersStarted());
  const result = await getPresalesSuppliers();

  if (result.status === 200) {
    dispatch(fetchPresalesSuppliersSuccess(result.suppliers));
  } else {
    dispatch(fetchPresalesSuppliersFailed(result.error));
  }
};

export const updateSku = (skuId, skuInfo) => async (dispatch) => {
  const result = await updateSkuInfo(skuId, skuInfo);

  if (result.status === 200) {
    dispatch(listingEditUpdateSuccess());
  } else {
    dispatch(
      listingEditUpdateFailed({
        eolSku: result.eolSku,
      })
    );
  }
};

export const fetchSkus = () => async (dispatch) => {
  dispatch(getSkusStarted());
  const result = await getPresalesSkus('NOT_SPECIFIED');
  console.log('PresaleSkus: ', result.status);
  if (result.status === 200) {
    dispatch(getSkusSuccess(result.skus));
  } else {
    dispatch(getSkusFailed(result.error));
  }
};

export const setTotalSkusToUpdate = (total) => (dispatch) => {
  dispatch(listingEditSkusToUpdate(total));
};

export const initiateStateReset = () => async (dispatch) => {
  dispatch(resetState());
};
