/* eslint-disable react/jsx-props-no-spreading */
import _ from 'lodash';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { green, red, yellow } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import WarningIcon from '@material-ui/icons/Warning';

const headerStyle = {
  backgroundColor: '#171415',
};

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.common.black,
  },
  link: {
    color: theme.palette.common.black,
  },
  loadingOverlay: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: fade(theme.palette.common.black, 0.8),
  },
  statusActive: {
    color: theme.palette.common.black,
    backgroundColor: green[500],
  },
  statusInactive: {
    color: theme.palette.common.black,
    backgroundColor: red[500],
  },
  statusOnboarding: {
    color: theme.palette.common.black,
    backgroundColor: yellow[500],
  },
  errorIcon: {
    marginRight: theme.spacing(1),
    color: red[600],
  },
  tooltipWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  warningIcon: {
    marginRight: theme.spacing(1),
    color: yellow[500],
  },
  wrapperContent: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

const SkusTable = ({
  isLoading,
  skus,
  viewSku,
  viewStockHistory,
  adjustUnitQuantity,
  warehouses,
  platform,
  pageSize,
  initialPage,
  updatePageSize,
  updateInitialPage,
}) => {
  const classes = useStyles();

  const getColumns = () => {
    const columns = [
      {
        title: 'Creation Date',
        field: 'creationDate',
        hidden: true,
        export: true,
      },
      { title: 'SKU', field: 'sku', editable: false },
      {
        title: platform === 'AMAZON' ? 'ASIN' : 'TSIN',
        field: platform === 'AMAZON' ? 'asin' : 'tsin',
        editable: false,
        render: (rowData) => {
          if (rowData.status === 'NOT_BUYABLE') {
            return (
              <div className={classes.tooltipWrapper}>
                <Tooltip title="Not Buyable">
                  <WarningIcon className={classes.warningIcon} />
                </Tooltip>
                {platform === 'AMAZON' ? rowData.childAsin : rowData.tsin}
              </div>
            );
          }
          if (rowData.status === 'DISABLED_BY_SELLER') {
            return (
              <div className={classes.tooltipWrapper}>
                <Tooltip title="Disabled by Seller">
                  <WarningIcon className={classes.errorIcon} />
                </Tooltip>
                {platform === 'AMAZON' ? rowData.childAsin : rowData.tsin}
              </div>
            );
          }
          if (rowData.status === 'DISABLED_BY_TAKEALOT') {
            return (
              <div className={classes.tooltipWrapper}>
                <Tooltip title="Disabled by Takealot">
                  <WarningIcon className={classes.errorIcon} />
                </Tooltip>
                {platform === 'AMAZON' ? rowData.childAsin : rowData.tsin}
              </div>
            );
          }
          return platform === 'AMAZON' ? rowData.childAsin : rowData.tsin;
        },
      },
      {
        title: 'Client Name',
        field: 'clientName',
        hidden: true,
        export: true,
      },
      {
        title: 'Product',
        editable: false,
        field: 'product',
        render: (rowData) => {
          const productTitle =
            rowData.variation === 'N/A'
              ? `${rowData.brand} - ${rowData.productName}`
              : `${rowData.brand} - ${rowData.productName} (${rowData.variation})`;
          return (
            <Tooltip title={productTitle} aria-label="add" interactive>
              <Typography variant="body2" component="span">
                {_.truncate(productTitle)}
              </Typography>
            </Tooltip>
          );
        },
      },
    ];

    warehouses
      .filter((warehouse) => {
        if (platform === 'AMAZON') {
          return (
            warehouse.name !== 'Takealot JHB' &&
            warehouse.name !== 'Takealot CPT'
          );
        }
        return warehouse.name !== 'Amazon US';
      })
      .sort((warehouseA, warehouseB) => {
        if (warehouseA.name === 'EasyOnline') return -1;
        if (
          warehouseA.name === 'Takealot CPT' &&
          warehouseB.name !== 'EasyOnline'
        )
          return -1;
        return 0;
      })
      .forEach((warehouse) => {
        if (warehouse.name === 'EasyOnline' || warehouse.name === 'Amazon US') {
          columns.push({
            title: warehouse.name,
            field: warehouse.id,
          });
        } else {
          columns.push({
            title: warehouse.name,
            field: warehouse.id,
            editable: false,
          });
        }
      });

    columns.push({
      title: '30 Day Sales',
      field: 'thirtyDaySales',
      editable: false,
    });

    return columns;
  };

  return (
    <div className={classes.wrapperContent}>
      <MaterialTable
        title=""
        columns={getColumns()}
        data={skus}
        isLoading={isLoading}
        onChangeRowsPerPage={updatePageSize}
        onChangePage={updateInitialPage}
        options={{
          pageSize,
          initialPage,
          headerStyle,
          actionsColumnIndex: -1,
          search: false,
          grouping: false,
          draggable: false,
          exportButton: true,
          exportAllData: true,
          exportFileName: 'Storage Units',
          maxBodyHeight: '600px',
        }}
        actions={[
          {
            icon: 'history',
            tooltip: 'View stock history',
            onClick: (event, rowData) => viewStockHistory(rowData.sku),
          },
          {
            icon: 'keyboard_arrow_right',
            tooltip: 'View SKU info',
            onClick: (event, rowData) => viewSku(rowData.sku),
          },
          {
            icon: 'edit',
            tooltip: 'Adjust EasyOnline units',
            onClick: (event, rowData) => adjustUnitQuantity(rowData.sku),
          },
        ]}
        components={{
          OverlayLoading: () => (
            <div className={classes.loadingOverlay}>
              <CircularProgress color="primary" size={32} />
            </div>
          ),
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'No listings to display',
          },
        }}
      />
    </div>
  );
};

SkusTable.defaultProps = {
  isLoading: false,
  skus: [],
  viewSku: (sku) => console.log(`SKU viewed: ${sku}`),
  viewStockHistory: (sku) => console.log(`SKU viewed: ${sku}`),
  adjustUnitQuantity: (sku) =>
    console.log(`Stock quantities for ${sku} changed.`),
  warehouses: [],
  platform: 'AMAZON',
  pageSize: 5,
  initialPage: 0,
  updatePageSize: (newPageSize) =>
    console.log(`Page size updated to: ${newPageSize}`),
  updateInitialPage: (newInitialPage) =>
    console.log(`Initial page updated to: ${newInitialPage}`),
};

SkusTable.propTypes = {
  isLoading: PropTypes.bool,
  skus: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string,
      asin: PropTypes.string,
      tsin: PropTypes.string,
      brand: PropTypes.string,
      clientId: PropTypes.string,
      clientName: PropTypes.string,
      productName: PropTypes.string,
      variation: PropTypes.string,
    })
  ),
  viewSku: PropTypes.func,
  viewStockHistory: PropTypes.func,
  adjustUnitQuantity: PropTypes.func,
  warehouses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      shortCode: PropTypes.string,
    })
  ),
  platform: PropTypes.string,
  pageSize: PropTypes.number,
  initialPage: PropTypes.number,
  updatePageSize: PropTypes.func,
  updateInitialPage: PropTypes.func,
};

export default SkusTable;
