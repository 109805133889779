import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import ClearIcon from '@material-ui/icons/Clear';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  actionBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  media: {
    backgroundSize: 'contain',
    height: 180,
    margin: theme.spacing(4),
  },
  root: {
    backgroundColor: theme.palette.common.white,
  },
  imageName: {
    color: theme.palette.common.black,
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
  },
}));

const ImagePreviewCard = ({
  imageLink,
  removeImage,
  isLoading,
  localImageName,
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <Typography variant="body1" gutterBottom className={classes.imageName}>
        {localImageName}
      </Typography>
      <CardMedia className={classes.media} image={imageLink} />
      <CardActions className={classes.actionBar}>
        <Button
          onClick={removeImage}
          startIcon={<ClearIcon />}
          size="small"
          color="primary"
          disabled={isLoading}
        >
          Remove
        </Button>
      </CardActions>
    </Card>
  );
};

ImagePreviewCard.defaultProps = {
  imageLink: '',
  removeImage: () => console.log('Image was removed.'),
  isLoading: false,
  localImageName: '',
};

ImagePreviewCard.propTypes = {
  imageLink: PropTypes.string,
  removeImage: PropTypes.func,
  isLoading: PropTypes.bool,
  localImageName: PropTypes.string,
};

export default ImagePreviewCard;
