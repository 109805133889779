import { useState } from 'react';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';

const CopywritingSelector = ({
  copywritingFilter,
  changeCopywritingFilter,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const getPrettyFilter = (filter) => {
    switch (filter) {
      case 'ALL':
        return 'All';
      case 'NOT_STARTED':
        return 'Not Started';
      case 'DRAFTING':
        return 'Drafting';
      case 'REVIEWING':
        return 'Reviewing';
      case 'COMPLETED':
        return 'Completed';
      default:
        return 'Custom';
    }
  };

  return (
    <>
      <Button
        aria-controls="copywriting-menu"
        aria-haspopup="true"
        size="large"
        startIcon={<EditIcon />}
        onClick={openMenu}
      >
        {getPrettyFilter(copywritingFilter)}
      </Button>
      <Menu
        id="copywriting-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem
          onClick={() => {
            changeCopywritingFilter('ALL');
            closeMenu();
          }}
        >
          All Statuses
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeCopywritingFilter('NOT_STARTED');
            closeMenu();
          }}
        >
          Not Started
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeCopywritingFilter('DRAFTING');
            closeMenu();
          }}
        >
          Drafting
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeCopywritingFilter('REVIEWING');
            closeMenu();
          }}
        >
          Reviewing
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeCopywritingFilter('COMPLETED');
            closeMenu();
          }}
        >
          Completed
        </MenuItem>
      </Menu>
    </>
  );
};

CopywritingSelector.defaultProps = {
  copywritingFilter: 'ALL',
  changeCopywritingFilter: (newFilter) =>
    console.log('User changes the copywriting filter to:', newFilter),
};

CopywritingSelector.propTypes = {
  copywritingFilter: PropTypes.string,
  changeCopywritingFilter: PropTypes.func,
};

export default CopywritingSelector;
