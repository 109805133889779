import { fade, makeStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MaterialTable from 'material-table';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FiltersBar from '../FiltersBar';

const headerStyle = {
  backgroundColor: '#A7A5A5',
  color: '#171415',
};

const useStyles = makeStyles((theme) => ({
  loadingOverlay: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: fade(theme.palette.common.black, 0.8),
  },
  wrapperContent: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
  separator: {
    height: theme.spacing(2),
  },

  formControl: {
    width: '100%',
  },
}));

const PriceChangeInfo = ({
  skus,
  updateSkus,
  priceType,
  setPriceType,
  vatInc,
  setVatInc,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  note,
  setNote,
  searchText,
  setSearchText,
}) => {
  const classes = useStyles();

  const columns = [
    {
      title: 'SKU',
      field: 'sku',
      editable: 'never',
    },
    {
      title: 'TSIN',
      field: 'tsin',
      editable: 'never',
    },
    {
      title: 'Product',
      field: 'product',
      editable: 'never',
    },
    {
      title: 'New Price',
      field: 'changeTo',
      render: (rowData) => {
        return (
          <TextField
            value={rowData.changeTo}
            InputProps={{
              startAdornment: (
                <InputAdornment disableTypography position="start">
                  R
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => {
              updateSkus(
                // eslint-disable-next-line react/prop-types
                skus.map((skuInfo) => {
                  const changeTo = e.target.value;

                  if (skuInfo.sku === rowData.sku) {
                    return {
                      ...skuInfo,
                      changeTo,
                    };
                  }
                  return skuInfo;
                })
              );
            }}
            type="number"
          />
        );
      },
    },
  ];

  return (
    <div className={classes.wrapperContent}>
      <Typography variant="h4" gutterBottom>
        Specify requirements
      </Typography>
      <Typography variant="body1">
        Please select the price that needs to changed and enter the new amount
        amount for each SKU.
      </Typography>
      <div className={classes.separator} />
      <FormControl className={classes.formControl}>
        <InputLabel shrink id="price-change-select-label">
          Price
        </InputLabel>
        <Select
          labelId="price-change-select-label"
          id="price-change-select"
          value={priceType}
          onChange={(e) => setPriceType(e.target.value)}
        >
          <MenuItem value="WHOLESALE_PRICE">Wholesale Price</MenuItem>
          <MenuItem value="RETAIL_PRICE">Retail Price</MenuItem>
        </Select>
      </FormControl>
      <div className={classes.separator} />
      <TextField
        fullWidth
        id="startDate"
        label={`Start Date ${startDate === null ? '(ASAP)' : ''}`}
        type="date"
        value={moment(startDate).format('YYYY-MM-DD')}
        onChange={(e) => {
          setStartDate(new Date(e.target.value));
        }}
        InputLabelProps={{ shrink: true }}
      />
      <div className={classes.separator} />
      <TextField
        fullWidth
        id="endDate"
        label={`End Date ${endDate === null ? '(Indefinite)' : ''}`}
        type="date"
        value={moment(endDate).format('YYYY-MM-DD')}
        defaultValue="Indefinite"
        onChange={(e) => {
          setEndDate(new Date(e.target.value));
        }}
        InputLabelProps={{ shrink: true }}
      />
      <div className={classes.separator} />
      <FormControlLabel
        control={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <Checkbox
            checked={vatInc}
            onChange={(e) => setVatInc(e.target.checked)}
          />
        }
        label="VAT inclusive?"
      />
      <div className={classes.separator} />
      <TextField
        required
        label="General notes"
        fullWidth
        multiline
        rows={5}
        value={note}
        onChange={(e) => setNote(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <div className={classes.separator} />
      <FiltersBar
        searchText={searchText}
        changeSearchText={setSearchText}
        skuCount={skus.length}
      />
      <MaterialTable
        columns={columns}
        data={skus}
        options={{
          headerStyle,
          sorting: false,
          grouping: false,
          draggable: false,
          search: false,
          showTitle: false,
          toolbar: false,
        }}
        components={{
          OverlayLoading: () => (
            <div className={classes.loadingOverlay}>
              <CircularProgress color="primary" size={32} />
            </div>
          ),
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'SKUs not yet selected.',
          },
        }}
      />
    </div>
  );
};

PriceChangeInfo.defaultProps = {
  skus: [],
  updateSkus: (skus) => console.log('Skus were updated to:', skus),
  priceType: null,
  setPriceType: () => {},
  vatInc: false,
  setVatInc: () => {},
  startDate: null,
  endDate: null,
  setStartDate: () => {},
  setEndDate: () => {},
  note: '',
  setNote: (newNote) => console.log('Note updated to:', newNote),
  searchText: '',
  setSearchText: () => {},
};

PriceChangeInfo.propTypes = {
  skus: PropTypes.arrayOf(PropTypes.shape()),
  updateSkus: PropTypes.func,
  priceType: PropTypes.oneOf(['RETAIL_PRICE', 'WHOLESALE_PRICE']),
  setPriceType: PropTypes.func,
  vatInc: PropTypes.bool,
  setVatInc: PropTypes.func,
  startDate: PropTypes.instanceOf(moment),
  endDate: PropTypes.instanceOf(moment),
  setStartDate: PropTypes.func,
  setEndDate: PropTypes.func,
  note: PropTypes.string,
  setNote: PropTypes.func,
  searchText: PropTypes.string,
  setSearchText: PropTypes.func,
};

export default PriceChangeInfo;
