import { connectRefinementList } from 'react-instantsearch-dom';
import { green, red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import ImageIcon from '@material-ui/icons/Image';
import PropTypes from 'prop-types';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    backgroundColor: theme.palette.primary.light,
  },
  falseButton: {
    color: red[800],
    '&:hover': {
      color: theme.palette.common.black,
      backgroundColor: red[700],
    },
    '&.Mui-selected': {
      backgroundColor: red[900],
      '&:hover': {
        backgroundColor: red[900],
      },
    },
  },
  trueButton: {
    color: green[800],
    '&:hover': {
      color: theme.palette.common.black,
      backgroundColor: green[700],
    },
    '&.Mui-selected': {
      backgroundColor: green[900],
      '&:hover': {
        backgroundColor: green[900],
      },
    },
  },
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
}));

const ImagesToggle = ({ refine }) => {
  const classes = useStyles();
  const [alignment, setAlignment] = useState('');

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment || '');
    if (newAlignment === '' || !newAlignment) {
      refine([]);
    } else {
      refine(newAlignment);
    }
  };

  return (
    <div className={classes.root}>
      <Tooltip title="Images">
        <ToggleButtonGroup
          className={classes.buttonGroup}
          value={alignment}
          exclusive
          onChange={handleAlignment}
          aria-label="images status toggle"
        >
          <ToggleButton value="false" className={classes.falseButton}>
            <ImageIcon />
          </ToggleButton>
          <ToggleButton value="">
            <ImageIcon />
          </ToggleButton>
          <ToggleButton value="true" className={classes.trueButton}>
            <ImageIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Tooltip>
    </div>
  );
};

ImagesToggle.defaultProps = {
  refine: (itemValue) => console.log(`User refined the filter by ${itemValue}`),
};

ImagesToggle.propTypes = {
  refine: PropTypes.func,
};

export default connectRefinementList(ImagesToggle);
