/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { createNewSupplier } from '../../../api/presalesSuppliersAPI';

const initialState = {
  isUserRedirectedToBrowse: false,
  loadingStatus: {
    creation: 'NOT_STARTED',
  },
  feedback: {
    isOpen: false,
    type: undefined,
    message: '',
  },
};

const presalesSuppliersAddSlice = createSlice({
  name: 'presalesSuppliersAdd',
  initialState,
  reducers: {
    createSupplierStarted(state) {
      state.loadingStatus.creation = 'PENDING';
    },
    createSupplierSuccess(state) {
      state.loadingStatus.creation = 'COMPLETE';
      state.isUserRedirectedToBrowse = true;
    },
    createSupplierFailed(state, action) {
      state.loadingStatus.creation = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    resetState() {
      return initialState;
    },
  },
});

export const {
  createSupplierStarted,
  createSupplierSuccess,
  createSupplierFailed,
  resetState,
} = presalesSuppliersAddSlice.actions;

export default presalesSuppliersAddSlice.reducer;

export const createSupplier = (supplierInfo) => async (dispatch) => {
  dispatch(createSupplierStarted());
  const result = await createNewSupplier(supplierInfo);

  if (result.status === 200) {
    dispatch(createSupplierSuccess());
  } else {
    dispatch(createSupplierFailed(result.error));
  }
};

export const initiateStateReset = () => async (dispatch) => {
  dispatch(resetState());
};
