import { useState } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ForumIcon from '@material-ui/icons/Forum';
import PropTypes from 'prop-types';

const StatusSelector = ({ statusId, changeStatus, statuses }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const getStatusLabel = (id) => {
    if (id === 'ALL') {
      return 'All Statuses';
    }

    const statusInfo = statuses.find((info) => info.id === id);
    if (!statusInfo) {
      return 'Unknown';
    }

    return statusInfo.label;
  };

  return (
    <>
      <Button
        aria-controls="status-menu"
        aria-haspopup="true"
        size="large"
        startIcon={<ForumIcon />}
        onClick={openMenu}
      >
        {getStatusLabel(statusId)}
      </Button>
      <Menu
        id="status-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem
          onClick={() => {
            changeStatus('ALL');
            closeMenu();
          }}
        >
          All Statuses
        </MenuItem>
        {statuses
          .map((info) => info)
          .map((info) => (
            <MenuItem
              key={info.id}
              onClick={() => {
                changeStatus(info.id);
                closeMenu();
              }}
            >
              {info.label}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

StatusSelector.defaultProps = {
  statusId: 'ALL',
  changeStatus: (newValue) => `Status was changed to '${newValue}'`,
  statuses: [
    {
      id: 'AWAITING_PROSPECT_RESPONSE',
      label: 'Negotiating',
    },
    {
      id: 'AWAITING_INVOICE',
      label: 'Awaiting Invoice',
    },
    {
      id: 'FINALIZED',
      label: 'Finalized',
    },
    {
      id: 'LOST',
      label: 'Lost',
    },
  ],
};

StatusSelector.propTypes = {
  statusId: PropTypes.string,
  changeStatus: PropTypes.func,
  statuses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};

export default StatusSelector;
