import { useEffect, useState } from 'react';
import numeral from 'numeral';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
  changeAmazonSku,
  changeTakealotSku,
  fetchSkus,
  fetchPriceChanges,
  updateInitialPage,
  updatePageSize,
} from './slice';
import { findSku, getClients, getProductName, getFormattedSkus } from './logic';
import ContentWrapper from '../../../components/ContentWrapper';
import EditPricingDialog from './components/EditPricingDialog';
import EmptyViewMessage from '../../../components/EmptyViewMessage';
import FiltersBar from './components/FiltersBar';
import PageHeader from '../../../components/PageHeader';
import PriceHistoryDialog from './components/PriceHistoryDialog';
import SkusTable from './components/SkusTable';

const breadcrumbs = [
  {
    label: 'Listings',
    link: '/listings',
  },
  {
    label: 'Pricing',
    link: '/pricing',
  },
];

const ListingsAdjustPricing = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [platformFilter, setPlatformFilter] = useState('TAKEALOT');
  const [clientFilter, setClientFilter] = useState('ALL');
  const [statusFilter, setStatusFilter] = useState('ALL');
  const [isEditPricingDialogOpen, setIsEditPricingDialogOpen] = useState(false);
  const [isPriceHistoryDialogOpen, setIsPriceHistoryDialogOpen] =
    useState(false);
  const [priceHistorySkuSelected, setPriceHistorySkuSelected] = useState('');
  const [skuToEdit, setSkuToEdit] = useState({});

  const skus = useSelector((state) => state.listingsAdjustPricing.skus);
  const priceChanges = useSelector(
    (state) => state.listingsAdjustPricing.priceChanges
  );
  const pageSize = useSelector((state) => state.listingsAdjustPricing.pageSize);
  const initialPage = useSelector(
    (state) => state.listingsAdjustPricing.initialPage
  );
  const skusLoadingStatus = useSelector(
    (state) => state.listingsAdjustPricing.loadingStatus.skus
  );
  const updateSkuLoadingStatus = useSelector(
    (state) => state.listingsAdjustPricing.loadingStatus.updateSku
  );
  const priceChangesLoadingStatus = useSelector(
    (state) => state.listingsAdjustPricing.loadingStatus.priceChanges
  );
  const userInfo = useSelector((state) => state.account.userInfo);

  useEffect(() => {
    if (skusLoadingStatus !== 'PENDING') {
      dispatch(fetchSkus(platformFilter));
    }
  }, [platformFilter]);

  useEffect(() => {
    if (
      skusLoadingStatus === 'PENDING' ||
      updateSkuLoadingStatus === 'PENDING' ||
      priceChangesLoadingStatus === 'PENDING'
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [skusLoadingStatus, updateSkuLoadingStatus, priceChangesLoadingStatus]);

  useEffect(() => {
    if (priceHistorySkuSelected.length > 0) {
      dispatch(fetchPriceChanges(priceHistorySkuSelected, platformFilter));
    }
  }, [priceHistorySkuSelected]);

  const updateSkuPricing = (
    skuInfo,
    platform,
    isCalculatedPricingOn,
    categories,
    measurements,
    pricing,
    dateType,
    dateTime
  ) => {
    if (platform === 'AMAZON') {
      if (isCalculatedPricingOn) {
        dispatch(
          changeAmazonSku(
            skuInfo.sku,
            skuInfo,
            {
              ...skuInfo,
              measurements,
              amazon: {
                ...skuInfo.amazon,
                categories,
                costPriceRands: numeral(pricing.costPrice).value(),
                wholesalePriceRands: numeral(pricing.wholesalePrice).value(),
                retailPriceDollars: numeral(pricing.retailPrice).value(),
              },
            },
            userInfo
          )
        );
      } else {
        dispatch(
          changeAmazonSku(
            skuInfo.sku,
            skuInfo,
            {
              ...skuInfo,
              amazon: {
                ...skuInfo.amazon,
                costPriceRands: numeral(pricing.costPrice).value(),
                wholesalePriceRands: numeral(pricing.wholesalePrice).value(),
                retailPriceDollars: numeral(pricing.retailPrice).value(),
              },
            },
            userInfo
          )
        );
      }
    } else if (isCalculatedPricingOn) {
      dispatch(
        changeTakealotSku(
          skuInfo.sku,
          skuInfo,
          {
            ...skuInfo,
            measurements,
            takealot: {
              ...skuInfo.takealot,
              categories,
              costPriceRands: numeral(pricing.costPrice).value(),
              wholesalePriceRands: numeral(pricing.wholesalePrice).value(),
              retailPriceRands: numeral(pricing.retailPrice).value(),
            },
          },
          dateType,
          dateTime,
          userInfo
        )
      );
    } else {
      dispatch(
        changeTakealotSku(
          skuInfo.sku,
          skuInfo,
          {
            ...skuInfo,
            takealot: {
              ...skuInfo.takealot,
              costPriceRands: numeral(pricing.costPrice).value(),
              wholesalePriceRands: numeral(pricing.wholesalePrice).value(),
              retailPriceRands: numeral(pricing.retailPrice).value(),
            },
          },
          dateType,
          dateTime,
          userInfo
        )
      );
    }
  };

  return (
    <div>
      <PageHeader
        title="Adjust Pricing"
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Adjust"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
        actions={[
          {
            label: 'Add new SKU',
            icon: <AddIcon />,
            onClick: () => history.push('/listings/basic-info/add'),
          },
        ]}
      />
      {isLoading && <LinearProgress color="secondary" />}
      <ContentWrapper>
        <FiltersBar
          searchText={searchQuery}
          changeSearchText={setSearchQuery}
          listingCount={
            getFormattedSkus(
              skus,
              searchQuery,
              clientFilter,
              platformFilter,
              statusFilter
            ).length
          }
          platformFilter={platformFilter}
          changePlatformFilter={(newPlatform) => {
            setPlatformFilter(newPlatform);
            setClientFilter('ALL');
          }}
          clientFilter={clientFilter}
          changeClientFilter={setClientFilter}
          clientOptions={getClients(skus)}
          statusFilter={statusFilter}
          changeStatusFilter={setStatusFilter}
        />
        {!isLoading && skus.length === 0 ? (
          <EmptyViewMessage
            heading="No SKUs Available"
            message="Please adjust the filters or add new skus to view them here."
          />
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SkusTable
                isLoading={isLoading}
                skus={getFormattedSkus(
                  skus,
                  searchQuery,
                  clientFilter,
                  platformFilter,
                  statusFilter
                )}
                platform={platformFilter}
                changePrices={(sku) => {
                  setIsEditPricingDialogOpen(true);
                  setSkuToEdit(findSku(sku, skus));
                }}
                viewPriceHistory={(sku) => {
                  setIsPriceHistoryDialogOpen(true);
                  setPriceHistorySkuSelected(sku);
                }}
                viewSku={(sku) =>
                  history.push(`/listings/basic-info/browse/${sku}`)
                }
                pageSize={pageSize}
                initialPage={initialPage}
                updatePageSize={(newPageSize) =>
                  dispatch(updatePageSize(newPageSize))
                }
                updateInitialPage={(newInitialPage) =>
                  dispatch(updateInitialPage(newInitialPage))
                }
              />
            </Grid>
          </Grid>
        )}
      </ContentWrapper>
      <EditPricingDialog
        platform={platformFilter}
        skuInfo={skuToEdit}
        isOpen={isEditPricingDialogOpen}
        updatePricingInfo={(
          isCalculatedPricingOn,
          categories,
          measurements,
          pricing,
          dateType,
          dateTime
        ) =>
          updateSkuPricing(
            skuToEdit,
            platformFilter,
            isCalculatedPricingOn,
            categories,
            measurements,
            pricing,
            dateType,
            dateTime
          )
        }
        closeDialog={() => {
          setIsEditPricingDialogOpen(false);
          setSkuToEdit({});
        }}
      />
      <PriceHistoryDialog
        isOpen={isPriceHistoryDialogOpen}
        isLoading={priceChangesLoadingStatus === 'PENDING'}
        sku={priceHistorySkuSelected}
        productName={getProductName(priceHistorySkuSelected, skus)}
        priceChanges={priceChanges}
        closeDialog={() => {
          setIsPriceHistoryDialogOpen(false);
          setPriceHistorySkuSelected('');
        }}
      />
    </div>
  );
};

export default ListingsAdjustPricing;
