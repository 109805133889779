import { green, grey, red, yellow } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import numeral from 'numeral';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    width: '100%',
    textAlign: 'right',
  },
  neutralValue: {
    color: grey[600],
    fontSize: '0.8rem',
    textAlign: 'center',
  },
  badMargin: {
    color: red[900],
    fontSize: '0.8rem',
    textAlign: 'center',
  },
  goodMargin: {
    color: green[900],
    fontSize: '0.8rem',
    textAlign: 'center',
  },
  chipGreen: {
    marginLeft: theme.spacing(1),
    color: green[800],
    borderColor: green[800],
  },
  chipRed: {
    marginLeft: theme.spacing(1),
    color: red[800],
    borderColor: red[800],
  },
  chipGrey: {
    marginLeft: theme.spacing(1),
    color: grey[800],
    borderColor: grey[800],
  },
  chipYellow: {
    marginLeft: theme.spacing(1),
    color: yellow[800],
    borderColor: yellow[800],
  },
  divider: {
    margin: '24px 0',
  },
  infoIconComplete: {
    color: green[800],
  },
  infoIconIncomplete: {
    color: red[800],
  },
  link: {
    color: theme.palette.common.white,
  },
  media: {
    minHeight: 140,
    height: '100%',
    backgroundColor: grey[800],
  },
  text: {
    color: theme.palette.secondary.light,
  },
  title: {
    color: theme.palette.primary.light,
  },
  separator: {
    height: theme.spacing(2),
  },
  subheading: {
    color: theme.palette.secondary.light,
    textAlign: 'center',
  },
  valueText: {
    color: theme.palette.primary.light,
    textAlign: 'center',
    fontSize: '1.6rem',
  },
}));

const Hit = ({
  title,
  eolSku,
  tsin,
  bundledUnits,
  buyboxOwner,
  status,
  supplier,
  primaryImageUrl,
  takealotListingLink,
  listingPrice,
  costPricePerUnit,
  costPrice,
  fulfilmentFee,
  successFee,
  handlingFee,
  retailPrice,
  manuallyDisabled,
  updateDisabledStatus,
}) => {
  const classes = useStyles();

  const calculateMargin = (tradingPrice) => {
    if (
      tradingPrice === 0 ||
      tradingPrice === null ||
      fulfilmentFee === null ||
      successFee === null ||
      handlingFee === null
    ) {
      return null;
    }

    return Math.round(
      ((tradingPrice -
        costPrice -
        fulfilmentFee * 1.15 -
        (successFee / 100) * 1.15 * tradingPrice -
        handlingFee) /
        tradingPrice) *
        100
    );
  };

  const currentPriceMargin = calculateMargin(retailPrice);
  const listingPriceMargin = calculateMargin(listingPrice);

  const getBuyboxChip = () => {
    switch (buyboxOwner) {
      case null:
        return (
          <Chip
            className={classes.chipGrey}
            variant="outlined"
            size="small"
            label="Not Yet Scraped"
          />
        );
      case 'No Stock':
      case 'Listing Unavailable':
        return (
          <Chip
            className={classes.chipRed}
            variant="outlined"
            size="small"
            label={buyboxOwner}
          />
        );
      case 'Takealot':
        return (
          <Chip
            variant="outlined"
            size="small"
            label={buyboxOwner}
            className={classes.chipRed}
          />
        );
      case 'Online Retail Management':
      case 'EasyOnline':
        return (
          <Chip
            variant="outlined"
            size="small"
            label={buyboxOwner}
            className={classes.chipGreen}
          />
        );
      default:
        return (
          <Chip
            variant="outlined"
            size="small"
            label={buyboxOwner}
            className={classes.chipYellow}
          />
        );
    }
  };
  const getStatusChip = () => {
    switch (status) {
      case 'MISSING_INFO':
      case 'SUBMITTED':
        return (
          <Chip
            variant="outlined"
            size="small"
            label="Onboarding"
            className={classes.chipRed}
          />
        );
      case 'BUYABLE':
        return (
          <Chip
            variant="outlined"
            size="small"
            label="Buyable"
            className={classes.chipGreen}
          />
        );
      case 'NOT_BUYABLE':
        return (
          <Chip
            variant="outlined"
            size="small"
            label="Not Buyable"
            className={classes.chipYellow}
          />
        );
      case 'DISABLED_BY_SELLER':
        return (
          <Chip
            variant="outlined"
            size="small"
            label="Disabled by Seller"
            className={classes.chipRed}
          />
        );
      case 'DISABLED_BY_TAKEALOT':
        return (
          <Chip
            variant="outlined"
            size="small"
            label="Disabled by Takealot"
            className={classes.chipRed}
          />
        );
      default:
        return <Chip variant="outlined" size="small" label="Basic" />;
    }
  };
  return (
    <Card className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <CardMedia
            className={classes.media}
            image={primaryImageUrl || 'No image'}
            title={title}
          />
        </Grid>
        <Grid item xs={9}>
          <CardContent>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography
                  className={classes.text}
                  variant="body2"
                  component="p"
                >
                  {`${eolSku} | ${tsin}`}
                </Typography>
              </Grid>
              <Grid item>
                <Tooltip
                  title={
                    manuallyDisabled ? 'Listing Disabled' : 'Listing Enabled'
                  }
                  placement="left"
                >
                  <Switch
                    checked={!manuallyDisabled}
                    onChange={(event) => {
                      updateDisabledStatus(!event.target.checked);
                    }}
                  />
                </Tooltip>
              </Grid>
            </Grid>
            <Typography variant="h5" component="h2" className={classes.title}>
              {title}
            </Typography>
            <div className={classes.separator} />
            <Grid
              container
              spacing={1}
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                className={classes.text}
                variant="body2"
                component="p"
              >
                {supplier}
              </Typography>
              <div>
                {getBuyboxChip()}
                {getStatusChip()}
              </div>
            </Grid>
            <Divider className={classes.divider} />
            <Grid
              container
              spacing={1}
              alignItems="center"
              justifyContent="space-around"
            >
              <Grid item xs={4}>
                <Grid
                  container
                  spacing={1}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    className={classes.subheading}
                    variant="body2"
                    component="p"
                  >
                    Cost Price
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    className={classes.valueText}
                  >
                    {`R ${numeral(costPrice).format('0,0.00')}`}
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    className={classes.neutralValue}
                  >
                    {`R ${numeral(costPricePerUnit).format('0,0.00')} Per Unit`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid
                  container
                  spacing={1}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    className={classes.subheading}
                    variant="body2"
                    component="p"
                  >
                    Our Price
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    className={classes.valueText}
                  >
                    {retailPrice === 0
                      ? '-'
                      : `R ${numeral(retailPrice).format('0,0')}`}
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    className={
                      currentPriceMargin >= 5
                        ? classes.goodMargin
                        : classes.badMargin
                    }
                  >
                    {currentPriceMargin === null
                      ? 'No Margin'
                      : `${currentPriceMargin}% Margin`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid
                  container
                  spacing={1}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    className={classes.subheading}
                    variant="body2"
                    component="p"
                  >
                    Listing Price
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    className={classes.valueText}
                  >
                    {listingPrice
                      ? `R ${numeral(listingPrice).format('0,0')}`
                      : '-'}
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    className={
                      listingPriceMargin >= 5
                        ? classes.goodMargin
                        : classes.badMargin
                    }
                  >
                    {listingPriceMargin === null
                      ? 'No margin'
                      : `${listingPriceMargin}% Margin`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <div className={classes.separator} />
            <Grid
              container
              spacing={1}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item>
                <Typography
                  className={classes.text}
                  variant="body2"
                  component="p"
                >
                  {`${bundledUnits} Bundled ${
                    bundledUnits === 1 ? 'Unit' : 'Units'
                  }`}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  size="small"
                  variant="outlined"
                  component={Link}
                  href={takealotListingLink}
                  disabled={
                    takealotListingLink === null || takealotListingLink === ''
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  {takealotListingLink === null || takealotListingLink === ''
                    ? 'No listing'
                    : 'View listing'}
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
};
Hit.defaultProps = {
  title: 'Example SKU',
  eolSku: 'EOL-EXAMPLE-0001',
  tsin: 'No TSIN',
  bundledUnits: 2,
  buyboxOwner: null,
  supplier: 'Example SKU',
  primaryImageUrl: '',
  takealotListingLink: '',
  status: 'MISSING_INFO',
  listingPrice: null,
  costPricePerUnit: 5,
  costPrice: 10,
  fulfilmentFee: null,
  successFee: null,
  handlingFee: null,
  retailPrice: 100,
  manuallyDisabled: false,
  updateDisabledStatus: (newStatus) =>
    console.log(`Manually disabled status was changed to ${newStatus}`),
};

Hit.propTypes = {
  title: PropTypes.string,
  supplier: PropTypes.string,
  eolSku: PropTypes.string,
  tsin: PropTypes.number,
  bundledUnits: PropTypes.number,
  primaryImageUrl: PropTypes.string,
  buyboxOwner: PropTypes.string,
  status: PropTypes.string,
  takealotListingLink: PropTypes.string,
  listingPrice: PropTypes.number,
  costPricePerUnit: PropTypes.number,
  costPrice: PropTypes.number,
  fulfilmentFee: PropTypes.number,
  successFee: PropTypes.number,
  handlingFee: PropTypes.number,
  retailPrice: PropTypes.number,
  manuallyDisabled: PropTypes.bool,
  updateDisabledStatus: PropTypes.func,
};

export default Hit;
