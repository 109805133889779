import { useState } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import TimelapseIcon from '@material-ui/icons/Timelapse';

const WeeksOutstandingSelector = ({
  weeksOutstandingFilter,
  changeWeeksOutstandingFilter,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const getPrettyFilter = (filter) => {
    switch (filter) {
      case 'ALL':
        return 'All Outstanding';
      case '2_WEEKS':
        return '2 Weeks';
      case '3_WEEKS':
        return '3 Weeks';
      case '4_WEEKS':
        return '4+ Weeks';
      default:
        return 'Custom';
    }
  };

  return (
    <>
      <Button
        aria-controls="weeks-outstanding-menu"
        aria-haspopup="true"
        size="large"
        startIcon={<TimelapseIcon />}
        onClick={openMenu}
      >
        {getPrettyFilter(weeksOutstandingFilter)}
      </Button>
      <Menu
        id="weeks-outstanding-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem
          onClick={() => {
            changeWeeksOutstandingFilter('ALL');
            closeMenu();
          }}
        >
          All Outstanding
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeWeeksOutstandingFilter('2_WEEKS');
            closeMenu();
          }}
        >
          2 Weeks
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeWeeksOutstandingFilter('3_WEEKS');
            closeMenu();
          }}
        >
          3 Weeks
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeWeeksOutstandingFilter('4_WEEKS');
            closeMenu();
          }}
        >
          4+ Weeks
        </MenuItem>
      </Menu>
    </>
  );
};

WeeksOutstandingSelector.defaultProps = {
  weeksOutstandingFilter: 'ALL',
  changeWeeksOutstandingFilter: (newFilter) =>
    console.log('User changes the weeks outstanding filter to:', newFilter),
};

WeeksOutstandingSelector.propTypes = {
  weeksOutstandingFilter: PropTypes.string,
  changeWeeksOutstandingFilter: PropTypes.func,
};

export default WeeksOutstandingSelector;
