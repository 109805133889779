/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { createSourcedProduct } from '../../../api/salesAPI';
import { getSuppliers } from '../../../api/suppliersAPI';

const initialState = {
  isUserRedirectedToBrowse: false,
  suppliers: [],
  productCreationCount: {
    total: 0,
    success: 0,
    failed: 0,
  },
  loadingStatus: {
    suppliers: 'NOT_STARTED',
  },
  feedback: {
    isOpen: false,
    type: undefined,
    message: '',
  },
};

const sourcedProductsAddSlice = createSlice({
  name: 'sourcedProductsAdd',
  initialState,
  reducers: {
    setTotalProductsToCreate(state, action) {
      state.productCreationCount.total = action.payload;
    },
    createProductSuccess(state) {
      state.productCreationCount.success += 1;
    },
    createProductFailed(state) {
      state.productCreationCount.failed += 1;
    },
    getSuppliersStarted(state) {
      state.loadingStatus.suppliers = 'PENDING';
    },
    getSuppliersSuccess(state, action) {
      state.suppliers = action.payload;
      state.loadingStatus.suppliers = 'COMPLETE';
    },
    getSuppliersFailed(state, action) {
      state.loadingStatus.suppliers = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    resetState() {
      return initialState;
    },
  },
});

export const {
  setTotalProductsToCreate,
  createProductSuccess,
  createProductFailed,
  getSuppliersStarted,
  getSuppliersSuccess,
  getSuppliersFailed,
  resetState,
} = sourcedProductsAddSlice.actions;

export default sourcedProductsAddSlice.reducer;

export const createProduct = (productInfo) => async (dispatch) => {
  const result = await createSourcedProduct(productInfo);

  if (result.status === 200) {
    dispatch(createProductSuccess());
  } else {
    dispatch(createProductFailed(result.error));
  }
};

export const fetchSuppliers = () => async (dispatch) => {
  dispatch(getSuppliersStarted());
  const result = await getSuppliers();

  if (result.status === 200) {
    dispatch(getSuppliersSuccess(result.suppliers));
  } else {
    dispatch(getSuppliersFailed(result.error));
  }
};

export const initiateStateReset = () => async (dispatch) => {
  dispatch(resetState());
};
