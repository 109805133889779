import { green, grey, yellow, red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { Doughnut } from 'react-chartjs-2';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import PropTypes from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.secondary.light,
  },
  loadingRect: {
    height: 300,
    backgroundColor: grey[900],
  },
}));

const options = {
  maintainAspectRatio: false,
};

const ConversionRateDoughnutGraph = ({ skuCounts, isLoading }) => {
  const classes = useStyles();

  const data = {
    labels: ['< 5 %', '5 - 10 %', '> 10 %'],
    datasets: [
      {
        label: 'SKU Count',
        data: skuCounts,
        backgroundColor: [red[600], yellow[700], green[700]],
        borderWidth: 0,
      },
    ],
  };

  return (
    <Card>
      <CardHeader
        avatar={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <Avatar className={classes.avatar}>
            <TrackChangesIcon />
          </Avatar>
        }
        title="Conversion Rate Distribution"
      />
      <CardContent>
        {isLoading ? (
          <Skeleton variant="rect" className={classes.loadingRect} />
        ) : (
          <div>
            <Doughnut height={300} data={data} options={options} />
          </div>
        )}
      </CardContent>
    </Card>
  );
};

ConversionRateDoughnutGraph.defaultProps = {
  isLoading: false,
  skuCounts: [9, 12, 3],
};

ConversionRateDoughnutGraph.propTypes = {
  isLoading: PropTypes.bool,
  skuCounts: PropTypes.arrayOf(PropTypes.number),
};

export default ConversionRateDoughnutGraph;
