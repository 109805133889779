/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  checkIfBarcodeTaken,
  checkIfSkuTaken,
  getSku,
  updateSku,
} from '../../../api/listingsAPI';
import { getWarehouses } from '../../../api/logisticsAPI';
import { getSuppliers } from '../../../api/suppliersAPI';

const initialState = {
  clients: [],
  warehousesList: [],
  suppliersList: [],
  isUserRedirectedToBrowse: false,
  skuExists: false,
  barcodeExists: false,
  skuInfo: {
    sku: '',
    amazon: {
      status: 'INACTIVE',
    },
    takealot: {
      status: 'INACTIVE',
    },
  },
  loadingStatus: {
    update: 'NOT_STARTED',
    warehouses: 'NOT_STARTED',
    skuInfo: 'NOT_STARTED',
    barcodeCheck: 'NOT_STARTED',
    skuCheck: 'NOT_STARTED',
    suppliers: 'NOT_STARTED',
  },
  feedback: {
    isOpen: false,
    type: undefined,
    message: '',
  },
};

const listingsEditSlice = createSlice({
  name: 'listingsEdit',
  initialState,
  reducers: {
    updateSkuStarted(state) {
      state.loadingStatus.update = 'PENDING';
    },
    updateSkuSuccess(state) {
      state.isUserRedirectedToBrowse = true;
      state.loadingStatus.update = 'COMPLETE';
    },
    updateSkuFailed(state, action) {
      state.loadingStatus.update = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    checkBarcodeStarted(state) {
      state.loadingStatus.barcodeCheck = 'PENDING';
    },
    checkBarcodeSuccess(state, action) {
      state.loadingStatus.barcodeCheck = 'COMPLETE';
      state.barcodeExists = action.payload;
    },
    checkBarcodeFailed(state, action) {
      state.loadingStatus.barcodeCheck = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    checkSkuStarted(state) {
      state.loadingStatus.skuCheck = 'PENDING';
    },
    checkSkuSuccess(state, action) {
      state.loadingStatus.skuCheck = 'COMPLETE';
      state.skuExists = action.payload;
    },
    checkSkuFailed(state, action) {
      state.loadingStatus.skuCheck = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getSkuStarted(state) {
      state.loadingStatus.skuInfo = 'PENDING';
    },
    getSkuSuccess(state, action) {
      state.loadingStatus.skuInfo = 'COMPLETE';
      state.skuInfo = action.payload;
    },
    getSkuFailed(state, action) {
      state.loadingStatus.skuInfo = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getWarehousesStarted(state) {
      state.loadingStatus.warehouses = 'PENDING';
    },
    getWarehousesSuccess(state, action) {
      state.warehousesList = action.payload;
      state.loadingStatus.warehouses = 'COMPLETE';
    },
    getWarehousesFailed(state, action) {
      state.loadingStatus.warehouses = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getSuppliersStarted(state) {
      state.loadingStatus.suppliers = 'PENDING';
    },
    getSuppliersSuccess(state, action) {
      state.suppliersList = action.payload;
      state.loadingStatus.suppliers = 'COMPLETE';
    },
    getSuppliersFailed(state, action) {
      state.loadingStatus.suppliers = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    resetState() {
      return initialState;
    },
  },
});

export const {
  updateSkuStarted,
  updateSkuSuccess,
  updateSkuFailed,
  checkBarcodeStarted,
  checkBarcodeSuccess,
  checkBarcodeFailed,
  checkSkuStarted,
  checkSkuSuccess,
  checkSkuFailed,
  getSkuStarted,
  getSkuSuccess,
  getSkuFailed,
  getWarehousesStarted,
  getWarehousesSuccess,
  getWarehousesFailed,
  getSuppliersFailed,
  getSuppliersSuccess,
  getSuppliersStarted,
  resetState,
} = listingsEditSlice.actions;

export default listingsEditSlice.reducer;

export const changeSku = (sku, newInfo) => async (dispatch) => {
  dispatch(updateSkuStarted());
  const result = await updateSku(sku, {
    ...newInfo,
    takealot: {
      ...newInfo.takealot,
      offerUrl: newInfo.takealot.offerUrl ? newInfo.takealot.offerUrl : null,
      thirtyDaySales: newInfo.takealot.thirtyDaySales
        ? newInfo.takealot.thirtyDaySales
        : null,
    },
  });

  if (result.status === 200) {
    dispatch(updateSkuSuccess({ sku, newInfo }));
  } else {
    dispatch(updateSkuFailed(result.error));
  }
};

export const fetchSkuInfo = (sku) => async (dispatch) => {
  dispatch(getSkuStarted());
  const result = await getSku(sku);

  if (result.status === 200) {
    dispatch(getSkuSuccess(result.skuInfo));
  } else {
    dispatch(getSkuFailed(result.error));
  }
};

export const checkIfBarcodeExists = (barcode) => async (dispatch) => {
  dispatch(checkBarcodeStarted());
  const result1 = await checkIfBarcodeTaken(barcode, 'AMAZON');
  const result2 = await checkIfBarcodeTaken(barcode, 'TAKEALOT');

  if (result1.status === 200 && result2.status === 200) {
    dispatch(checkBarcodeSuccess(result1.taken || result2.taken));
  } else if (result1.status !== 200) {
    dispatch(checkBarcodeFailed(result1.error));
  } else {
    dispatch(checkBarcodeFailed(result2.error));
  }
};

export const checkIfSkuExists = (sku) => async (dispatch) => {
  dispatch(checkSkuStarted());
  const result1 = await checkIfSkuTaken(sku, 'GENERAL');
  const result2 = await checkIfSkuTaken(sku, 'AMAZON');
  const result3 = await checkIfSkuTaken(sku, 'TAKEALOT');

  if (
    result1.status === 200 &&
    result2.status === 200 &&
    result3.status === 200
  ) {
    dispatch(checkSkuSuccess(result1.taken || result2.taken || result3.taken));
  } else if (result1.status !== 200) {
    dispatch(checkSkuFailed(result1.error));
  } else if (result2.status !== 200) {
    dispatch(checkSkuFailed(result2.error));
  } else {
    dispatch(checkSkuFailed(result3.error));
  }
};

export const fetchWarehouses = () => async (dispatch) => {
  dispatch(getWarehousesStarted());
  const result = await getWarehouses();

  if (result.status === 200) {
    dispatch(getWarehousesSuccess(result.warehouses));
  } else {
    dispatch(getWarehousesFailed(result.error));
  }
};

export const fetchSuppliers = () => async (dispatch) => {
  dispatch(getSuppliersStarted());
  const result = await getSuppliers();

  if (result.status === 200) {
    dispatch(getSuppliersSuccess(result.suppliers));
  } else {
    dispatch(getSuppliersFailed(result.error));
  }
};

export const initiateStateReset = () => async (dispatch) => {
  dispatch(resetState());
};
