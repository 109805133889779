/* eslint-disable react/jsx-props-no-spreading */
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import getStyles from './styles';

const headerStyle = {
  backgroundColor: '#171415',
};

const useStyles = makeStyles(getStyles);

const SkusTable = ({
  isLoading,
  skus,
  viewSkuInfo,
  pageSize,
  initialPage,
  updatePageSize,
  updateInitialPage,
}) => {
  const classes = useStyles();

  const getChangeText = (changeDetail) => {
    switch (changeDetail) {
      case 'TITLE':
        return 'Title';
      case 'SUBTITLE':
        return 'Subtitle';
      case 'DESCRIPTION':
        return 'Description';
      case 'IMAGES':
        return 'Images';
      case 'WHOLESALE_PRICE':
        return 'Wholesale Price';
      case 'RETAIL_PRICE':
        return 'Retail Price';
      case 'OTHER':
        return 'Other';
      case 'SIZE':
        return 'Size';
      case 'COLOR':
        return 'Color';
      default:
        return 'Unknown';
    }
  };

  const getChangeToText = (changeDetail, value) => {
    switch (changeDetail) {
      case 'TITLE':
        return _.truncate(value);
      case 'SUBTITLE':
        return _.truncate(value);
      case 'DESCRIPTION':
        return _.truncate(value);
      case 'IMAGES':
        return _.truncate(value);
      case 'WHOLESALE_PRICE':
        return `R ${numeral(value).format('0,0.00')}`;
      case 'RETAIL_PRICE':
        return `R ${numeral(value).format('0,0.00')}`;
      case 'OTHER':
        return _.truncate(value);
      case 'SIZE':
        return _.truncate(value);
      case 'COLOR':
        return _.truncate(value);
      default:
        return 'Unknown';
    }
  };

  const getColumns = () => {
    const columns = [
      {
        title: 'SKU',
        field: 'sku',
      },
      {
        title: 'TSIN',
        field: 'tsin',
      },
      {
        title: 'Product',
        field: 'product',
      },
      {
        title: 'Change Detail',
        field: 'changeDetail',
        render: (rowData) => getChangeText(rowData.changeDetail),
      },
      {
        title: 'Change To',
        field: 'changeTo',
        render: (rowData) =>
          getChangeToText(rowData.changeDetail, rowData.changeTo),
      },
    ];

    return columns;
  };

  return (
    <div className={classes.wrapperContent}>
      <MaterialTable
        title=""
        columns={getColumns()}
        data={skus}
        isLoading={isLoading}
        onChangeRowsPerPage={updatePageSize}
        onChangePage={updateInitialPage}
        options={{
          pageSize,
          initialPage,
          headerStyle,
          actionsColumnIndex: -1,
          search: false,
          grouping: false,
          draggable: false,
          exportButton: true,
          exportAllData: true,
          exportFileName: 'Query SKUs',
        }}
        actions={[
          {
            icon: 'keyboard_arrow_right',
            tooltip: 'View SKU info',
            onClick: (event, rowData) => viewSkuInfo(rowData.sku),
          },
        ]}
        components={{
          OverlayLoading: () => (
            <div className={classes.loadingOverlay}>
              <CircularProgress color="primary" size={32} />
            </div>
          ),
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'No skus to display',
          },
        }}
      />
    </div>
  );
};

SkusTable.defaultProps = {
  isLoading: false,
  skus: [],
  viewSkuInfo: (sku) => console.log(`SKU viewed: ${sku}`),
  pageSize: 5,
  initialPage: 0,
  updatePageSize: (newPageSize) =>
    console.log(`Page size updated to: ${newPageSize}`),
  updateInitialPage: (newInitialPage) =>
    console.log(`Initial page updated to: ${newInitialPage}`),
};

SkusTable.propTypes = {
  isLoading: PropTypes.bool,
  skus: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string,
      product: PropTypes.string,
      changeDetail: PropTypes.oneOf([
        'TITLE',
        'SUBTITLE',
        'DESCRIPTION',
        'IMAGES',
        'WHOLESALE_PRICE',
        'RETAIL_PRICE',
        'OTHER',
        'SIZE',
        'COLOR',
      ]),
      changeTo: PropTypes.string,
    })
  ),
  viewSkuInfo: PropTypes.func,
  pageSize: PropTypes.number,
  initialPage: PropTypes.number,
  updatePageSize: PropTypes.func,
  updateInitialPage: PropTypes.func,
};

export default SkusTable;
