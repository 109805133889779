import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { fetchClients, updateInitialPage, updatePageSize } from './slice';
import getFormattedClients from './logic';
import ClientsTable from './components/ClientsTable';
import FiltersBar from './components/FiltersBar';
import ContentWrapper from '../../../components/ContentWrapper';
import EmptyViewMessage from '../../../components/EmptyViewMessage';
import PageHeader from '../../../components/PageHeader';

const breadcrumbs = [
  {
    label: 'Customer Relationship Management',
    link: '/crm',
  },
  {
    label: 'Clients',
    link: '/crm/clients',
  },
];

const ClientsBrowse = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState('ALL');
  const [typeFilter, setTypeFilter] = useState('ALL');

  const clients = useSelector((state) => state.clientsBrowse.clientsList);
  const pageSize = useSelector((state) => state.clientsBrowse.pageSize);
  const initialPage = useSelector((state) => state.clientsBrowse.initialPage);
  const clientsLoadingStatus = useSelector(
    (state) => state.clientsBrowse.loadingStatus.clients
  );

  useEffect(() => {
    if (clientsLoadingStatus === 'NOT_STARTED') {
      dispatch(fetchClients());
    }
  }, []);

  useEffect(() => {
    if (clientsLoadingStatus === 'PENDING') {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [clientsLoadingStatus]);

  return (
    <div>
      <PageHeader
        title="Browse Clients"
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Browse"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
        actions={[
          {
            label: 'Add new client',
            icon: <AddIcon />,
            onClick: () => history.push('/crm/clients/add'),
          },
        ]}
      />
      {isLoading && <LinearProgress color="secondary" />}
      <ContentWrapper>
        {!isLoading && clients.length === 0 ? (
          <EmptyViewMessage
            heading="No Clients Available"
            message="Please adjust the filters or add new client to view them here."
          />
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FiltersBar
                searchText={searchQuery}
                changeSearchText={setSearchQuery}
                typeId={typeFilter}
                statusId={statusFilter}
                changeType={setTypeFilter}
                changeStatus={setStatusFilter}
                clientCount={
                  getFormattedClients(clients, searchQuery, statusFilter).length
                }
              />
              <ClientsTable
                isLoading={clientsLoadingStatus === 'PENDING'}
                clients={getFormattedClients(
                  clients,
                  searchQuery,
                  statusFilter,
                  typeFilter
                )}
                viewClient={(clientId) =>
                  history.push(`/crm/clients/browse/${clientId}`)
                }
                editClient={(clientId) =>
                  history.push(`/crm/clients/edit/${clientId}`)
                }
                pageSize={pageSize}
                initialPage={initialPage}
                updatePageSize={(newPageSize) =>
                  dispatch(updatePageSize(newPageSize))
                }
                updateInitialPage={(newInitialPage) =>
                  dispatch(updateInitialPage(newInitialPage))
                }
              />
            </Grid>
          </Grid>
        )}
      </ContentWrapper>
    </div>
  );
};

export default ClientsBrowse;
