/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { getSku, getSkuByTsin, getSkuByChildAsin } from '../../api/listingsAPI';
import {
  createReport,
  getReportSummary,
  getRecentTakealotOrders,
  updateOutdatedTakealotSkus,
} from '../../api/maintenanceAPI';

const initialState = {
  failedSkus: [],
  skuLoadingCount: {
    total: 0,
    success: 0,
    failed: 0,
  },
  reportSummary: {},
  loadingStatus: {
    reportUpload: 'NOT_STARTED',
    reportSummary: 'NOT_STARTED',
    updateRecentOrders: 'NOT_STARTED',
    updateTakealotSkus: 'NOT_STARTED',
  },
  feedback: {
    isOpen: false,
    type: undefined,
    message: '',
  },
};

const maintenanceReportsSlice = createSlice({
  name: 'maintenanceReports',
  initialState,
  reducers: {
    getSkuSuccess(state) {
      state.skuLoadingCount.success += 1;
    },
    getSkuFailed(state, action) {
      state.skuLoadingCount.failed += 1;
      state.failedSkus.push(action.payload);
    },
    updateTotalSkusToLoad(state, action) {
      state.failedSkus = [];
      state.skuLoadingCount.total = action.payload;
      state.skuLoadingCount.success = 0;
      state.skuLoadingCount.failed = 0;
    },
    uploadReportStarted(state) {
      state.loadingStatus.reportUpload = 'PENDING';
    },
    uploadReportSuccess(state, action) {
      state.loadingStatus.reportUpload = 'COMPLETE';
      if (action.payload.reportCode === 'takealot-product-sales') {
        const firstDay = moment(action.payload.dateString, 'DDMMYYYY').format(
          'DD'
        );
        const secondDay = moment(action.payload.dateString, 'DDMMYYYY')
          .add(1, 'days')
          .format('DD');
        const firstMonth = moment(action.payload.dateString, 'DDMMYYYY').format(
          'MM'
        );
        const secondMonth = moment(action.payload.dateString, 'DDMMYYYY')
          .add(1, 'days')
          .format('MM');

        if (firstMonth === secondMonth) {
          state.reportSummary = {
            ...state.reportSummary,
            [`${firstDay}-${action.payload.reportCode}`]: true,
            [`${secondDay}-${action.payload.reportCode}`]: true,
          };
        } else {
          state.reportSummary = {
            ...state.reportSummary,
            [`${firstDay}-${action.payload.reportCode}`]: true,
          };
        }
      } else {
        state.reportSummary = {
          ...state.reportSummary,
          [`${moment(action.payload.dateString, 'DDMMYYYY').format('DD')}-${
            action.payload.reportCode
          }`]: true,
        };
      }
    },
    uploadReportFailed(state, action) {
      state.loadingStatus.reportUpload = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getReportSummaryStarted(state) {
      state.loadingStatus.reportSummary = 'PENDING';
    },
    getReportSummarySuccess(state, action) {
      state.loadingStatus.reportSummary = 'COMPLETE';
      state.reportSummary = action.payload;
    },
    getReportSummaryFailed(state, action) {
      state.loadingStatus.reportSummary = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    updateRecentTakealotOrdersStarted(state) {
      state.loadingStatus.updateRecentOrders = 'PENDING';
    },
    updateRecentTakealotOrdersSuccess(state) {
      state.loadingStatus.updateRecentOrders = 'COMPLETE';
    },
    updateRecentTakealotOrdersFailed(state, action) {
      state.loadingStatus.updateRecentOrders = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    updateTakealotSkusStarted(state) {
      state.loadingStatus.updateTakealotSkus = 'PENDING';
    },
    updateTakealotSkusSuccess(state) {
      state.loadingStatus.updateTakealotSkus = 'COMPLETE';
    },
    updateTakealotSkusFailed(state, action) {
      state.loadingStatus.updateTakealotSkus = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
  },
});

export const {
  getSkuSuccess,
  getSkuFailed,
  updateTotalSkusToLoad,
  uploadReportStarted,
  uploadReportSuccess,
  uploadReportFailed,
  getReportSummaryStarted,
  getReportSummarySuccess,
  getReportSummaryFailed,
  updateRecentTakealotOrdersStarted,
  updateRecentTakealotOrdersSuccess,
  updateRecentTakealotOrdersFailed,
  updateTakealotSkusStarted,
  updateTakealotSkusSuccess,
  updateTakealotSkusFailed,
} = maintenanceReportsSlice.actions;

export const fetchSku = (code, title, codeType) => async (dispatch) => {
  if (codeType === 'tsin') {
    const result = await getSkuByTsin(code);

    if (result.status === 200) {
      dispatch(getSkuSuccess());
    } else {
      dispatch(
        getSkuFailed({
          code,
          title,
        })
      );
    }
  } else if (codeType === 'childAsin') {
    const result = await getSkuByChildAsin(code);

    if (result.status === 200) {
      dispatch(getSkuSuccess());
    } else {
      dispatch(
        getSkuFailed({
          code,
          title,
        })
      );
    }
  } else {
    const result = await getSku(code);

    if (result.status === 200) {
      dispatch(getSkuSuccess());
    } else {
      dispatch(
        getSkuFailed({
          code,
          title,
        })
      );
    }
  }
};

export const setTotalSkusToLoad = (total) => (dispatch) => {
  dispatch(updateTotalSkusToLoad(total));
};

export const uploadReportData =
  (reportCode, dateString, uploaderId, uploaderName, data) =>
  async (dispatch) => {
    dispatch(uploadReportStarted());
    const result = await createReport(
      reportCode,
      dateString,
      uploaderId,
      uploaderName,
      data
    );

    if (result.status === 200) {
      dispatch(uploadReportSuccess({ reportCode, dateString }));
    } else {
      dispatch(uploadReportFailed(result.error));
    }
  };

export const fetchReportSummary = (monthString) => async (dispatch) => {
  dispatch(getReportSummaryStarted());
  const result = await getReportSummary(monthString);

  if (result.status === 200) {
    dispatch(getReportSummarySuccess(result.summary));
  } else {
    dispatch(getReportSummaryFailed(result.error));
  }
};

export const updateTakealotOrders = () => async (dispatch) => {
  dispatch(updateRecentTakealotOrdersStarted());
  const result = await getRecentTakealotOrders();

  if (result.status === 200) {
    dispatch(updateRecentTakealotOrdersSuccess());
  } else {
    dispatch(updateRecentTakealotOrdersFailed(result.error));
  }
};

export const updateTakealotSkuInfo = () => async (dispatch) => {
  dispatch(updateTakealotSkusStarted());
  const result = await updateOutdatedTakealotSkus();

  if (result.status === 200) {
    dispatch(updateTakealotSkusSuccess());
  } else {
    dispatch(updateTakealotSkusFailed(result.error));
  }
};

export default maintenanceReportsSlice.reducer;
