import _ from 'lodash';

export const getFilteredSkus = (skus, searchText, clientId) => {
  return (skus || [])
    .filter((skuInfo) => {
      if (clientId !== '') {
        return skuInfo.clientId === clientId;
      }

      return true;
    })
    .filter((skuInfo) => {
      if (searchText !== '') {
        const caseInsensitiveSearch = _.toLower(searchText);
        const caseInsensitiveSku = _.toLower(skuInfo.sku);
        const caseInsensitiveAsin = _.toLower(skuInfo.asin);
        const caseInsensitiveTsin = _.toLower(skuInfo.tsin);
        const caseInsensitiveVariation = _.toLower(skuInfo.variation);
        const caseInsensitiveProductName = _.toLower(skuInfo.productName);
        const caseInsensitiveClientName = _.toLower(skuInfo.clientName);
        const caseInsensitiveBrand = _.toLower(skuInfo.brand);
        const caseInsensitiveBarcode = _.toLower(skuInfo.barcode);

        if (caseInsensitiveSku.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveAsin.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveTsin.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveVariation.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveProductName.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveClientName.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveBrand.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveBarcode.includes(caseInsensitiveSearch)) {
          return true;
        }

        return false;
      }

      return true;
    });
};

export default getFilteredSkus;
