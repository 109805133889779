import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  loadingWrapper: {
    padding: theme.spacing(8),
    textAlign: 'center',
  },
}));

const LogisticsCard = ({ isLoading, onMissingInfoClick, leadTime }) => {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader title="Logistics" />
      <Divider />
      <CardContent>
        {isLoading ? (
          <div className={classes.loadingWrapper}>
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <List>
            <ListItem>
              <ListItemText
                primary={leadTime === -1 ? '7 days' : `${leadTime} days`}
                secondary="Lead Time"
              />
              {leadTime === -1 && (
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={onMissingInfoClick}
                  >
                    <HelpOutlineIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          </List>
        )}
      </CardContent>
    </Card>
  );
};

LogisticsCard.defaultProps = {
  isLoading: false,
  onMissingInfoClick: () => console.log('Missing info button was clicked.'),
  leadTime: -1,
};

LogisticsCard.propTypes = {
  isLoading: PropTypes.bool,
  onMissingInfoClick: PropTypes.func,
  leadTime: PropTypes.number,
};

export default LogisticsCard;
