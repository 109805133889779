/* eslint-disable react/jsx-props-no-spreading */
import { fade } from '@material-ui/core/styles/colorManipulator';
import numeral from 'numeral';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';

const headerStyle = {
  backgroundColor: '#171415',
};

const useStyles = makeStyles((theme) => ({
  loadingOverlay: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: fade(theme.palette.common.black, 0.8),
  },
  wrapperContent: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

const SkusTable = ({
  isLoading,
  skus,
  viewSku,
  pageSize,
  initialPage,
  updatePageSize,
  updateInitialPage,
  platformFilter,
}) => {
  const classes = useStyles();

  const getProductName = (rowData) => {
    if (rowData) {
      return rowData.variation === 'N/A'
        ? `${rowData.brand} - ${rowData.productName}`
        : `${rowData.brand} - ${rowData.productName} (${rowData.variation})`;
    }
    return 'Unknown';
  };

  const getColumns = () => {
    const columns = [
      { title: 'SKU', field: 'sku', editable: false },
      {
        title: 'Client',
        field: 'clientName',
        hidden: true,
        export: true,
      },
      { title: 'Product', render: (rowData) => getProductName(rowData) },
      {
        title: 'Revenue',
        field: 'revenue',
        type: 'numeric',
        render: (rowData) =>
          `${platformFilter === 'TAKEALOT' ? 'R' : '$'} ${numeral(
            rowData.revenue
          ).format('0,0')}`,
      },
      {
        title: 'Units Sold',
        field: 'unitsSold',
        type: 'numeric',
        render: (rowData) => numeral(rowData.unitsSold).format('0,0'),
      },
      {
        title: 'Page Views',
        field: 'pageViews',
        type: 'numeric',
        render: (rowData) => numeral(rowData.pageViews).format('0,0'),
      },
      {
        title: 'Conversion Rate',
        field: 'conversionRate',
        type: 'numeric',
        render: (rowData) => `${Math.round(rowData.conversionRate * 100)} %`,
      },
    ];

    return columns;
  };

  return (
    <div className={classes.wrapperContent}>
      <MaterialTable
        title=""
        columns={getColumns()}
        data={skus}
        isLoading={isLoading}
        onChangeRowsPerPage={updatePageSize}
        onChangePage={updateInitialPage}
        options={{
          pageSize,
          initialPage,
          headerStyle,
          actionsColumnIndex: -1,
          search: false,
          grouping: false,
          draggable: false,
          exportButton: true,
          exportAllData: true,
          exportFileName: 'Listings',
        }}
        actions={[
          {
            icon: 'keyboard_arrow_right',
            tooltip: 'View SKU analytics',
            onClick: (event, rowData) => viewSku(rowData.sku),
          },
        ]}
        components={{
          OverlayLoading: () => (
            <div className={classes.loadingOverlay}>
              <CircularProgress color="primary" size={32} />
            </div>
          ),
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'No listings to display',
          },
        }}
      />
    </div>
  );
};

SkusTable.defaultProps = {
  isLoading: false,
  skus: [],
  viewSku: (sku) => console.log(`SKU viewed: ${sku}`),
  pageSize: 5,
  initialPage: 0,
  updatePageSize: (newPageSize) =>
    console.log(`Page size updated to: ${newPageSize}`),
  updateInitialPage: (newInitialPage) =>
    console.log(`Initial page updated to: ${newInitialPage}`),
  platformFilter: 'TAKEALOT',
};

SkusTable.propTypes = {
  isLoading: PropTypes.bool,
  skus: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      brand: PropTypes.string,
      clientId: PropTypes.string,
      clientName: PropTypes.string,
      productName: PropTypes.string,
      variation: PropTypes.string,
      stockImagesLink: PropTypes.string,
      status: PropTypes.oneOf(['ACTIVE', 'INACTIVE']),
    })
  ),
  viewSku: PropTypes.func,
  pageSize: PropTypes.number,
  initialPage: PropTypes.number,
  updatePageSize: PropTypes.func,
  updateInitialPage: PropTypes.func,
  platformFilter: PropTypes.string,
};

export default SkusTable;
