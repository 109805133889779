import { useState } from 'react';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';

const SelectedSelector = ({ selectedFilter, changeSelectedFilter }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const getPrettyFilter = (filter) => {
    switch (filter) {
      case 'ALL':
        return 'All Selections';
      case 'SELECTED_ONLY':
        return 'Selected Only';
      case 'UNSELECTED_ONLY':
        return 'Unselected Only';
      default:
        return 'Unknown';
    }
  };

  return (
    <>
      <Button
        aria-controls="selected-menu"
        aria-haspopup="true"
        size="large"
        startIcon={<CheckCircleIcon />}
        onClick={openMenu}
      >
        {getPrettyFilter(selectedFilter)}
      </Button>
      <Menu
        id="selected-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem
          onClick={() => {
            changeSelectedFilter('ALL');
            closeMenu();
          }}
        >
          All
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeSelectedFilter('SELECTED_ONLY');
            closeMenu();
          }}
        >
          Only Selected Products
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeSelectedFilter('UNSELECTED_ONLY');
            closeMenu();
          }}
        >
          Only Unselected Products
        </MenuItem>
      </Menu>
    </>
  );
};

SelectedSelector.defaultProps = {
  selectedFilter: 'ALL',
  changeSelectedFilter: (newFilter) =>
    console.log('User changes the selected filter to:', newFilter),
};

SelectedSelector.propTypes = {
  selectedFilter: PropTypes.string,
  changeSelectedFilter: PropTypes.func,
};

export default SelectedSelector;
