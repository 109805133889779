/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  createNewDeal,
  getSalesAgents,
  getSourcedProducts,
} from '../../../api/salesAPI';
import { getClients } from '../../../api/clientsAPI';

const initialState = {
  isUserRedirectedToBrowse: false,
  salesAgents: [],
  clients: [],
  products: [],
  loadingStatus: {
    creation: 'NOT_STARTED',
    salesAgents: 'NOT_STARTED',
    clients: 'NOT_STARTED',
    products: 'NOT_STARTED',
  },
  feedback: {
    isOpen: false,
    type: undefined,
    message: '',
  },
};

const dealsCreateSlice = createSlice({
  name: 'dealsCreate',
  initialState,
  reducers: {
    createDealStarted(state) {
      state.loadingStatus.creation = 'PENDING';
    },
    createDealSuccess(state) {
      state.loadingStatus.creation = 'COMPLETE';
      state.isUserRedirectedToBrowse = true;
    },
    createDealFailed(state, action) {
      state.loadingStatus.creation = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getSalesAgentsStarted(state) {
      state.loadingStatus.salesAgents = 'PENDING';
    },
    getSalesAgentsSuccess(state, action) {
      state.salesAgents = action.payload;
      state.loadingStatus.salesAgents = 'COMPLETE';
    },
    getSalesAgentsFailed(state, action) {
      state.loadingStatus.salesAgents = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getClientsStarted(state) {
      state.loadingStatus.clients = 'PENDING';
    },
    getClientsSuccess(state, action) {
      state.clients = action.payload;
      state.loadingStatus.clients = 'COMPLETE';
    },
    getClientsFailed(state, action) {
      state.loadingStatus.clients = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getProductsStarted(state) {
      state.loadingStatus.products = 'PENDING';
    },
    getProductsSuccess(state, action) {
      state.products = action.payload;
      state.loadingStatus.products = 'COMPLETE';
    },
    getProductsFailed(state, action) {
      state.loadingStatus.products = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    resetState() {
      return initialState;
    },
  },
});

export const {
  createDealStarted,
  createDealSuccess,
  createDealFailed,
  getSalesAgentsStarted,
  getSalesAgentsSuccess,
  getSalesAgentsFailed,
  getClientsStarted,
  getClientsSuccess,
  getClientsFailed,
  getProductsStarted,
  getProductsSuccess,
  getProductsFailed,
  resetState,
} = dealsCreateSlice.actions;

export default dealsCreateSlice.reducer;

export const createDeal = (dealInfo, proposalInfo) => async (dispatch) => {
  dispatch(createDealStarted());
  const result = await createNewDeal(dealInfo, proposalInfo);

  if (result.status === 200) {
    dispatch(createDealSuccess());
  } else {
    dispatch(createDealFailed(result.error));
  }
};

export const fetchSalesAgents = () => async (dispatch) => {
  dispatch(getSalesAgentsStarted());
  const result = await getSalesAgents();

  if (result.status === 200) {
    dispatch(getSalesAgentsSuccess(result.salesAgents));
  } else {
    dispatch(getSalesAgentsFailed(result.error));
  }
};

export const fetchClients = () => async (dispatch) => {
  dispatch(getClientsStarted());
  const result = await getClients();

  if (result.status === 200) {
    dispatch(getClientsSuccess(result.clients));
  } else {
    dispatch(getClientsFailed(result.error));
  }
};

export const fetchProducts = () => async (dispatch) => {
  dispatch(getProductsStarted());
  const result = await getSourcedProducts('AVAILABLE');

  if (result.status === 200) {
    dispatch(getProductsSuccess(result.products));
  } else {
    dispatch(getProductsFailed(result.error));
  }
};

export const initiateStateReset = () => async (dispatch) => {
  dispatch(resetState());
};
