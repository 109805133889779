/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  updateSupplierInfo,
  getSupplier,
} from '../../../api/presalesSuppliersAPI';

const initialState = {
  isUserRedirectedToBrowse: false,
  supplierInfo: {
    id: '',
    pricingColumns: {
      supplierSkuPricingIndex: 0,
      costPerUnitIndex: 0,
    },
  },
  loadingStatus: {
    updating: 'NOT_STARTED',
    supplierInfo: 'NOT_STARTED',
  },
  feedback: {
    isOpen: false,
    type: undefined,
    message: '',
  },
};

const presalesSuppliersUpdatePricingSettingsSlice = createSlice({
  name: 'presalesSuppliersUpdatePricingSettings',
  initialState,
  reducers: {
    updateSupplierStarted(state) {
      state.loadingStatus.updating = 'PENDING';
    },
    updateSupplierSuccess(state) {
      state.loadingStatus.updating = 'COMPLETE';
      state.isUserRedirectedToBrowse = true;
    },
    updateSupplierFailed(state, action) {
      state.loadingStatus.updating = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    fetchSupplierInfoStarted(state) {
      state.loadingStatus.supplierInfo = 'PENDING';
    },
    fetchSupplierInfoSuccess(state, action) {
      state.supplierInfo = action.payload;
      state.loadingStatus.supplierInfo = 'COMPLETE';
    },
    fetchSupplierInfoFailed(state, action) {
      state.loadingStatus.supplierInfo = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    resetState() {
      return initialState;
    },
  },
});

export const {
  updateSupplierStarted,
  updateSupplierSuccess,
  updateSupplierFailed,
  fetchSupplierInfoStarted,
  fetchSupplierInfoSuccess,
  fetchSupplierInfoFailed,
  resetState,
} = presalesSuppliersUpdatePricingSettingsSlice.actions;

export default presalesSuppliersUpdatePricingSettingsSlice.reducer;

export const updateSupplierPricingColums =
  (supplierId, newInfo) => async (dispatch) => {
    dispatch(updateSupplierStarted());
    const result = await updateSupplierInfo(supplierId, newInfo);

    if (result.status === 200) {
      dispatch(updateSupplierSuccess());
    } else {
      dispatch(updateSupplierFailed(result.error));
    }
  };

export const fetchSupplierInfo = (supplierId) => async (dispatch) => {
  dispatch(fetchSupplierInfoStarted());
  const result = await getSupplier(supplierId);

  if (result.status === 200) {
    dispatch(fetchSupplierInfoSuccess(result.supplierInfo));
  } else {
    dispatch(fetchSupplierInfoFailed(result.error));
  }
};

export const initiateStateReset = () => async (dispatch) => {
  dispatch(resetState());
};
