/* eslint-disable react/jsx-props-no-spreading */
import { fade } from '@material-ui/core/styles/colorManipulator';
import { green, red, orange, yellow } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import CreateIcon from '@material-ui/icons/Create';
import RateReviewIcon from '@material-ui/icons/RateReview';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';

const headerStyle = {
  backgroundColor: '#171415',
};

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.common.black,
  },
  link: {
    color: theme.palette.common.white,
  },
  loadingOverlay: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: fade(theme.palette.common.black, 0.8),
  },
  statusCompleted: {
    color: theme.palette.common.black,
    backgroundColor: green[500],
  },
  statusNotStarted: {
    color: theme.palette.common.black,
    backgroundColor: red[500],
  },
  statusReviewing: {
    color: theme.palette.common.black,
    backgroundColor: yellow[500],
  },
  statusDrafting: {
    color: theme.palette.common.black,
    backgroundColor: orange[500],
  },
  wrapperContent: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

const SkusTable = ({
  isLoading,
  skus,
  viewSku,
  pageSize,
  initialPage,
  updatePageSize,
  updateInitialPage,
}) => {
  const classes = useStyles();

  const getPrettyStatus = (status) => {
    switch (status) {
      case 'NOT_STARTED':
        return (
          <Chip
            icon={<ClearIcon className={classes.icon} />}
            label="Not Started"
            className={classes.statusNotStarted}
          />
        );
      case 'DRAFTING':
        return (
          <Chip
            icon={<CreateIcon className={classes.icon} />}
            label="Drafting"
            className={classes.statusDrafting}
          />
        );
      case 'REVIEWING':
        return (
          <Chip
            icon={<RateReviewIcon className={classes.icon} />}
            label="Reviewing"
            className={classes.statusReviewing}
          />
        );
      case 'COMPLETED':
        return (
          <Chip
            icon={<DoneIcon className={classes.icon} />}
            label="Completed"
            className={classes.statusCompleted}
          />
        );
      default:
        return (
          <Chip
            icon={<ClearIcon className={classes.icon} />}
            label="Not Started"
            className={classes.statusNotStarted}
          />
        );
    }
  };

  const getColumns = () => {
    const columns = [
      { title: 'SKU', field: 'sku', editable: false },
      { title: 'Client', field: 'clientName', editable: false },
      { title: 'Brand', field: 'brand' },
      { title: 'Product Name', field: 'productName' },
      { title: 'Variation', field: 'variation' },
      { title: 'Copywriter', field: 'copywriter' },
      {
        title: 'Status',
        field: 'status',
        editable: false,
        render: (rowData) => getPrettyStatus(rowData.status),
      },
      {
        title: 'Creation Date',
        field: 'creationDate',
        hidden: true,
        export: true,
      },
    ];

    return columns;
  };

  return (
    <div className={classes.wrapperContent}>
      <MaterialTable
        title=""
        columns={getColumns()}
        data={skus}
        isLoading={isLoading}
        onChangeRowsPerPage={updatePageSize}
        onChangePage={updateInitialPage}
        options={{
          pageSize,
          initialPage,
          headerStyle,
          actionsColumnIndex: -1,
          search: false,
          grouping: false,
          draggable: false,
          exportButton: true,
          exportAllData: true,
          exportFileName: 'Listings',
        }}
        actions={[
          {
            icon: 'keyboard_arrow_right',
            tooltip: 'View SKU info',
            onClick: (event, rowData) => viewSku(rowData.sku),
          },
        ]}
        components={{
          OverlayLoading: () => (
            <div className={classes.loadingOverlay}>
              <CircularProgress color="primary" size={32} />
            </div>
          ),
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'No listings to display',
          },
        }}
      />
    </div>
  );
};

SkusTable.defaultProps = {
  isLoading: false,
  skus: [],
  viewSku: (sku) => console.log(`SKU viewed: ${sku}`),
  pageSize: 5,
  initialPage: 0,
  updatePageSize: (newPageSize) =>
    console.log(`Page size updated to: ${newPageSize}`),
  updateInitialPage: (newInitialPage) =>
    console.log(`Initial page updated to: ${newInitialPage}`),
};

SkusTable.propTypes = {
  isLoading: PropTypes.bool,
  skus: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string,
      brand: PropTypes.string,
      clientId: PropTypes.string,
      clientName: PropTypes.string,
      productName: PropTypes.string,
      variation: PropTypes.string,
      copywriter: PropTypes.string,
      stockImagesLink: PropTypes.string,
      status: PropTypes.oneOf(['ACTIVE', 'INACTIVE']),
    })
  ),
  viewSku: PropTypes.func,
  pageSize: PropTypes.number,
  initialPage: PropTypes.number,
  updatePageSize: PropTypes.func,
  updateInitialPage: PropTypes.func,
};

export default SkusTable;
