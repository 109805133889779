import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import GetAppIcon from '@material-ui/icons/GetApp';
import PropTypes from 'prop-types';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import numeral from 'numeral';
import Tooltip from '@material-ui/core/Tooltip';
import EmptyViewMessage from '../EmptyViewMessage';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    backgroundColor: grey[900],
  },
}));

const DocumentsTab = ({ documents, deleteDocument }) => {
  const classes = useStyles();

  if (documents.length === 0) {
    return (
      <div className={classes.root}>
        <EmptyViewMessage
          heading="No Documents"
          message="Upload new documents to view them here."
        />
      </div>
    );
  }
  const getPrettyTag = (tag) => {
    switch (tag) {
      case 'CONSIGNMENT_INVOICE':
        return 'Consignment Invoice';
      case 'INVOICE':
        return 'Invoice';
      case 'PROOF_OF_PAYMENT':
        return 'Proof of Payment';
      case 'WAYBILL':
        return 'Waybill';
      case 'CATALOGUE':
        return 'Catalogue';
      case 'OTHER':
        return 'Other';
      default:
        return 'Unknown';
    }
  };

  const getFormattedFileSize = (fileSize) => {
    return numeral(fileSize / 1048576).format('0,0.00');
  };

  return (
    <Card>
      <CardContent>
        <List>
          {documents.map((info) => (
            <ListItem key={info.id}>
              <ListItemText
                primary={info.description}
                secondary={`${getPrettyTag(info.tag)}  | ${getFormattedFileSize(
                  info.fileSize
                )} mb`}
              />
              <ListItemSecondaryAction>
                <Tooltip title="Download">
                  <IconButton
                    edge="end"
                    onClick={() => window.open(info.url, '_blank')}
                  >
                    <GetAppIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    edge="end"
                    onClick={() => {
                      deleteDocument(info);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

DocumentsTab.defaultProps = {
  documents: [],
  deleteDocument: () => {},
};

DocumentsTab.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.shape({})),
  deleteDocument: PropTypes.func,
};

export default DocumentsTab;
