import moment from 'moment';
import numeral from 'numeral';
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import logo from '../../img/logo-white.png';
import roboto from '../../fonts/Roboto-Regular.ttf';
import robotoBold from '../../fonts/Roboto-Bold.ttf';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: roboto,
    },
    {
      src: robotoBold,
      fontWeight: 'bold',
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    padding: 50,
    paddingBottom: 70,
    lineHeight: 1.4,
    fontFamily: 'Roboto',
  },
  header: {
    flexDirection: 'row',
    backgroundColor: '#171415',
    width: '100%',
    padding: 20,
  },
  headerTitle: {
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    marginTop: 12,
    color: '#f36438',
    fontSize: 16,
  },
  headerInfo: {
    width: '60%',
    fontSize: 10,
    color: '#fcf9f8',
    textAlign: 'right',
  },
  logoWrapper: {
    width: '40%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: 120,
    height: 'auto',
  },
  section: {
    width: '100%',
    textAlign: 'center',
    color: '#595959',
    fontSize: 10,
    paddingVertical: 24,
  },
  sectionTitle: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginBottom: 12,
    backgroundColor: '#584b53',
    color: '#fcf9f8',
    padding: 9,
    fontSize: 10,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    fontSize: 9,
  },
  tableSales: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderBottomWidth: 0,
    fontSize: 9,
  },
  oddRow: {
    margin: 'auto',
    flexDirection: 'row',
    backgroundColor: '#f3f3f3',
  },
  evenRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  titleRow: {
    fontFamily: 'Roboto',
    margin: 'auto',
    width: '100%',
    flexDirection: 'row',
    textAlign: 'left',
    fontWeight: 'bold',
    paddingVertical: 8,
    paddingHorizontal: 14,
    borderWidth: 1,
    backgroundColor: '#595959',
    color: '#fcf9f8',
  },
  tableHeader: {
    fontFamily: 'Roboto',
    margin: 'auto',
    flexDirection: 'row',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  tableFooter: {
    fontFamily: 'Roboto',
    margin: 'auto',
    flexDirection: 'row',
    fontWeight: 'bold',
  },
  page1Col1: {
    fontFamily: 'Roboto',
    width: '30%',
    fontWeight: 'bold',
    borderStyle: 'solid',
    textAlign: 'right',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
  },
  page1Col2: {
    width: '70%',
    borderStyle: 'solid',
    textAlign: 'left',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
  },
  billingCol1: {
    width: '30%',
    fontWeight: 'bold',
    borderStyle: 'solid',
    textAlign: 'right',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  billingCol2: {
    width: '70%',
    borderStyle: 'solid',
    textAlign: 'left',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  salesCol1: {
    width: '25%',
    borderStyle: 'solid',
    textAlign: 'left',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  salesCol2: {
    width: '33%',
    borderStyle: 'solid',
    textAlign: 'left',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  salesCol3: {
    width: '10%',
    borderStyle: 'solid',
    textAlign: 'center',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  salesCol4: {
    width: '15%',
    borderStyle: 'solid',
    textAlign: 'right',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  salesCol5: {
    width: '17%',
    borderStyle: 'solid',
    textAlign: 'right',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  termRow: {
    margin: 'auto',
    flexDirection: 'row',
    alignItems: 'center',
  },
  standardTermCol1: {
    width: '5%',
    textAlign: 'right',
    fontWeight: 'bold',
    paddingVertical: 2,
    paddingHorizontal: 4,
  },
  standardTermCol2: {
    width: '95%',
    textAlign: 'left',
    paddingVertical: 2,
    paddingHorizontal: 4,
    fontSize: 8,
  },
  indentedTermCol1: {
    width: '10%',
    textAlign: 'right',
    fontWeight: 'bold',
    paddingVertical: 2,
    paddingHorizontal: 4,
  },
  indentedTermCol2: {
    width: '90%',
    textAlign: 'left',
    paddingVertical: 2,
    paddingHorizontal: 4,
    fontSize: 8,
  },
  listingsCol1: {
    width: '5%',
    borderStyle: 'solid',
    textAlign: 'center',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  listingsCol2: {
    width: '30%',
    borderStyle: 'solid',
    textAlign: 'left',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  listingsCol3: {
    width: '10%',
    borderStyle: 'solid',
    textAlign: 'center',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  listingsCol4: {
    width: '10%',
    borderStyle: 'solid',
    textAlign: 'center',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  listingsCol5: {
    width: '15%',
    borderStyle: 'solid',
    textAlign: 'right',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  listingsCol6: {
    width: '15%',
    borderStyle: 'solid',
    textAlign: 'right',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  listingsCol7: {
    width: '15%',
    borderStyle: 'solid',
    textAlign: 'right',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  listingsFinalRow: {
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 0,
    borderTopWidth: 1,
    height: 0,
  },
  listingsHeaderCol1: {
    width: '5%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  listingsHeaderCol2: {
    width: '30%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  listingsHeaderCol3: {
    width: '10%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  listingsHeaderCol4: {
    width: '10%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  listingsHeaderCol5: {
    width: '15%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  listingsHeaderCol6: {
    width: '15%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  listingsHeaderCol7: {
    width: '15%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  headerCol1: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  headerCol2: {
    width: '33%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  headerCol3: {
    width: '10%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  headerCol4: {
    width: '15%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  headerCol5: {
    width: '17%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  footerRow1Col1: {
    fontSize: 10,
    width: '83%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 2,
    textAlign: 'right',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  footerRow1Col2: {
    fontSize: 10,
    width: '17%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    textAlign: 'right',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  footerRow2Col1: {
    fontSize: 10,
    width: '83%',
    borderWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    textAlign: 'right',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  footerRow2Col2: {
    fontSize: 10,
    width: '17%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderTopWidth: 0,
    paddingVertical: 2,
    paddingHorizontal: 4,
    textAlign: 'right',
    backgroundColor: '#f3f3f3',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  footerRow3Col1: {
    fontSize: 11,
    width: '83%',
    borderWidth: 0,
    paddingVertical: 4,
    paddingHorizontal: 4,
    textAlign: 'right',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  footerRow3Col2: {
    fontSize: 11,
    width: '17%',
    borderWidth: 1,
    borderTopWidth: 0,
    paddingVertical: 4,
    paddingHorizontal: 4,
    textAlign: 'right',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  finePrint: {
    paddingHorizontal: 48,
    fontSize: 8,
    marginBottom: 12,
  },
  totalsWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  totalWrapper: {
    width: '20%',
    display: 'flex',
    flexDirection: 'column',
    padding: 12,
  },
  totalValue: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 16,
    textAlign: 'center',
  },
  totalHeading: {
    fontFamily: 'Roboto',
    fontSize: 9,
    textAlign: 'center',
  },
});

const SuperDealerProposal = ({
  businessName,
  creationDate,
  listings,
  batchCode,
}) => {
  const formattedCreationDate = moment(creationDate).format('D MMMM YYYY');
  const totals = listings.reduce(
    (tally, current) => {
      const currentCostPrice = current.pricing.costPrice;
      const currentWholesalePrice = current.pricing.wholesalePrice;
      const currentRetailPrice = current.pricing.retailPrice;
      const currentMarkup =
        ((currentWholesalePrice - currentCostPrice) / currentCostPrice) * 100;

      return {
        costPrice: tally.costPrice + currentCostPrice,
        wholesalePrice: tally.wholesalePrice + currentWholesalePrice,
        retailPrice: tally.retailPrice + currentRetailPrice,
        markup: tally.markup + currentMarkup,
      };
    },
    {
      costPrice: 0,
      wholesalePrice: 0,
      retailPrice: 0,
      markup: 0,
    }
  );

  return (
    <Document
      title="Super Dealer Product List"
      creator="EasyOnline Pty Ltd"
      subject={`Super Dealer Products for ${businessName} (${moment(
        creationDate
      ).format('DD/MM/YYYY')})`}
    >
      <Page wrap size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.logoWrapper}>
            <Image src={logo} style={styles.logo} />
          </View>
          <View style={styles.headerInfo}>
            <Text style={styles.headerTitle}>{businessName}</Text>
            <Text>{`Issued on ${formattedCreationDate}`}</Text>
          </View>
        </View>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Batch Averages Summary</Text>
          <Text style={styles.finePrint}>
            {`This document outlines products to be onboarded for ${businessName} in batch coded ${batchCode}. Below are averages for key product metrics in this batch.`}
          </Text>
          <View style={styles.totalsWrapper}>
            <View style={styles.totalWrapper}>
              <Text style={styles.totalValue}>
                {`R ${(totals.costPrice / listings.length).toFixed(2)}`}
              </Text>
              <Text style={styles.totalHeading}>Cost Price</Text>
            </View>
            <View style={styles.totalWrapper}>
              <Text style={styles.totalValue}>
                {`R ${(totals.wholesalePrice / listings.length).toFixed(2)}`}
              </Text>
              <Text style={styles.totalHeading}>Wholesale Price</Text>
            </View>
            <View style={styles.totalWrapper}>
              <Text style={styles.totalValue}>
                {`R ${Math.ceil(totals.retailPrice / listings.length)}`}
              </Text>
              <Text style={styles.totalHeading}>Retail Price</Text>
            </View>
            <View style={styles.totalWrapper}>
              <Text style={styles.totalValue}>
                {`${Math.ceil(totals.markup / listings.length)} %`}
              </Text>
              <Text style={styles.totalHeading}>Markup</Text>
            </View>
          </View>
        </View>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Product List</Text>
          <Text style={styles.finePrint}>
            The table below provides details of the agreed wholesale price paid
            to the customer after each unit sold. All prices below include VAT.
          </Text>
          <View style={styles.tableSales}>
            <View style={styles.tableHeader}>
              <View style={styles.listingsHeaderCol1} />
              <View style={styles.listingsHeaderCol2}>
                <Text>Product Description</Text>
              </View>
              <View style={styles.listingsHeaderCol3}>
                <Text>Images Bought</Text>
              </View>
              <View style={styles.listingsHeaderCol4}>
                <Text>Initial Units</Text>
              </View>
              <View style={styles.listingsHeaderCol5}>
                <Text>Cost Price</Text>
              </View>
              <View style={styles.listingsHeaderCol6}>
                <Text>Wholesale Price</Text>
              </View>
              <View style={styles.listingsHeaderCol7}>
                <Text>Retail Price</Text>
              </View>
            </View>
            {listings.map((listing, index) => (
              <View
                key={`${listing.name}-${listing.retailPrice}`}
                style={index % 2 === 0 ? styles.oddRow : styles.evenRow}
              >
                <View style={styles.listingsCol1}>
                  <Text>{index + 1}</Text>
                </View>
                <View style={styles.listingsCol2}>
                  <Text>{listing.description}</Text>
                </View>
                <View style={styles.listingsCol3}>
                  <Text>2</Text>
                </View>
                <View style={styles.listingsCol4}>
                  <Text>8</Text>
                </View>
                <View style={styles.listingsCol5}>
                  <Text>
                    {`R ${numeral(listing.pricing.costPrice).format('0,0.00')}`}
                  </Text>
                </View>
                <View style={styles.listingsCol6}>
                  <Text>
                    {`R ${numeral(listing.pricing.wholesalePrice).format(
                      '0,0.00'
                    )}`}
                  </Text>
                </View>
                <View style={styles.listingsCol7}>
                  <Text>
                    {`R ${numeral(listing.pricing.retailPrice).format(
                      '0,0.00'
                    )}`}
                  </Text>
                </View>
              </View>
            ))}
            <View style={styles.listingsFinalRow} />
          </View>
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

SuperDealerProposal.defaultProps = {
  businessName: 'Madeup Inc',
  creationDate: new Date(),
  batchCode: '001012021',
  listings: [
    {
      name: 'Sweater - Blue (XL)',
      units: 8,
      costPrice: 45,
      wholesalePrice: 90,
      retailPrice: 156,
    },
    {
      name: 'Shorts - Pink (XS)',
      units: 8,
      costPrice: 20,
      wholesalePrice: 70,
      retailPrice: 99,
    },
    {
      name: 'Mens Perfume (200ml)',
      units: 8,
      costPrice: 100,
      wholesalePrice: 150,
      retailPrice: 199,
    },
  ],
};

SuperDealerProposal.propTypes = {
  businessName: PropTypes.string,
  creationDate: PropTypes.instanceOf(Date),
  batchCode: PropTypes.string,
  listings: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      units: PropTypes.number,
      costPrice: PropTypes.number,
      wholesalePrice: PropTypes.number,
      retailPrice: PropTypes.number,
    })
  ),
};

export default SuperDealerProposal;
