import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { fetchUsers, updateInitialPage, updatePageSize } from './slice';
import ContentWrapper from '../../../components/ContentWrapper';
import EmptyViewMessage from '../../../components/EmptyViewMessage';
import PageHeader from '../../../components/PageHeader';
import UsersTable from './components/UsersTable';

const breadcrumbs = [
  {
    label: 'User Management',
    link: '/user-management',
  },
  {
    label: 'Staff',
    link: '/user-management/staff',
  },
];

const Users = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const userInfo = useSelector((state) => state.account.userInfo);
  const users = useSelector((state) => state.usersBrowse.usersList);
  const pageSize = useSelector((state) => state.usersBrowse.pageSize);
  const initialPage = useSelector((state) => state.usersBrowse.initialPage);
  const usersLoadingStatus = useSelector(
    (state) => state.usersBrowse.loadingStatus.users
  );

  useEffect(() => {
    if (usersLoadingStatus === 'NOT_STARTED') {
      dispatch(fetchUsers());
    }
  }, []);

  useEffect(() => {
    if (usersLoadingStatus === 'PENDING') {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [usersLoadingStatus]);

  const getFormattedUsers = () => {
    return users
      .filter((info) => {
        if (userInfo.agencyId && info.agencyId !== userInfo.agencyId) {
          return false;
        }
        return true;
      })
      .map((info) => {
        return {
          id: info.id,
          name: info.name,
          surname: info.surname,
          email: info.email,
          phoneNumber: info.phoneNumber,
          title: info.title,
          type: info.type,
          status: info.status,
          isPersonalNumberShownInSignature: info.signature
            ? info.signature.showPersonalNumber
            : false,
          agencyName: info.agencyName ? info.agencyName : 'N/A',
        };
      });
  };

  return (
    <div>
      <PageHeader
        title="Browse Staff"
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Browse"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
        actions={[
          {
            label: 'Add new member',
            icon: <AddIcon />,
            onClick: () => history.push('/user-management/staff/add'),
          },
        ]}
      />
      {isLoading && <LinearProgress color="secondary" />}
      <ContentWrapper>
        {!isLoading && users.length === 0 ? (
          <EmptyViewMessage
            heading="No Staff Available"
            message="Please adjust the filters or add new member to view them here."
          />
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <UsersTable
                isLoading={usersLoadingStatus === 'PENDING'}
                users={getFormattedUsers()}
                viewUser={(userId) =>
                  history.push(`/user-management/staff/browse/${userId}`)
                }
                isAgencyColumnShown={!userInfo.agencyId}
                pageSize={pageSize}
                initialPage={initialPage}
                updatePageSize={(newPageSize) =>
                  dispatch(updatePageSize(newPageSize))
                }
                updateInitialPage={(newInitialPage) =>
                  dispatch(updateInitialPage(newInitialPage))
                }
              />
            </Grid>
          </Grid>
        )}
      </ContentWrapper>
    </div>
  );
};

export default Users;
