import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import EmptyViewMessage from '../../../../../components/EmptyViewMessage';
import Report from './components/Report';

const RequestedReports = ({ orderReports }) => {
  return (
    <Grid container spacing={3}>
      {orderReports.length === 0 && (
        <EmptyViewMessage
          heading="No reports requested"
          message="Request a new report to view the result here."
        />
      )}
      {orderReports.map((reportInfo) => {
        return (
          <Grid key={reportInfo.id} item xs={12}>
            <Report
              startDate={reportInfo.startDate}
              endDate={reportInfo.endDate}
              supplierFilter={reportInfo.supplierName}
              statusFilter={reportInfo.statusId}
              requestedBy={reportInfo.requestedByName}
              requestDate={reportInfo.requestDate}
              status={reportInfo.status}
              reportType={reportInfo.reportType}
              dcFilter={reportInfo.dcFilter}
              downloadLink={reportInfo.downloadLink}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

RequestedReports.defaultProps = {
  orderReports: [],
};

RequestedReports.propTypes = {
  orderReports: PropTypes.arrayOf(
    PropTypes.shape({
      objectID: PropTypes.string,
    })
  ),
};

export default RequestedReports;
