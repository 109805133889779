import { useState } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';

const ConversionRateSelector = ({
  conversionRateFilter,
  changeConversionRateFilter,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const getPrettyFilter = (filter) => {
    switch (filter) {
      case 'ALL':
        return 'All Conversion Rates';
      case 'LESS_THAN_5':
        return '< 5 %';
      case 'BETWEEN_5_AND_10':
        return '5 - 10 %';
      case 'MORE_THAN_10':
        return '> 10 %';
      default:
        return 'Custom';
    }
  };

  return (
    <>
      <Button
        aria-controls="conversion-rate-menu"
        aria-haspopup="true"
        size="large"
        startIcon={<TrackChangesIcon />}
        onClick={openMenu}
      >
        {getPrettyFilter(conversionRateFilter)}
      </Button>
      <Menu
        id="conversion-rate-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem
          onClick={() => {
            changeConversionRateFilter('ALL');
            closeMenu();
          }}
        >
          All
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeConversionRateFilter('LESS_THAN_5');
            closeMenu();
          }}
        >
          {'< 5 %'}
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeConversionRateFilter('BETWEEN_5_AND_10');
            closeMenu();
          }}
        >
          5 - 10 %
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeConversionRateFilter('MORE_THAN_10');
            closeMenu();
          }}
        >
          {'> 10 %'}
        </MenuItem>
      </Menu>
    </>
  );
};

ConversionRateSelector.defaultProps = {
  conversionRateFilter: 'ALL',
  changeConversionRateFilter: (newFilter) =>
    console.log('User changes the conversion rate filter to:', newFilter),
};

ConversionRateSelector.propTypes = {
  conversionRateFilter: PropTypes.string,
  changeConversionRateFilter: PropTypes.func,
};

export default ConversionRateSelector;
