/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  getPresalesSuppliers,
  updateSkuInfo,
  getPresalesSkus,
} from '../../../api/presalesAPI';

const initialState = {
  isUserRedirectedToBrowse: false,
  suppliers: [],
  presalesSkus: [],
  loadingStatus: {
    creation: 'NOT_STARTED',
    agencies: 'NOT_STARTED',
    presalesSkus: 'NOT_STARTED',
    updating: 'NOT_STARTED',
    statuses: 'NOT_STARTED',
  },
  feedback: {
    isOpen: false,
    type: undefined,
    message: '',
  },
};

const presalesSubmissionsAddSlice = createSlice({
  name: 'presalesSubmissionsAdd',
  initialState,
  reducers: {
    fetchPresalesSuppliersStarted(state) {
      state.loadingStatus.suppliers = 'PENDING';
    },
    fetchPresalesSuppliersSuccess(state, action) {
      state.suppliers = action.payload;
      state.loadingStatus.suppliers = 'COMPLETE';
    },
    fetchPresalesSuppliersFailed(state, action) {
      state.loadingStatus.suppliers = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    updateSkuStatusesStarted(state) {
      state.loadingStatus.statuses = 'PENDING';
    },
    updateSkuStatusesSuccess(state) {
      state.loadingStatus.statuses = 'COMPLETE';
    },
    updateSkuStatusesFailed(state, action) {
      state.loadingStatus.statuses = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getSkusStarted(state) {
      state.loadingStatus.presalesSkus = 'PENDING';
    },
    getSkusSuccess(state, action) {
      state.presalesSkus = action.payload;
      state.loadingStatus.presalesSkus = 'COMPLETE';
    },
    getSkusFailed(state, action) {
      state.loadingStatus.presalesSkus = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    resetState() {
      return initialState;
    },
  },
});

export const {
  fetchPresalesSuppliersStarted,
  fetchPresalesSuppliersSuccess,
  fetchPresalesSuppliersFailed,
  updateSkuStatusesStarted,
  updateSkuStatusesSuccess,
  updateSkuStatusesFailed,
  getSkusStarted,
  getSkusSuccess,
  getSkusFailed,
  resetState,
} = presalesSubmissionsAddSlice.actions;

export default presalesSubmissionsAddSlice.reducer;

export const fetchPresalesSuppliers = () => async (dispatch) => {
  dispatch(fetchPresalesSuppliersStarted());
  const result = await getPresalesSuppliers();

  if (result.status === 200) {
    dispatch(fetchPresalesSuppliersSuccess(result.suppliers));
  } else {
    dispatch(fetchPresalesSuppliersFailed(result.error));
  }
};

export const updateSku = (skuId, skuInfo) => async (dispatch) => {
  const result = await updateSkuInfo(skuId, skuInfo);

  if (result.status === 200) {
    dispatch(updateSkuStatusesSuccess());
  } else {
    dispatch(updateSkuStatusesFailed(result.error));
  }
};

export const fetchSkus = () => async (dispatch) => {
  dispatch(getSkusStarted());
  const result = await getPresalesSkus('NOT_SPECIFIED');

  if (result.status === 200) {
    dispatch(getSkusSuccess(result.skus));
  } else {
    dispatch(getSkusFailed(result.error));
  }
};

export const initiateStateReset = () => async (dispatch) => {
  dispatch(resetState());
};
