import { useState } from 'react';
import Button from '@material-ui/core/Button';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';

const DestinationSelector = ({
  destinationFilter,
  changeDestinationFilter,
  destinations,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const getPrettyFilter = (filter) => {
    switch (filter) {
      case 'TAKEALOT':
        return 'To Takealot';
      case 'AMAZON':
        return 'To Amazon';
      case 'EASYONLINE':
        return 'To EasyOnline';
      default:
        return 'Client';
    }
  };

  return (
    <>
      <Button
        aria-controls="destinations-menu"
        aria-haspopup="true"
        size="large"
        startIcon={<HomeWorkIcon />}
        onClick={openMenu}
      >
        {getPrettyFilter(destinationFilter)}
      </Button>
      <Menu
        id="from-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {destinations.map((option) => (
          <MenuItem
            key={`filter-option-${option.id}`}
            onClick={() => {
              changeDestinationFilter(option.id);
              closeMenu();
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

DestinationSelector.defaultProps = {
  destinationFilter: 'ALL',
  changeDestinationFilter: (selection) =>
    console.log(`User selected ${selection} as the destination`),
  destinations: [
    {
      id: 'TAKEALOT',
      label: 'Takealot',
    },
    {
      id: 'AMAZON',
      label: 'Amazon',
    },
    {
      id: 'EASYONLINE',
      label: 'EasyOnline',
    },
  ],
};

DestinationSelector.propTypes = {
  destinationFilter: PropTypes.string,
  changeDestinationFilter: PropTypes.func,
  destinations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};
export default DestinationSelector;
