import { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { fetchAgencies, fetchDeals, fetchSalesAgents } from './slice';
import {
  getAgencyAgents,
  getFilteredDeals,
  getRelevantSalesPeople,
  changeDateRange,
} from './logic';
import ContentWrapper from '../../../components/ContentWrapper';
import DateFilteringBar from './components/DateFilteringBar';
import DealCard from './components/DealCard';
import EmptyViewMessage from '../../../components/EmptyViewMessage';
import FiltersBar from './components/FiltersBar';
import PageHeader from '../../../components/PageHeader';

const breadcrumbs = [
  {
    label: 'Sales',
    link: '/sales',
  },
  {
    label: 'Deals',
    link: '/sales/deals',
  },
];

const DealsBrowse = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  // State
  const [isLoading, setIsLoading] = useState(false);
  const [relevantSalesPeople, setRelevantSalesPeople] = useState([]);
  const [relevantAgencies, setRelevantAgencies] = useState([]);
  const [filteredDeals, setFilteredDeals] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [salesPersonFilter, setSalesPersonFilter] = useState('ALL');
  const [agencyFilter, setAgencyFilter] = useState('ALL');
  const [statusFilter, setStatusFilter] = useState('ALL');
  const [dateTypeFilter, setDateTypeFilter] = useState('LAST_UPDATED');
  const [rangeFilter, setRangeFilter] = useState('THIS_MONTH');
  const [startDate, setStartDate] = useState(
    moment().startOf('month').toDate()
  );
  const [endDate, setEndDate] = useState(moment().toDate());

  // Selectors
  const agencies = useSelector((state) => state.deals.agencies);
  const deals = useSelector((state) => state.deals.dealsList);
  const salesAgents = useSelector((state) => state.deals.salesAgents);
  const userInfo = useSelector((state) => state.account.userInfo);
  const agenciesLoadingStatus = useSelector(
    (state) => state.deals.loadingStatus.agencies
  );
  const dealsLoadingStatus = useSelector(
    (state) => state.deals.loadingStatus.deals
  );
  const salesAgentsLoadingStatus = useSelector(
    (state) => state.deals.loadingStatus.salesAgents
  );

  // Effects
  useEffect(() => {
    if (userInfo.type === 'ADMIN' || userInfo.type === 'SALES_MANAGER') {
      dispatch(fetchDeals(dateTypeFilter, startDate, endDate, false));
      dispatch(fetchSalesAgents());
      dispatch(fetchAgencies());
    } else {
      dispatch(
        fetchDeals(dateTypeFilter, startDate, endDate, true, userInfo.uid)
      );
    }
    if (userInfo.type === 'SALES_MANAGER' && userInfo.agencyId) {
      setAgencyFilter(userInfo.agencyId);
    }
  }, []);

  useEffect(() => {
    if (
      dealsLoadingStatus === 'PENDING' ||
      salesAgentsLoadingStatus === 'PENDING' ||
      agenciesLoadingStatus === 'PENDING'
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [dealsLoadingStatus, salesAgentsLoadingStatus, agenciesLoadingStatus]);

  useEffect(() => {
    setRelevantSalesPeople(
      getRelevantSalesPeople(deals, agencyFilter, salesAgents)
    );
  }, [deals, agencyFilter, salesAgents]);

  useEffect(() => {
    setRelevantAgencies(agencies);
  }, [agencies]);

  useEffect(() => {
    changeDateRange(rangeFilter, setStartDate, setEndDate);
  }, [rangeFilter]);

  useEffect(() => {
    if (userInfo.type === 'ADMIN' || userInfo.type === 'SALES_MANAGER') {
      dispatch(fetchDeals(dateTypeFilter, startDate, endDate, false));
    } else {
      dispatch(
        fetchDeals(dateTypeFilter, startDate, endDate, true, userInfo.uid)
      );
    }
  }, [dateTypeFilter, startDate, endDate]);

  useEffect(() => {
    setFilteredDeals(
      getFilteredDeals(
        deals,
        salesAgents,
        userInfo,
        searchText,
        salesPersonFilter,
        agencyFilter,
        getAgencyAgents(agencyFilter, salesAgents),
        statusFilter
      )
    );
  }, [
    deals,
    salesAgents,
    userInfo,
    searchText,
    salesPersonFilter,
    agencyFilter,
    statusFilter,
  ]);

  return (
    <div>
      <PageHeader
        title="Browse Deals"
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Browse"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
        actions={[
          {
            label: 'Create new deal',
            icon: <AddIcon />,
            onClick: () => history.push('/sales/deals/create'),
          },
        ]}
      />
      {isLoading && <LinearProgress color="secondary" />}
      <ContentWrapper>
        <DateFilteringBar
          startDate={startDate}
          changeStartDate={setStartDate}
          endDate={endDate}
          changeEndDate={setEndDate}
          dateTypeId={dateTypeFilter}
          changeDateType={setDateTypeFilter}
          rangeId={rangeFilter}
          changeRange={setRangeFilter}
        />
        <FiltersBar
          searchText={searchText}
          changeSearchText={setSearchText}
          salesPerson={salesPersonFilter}
          changeSalesPerson={setSalesPersonFilter}
          salesPeople={relevantSalesPeople}
          agency={agencyFilter}
          changeAgency={setAgencyFilter}
          agencies={relevantAgencies}
          statusId={statusFilter}
          changeStatus={setStatusFilter}
          userType={userInfo.type}
          dealCount={filteredDeals.length}
        />
        {!isLoading && filteredDeals.length === 0 ? (
          <EmptyViewMessage
            heading="No Deals Available"
            message="Please adjust the filters or create new deals to view them here."
          />
        ) : (
          <Grid container spacing={3}>
            {filteredDeals.map((deal) => {
              return (
                <Grid key={deal.id} item xs={6} lg={4}>
                  <DealCard
                    dealName={deal.dealName ? deal.dealName : deal.businessName}
                    salesPerson={deal.salesPersonName}
                    dealValue={deal.dealValue}
                    platform={deal.platform}
                    status={deal.status}
                    creationDate={deal.creationDate}
                    lastUpdated={deal.lastUpdated}
                    dateType={dateTypeFilter}
                    dealType={deal.dealType || 'UNKNOWN'}
                    goToDeal={() =>
                      history.push(`/sales/deals/browse/${deal.id}`)
                    }
                    isSalesPersonDisplayed={userInfo.type !== 'SALES_AGENT'}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
      </ContentWrapper>
    </div>
  );
};

export default DealsBrowse;
