import { useEffect, useState } from 'react';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import numeral from 'numeral';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import TotalsBar from '../../../../../components/TotalsBar';

const useStyles = makeStyles((theme) => ({
  adornment: {
    color: grey[700],
  },
  formControl: {
    width: '100%',
    marginBottom: theme.spacing(4),
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  wrapperTotalsBar: {
    textAlign: 'center',
    margin: theme.spacing(6),
  },
}));

const FixPricingDialog = ({
  isOpen,
  code,
  productName,
  closeDialog,
  fixPricing,
  purchasePrice,
  retailPrice,
  platform,
  initalPaidToClient,
}) => {
  const classes = useStyles();
  const [type, setType] = useState('Unknown');
  const [adjustedRetailPrice, setAdjustedRetailPrice] = useState(0);
  const [paidToClient, setPaidToClient] = useState(0);
  const [discount, setDiscount] = useState(0);
  const isRefund = purchasePrice < 0;

  useEffect(() => {
    if (isOpen) {
      setType('UNKNOWN');
      setAdjustedRetailPrice(retailPrice);
      setPaidToClient(initalPaidToClient);
    }
  }, [isOpen]);

  useEffect(() => {
    if (
      adjustedRetailPrice === 0 ||
      adjustedRetailPrice === '' ||
      adjustedRetailPrice === null
    ) {
      setDiscount(undefined);
    } else {
      setDiscount((adjustedRetailPrice - purchasePrice) / adjustedRetailPrice);
    }
  }, [adjustedRetailPrice]);

  return (
    <Dialog open={isOpen} onClose={closeDialog}>
      <DialogTitle>{`${productName} [${code}]`}</DialogTitle>
      <DialogContent>
        <TotalsBar
          className={classes.wrapperTotalsBar}
          totals={[
            {
              label: isRefund ? 'Refund Value' : 'Purchase Price',
              value: `${platform === 'amazon' ? '$' : 'R'} ${numeral(
                purchasePrice
              ).format('0,0.00')}`,
            },
            {
              label: isRefund
                ? 'Standard Refund Value'
                : 'Standard Retail Price',
              value: `${platform === 'amazon' ? '$' : 'R'} ${numeral(
                adjustedRetailPrice
              ).format('0,0.00')}`,
            },
            {
              label: 'Discount',
              value:
                discount === undefined
                  ? '?'
                  : `${numeral(discount * 100).format('0,0.0')}%`,
            },
          ]}
        />
        <DialogContentText>
          Please enter the details for this purchase:
        </DialogContentText>
        <TextField
          className={classes.formControl}
          label={isRefund ? 'Standard Refund Value' : 'Standard Retail Price'}
          type="number"
          value={adjustedRetailPrice}
          onChange={(e) => setAdjustedRetailPrice(e.target.value)}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment disableTypography position="start">
                {platform === 'amazon' ? '$' : 'R'}
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          className={classes.formControl}
          label={
            isRefund
              ? 'Statement Deduction Per Unit'
              : 'Paid to Client Per Unit'
          }
          type="number"
          fullWidth
          value={paidToClient}
          onChange={(e) => setPaidToClient(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment disableTypography position="start">
                R
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <FormControl className={classes.formControl}>
          <InputLabel id="select-type">Classify As</InputLabel>
          <Select
            labelId="select-type-label"
            id="select-type"
            value={type}
            onChange={(e) => setType(e.target.value)}
          >
            <MenuItem value="STANDARD">
              {isRefund ? 'Standard Refund' : 'Standard Sale'}
            </MenuItem>
            <MenuItem value="DISCOUNTED">
              {isRefund ? 'Refund on Discounted Purchase' : 'Discounted Sale'}
            </MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Cancel</Button>
        <Button
          disabled={
            type === 'UNKNOWN' ||
            adjustedRetailPrice === null ||
            paidToClient === null ||
            adjustedRetailPrice === 0 ||
            paidToClient === 0 ||
            (isRefund && (adjustedRetailPrice > 0 || paidToClient > 0)) ||
            (!isRefund && (adjustedRetailPrice < 0 || paidToClient < 0))
          }
          variant="contained"
          color="secondary"
          onClick={() => {
            fixPricing(
              numeral(adjustedRetailPrice).value(),
              numeral(paidToClient).value(),
              type
            );
            closeDialog();
          }}
        >
          Save changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

FixPricingDialog.defaultProps = {
  isOpen: true,
  code: '12345678',
  productName: 'Nike - Yeezy Boost - Red',
  closeDialog: () => console.log('Dialog was closed.'),
  fixPricing: () => console.log('Pricing was fixed.'),
  purchasePrice: 18.99,
  retailPrice: 19.99,
  platform: 'amazon',
  initalPaidToClient: 100.0,
};

FixPricingDialog.propTypes = {
  isOpen: PropTypes.bool,
  code: PropTypes.string,
  productName: PropTypes.string,
  closeDialog: PropTypes.func,
  fixPricing: PropTypes.func,
  purchasePrice: PropTypes.number,
  retailPrice: PropTypes.number,
  platform: PropTypes.string,
  initalPaidToClient: PropTypes.number,
};

export default FixPricingDialog;
