import { green, red, yellow, grey } from '@material-ui/core/colors';
import { alpha } from '@material-ui/core/styles/colorManipulator';

const allStyles = (theme) => ({
  actionsBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  alert: {
    marginTop: theme.spacing(3),
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    maxHeight: '90vh',
    minHeight: '75vh',
    padding: theme.spacing(4),
  },
  desc: {
    color: theme.palette.common.black,
  },
  dropZone: {
    padding: theme.spacing(4),
    textAlign: 'center',
    width: '100%',
  },
  explanationWrapper: {
    color: grey[700],
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  formControl: {
    minWidth: 200,
    width: '100%',
    marginBottom: theme.spacing(4),
  },
  formControlLeft: {
    minWidth: 200,
    width: '40%',
    float: 'left',
    marginBottom: theme.spacing(4),
  },
  formControlRight: {
    minWidth: 200,
    width: '40%',
    float: 'right',
    marginBottom: theme.spacing(4),
  },
  formWrapper: {
    padding: `${theme.spacing(4)}px ${theme.spacing(1)}px`,
  },
  headerButtonIcon: {
    marginRight: theme.spacing(1),
  },
  hidden: {
    display: 'none',
  },
  icon: {
    color: theme.palette.common.black,
  },
  loadingIndicator: {
    marginBottom: theme.spacing(2),
  },
  loadingOverlay: {
    alignItems: 'center',
    backgroundColor: alpha(theme.palette.common.black, 0.8),
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
  loadingWrapper: {
    padding: theme.spacing(8),
    textAlign: 'center',
  },
  results: {
    margin: theme.spacing(1),
    color: grey[800],
  },
  root: {
    backgroundColor: theme.palette.common.white,
  },
  statusUpdated: {
    backgroundColor: green[800],
  },
  statusNotUpdated: {
    backgroundColor: red[800],
  },
  statusNotSubmitted: {
    backgroundColor: theme.palette.secondary.main,
  },
  typesenseSearchBar: {
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: '#fcfcfb',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.primary.light,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.light,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.light,
      },
    },
  },
  select: {
    icon: {
      color: theme.palette.common.black,
    },
  },
  separator: {
    height: theme.spacing(2),
  },
  seperatorButtons: {
    height: theme.spacing(1),
  },
  spiltLeftt: {
    height: '100%',
    width: '80%',
    position: 'fixed',
  },
  statusGreen: {
    backgroundColor: green[500],
    color: theme.palette.common.black,
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  statusRed: {
    backgroundColor: red[500],
    color: theme.palette.common.black,
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  statusYellow: {
    backgroundColor: yellow[500],
    color: theme.palette.common.black,
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  toggleButtonsGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: theme.spacing(1),
  },
  wrapperButtons: {
    display: 'flex-end',
    flexGrow: 1,
  },
  wrapperContent: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
  wrapperDialog: {
    padding: theme.spacing(4),
    display: 'flex',
  },
  wrapperRoot: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  wrapperStep: {
    padding: `${theme.spacing(4)}px 0px`,
  },
  wrapperWizard: {
    width: '100%',
    padding: theme.spacing(4),
  },
});

export function getCurrentDate(separator = '') {
  const newDate = new Date();
  const date = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();

  return `${date}${separator}${
    month < 10 ? `0${month}` : `${month}`
  }${separator}${year}`;
}

export default allStyles;
