import 'moment';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import DateFnsUtils from '@date-io/moment';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.primary.contrastText,
    margin: `0 ${theme.spacing(2)}px`,
  },
}));

const RangeSelector = ({
  startDate,
  changeStartDate,
  endDate,
  changeEndDate,
  rangeId,
  changeRange,
  ranges,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const getRangeLabel = (id) => {
    const rangeInfo = ranges.find((info) => info.id === id);
    if (!rangeInfo) {
      return 'Unknown';
    }

    return rangeInfo.label;
  };

  return (
    <>
      <Grid container alignItems="center">
        <Button
          aria-controls="range-menu"
          aria-haspopup="true"
          size="large"
          startIcon={<CalendarTodayIcon />}
          onClick={openMenu}
        >
          {getRangeLabel(rangeId)}
        </Button>
        <Menu
          id="range-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={closeMenu}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {ranges.map((info) => (
            <MenuItem
              key={info.id}
              onClick={() => {
                changeRange(info.id);
                closeMenu();
              }}
            >
              {info.label}
            </MenuItem>
          ))}
        </Menu>
        {rangeId === 'CUSTOM' && (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Typography
              className={classes.text}
              variant="subtitle1"
              component="p"
            >
              |
            </Typography>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/DD/YYYY"
              id="date-picker-start"
              value={startDate}
              onChange={(date) => changeStartDate(date.toDate())}
              maxDate={endDate}
              KeyboardButtonProps={{
                'aria-label': 'change start date',
              }}
            />
            <Typography
              className={classes.text}
              variant="subtitle1"
              component="p"
            >
              to
            </Typography>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/DD/YYYY"
              id="date-picker-end"
              value={endDate}
              onChange={(date) => changeEndDate(date.toDate())}
              minDate={startDate}
              maxDate={new Date()}
              KeyboardButtonProps={{
                'aria-label': 'change end date',
              }}
            />
          </MuiPickersUtilsProvider>
        )}
      </Grid>
    </>
  );
};

RangeSelector.defaultProps = {
  startDate: new Date(),
  changeStartDate: (newValue) =>
    `Start date was changed to '${newValue.toString()}'`,
  endDate: new Date(),
  changeEndDate: (newValue) =>
    `End date was changed to '${newValue.toString()}'`,
  rangeId: 'TODAY',
  changeRange: (newValue) => `Range was changed to '${newValue}'`,
  ranges: [
    {
      id: 'LAST_10_DAYS',
      label: 'Past 10 Days',
    },
    {
      id: 'TODAY',
      label: 'Today',
    },
    {
      id: 'THIS_MONTH',
      label: 'This Month',
    },
    {
      id: 'THIS_WEEK',
      label: 'This Week',
    },
    {
      id: 'THIS_YEAR',
      label: 'This Year',
    },
    {
      id: 'CUSTOM',
      label: 'Custom',
    },
  ],
};

RangeSelector.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  changeStartDate: PropTypes.func,
  endDate: PropTypes.instanceOf(Date),
  changeEndDate: PropTypes.func,
  rangeId: PropTypes.string,
  changeRange: PropTypes.func,
  ranges: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};

export default RangeSelector;
