/* eslint-disable no-param-reassign */
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {
  fetchClients,
  fetchShipmentInfo,
  fetchSkus,
  fetchWarehouses,
  initiateStateReset,
  updateShipment,
} from './slice';
import { getFilteredSkus } from './logic';
import getStyles from './styles';
import ContentWrapper from '../../../components/ContentWrapper';
import FiltersBar from './components/FiltersBar';
import PageHeader from '../../../components/PageHeader';
import ProductSelectionTable from './components/ProductSelectionTable';
import SKUUnitTable from './components/SKUUnitTable';

const useStyles = makeStyles((theme) => getStyles(theme));

const breadcrumbs = [
  {
    label: 'Logistics',
    link: '/logistics',
  },
  {
    label: 'Shipments',
    link: '/logistics/shipments',
  },
];

const LogisticsShipmentEdit = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { shipmentId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [filteredSkus, setFilteredSkus] = useState([]);
  const [selectedShipmentFromId, setSelectedShipmentFromId] = useState('');
  const [selectedShipmentToId, setSelectedShipmentToId] = useState('');
  const [selectedShipmentClientId, setSelectedShipmentClientId] = useState('');
  const [shipmentCost, setShipmentCost] = useState(0);
  const [selectedShipmentSkus, setSelectedShipmentSkus] = useState([]);
  const [skuSearchText, setSkuSearchText] = useState('');
  const [shipmentSkuSearch, setShipmentSkuSearch] = useState('');
  const [filteredShipmentSkus, setFilteredShipmentSkus] = useState([]);

  const [validation, setValidation] = useState({
    isErrorShowing: false,
    message: '',
    errorStep: 0,
  });

  const warehousesLoadingStatus = useSelector(
    (state) => state.shipmentEdit.loadingStatus.warehouses
  );
  const clientsLoadingStatus = useSelector(
    (state) => state.shipmentEdit.loadingStatus.clients
  );
  const skusLoadingStatus = useSelector(
    (state) => state.shipmentEdit.loadingStatus.skus
  );
  const updatingLoadingStatus = useSelector(
    (state) => state.shipmentEdit.loadingStatus.updating
  );
  const shipmentInfoLoadingStatus = useSelector(
    (state) => state.shipmentEdit.loadingStatus.shipmentInfo
  );
  const isUserRedirectedToBrowse = useSelector(
    (state) => state.shipmentEdit.isUserRedirectedToBrowse
  );

  const clients = useSelector((state) => state.shipmentEdit.clients);
  const warehouses = useSelector((state) => state.shipmentEdit.warehouses);
  const skus = useSelector((state) => state.shipmentEdit.skus);
  const shipmentInfo = useSelector((state) => state.shipmentEdit.shipmentInfo);

  useEffect(() => {
    dispatch(fetchClients());
    dispatch(fetchShipmentInfo(shipmentId));
    if (warehousesLoadingStatus !== 'COMPLETE') {
      dispatch(fetchWarehouses());
    }
    if (skusLoadingStatus !== 'COMPLETE') {
      dispatch(fetchSkus());
    }
  }, []);

  useEffect(() => {
    setSelectedShipmentFromId(shipmentInfo.fromId);
    setSelectedShipmentToId(shipmentInfo.toId);
    setShipmentCost(shipmentInfo.cost);
    setSelectedShipmentClientId(shipmentInfo.clientId);
    setSelectedShipmentSkus(
      shipmentInfo.skus.map((skuInfo) => {
        let unfrozenSku = {};
        unfrozenSku = {
          sku: skuInfo.sku,
          tsin: skuInfo.tsin,
          childAsin: skuInfo.childAsin,
          product: skuInfo.product,
          units: skuInfo.units,
        };
        return unfrozenSku;
      })
    );
  }, [shipmentInfo]);

  useEffect(() => {
    setFilteredSkus(
      getFilteredSkus(skus, skuSearchText, selectedShipmentClientId)
    );
  }, [skus, skuSearchText, selectedShipmentClientId]);

  useEffect(() => {
    setFilteredShipmentSkus(
      getFilteredSkus(
        selectedShipmentSkus,
        shipmentSkuSearch,
        selectedShipmentClientId
      )
    );
  }, [selectedShipmentSkus, shipmentSkuSearch, selectedShipmentClientId]);

  useEffect(() => {
    if (
      clientsLoadingStatus === 'PENDING' ||
      warehousesLoadingStatus === 'PENDING' ||
      skusLoadingStatus === 'PENDING' ||
      updatingLoadingStatus === 'PENDING' ||
      shipmentInfoLoadingStatus === 'PENDING'
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [
    clientsLoadingStatus,
    warehousesLoadingStatus,
    skusLoadingStatus,
    updatingLoadingStatus,
    shipmentInfoLoadingStatus,
  ]);

  useEffect(() => {
    if (isUserRedirectedToBrowse) {
      dispatch(initiateStateReset());
      setIsLoading(true);
      history.goBack();
    }
  }, [isUserRedirectedToBrowse]);

  const getFromWarehouseList = () => {
    const warehouseItems = [
      ...warehouses
        .filter(() => true)
        .sort((a, b) => {
          if (a.name > b.name) return +1;
          if (a.name < b.name) return -1;
          return 0;
        })
        .map((warehouse) => ({
          value: warehouse.id,
          label: warehouse.name,
        })),
      {
        value: 'CLIENT',
        label: 'Client',
      },
    ];

    return warehouseItems
      .filter((item) => item.value !== selectedShipmentToId)
      .map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ));
  };

  const getToWarehouseList = () => {
    const warehouseItems = [
      ...warehouses
        .filter(() => true)
        .sort((a, b) => {
          if (a.name > b.name) return +1;
          if (a.name < b.name) return -1;
          return 0;
        })
        .map((warehouse) => ({
          value: warehouse.id,
          label: warehouse.name,
        })),
      {
        value: 'CLIENT',
        label: 'Client',
      },
    ];

    return warehouseItems
      .filter((item) => item.value !== selectedShipmentFromId)
      .map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ));
  };

  const getClientList = () => {
    return clients
      .filter((clientInfo) => clientInfo.status === 'HIGH_PRIORITY')
      .sort((a, b) => {
        if (a.name > b.name) return +1;
        if (a.name < b.name) return -1;
        return 0;
      })
      .map((clientInfo) => (
        <MenuItem key={clientInfo.id} value={clientInfo.id}>
          {clientInfo.name}
        </MenuItem>
      ));
  };

  const checkIfSelected = (sku) => {
    const relevantSku = selectedShipmentSkus.find(
      (selectedSkuInfo) => selectedSkuInfo.sku === sku
    );

    if (relevantSku) {
      return true;
    }

    return false;
  };

  const getSelectedSkuUnits = (sku) => {
    const relevantSku = selectedShipmentSkus.find(
      (selectedSkuInfo) => selectedSkuInfo.sku === sku
    );

    return relevantSku ? relevantSku.units || 0 : 0;
  };

  const getFormattedSkus = () => {
    return filteredSkus
      .map((skuInfo, index) => ({
        sku: skuInfo.sku,
        childAsin: skuInfo.amazon.childAsin,
        tsin: skuInfo.takealot.tsin,
        description:
          skuInfo.variation === 'N/A'
            ? skuInfo.productName
            : `${skuInfo.productName} (${skuInfo.variation})`,
        brand: skuInfo.brand,
        units: getSelectedSkuUnits(skuInfo.sku),
        tableData: {
          id: index,
          checked: checkIfSelected(skuInfo.sku),
        },
      }))
      .filter((skuInfo) => {
        if (
          selectedShipmentFromId === 'baGsBI92BgjNEB9r0tPT' ||
          selectedShipmentToId === 'baGsBI92BgjNEB9r0tPT'
        ) {
          if (skuInfo.childAsin) {
            return true;
          }
          return false;
        }
        if (
          selectedShipmentFromId === 'ktwmpFt2DUXU1wsC59EJ' ||
          selectedShipmentToId === 'ktwmpFt2DUXU1wsC59EJ' ||
          selectedShipmentFromId === 't6NDHxRtKUiOhmv64ptz' ||
          selectedShipmentToId === 't6NDHxRtKUiOhmv64ptz'
        ) {
          if (skuInfo.tsin) {
            return true;
          }
          return false;
        }
        return true;
      });
  };

  const getWarehouseName = (warehouseId) => {
    const warehouseInfo = warehouses.find((info) => info.id === warehouseId);
    if (warehouseInfo === undefined) {
      return '';
    }
    return warehouseInfo.name;
  };

  const getClientName = (clientId) => {
    const clientInfo = clients.find((info) => info.id === clientId);
    if (clientInfo === undefined) {
      return '';
    }
    return clientInfo.name;
  };

  const onNextClick = () => {
    switch (activeStep) {
      case 0:
        if (selectedShipmentFromId === '' || selectedShipmentToId === '') {
          setValidation({
            isErrorShowing: true,
            message: 'Please complete all required fields.',
            errorStep: 0,
          });
        } else if (
          selectedShipmentClientId === '' &&
          (selectedShipmentToId === 'CLIENT' ||
            selectedShipmentFromId === 'CLIENT')
        ) {
          setValidation({
            isErrorShowing: true,
            message: 'Please select a client.',
            errorStep: 0,
          });
        } else {
          setValidation({
            isErrorShowing: false,
            message: '',
            errorStep: 0,
          });
          setActiveStep(1);
        }
        break;
      case 1:
        if (selectedShipmentSkus.length === 0) {
          setValidation({
            isErrorShowing: true,
            message: 'Please select at least one sku.',
            errorStep: 1,
          });
        } else {
          setValidation({
            isErrorShowing: false,
            message: '',
            errorStep: 1,
          });
          setActiveStep(2);
        }
        break;
      case 2:
        dispatch(
          updateShipment(shipmentId, {
            lastUpdated: new Date(),
            cost: shipmentCost,
            fromId: selectedShipmentFromId,
            fromName: getWarehouseName(selectedShipmentFromId),
            toId: selectedShipmentToId,
            toName: getWarehouseName(selectedShipmentToId),
            clientId: selectedShipmentClientId,
            clientName: getClientName(selectedShipmentClientId),
            totalUnits: selectedShipmentSkus.reduce(
              (total, current) => total + current.units,
              0
            ),
            totalSkus: selectedShipmentSkus.length,
            skus: selectedShipmentSkus.reduce((finalSkus, currentSku) => {
              finalSkus[currentSku.sku] = {
                sku: currentSku.sku,
                tsin: currentSku.tsin,
                childAsin: currentSku.childAsin,
                product: currentSku.product,
                units: currentSku.units,
              };
              return finalSkus;
            }, {}),
          })
        );
        break;
      default:
        console.error('Unknown step');
        break;
    }
  };

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <div className={classes.wrapperStep}>
            <Typography variant="h4" gutterBottom>
              Enter Shipment&apos;s information
            </Typography>
            <Typography variant="body1">
              Please complete all fields below.
            </Typography>
            <form className={classes.formWrapper}>
              <FormControl className={classes.formControl} required>
                <InputLabel shrink id="shipment-source-select-label">
                  Shipment From
                </InputLabel>
                <Select
                  labelId="shipment-source-select-label"
                  id="shipment-source-select"
                  value={selectedShipmentFromId}
                  onChange={(e) => {
                    setSelectedShipmentFromId(e.target.value);
                    if (
                      e.target.value !== 'CLIENT' &&
                      selectedShipmentToId !== 'CLIENT'
                    ) {
                      setSelectedShipmentClientId('');
                    }
                  }}
                >
                  {getFromWarehouseList()}
                </Select>
              </FormControl>
              <div className={classes.separator} />
              <FormControl className={classes.formControl} required>
                <InputLabel shrink id="shipment-destination-select-label">
                  Shipment To
                </InputLabel>
                <Select
                  labelId="shipment-destination-select-label"
                  id="shipment-destination-select"
                  value={selectedShipmentToId}
                  onChange={(e) => {
                    setSelectedShipmentToId(e.target.value);
                    if (
                      e.target.value !== 'CLIENT' &&
                      selectedShipmentFromId !== 'CLIENT'
                    ) {
                      setSelectedShipmentClientId('');
                    }
                  }}
                >
                  {getToWarehouseList()}
                </Select>
              </FormControl>
              <div className={classes.separator} />
              {(selectedShipmentToId === 'CLIENT' ||
                selectedShipmentFromId === 'CLIENT') && (
                <FormControl className={classes.formControl}>
                  <InputLabel shrink id="shipment-client-select-label">
                    Client
                  </InputLabel>
                  <Select
                    labelId="shipment-client-select-label"
                    id="shipment-client-select"
                    value={selectedShipmentClientId}
                    onChange={(e) =>
                      setSelectedShipmentClientId(e.target.value)
                    }
                  >
                    {getClientList()}
                  </Select>
                </FormControl>
              )}
              <div className={classes.separator} />
              <TextField
                label="Cost"
                fullWidth
                value={shipmentCost}
                onChange={(e) =>
                  setShipmentCost(
                    Number.isNaN(parseFloat(e.target.value))
                      ? null
                      : parseFloat(e.target.value)
                  )
                }
                type="number"
                min={0}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </form>
            {validation.isErrorShowing && validation.errorStep === 0 && (
              <Alert className={classes.alert} severity="error">
                {validation.message}
              </Alert>
            )}
          </div>
        );
      case 1:
        return (
          <div className={classes.wrapperStep}>
            <Typography variant="h4" gutterBottom>
              Select Shipment&apos;s SKUs
            </Typography>
            <Typography variant="body1">
              Please select all SKUs that should be associated with this
              shipment.
            </Typography>
            <div className={classes.separator} />
            <FiltersBar
              searchText={skuSearchText}
              changeSearchText={setSkuSearchText}
              skuCount={filteredSkus.length}
            />
            <ProductSelectionTable
              skus={getFormattedSkus()}
              platform={
                selectedShipmentFromId === 'baGsBI92BgjNEB9r0tPT' ||
                selectedShipmentToId === 'baGsBI92BgjNEB9r0tPT'
                  ? 'AMAZON'
                  : 'TAKEALOT'
              }
              updateSelectedProducts={setSelectedShipmentSkus}
            />
            {validation.isErrorShowing && validation.errorStep === 1 && (
              <Alert className={classes.alert} severity="error">
                {validation.message}
              </Alert>
            )}
          </div>
        );
      case 2:
        return (
          <div className={classes.wrapperStep}>
            <Typography variant="h4" gutterBottom>
              Add Shipment SKU units
            </Typography>
            <Typography variant="body1">
              Please indicate the amount of each SKU that would like to add to
              this shipment.
            </Typography>
            <div className={classes.separator} />
            <FiltersBar
              searchText={shipmentSkuSearch}
              changeSearchText={setShipmentSkuSearch}
              skuCount={filteredShipmentSkus.length}
            />
            <SKUUnitTable
              skus={filteredShipmentSkus}
              platform={
                selectedShipmentFromId === 'baGsBI92BgjNEB9r0tPT' ||
                selectedShipmentToId === 'baGsBI92BgjNEB9r0tPT'
                  ? 'AMAZON'
                  : 'TAKEALOT'
              }
              updateSkus={setSelectedShipmentSkus}
            />
          </div>
        );
      default:
        return <div />;
    }
  };

  return (
    <div>
      <PageHeader
        isLoading={isLoading}
        title="Edit Shipment"
        subtitle={shipmentId}
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Edit"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
      />
      <ContentWrapper>
        <Paper className={classes.wrapperWizard}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Stepper activeStep={activeStep} orientation="vertical">
                <Step>
                  <StepLabel>Basic Info</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Stock Info</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Unit Quantities</StepLabel>
                </Step>
              </Stepper>
            </Grid>
            <Grid item xs={9}>
              {getStepContent()}
              <div className={classes.actionsBar}>
                <Button
                  disabled={activeStep === 0 || isLoading}
                  onClick={() => setActiveStep(activeStep - 1)}
                >
                  Previous
                </Button>
                <Button
                  disabled={isLoading}
                  variant="contained"
                  color="secondary"
                  onClick={onNextClick}
                >
                  {activeStep !== 2 ? 'Next' : 'Update'}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </ContentWrapper>
    </div>
  );
};

export default LogisticsShipmentEdit;
