/* eslint-disable react/jsx-wrap-multilines */
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import takealotCategoryData from './takealotCategories';
import DateTypeSelector from './components/DateTypeSelector';

const useStyles = makeStyles((theme) => ({
  adornment: {
    color: theme.palette.primary.light,
  },
  alert: {
    marginBottom: theme.spacing(3),
  },
  dateTimePickerWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  section: {
    margin: `${theme.spacing(4)}px 0`,
  },
}));

const EditPricingDialog = ({
  isOpen,
  closeDialog,
  updatePricingInfo,
  skuInfo,
  platform,
}) => {
  const classes = useStyles();

  const [isCalculatedPricingOn, setIsCalculatedPricingOn] = useState(true);
  const [department, setDepartment] = useState('');
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [weight, setWeight] = useState(undefined);
  const [length, setLength] = useState(undefined);
  const [width, setWidth] = useState(undefined);
  const [height, setHeight] = useState(undefined);
  const [wholesalePrice, setWholesalePrice] = useState(undefined);
  const [costPrice, setCostPrice] = useState(undefined);
  const [retailPrice, setRetailPrice] = useState(undefined);
  const [errors, setErrors] = useState({
    categories: false,
    measurements: false,
  });
  const [dateType, setDateType] = useState('NOW');
  const [dateTime, setDateTime] = useState(moment().format('YYYY-MM-DDTHH:mm'));

  useEffect(() => {
    if (platform === 'AMAZON' && skuInfo.amazon) {
      if (skuInfo.amazon.categories) {
        setDepartment(skuInfo.amazon.categories.department);
        setCategory(skuInfo.amazon.categories.category);
        setSubCategory(skuInfo.amazon.categories.subCategory);
      }
    } else if (skuInfo.takealot && skuInfo.takealot.categories) {
      setDepartment(skuInfo.takealot.categories.department);
      setCategory(skuInfo.takealot.categories.category);
      setSubCategory(skuInfo.takealot.categories.subCategory);
    } else {
      setDepartment('');
      setCategory('');
      setSubCategory('');
    }
    if (skuInfo.measurements) {
      setWeight(skuInfo.measurements.weight);
      setLength(skuInfo.measurements.length);
      setWidth(skuInfo.measurements.width);
      setHeight(skuInfo.measurements.height);
    } else {
      setWeight(undefined);
      setLength(undefined);
      setWidth(undefined);
      setHeight(undefined);
    }
    if (platform === 'TAKEALOT' && skuInfo.takealot) {
      setIsCalculatedPricingOn(true);
      setDateType('NOW');
      setDateTime(moment().format('YYYY-MM-DDTHH:mm'));
      setWholesalePrice(skuInfo.takealot.wholesalePriceRands);
      setRetailPrice(skuInfo.takealot.retailPriceRands);
      if (!skuInfo.takealot.costPriceRands) {
        setCostPrice(skuInfo.takealot.wholesalePriceRands / 2);
      } else {
        setCostPrice(skuInfo.takealot.costPriceRands);
      }
    } else if (skuInfo.amazon) {
      setIsCalculatedPricingOn(false);
      setDateType('NOW');
      setDateTime(moment().format('YYYY-MM-DDTHH:mm'));
      setWholesalePrice(skuInfo.amazon.wholesalePriceRands);
      setRetailPrice(skuInfo.amazon.retailPriceDollars);
      if (!skuInfo.amazon.costPriceRands) {
        setCostPrice(skuInfo.amazon.wholesalePriceRands / 2);
      } else {
        setCostPrice(skuInfo.amazon.costPriceRands);
      }
    }
  }, [skuInfo]);

  useEffect(() => {
    if (platform === 'AMAZON') {
      setIsCalculatedPricingOn(false);
    }
  }, [platform]);

  const getDepartments = () => {
    return _.uniq(takealotCategoryData.map((data) => data.department))
      .filter((data) => data !== '')
      .sort((departmentA, departmentB) => {
        if (departmentA > departmentB) return +1;
        if (departmentA < departmentB) return -1;
        return 0;
      });
  };

  const getCategories = () => {
    return _.uniq(
      takealotCategoryData
        .filter((data) => data.department === department)
        .map((data) => data.category)
    ).sort((categoryA, categoryB) => {
      if (categoryA > categoryB) return +1;
      if (categoryA < categoryB) return -1;
      return 0;
    });
  };

  const getSubCategories = () => {
    return _.uniq(
      takealotCategoryData
        .filter(
          (data) => data.department === department && data.category === category
        )
        .map((data) => data.subCategory)
    ).sort((subCategoryA, subCategoryB) => {
      if (subCategoryA > subCategoryB) return +1;
      if (subCategoryA < subCategoryB) return -1;
      return 0;
    });
  };

  const getSizeCategory = (volume) => {
    if (volume <= 35000) {
      return 'Standard';
    }
    if (volume <= 130000) {
      return 'Large';
    }
    if (volume <= 200000) {
      return 'Oversize';
    }
    if (volume <= 545000) {
      return 'Bulky';
    }
    return 'Extra Bulky';
  };

  const getWeightCategory = () => {
    if (weight <= 7) {
      return 'Light';
    }
    if (weight <= 25) {
      return 'Heavy';
    }
    if (weight <= 40) {
      return 'Heavy Plus';
    }
    return 'Very Heavy';
  };

  const getFulfilmentFee = (sizeCategory, weightCategory) => {
    switch (sizeCategory) {
      case 'Standard':
        switch (weightCategory) {
          case 'Light':
            return 32;
          case 'Heavy':
            return 36;
          case 'Heavy Plus':
            return 85;
          case 'Very Heavy':
            return 85;
          default:
            return 0;
        }
      case 'Large':
        switch (weightCategory) {
          case 'Light':
            return 42;
          case 'Heavy':
            return 46;
          case 'Heavy Plus':
            return 85;
          case 'Very Heavy':
            return 95;
          default:
            return 0;
        }
      case 'Oversize':
        switch (weightCategory) {
          case 'Light':
            return 95;
          case 'Heavy':
            return 105;
          case 'Heavy Plus':
            return 125;
          case 'Very Heavy':
            return 100;
          default:
            return 0;
        }
      case 'Bulky':
        switch (weightCategory) {
          case 'Light':
            return 95;
          case 'Heavy':
            return 115;
          case 'Heavy Plus':
            return 130;
          case 'Very Heavy':
            return 140;
          default:
            return 0;
        }
      case 'Extra Bulky':
        switch (weightCategory) {
          case 'Light':
            return 220;
          case 'Heavy':
            return 220;
          case 'Heavy Plus':
            return 270;
          case 'Very Heavy':
            return 325;
          default:
            return 0;
        }
      default:
        return 0;
    }
  };

  const calculateRetailPrice = (flatFees, margins, givenWholesalePrice) => {
    return (flatFees + givenWholesalePrice) / (1 - margins);
  };

  const calculateWholesalePrice = (
    flatFees,
    insurancePercentage,
    margins,
    givenRetailPrice
  ) => {
    return (
      (givenRetailPrice * (1 - margins) - flatFees) /
      (1 + 0.5 * insurancePercentage)
    );
  };

  const getSuccessFeePercentage = () => {
    const relevantSubCategory = takealotCategoryData.find(
      (data) =>
        data.department === department &&
        data.category === category &&
        data.subCategory === subCategory
    );
    return relevantSubCategory.successFee || 0;
  };

  const updateBasedOnRetailPricing = (newRetailPrice) => {
    const volume = length * width * height;
    const insurancePercentage = 0.0075;
    const handlingFee = 5;

    const sizeCategory = getSizeCategory(volume);
    const weightCategory = getWeightCategory(weight);
    const fulfilmentFee = getFulfilmentFee(sizeCategory, weightCategory);
    const successFeePercentage =
      getSuccessFeePercentage(department, category, subCategory) * 1.15;
    const fulfilmentFeeVat = fulfilmentFee * 1.15;
    const easyonlineMarginPercentage = 0.1;
    const totalMargin = successFeePercentage + easyonlineMarginPercentage;

    const newWholesalePrice = calculateWholesalePrice(
      fulfilmentFeeVat + handlingFee,
      insurancePercentage,
      totalMargin,
      parseInt(newRetailPrice, 10)
    );
    const newCostPrice = newWholesalePrice / 2;

    setWholesalePrice(newWholesalePrice.toFixed(2));
    setCostPrice(newCostPrice.toFixed(2));
  };

  const updateBasedOnWholesalePricing = (newWholesalePrice, newCostPrice) => {
    const volume = length * width * height;
    const insurancePercentage = 0.0075;
    const handlingFee = 5;

    const sizeCategory = getSizeCategory(volume);
    const weightCategory = getWeightCategory(weight);
    const fulfilmentFee = getFulfilmentFee(sizeCategory, weightCategory);
    const successFeePercentage =
      getSuccessFeePercentage(department, category, subCategory) * 1.15;
    const fulfilmentFeeVat = fulfilmentFee * 1.15;
    const easyonlineMarginPercentage = 0.1;
    const totalMargin = successFeePercentage + easyonlineMarginPercentage;

    const insuranceFee = newCostPrice * insurancePercentage;
    const newRetailPrice = Math.ceil(
      calculateRetailPrice(
        fulfilmentFeeVat + insuranceFee + handlingFee,
        totalMargin,
        parseFloat(newWholesalePrice)
      )
    );

    setRetailPrice(newRetailPrice);
  };

  const updateBasedOnCostPricing = (newCostPrice) => {
    const volume = length * width * height;
    const insurancePercentage = 0.0075;
    const handlingFee = 5;

    const sizeCategory = getSizeCategory(volume);
    const weightCategory = getWeightCategory(weight);
    const fulfilmentFee = getFulfilmentFee(sizeCategory, weightCategory);
    const successFeePercentage =
      getSuccessFeePercentage(department, category, subCategory) * 1.15;
    const fulfilmentFeeVat = fulfilmentFee * 1.15;
    const easyonlineMarginPercentage = 0.1;
    const totalMargin = successFeePercentage + easyonlineMarginPercentage;

    const insuranceFee = parseFloat(newCostPrice) * insurancePercentage;
    const newRetailPrice = Math.ceil(
      calculateRetailPrice(
        fulfilmentFeeVat + insuranceFee + handlingFee,
        totalMargin,
        parseFloat(wholesalePrice)
      )
    );

    setRetailPrice(newRetailPrice);
  };

  const checkIsInfoComplete = () => {
    const isCategoriesComplete =
      !isCalculatedPricingOn ||
      (department.length > 0 && category.length > 0 && subCategory.length > 0);
    const isMeasurementsComplete =
      !isCalculatedPricingOn ||
      (weight > 0 && length > 0 && width > 0 && height > 0);

    setErrors({
      categories: !isCategoriesComplete,
      measurements: !isMeasurementsComplete,
    });

    return {
      categories: isCategoriesComplete,
      measurements: isMeasurementsComplete,
    };
  };

  return (
    <Dialog
      fullWidth
      open={isOpen}
      onClose={closeDialog}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
    >
      <DialogTitle id="confirmation-dialog-title">Adjust Prices</DialogTitle>
      <DialogContent>
        {platform === 'TAKEALOT' && (
          <>
            <div className={classes.dateTimePickerWrapper}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isCalculatedPricingOn}
                    onChange={() =>
                      setIsCalculatedPricingOn(!isCalculatedPricingOn)
                    }
                    name="calculatedPricingToggle"
                  />
                }
                label="Calculate New Prices"
              />
              <DateTypeSelector
                dateTypeId={dateType}
                changeDateType={setDateType}
              />
            </div>
            {dateType === 'LATER' && (
              <div className={classes.dateTimePickerWrapper}>
                <TextField
                  id="datetime-local"
                  label="Update date and time"
                  type="datetime-local"
                  value={dateTime}
                  onChange={(e) =>
                    setDateTime(
                      moment(e.target.value).format('YYYY-MM-DDTHH:mm')
                    )
                  }
                  className={classes.dateTimePicker}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: moment().format('YYYY-MM-DDTHH:mm'),
                  }}
                />
              </div>
            )}
          </>
        )}
        {platform === 'TAKEALOT' && isCalculatedPricingOn && (
          <div className={classes.section}>
            <Typography variant="h5" component="h5" gutterBottom>
              Categories
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id="department-select-label">
                    Department
                  </InputLabel>
                  <Select
                    labelId="department-select-label"
                    id="department-select"
                    label="Department"
                    value={department}
                    onChange={(e) => {
                      setDepartment(e.target.value);
                      setCategory(undefined);
                      setSubCategory(undefined);
                    }}
                  >
                    {getDepartments().map((departmentItem) => (
                      <MenuItem
                        key={`department-${departmentItem}`}
                        value={departmentItem}
                      >
                        {departmentItem}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id="category-select-label">Category</InputLabel>
                  <Select
                    labelId="category-select-label"
                    id="category-select"
                    disabled={department === undefined}
                    value={category}
                    onChange={(e) => {
                      setCategory(e.target.value);
                      setSubCategory(undefined);
                    }}
                  >
                    {getCategories().map((categoryItem) => (
                      <MenuItem
                        key={`category-${categoryItem}`}
                        value={categoryItem}
                      >
                        {categoryItem}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id="subcategory-select-label">
                    Sub-Category
                  </InputLabel>
                  <Select
                    labelId="subcategory-select-label"
                    id="subcategory-select"
                    disabled={category === undefined}
                    value={subCategory}
                    onChange={(e) => setSubCategory(e.target.value)}
                  >
                    {getSubCategories().map((categoryItem) => (
                      <MenuItem
                        key={`category-${categoryItem}`}
                        value={categoryItem}
                      >
                        {categoryItem}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>
        )}
        {platform === 'TAKEALOT' && isCalculatedPricingOn && (
          <div className={classes.section}>
            <Typography variant="h5" component="h5" gutterBottom>
              Measurements
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <TextField
                  label="Weight"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        classes={{
                          positionEnd: classes.adornment,
                        }}
                        disableTypography
                        position="end"
                      >
                        kg
                      </InputAdornment>
                    ),
                  }}
                  type="number"
                  min={0}
                  value={weight}
                  onChange={(e) => setWeight(parseFloat(e.target.value))}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Length"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        classes={{
                          positionEnd: classes.adornment,
                        }}
                        disableTypography
                        position="end"
                      >
                        cm
                      </InputAdornment>
                    ),
                  }}
                  min={0}
                  type="number"
                  value={length}
                  onChange={(e) => setLength(parseFloat(e.target.value))}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Width"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        classes={{
                          positionEnd: classes.adornment,
                        }}
                        disableTypography
                        position="end"
                      >
                        cm
                      </InputAdornment>
                    ),
                  }}
                  min={0}
                  type="number"
                  value={width}
                  onChange={(e) => setWidth(parseFloat(e.target.value))}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Height"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        classes={{
                          positionEnd: classes.adornment,
                        }}
                        disableTypography
                        position="end"
                      >
                        cm
                      </InputAdornment>
                    ),
                  }}
                  min={0}
                  type="number"
                  value={height}
                  onChange={(e) => setHeight(parseFloat(e.target.value))}
                />
              </Grid>
            </Grid>
          </div>
        )}
        <div className={classes.section}>
          <Typography variant="h5" component="h5" gutterBottom>
            Prices
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                label="Wholesale Price"
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      classes={{
                        positionStart: classes.adornment,
                      }}
                      disableTypography
                      position="start"
                    >
                      R
                    </InputAdornment>
                  ),
                }}
                type="number"
                value={wholesalePrice}
                onChange={(e) => {
                  const isInfoComplete = checkIsInfoComplete();

                  if (
                    isInfoComplete.categories &&
                    isInfoComplete.measurements
                  ) {
                    setWholesalePrice(parseFloat(e.target.value));
                    setCostPrice(parseFloat(e.target.value / 2));
                    if (isCalculatedPricingOn) {
                      updateBasedOnWholesalePricing(
                        parseFloat(e.target.value),
                        parseFloat(e.target.value / 2)
                      );
                    }
                  }
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Cost Price"
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      classes={{
                        positionStart: classes.adornment,
                      }}
                      disableTypography
                      position="start"
                    >
                      R
                    </InputAdornment>
                  ),
                }}
                type="number"
                value={costPrice}
                onChange={(e) => {
                  const isInfoComplete = checkIsInfoComplete();

                  if (
                    isInfoComplete.categories &&
                    isInfoComplete.measurements
                  ) {
                    setCostPrice(parseFloat(e.target.value));
                    if (isCalculatedPricingOn) {
                      updateBasedOnCostPricing(parseFloat(e.target.value));
                    }
                  }
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Retail Price"
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      classes={{
                        positionStart: classes.adornment,
                      }}
                      disableTypography
                      position="start"
                    >
                      {platform === 'TAKEALOT' ? 'R' : '$'}
                    </InputAdornment>
                  ),
                }}
                type="number"
                value={retailPrice}
                onChange={(e) => {
                  const isInfoComplete = checkIsInfoComplete();

                  if (
                    isInfoComplete.categories &&
                    isInfoComplete.measurements
                  ) {
                    setRetailPrice(parseFloat(e.target.value));
                    if (isCalculatedPricingOn) {
                      updateBasedOnRetailPricing(parseFloat(e.target.value));
                    }
                  }
                }}
              />
            </Grid>
          </Grid>
        </div>
        {errors.categories && (
          <Alert className={classes.alert} severity="error">
            Please select a department, category and sub-category for this SKU.
          </Alert>
        )}
        {errors.measurements && (
          <Alert className={classes.alert} severity="error">
            Please specify the weight, length, width and height of this SKU.
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary" autoFocus>
          Close
        </Button>
        <Button
          disabled={errors.categories || errors.measurements}
          onClick={() => {
            updatePricingInfo(
              isCalculatedPricingOn,
              {
                department,
                category,
                subCategory,
              },
              {
                weight: parseFloat(weight.toFixed(2)),
                length: parseFloat(length.toFixed(2)),
                width: parseFloat(width.toFixed(2)),
                height: parseFloat(height.toFixed(2)),
              },
              {
                wholesalePrice,
                costPrice,
                retailPrice,
              },
              dateType,
              dateTime
            );
            closeDialog();
          }}
          color="secondary"
          variant="contained"
        >
          Update prices
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EditPricingDialog.defaultProps = {
  isOpen: true,
  closeDialog: () => console.log('The dialog was closed.'),
  updatePricingInfo: () => console.log('Pricing info was updated.'),
  skuInfo: {
    sku: 'SKU-EXA-0101',
  },
  platform: 'TAKEALOT',
};

EditPricingDialog.propTypes = {
  isOpen: PropTypes.bool,
  closeDialog: PropTypes.func,
  updatePricingInfo: PropTypes.func,
  skuInfo: PropTypes.shape({
    sku: PropTypes.string,
    measurements: PropTypes.shape({
      weight: PropTypes.number,
      length: PropTypes.number,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    takealot: PropTypes.shape({
      categories: PropTypes.shape({
        department: PropTypes.string,
        category: PropTypes.string,
        subCategory: PropTypes.string,
      }),
      costPriceRands: PropTypes.number,
      wholesalePriceRands: PropTypes.number,
      retailPriceRands: PropTypes.number,
    }),
    amazon: PropTypes.shape({
      categories: PropTypes.shape({
        department: PropTypes.string,
        category: PropTypes.string,
        subCategory: PropTypes.string,
      }),
      costPriceRands: PropTypes.number,
      wholesalePriceRands: PropTypes.number,
      retailPriceDollars: PropTypes.number,
    }),
  }),
  platform: PropTypes.string,
};

export default EditPricingDialog;
