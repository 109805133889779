import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import numeral from 'numeral';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(4),
  },
  wrapperDialog: {
    padding: theme.spacing(4),
  },
}));

const FixPricingDialog = ({ isOpen, closeDialog, addAdjustment }) => {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    if (isOpen) {
      setName('');
      setAmount(0);
    }
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onClose={closeDialog}>
      <DialogTitle>Add Adjustment</DialogTitle>
      <DialogContent className={classes.wrapperDialog}>
        <DialogContentText>
          Please enter the details for the adjustment (Note: indicate costs to
          client with negative values):
        </DialogContentText>
        <TextField
          className={classes.formControl}
          label="Short Description"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          className={classes.formControl}
          label="Amount"
          type="number"
          fullWidth
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment disableTypography position="start">
                R
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Cancel</Button>
        <Button
          disabled={name === '' || amount === null}
          variant="contained"
          color="secondary"
          onClick={() => {
            addAdjustment(name, numeral(amount).value());
            closeDialog();
          }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

FixPricingDialog.defaultProps = {
  isOpen: true,
  closeDialog: () => console.log('Dialog was closed.'),
  addAdjustment: () => console.log('Adjustment was add.'),
};

FixPricingDialog.propTypes = {
  isOpen: PropTypes.bool,
  closeDialog: PropTypes.func,
  addAdjustment: PropTypes.func,
};

export default FixPricingDialog;
