import { InstantSearch } from 'react-instantsearch-dom';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import moment from 'moment';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import { changeDateRange } from './logic';
import { fetchPresalesSuppliers, requestReport } from './slice';
import { firestore } from '../../../utils/firebase';
import assembleTypesenseServerConfig from '../../../utils/typesense';
import ContentWrapper from '../../../components/ContentWrapper';
import DateNavigator from './components/DateNavigator';
import DcRefinementList from './components/DcRefinementList';
import Hits from './components/Hits';
import PageHeader from '../../../components/PageHeader';
import ReportActionBar from './components/ReportActionBar';
import RequestedReports from './components/RequestedReports';
import StatusRefinementList from './components/StatusRefinementList';
import SupplierRefinementList from './components/SupplierRefinementList';
import TypesenseSearchBar from '../../../components/TypesenseSearchBar';

const TYPESENSE_SERVER_CONFIG = assembleTypesenseServerConfig();
const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: TYPESENSE_SERVER_CONFIG,
  additionalSearchParameters: {
    queryBy:
      'customer,dc,orderId,productTitle,saleStatus,shipmentId,shipmentName,sku,skuInfo.barcode,skuInfo.supplierId,skuInfo.supplierName,skuInfo.supplierSku,tsin',
    numTypos: 1,
    typoTokensThreshold: 1,
  },
});
const { searchClient } = typesenseInstantsearchAdapter;

const breadcrumbs = [
  {
    label: 'Presales',
    link: '/presales',
  },
  {
    label: 'Orders',
    link: '/presales/orders',
  },
];

const OrdersBrowse = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('TAKEALOT');
  const [dateSelected, setDateSelected] = useState(new Date());
  const [reportType, setReportType] = useState('TAKEALOT_ORDERS');
  const [dcFilter, setDcFilter] = useState('BOTH');
  const [supplier, setSupplier] = useState('ALL');
  const [statusId, setStatusId] = useState('NEW_LEAD_TIME_ORDER');
  const [dateType, setDateType] = useState('LAST_10_DAYS');
  const [startDate, setStartDate] = useState(
    moment().subtract(10, 'days').startOf('day').toDate()
  );
  const [endDate, setEndDate] = useState(moment().toDate());
  const [orderReports, setOrderReports] = useState([]);

  const suppliers = useSelector(
    (state) => state.presalesOrdersBrowse.suppliers
  );
  const suppliersLoadingStatus = useSelector(
    (state) => state.presalesOrdersBrowse.loadingStatus.suppliers
  );
  const requestReportLoadingStatus = useSelector(
    (state) => state.presalesOrdersBrowse.loadingStatus.requestReport
  );
  const userInfo = useSelector((state) => state.account.userInfo);

  useEffect(() => {
    dispatch(fetchPresalesSuppliers());
    const orderReportsRef = firestore
      .collection('presalesOrdersReports')
      .orderBy('requestDate', 'desc')
      .limit(20);

    const unsubscribe = orderReportsRef.onSnapshot((querySnapshot) => {
      const result = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          startDate: data.startDate.toDate(),
          endDate: data.endDate.toDate(),
          requestDate: data.requestDate.toDate(),
        };
      });

      setOrderReports(result);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (
      suppliersLoadingStatus === 'PENDING' ||
      requestReportLoadingStatus === 'PENDING'
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [suppliersLoadingStatus, requestReportLoadingStatus]);

  useEffect(() => {
    if (requestReportLoadingStatus === 'COMPLETE') {
      setDcFilter('BOTH');
      setSupplier('ALL');
      setStatusId('NEW_LEAD_TIME_ORDER');
      setDateType('LAST_10_DAYS');
      setStartDate(moment().subtract(10, 'days').startOf('day').toDate());
      setEndDate(moment().toDate());
    }
  }, [requestReportLoadingStatus]);

  useEffect(() => {
    changeDateRange(dateType, setStartDate, setEndDate);
  }, [dateType]);

  const getSupplierName = () => {
    const supplierInfo = suppliers.find((info) => info.id === supplier);
    if (!supplierInfo) {
      return 'Unknown';
    }

    return supplierInfo.name;
  };

  return (
    <div>
      <PageHeader
        title="Orders"
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Browse"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
      />
      {isLoading && <LinearProgress color="secondary" />}
      <ContentWrapper>
        <InstantSearch
          searchClient={searchClient}
          indexName="presalesOrders"
          refresh
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Tabs
                value={activeTab}
                indicatorColor="secondary"
                textColor="secondary"
                onChange={(e, newTab) => setActiveTab(newTab)}
              >
                <Tab label="Takealot Orders" value="TAKEALOT" />
                <Tab label="Reports" value="REPORTS" />
              </Tabs>
            </Grid>
            {activeTab === 'TAKEALOT' && (
              <>
                <Grid item xs={12}>
                  <DateNavigator
                    attribute="orderDate"
                    dateSelected={dateSelected}
                    changeDateSelected={setDateSelected}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TypesenseSearchBar />
                </Grid>
                <Grid item xs={6} sm={9}>
                  <Hits />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <DcRefinementList attribute="dc" />
                  <StatusRefinementList attribute="saleStatus" />
                  <SupplierRefinementList attribute="skuInfo.supplierName" />
                </Grid>
              </>
            )}
            {activeTab === 'REPORTS' && (
              <>
                <Grid item sm={12}>
                  <ReportActionBar
                    dateType={dateType}
                    changeDateType={setDateType}
                    startDate={startDate}
                    changeStartDate={setStartDate}
                    endDate={endDate}
                    changeEndDate={setEndDate}
                    dcFilter={dcFilter}
                    changeDcFilter={setDcFilter}
                    statusId={statusId}
                    changeStatusId={setStatusId}
                    supplier={supplier}
                    changeSupplier={setSupplier}
                    suppliers={suppliers}
                    reportType={reportType}
                    changeReportType={setReportType}
                    isLoading={isLoading}
                    requestReport={() =>
                      dispatch(
                        requestReport(
                          startDate,
                          endDate,
                          userInfo.uid,
                          `${userInfo.name} ${userInfo.surname}`,
                          supplier,
                          getSupplierName(),
                          statusId,
                          reportType,
                          dcFilter
                        )
                      )
                    }
                  />
                  <RequestedReports orderReports={orderReports} />
                </Grid>
              </>
            )}
          </Grid>
        </InstantSearch>
      </ContentWrapper>
    </div>
  );
};
export default OrdersBrowse;
