import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import { createClient, getDealerSummary, initiateStateReset } from './slice';
import ContentWrapper from '../../../components/ContentWrapper';
import PageHeader from '../../../components/PageHeader';

const useStyles = makeStyles((theme) => ({
  actionsBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  alert: {
    marginBottom: theme.spacing(3),
  },
  formControl: {
    minWidth: 200,
  },
  formWrapper: {
    padding: `${theme.spacing(4)}px ${theme.spacing(1)}px`,
  },
  separator: {
    height: theme.spacing(2),
  },
  wrapperStep: {
    padding: `${theme.spacing(4)}px 0px`,
  },
  wrapperWizard: {
    width: '100%',
    padding: theme.spacing(4),
  },
}));

const breadcrumbs = [
  {
    label: 'Customer Relationship Management',
    link: '/crm',
  },
  {
    label: 'Clients',
    link: '/crm/clients',
  },
];

const ClientsAdd = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [name, setName] = useState('');
  const [type, setType] = useState('STANDARD');
  const [dealerCode, setDealerCode] = useState('');
  const [legalEntityName, setLegalEntityName] = useState('');
  const [vatNumber, setVatNumber] = useState('');
  const [physicalAddress, setPhysicalAddress] = useState('');
  const [mainContact, setMainContact] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [telephoneNumber, setTelephoneNumber] = useState('');
  const [leadTime, setLeadTime] = useState(7);
  const [validation, setValidation] = useState({
    isErrorShowing: false,
    message: '',
    errorStep: 0,
  });

  const superDealerSummary = useSelector(
    (state) => state.clientsAdd.superDealerSummary
  );
  const creationLoadingStatus = useSelector(
    (state) => state.clientsAdd.loadingStatus.creation
  );
  const superDealerSummaryLoadingStatus = useSelector(
    (state) => state.clientsAdd.loadingStatus.superDealerSummary
  );
  const isUserRedirectedToBrowse = useSelector(
    (state) => state.clientsAdd.isUserRedirectedToBrowse
  );

  useEffect(() => {
    dispatch(getDealerSummary());
  }, []);

  useEffect(() => {
    if (
      creationLoadingStatus === 'PENDING' ||
      superDealerSummaryLoadingStatus === 'PENDING'
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [creationLoadingStatus, superDealerSummaryLoadingStatus]);

  useEffect(() => {
    if (isUserRedirectedToBrowse) {
      dispatch(initiateStateReset());
      setIsLoading(true);
      history.goBack();
    }
  }, [isUserRedirectedToBrowse]);

  useEffect(() => {
    if (type === 'SUPER_DEALER') {
      setDealerCode((superDealerSummary.count + 1).toString().padStart(3, '0'));
    } else {
      setDealerCode('');
    }
  }, [type]);

  const onNextClick = () => {
    switch (activeStep) {
      case 0:
        if (name === '') {
          setValidation({
            isErrorShowing: true,
            message: 'Please complete all required fields.',
            errorStep: 0,
          });
        } else {
          setValidation({
            isErrorShowing: false,
            message: '',
            errorStep: 0,
          });
          setActiveStep(1);
        }
        break;
      case 1:
        dispatch(
          createClient({
            name,
            type,
            dealerCode,
            status: 'HIGH_PRIORITY',
            billingInfo: {
              legalEntityName,
              vatNumber,
              physicalAddress,
              mainContact,
              emailAddress,
              telephoneNumber,
            },
            leadTime,
          })
        );
        setValidation({
          isErrorShowing: false,
          message: '',
          errorStep: 0,
        });
        break;
      default:
        console.log('Unknown step');
        break;
    }
  };

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <div className={classes.wrapperStep}>
            <Typography variant="h4" gutterBottom>
              Enter client&apos;s name
            </Typography>
            <Typography variant="body1">
              Please enter an easy-to-read name used to refer to the client.
              Note that this need not be the legal name.
            </Typography>
            <form className={classes.formWrapper}>
              <TextField
                required
                label="Name"
                fullWidth
                placeholder="Madeup Corporation"
                value={name}
                onChange={(e) => setName(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <div className={classes.separator} />
              <FormControl required className={classes.formControl}>
                <InputLabel shrink id="type-select-label">
                  Type
                </InputLabel>
                <Select
                  labelId="type-select-label"
                  id="type-select"
                  value={type}
                  onChange={(e) => {
                    setType(e.target.value);
                  }}
                >
                  <MenuItem value="STANDARD">Standard</MenuItem>
                  <MenuItem value="SUPER_DEALER">Super Dealer</MenuItem>
                </Select>
              </FormControl>
            </form>
            <div className={classes.separator} />
            {validation.isErrorShowing && validation.errorStep === 0 && (
              <Alert className={classes.alert} severity="error">
                {validation.message}
              </Alert>
            )}
          </div>
        );
      case 1:
        return (
          <div className={classes.wrapperStep}>
            <Typography variant="h4" gutterBottom>
              Enter the client&apos;s billing info
            </Typography>
            <Typography variant="body1">
              Please complete as many fields for the client&apos;s billing info
              as possible. You can edit this in the future if you are uncertain
              of the info now.
            </Typography>
            <div className={classes.separator} />
            <TextField
              label="Legal Entity Name"
              fullWidth
              value={legalEntityName}
              onChange={(e) => setLegalEntityName(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <div className={classes.separator} />
            <TextField
              label="VAT Number"
              fullWidth
              value={vatNumber}
              onChange={(e) => setVatNumber(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <div className={classes.separator} />
            <TextField
              label="Physical Address"
              fullWidth
              value={physicalAddress}
              onChange={(e) => setPhysicalAddress(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <div className={classes.separator} />
            <TextField
              label="Main Contact"
              fullWidth
              value={mainContact}
              onChange={(e) => setMainContact(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <div className={classes.separator} />
            <TextField
              label="Email Address"
              fullWidth
              type="email"
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <div className={classes.separator} />
            <TextField
              label="Telephone Number"
              fullWidth
              type="telephone"
              value={telephoneNumber}
              onChange={(e) => setTelephoneNumber(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <div className={classes.separator} />
            <TextField
              label="Lead Time"
              fullWidth
              type="number"
              value={leadTime}
              onChange={(e) => setLeadTime(parseInt(e.target.value, 10))}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">days</InputAdornment>
                ),
              }}
            />
            <div className={classes.separator} />
            {validation.isErrorShowing && validation.errorStep === 1 && (
              <Alert className={classes.alert} severity="error">
                {validation.message}
              </Alert>
            )}
          </div>
        );
      default:
        return <div />;
    }
  };

  return (
    <div>
      <PageHeader
        isLoading={isLoading}
        title="Add New Client"
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Add"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
      />
      <ContentWrapper>
        <Paper className={classes.wrapperWizard}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Stepper activeStep={activeStep} orientation="vertical">
                <Step>
                  <StepLabel>Basic Details</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Billing Info</StepLabel>
                </Step>
              </Stepper>
            </Grid>
            <Grid item xs={9}>
              {getStepContent()}
              <div className={classes.actionsBar}>
                <Button
                  disabled={activeStep === 0 || isLoading}
                  onClick={() => setActiveStep(activeStep - 1)}
                >
                  Previous
                </Button>
                <Button
                  disabled={isLoading}
                  variant="contained"
                  color="secondary"
                  onClick={onNextClick}
                >
                  {activeStep !== 1 ? 'Next' : 'Create'}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </ContentWrapper>
    </div>
  );
};

export default ClientsAdd;
