import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: `0 ${theme.spacing(1)}px`,
  },
}));

const ViewSelector = ({ viewFilter, changeViewFilter, views }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const getStatusLabel = (id) => {
    const statusInfo = views.find((info) => info.id === id);
    if (!statusInfo) {
      return 'Unknown';
    }

    return statusInfo.label;
  };

  return (
    <div className={classes.wrapper}>
      <Button
        aria-controls="views-menu"
        aria-haspopup="true"
        size="large"
        onClick={openMenu}
      >
        {getStatusLabel(viewFilter)}
      </Button>
      <Menu
        id="views-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {views
          .map((info) => info)
          .map((info) => (
            <MenuItem
              key={info.id}
              onClick={() => {
                changeViewFilter(info.id);
                closeMenu();
              }}
            >
              {info.label}
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
};

ViewSelector.defaultProps = {
  viewFilter: 'STANDARD',
  changeViewFilter: (newValue) => `View was changed to '${newValue}'`,
  views: [
    {
      id: 'STANDARD',
      label: 'Standard View',
    },
    {
      id: 'PRESALES',
      label: 'Presales View',
    },
  ],
};

ViewSelector.propTypes = {
  viewFilter: PropTypes.oneOf(['STANDARD', 'PRESALES']),
  changeViewFilter: PropTypes.func,
  views: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};

export default ViewSelector;
