import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { green, red, yellow } from '@material-ui/core/colors';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import Chip from '@material-ui/core/Chip';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import { deleteProduct, fetchProductInfo, initiateStateReset } from './slice';
import BasicInfoCard from './components/BasicInfoCard';
import ContentWrapper from '../../../components/ContentWrapper';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import EmptyViewMessage from '../../../components/EmptyViewMessage';
import PageHeader from '../../../components/PageHeader';
import ProductDetails from './components/ProductDetails';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.common.black,
  },
  seperator: {
    height: theme.spacing(2),
  },
  seperatorButtons: {
    height: theme.spacing(1),
  },
  statusAssigned: {
    width: '100%',
    color: theme.palette.common.black,
    backgroundColor: red[500],
  },
  statusAvailable: {
    width: '100%',
    color: theme.palette.common.black,
    backgroundColor: green[500],
  },
  statusReserved: {
    width: '100%',
    color: theme.palette.common.black,
    backgroundColor: yellow[500],
  },
  statusWrapper: {
    width: '100%',
    textAlign: 'center',
    padding: theme.spacing(4),
  },
}));

const breadcrumbs = [
  {
    label: 'Sales',
    link: '/sales',
  },
  {
    label: 'Products',
    link: '/sales/products',
  },
  {
    label: 'Browse',
    link: '/sales/products/browse',
  },
];

const SourcedProductsView = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [isDeleteProductDialogOpen, setIsDeleteProductDialogOpen] =
    useState(false);

  const productInfo = useSelector(
    (state) => state.sourcedProductsView.productInfo
  );
  const isUserRedirectedToBrowse = useSelector(
    (state) => state.sourcedProductsView.isUserRedirectedToBrowse
  );
  const productInfoLoadingStatus = useSelector(
    (state) => state.sourcedProductsView.loadingStatus.productInfo
  );
  const deleteProductLoadingStatus = useSelector(
    (state) => state.sourcedProductsView.loadingStatus.deleteProduct
  );

  useEffect(() => {
    dispatch(fetchProductInfo(id));
  }, []);

  useEffect(() => {
    if (
      productInfoLoadingStatus === 'PENDING' ||
      deleteProductLoadingStatus === 'PENDING'
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [productInfoLoadingStatus, deleteProductLoadingStatus]);

  useEffect(() => {
    if (isUserRedirectedToBrowse) {
      dispatch(initiateStateReset());
      history.push('/sales/products/browse/');
    }
  }, [isUserRedirectedToBrowse]);

  const getStatusChip = (status) => {
    switch (status) {
      case 'AVAILABLE':
        return (
          <Chip
            icon={<DoneIcon className={classes.icon} />}
            label="Available"
            className={classes.statusAvailable}
          />
        );
      case 'RESERVED':
        return (
          <Chip
            icon={<AutorenewIcon className={classes.icon} />}
            label="Reserved"
            className={classes.statusReserved}
          />
        );
      case 'ASSIGNED':
        return (
          <Chip
            icon={<ClearIcon className={classes.icon} />}
            label="Assigned"
            className={classes.statusAssigned}
          />
        );
      default:
        return (
          <Chip
            icon={<DoneIcon className={classes.icon} />}
            label="Active"
            className={classes.statusActive}
          />
        );
    }
  };

  return (
    <div>
      <PageHeader
        title="Product Info"
        subtitle={isLoading ? 'Loading...' : productInfo.description}
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Product"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
      />
      {isLoading && <LinearProgress color="secondary" />}
      <ContentWrapper>
        {!isLoading && productInfo.id === 'Loading' ? (
          <EmptyViewMessage
            heading="Info Failed to Load"
            message="Please check your internet connection and reload the page."
          />
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <div>
                <Button
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  startIcon={<EditIcon />}
                  onClick={() =>
                    history.push(`/sales/products/browse/edit/${id}`)
                  }
                >
                  Edit details
                </Button>
                <div className={classes.seperatorButtons} />
                <Button
                  fullWidth
                  disabled={productInfo.status !== 'AVAILABLE'}
                  variant="outlined"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  onClick={() => setIsDeleteProductDialogOpen(true)}
                >
                  Delete product
                </Button>
              </div>
              <div className={classes.seperator} />
              <BasicInfoCard
                creationDate={productInfo.creationDate}
                supplierName={productInfo.supplierName}
                productSourcerName={productInfo.productSourcerName}
                isLoading={productInfoLoadingStatus === 'PENDING'}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={9}>
              {!isLoading && (
                <div>
                  <div className={classes.statusWrapper}>
                    {getStatusChip(productInfo.status)}
                  </div>
                  <Paper>
                    <ProductDetails
                      stockInfo={productInfo.stockInfo}
                      categories={productInfo.categories}
                      pricing={productInfo.pricing}
                      isLoading={isLoading}
                      onMissingInfoClick={() =>
                        history.push(`/sales/products/browse/edit/${id}`)
                      }
                    />
                  </Paper>
                </div>
              )}
            </Grid>
          </Grid>
        )}
      </ContentWrapper>
      <ConfirmationDialog
        isOpen={isDeleteProductDialogOpen}
        closeDialog={() => setIsDeleteProductDialogOpen(false)}
        message="Are you sure you want to delete this product from the database?"
        confirmAction={() => dispatch(deleteProduct(id))}
      />
    </div>
  );
};

export default SourcedProductsView;
