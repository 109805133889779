import { green, grey, yellow, red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { Doughnut } from 'react-chartjs-2';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import PropTypes from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';
import VisibilityIcon from '@material-ui/icons/Visibility';

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.secondary.light,
  },
  loadingRect: {
    height: 300,
    backgroundColor: grey[900],
  },
}));

const options = {
  maintainAspectRatio: false,
};

const PageViewsDoughnutGraph = ({ skuCounts, rangeInDays, isLoading }) => {
  const classes = useStyles();

  const data = {
    labels:
      rangeInDays <= 7
        ? ['< 25', '25 - 250', '> 250']
        : ['< 100', '100 - 1,000', '> 1,000'],
    datasets: [
      {
        label: 'SKU Count',
        data: skuCounts,
        backgroundColor: [red[700], yellow[700], green[600]],
        borderWidth: 0,
      },
    ],
  };

  return (
    <Card>
      <CardHeader
        avatar={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <Avatar className={classes.avatar}>
            <VisibilityIcon />
          </Avatar>
        }
        title="Page Views Distribution"
      />
      <CardContent>
        {isLoading ? (
          <Skeleton variant="rect" className={classes.loadingRect} />
        ) : (
          <div>
            <Doughnut height={300} data={data} options={options} />
          </div>
        )}
      </CardContent>
    </Card>
  );
};

PageViewsDoughnutGraph.defaultProps = {
  isLoading: false,
  skuCounts: [9, 12, 3],
  rangeInDays: 7,
};

PageViewsDoughnutGraph.propTypes = {
  isLoading: PropTypes.bool,
  skuCounts: PropTypes.arrayOf(PropTypes.number),
  rangeInDays: PropTypes.number,
};

export default PageViewsDoughnutGraph;
