import { useState } from 'react';
import Button from '@material-ui/core/Button';
import CategoryIcon from '@material-ui/icons/Category';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';

const TypeSelector = ({ typesFilter, changeTypeFilter, types }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const getStatusLabel = (id) => {
    if (id === 'ALL') {
      return 'All Types';
    }

    const typeInfo = types.find((info) => info.id === id);
    if (!typeInfo) {
      return 'Unknown';
    }

    return typeInfo.label;
  };

  return (
    <>
      <Button
        aria-controls="types-menu"
        aria-haspopup="true"
        size="large"
        startIcon={<CategoryIcon />}
        onClick={openMenu}
      >
        {getStatusLabel(typesFilter)}
      </Button>
      <Menu
        id="types-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem
          onClick={() => {
            changeTypeFilter('ALL');
            closeMenu();
          }}
        >
          All Types
        </MenuItem>
        {types
          .map((info) => info)
          .map((info) => (
            <MenuItem
              key={info.id}
              onClick={() => {
                changeTypeFilter(info.id);
                closeMenu();
              }}
            >
              {info.label}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

TypeSelector.defaultProps = {
  typesFilter: 'ALL',
  changeTypeFilter: (newValue) => `Status was changed to '${newValue}'`,
  types: [
    {
      id: 'LISTING_CHANGE',
      label: 'Listing Edit',
    },
    {
      id: 'PRICE_CHANGE',
      label: 'Price Change',
    },
    {
      id: 'MISCELLANEOUS',
      label: 'Miscellaneous',
    },
    {
      id: 'PRODUCT_MISMATCH',
      label: 'Received Product Mismatch',
    },
  ],
};

TypeSelector.propTypes = {
  typesFilter: PropTypes.oneOf([
    'LISTING_CHANGE',
    'PRICE_CHANGE',
    'MISCELLANEOUS',
    'PRODUCT_MISMATCH',
    'ALL',
  ]),
  changeTypeFilter: PropTypes.func,
  types: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};

export default TypeSelector;
