import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import ReportTypeSelector from './components/ReportTypeSelector';
import SupplierSelector from './components/SupplierSelector';

const useStyles = makeStyles((theme) => ({
  seperator: {
    width: theme.spacing(2),
  },
  wrapper: {
    backgroundColor: grey[800],
    borderRadius: 8,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
}));

const ReportsActionBar = ({
  reportType,
  changeReportType,
  supplier,
  changeSupplier,
  suppliers,
  isLoading,
  requestReport,
}) => {
  const classes = useStyles();

  return (
    <Grid container spacing={4} alignItems="center" className={classes.wrapper}>
      <Grid item>
        <Button
          size="large"
          variant="contained"
          disabled={isLoading}
          onClick={requestReport}
        >
          Request Report
        </Button>
      </Grid>
      <Divider orientation="vertical" flexItem />
      <Grid item>
        <Grid container direction="row">
          <ReportTypeSelector
            reportType={reportType}
            changeReportType={changeReportType}
          />
          <div className={classes.seperator} />
          <SupplierSelector
            supplier={supplier}
            changeSupplier={changeSupplier}
            supplierOptions={suppliers}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

ReportsActionBar.defaultProps = {
  reportType: 'PRICING_STOCK',
  changeReportType: (newReportType) =>
    console.log(`Report type was changed to: ${newReportType}`),
  supplier: 'ALL',
  changeSupplier: (newSupplier) =>
    console.log(`Supplier was changed to: ${newSupplier}`),
  suppliers: [],
  isLoading: false,
  requestReport: () => console.log('New report requested.'),
};

ReportsActionBar.propTypes = {
  supplier: PropTypes.string,
  changeSupplier: PropTypes.func,
  reportType: PropTypes.string,
  changeReportType: PropTypes.func,
  suppliers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  isLoading: PropTypes.bool,
  requestReport: PropTypes.func,
};

export default ReportsActionBar;
