import PropTypes from 'prop-types';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import { Scrollbars } from 'react-custom-scrollbars';
import Typography from '@material-ui/core/Typography';
import CRMNav from './components/CRMNav';
import FinanceNav from './components/FinanceNav';
import ListingsNav from './components/ListingsNav';
import LogisticsNav from './components/LogisticsNav';
import SalesNav from './components/SalesNav';
import SystemMaintenanceNav from './components/SystemMaintenanceNav';
import UsersNav from './components/UsersNav';

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: grey[800],
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginBottom: theme.spacing(2),
  },
  jobTitle: {
    color: grey[700],
  },
  sectionDivider: {
    margin: `${theme.spacing(2)}px 0`,
  },
  username: {
    color: grey[400],
  },
  wrapperNav: {
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
  },
  wrapperProfile: {
    padding: theme.spacing(4),
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const StandardSideBar = ({
  isOpen,
  username,
  jobTitle,
  userType,
  activeSectionLink,
  goToLink,
  isSuperAdmin,
}) => {
  const classes = useStyles();

  return (
    <Scrollbars className={classes.wrapperNav}>
      {isOpen && (
        <div className={classes.wrapperProfile}>
          <Avatar className={classes.avatar} />
          <Typography variant="h5" className={classes.username}>
            {username}
          </Typography>
          <Typography variant="subtitle2" className={classes.jobTitle}>
            {jobTitle}
          </Typography>
        </div>
      )}
      {isOpen && <Divider />}
      {!isOpen &&
        (userType === 'ADMIN' ||
          userType === 'SALES_MANAGER' ||
          userType === 'SALES_AGENT') && (
          <Divider className={classes.sectionDivider} />
        )}
      {(userType === 'ADMIN' ||
        userType === 'SALES_MANAGER' ||
        userType === 'SALES_AGENT') && (
        <SalesNav
          isOpen={isOpen}
          activeSectionLink={activeSectionLink}
          goToLink={goToLink}
        />
      )}
      {!isOpen && userType === 'ADMIN' && (
        <Divider className={classes.sectionDivider} />
      )}
      {userType === 'ADMIN' && (
        <ListingsNav
          isOpen={isOpen}
          activeSectionLink={activeSectionLink}
          goToLink={goToLink}
        />
      )}
      {!isOpen && userType === 'ADMIN' && (
        <Divider className={classes.sectionDivider} />
      )}
      {userType === 'ADMIN' && (
        <LogisticsNav
          isOpen={isOpen}
          activeSectionLink={activeSectionLink}
          goToLink={goToLink}
        />
      )}
      {!isOpen && userType === 'ADMIN' && (
        <Divider className={classes.sectionDivider} />
      )}
      {userType === 'ADMIN' && (
        <FinanceNav
          isOpen={isOpen}
          activeSectionLink={activeSectionLink}
          goToLink={goToLink}
        />
      )}
      {!isOpen && userType === 'ADMIN' && (
        <Divider className={classes.sectionDivider} />
      )}
      {userType === 'ADMIN' && (
        <CRMNav
          isOpen={isOpen}
          activeSectionLink={activeSectionLink}
          goToLink={goToLink}
        />
      )}
      {!isOpen &&
        (userType === 'ADMIN' ||
          userType === 'SALES_MANAGER' ||
          userType === 'LEADS_MANAGER') && (
          <Divider className={classes.sectionDivider} />
        )}
      {(userType === 'ADMIN' ||
        userType === 'SALES_MANAGER' ||
        userType === 'LEADS_MANAGER') && (
        <UsersNav
          isOpen={isOpen}
          activeSectionLink={activeSectionLink}
          goToLink={goToLink}
          userType={userType}
        />
      )}
      {!isOpen && isSuperAdmin && (
        <Divider className={classes.sectionDivider} />
      )}
      {isSuperAdmin && (
        <SystemMaintenanceNav
          isOpen={isOpen}
          activeSectionLink={activeSectionLink}
          goToLink={goToLink}
          userType={userType}
        />
      )}
    </Scrollbars>
  );
};

StandardSideBar.defaultProps = {
  isOpen: true,
  username: 'John Doe',
  jobTitle: 'Administrator',
  userType: 'ADMIN',
  activeSectionLink: '/dashboard',
  goToLink: (link) => console.log('User navigated to:', link),
  isSuperAdmin: true,
};

StandardSideBar.propTypes = {
  isOpen: PropTypes.bool,
  username: PropTypes.string,
  jobTitle: PropTypes.string,
  userType: PropTypes.string,
  activeSectionLink: PropTypes.string,
  goToLink: PropTypes.func,
  isSuperAdmin: PropTypes.bool,
};

export default StandardSideBar;
