import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import numeral from 'numeral';
import AddIcon from '@material-ui/icons/Add';
import CircularProgress from '@material-ui/core/CircularProgress';
import GetAppIcon from '@material-ui/icons/GetApp';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import {
  fetchStatementReports,
  fetchClient,
  setTotalClientsToLoad,
  fetchAmazonSku,
  setTotalAmazonSkusToLoad,
  fetchTakealotSku,
  setTotalTakealotSkusToLoad,
  addStatementReport,
  uploadAmazonStatement,
  setTotalAmazonStatementsToLoad,
  uploadTakealotStatement,
  setTotalTakealotStatementsToLoad,
  fetchAmazonStatements,
  fetchTakealotStatements,
  saveFinalizeAmazonStatement,
  saveFinalizeTakealotStatement,
  resetState,
  uploadPriceFixes,
  uploadAdjustmentAddition,
  uploadAdjustmentRemoval,
  updateAmazonInitialPage,
  updateAmazonPageSize,
  updateTakealotInitialPage,
  updateTakealotPageSize,
} from './slice';
import AddAdjustmentDialog from './components/AddAdjustmentDialog';
import ContentWrapper from '../../../components/ContentWrapper';
import CreateNewStatementReportDialog from './components/CreateNewStatementReportDialog';
import FixPricingDialog from './components/FixPricingDialog';
import PageHeader from '../../../components/PageHeader';
import PlatformSelector from './components/PlatformSelector';
import StatementDocument from '../../../components/StatementDocument';
import StatementReportsTable from './components/StatementReportsTable';
import StatementsTable from './components/StatementsTable';
import StatementViewTables from './components/StatementViewTables';
import TotalsBar from '../../../components/TotalsBar';

const useStyles = makeStyles((theme) => ({
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  breadcrumbs: {
    marginBottom: theme.spacing(2),
  },
  buttonBack: {
    marginRight: theme.spacing(2),
  },
  loadingWrapper: {
    textAlign: 'center',
    padding: theme.spacing(8),
  },
  separator: {
    height: theme.spacing(3),
  },
  totalsBarWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  wrapperContent: {
    width: '100%',
    maxWidth: 1200,
    margin: '0 auto',
    padding: theme.spacing(3),
  },
}));

const FinanceStatements = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();

  const [
    isCreateNewStatementReportDialogOpen,
    setIsCreateNewStatementReportDialogOpen,
  ] = useState(false);
  const [isFixPricingDialogOpen, setIsFixPricingDialogOpen] = useState(false);
  const [isAddAdjustmentDialogOpen, setIsAddAdjustmentDialogOpen] =
    useState(false);
  const [fixPricingLineItem, setFixPricingLineItem] = useState({});

  const amazonPageSize = useSelector(
    (state) => state.financeStatements.amazonPageSize
  );
  const amazonInitialPage = useSelector(
    (state) => state.financeStatements.amazonInitialPage
  );
  const takealotPageSize = useSelector(
    (state) => state.financeStatements.takealotPageSize
  );
  const takealotInitialPage = useSelector(
    (state) => state.financeStatements.takealotInitialPage
  );
  const statementReports = useSelector(
    (state) => state.financeStatements.statementReportsList
  );
  const amazonStatements = useSelector(
    (state) => state.financeStatements.amazonStatements
  );
  const takealotStatements = useSelector(
    (state) => state.financeStatements.takealotStatements
  );
  const statementReportsLoadingStatus = useSelector(
    (state) => state.financeStatements.loadingStatus.statementReports
  );
  const amazonStatementsLoadingStatus = useSelector(
    (state) => state.financeStatements.loadingStatus.amazonStatements
  );
  const takealotStatementsLoadingStatus = useSelector(
    (state) => state.financeStatements.loadingStatus.takealotStatements
  );
  const pricingFixLoadingStatus = useSelector(
    (state) => state.financeStatements.loadingStatus.pricingFix
  );
  const addAdjustmentLoadingStatus = useSelector(
    (state) => state.financeStatements.loadingStatus.addAdjustment
  );
  const removeAdjustmentLoadingStatus = useSelector(
    (state) => state.financeStatements.loadingStatus.removeAdjustment
  );
  const addReportLoadingStatus = useSelector(
    (state) => state.financeStatements.loadingStatus.statementReportCreation
  );
  const reportStatementsLoadingStatus = useSelector(
    (state) => state.financeStatements.loadingStatus.reportStatements
  );
  const clients = useSelector((state) => state.financeStatements.clientsList);
  const clientLoadingCount = useSelector(
    (state) => state.financeStatements.clientLoadingCount
  );
  const failedClientIds = useSelector(
    (state) => state.financeStatements.failedClientIds
  );
  const amazonSkuLoadingCount = useSelector(
    (state) => state.financeStatements.amazonSkuLoadingCount
  );
  const amazonFailedSkus = useSelector(
    (state) => state.financeStatements.amazonFailedSkus
  );
  const amazonSkus = useSelector(
    (state) => state.financeStatements.amazonSkusList
  );
  const takealotSkuLoadingCount = useSelector(
    (state) => state.financeStatements.takealotSkuLoadingCount
  );
  const takealotFailedSkus = useSelector(
    (state) => state.financeStatements.takealotFailedSkus
  );
  const takealotSkus = useSelector(
    (state) => state.financeStatements.takealotSkusList
  );
  const amazonStatementsLoadingCount = useSelector(
    (state) => state.financeStatements.amazonStatementsLoadingCount
  );
  const amazonFailedStatements = useSelector(
    (state) => state.financeStatements.amazonFailedStatements
  );
  const takealotStatementsLoadingCount = useSelector(
    (state) => state.financeStatements.takealotStatementsLoadingCount
  );
  const takealotFailedStatements = useSelector(
    (state) => state.financeStatements.takealotFailedStatements
  );

  useEffect(() => {
    if (statementReportsLoadingStatus === 'NOT_STARTED') {
      dispatch(fetchStatementReports());
    }
    if (
      amazonStatementsLoadingStatus === 'NOT_STARTED' &&
      match.params.reportId
    ) {
      dispatch(fetchAmazonStatements(match.params.reportId));
    }
    if (
      takealotStatementsLoadingStatus === 'NOT_STARTED' &&
      match.params.reportId
    ) {
      dispatch(fetchTakealotStatements(match.params.reportId));
    }
  }, []);

  useEffect(() => {
    if (
      amazonStatementsLoadingStatus === 'NOT_STARTED' &&
      match.params.reportId
    ) {
      dispatch(fetchAmazonStatements(match.params.reportId));
    }
    if (
      takealotStatementsLoadingStatus === 'NOT_STARTED' &&
      match.params.reportId
    ) {
      dispatch(fetchTakealotStatements(match.params.reportId));
    }
    if (match.params.reportId === undefined) {
      resetState();
    }
  }, [match.params.reportId]);

  const loadClients = (clientIdsList) => {
    dispatch(setTotalClientsToLoad(clientIdsList.length));
    clientIdsList.map((clientId) => dispatch(fetchClient(clientId)));
  };

  const uploadAmazonStatements = (reportId, statementsList) => {
    dispatch(setTotalAmazonStatementsToLoad(statementsList.length));
    statementsList.map((statementInfo) =>
      dispatch(
        uploadAmazonStatement(reportId, statementInfo.clientId, statementInfo)
      )
    );
  };

  const uploadTakealotStatements = (reportId, statementsList) => {
    dispatch(setTotalTakealotStatementsToLoad(statementsList.length));
    statementsList.map((statementInfo) =>
      dispatch(
        uploadTakealotStatement(reportId, statementInfo.clientId, statementInfo)
      )
    );
  };

  const loadAmazonSkus = (skusList) => {
    dispatch(setTotalAmazonSkusToLoad(skusList.length));
    skusList.map((sku) => dispatch(fetchAmazonSku(sku)));
  };

  const loadTakealotSkus = (skusList) => {
    dispatch(setTotalTakealotSkusToLoad(skusList.length));
    skusList.map((sku) => dispatch(fetchTakealotSku(sku)));
  };

  const getFormattedStatementReports = () => {
    return statementReports.map((reportInfo) => {
      return {
        id: reportInfo.id,
        startDate: reportInfo.startDate,
        endDate: reportInfo.endDate,
        status: reportInfo.status,
      };
    });
  };

  const getFormattedStatements = () => {
    if (
      amazonStatementsLoadingStatus !== 'COMPLETE' ||
      takealotStatementsLoadingStatus !== 'COMPLETE'
    ) {
      return [];
    }
    if (match.params.platform === 'amazon') {
      return amazonStatements.map((statementInfo) => {
        return {
          clientId: statementInfo.clientId,
          status: statementInfo.status,
          clientName: statementInfo.clientInfo.name,
          due: statementInfo.summary.totalDueToClient,
          units: statementInfo.summary.totalUnitsSold.total,
          unknownDiscountCount: statementInfo.summary.unknownDiscountCount,
        };
      });
    }
    return takealotStatements.map((statementInfo) => {
      return {
        clientId: statementInfo.clientId,
        status: statementInfo.status,
        clientName: statementInfo.clientInfo.name,
        due: statementInfo.summary.totalDueToClient,
        units: statementInfo.summary.totalUnitsSold.total,
        unknownDiscountCount: statementInfo.summary.unknownDiscountCount,
      };
    });
  };

  const getFormattedLineItems = () => {
    if (
      amazonStatementsLoadingStatus !== 'COMPLETE' ||
      takealotStatementsLoadingStatus !== 'COMPLETE'
    ) {
      return [];
    }
    if (match.params.platform === 'amazon') {
      const relevantStatement = amazonStatements.find(
        (info) => info.clientId === match.params.clientId
      );
      return (
        relevantStatement &&
        relevantStatement.lineItems.map((info, index) => {
          return {
            index,
            code: info.code,
            type: info.type,
            discountStatus: info.discountStatus,
            margin: info.easyonlineMargin,
            name: info.name,
            perUnit: info.perUnit,
            units: info.units,
            total: info.total,
            expectedRetail: info.expectedRetailPriceDollars,
            actualRetail: info.actualRetailPriceDollars,
          };
        })
      );
    }
    const relevantStatement = takealotStatements.find(
      (info) => info.clientId === match.params.clientId
    );
    return (
      relevantStatement &&
      relevantStatement.lineItems.map((info, index) => {
        return {
          index,
          code: info.code,
          type: info.type,
          discountStatus: info.discountStatus,
          margin: info.easyonlineMargin,
          name: info.name,
          perUnit: info.perUnit,
          units: info.units,
          total: info.total,
          expectedRetail: info.expectedRetailPriceRands,
          actualRetail: info.actualRetailPriceRands,
        };
      })
    );
  };

  const getRelevantStatement = () => {
    if (
      amazonStatementsLoadingStatus !== 'COMPLETE' ||
      takealotStatementsLoadingStatus !== 'COMPLETE'
    ) {
      return [];
    }
    if (match.params.platform === 'amazon') {
      const relevantStatement = amazonStatements.find(
        (info) => info.clientId === match.params.clientId
      );
      return relevantStatement;
    }
    const relevantStatement = takealotStatements.find(
      (info) => info.clientId === match.params.clientId
    );
    return relevantStatement;
  };

  const getRelevantReport = () => {
    const relevantReport = statementReports.find(
      (info) => info.id === match.params.reportId
    );
    return relevantReport;
  };

  const getStatementReportsActions = () => {
    return [
      {
        label: 'Create new statement report',
        icon: <AddIcon className={classes.actionIcon} />,
        onClick: () => setIsCreateNewStatementReportDialogOpen(true),
      },
    ];
  };

  const getStatementViewActions = () => {
    if (
      statementReportsLoadingStatus !== 'COMPLETE' ||
      amazonStatementsLoadingStatus !== 'COMPLETE' ||
      takealotStatementsLoadingStatus !== 'COMPLETE'
    ) {
      return [];
    }
    if (match.params.platform === 'amazon') {
      const relevantStatement = amazonStatements.find(
        (info) => info.clientId === match.params.clientId
      );
      if (relevantStatement.summary.unknownDiscountCount > 0) {
        return [
          {
            label: 'Add adjustment',
            icon: <AddIcon className={classes.actionIcon} />,
            onClick: () => setIsAddAdjustmentDialogOpen(true),
          },
        ];
      }
      if (relevantStatement.status === 'FINALIZED') {
        const relevantReport = statementReports.find(
          (info) => info.id === match.params.reportId
        );
        return [
          {
            label: 'Statement of Sales',
            icon: <GetAppIcon className={classes.actionIcon} />,
            type: 'pdfDownload',
            config: {
              document: (
                <StatementDocument
                  version={relevantStatement.version}
                  platform="amazon"
                  clientInfo={relevantStatement.clientInfo}
                  ourInfo={relevantReport.bankDetails}
                  startDate={relevantReport.startDate}
                  endDate={relevantReport.endDate}
                  lineItems={relevantStatement.lineItems}
                  totalDue={relevantStatement.summary.totalDueToClient}
                />
              ),
              fileName: `[${
                relevantStatement.clientInfo.name
              }] Amazon Statement of Sales (${moment(
                relevantReport.startDate
              ).format('DDMMYYYY')}-${moment(relevantReport.endDate).format(
                'DDMMYYYY'
              )}).pdf`,
            },
          },
        ];
      }
      return [
        {
          label: 'Add adjustment',
          icon: <AddIcon className={classes.actionIcon} />,
          onClick: () => setIsAddAdjustmentDialogOpen(true),
        },
        {
          label: 'Finalize',
          icon: <ThumbUpIcon className={classes.actionIcon} />,
          onClick: () =>
            dispatch(
              saveFinalizeAmazonStatement(
                match.params.reportId,
                match.params.clientId
              )
            ),
        },
      ];
    }

    const relevantStatement = takealotStatements.find(
      (info) => info.clientId === match.params.clientId
    );
    if (relevantStatement.summary.unknownDiscountCount > 0) {
      return [
        {
          label: 'Add adjustment',
          icon: <AddIcon className={classes.actionIcon} />,
          onClick: () => setIsAddAdjustmentDialogOpen(true),
        },
      ];
    }
    if (relevantStatement.status === 'FINALIZED') {
      const relevantReport = statementReports.find(
        (info) => info.id === match.params.reportId
      );

      // eslint-disable-next-line no-constant-condition
      if (relevantReport.version === 2 && false) {
        return [
          {
            label: 'Statement of Sales',
            icon: <GetAppIcon className={classes.actionIcon} />,
            type: 'pdfDownload',
            config: {
              document: (
                <StatementDocument
                  platform="takealot"
                  version={relevantReport.version}
                  // isClientVatRegistered={
                  //   relevantStatement.clientInfo.vatNumber.length === 10
                  // }
                  isClientVatRegistered={false}
                  clientInfo={relevantStatement.clientInfo}
                  ourInfo={relevantReport.bankDetails}
                  startDate={relevantReport.startDate}
                  endDate={relevantReport.endDate}
                  lineItems={relevantStatement.lineItems}
                  totalDue={relevantStatement.summary.totalRevenueRands.total}
                />
              ),
              fileName: `[${
                relevantStatement.clientInfo.name
              }] 1. Takealot Statement of Sales (${moment(
                relevantReport.startDate
              ).format('DDMMYYYY')}-${moment(relevantReport.endDate).format(
                'DDMMYYYY'
              )}).pdf`,
            },
          },
          {
            label: 'Invoice',
            icon: <GetAppIcon className={classes.actionIcon} />,
            type: 'pdfDownload',
            config: {
              document: (
                <StatementDocument
                  platform="takealot"
                  type="INVOICE"
                  version={relevantReport.version}
                  // isClientVatRegistered={
                  //   relevantStatement.clientInfo.vatNumber.length === 10
                  // }
                  isClientVatRegistered={false}
                  clientInfo={relevantStatement.clientInfo}
                  ourInfo={relevantReport.bankDetails}
                  startDate={relevantReport.startDate}
                  endDate={relevantReport.endDate}
                  lineItems={relevantStatement.lineItems}
                  totalDue={relevantStatement.summary.totalRevenueRands.total}
                />
              ),
              fileName: `[${
                relevantStatement.clientInfo.name
              }] 2. Takealot Invoice (${moment(relevantReport.startDate).format(
                'DDMMYYYY'
              )}-${moment(relevantReport.endDate).format('DDMMYYYY')}).pdf`,
            },
          },
          {
            label: 'Reconciliation',
            icon: <GetAppIcon className={classes.actionIcon} />,
            type: 'pdfDownload',
            config: {
              document: (
                <StatementDocument
                  platform="takealot"
                  type="RECON"
                  version={relevantReport.version}
                  // isClientVatRegistered={
                  //   relevantStatement.clientInfo.vatNumber.length === 10
                  // }
                  isClientVatRegistered={false}
                  clientInfo={relevantStatement.clientInfo}
                  ourInfo={relevantReport.bankDetails}
                  startDate={relevantReport.startDate}
                  endDate={relevantReport.endDate}
                  lineItems={relevantStatement.lineItems}
                  totalDue={relevantStatement.summary.totalRevenueRands.total}
                />
              ),
              fileName: `[${
                relevantStatement.clientInfo.name
              }] 3. Takealot Reconciliation (${moment(
                relevantReport.startDate
              ).format('DDMMYYYY')}-${moment(relevantReport.endDate).format(
                'DDMMYYYY'
              )}).pdf`,
            },
          },
        ];
      }
      return [
        {
          label: 'Statement of Sales',
          icon: <GetAppIcon className={classes.actionIcon} />,
          type: 'pdfDownload',
          config: {
            document: (
              <StatementDocument
                platform="takealot"
                clientInfo={relevantStatement.clientInfo}
                ourInfo={relevantReport.bankDetails}
                startDate={relevantReport.startDate}
                endDate={relevantReport.endDate}
                lineItems={relevantStatement.lineItems}
                totalDue={relevantStatement.summary.totalDueToClient}
              />
            ),
            fileName: `[${
              relevantStatement.clientInfo.name
            }] Takealot Statement of Sales (${moment(
              relevantReport.startDate
            ).format('DDMMYYYY')}-${moment(relevantReport.endDate).format(
              'DDMMYYYY'
            )}).pdf`,
          },
        },
      ];
    }
    return [
      {
        label: 'Add adjustment',
        icon: <AddIcon className={classes.actionIcon} />,
        onClick: () => setIsAddAdjustmentDialogOpen(true),
      },
      {
        label: 'Finalize',
        icon: <ThumbUpIcon className={classes.actionIcon} />,
        onClick: () =>
          dispatch(
            saveFinalizeTakealotStatement(
              match.params.reportId,
              match.params.clientId
            )
          ),
      },
    ];
  };

  const getAmazonOverallSummary = (reportId) => {
    const relevantReport = statementReports.find(
      (info) => info.id === reportId
    );
    if (relevantReport !== undefined) {
      return relevantReport.amazonSummary;
    }
    return {
      avgEasyonlineMargin: 0,
      clientCount: 0,
      skuCount: 0,
      totalDueToClient: 0,
      totalRevenueDollars: {
        discountedRefunds: 0,
        discountedSales: 0,
        standardRefunds: 0,
        standardSales: 0,
        total: 0,
      },
      totalUnitsSold: {
        discountedRefunds: 0,
        discountedSales: 0,
        standardRefunds: 0,
        standardSales: 0,
        total: 0,
      },
      unknownDiscountCount: 0,
    };
  };

  const getAmazonStatementSummary = (clientId) => {
    const relevantStatement = amazonStatements.find(
      (info) => info.clientId === clientId
    );
    if (relevantStatement !== undefined) {
      return relevantStatement.summary;
    }
    return {
      avgEasyonlineMargin: 0,
      clientCount: 0,
      skuCount: 0,
      totalDueToClient: 0,
      totalRevenueDollars: {
        discountedRefunds: 0,
        discountedSales: 0,
        standardRefunds: 0,
        standardSales: 0,
        total: 0,
      },
      totalUnitsSold: {
        discountedRefunds: 0,
        discountedSales: 0,
        standardRefunds: 0,
        standardSales: 0,
        total: 0,
      },
      unknownDiscountCount: 0,
    };
  };

  const getTakealotOverallSummary = (reportId) => {
    const relevantReport = statementReports.find(
      (info) => info.id === reportId
    );
    if (relevantReport !== undefined) {
      return relevantReport.takealotSummary;
    }
    return {
      avgEasyonlineMargin: 0,
      clientCount: 0,
      skuCount: 0,
      totalDueToClient: 0,
      totalRevenueRands: {
        discountedRefunds: 0,
        discountedSales: 0,
        standardRefunds: 0,
        standardSales: 0,
        total: 0,
      },
      totalUnitsSold: {
        discountedRefunds: 0,
        discountedSales: 0,
        standardRefunds: 0,
        standardSales: 0,
        total: 0,
      },
      unknownDiscountCount: 0,
    };
  };

  const getTakealotStatementSummary = (clientId) => {
    const relevantStatement = takealotStatements.find(
      (info) => info.clientId === clientId
    );
    if (relevantStatement !== undefined) {
      return relevantStatement.summary;
    }
    return {
      avgEasyonlineMargin: 0,
      clientCount: 0,
      skuCount: 0,
      totalDueToClient: 0,
      totalRevenueRands: {
        discountedRefunds: 0,
        discountedSales: 0,
        standardRefunds: 0,
        standardSales: 0,
        total: 0,
      },
      totalUnitsSold: {
        discountedRefunds: 0,
        discountedSales: 0,
        standardRefunds: 0,
        standardSales: 0,
        total: 0,
      },
      unknownDiscountCount: 0,
    };
  };

  const getAmazonTotals = () => {
    const amazonSummary = getAmazonOverallSummary(match.params.reportId);
    const revenue = `$ ${numeral(
      amazonSummary.totalRevenueDollars.total
    ).format('0,0')}`;
    const units = numeral(amazonSummary.totalUnitsSold.total).format('0,0');
    const dueToClients = `R ${numeral(amazonSummary.totalDueToClient).format(
      '0,0'
    )}`;
    const margin = `${numeral(amazonSummary.avgEasyonlineMargin * 100).format(
      '0,0'
    )} %`;

    return [
      {
        label: 'Revenue',
        value: revenue,
      },
      {
        label: 'Units',
        value: units,
      },
      {
        label: 'Due to Clients',
        value: dueToClients,
      },
      {
        label: 'Avg Margin',
        value: margin,
      },
    ];
  };

  const getTakealotTotals = () => {
    const takealotSummary = getTakealotOverallSummary(match.params.reportId);
    const revenue = `R ${numeral(
      takealotSummary.totalRevenueRands.total
    ).format('0,0')}`;
    const units = numeral(takealotSummary.totalUnitsSold.total).format('0,0');
    const dueToClients = `R ${numeral(takealotSummary.totalDueToClient).format(
      '0,0'
    )}`;
    const margin = `${numeral(takealotSummary.avgEasyonlineMargin * 100).format(
      '0,0'
    )} %`;

    return [
      {
        label: 'Revenue',
        value: revenue,
      },
      {
        label: 'Units',
        value: units,
      },
      {
        label: 'Due to Clients',
        value: dueToClients,
      },
      {
        label: 'Avg Margin',
        value: margin,
      },
    ];
  };

  const getAmazonStatementTotals = () => {
    const amazonSummary = getAmazonStatementSummary(match.params.clientId);
    const revenue = `$ ${numeral(
      amazonSummary.totalRevenueDollars.total
    ).format('0,0')}`;
    const units = numeral(amazonSummary.totalUnitsSold.total).format('0,0');
    const dueToClient = `R ${numeral(amazonSummary.totalDueToClient).format(
      '0,0'
    )}`;
    const margin = `${numeral(amazonSummary.avgEasyonlineMargin * 100).format(
      '0,0'
    )} %`;

    return [
      {
        label: 'Revenue',
        value: revenue,
      },
      {
        label: 'Units',
        value: units,
      },
      {
        label: 'Due to Client',
        value: dueToClient,
      },
      {
        label: 'Avg Margin',
        value: margin,
      },
    ];
  };

  const getTakealotStatementTotals = () => {
    const takealotSummary = getTakealotStatementSummary(match.params.clientId);
    const revenue = `R ${numeral(
      takealotSummary.totalRevenueRands.total
    ).format('0,0')}`;
    const units = numeral(takealotSummary.totalUnitsSold.total).format('0,0');
    const dueToClient = `R ${numeral(takealotSummary.totalDueToClient).format(
      '0,0'
    )}`;
    const margin = `${numeral(takealotSummary.avgEasyonlineMargin * 100).format(
      '0,0'
    )} %`;

    return [
      {
        label: 'Revenue',
        value: revenue,
      },
      {
        label: 'Units',
        value: units,
      },
      {
        label: 'Due to Client',
        value: dueToClient,
      },
      {
        label: 'Avg Margin',
        value: margin,
      },
    ];
  };

  const preparePriceFixData = (
    reportId,
    clientId,
    lineItemIndex,
    expectedRetail,
    dueToClient,
    discountStatus
  ) => {
    const getOverallMetrics = (statements, platform) => {
      let count = 0;
      let totalEasyonlineMargin = 0;
      let totalDueToClient = 0;
      const totalUnitsSold = {
        standardSales: 0,
        discountedSales: 0,
        standardRefunds: 0,
        discountedRefunds: 0,
        total: 0,
      };
      const totalRevenue = {
        standardSales: 0,
        discountedSales: 0,
        standardRefunds: 0,
        discountedRefunds: 0,
        total: 0,
      };

      statements.forEach((statement) => {
        count += 1;
        totalEasyonlineMargin += statement.summary.avgEasyonlineMargin;
        totalDueToClient += statement.summary.totalDueToClient;

        totalUnitsSold.standardSales +=
          statement.summary.totalUnitsSold.standardSales;
        totalUnitsSold.discountedSales +=
          statement.summary.totalUnitsSold.discountedSales;
        totalUnitsSold.standardRefunds +=
          statement.summary.totalUnitsSold.standardRefunds;
        totalUnitsSold.discountedRefunds +=
          statement.summary.totalUnitsSold.discountedRefunds;
        totalUnitsSold.total += statement.summary.totalUnitsSold.total;

        if (platform === 'amazon') {
          totalRevenue.standardSales +=
            statement.summary.totalRevenueDollars.standardSales;
          totalRevenue.discountedSales +=
            statement.summary.totalRevenueDollars.discountedSales;
          totalRevenue.standardRefunds +=
            statement.summary.totalRevenueDollars.standardRefunds;
          totalRevenue.discountedRefunds +=
            statement.summary.totalRevenueDollars.discountedRefunds;
          totalRevenue.total += statement.summary.totalRevenueDollars.total;
        } else {
          totalRevenue.standardSales +=
            statement.summary.totalRevenueRands.standardSales;
          totalRevenue.discountedSales +=
            statement.summary.totalRevenueRands.discountedSales;
          totalRevenue.standardRefunds +=
            statement.summary.totalRevenueRands.standardRefunds;
          totalRevenue.discountedRefunds +=
            statement.summary.totalRevenueRands.discountedRefunds;
          totalRevenue.total += statement.summary.totalRevenueRands.total;
        }
      });

      if (platform === 'amazon') {
        return {
          totalDueToClient,
          totalUnitsSold,
          totalRevenue,
          avgEasyonlineMargin: totalEasyonlineMargin / count,
        };
      }
      return {
        totalDueToClient,
        totalUnitsSold,
        totalRevenue,
        avgEasyonlineMargin: totalEasyonlineMargin / count,
      };
    };

    const getAvgEasyonlineMargin = (lineItems) => {
      let total = 0;
      let count = 0;
      lineItems
        .filter((lineItem) => lineItem.type !== 'ADJUSTMENT')
        .forEach((lineItem) => {
          count += 1;
          total += lineItem.easyonlineMargin;
        });
      return total / count;
    };

    const getTotalDueToClient = (lineItems) => {
      let total = 0;
      lineItems.forEach((lineItem) => {
        total += lineItem.total;
      });
      return total;
    };

    const getRevenueTotals = (lineItems, platform) => {
      let standardSales = 0;
      let discountedSales = 0;
      let standardRefunds = 0;
      let discountedRefunds = 0;
      let total = 0;

      if (platform === 'amazon') {
        lineItems
          .filter((lineItem) => lineItem.type !== 'ADJUSTMENT')
          .forEach((lineItem) => {
            if (lineItem.type === 'SALE') {
              if (lineItem.discountStatus === 'STANDARD') {
                standardSales += lineItem.totalRevenueDollars;
              } else {
                discountedSales += lineItem.totalRevenueDollars;
              }
            } else if (lineItem.discountStatus === 'STANDARD') {
              standardRefunds += lineItem.totalRevenueDollars;
            } else {
              discountedRefunds += lineItem.totalRevenueDollars;
            }
            total += lineItem.totalRevenueDollars;
          });
      } else {
        lineItems
          .filter((lineItem) => lineItem.type !== 'ADJUSTMENT')
          .forEach((lineItem) => {
            if (lineItem.type === 'SALE') {
              if (lineItem.discountStatus === 'STANDARD') {
                standardSales += lineItem.totalRevenueRands;
              } else {
                discountedSales += lineItem.totalRevenueRands;
              }
            } else if (lineItem.discountStatus === 'STANDARD') {
              standardRefunds += lineItem.totalRevenueRands;
            } else {
              discountedRefunds += lineItem.totalRevenueRands;
            }
            total += lineItem.totalRevenueRands;
          });
      }

      return {
        standardSales,
        discountedSales,
        standardRefunds,
        discountedRefunds,
        total,
      };
    };

    const getUnitTotals = (lineItems) => {
      let standardSales = 0;
      let discountedSales = 0;
      let standardRefunds = 0;
      let discountedRefunds = 0;
      let total = 0;

      lineItems
        .filter((lineItem) => lineItem.type !== 'ADJUSTMENT')
        .forEach((lineItem) => {
          if (lineItem.type === 'SALE') {
            if (lineItem.discountStatus === 'STANDARD') {
              standardSales += lineItem.units;
            } else {
              discountedSales += lineItem.units;
            }
          } else if (lineItem.discountStatus === 'STANDARD') {
            standardRefunds += lineItem.units;
          } else {
            discountedRefunds += lineItem.units;
          }
          total += lineItem.units;
        });

      return {
        standardSales,
        discountedSales,
        standardRefunds,
        discountedRefunds,
        total,
      };
    };

    const report = getRelevantReport();
    const statement = getRelevantStatement();
    const { platform } = match.params;

    const updatedLineItems = statement.lineItems.map((item, index) => {
      if (index === lineItemIndex) {
        if (platform === 'amazon') {
          const { type } = item;
          let total = item.units * dueToClient;
          if (type === 'REFUND') {
            total = -total;
          }
          const exchangeRate = statement.summary.randDollarExchangeRate;
          const { revenueAfterFeesDollars } = item;
          const totalRevenue = item.totalRevenueDollars * exchangeRate;
          const easyonlineMargin =
            (revenueAfterFeesDollars * exchangeRate - total) / totalRevenue;

          return {
            ...item,
            discountStatus,
            total,
            easyonlineMargin,
            expectedRetailPriceDollars: expectedRetail,
            perUnit: dueToClient,
          };
        }
        const { type } = item;
        let total = item.units * dueToClient;
        if (type === 'REFUND') {
          total = -total;
        }
        const { revenueAfterFeesRands } = item;
        const totalRevenue = item.totalRevenueRands;
        const easyonlineMargin = (revenueAfterFeesRands - total) / totalRevenue;

        return {
          ...item,
          discountStatus,
          total,
          easyonlineMargin,
          expectedRetailPriceRands: expectedRetail,
          perUnit: dueToClient,
        };
      }
      return item;
    });

    const statementUpdates = {
      lineItems: updatedLineItems,
      'summary.unknownDiscountCount':
        statement.summary.unknownDiscountCount - 1,
      'summary.avgEasyonlineMargin': getAvgEasyonlineMargin(updatedLineItems),
      'summary.totalDueToClient': getTotalDueToClient(updatedLineItems),
      [`summary.totalRevenue${platform === 'amazon' ? 'Dollars' : 'Rands'}`]:
        getRevenueTotals(updatedLineItems, platform),
      'summary.totalUnitsSold': getUnitTotals(updatedLineItems),
    };

    const newStatement = {
      ...statement,
      lineItems: updatedLineItems,
      summary: {
        ...statement.summary,
        unknownDiscountCount: statement.summary.unknownDiscountCount - 1,
        avgEasyonlineMargin: getAvgEasyonlineMargin(updatedLineItems),
        totalDueToClient: getTotalDueToClient(updatedLineItems),
        [`totalRevenue${platform === 'amazon' ? 'Dollars' : 'Rands'}`]:
          getRevenueTotals(updatedLineItems, platform),
        totalUnitsSold: getUnitTotals(updatedLineItems),
      },
    };
    const statementsWithUpdates =
      platform === 'amazon'
        ? amazonStatements.map((info) => {
            if (info.clientId === clientId) {
              return newStatement;
            }
            return info;
          })
        : takealotStatements.map((info) => {
            if (info.clientId === clientId) {
              return newStatement;
            }
            return info;
          });
    const overallMetrics = getOverallMetrics(statementsWithUpdates, platform);
    const platformSummaryKey = `${platform}Summary`;
    const reportUpdates = {
      [`${platformSummaryKey}.unknownDiscountCount`]:
        report[platformSummaryKey].unknownDiscountCount - 1,
      [`${platformSummaryKey}.avgEasyonlineMargin`]:
        overallMetrics.avgEasyonlineMargin,
      [`${platformSummaryKey}.totalDueToClient`]:
        overallMetrics.totalDueToClient,
      [`${platformSummaryKey}.totalRevenue${
        platform === 'amazon' ? 'Dollars' : 'Rands'
      }`]: overallMetrics.totalRevenue,
      [`${platformSummaryKey}.totalUnitsSold`]: overallMetrics.totalUnitsSold,
    };

    dispatch(
      uploadPriceFixes(
        reportId,
        clientId,
        statementUpdates,
        reportUpdates,
        newStatement,
        platform
      )
    );
  };

  const prepareAdjustmentAddition = (reportId, clientId, name, amount) => {
    const getOverallTotalDueToClient = (statements) => {
      let totalDueToClient = 0;

      statements.forEach((statement) => {
        totalDueToClient += statement.summary.totalDueToClient;
      });

      return totalDueToClient;
    };

    const getTotalDueToClient = (lineItems) => {
      let total = 0;
      lineItems.forEach((lineItem) => {
        total += lineItem.total;
      });
      return total;
    };

    const statement = getRelevantStatement();
    const { platform } = match.params;

    const updatedLineItems = [
      ...statement.lineItems,
      {
        name,
        total: amount,
        type: 'ADJUSTMENT',
      },
    ];

    const statementUpdates = {
      lineItems: updatedLineItems,
      'summary.totalDueToClient': getTotalDueToClient(updatedLineItems),
    };

    const newStatement = {
      ...statement,
      lineItems: updatedLineItems,
      summary: {
        ...statement.summary,
        totalDueToClient: getTotalDueToClient(updatedLineItems),
      },
    };
    const statementsWithUpdates =
      platform === 'amazon'
        ? amazonStatements.map((info) => {
            if (info.clientId === clientId) {
              return newStatement;
            }
            return info;
          })
        : takealotStatements.map((info) => {
            if (info.clientId === clientId) {
              return newStatement;
            }
            return info;
          });
    const overallTotalDueToClient = getOverallTotalDueToClient(
      statementsWithUpdates
    );
    const platformSummaryKey = `${platform}Summary`;
    const reportUpdates = {
      [`${platformSummaryKey}.totalDueToClient`]: overallTotalDueToClient,
    };

    dispatch(
      uploadAdjustmentAddition(
        reportId,
        clientId,
        statementUpdates,
        reportUpdates,
        newStatement,
        platform
      )
    );
  };

  const prepareAdjustmentRemoval = (reportId, clientId, lineItemIndex) => {
    const getOverallTotalDueToClient = (statements) => {
      let totalDueToClient = 0;

      statements.forEach((statement) => {
        totalDueToClient += statement.summary.totalDueToClient;
      });

      return totalDueToClient;
    };

    const getTotalDueToClient = (lineItems) => {
      let total = 0;
      lineItems.forEach((lineItem) => {
        total += lineItem.total;
      });
      return total;
    };

    const statement = getRelevantStatement();
    const { platform } = match.params;

    const updatedLineItems = statement.lineItems.filter(
      (item, index) => index !== lineItemIndex
    );

    const statementUpdates = {
      lineItems: updatedLineItems,
      'summary.totalDueToClient': getTotalDueToClient(updatedLineItems),
    };

    const newStatement = {
      ...statement,
      lineItems: updatedLineItems,
      summary: {
        ...statement.summary,
        totalDueToClient: getTotalDueToClient(updatedLineItems),
      },
    };
    const statementsWithUpdates =
      platform === 'amazon'
        ? amazonStatements.map((info) => {
            if (info.clientId === clientId) {
              return newStatement;
            }
            return info;
          })
        : takealotStatements.map((info) => {
            if (info.clientId === clientId) {
              return newStatement;
            }
            return info;
          });
    const overallTotalDueToClient = getOverallTotalDueToClient(
      statementsWithUpdates
    );
    const platformSummaryKey = `${platform}Summary`;
    const reportUpdates = {
      [`${platformSummaryKey}.totalDueToClient`]: overallTotalDueToClient,
    };

    dispatch(
      uploadAdjustmentRemoval(
        reportId,
        clientId,
        statementUpdates,
        reportUpdates,
        newStatement,
        platform
      )
    );
  };

  const getClientName = (clientId, platform) => {
    if (platform === 'amazon') {
      const statementInfo = amazonStatements.find(
        (statement) => statement.clientId === clientId
      );
      if (statementInfo !== undefined) {
        return statementInfo.clientInfo.name;
      }
      return '';
    }
    const statementInfo = takealotStatements.find(
      (statement) => statement.clientId === clientId
    );
    if (statementInfo !== undefined) {
      return statementInfo.clientInfo.name;
    }
    return '';
  };

  const getPlatformName = (platform) => {
    if (platform === 'amazon') {
      return 'Amazon';
    }
    return 'Takealot';
  };

  const getBreadcrumbs = () => {
    const breadcrumbs = [
      {
        label: 'Finance',
        link: '/finance',
      },
      {
        label: 'Statements',
        link: '/finance/statements',
      },
      {
        label: 'Reports',
        link: '/finance/statements/reports',
      },
    ];

    if (match.params.platform) {
      breadcrumbs.push({
        label: 'Platform Selection',
        link: `/finance/statements/reports/${match.params.reportId}`,
      });
    }
    if (match.params.clientId) {
      breadcrumbs.push({
        label: getPlatformName(match.params.platform),
        link: `/finance/statements/reports/${match.params.reportId}/${match.params.platform}`,
      });
    }

    return breadcrumbs;
  };

  if (match.params.clientId) {
    const relevantStatement =
      match.params.platform === 'amazon'
        ? amazonStatements.find(
            (info) => info.clientId === match.params.clientId
          )
        : takealotStatements.find(
            (info) => info.clientId === match.params.clientId
          );

    return (
      <>
        <PageHeader
          title={`${
            match.params.platform === 'takealot' ? 'Takealot' : 'Amazon'
          } Statement`}
          subtitle={getClientName(match.params.clientId, match.params.platform)}
          breadcrumbs={getBreadcrumbs()}
          currentPageBreadcrumb="Statements"
          goToLink={(link) => history.push(link)}
          goBack={() => history.goBack()}
          actions={getStatementViewActions()}
        />
        <ContentWrapper>
          {amazonStatementsLoadingStatus === 'PENDING' ||
          takealotStatementsLoadingStatus === 'PENDING' ||
          reportStatementsLoadingStatus === 'PENDING' ||
          pricingFixLoadingStatus === 'PENDING' ||
          addAdjustmentLoadingStatus === 'PENDING' ||
          removeAdjustmentLoadingStatus === 'PENDING' ? (
            <div className={classes.loadingWrapper}>
              <CircularProgress />
            </div>
          ) : (
            <div>
              <div className={classes.totalsBarWrapper}>
                <TotalsBar
                  totals={
                    match.params.platform === 'amazon'
                      ? getAmazonStatementTotals()
                      : getTakealotStatementTotals()
                  }
                />
              </div>
              <div className={classes.separator} />
              <StatementViewTables
                isFinalized={
                  relevantStatement && relevantStatement.status === 'FINALIZED'
                }
                lineItems={getFormattedLineItems()}
                fixPricing={(lineItemInfo) => {
                  setIsFixPricingDialogOpen(true);
                  setFixPricingLineItem(lineItemInfo);
                }}
                removeAdjustment={(lineItemIndex) =>
                  prepareAdjustmentRemoval(
                    match.params.reportId,
                    match.params.clientId,
                    lineItemIndex
                  )
                }
              />
            </div>
          )}
          <AddAdjustmentDialog
            isOpen={isAddAdjustmentDialogOpen}
            closeDialog={() => setIsAddAdjustmentDialogOpen(false)}
            addAdjustment={(name, amount) =>
              prepareAdjustmentAddition(
                match.params.reportId,
                match.params.clientId,
                name,
                amount
              )
            }
          />
          <FixPricingDialog
            isOpen={isFixPricingDialogOpen}
            code={fixPricingLineItem.code}
            productName={fixPricingLineItem.name}
            purchasePrice={fixPricingLineItem.actualRetail}
            retailPrice={fixPricingLineItem.expectedRetail}
            platform={match.params.platform}
            initalPaidToClient={fixPricingLineItem.perUnit}
            fixPricing={(expectedRetail, dueToClient, discountStatus) =>
              preparePriceFixData(
                match.params.reportId,
                match.params.clientId,
                fixPricingLineItem.index,
                expectedRetail,
                dueToClient,
                discountStatus
              )
            }
            closeDialog={() => setIsFixPricingDialogOpen(false)}
          />
        </ContentWrapper>
      </>
    );
  }

  if (match.params.platform) {
    return (
      <>
        <PageHeader
          title={`${getPlatformName(match.params.platform)} Statements`}
          breadcrumbs={getBreadcrumbs()}
          currentPageBreadcrumb={getPlatformName(match.params.platform)}
          goToLink={(link) => history.push(link)}
          goBack={() => history.goBack()}
          actions={[]}
        />
        <ContentWrapper>
          {amazonStatementsLoadingStatus === 'PENDING' ||
          takealotStatementsLoadingStatus === 'PENDING' ||
          reportStatementsLoadingStatus === 'PENDING' ? (
            <div className={classes.loadingWrapper}>
              <CircularProgress />
            </div>
          ) : (
            <div>
              <div className={classes.totalsBarWrapper}>
                <TotalsBar
                  totals={
                    match.params.platform === 'amazon'
                      ? getAmazonTotals()
                      : getTakealotTotals()
                  }
                />
              </div>
              <div className={classes.separator} />
              <StatementsTable
                pageSize={
                  match.params.platform === 'amazon'
                    ? amazonPageSize
                    : takealotPageSize
                }
                initialPage={
                  match.params.platform === 'amazon'
                    ? amazonInitialPage
                    : takealotInitialPage
                }
                updatePageSize={(newPageSize) => {
                  if (match.params.platform === 'amazon') {
                    dispatch(updateAmazonPageSize(newPageSize));
                  } else {
                    dispatch(updateTakealotPageSize(newPageSize));
                  }
                }}
                updateInitialPage={(newInitialPage) => {
                  if (match.params.platform === 'amazon') {
                    dispatch(updateAmazonInitialPage(newInitialPage));
                  } else {
                    dispatch(updateTakealotInitialPage(newInitialPage));
                  }
                }}
                statements={getFormattedStatements()}
                viewStatement={(clientId) =>
                  history.push(
                    `/finance/statements/reports/${match.params.reportId}/${match.params.platform}/${clientId}`
                  )
                }
              />
            </div>
          )}
        </ContentWrapper>
      </>
    );
  }

  if (match.params.reportId) {
    return (
      <>
        <PageHeader
          title="Select Platform"
          breadcrumbs={getBreadcrumbs()}
          currentPageBreadcrumb="Platform Selection"
          goToLink={(link) => history.push(link)}
          goBack={() => history.goBack()}
          actions={[]}
        />
        <ContentWrapper>
          <PlatformSelector
            selectPlatform={(platform) =>
              history.push(
                `/finance/statements/reports/${match.params.reportId}/${platform}`
              )
            }
          />
        </ContentWrapper>
      </>
    );
  }

  return (
    <>
      <PageHeader
        title="Statements"
        breadcrumbs={getBreadcrumbs()}
        currentPageBreadcrumb="Browse"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
        actions={getStatementReportsActions()}
      />
      <ContentWrapper>
        <StatementReportsTable
          isLoading={statementReportsLoadingStatus === 'PENDING'}
          statementReports={getFormattedStatementReports()}
          viewReport={(reportId) =>
            history.push(`/finance/statements/reports/${reportId}`)
          }
        />
        <CreateNewStatementReportDialog
          clientLoadingCount={clientLoadingCount}
          clients={clients}
          loadClients={loadClients}
          failedClientIds={failedClientIds}
          amazonSkuLoadingCount={amazonSkuLoadingCount}
          loadAmazonSkus={loadAmazonSkus}
          amazonFailedSkus={amazonFailedSkus}
          amazonSkus={amazonSkus}
          takealotSkuLoadingCount={takealotSkuLoadingCount}
          loadTakealotSkus={loadTakealotSkus}
          takealotFailedSkus={takealotFailedSkus}
          takealotSkus={takealotSkus}
          isOpen={isCreateNewStatementReportDialogOpen}
          addReportLoadingStatus={addReportLoadingStatus}
          addStatementReport={(reportInfo) =>
            dispatch(addStatementReport(reportInfo))
          }
          amazonStatementsLoadingCount={amazonStatementsLoadingCount}
          takealotStatementsLoadingCount={takealotStatementsLoadingCount}
          amazonFailedStatements={amazonFailedStatements}
          takealotFailedStatements={takealotFailedStatements}
          uploadAmazonStatements={uploadAmazonStatements}
          uploadTakealotStatements={uploadTakealotStatements}
          closeDialog={() => setIsCreateNewStatementReportDialogOpen(false)}
        />
      </ContentWrapper>
    </>
  );
};

export default FinanceStatements;
