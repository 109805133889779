import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { fetchSuppliers, updateInitialPage, updatePageSize } from './slice';
import getFormattedListings from './logic';
import ContentWrapper from '../../../components/ContentWrapper';
import EmptyViewMessage from '../../../components/EmptyViewMessage';
import FiltersBar from './components/FiltersBar';
import PageHeader from '../../../components/PageHeader';
import SuppliersTable from './components/SuppliersTable';

const breadcrumbs = [
  {
    label: 'Sales',
    link: '/sales',
  },
  {
    label: 'Suppliers',
    link: '/sales/suppliers',
  },
];

const ListingsBasicInfo = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [platformFilter, setPlatformFilter] = useState('TAKEALOT');
  const [clientFilter, setClientFilter] = useState('ALL');
  const [statusFilter, setStatusFilter] = useState('ACTIVE');

  const suppliers = useSelector((state) => state.suppliersBrowse.suppliersList);
  const pageSize = useSelector((state) => state.suppliersBrowse.pageSize);
  const initialPage = useSelector((state) => state.suppliersBrowse.initialPage);
  const suppliersLoadingStatus = useSelector(
    (state) => state.suppliersBrowse.loadingStatus.suppliers
  );

  useEffect(() => {
    if (suppliersLoadingStatus !== 'PENDING') {
      dispatch(fetchSuppliers());
    }
  }, []);

  useEffect(() => {
    if (suppliersLoadingStatus === 'PENDING') {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [suppliersLoadingStatus]);

  return (
    <div>
      <PageHeader
        title="Browse Suppliers"
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Browse"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
        actions={[
          {
            label: 'Add new Supplier',
            icon: <AddIcon />,
            onClick: () =>
              history.push({
                pathname: '/sales/supplier/browse/add',
                state: { selectedData: {}, type: 'add' },
              }),
          },
        ]}
      />
      {isLoading && <LinearProgress color="secondary" />}
      <ContentWrapper>
        {!isLoading && suppliers.length === 0 ? (
          <EmptyViewMessage
            heading="No SKUs Available"
            message="Please adjust the filters or add new SKU to view them here."
          />
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FiltersBar
                searchText={searchQuery}
                changeSearchText={setSearchQuery}
                listingCount={
                  getFormattedListings(
                    suppliers,
                    searchQuery,
                    clientFilter,
                    platformFilter,
                    statusFilter
                  ).length
                }
                platformFilter={platformFilter}
                changePlatformFilter={(newPlatform) => {
                  setPlatformFilter(newPlatform);
                  setClientFilter('ALL');
                }}
                clientFilter={clientFilter}
                statusFilter={statusFilter}
                changeStatusFilter={setStatusFilter}
              />
              <SuppliersTable
                platform={platformFilter}
                isLoading={isLoading}
                suppliers={getFormattedListings(
                  suppliers,
                  searchQuery,
                  clientFilter,
                  platformFilter,
                  statusFilter
                )}
                viewSupplier={(sku) =>
                  history.push(`/sales/suppliers/browse/${sku}`)
                }
                editSupplier={(data) =>
                  history.push({
                    pathname: `/sales/suppliers/browse/edit/${data.sku}`,
                    state: { selectedData: data, type: 'edit' },
                  })
                }
                pageSize={pageSize}
                initialPage={initialPage}
                updatePageSize={(newPageSize) =>
                  dispatch(updatePageSize(newPageSize))
                }
                updateInitialPage={(newInitialPage) =>
                  dispatch(updateInitialPage(newInitialPage))
                }
              />
            </Grid>
          </Grid>
        )}
      </ContentWrapper>
    </div>
  );
};

export default ListingsBasicInfo;
