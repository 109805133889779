import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ClientSelector from './components/ClientSelector';
import DestinationSelector from './components/DestinationSelector';
import WarningsSelector from './components/WarningsSelector';

const useStyles = makeStyles((theme) => ({
  results: {
    margin: theme.spacing(1),
    color: grey[800],
  },
  separator: {
    width: theme.spacing(1),
  },
  wrapperButtons: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  wrapperRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
}));

const ReplenishmentsFiltersBar = ({
  searchText,
  changeSearchText,
  replenishmentCount,
  destinationFilter,
  changeDestinationFilter,
  clientFilter,
  changeClientFilter,
  clientOptions,
  warningsFilter,
  changeWarningsFilter,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapperRoot}>
      <TextField
        placeholder="Search"
        value={searchText}
        onChange={(e) => changeSearchText(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <Typography className={classes.results} variant="body1" component="span">
        {`${replenishmentCount} ${
          replenishmentCount === 1 ? 'result' : 'results'
        } displayed`}
      </Typography>
      <div className={classes.wrapperButtons}>
        <DestinationSelector
          destinationFilter={destinationFilter}
          changeDestinationFilter={changeDestinationFilter}
        />
        <div className={classes.separator} />
        <ClientSelector
          clientFilter={clientFilter}
          changeClientFilter={changeClientFilter}
          clientOptions={clientOptions}
        />
        <div className={classes.separator} />
        {destinationFilter !== 'EASYONLINE' && (
          <WarningsSelector
            warningsFilter={warningsFilter}
            changeWarningsFilter={changeWarningsFilter}
          />
        )}
      </div>
    </div>
  );
};

ReplenishmentsFiltersBar.defaultProps = {
  searchText: '',
  changeSearchText: (newValue) => `Search text was changed to '${newValue}'`,
  replenishmentCount: 0,
  destinationFilter: 'ALL',
  changeDestinationFilter: (newFilter) =>
    console.log('User changes the destination filter to:', newFilter),
  clientFilter: 'ALL',
  changeClientFilter: (newFilter) =>
    console.log('User changes the client filter to:', newFilter),
  clientOptions: [],
  warningsFilter: 'ALL',
  changeWarningsFilter: (newFilter) =>
    console.log('User changes the warnings filter to:', newFilter),
};

ReplenishmentsFiltersBar.propTypes = {
  searchText: PropTypes.string,
  changeSearchText: PropTypes.func,
  replenishmentCount: PropTypes.number,
  destinationFilter: PropTypes.string,
  changeDestinationFilter: PropTypes.func,
  clientFilter: PropTypes.string,
  changeClientFilter: PropTypes.func,
  clientOptions: PropTypes.arrayOf(PropTypes.string),
  warningsFilter: PropTypes.string,
  changeWarningsFilter: PropTypes.func,
};

export default ReplenishmentsFiltersBar;
