import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import allStyles from '../../utils/styles';

const useStyles = makeStyles(allStyles);

const SearchBar = ({ searchText, changeSearchText }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapperRoot}>
      <TextField
        placeholder="Search"
        value={searchText}
        onChange={(e) => changeSearchText(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />

      <div className={classes.separator} />
    </div>
  );
};

SearchBar.defaultProps = {
  searchText: '',
  changeSearchText: (newValue) => `Search text was changed to '${newValue}'`,
};

SearchBar.propTypes = {
  searchText: PropTypes.string,
  changeSearchText: PropTypes.func,
};

export default SearchBar;
