/* eslint-disable react/jsx-wrap-multilines */
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import IconButton from '@material-ui/core/IconButton';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  avatarActive: {
    backgroundColor: theme.palette.common.white,
  },
  avatarInactive: {
    backgroundColor: theme.palette.secondary.light,
  },
  actionBar: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  formControl: {
    flexGrow: 1,
  },
  media: {
    backgroundSize: 'contain',
    height: 180,
    margin: theme.spacing(2),
  },
  separator: {
    width: theme.spacing(1),
  },
}));

const ImagePreviewCard = ({
  fileSize,
  imageLink,
  isLoading,
  showLinkCopyFeedback,
  deleteImage,
  imageDate,
}) => {
  const classes = useStyles();

  const getFormattedFileSize = () => {
    return numeral(fileSize / 1048576).format('0,0.00');
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        subheader={`${getFormattedFileSize(fileSize)} mb | ${`Uploaded ${
          imageDate ? moment(imageDate.toDate()).format('DD/MM/YYYY') : '-'
        }`}`}
      />
      <CardMedia className={classes.media} image={imageLink} />
      <CardActions className={classes.actionBar}>
        <Tooltip title="View image">
          <IconButton
            onClick={() => window.open(imageLink, '_blank')}
            size="small"
            disabled={isLoading}
          >
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Copy link">
          <IconButton
            onClick={() => {
              navigator.clipboard.writeText(imageLink);
              showLinkCopyFeedback();
            }}
            size="small"
            disabled={isLoading}
          >
            <FileCopyIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete image">
          <IconButton onClick={deleteImage} size="small" disabled={isLoading}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </CardActions>
    </Card>
  );
};

ImagePreviewCard.defaultProps = {
  imageLink: '',
  fileSize: 12000000,
  isLoading: false,
  showLinkCopyFeedback: () => console.log('Image was copied to the clipboard.'),
  deleteImage: () => {},
  imageDate: '',
};

ImagePreviewCard.propTypes = {
  fileSize: PropTypes.number,
  imageLink: PropTypes.string,
  isLoading: PropTypes.bool,
  showLinkCopyFeedback: PropTypes.func,
  deleteImage: PropTypes.func,
  imageDate: PropTypes.shape(),
};

export default ImagePreviewCard;
