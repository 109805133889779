import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import allStyles from '../../utils/styles';

const headerStyle = {
  backgroundColor: '#A7A5A5',
  color: '#171415',
};

const useStyles = makeStyles(allStyles);

const PresaleSkuTable = ({ skus, updateSelectedProducts, contentTitle }) => {
  const classes = useStyles();

  const getStatusDisplay = (status) => {
    switch (status) {
      case 'UPDATED':
        return (
          <Chip
            icon={<DoneIcon className={classes.icon} />}
            label="Updated"
            className={classes.statusUpdated}
          />
        );
      case 'NOT_UPDATED':
        return (
          <Chip
            icon={<ClearIcon className={classes.icon} />}
            label="Not Updated"
            className={classes.statusNotUpdated}
          />
        );
      default:
        return (
          <Chip
            icon={<ClearIcon className={classes.icon} />}
            label="Not Updated"
            className={classes.statusNotUpdated}
          />
        );
    }
  };

  const columns = [
    {
      title: 'SKU',
      field: 'eolSku',
      editable: 'never',
    },
    {
      title: 'Supplier Description',
      field: 'supplierDescription',
      editable: 'never',
    },
    {
      title: contentTitle,
      editable: 'never,',
      render: (rowData) => {
        if (rowData.isUpdated) {
          return getStatusDisplay('UPDATED');
        }
        return getStatusDisplay('NOT_UPDATED');
      },
    },
  ];

  return (
    <div className={classes.wrapperContent}>
      <MaterialTable
        columns={columns}
        data={skus}
        options={{
          headerStyle,
          sorting: false,
          grouping: false,
          draggable: false,
          search: false,
          showTitle: false,
          toolbar: false,
          selection: true,
        }}
        components={{
          OverlayLoading: () => (
            <div className={classes.loadingOverlay}>
              <CircularProgress color="primary" size={32} />
            </div>
          ),
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'No available products.',
          },
        }}
        onSelectionChange={(rows) => {
          updateSelectedProducts(
            rows.map(({ skuId, eolSku, supplierDescription }) => ({
              skuId,
              eolSku,
              supplierDescription,
            }))
          );
        }}
      />
    </div>
  );
};

PresaleSkuTable.defaultProps = {
  skus: [],
  updateSelectedProducts: (rows) =>
    console.log(`Users selected the following rows:`, rows),
  contentTitle: '',
};

PresaleSkuTable.propTypes = {
  skus: PropTypes.arrayOf(PropTypes.shape()),
  updateSelectedProducts: PropTypes.func,
  contentTitle: PropTypes.string,
};

export default PresaleSkuTable;
