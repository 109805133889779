import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import numeral from 'numeral';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  loadingWrapper: {
    padding: theme.spacing(8),
    textAlign: 'center',
  },
}));

const BasicInfoCard = ({
  creationDate,
  fromWarehouse,
  totalUnits,
  totalSkus,
  isLoading,
}) => {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader title="Shipment Info" />
      <Divider />
      <CardContent>
        {isLoading ? (
          <div className={classes.loadingWrapper}>
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <List>
            <ListItem>
              <ListItemText
                primary={moment(creationDate).format('D MMMM YYYY')}
                secondary="Creation Date"
              />
            </ListItem>
            <ListItem>
              <ListItemText primary={fromWarehouse} secondary="From" />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={numeral(totalSkus).format('0,0')}
                secondary="Total SKUs"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={numeral(totalUnits).format('0,0')}
                secondary="Total Units"
              />
            </ListItem>
          </List>
        )}
      </CardContent>
    </Card>
  );
};

BasicInfoCard.defaultProps = {
  isLoading: false,
  creationDate: moment().toDate().toISOString(),
  fromWarehouse: 'EasyOnline',
  totalUnits: 3,
  totalSkus: 63,
};

BasicInfoCard.propTypes = {
  isLoading: PropTypes.bool,
  creationDate: PropTypes.string,
  fromWarehouse: PropTypes.string,
  totalSkus: PropTypes.number,
  totalUnits: PropTypes.number,
};

export default BasicInfoCard;
