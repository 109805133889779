import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ClientSelector from './components/ClientSelector';
import PlatformSelector from './components/PlatformSelector';

const useStyles = makeStyles((theme) => ({
  counts: {
    color: theme.palette.primary.light,
  },
  separator: {
    width: theme.spacing(1),
  },
  wrapperButtons: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  wrapperRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const FiltersBar = ({
  selectedClientName,
  showClientCount,
  clientCount,
  skuCount,
  platformFilter,
  changePlatformFilter,
  clientFilter,
  changeClientFilter,
  clientOptions,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapperRoot}>
      <Typography className={classes.counts} variant="h5" component="span">
        {showClientCount
          ? `${clientCount} ${
              clientCount.length === 1 ? 'Client' : 'Clients'
            } | ${skuCount} ${skuCount === 1 ? 'SKU' : 'SKUs'}`
          : `${selectedClientName} | ${skuCount} ${
              skuCount === 1 ? 'SKU' : 'SKUs'
            }`}
      </Typography>
      <div className={classes.wrapperButtons}>
        <PlatformSelector
          platformFilter={platformFilter}
          changePlatformFilter={changePlatformFilter}
        />
        <div className={classes.separator} />
        <ClientSelector
          clientFilter={clientFilter}
          changeClientFilter={changeClientFilter}
          clientOptions={clientOptions}
        />
      </div>
    </div>
  );
};

FiltersBar.defaultProps = {
  selectedClientName: 'Example Client',
  showClientCount: true,
  clientCount: 12,
  skuCount: 46,
  platformFilter: 'AMAZON',
  changePlatformFilter: (newFilter) =>
    console.log('User changes the platform filter to:', newFilter),
  clientFilter: 'AMAZON',
  changeClientFilter: (newFilter) =>
    console.log('User changes the client filter to:', newFilter),
  clientOptions: [],
};

FiltersBar.propTypes = {
  selectedClientName: PropTypes.string,
  showClientCount: PropTypes.bool,
  clientCount: PropTypes.number,
  skuCount: PropTypes.number,
  platformFilter: PropTypes.string,
  changePlatformFilter: PropTypes.func,
  clientFilter: PropTypes.string,
  changeClientFilter: PropTypes.func,
  clientOptions: PropTypes.arrayOf(PropTypes.string),
};

export default FiltersBar;
