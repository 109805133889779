import { fade } from '@material-ui/core/styles/colorManipulator';
import { green, red, yellow } from '@material-ui/core/colors';

const getStyles = (theme) => ({
  icon: {
    color: theme.palette.common.black,
  },
  link: {
    color: theme.palette.common.black,
  },
  loadingOverlay: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: fade(theme.palette.common.black, 0.8),
  },
  statusCollectionArranged: {
    width: '100%',
    color: theme.palette.common.black,
    backgroundColor: green[500],
  },
  statusCompleted: {
    width: '100%',
    color: theme.palette.common.black,
    backgroundColor: green[500],
  },
  statusRequested: {
    width: '100%',
    color: theme.palette.common.black,
    backgroundColor: yellow[500],
  },
  statusPreparing: {
    width: '100%',
    color: theme.palette.common.black,
    backgroundColor: yellow[500],
  },
  statusDelayed: {
    width: '100%',
    color: theme.palette.common.black,
    backgroundColor: green[500],
  },
  statusCancelled: {
    width: '100%',
    color: theme.palette.common.black,
    backgroundColor: red[500],
  },
  statusInbound: {
    width: '100%',
    color: theme.palette.common.black,
    backgroundColor: green[500],
  },
  statusWaybillCreated: {
    width: '100%',
    color: theme.palette.common.black,
    backgroundColor: yellow[500],
  },
  tooltipWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  warningIcon: {
    marginRight: theme.spacing(1),
    color: yellow[500],
  },
  wrapperContent: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
});

export default getStyles;
