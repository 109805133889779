/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getPresalesSuppliers, requestReport } from '../../../api/presalesAPI';

const initialState = {
  suppliers: [],
  loadingStatus: {
    suppliers: 'NOT_STARTED',
    requestReport: 'NOT_STARTED',
  },
};

const ReportsBrowseSlice = createSlice({
  name: 'reportsBrowse',
  initialState,
  reducers: {
    fetchPresalesSuppliersStarted(state) {
      state.loadingStatus.suppliers = 'PENDING';
    },
    fetchPresalesSuppliersSuccess(state, action) {
      state.suppliers = action.payload;
      state.loadingStatus.suppliers = 'COMPLETE';
    },
    fetchPresalesSuppliersFailed(state) {
      state.loadingStatus.suppliers = 'COMPLETE';
    },
    requestReportStarted(state) {
      state.loadingStatus.requestReport = 'PENDING';
    },
    requestReportSuccess(state) {
      state.loadingStatus.requestReport = 'COMPLETE';
    },
    requestReportFailed(state) {
      state.loadingStatus.requestReport = 'COMPLETE';
    },
  },
});

export const {
  fetchPresalesSuppliersStarted,
  fetchPresalesSuppliersSuccess,
  fetchPresalesSuppliersFailed,
  requestReportStarted,
  requestReportSuccess,
  requestReportFailed,
} = ReportsBrowseSlice.actions;

export const fetchPresalesSuppliers = () => async (dispatch) => {
  dispatch(fetchPresalesSuppliersStarted());
  const result = await getPresalesSuppliers();

  if (result.status === 200) {
    dispatch(fetchPresalesSuppliersSuccess(result.suppliers));
  } else {
    dispatch(fetchPresalesSuppliersFailed(result.error));
  }
};

export const requestPresalesReport =
  (requestedById, requestedByName, supplierId, supplierName, reportType) =>
  async (dispatch) => {
    dispatch(requestReportStarted());
    const result = await requestReport(
      requestedById,
      requestedByName,
      supplierId,
      supplierName,
      reportType
    );

    if (result.status === 200) {
      dispatch(requestReportSuccess());
    } else {
      dispatch(requestReportFailed(result.error));
    }
  };

export default ReportsBrowseSlice.reducer;
