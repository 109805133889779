import { green, grey, red, yellow } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import ImageIcon from '@material-ui/icons/Image';
import LineWeightIcon from '@material-ui/icons/LineWeight';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import PropTypes from 'prop-types';
import SpellcheckIcon from '@material-ui/icons/Spellcheck';
import StraightenIcon from '@material-ui/icons/Straighten';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  chipGreen: {
    color: green[800],
    borderColor: green[800],
  },
  chipRed: {
    color: red[800],
    borderColor: red[800],
  },
  chipYellow: {
    color: yellow[800],
    borderColor: yellow[800],
  },
  divider: {
    margin: '24px 0',
  },
  infoIconComplete: {
    color: green[800],
  },
  infoIconIncomplete: {
    color: red[800],
  },
  media: {
    minHeight: 140,
    height: '100%',
    backgroundColor: grey[800],
  },
  text: {
    color: theme.palette.secondary.light,
  },
  title: {
    color: theme.palette.primary.light,
  },
  separator: {
    height: 10,
  },
}));

const Hit = ({
  title,
  eolSku,
  status,
  supplier,
  primaryImageUrl,
  onboardingStatuses,
}) => {
  const classes = useStyles();

  const getStatusChip = () => {
    switch (status) {
      case 'MISSING_INFO':
        return (
          <Chip
            variant="outlined"
            size="small"
            label="Missing Info"
            className={classes.chipRed}
          />
        );
      case 'SUBMITTED':
        return (
          <Chip
            variant="outlined"
            size="small"
            label="Submitted"
            className={classes.chipGreen}
          />
        );
      case 'BUYABLE':
      case 'NOT_BUYABLE':
      case 'DISABLED_BY_SELLER':
      case 'DISABLED_BY_TAKEALOT':
        return (
          <Chip
            variant="outlined"
            size="small"
            label="Approved"
            className={classes.chipGreen}
          />
        );
      default:
        return <Chip variant="outlined" size="small" label="Basic" />;
    }
  };

  return (
    <Card className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <CardMedia
            className={classes.media}
            image={primaryImageUrl || 'No image'}
            title={title}
          />
        </Grid>
        <Grid item xs={9}>
          <CardContent>
            <Typography className={classes.text} variant="body2" component="p">
              {eolSku}
            </Typography>
            <Typography
              gutterBottom
              variant="h5"
              component="h2"
              className={classes.title}
            >
              {title}
            </Typography>
            <div className={classes.separator} />
            <Grid
              container
              spacing={1}
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                className={classes.text}
                variant="body2"
                component="p"
              >
                {supplier}
              </Typography>
              {getStatusChip()}
            </Grid>
            <Divider className={classes.divider} />
            <Grid
              container
              spacing={1}
              alignItems="center"
              justifyContent="space-around"
            >
              <Tooltip title="Measurements">
                <StraightenIcon
                  className={
                    onboardingStatuses.measurementsComplete
                      ? classes.infoIconComplete
                      : classes.infoIconIncomplete
                  }
                />
              </Tooltip>
              <Tooltip title="Copy">
                <SpellcheckIcon
                  className={
                    onboardingStatuses.copyComplete
                      ? classes.infoIconComplete
                      : classes.infoIconIncomplete
                  }
                />
              </Tooltip>
              <Tooltip title="Images">
                <ImageIcon
                  className={
                    onboardingStatuses.imagesComplete
                      ? classes.infoIconComplete
                      : classes.infoIconIncomplete
                  }
                />
              </Tooltip>
              <Tooltip title="Categories">
                <PermMediaIcon
                  className={
                    onboardingStatuses.categoriesComplete
                      ? classes.infoIconComplete
                      : classes.infoIconIncomplete
                  }
                />
              </Tooltip>
              <Tooltip title="Barcodes">
                <LineWeightIcon
                  className={
                    onboardingStatuses.barcodesComplete
                      ? classes.infoIconComplete
                      : classes.infoIconIncomplete
                  }
                />
              </Tooltip>
            </Grid>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
};

Hit.defaultProps = {
  title: 'Example SKU',
  eolSku: 'EOL-EXAMPLE-0001',
  status: 'MISSING_INFO',
  supplier: 'Example SKU',
  primaryImageUrl: '',
  onboardingStatuses: {
    measurementsComplete: true,
    copyComplete: false,
    categoriesComplete: false,
    imagesComplete: true,
    barcodesComplete: true,
  },
};

Hit.propTypes = {
  title: PropTypes.string,
  supplier: PropTypes.string,
  eolSku: PropTypes.string,
  primaryImageUrl: PropTypes.string,
  status: PropTypes.oneOf([
    'MISSING_INFO',
    'BUYABLE',
    'NOT_BUYABLE',
    'DISABLED',
  ]),
  onboardingStatuses: PropTypes.shape({
    measurementsComplete: PropTypes.bool,
    copyComplete: PropTypes.bool,
    categoriesComplete: PropTypes.bool,
    imagesComplete: PropTypes.bool,
    barcodesComplete: PropTypes.bool,
  }),
};

export default Hit;
