import { firestore, functions } from '../utils/firebase';

export const addNewUser = async (userInfo, loginCredentials) => {
  const createUser = functions.httpsCallable('createUser');
  return createUser({ userInfo, loginCredentials })
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const getUserInfo = async (uid) => {
  const userRef = firestore.collection('users').doc(uid);

  return userRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        const userInfo = doc.data();
        return {
          userInfo: {
            id: doc.id,
            email: userInfo.email || null,
            name: userInfo.name || null,
            phoneNumber: userInfo.phoneNumber || null,
            surname: userInfo.surname || null,
            title: userInfo.title || null,
            type: userInfo.type || null,
            agencyId: userInfo.agencyId || null,
            agencyName: userInfo.agencyName || null,
            isSuperAdmin: userInfo.isSuperAdmin || false,
          },
          status: 200,
        };
      }
      return {
        status: 500,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const getUsers = async () => {
  const usersRef = firestore.collection('users');

  return usersRef
    .get()
    .then((querySnapshot) => {
      const users = [];
      querySnapshot.forEach((doc) => {
        const userInfo = doc.data();
        users.push({
          id: doc.id,
          ...userInfo,
        });
      });
      return {
        users,
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const getAgencies = async () => {
  const agenciesRef = firestore.collection('agencies');

  return agenciesRef
    .get()
    .then((querySnapshot) => {
      const agencies = [];
      querySnapshot.forEach((doc) => {
        const agencyInfo = doc.data();
        agencies.push({
          id: doc.id,
          ...agencyInfo,
        });
      });
      return {
        agencies,
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};
