/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { uploadQueryImage } from '../../../api/clientsAPI';

const initialState = {
  uploadTransfers: {},
  imageUploadCounts: {
    total: 0,
    success: 0,
    failed: 0,
  },
  feedback: {
    isOpen: false,
    type: undefined,
    message: '',
  },
};

const clientsQueryImageUploadSlice = createSlice({
  name: 'clientsQueryImageUpload',
  initialState,
  reducers: {
    setTotalImageUploadCount(state, action) {
      state.imageUploadCounts.total = action.payload;
    },
    uploadImageSuccess(state) {
      state.imageUploadCounts.success += 1;
    },
    uploadImageFailed(state) {
      state.imageUploadCounts.failed += 1;
    },
    updateUploadBytes(state, action) {
      state.uploadTransfers = {
        ...state.uploadTransfers,
        [action.payload.imageId]: {
          totalBytes: action.payload.totalBytes,
          transferredBytes: action.payload.transferredBytes,
        },
      };
    },
    resetUploadBytes(state) {
      state.uploadTotalBytes = 0;
      state.uploadTransferredBytes = 0;
    },
    resetState() {
      return initialState;
    },
  },
});

export const {
  setTotalImageUploadCount,
  uploadImageSuccess,
  uploadImageFailed,
  resetState,
  updateUploadBytes,
  resetUploadBytes,
} = clientsQueryImageUploadSlice.actions;

export default clientsQueryImageUploadSlice.reducer;

export const uploadNewImage = (file, queryId) => async (dispatch) => {
  await uploadQueryImage(
    file,
    queryId,
    (imageId, totalBytes, transferredBytes) =>
      dispatch(updateUploadBytes({ imageId, totalBytes, transferredBytes })),
    () => dispatch(uploadImageSuccess()),
    () => dispatch(uploadImageFailed())
  );
  dispatch(resetUploadBytes());
};
