import { createTheme } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

const theme = createTheme({
  palette: {
    primary: {
      light: '#A7A5A5',
      main: '#171415',
      dark: '#100E0E',
      contrastText: '#FCF9F8',
    },
    secondary: {
      light: '#584B53',
      main: '#F36438',
      dark: '#F1430E',
      contrastText: '#171415',
    },
    background: {
      default: '#212121',
    },
    common: {
      black: '#171415',
      white: '#FCF9F8',
    },
  },
  overrides: {
    MuiBreadcrumbs: {
      separator: {
        color: grey[700],
      },
    },
    MuiButton: {
      root: {
        color: '#FCF9F8',
        '&$disabled': {
          color: 'rgba(255, 255, 255, 0.3)',
        },
      },
      contained: {
        '&$disabled': {
          color: 'rgba(0, 0, 0, 0.5)',
          backgroundColor: 'rgba(255, 255, 255, 0.3)',
        },
      },
      outlined: {
        border: '1px solid #FCF9F8',
      },
    },
    MuiCardHeader: {
      subheader: {
        color: grey[700],
      },
    },
    MuiCheckbox: {
      root: {
        color: grey[700],
      },
      colorPrimary: {
        '&$checked': {
          color: '#FCF9F8',
        },
      },
    },
    MuiDialogContent: {
      root: {
        backgroundColor: '#171415',
        color: '#FCF9F8',
      },
    },
    MuiDialogContentText: {
      root: {
        color: '#FCF9F8',
      },
    },
    MuiDialogActions: {
      root: {
        backgroundColor: '#584B53',
      },
    },
    MuiDialogTitle: {
      root: {
        backgroundColor: '#584B53',
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: grey[900],
      },
    },
    MuiFormControlLabel: {
      root: {
        color: grey[700],
      },
    },
    MuiFormLabel: {
      root: {
        color: grey[700],
        '&$focused': {
          color: grey[700],
        },
      },
    },
    MuiInput: {
      input: {
        '&::placeholder': {
          color: grey[700],
        },
      },
      underline: {
        color: '#FCF9F8',
        '&::before': {
          borderBottomColor: '#FCF9F8',
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottomColor: '#FCF9F8',
        },
        '&::after': {
          borderBottomColor: '#FCF9F8',
        },
      },
    },
    MuiIcon: {
      root: {
        color: '#FCF9F8',
      },
    },
    MuiIconButton: {
      root: {
        color: '#FCF9F8',
        '&$disabled': {
          color: '#A7A5A5',
        },
      },
    },
    MuiInputAdornment: {
      root: {
        color: grey[500],
        '&$positionStart': {
          color: grey[500],
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: grey[700],
        '&$focused': {
          color: grey[700],
        },
      },
    },
    MuiLink: {
      root: {
        color: grey[700],
      },
    },
    MuiListSubheader: {
      root: {
        color: grey[400],
      },
    },
    MuiListItemText: {
      secondary: {
        color: grey[700],
      },
    },
    MuiMenu: {
      paper: {
        backgroundColor: '#584B53',
      },
    },
    MuiPaginationItem: {
      root: {
        color: '#616161',
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: '#171415',
        color: '#FCF9F8',
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        backgroundColor: '#584B53',
      },
      transitionContainer: {
        backgroundColor: '#FCF9F8',
      },
    },
    MuiPickersCalendar: {
      transitionContainer: {
        backgroundColor: '#FCF9F8',
      },
    },
    MuiPickersCalendarHeader: {
      daysHeader: {
        color: '#171415',
      },
      iconButton: {
        backgroundColor: '#584B53',
        '&:hover': {
          backgroundColor: '#6E5E68',
        },
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#584B53',
      },
    },
    MuiRadio: {
      root: {
        color: grey[700],
      },
    },
    MuiSelect: {
      icon: {
        color: '#FCF9F8',
      },
    },
    MuiStepIcon: {
      text: {
        fill: '#171415',
      },
      root: {
        color: '#FCF9F8',
        '&$active': {
          color: '#F36438',
        },
        '&$completed': {
          color: grey[700],
        },
      },
    },
    MuiStepLabel: {
      label: {
        color: '#FCF9F8',
        '&$active': {
          color: '#F36438',
        },
        '&$completed': {
          color: grey[700],
        },
      },
    },
    MuiSwitch: {
      colorPrimary: {
        thumb: {
          backgroundColor: '#FCF9F8',
        },
      },
      track: {
        backgroundColor: grey[700],
      },
    },
    MuiTab: {
      textColorSecondary: {
        color: '#584B53',
      },
    },
    MuiTable: {
      root: {
        backgroundColor: grey[800],
        color: grey[300],
      },
    },
    MuiTableCell: {
      body: {
        backgroundColor: grey[800],
        borderColor: '#363636',
      },
      head: {
        backgroundColor: '#171415',
        color: '#FCF9F8',
        borderColor: '#171415',
      },
    },
    MuiTablePagination: {
      root: {
        borderColor: '#171415',
      },
    },
    MuiTableSortLabel: {
      root: {
        color: '#FCF9F8',
        '&:hover': {
          color: '#F36438',
        },
        '&:focus': {
          color: '#FCF9F8',
        },
        '&$active': {
          color: '#F36438',
        },
      },
      icon: {
        '&$iconDirectionAsc': {
          '& path': {
            color: '#F36438',
          },
        },
        '&$iconDirectionDesc': {
          '& path': {
            color: '#F36438',
          },
        },
      },
    },
    // MuiToggleButton: {
    //   root: {
    //     '&$selected': {
    //       backgroundColor: '#F36438 !important',
    //     },

    //     '&:hover': {
    //       backgroundColor: '#363535',
    //       color: '#F36438',
    //     },
    //   },
    //   label: {
    //     color: '#FCF9F8',
    //   },
    // },
    MuiToolbar: {
      root: {
        backgroundColor: '#171415',
        color: grey[700],
        borderColor: '#171415',
      },
    },
    MuiTypography: {
      caption: {
        color: grey[700],
      },
      body1: {
        color: '#A7A5A5',
      },
    },
  },
});

export default theme;
