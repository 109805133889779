import { CSVReader } from 'react-papaparse';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';
import {
  updateSupplierStockColums,
  fetchSupplierInfo,
  initiateStateReset,
} from './slice';
import ContentWrapper from '../../../components/ContentWrapper';
import PageHeader from '../../../components/PageHeader';
import PreviewTable from './components/PreviewTable';
import allStyles from '../../../utils/styles';

const useStyles = makeStyles(allStyles);

const breadcrumbs = [
  {
    label: 'Presales',
    link: '/presales',
  },
  {
    label: 'Suppliers',
    link: '/presales/suppliers',
  },
  {
    label: 'Browse',
    link: '/presales/suppliers/browse',
  },
];

const UpdateStockSettings = () => {
  const classes = useStyles();
  const history = useHistory();
  const fileUploadRef = useRef();
  const dispatch = useDispatch();
  const { supplierId } = useParams();

  const [csvColumns, setCsvColumns] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [suppliersku, setSupplierSku] = useState('');
  const [soh, setSoh] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [validation, setValidation] = useState({
    isErrorShowing: false,
    message: '',
    errorStep: 0,
  });

  const updatingLoadingStatus = useSelector(
    (state) => state.presalesSuppliersUpdateStockSettings.loadingStatus.updating
  );
  const supplierInfoLoadingStatus = useSelector(
    (state) =>
      state.presalesSuppliersUpdateStockSettings.loadingStatus.supplierInfo
  );
  const isUserRedirectedToBrowse = useSelector(
    (state) =>
      state.presalesSuppliersUpdateStockSettings.isUserRedirectedToBrowse
  );

  useEffect(() => {
    dispatch(fetchSupplierInfo(supplierId));
  }, []);

  useEffect(() => {
    if (
      updatingLoadingStatus === 'PENDING' ||
      supplierInfoLoadingStatus === 'PENDING'
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [updatingLoadingStatus, supplierInfoLoadingStatus]);

  useEffect(() => {
    if (isUserRedirectedToBrowse) {
      dispatch(initiateStateReset());
      setIsLoading(true);
      history.goBack();
    }
  }, [isUserRedirectedToBrowse]);

  useEffect(() => {
    if (fileUploadRef.current) {
      fileUploadRef.current.removeFile();
    }
  }, [activeStep]);

  const formatStockUpdate = (data) => {
    const formattedData = data
      .filter((_, index) => index > 0 && index < data.length - 1)
      .map((item) => item.data);

    setCsvColumns(data[0].data);
    setCsvData(formattedData);
  };

  const handleFileLoad = (data) => {
    formatStockUpdate(data);
  };

  const handleError = (err) => {
    console.log(err);
  };

  const onNextClick = () => {
    switch (activeStep) {
      case 0:
        if (csvColumns.length === 0) {
          setValidation({
            isErrorShowing: true,
            errorStep: 0,
            message: 'Please upload a CSV file',
          });
        } else {
          setValidation({
            isErrorShowing: false,
            message: '',
            errorStep: 1,
          });

          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        break;
      case 1:
        if (suppliersku === soh) {
          setValidation({
            isErrorShowing: true,
            errorStep: 1,
            message:
              ' Please select two different columns for the Supplier SKU and SOH fields',
          });
        } else {
          setValidation({
            isErrorShowing: false,
            message: '',
            errorStep: 1,
          });
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        break;
      case 2:
        dispatch(
          updateSupplierStockColums(supplierId, {
            stockColumns: {
              supplierSkuStockIndex: csvColumns.indexOf(suppliersku),
              sohIndex: csvColumns.indexOf(soh),
            },
          })
        );
        setValidation({
          isErrorShowing: false,
          message: '',
          errorStep: 2,
        });

        break;
      default:
        console.log('Unknown step');
        break;
    }
  };

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <div className={classes.wrapperStep}>
            <Typography variant="h4" gutterBottom>
              Upload CSV for Stock Update
            </Typography>
            <div className={classes.separator} />
            <Typography variant="body1">
              Please upload a CSV document containing the updated SOH along with
              the Supplier SKUs for each product.
            </Typography>
            <div className={classes.separator} />
            <CSVReader
              ref={fileUploadRef}
              onDrop={handleFileLoad}
              onError={handleError}
              addRemoveButton
            >
              <span>Drop CSV file here or click to upload.</span>
            </CSVReader>
            {validation.isErrorShowing && validation.errorStep === 0 && (
              <Alert className={classes.alert} severity="error">
                {validation.message}
              </Alert>
            )}
          </div>
        );
      case 1:
        return (
          <div className={classes.wrapperStep}>
            <Typography variant="h4" gutterBottom>
              Select Stock Information to be Updated
            </Typography>
            <div className={classes.separator} />
            <Typography variant="body1">
              Please select the columns in the uploaded CSV document that
              correspond with the Supplier SKU and SOH.
            </Typography>
            <div className={classes.separator} />
            <FormControl className={classes.formControl}>
              <InputLabel>Supplier SKU</InputLabel>
              <Select
                labelId="presale-supplier-sku-select-label"
                id="presale-supplier-sku-select"
                required
                value={suppliersku}
                onChange={(e) => {
                  setSupplierSku(e.target.value);
                }}
              >
                {csvColumns.map((info) => (
                  <MenuItem value={info}>{info}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <div className={classes.separator} />
            <FormControl className={classes.formControl}>
              <InputLabel>SOH</InputLabel>
              <Select
                labelId="presale-soh-select-label"
                id="presale-soh-select"
                required
                value={soh}
                onChange={(e) => {
                  setSoh(e.target.value);
                }}
              >
                {csvColumns.map((info) => (
                  <MenuItem value={info}>{info}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <div className={classes.separator} />

            {validation.isErrorShowing && validation.errorStep === 1 && (
              <Alert className={classes.alert} severity="error">
                {validation.message}
              </Alert>
            )}
          </div>
        );
      case 2:
        return (
          <div className={classes.wrapperStep}>
            <Typography variant="h4" gutterBottom>
              Confirm Stock Information
            </Typography>

            <div className={classes.separator} />
            <Typography variant="body1">
              Please examine the preview table below to confirm that the correct
              columns have been selected. The selected columns will be used for
              all future stock updates and can be changed at a later date.
            </Typography>

            <div className={classes.separator} />
            <PreviewTable
              csvData={csvData}
              supplierSkuIndex={csvColumns.indexOf(suppliersku)}
              sohIndex={csvColumns.indexOf(soh)}
            />
          </div>
        );
      default:
        return <div />;
    }
  };

  return (
    <div>
      <PageHeader
        isLoading={isLoading}
        title="Update Stock Info"
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Edit"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
      />
      <ContentWrapper>
        <Paper className={classes.wrapperWizard}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Stepper activeStep={activeStep} orientation="vertical">
                <Step>
                  <StepLabel>Upload CSV</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Select Columns</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Update Stock</StepLabel>
                </Step>
              </Stepper>
            </Grid>
            <Grid item xs={9}>
              {getStepContent()}
              <div className={classes.actionsBar}>
                <Button
                  disabled={activeStep === 0 || isLoading}
                  onClick={() => setActiveStep(activeStep - 1)}
                >
                  Previous
                </Button>
                <Button
                  disabled={isLoading}
                  variant="contained"
                  color="secondary"
                  onClick={onNextClick}
                >
                  {activeStep !== 2 ? 'Next' : 'Update'}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </ContentWrapper>
    </div>
  );
};

export default UpdateStockSettings;
