import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import EmptyViewMessage from '../../../../../components/EmptyViewMessage';
import ProductCard from './components/ProductCard';

const useStyles = makeStyles((theme) => ({
  seperator: {
    height: theme.spacing(3),
  },
}));

const ProductsTab = ({ products }) => {
  const classes = useStyles();
  const history = useHistory();

  if (products.length === 0) {
    return (
      <div className={classes.root}>
        <EmptyViewMessage
          heading="No Products"
          message="Add new products for this supplier to view them here."
        />
      </div>
    );
  }

  return products.map((productInfo) => (
    <div key={productInfo.id}>
      <ProductCard
        productName={productInfo.description}
        costPrice={productInfo.pricing.costPrice}
        wholesalePrice={productInfo.pricing.wholesalePrice}
        retailPrice={productInfo.pricing.retailPrice}
        markup={Math.round(
          ((productInfo.pricing.wholesalePrice -
            productInfo.pricing.costPrice) /
            productInfo.pricing.costPrice) *
            100
        )}
        goToProduct={() =>
          history.push(`/sales/products/browse/${productInfo.id}`)
        }
      />
      <div className={classes.seperator} />
    </div>
  ));
};

ProductsTab.defaultProps = {
  products: [],
};

ProductsTab.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({})),
};

export default ProductsTab;
