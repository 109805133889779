import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import ClearIcon from '@material-ui/icons/Clear';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  actionBar: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  formControl: {
    width: '100%',
  },
  formWrapper: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
  },
  root: {
    backgroundColor: theme.palette.common.white,
  },
  select: {
    icon: {
      color: theme.palette.common.black,
    },
  },
  desc: {
    color: theme.palette.common.black,
  },
  separator: {
    height: theme.spacing(2),
  },
}));

const DocumentPreviewCard = ({
  tag,
  setTag,
  removeDoc,
  isLoading,
  description,
  setDescription,
  documentName,
}) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardActions className={classes.actionBar}>
        <form className={classes.formWrapper}>
          <Typography variant="body1" gutterBottom className={classes.desc}>
            {documentName}
          </Typography>
          <div className={classes.separator} />
          <FormControl
            variant="outlined"
            className={classes.formControl}
            size="small"
            disabled={isLoading}
          >
            <InputLabel
              id="tag-select-outlined-label"
              htmlFor="tag-select-outlined"
            >
              Tag
            </InputLabel>
            <Select
              labelId="tag-select-outlined-label"
              id="tag-select-outlined"
              value={tag}
              onChange={(e) => setTag(e.target.value)}
              label="Tag"
              className={classes.select}
            >
              <MenuItem value="CONSIGNMENT_INVOICE">
                Consignment Invoice
              </MenuItem>
              <MenuItem value="INVOICE">Invoice</MenuItem>
              <MenuItem value="PROOF_OF_PAYMENT">Proof of Payment</MenuItem>
              <MenuItem value="WAYBILL">Waybill</MenuItem>
              <MenuItem value="OTHER">Other</MenuItem>
            </Select>
          </FormControl>
          <div className={classes.separator} />
          <FormControl
            variant="outlined"
            className={classes.formControl}
            size="small"
            disabled={isLoading}
          >
            <TextField
              required
              id="doc-description"
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className={classes.desc}
              variant="outlined"
              error={description.length < 1}
            />
          </FormControl>
          <div className={classes.separator} />
          <Button
            onClick={removeDoc}
            startIcon={<ClearIcon />}
            size="small"
            color="primary"
            disabled={isLoading}
          >
            Remove
          </Button>
        </form>
      </CardActions>
    </Card>
  );
};

DocumentPreviewCard.defaultProps = {
  tag: 'OTHER',
  setTag: (newTag) => console.log(`Tag was updated to ${newTag}`),
  removeDoc: () => {},
  isLoading: false,
  description: '',
  setDescription: () => {},
  documentName: '',
};

DocumentPreviewCard.propTypes = {
  tag: PropTypes.oneOf([
    'CONSIGNMENT_INVOICE',
    'INVOICE',
    'PROOF_OF_PAYMENT',
    'CATALOGUE',
    'OTHER',
  ]),
  setTag: PropTypes.func,
  removeDoc: PropTypes.func,
  isLoading: PropTypes.bool,
  description: PropTypes.string,
  setDescription: PropTypes.func,
  documentName: PropTypes.string,
};

export default DocumentPreviewCard;
