import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import StatusSelector from './components/StatusSelector';
import getStyles from './styles';

const useStyles = makeStyles(getStyles);

const FiltersBar = ({
  searchText,
  changeSearchText,
  shipmentCount,
  statusFilter,
  changeStatusFilter,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapperRoot}>
      <TextField
        placeholder="Search"
        value={searchText}
        onChange={(e) => changeSearchText(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <Typography className={classes.results} variant="body1" component="span">
        {`${shipmentCount} ${
          shipmentCount === 1 ? 'result' : 'results'
        } displayed`}
      </Typography>
      <div className={classes.wrapperButtons}>
        <StatusSelector
          statusFilter={statusFilter}
          changeStatusFilter={changeStatusFilter}
        />
      </div>
    </div>
  );
};

FiltersBar.defaultProps = {
  searchText: '',
  changeSearchText: (newValue) => `Search text was changed to '${newValue}'`,
  shipmentCount: 1,
  statusFilter: 'ALL',
  changeStatusFilter: (newFilter) =>
    console.log('User changes the status filter to:', newFilter),
};

FiltersBar.propTypes = {
  searchText: PropTypes.string,
  changeSearchText: PropTypes.func,
  shipmentCount: PropTypes.number,
  statusFilter: PropTypes.string,
  changeStatusFilter: PropTypes.func,
};

export default FiltersBar;
