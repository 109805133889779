import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import numeral from 'numeral';
import Button from '@material-ui/core/Button';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
  fetchDealInfo,
  fetchInvoices,
  fetchProposals,
  deleteDeal,
  finalizeDeal,
  lostDeal,
  initiateStateReset,
} from './slice';
import ContentWrapper from '../../../components/ContentWrapper';
import DealInfoCard from './components/DealInfoCard';
import EmptyViewMessage from '../../../components/EmptyViewMessage';
import PageHeader from '../../../components/PageHeader';
import DocumentsCard from './components/DocumentsCard';
import FinalizeDealDialog from './components/FinalizeDealDialog';
import TotalsBar from '../../../components/TotalsBar';

const useStyles = makeStyles((theme) => ({
  loadingWrapper: {
    padding: theme.spacing(4),
    textAlign: 'center',
  },
  pdfButtonInner: {
    display: 'flex',
    alignItems: 'center',
  },
  pdfButtonOuter: {
    color: theme.palette.common.white,
    textDecoration: 'none',
  },
  seperatorButtons: {
    height: theme.spacing(1),
  },
  seperator: {
    height: theme.spacing(3),
  },
  totalsWrapper: {
    padding: theme.spacing(4),
  },
}));

const breadcrumbs = [
  {
    label: 'Sales',
    link: '/sales',
  },
  {
    label: 'Deals',
    link: '/sales/deals',
  },
  {
    label: 'Browse',
    link: '/sales/deals/browse',
  },
];

const DealsView = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { dealId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [isFinalizeDealDialogOpen, setIsFinalizeDealDialogOpen] =
    useState(false);

  const dealInfo = useSelector((state) => state.dealsView.dealInfo);
  const proposals = useSelector((state) => state.dealsView.proposals);
  const invoices = useSelector((state) => state.dealsView.invoices);
  const isUserRedirectedToBrowse = useSelector(
    (state) => state.dealsView.isUserRedirectedToBrowse
  );
  const isPageRefreshed = useSelector(
    (state) => state.dealsView.isPageRefreshed
  );
  const dealInfoLoadingStatus = useSelector(
    (state) => state.dealsView.loadingStatus.dealInfo
  );
  const proposalsLoadingStatus = useSelector(
    (state) => state.dealsView.loadingStatus.proposals
  );
  const invoicesLoadingStatus = useSelector(
    (state) => state.dealsView.loadingStatus.invoices
  );
  const deletionLoadingStatus = useSelector(
    (state) => state.dealsView.loadingStatus.deletion
  );
  const finalizationLoadingStatus = useSelector(
    (state) => state.dealsView.loadingStatus.finalization
  );
  const lostLoadingStatus = useSelector(
    (state) => state.dealsView.loadingStatus.lost
  );

  useEffect(() => {
    dispatch(fetchDealInfo(dealId));
    dispatch(fetchProposals(dealId));
    dispatch(fetchInvoices(dealId));
  }, []);

  useEffect(() => {
    if (
      dealInfoLoadingStatus === 'PENDING' ||
      proposalsLoadingStatus === 'PENDING' ||
      invoicesLoadingStatus === 'PENDING' ||
      deletionLoadingStatus === 'PENDING' ||
      finalizationLoadingStatus === 'PENDING' ||
      lostLoadingStatus === 'PENDING'
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [
    dealInfoLoadingStatus,
    proposalsLoadingStatus,
    invoicesLoadingStatus,
    deletionLoadingStatus,
  ]);

  useEffect(() => {
    if (isUserRedirectedToBrowse) {
      dispatch(initiateStateReset());
      history.push('/sales/deals/browse');
    }
  }, [isUserRedirectedToBrowse]);

  useEffect(() => {
    if (isPageRefreshed) {
      history.go(0);
    }
  }, [isPageRefreshed]);

  const getStatusText = (status) => {
    switch (status) {
      case 'AWAITING_INVOICE':
        return 'Awaiting Invoice';
      case 'AWAITING_PROSPECT_RESPONSE':
        return 'Negotiating';
      case 'AWAITING_ASSESSMENT':
        return 'Awaiting Assessment';
      case 'FINALIZED':
        return 'Successfully Closed';
      case 'LOST':
        return 'Lost';
      default:
        return 'Unknown';
    }
  };

  const getPlatformText = (platform) => {
    switch (platform) {
      case 'AMAZON':
        return 'Amazon';
      case 'TAKEALOT':
        return 'Takealot';
      case 'AMAZON_TAKEALOT':
        return 'Amazon & Takealot';
      default:
        return 'Unknown';
    }
  };

  const getDealTypeText = (dealType) => {
    switch (dealType) {
      case 'NEW':
        return 'New Prospect';
      case 'ADD_ON':
        return 'Existing Client';
      case 'SUPER_DEALER':
        return 'Super Dealer';
      default:
        return 'Unknown';
    }
  };

  const getTotalsBarContent = () => {
    const totals = [
      {
        label: 'Deal Value',
        value: `R ${numeral(dealInfo.dealValue).format('0,0.00')}`,
      },
      {
        label: 'Status',
        value: getStatusText(dealInfo.status),
      },
      {
        label: 'Platform',
        value: getPlatformText(dealInfo.platform),
      },
    ];

    if (dealInfo.dealType) {
      totals.push({
        label: 'Type',
        value: getDealTypeText(dealInfo.dealType),
      });
    }

    return totals;
  };

  const getVersions = () => {
    return proposals.map((proposalInfo) => proposalInfo.versionNumber);
  };

  if (dealInfo.status === 'DELETED') {
    return <Redirect to="/sales/deals/browse" />;
  }

  return (
    <div>
      <PageHeader
        title="Deal Info"
        subtitle={
          isLoading
            ? 'Loading...'
            : `${dealInfo.dealName ? dealInfo.dealName : dealInfo.businessName}`
        }
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Deal"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
        actions={
          dealInfo.status !== 'LOST' &&
          dealInfo.status !== 'FINALIZED' &&
          !isLoading
            ? [
                {
                  label: 'Finalize deal',
                  icon: <CheckIcon />,
                  onClick: () => setIsFinalizeDealDialogOpen(true),
                },
              ]
            : []
        }
      />
      {isLoading && <LinearProgress color="secondary" />}
      <ContentWrapper>
        {!isLoading && dealInfo.businessName === 'Loading' ? (
          <EmptyViewMessage
            heading="Info Failed to Load"
            message="Please check your internet connection and reload the page."
          />
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {dealInfoLoadingStatus === 'PENDING' ? (
                <div className={classes.loadingWrapper}>
                  <CircularProgress />
                </div>
              ) : (
                <div className={classes.totalsWrapper}>
                  <TotalsBar totals={getTotalsBarContent()} />
                </div>
              )}
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              {dealInfo.status !== 'LOST' &&
                dealInfo.status !== 'FINALIZED' && (
                  <div>
                    <Button
                      fullWidth
                      variant="outlined"
                      color="secondary"
                      startIcon={<EditIcon />}
                      onClick={() =>
                        history.push(`/sales/deals/browse/${dealId}/adjust`)
                      }
                    >
                      Adjust proposal
                    </Button>
                    <div className={classes.seperatorButtons} />
                    <Button
                      fullWidth
                      variant="outlined"
                      color="secondary"
                      startIcon={<CancelIcon />}
                      onClick={() =>
                        dispatch(
                          lostDeal(
                            dealId,
                            dealInfo.dealType,
                            proposals[proposals.length - 1].listingInfo
                          )
                        )
                      }
                    >
                      Mark deal lost
                    </Button>
                    <div className={classes.seperatorButtons} />
                    <Button
                      fullWidth
                      variant="outlined"
                      color="secondary"
                      startIcon={<DeleteIcon />}
                      onClick={() =>
                        dispatch(
                          deleteDeal(
                            dealId,
                            dealInfo.dealType,
                            proposals[proposals.length - 1].listingInfo
                          )
                        )
                      }
                    >
                      Delete deal
                    </Button>
                    <div className={classes.seperator} />
                  </div>
                )}
              <DealInfoCard
                businessName={dealInfo.businessName}
                salesPerson={dealInfo.salesPersonName}
                creationDate={new Date(dealInfo.creationDate)}
                lastUpdated={new Date(dealInfo.lastUpdated)}
                dealValue={dealInfo.dealValue}
                platform={dealInfo.platform}
                status={dealInfo.status}
                isLoading={dealInfoLoadingStatus === 'PENDING'}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={9}>
              {!isLoading && (
                <DocumentsCard
                  finalizedVersion={dealInfo.finalizedVersion || 0}
                  dealType={dealInfo.dealType}
                  proposals={proposals}
                  invoices={invoices}
                  batchCode={dealInfo.batchCode}
                />
              )}
            </Grid>
          </Grid>
        )}
      </ContentWrapper>
      <FinalizeDealDialog
        isLoading={finalizationLoadingStatus === 'PENDING'}
        versions={getVersions()}
        isOpen={isFinalizeDealDialogOpen}
        closeDialog={() => setIsFinalizeDealDialogOpen(false)}
        finalizeDeal={(versionNumber) => {
          const finalizedProposal = proposals.find(
            (proposalInfo) => proposalInfo.versionNumber === versionNumber
          );

          if (dealInfo.dealType === 'SUPER_DEALER') {
            const removedProducts = proposals[
              proposals.length - 1
            ].listingInfo.filter((info) => {
              const relevantListing = finalizedProposal.listingInfo.find(
                (finalizedListing) => finalizedListing.id === info.id
              );
              return !relevantListing;
            });

            dispatch(
              finalizeDeal(
                dealId,
                versionNumber,
                finalizedProposal.total,
                finalizedProposal.listingInfo.length,
                dealInfo.dealType,
                finalizedProposal.listingInfo,
                removedProducts,
                `${dealInfo.dealerCode}${moment().format('MMYYYY')}`
              )
            );
          }

          dispatch(
            finalizeDeal(
              dealId,
              versionNumber,
              finalizedProposal.total,
              finalizedProposal.listingInfo.length,
              dealInfo.dealType,
              [],
              [],
              ''
            )
          );
        }}
      />
    </div>
  );
};

export default DealsView;
