import moment from 'moment';

export const getAgencyAgents = (agencyId, salesAgents) => {
  return salesAgents.filter((info) => info.agencyId === agencyId);
};

export const changeDateRange = (
  rangeFilter,
  changeStartDate,
  changeEndDate
) => {
  if (rangeFilter === 'THIS_MONTH') {
    changeStartDate(moment().startOf('month').toDate());
    changeEndDate(moment().toDate());
  } else if (rangeFilter === 'THIS_YEAR') {
    changeStartDate(moment().startOf('year').toDate());
    changeEndDate(moment().toDate());
  } else if (rangeFilter === 'THIS_WEEK') {
    changeStartDate(moment().startOf('week').toDate());
    changeEndDate(moment().toDate());
  } else if (rangeFilter === 'TODAY') {
    changeStartDate(moment().startOf('day').toDate());
    changeEndDate(moment().toDate());
  } else if (rangeFilter === 'LAST_10_DAYS') {
    changeStartDate(moment().subtract(10, 'days').startOf('day').toDate());
    changeEndDate(moment().toDate());
  }
};
