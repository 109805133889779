/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getClients } from '../../../api/clientsAPI';

const initialState = {
  clientsList: [],
  loadingStatus: {
    clients: 'NOT_STARTED',
  },
  pageSize: 5,
  initialPage: 0,
};

const clientsBrowseSlice = createSlice({
  name: 'clientsBrowse',
  initialState,
  reducers: {
    getClientsStarted(state) {
      state.loadingStatus.clients = 'PENDING';
    },
    getClientsSuccess(state, action) {
      state.clientsList = action.payload;
      state.loadingStatus.clients = 'COMPLETE';
    },
    getClientsFailed(state, action) {
      state.loadingStatus.clients = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    changePageSize(state, action) {
      state.pageSize = action.payload;
    },
    changeInitialPage(state, action) {
      state.initialPage = action.payload;
    },
  },
});

export const {
  getClientsStarted,
  getClientsSuccess,
  getClientsFailed,
  changePageSize,
  changeInitialPage,
} = clientsBrowseSlice.actions;

export const fetchClients = () => async (dispatch) => {
  dispatch(getClientsStarted());
  const result = await getClients();

  if (result.status === 200) {
    dispatch(getClientsSuccess(result.clients));
  } else {
    dispatch(getClientsFailed(result.error));
  }
};

export const updatePageSize = (newPageSize) => (dispatch) => {
  dispatch(changePageSize(newPageSize));
};

export const updateInitialPage = (newInitialPage) => (dispatch) => {
  dispatch(changeInitialPage(newInitialPage));
};

export default clientsBrowseSlice.reducer;
