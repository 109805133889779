/* eslint-disable react/prop-types */
import { fade } from '@material-ui/core/styles/colorManipulator';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

const headerStyle = {
  backgroundColor: '#A7A5A5',
  color: '#171415',
};

const useStyles = makeStyles((theme) => ({
  disabled: {
    color: grey[500],
  },
  loadingOverlay: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: fade(theme.palette.common.black, 0.8),
  },
  wrapperContent: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

const PricingTable = ({
  products,
  updateProducts,
  updateBasedOnCostPricing,
  updateBasedOnWholesalePricing,
}) => {
  const classes = useStyles();

  const columns = [
    {
      title: 'Description',
      field: 'description',
      editable: 'never',
    },
    {
      title: 'Supplier Cost Price',
      field: 'costPrice',
      render: (rowData) => (
        <TextField
          InputProps={{
            startAdornment: <InputAdornment position="start">R</InputAdornment>,
          }}
          type="number"
          value={rowData.costPrice}
          onBlur={() => updateBasedOnCostPricing(rowData.id)}
          onChange={(e) => {
            updateProducts(
              products.map((productInfo) => {
                if (productInfo.id === rowData.id) {
                  return {
                    ...productInfo,
                    costPrice: e.target.value,
                  };
                }
                return productInfo;
              })
            );
          }}
        />
      ),
    },
    {
      title: 'Wholesale Price',
      field: 'wholesalePrice',
      render: (rowData) => (
        <TextField
          InputProps={{
            startAdornment: <InputAdornment position="start">R</InputAdornment>,
          }}
          type="number"
          value={rowData.wholesalePrice}
          onBlur={() => updateBasedOnWholesalePricing(rowData.id)}
          onChange={(e) => {
            updateProducts(
              products.map((productInfo) => {
                if (productInfo.id === rowData.id) {
                  return {
                    ...productInfo,
                    wholesalePrice: e.target.value,
                  };
                }
                return productInfo;
              })
            );
          }}
        />
      ),
    },
    {
      title: 'Markup',
      render: (rowData) => (
        <TextField
          disabled
          InputProps={{
            classes: {
              disabled: classes.disabled,
            },
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          type="number"
          value={Math.round(
            ((rowData.wholesalePrice - rowData.costPrice) / rowData.costPrice) *
              100
          )}
        />
      ),
    },
    {
      title: 'Retail Price',
      field: 'retailPrice',
      render: (rowData) => (
        <TextField
          InputProps={{
            classes: {
              disabled: classes.disabled,
            },
            startAdornment: <InputAdornment position="start">R</InputAdornment>,
          }}
          type="number"
          value={rowData.retailPrice}
          disabled
        />
      ),
    },
  ];

  return (
    <div className={classes.wrapperContent}>
      <MaterialTable
        columns={columns}
        data={products}
        options={{
          headerStyle,
          sorting: false,
          grouping: false,
          draggable: false,
          search: false,
          showTitle: false,
          toolbar: false,
          paging: false,
        }}
        components={{
          OverlayLoading: () => (
            <div className={classes.loadingOverlay}>
              <CircularProgress color="primary" size={32} />
            </div>
          ),
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'No Takealot products.',
          },
        }}
      />
    </div>
  );
};

PricingTable.defaultProps = {
  products: [],
  updateProducts: (newProducts) =>
    console.log('Products were updated to:', newProducts),
  updateBasedOnCostPricing: (newProducts, id) =>
    console.log('Products and pricing were updated using:', id),
  updateBasedOnWholesalePricing: (newProducts, id) =>
    console.log('Products and pricing were updated using:', id),
};

PricingTable.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape()),
  updateProducts: PropTypes.func,
  updateBasedOnCostPricing: PropTypes.func,
  updateBasedOnWholesalePricing: PropTypes.func,
};

export default PricingTable;
