import { useState } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import SpeedIcon from '@material-ui/icons/Speed';

const StockInfoSelector = ({ stockInfoFilter, changeStockInfoFilter }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const getPrettyFilter = (filter) => {
    switch (filter) {
      case 'ALL':
        return 'All';
      case 'NO_STOCK':
        return 'No Stock';
      case 'MEASURING':
        return 'Measuring';
      case 'TAKING_STOCK_PHOTOS':
        return 'Taking Stock Photos';
      case 'COMPLETED':
        return 'Completed';
      default:
        return 'Custom';
    }
  };

  return (
    <>
      <Button
        aria-controls="stock-info-menu"
        aria-haspopup="true"
        size="large"
        startIcon={<SpeedIcon />}
        onClick={openMenu}
      >
        {getPrettyFilter(stockInfoFilter)}
      </Button>
      <Menu
        id="stock-info-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem
          onClick={() => {
            changeStockInfoFilter('ALL');
            closeMenu();
          }}
        >
          All Statuses
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeStockInfoFilter('NO_STOCK');
            closeMenu();
          }}
        >
          No Stock
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeStockInfoFilter('MEASURING');
            closeMenu();
          }}
        >
          Measuring
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeStockInfoFilter('TAKING_STOCK_PHOTOS');
            closeMenu();
          }}
        >
          Taking Stock Photos
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeStockInfoFilter('COMPLETED');
            closeMenu();
          }}
        >
          Completed
        </MenuItem>
      </Menu>
    </>
  );
};

StockInfoSelector.defaultProps = {
  stockInfoFilter: 'ALL',
  changeStockInfoFilter: (newFilter) =>
    console.log('User changes the stock info filter to:', newFilter),
};

StockInfoSelector.propTypes = {
  stockInfoFilter: PropTypes.string,
  changeStockInfoFilter: PropTypes.func,
};

export default StockInfoSelector;
