/* eslint-disable react/prop-types */
import { fade } from '@material-ui/core/styles/colorManipulator';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';

const headerStyle = {
  backgroundColor: '#A7A5A5',
  color: '#171415',
};

const useStyles = makeStyles((theme) => ({
  loadingOverlay: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: fade(theme.palette.common.black, 0.8),
  },
  wrapperContent: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

const SkuAdjustmentsTable = ({ adjustedSkus, updateAdjustedQuantities }) => {
  const classes = useStyles();

  const columns = [
    {
      title: 'SKU',
      field: 'sku',
      editable: 'never',
    },
    {
      title: 'TSIN',
      field: 'tsin',
      editable: 'never',
      render: (rowData) => (rowData.tsin === '' ? '-' : rowData.tsin),
    },
    {
      title: 'ASIN',
      field: 'childAsin',
      editable: 'never',
      render: (rowData) => (rowData.childAsin === '' ? '-' : rowData.childAsin),
    },
    {
      title: 'Product',
      field: 'product',
      editable: 'never',
    },
    {
      title: 'Requested Units',
      field: 'requestedUnits',
      type: 'numeric',
      editable: 'never',
    },
    {
      title: 'Suggested Units',
      field: 'adjustedUnits',
      type: 'numeric',
    },
  ];

  return (
    <div className={classes.wrapperContent}>
      <MaterialTable
        columns={columns}
        data={adjustedSkus}
        options={{
          headerStyle,
          actionsColumnIndex: -1,
          sorting: false,
          grouping: false,
          draggable: false,
          search: false,
          showTitle: false,
          toolbar: false,
          selection: false,
        }}
        components={{
          OverlayLoading: () => (
            <div className={classes.loadingOverlay}>
              <CircularProgress color="primary" size={32} />
            </div>
          ),
        }}
        editable={{
          onRowUpdate: (newData) =>
            new Promise((resolve) => {
              updateAdjustedQuantities(
                adjustedSkus.map((info) => {
                  if (info.sku === newData.sku) {
                    return {
                      ...info,
                      adjustedUnits: newData.adjustedUnits,
                    };
                  }
                  return info;
                })
              );
              resolve();
            }),
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'No skus in shipment.',
          },
        }}
      />
    </div>
  );
};

SkuAdjustmentsTable.defaultProps = {
  adjustedSkus: [],
  updateAdjustedQuantities: (rows) =>
    console.log('Users selected the following rows:', rows),
};

SkuAdjustmentsTable.propTypes = {
  adjustedSkus: PropTypes.arrayOf(
    PropTypes.shape({
      childAsin: PropTypes.string,
      sku: PropTypes.string,
      tsin: PropTypes.number,
      requestedUnits: PropTypes.number,
      adjustedUnits: PropTypes.number,
      tableData: {
        id: PropTypes.number,
        checked: PropTypes.bool,
      },
    })
  ),
  updateAdjustedQuantities: PropTypes.func,
};

export default SkuAdjustmentsTable;
