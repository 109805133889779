/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  createNewClient,
  getSuperDealerSummary,
} from '../../../api/clientsAPI';

const initialState = {
  isUserRedirectedToBrowse: false,
  superDealerSummary: {
    dealers: {},
    count: 0,
  },
  loadingStatus: {
    creation: 'NOT_STARTED',
    superDealerSummary: 'NOT_STARTED',
  },
  feedback: {
    isOpen: false,
    type: undefined,
    message: '',
  },
};

const clientsAddSlice = createSlice({
  name: 'clientsAdd',
  initialState,
  reducers: {
    createClientStarted(state) {
      state.loadingStatus.creation = 'PENDING';
    },
    createClientSuccess(state) {
      state.loadingStatus.creation = 'COMPLETE';
      state.isUserRedirectedToBrowse = true;
    },
    createClientFailed(state, action) {
      state.loadingStatus.creation = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    getSuperDealerSummaryStarted(state) {
      state.loadingStatus.superDealerSummary = 'PENDING';
    },
    getSuperDealerSummarySuccess(state, action) {
      state.loadingStatus.superDealerSummary = 'COMPLETE';
      state.superDealerSummary = action.payload;
    },
    getSuperDealerSummaryFailed(state, action) {
      state.loadingStatus.superDealerSummary = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    resetState() {
      return initialState;
    },
  },
});

export const {
  createClientStarted,
  createClientSuccess,
  createClientFailed,
  getSuperDealerSummaryStarted,
  getSuperDealerSummarySuccess,
  getSuperDealerSummaryFailed,
  resetState,
} = clientsAddSlice.actions;

export default clientsAddSlice.reducer;

export const createClient = (clientInfo) => async (dispatch) => {
  dispatch(createClientStarted());
  const result = await createNewClient(null, clientInfo);

  if (result.status === 200) {
    dispatch(createClientSuccess());
  } else {
    dispatch(createClientFailed(result.error));
  }
};

export const getDealerSummary = () => async (dispatch) => {
  dispatch(getSuperDealerSummaryStarted());
  const result = await getSuperDealerSummary();

  if (result.status === 200) {
    dispatch(getSuperDealerSummarySuccess(result.summary));
  } else {
    dispatch(getSuperDealerSummaryFailed(result.error));
  }
};

export const initiateStateReset = () => async (dispatch) => {
  dispatch(resetState());
};
