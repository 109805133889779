import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
  changeSku,
  fetchSkus,
  updateInitialPage,
  updatePageSize,
} from './slice';
import getFormattedListings from './logic';
import ContentWrapper from '../../../components/ContentWrapper';
import EmptyViewMessage from '../../../components/EmptyViewMessage';
import FiltersBar from './components/FiltersBar';
import PageHeader from '../../../components/PageHeader';
import SkusTable from './components/SkusTable';

const breadcrumbs = [
  {
    label: 'Listings',
    link: '/listings',
  },
  {
    label: 'Basic Info',
    link: '/listings/basic-info',
  },
];

const ListingsBasicInfo = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [platformFilter, setPlatformFilter] = useState('TAKEALOT');
  const [clientFilter, setClientFilter] = useState('ALL');
  const [statusFilter, setStatusFilter] = useState('ALL');

  const skus = useSelector((state) => state.listingsBasicInfo.skusList);
  const pageSize = useSelector((state) => state.listingsBasicInfo.pageSize);
  const initialPage = useSelector(
    (state) => state.listingsBasicInfo.initialPage
  );
  const skusLoadingStatus = useSelector(
    (state) => state.listingsBasicInfo.loadingStatus.skus
  );
  const updateSkuLoadingStatus = useSelector(
    (state) => state.listingsBasicInfo.loadingStatus.updateSku
  );

  useEffect(() => {
    if (skusLoadingStatus !== 'PENDING') {
      dispatch(fetchSkus(platformFilter));
      setClientFilter('ALL');
      setStatusFilter('ALL');
    }
  }, [platformFilter]);

  useEffect(() => {
    if (
      skusLoadingStatus === 'PENDING' ||
      updateSkuLoadingStatus === 'PENDING'
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [skusLoadingStatus, updateSkuLoadingStatus]);

  const getClients = () => {
    return _.uniq(
      getFormattedListings(
        skus,
        searchQuery,
        clientFilter,
        platformFilter,
        statusFilter
      ).map((info) => info.clientName)
    ).sort();
  };

  const findSku = (sku) => {
    const skuInfo = skus.find((info) => info.sku === sku);
    return skuInfo;
  };

  const changeBasicInfo = (
    platformCode,
    brand,
    productName,
    variation,
    stockImagesLink,
    status,
    sku
  ) => {
    const skuInfo = findSku(sku);
    if (platformFilter === 'AMAZON') {
      dispatch(
        changeSku(sku, {
          ...skuInfo,
          brand,
          productName,
          variation,
          stockImagesLink,
          amazon: {
            ...skuInfo.amazon,
            status,
            childAsin: platformCode,
          },
        })
      );
    }
    dispatch(
      changeSku(sku, {
        ...skuInfo,
        brand,
        productName,
        variation,
        stockImagesLink,
        takealot: {
          ...skuInfo.takealot,
          status,
          tsin: platformCode,
        },
      })
    );
  };

  return (
    <div>
      <PageHeader
        title="Basic Info"
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Browse"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
        actions={[
          {
            label: 'Add new SKU',
            icon: <AddIcon />,
            onClick: () => history.push('/listings/basic-info/add'),
          },
        ]}
      />
      {isLoading && <LinearProgress color="secondary" />}
      <ContentWrapper>
        {!isLoading && skus.length === 0 ? (
          <EmptyViewMessage
            heading="No SKUs Available"
            message="Please adjust the filters or add new SKU to view them here."
          />
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FiltersBar
                searchText={searchQuery}
                changeSearchText={setSearchQuery}
                listingCount={
                  getFormattedListings(
                    skus,
                    searchQuery,
                    clientFilter,
                    platformFilter,
                    statusFilter
                  ).length
                }
                platformFilter={platformFilter}
                changePlatformFilter={(newPlatform) => {
                  setPlatformFilter(newPlatform);
                  setClientFilter('ALL');
                }}
                clientFilter={clientFilter}
                changeClientFilter={setClientFilter}
                clientOptions={getClients()}
                statusFilter={statusFilter}
                changeStatusFilter={setStatusFilter}
              />
              <SkusTable
                platform={platformFilter}
                isLoading={isLoading}
                skus={getFormattedListings(
                  skus,
                  searchQuery,
                  clientFilter,
                  platformFilter,
                  statusFilter
                )}
                viewSku={(sku) =>
                  history.push(`/listings/basic-info/browse/${sku}`)
                }
                changeBasicInfo={(
                  platformCode,
                  brand,
                  productName,
                  variation,
                  stockImagesLink,
                  status,
                  sku
                ) =>
                  changeBasicInfo(
                    platformCode,
                    brand,
                    productName,
                    variation,
                    stockImagesLink,
                    status,
                    sku
                  )
                }
                pageSize={pageSize}
                initialPage={initialPage}
                updatePageSize={(newPageSize) =>
                  dispatch(updatePageSize(newPageSize))
                }
                updateInitialPage={(newInitialPage) =>
                  dispatch(updateInitialPage(newInitialPage))
                }
              />
            </Grid>
          </Grid>
        )}
      </ContentWrapper>
    </div>
  );
};

export default ListingsBasicInfo;
