import React from 'react';
import PropTypes from 'prop-types';
import AmazonStatement from './components/AmazonStatement';
import TakealotInvoice from './components/TakealotInvoice';
import TakealotReconciliation from './components/TakealotReconciliation';
import TakealotRetailStatement from './components/TakealotRetailStatement';
import TakealotWholesaleStatement from './components/TakealotWholesaleStatement';

const StatementDocument = ({
  version,
  isClientVatRegistered,
  type,
  platform,
  ourInfo,
  clientInfo,
  startDate,
  endDate,
  lineItems,
  totalDue,
}) => {
  const sortLineItemsAlphabetically = (a, b) => {
    if (a.name > b.name) return +1;
    if (a.name < b.name) return -1;
    return 0;
  };

  const standardSales = lineItems
    .filter(
      (info) => info.type === 'SALE' && info.discountStatus === 'STANDARD'
    )
    .sort(sortLineItemsAlphabetically);
  const standardRefunds = lineItems
    .filter(
      (info) => info.type === 'REFUND' && info.discountStatus === 'STANDARD'
    )
    .sort(sortLineItemsAlphabetically);
  const discountedSales = lineItems
    .filter(
      (info) => info.type === 'SALE' && info.discountStatus === 'DISCOUNTED'
    )
    .sort(sortLineItemsAlphabetically);
  const discountedRefunds = lineItems
    .filter(
      (info) => info.type === 'REFUND' && info.discountStatus === 'DISCOUNTED'
    )
    .sort(sortLineItemsAlphabetically);
  const adjustments = lineItems
    .filter((info) => info.type === 'ADJUSTMENT')
    .sort(sortLineItemsAlphabetically);

  if (platform === 'amazon') {
    if (version === 2) {
      return (
        <AmazonStatement
          ourInfo={ourInfo}
          clientInfo={clientInfo}
          startDate={startDate}
          endDate={endDate}
          standardSales={standardSales}
          standardRefunds={standardRefunds}
          discountedSales={discountedSales}
          discountedRefunds={discountedRefunds}
          adjustments={adjustments}
          totalDue={totalDue}
        />
      );
    }

    return (
      <AmazonStatement
        ourInfo={ourInfo}
        clientInfo={clientInfo}
        startDate={startDate}
        endDate={endDate}
        standardSales={standardSales}
        standardRefunds={standardRefunds}
        discountedSales={discountedSales}
        discountedRefunds={discountedRefunds}
        adjustments={adjustments}
        totalDue={totalDue}
      />
    );
  }

  if (version === 2 && isClientVatRegistered) {
    if (type === 'STATEMENT') {
      return (
        <TakealotRetailStatement
          ourInfo={ourInfo}
          clientInfo={clientInfo}
          startDate={startDate}
          endDate={endDate}
          standardSales={standardSales}
          standardRefunds={standardRefunds}
          discountedSales={discountedSales}
          discountedRefunds={discountedRefunds}
          totalDue={totalDue}
        />
      );
    }
    if (type === 'INVOICE') {
      return (
        <TakealotInvoice
          ourInfo={ourInfo}
          clientInfo={clientInfo}
          startDate={startDate}
          endDate={endDate}
          lineItems={lineItems}
          adjustments={adjustments}
        />
      );
    }
    return (
      <TakealotReconciliation
        ourInfo={ourInfo}
        clientInfo={clientInfo}
        startDate={startDate}
        endDate={endDate}
        lineItems={lineItems}
        adjustments={adjustments}
      />
    );
  }

  return (
    <TakealotWholesaleStatement
      ourInfo={ourInfo}
      clientInfo={clientInfo}
      startDate={startDate}
      endDate={endDate}
      standardSales={standardSales}
      standardRefunds={standardRefunds}
      discountedSales={discountedSales}
      discountedRefunds={discountedRefunds}
      adjustments={adjustments}
      totalDue={totalDue}
    />
  );
};

StatementDocument.defaultProps = {
  version: 1,
  isClientVatRegistered: false,
  platform: 'amazon',
  type: 'STATEMENT',
  ourInfo: {},
  clientInfo: {},
  startDate: '01-12-2020',
  endDate: '31-12-2020',
  lineItems: [],
  totalDue: 0,
};

StatementDocument.propTypes = {
  version: PropTypes.number,
  isClientVatRegistered: PropTypes.bool,
  platform: PropTypes.string,
  type: PropTypes.string,
  ourInfo: PropTypes.shape({
    businessName: PropTypes.string,
    vatNumber: PropTypes.string,
    physicalAddress: PropTypes.string,
    telephoneNumber: PropTypes.string,
  }),
  clientInfo: PropTypes.shape({
    name: PropTypes.string,
    legalEntityName: PropTypes.string,
    vatNumber: PropTypes.string,
    physicalAddress: PropTypes.string,
    telephoneNumber: PropTypes.string,
    mainContact: PropTypes.string,
    emailAddress: PropTypes.string,
  }),
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  lineItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      units: PropTypes.number,
      perUnit: PropTypes.number,
      total: PropTypes.number,
      type: PropTypes.string,
      discountStatus: PropTypes.string,
    })
  ),
  totalDue: PropTypes.number,
};

export default StatementDocument;
