import PropTypes from 'prop-types';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import { Scrollbars } from 'react-custom-scrollbars';
import Typography from '@material-ui/core/Typography';
import MaintenanceNav from './components/MaintenanceNav';
import OnboardingNav from './components/OnboardingNav';

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: grey[800],
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginBottom: theme.spacing(2),
  },
  jobTitle: {
    color: grey[700],
  },
  sectionDivider: {
    margin: `${theme.spacing(2)}px 0`,
  },
  username: {
    color: grey[400],
  },
  wrapperNav: {
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
  },
  wrapperProfile: {
    padding: theme.spacing(4),
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const PresalesSidebar = ({
  isOpen,
  username,
  jobTitle,
  userType,
  activeSectionLink,
  goToLink,
}) => {
  const classes = useStyles();

  return (
    <Scrollbars className={classes.wrapperNav}>
      {isOpen && (
        <div className={classes.wrapperProfile}>
          <Avatar className={classes.avatar} />
          <Typography variant="h5" className={classes.username}>
            {username}
          </Typography>
          <Typography variant="subtitle2" className={classes.jobTitle}>
            {jobTitle}
          </Typography>
        </div>
      )}
      {isOpen && <Divider />}
      {!isOpen && userType === 'ADMIN' && (
        <Divider className={classes.sectionDivider} />
      )}
      {userType === 'ADMIN' && (
        <MaintenanceNav
          isOpen={isOpen}
          activeSectionLink={activeSectionLink}
          goToLink={goToLink}
        />
      )}
      {!isOpen && userType === 'ADMIN' && (
        <Divider className={classes.sectionDivider} />
      )}
      {userType === 'ADMIN' && (
        <OnboardingNav
          isOpen={isOpen}
          activeSectionLink={activeSectionLink}
          goToLink={goToLink}
        />
      )}
    </Scrollbars>
  );
};

PresalesSidebar.defaultProps = {
  isOpen: true,
  username: 'John Doe',
  jobTitle: 'Administrator',
  userType: 'ADMIN',
  activeSectionLink: '/dashboard',
  goToLink: (link) => console.log('User navigated to:', link),
};

PresalesSidebar.propTypes = {
  isOpen: PropTypes.bool,
  username: PropTypes.string,
  jobTitle: PropTypes.string,
  userType: PropTypes.string,
  activeSectionLink: PropTypes.string,
  goToLink: PropTypes.func,
};

export default PresalesSidebar;
