import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import { IconButton } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import SettingIcon from '@material-ui/icons/Settings';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import UpdateIcon from '@material-ui/icons/Update';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import {
  deleteSupplierDocument,
  fetchDocuments,
  fetchSupplierInfo,
} from './slice';
import BasicInfoCard from './components/BasicInfoCard';
import SettingsTable from './components/SettingsTable';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import ContentWrapper from '../../../components/ContentWrapper';
import DocumentsTab from '../../../components/DocumentsTab';
import EmptyViewMessage from '../../../components/EmptyViewMessage';
import PageHeader from '../../../components/PageHeader';
import allStyles from '../../../utils/styles';

const useStyles = makeStyles(allStyles);

const breadcrumbs = [
  {
    label: 'Presales',
    link: '/presales',
  },
  {
    label: 'Suppliers',
    link: '/presales/suppliers',
  },
  {
    label: 'Browse',
    link: '/presales/suppliers/browse',
  },
];

const SuppliersView = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { supplierId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('DOCUMENTS');
  const [isDeleteDocumentDialogOpen, setIsDeleteDocumentDialogOpen] =
    useState(false);
  const [documentToBeDeleted, setDocumentToBeDeleted] = useState({
    documentPath: '',
    documentId: '',
  });

  const supplierInfo = useSelector(
    (state) => state.presalesSuppliersView.supplierInfo
  );
  const documents = useSelector(
    (state) => state.presalesSuppliersView.documents
  );
  const supplierInfoLoadingStatus = useSelector(
    (state) => state.presalesSuppliersView.loadingStatus.supplierInfo
  );
  const documentLoadingStatus = useSelector(
    (state) => state.presalesSuppliersView.loadingStatus.documents
  );
  const deleteDocumentLoadingStatus = useSelector(
    (state) => state.presalesSuppliersView.loadingStatus.deleteDocument
  );

  useEffect(() => {
    dispatch(fetchSupplierInfo(supplierId));
    dispatch(fetchDocuments(supplierId));
  }, []);

  useEffect(() => {
    if (
      supplierInfoLoadingStatus === 'PENDING' ||
      documentLoadingStatus === 'PENDING' ||
      deleteDocumentLoadingStatus === 'PENDING'
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [
    supplierInfoLoadingStatus,
    documentLoadingStatus,
    deleteDocumentLoadingStatus,
  ]);

  const getStatusChip = (status) => {
    switch (status) {
      case 'AWAITING_APPROVAL':
        return (
          <Chip label="Awaiting Approval" className={classes.statusYellow} />
        );
      case 'ONBOARDING_PRODUCTS':
        return (
          <Chip label="Onboarding Products" className={classes.statusYellow} />
        );
      case 'REJECTED':
        return <Chip label="Rejected" className={classes.statusRed} />;
      case 'TRADING':
        return <Chip label="Trading" className={classes.statusGreen} />;
      default:
        return <Chip label="Active" className={classes.statusGreen} />;
    }
  };

  return (
    <div>
      <PageHeader
        title="Supplier Details"
        subtitle={isLoading ? 'Loading...' : supplierInfo.name}
        breadcrumbs={breadcrumbs}
        currentPageBreadcrumb="Supplier"
        goToLink={(link) => history.push(link)}
        goBack={() => history.goBack()}
      />
      {isLoading && <LinearProgress color="secondary" />}
      <ContentWrapper>
        {!isLoading && supplierInfo.sku === 'Loading' ? (
          <EmptyViewMessage
            heading="Info Failed to Load"
            message="Please check your internet connection and reload the page."
          />
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <div>
                {getStatusChip(supplierInfo.status)}
                <Button
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  startIcon={<EditIcon />}
                  onClick={() =>
                    history.push(`/presales/suppliers/edit/${supplierId}`)
                  }
                >
                  Edit details
                </Button>
                <div className={classes.seperatorButtons} />
                <Button
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  startIcon={<NoteAddIcon />}
                  onClick={() =>
                    history.push(
                      `/presales/suppliers/browse/upload-documents/${supplierId}`
                    )
                  }
                >
                  Upload Documents
                </Button>
                <div className={classes.separator} />
                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={<UpdateIcon />}
                  style={{ padding: '5px 40px' }}
                  onClick={() =>
                    history.push(
                      `/presales/suppliers/browse/update-pricing/${supplierId}`
                    )
                  }
                >
                  Update Pricing
                </Button>
                <IconButton
                  variant="outlined"
                  color="secondary"
                  onClick={() =>
                    history.push(
                      `/presales/suppliers/browse/update-pricing-settings/${supplierId}`
                    )
                  }
                >
                  <SettingIcon />
                </IconButton>
                <div className={classes.separatorButtons} />
                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={<UpdateIcon />}
                  style={{ padding: '5px 45px' }}
                  onClick={() =>
                    history.push(
                      `/presales/suppliers/browse/update-stock/${supplierId}`
                    )
                  }
                >
                  Update Stock
                </Button>
                <IconButton
                  variant="outlined"
                  color="secondary"
                  onClick={() =>
                    history.push(
                      `/presales/suppliers/browse/update-stock-settings/${supplierId}`
                    )
                  }
                >
                  <SettingIcon />
                </IconButton>
              </div>
              <div className={classes.separator} />
              <div>
                <BasicInfoCard
                  code={supplierInfo.code}
                  websiteUrl={supplierInfo.additionalInfo.websiteUrl}
                  contactPerson={supplierInfo.additionalInfo.mainContact}
                  contactNumber={supplierInfo.additionalInfo.telephoneNumber}
                  status={supplierInfo.status}
                  isLoading={supplierInfoLoadingStatus === 'PENDING'}
                  onMissingInfoClick={() =>
                    history.push(
                      `/presales/suppliers/browse/edit/${supplierId}`
                    )
                  }
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={9}>
              {!isLoading && (
                <div>
                  <AppBar position="static">
                    <Tabs
                      value={activeTab}
                      onChange={(e, newValue) => setActiveTab(newValue)}
                      aria-label="supplier tabs"
                    >
                      <Tab label="Documents" value="DOCUMENTS" />
                      <Tab label="Supplier Settings" value="SUPPLIERSETTINGS" />
                    </Tabs>
                  </AppBar>
                  <div className={classes.separator} />
                  {activeTab === 'DOCUMENTS' && (
                    <DocumentsTab
                      documents={documents}
                      deleteDocument={(documentInfo) => {
                        setIsDeleteDocumentDialogOpen(true);
                        setDocumentToBeDeleted({
                          documentPath: documentInfo.reference,
                          documentId: documentInfo.id,
                        });
                      }}
                    />
                  )}
                  <div className={classes.separator} />

                  {activeTab === 'SUPPLIERSETTINGS' && (
                    <SettingsTable
                      minUnitsAvailable={
                        supplierInfo.supplierSettings.minUnitsAvailable
                      }
                      maxCostPrice={supplierInfo.supplierSettings.maxCostPrice}
                      minRetailPrice={
                        supplierInfo.supplierSettings.minRetailPrice
                      }
                      sohPercentMadeAvailable={
                        supplierInfo.supplierSettings.sohPercentMadeAvailable
                      }
                      maxWeight={supplierInfo.supplierSettings.maxWeight}
                      maxLongestSide={
                        supplierInfo.supplierSettings.maxLongestSide
                      }
                      minMargin={supplierInfo.supplierSettings.minMargin}
                      vatOption={supplierInfo.supplierSettings.vatOption}
                      isLoading={supplierInfoLoadingStatus === 'PENDING'}
                    />
                  )}
                </div>
              )}
            </Grid>
          </Grid>
        )}
      </ContentWrapper>
      <ConfirmationDialog
        isOpen={isDeleteDocumentDialogOpen}
        message="Are you sure you want to delete this document?"
        closeDialog={() => {
          setIsDeleteDocumentDialogOpen(false);
          setDocumentToBeDeleted({
            documentPath: '',
            documentId: '',
          });
        }}
        confirmAction={() =>
          dispatch(
            deleteSupplierDocument(
              supplierId,
              documentToBeDeleted.documentPath,
              documentToBeDeleted.documentId
            )
          )
        }
      />
    </div>
  );
};

export default SuppliersView;
