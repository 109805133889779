import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import DateTypeSelector from './components/DateTypeSelector';
import RangeSelector from './components/RangeSelector';

const useStyles = makeStyles((theme) => ({
  separator: {
    width: theme.spacing(1),
  },
  text: {
    color: theme.palette.primary.contrastText,
  },
  wrapperRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));

const DateFilteringBar = ({
  startDate,
  changeStartDate,
  endDate,
  changeEndDate,
  dateTypeId,
  changeDateType,
  rangeId,
  changeRange,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapperRoot}>
      <DateTypeSelector
        dateTypeId={dateTypeId}
        changeDateType={changeDateType}
      />
      <div className={classes.separator} />
      <RangeSelector
        startDate={startDate}
        changeStartDate={changeStartDate}
        endDate={endDate}
        changeEndDate={changeEndDate}
        rangeId={rangeId}
        changeRange={changeRange}
      />
    </div>
  );
};

DateFilteringBar.defaultProps = {
  startDate: new Date(),
  changeStartDate: (newValue) =>
    `Start date was changed to '${newValue.toString()}'`,
  endDate: new Date(),
  changeEndDate: (newValue) =>
    `End date was changed to '${newValue.toString()}'`,
  dateTypeId: 'LAST_UPDATED',
  changeDateType: (newValue) => `Date type was changed to '${newValue}'`,
  rangeId: 'THIS_MONTH',
  changeRange: (newValue) => `Range was changed to '${newValue}'`,
};

DateFilteringBar.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  changeStartDate: PropTypes.func,
  endDate: PropTypes.instanceOf(Date),
  changeEndDate: PropTypes.func,
  dateTypeId: PropTypes.string,
  changeDateType: PropTypes.func,
  rangeId: PropTypes.string,
  changeRange: PropTypes.func,
};

export default DateFilteringBar;
