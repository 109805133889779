import { firestore, storage } from '../utils/firebase';

export const createNewSupplier = async (info) => {
  const newListingRef = firestore.collection('suppliers').doc();

  return newListingRef
    .set(info)
    .then(() => {
      return {
        status: 200,
        message: 'Added',
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const updateSupplier = async (sku, newSupplier) => {
  const supplierRef = firestore.collection('suppliers').doc(sku);

  return supplierRef
    .update(newSupplier)
    .then(() => {
      return {
        status: 200,
        message: 'Updated',
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const getSuppliers = async () => {
  const skuRef = firestore.collection('suppliers');
  return skuRef
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.length === 0) {
        return {
          status: 500,
        };
      }
      const result = querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });

      return {
        suppliers: result,
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const getSupplier = async (sku) => {
  const supplierRef = firestore.collection('suppliers').doc(sku);

  return supplierRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        const supplierInfo = { id: doc.id, ...doc.data() };

        return {
          supplierInfo,
          status: 200,
        };
      }

      return {
        supplierRef,
        status: 500,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const uploadDocuments = async (
  supplierId,
  doc,
  tag,
  description,
  uploaderId,
  uploaderName,
  updateUploadBytes,
  uploadSuccessful,
  uploadFailed
) => {
  const storageRef = storage.ref();
  const supplierDocumentRef = firestore
    .collection('suppliers')
    .doc(supplierId)
    .collection('documents')
    .doc();
  const newFileRef = storageRef.child(
    `suppliers/${supplierId}/documents/${supplierDocumentRef.id}`
  );
  const uploadTask = newFileRef.put(doc);
  uploadTask.on(
    'state_changed',
    (snapshot) => {
      updateUploadBytes(
        supplierDocumentRef.id,
        snapshot.totalBytes,
        snapshot.bytesTransferred
      );
    },
    () => {
      uploadFailed();
    },
    async () => {
      return supplierDocumentRef
        .set({
          tag,
          description,
          reference: newFileRef.fullPath,
          fileSize: doc.size,
          uploaderId,
          uploaderName,
        })
        .then(() => {
          uploadSuccessful();
        })
        .catch((error) => {
          uploadFailed();
          console.log(error);
        });
    }
  );
};

export const getDocuments = async (supplierId) => {
  const documentsRef = firestore
    .collection('suppliers')
    .doc(supplierId)
    .collection('documents');
  const storageRef = storage.ref();

  return documentsRef
    .get()
    .then(async (querySnapshot) => {
      if (querySnapshot.length === 0) {
        return {
          status: 500,
        };
      }

      const documentRefs = [];
      const documents = [];

      querySnapshot.forEach(async (doc) => {
        const documentInfo = doc.data();
        const documentRef = storageRef.child(documentInfo.reference);
        documentRefs.push(documentRef);
        documents.push({ ...documentInfo, id: doc.id });
      });

      await Promise.all(
        documentRefs.map((documentRef, index) =>
          documentRef.getDownloadURL().then((url) => {
            documents[index] = {
              ...documents[index],
              url,
            };
          })
        )
      );

      return {
        documents,
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const deleteDocument = async (supplierId, documentPath, documentId) => {
  const storageRef = storage.ref();
  const supplierDocumentRef = firestore
    .collection('suppliers')
    .doc(supplierId)
    .collection('documents')
    .doc(documentId);
  const documentRef = storageRef.child(documentPath);

  await documentRef.delete();

  return supplierDocumentRef
    .delete()
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        error,
        status: 500,
      };
    });
};
