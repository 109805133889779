import { useState } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import VisibilityIcon from '@material-ui/icons/Visibility';

const PageViewsSelector = ({
  pageViewsFilter,
  changePageViewsFilter,
  rangeInDays,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const getPrettyFilter = (filter) => {
    if (rangeInDays <= 7) {
      switch (filter) {
        case 'ALL':
          return 'All Page Views';
        case 'LESS_THAN_25':
          return '< 25';
        case 'BETWEEN_25_AND_250':
          return '25 - 250';
        case 'MORE_THAN_250':
          return '> 250';
        default:
          return 'Custom';
      }
    } else {
      switch (filter) {
        case 'ALL':
          return 'All Page Views';
        case 'LESS_THAN_100':
          return '< 100';
        case 'BETWEEN_100_AND_1000':
          return '100 - 1,000';
        case 'MORE_THAN_1000':
          return '> 1,000';
        default:
          return 'Custom';
      }
    }
  };

  return (
    <>
      <Button
        aria-controls="page-views-menu"
        aria-haspopup="true"
        size="large"
        startIcon={<VisibilityIcon />}
        onClick={openMenu}
      >
        {getPrettyFilter(pageViewsFilter)}
      </Button>
      <Menu
        id="page-views-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem
          onClick={() => {
            changePageViewsFilter('ALL');
            closeMenu();
          }}
        >
          All
        </MenuItem>
        {rangeInDays <= 7 ? (
          <>
            <MenuItem
              onClick={() => {
                changePageViewsFilter('LESS_THAN_25');
                closeMenu();
              }}
            >
              {'< 25'}
            </MenuItem>
            <MenuItem
              onClick={() => {
                changePageViewsFilter('BETWEEN_25_AND_250');
                closeMenu();
              }}
            >
              25 - 250
            </MenuItem>
            <MenuItem
              onClick={() => {
                changePageViewsFilter('MORE_THAN_250');
                closeMenu();
              }}
            >
              {'> 250'}
            </MenuItem>
          </>
        ) : (
          <>
            <MenuItem
              onClick={() => {
                changePageViewsFilter('LESS_THAN_100');
                closeMenu();
              }}
            >
              {'< 100'}
            </MenuItem>
            <MenuItem
              onClick={() => {
                changePageViewsFilter('BETWEEN_100_AND_1000');
                closeMenu();
              }}
            >
              100 - 1,000
            </MenuItem>
            <MenuItem
              onClick={() => {
                changePageViewsFilter('MORE_THAN_1000');
                closeMenu();
              }}
            >
              {'> 1,000'}
            </MenuItem>
          </>
        )}
      </Menu>
    </>
  );
};

PageViewsSelector.defaultProps = {
  pageViewsFilter: 'ALL',
  changePageViewsFilter: (newFilter) =>
    console.log('User changes the page views filter to:', newFilter),
  rangeInDays: 7,
};

PageViewsSelector.propTypes = {
  pageViewsFilter: PropTypes.string,
  changePageViewsFilter: PropTypes.func,
  rangeInDays: PropTypes.number,
};

export default PageViewsSelector;
