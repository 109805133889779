import _ from 'lodash';

const getFormattedListings = (suppliers, searchQuery) => {
  return suppliers
    .map(
      ({
        id,
        status,
        contactPerson,
        websiteUrl,
        contactNumber,
        category,
        name,
      }) => {
        return {
          sku: id,
          name,
          status,
          contactPerson,
          websiteUrl,
          contactNumber,
          category,
        };
      }
    )
    .filter(({ category, name }) => {
      if (searchQuery !== '') {
        const caseInsensitiveSearch = _.toLower(searchQuery);
        const caseInsensitiveCategory = _.toLower(category);
        const caseInsensitiveSupplierName = _.toLower(name);

        if (caseInsensitiveCategory.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveSupplierName.includes(caseInsensitiveSearch)) {
          return true;
        }
        return false;
      }

      return true;
    })
    .sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return +1;
      return 0;
    });
};

export default getFormattedListings;
