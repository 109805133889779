export const getShipmentPlatform = (fromId, toId) => {
  let platform = 'BOTH';

  if (fromId === 'baGsBI92BgjNEB9r0tPT' || toId === 'baGsBI92BgjNEB9r0tPT') {
    platform = 'AMAZON';
  }
  if (
    fromId === 'ktwmpFt2DUXU1wsC59EJ' ||
    toId === 'ktwmpFt2DUXU1wsC59EJ' ||
    fromId === 't6NDHxRtKUiOhmv64ptz' ||
    toId === 't6NDHxRtKUiOhmv64ptz'
  ) {
    platform = 'TAKEALOT';
  }

  return platform;
};

export const getTotalInsuranceValue = (skus) => {
  return skus.reduce((total, current) => {
    return total + current.costPrice * current.units;
  }, 0);
};
