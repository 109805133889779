/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { updateSupplier, createNewSupplier } from '../../../api/suppliersAPI';

const initialState = {
  isUserRedirectedToBrowse: false,
  loadingStatus: {
    creation: 'NOT_STARTED',
    edit: 'NOT_STARTED',
  },
  feedback: {
    isOpen: false,
    type: undefined,
    message: '',
  },
};

const suppliersEditSlice = createSlice({
  name: 'supplierEdit',
  initialState,
  reducers: {
    createSupplierStarted(state) {
      state.loadingStatus.creation = 'PENDING';
    },
    createSupplierSuccess(state) {
      state.loadingStatus.creation = 'COMPLETE';
      state.isUserRedirectedToBrowse = true;
    },
    createSupplierFailed(state, action) {
      state.loadingStatus.creation = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    editSupplierStarted(state) {
      state.loadingStatus.edit = 'PENDING';
    },
    editSupplierSuccess(state) {
      state.loadingStatus.edit = 'COMPLETE';
      state.isUserRedirectedToBrowse = true;
    },
    editSupplierFailed(state, action) {
      state.loadingStatus.edit = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    resetState() {
      return initialState;
    },
  },
});

export const {
  createSupplierStarted,
  createSupplierSuccess,
  createSupplierFailed,
  editSupplierStarted,
  editSupplierSuccess,
  editSupplierFailed,
  resetState,
} = suppliersEditSlice.actions;

export default suppliersEditSlice.reducer;

export const createSupplier = (sku, data) => async (dispatch) => {
  dispatch(createSupplierStarted());
  const result = await createNewSupplier(sku, data);

  if (result.status === 200) {
    dispatch(createSupplierSuccess());
  } else {
    dispatch(createSupplierFailed(result.error));
  }
};

export const editSupplier = (sku, data) => async (dispatch) => {
  dispatch(editSupplierStarted());
  const result = await updateSupplier(sku, data);

  if (result.status === 200) {
    dispatch(editSupplierSuccess(result.message));
  } else {
    dispatch(editSupplierFailed(result.error));
  }
};

export const initiateStateReset = () => async (dispatch) => {
  dispatch(resetState());
};
