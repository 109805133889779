import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';

const FeedbackSnackbar = ({ type, isOpen, message, closeSnackbar }) => {
  return (
    <Snackbar open={isOpen} autoHideDuration={6000} onClose={closeSnackbar}>
      <Alert
        elevation={6}
        variant="filled"
        onClose={closeSnackbar}
        severity={type}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

FeedbackSnackbar.defaultProps = {
  type: 'success',
};

FeedbackSnackbar.propTypes = {
  type: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  closeSnackbar: PropTypes.func.isRequired,
};

export default FeedbackSnackbar;
