import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import EmptyViewMessage from '../../../../../components/EmptyViewMessage';
import SkusTable from './components/SkusTable';

const useStyles = makeStyles((theme) => ({
  seperator: {
    height: theme.spacing(3),
  },
}));

const UnitsTab = ({ skus }) => {
  const classes = useStyles();
  const history = useHistory();

  if (skus.length === 0) {
    return (
      <div className={classes.root}>
        <EmptyViewMessage
          heading="No SKUs"
          message="Please edit the shipment to include SKUs."
        />
      </div>
    );
  }

  return (
    <SkusTable
      skus={skus.map((skuInfo) => {
        let unfrozenSku = {};
        unfrozenSku = {
          sku: skuInfo.sku,
          tsin: skuInfo.tsin,
          childAsin: skuInfo.childAsin,
          product: skuInfo.product,
          units: skuInfo.units,
        };
        return unfrozenSku;
      })}
      viewSku={(sku) => history.push(`/listings/basic-info/browse/${sku}`)}
    />
  );
};

UnitsTab.defaultProps = {
  skus: [],
};

UnitsTab.propTypes = {
  skus: PropTypes.arrayOf(PropTypes.shape({})),
};

export default UnitsTab;
