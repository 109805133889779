/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getOnboardingSkus, updateSku } from '../../../api/listingsAPI';

const initialState = {
  skusList: [],
  loadingStatus: {
    skus: 'NOT_STARTED',
    updateSku: 'NOT_STARTED',
  },
  pageSize: 5,
  initialPage: 0,
};

const listingsOnboardingSlice = createSlice({
  name: 'listingsOnboarding',
  initialState,
  reducers: {
    getListingsStarted(state) {
      state.loadingStatus.skus = 'PENDING';
    },
    getListingsSuccess(state, action) {
      state.skusList = action.payload;
      state.loadingStatus.skus = 'COMPLETE';
    },
    getListingsFailed(state, action) {
      state.loadingStatus.skus = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    updateSkuStarted(state) {
      state.loadingStatus.updateSku = 'PENDING';
    },
    updateSkuSuccess(state, action) {
      state.skusList = state.skusList.map((info) => {
        if (info.sku === action.payload.sku) {
          return action.payload.newInfo;
        }
        return info;
      });
      state.loadingStatus.updateSku = 'COMPLETE';
    },
    updateSkuFailed(state, action) {
      state.loadingStatus.updateSku = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    bulkUpdateStarted(state) {
      state.loadingStatus.updateSku = 'PENDING';
    },
    bulkUpdateSuccess(state, action) {
      state.skusList = state.skusList.map((info) => {
        const updatedSkuFound = action.payload.skus.find(
          (updatedSku) => updatedSku.sku === info.sku
        );
        if (updatedSkuFound) {
          return {
            ...updatedSkuFound,
            onboarding: {
              ...updatedSkuFound.onboarding,
              ...action.payload.onboarding,
            },
          };
        }
        return info;
      });
      state.loadingStatus.updateSku = 'COMPLETE';
    },
    bulkUpdateFailed(state, action) {
      state.loadingStatus.updateSku = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    changePageSize(state, action) {
      state.pageSize = action.payload;
    },
    changeInitialPage(state, action) {
      state.initialPage = action.payload;
    },
  },
});

export const {
  getListingsStarted,
  getListingsSuccess,
  getListingsFailed,
  updateSkuStarted,
  updateSkuSuccess,
  updateSkuFailed,
  bulkUpdateStarted,
  bulkUpdateSuccess,
  bulkUpdateFailed,
  changePageSize,
  changeInitialPage,
} = listingsOnboardingSlice.actions;

export const fetchSkus = (platform) => async (dispatch) => {
  dispatch(getListingsStarted());
  const result = await getOnboardingSkus(platform);

  if (result.status === 200) {
    dispatch(getListingsSuccess(result.skus));
  } else {
    dispatch(getListingsFailed(result.error));
  }
};

export const changeSku = (sku, newInfo, platform) => async (dispatch) => {
  dispatch(updateSkuStarted());
  const result = await updateSku(sku, newInfo);

  if (result.status === 200) {
    if (platform === 'AMAZON') {
      dispatch(
        updateSkuSuccess({
          sku,
          newInfo: { ...newInfo, onboarding: newInfo.amazon.onboarding },
        })
      );
    } else {
      dispatch(
        updateSkuSuccess({
          sku,
          newInfo: { ...newInfo, onboarding: newInfo.takealot.onboarding },
        })
      );
    }
  } else {
    dispatch(updateSkuFailed(result.error));
  }
};

export const bulkUpdateSkus = (skus, newInfo, platform) => async (dispatch) => {
  dispatch(bulkUpdateStarted());
  const results = await Promise.all(
    skus.map((info) => updateSku(info.sku, newInfo))
  );

  const allPassed =
    results.filter((result) => result.status !== 200).length === 0;

  if (allPassed) {
    if (platform === 'AMAZON') {
      dispatch(
        bulkUpdateSuccess({
          skus,
          onboarding: {
            photosRequired: newInfo['amazon.onboarding.photosRequired'],
            clientCopyLink: newInfo['amazon.onboarding.clientCopyLink'],
            clientImagesLink: newInfo['amazon.onboarding.clientImagesLink'],
            specialRequests: newInfo['amazon.onboarding.specialRequests'],
          },
        })
      );
    } else {
      dispatch(
        bulkUpdateSuccess({
          skus,
          onboarding: {
            photosRequired: newInfo['takealot.onboarding.photosRequired'],
            clientCopyLink: newInfo['takealot.onboarding.clientCopyLink'],
            clientImagesLink: newInfo['takealot.onboarding.clientImagesLink'],
            specialRequests: newInfo['takealot.onboarding.specialRequests'],
          },
        })
      );
    }
  } else {
    dispatch(
      bulkUpdateFailed({
        message: 'Failed to update all SKUs.',
      })
    );
  }
};

export const updatePageSize = (newPageSize) => (dispatch) => {
  dispatch(changePageSize(newPageSize));
};

export const updateInitialPage = (newInitialPage) => (dispatch) => {
  dispatch(changeInitialPage(newInitialPage));
};

export default listingsOnboardingSlice.reducer;
