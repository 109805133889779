import { useState } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

const PlatformSelector = ({ platformFilter, changePlatformFilter }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const getPrettyFilter = (filter) => {
    switch (filter) {
      case 'AMAZON':
        return 'Amazon';
      case 'TAKEALOT':
        return 'Takealot';
      default:
        return 'Custom';
    }
  };

  return (
    <>
      <Button
        aria-controls="platforms-menu"
        aria-haspopup="true"
        size="large"
        startIcon={<ShoppingCartIcon />}
        onClick={openMenu}
      >
        {getPrettyFilter(platformFilter)}
      </Button>
      <Menu
        id="platforms-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem
          onClick={() => {
            changePlatformFilter('AMAZON');
            closeMenu();
          }}
        >
          Amazon
        </MenuItem>
        <MenuItem
          onClick={() => {
            changePlatformFilter('TAKEALOT');
            closeMenu();
          }}
        >
          Takealot
        </MenuItem>
      </Menu>
    </>
  );
};

PlatformSelector.defaultProps = {
  platformFilter: 'AMAZON',
  changePlatformFilter: (newFilter) =>
    console.log('User changes the platform filter to:', newFilter),
};

PlatformSelector.propTypes = {
  platformFilter: PropTypes.string,
  changePlatformFilter: PropTypes.func,
};

export default PlatformSelector;
