import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  loadingWrapper: {
    padding: theme.spacing(8),
    textAlign: 'center',
  },
}));

const BillingInfoCard = ({ billingInfo, isLoading, onMissingInfoClick }) => {
  const classes = useStyles();
  const {
    legalEntityName,
    vatNumber,
    emailAddress,
    telephoneNumber,
    mainContact,
    physicalAddress,
  } = billingInfo;
  return (
    <Card>
      <CardHeader title="Billing Info" />
      <Divider />
      <CardContent>
        {isLoading ? (
          <div className={classes.loadingWrapper}>
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <List>
            <ListItem>
              <ListItemText
                primary={
                  legalEntityName.length === 0
                    ? 'Missing Info'
                    : legalEntityName
                }
                secondary="Legal Entity Name"
              />
              {legalEntityName.length === 0 && (
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={onMissingInfoClick}
                  >
                    <HelpOutlineIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
            <ListItem>
              <ListItemText
                primary={vatNumber.length === 0 ? 'Missing Info' : vatNumber}
                secondary="VAT Number"
              />
              {vatNumber.length === 0 && (
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={onMissingInfoClick}
                  >
                    <HelpOutlineIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  physicalAddress.length === 0
                    ? 'Missing Info'
                    : physicalAddress
                }
                secondary="Physical Address"
              />
              {physicalAddress.length === 0 && (
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={onMissingInfoClick}
                  >
                    <HelpOutlineIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  mainContact.length === 0 ? 'Missing Info' : mainContact
                }
                secondary="Main Contact"
              />
              {mainContact.length === 0 && (
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={onMissingInfoClick}
                  >
                    <HelpOutlineIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  emailAddress.length === 0 ? 'Missing Info' : emailAddress
                }
                secondary="Email Address"
              />
              {emailAddress.length === 0 && (
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={onMissingInfoClick}
                  >
                    <HelpOutlineIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  telephoneNumber.length === 0
                    ? 'Missing Info'
                    : telephoneNumber
                }
                secondary="Telephone Number"
              />
              {telephoneNumber.length === 0 && (
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={onMissingInfoClick}
                  >
                    <HelpOutlineIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          </List>
        )}
      </CardContent>
    </Card>
  );
};

BillingInfoCard.defaultProps = {
  billingInfo: {
    emailAddress: PropTypes.string,
    legalEntityName: PropTypes.string,
    mainContact: PropTypes.string,
    physicalAddress: PropTypes.string,
    telephoneNumber: PropTypes.string,
    vatNumber: PropTypes.string,
  },
  isLoading: false,
  onMissingInfoClick: () => console.log('Missing info button was clicked.'),
};

BillingInfoCard.propTypes = {
  billingInfo: PropTypes.shape({
    emailAddress: PropTypes.string,
    legalEntityName: PropTypes.string,
    mainContact: PropTypes.string,
    physicalAddress: PropTypes.string,
    telephoneNumber: PropTypes.string,
    vatNumber: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
  onMissingInfoClick: PropTypes.func,
};

export default BillingInfoCard;
