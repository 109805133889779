import _ from 'lodash';

const getFormattedClients = (
  clients,
  searchQuery,
  statusFilter,
  typeFilter
) => {
  console.log('type filter', typeFilter);
  return clients
    .map((info) => {
      let formattedType = info.type;
      let { status } = info;
      const { billingInfo } = info;

      if (status === 'HIGH_PRIORITY') {
        status = 'ACTIVE';
      }

      if (
        billingInfo.emailAddress.length === 0 ||
        billingInfo.legalEntityName.length === 0 ||
        billingInfo.mainContact.length === 0 ||
        billingInfo.physicalAddress.length === 0 ||
        billingInfo.telephoneNumber.length === 0 ||
        billingInfo.vatNumber.length === 0
      ) {
        status = 'MISSING_INFO';
      }

      switch (formattedType) {
        case 'SUPER_DEALER':
          formattedType = 'Super Dealer';
          break;
        default:
          formattedType = 'Standard';
          break;
      }

      return {
        status,
        formattedType,
        id: info.id,
        type: info.type,
        name: info.name,
        emailAddress: billingInfo.emailAddress,
        legalEntityName: billingInfo.legalEntityName,
        mainContact: billingInfo.mainContact,
        physicalAddress: billingInfo.physicalAddress,
        telephoneNumber: billingInfo.telephoneNumber,
        vatNumber: billingInfo.vatNumber,
      };
    })
    .filter((info) => {
      if (searchQuery !== '') {
        const caseInsensitiveSearch = _.toLower(searchQuery);
        const caseInsensitiveName = _.toLower(info.name);
        const caseInsensitiveLegalEntityName = _.toLower(info.legalEntityName);
        const caseInsensitiveMainContact = _.toLower(info.mainContact);
        const caseInsensitiveStatus = _.toLower(info.status);

        if (caseInsensitiveName.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveLegalEntityName.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveMainContact.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveStatus.includes(caseInsensitiveSearch)) {
          return true;
        }

        return false;
      }

      return true;
    })
    .filter((info) => {
      if (statusFilter === 'ALL') {
        return true;
      }

      return info.status === statusFilter;
    })
    .filter((info) => {
      if (typeFilter === 'ALL') {
        return true;
      }

      if (typeFilter === 'STANDARD') {
        return info.type === 'STANDARD' || !info.type;
      }

      return info.type === typeFilter;
    })
    .sort((a, b) => {
      if (a.status === 'MISSING_INFO' && b.status !== 'MISSING_INFO') return -1;
      if (a.status !== 'MISSING_INFO' && b.status === 'MISSING_INFO') return +1;
      if (a.name < b.name) return -1;
      if (a.name > b.name) return +1;
      return 0;
    });
};

export default getFormattedClients;
