import { auth } from '../utils/firebase';

export const signIn = async (email, password) => {
  return auth
    .signInWithEmailAndPassword(email, password)
    .then((user) => {
      return {
        uid: user.user.uid,
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error: {
          code: error.code,
          message: error.message,
        },
        status: 500,
      };
    });
};

export const signOut = async () => {
  return auth
    .signOut()
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const sendPasswordResetEmail = async (email) => {
  return auth
    .sendPasswordResetEmail(email)
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error: {
          code: error.code,
          message: error.message,
        },
        status: 500,
      };
    });
};
