/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getSku, uploadImage } from '../../../api/listingsAPI';

const initialState = {
  skuInfo: {
    sku: '',
    amazon: {
      status: 'INACTIVE',
    },
    takealot: {
      status: 'INACTIVE',
    },
  },
  loadingStatus: {
    skuInfo: 'NOT_STARTED',
  },
  uploadTransfers: {},
  imageUploadCounts: {
    total: 0,
    success: 0,
    failed: 0,
  },
  feedback: {
    isOpen: false,
    type: undefined,
    message: '',
  },
};

const listingsImageUploadSlice = createSlice({
  name: 'listingsImageUpload',
  initialState,
  reducers: {
    setTotalImageUploadCount(state, action) {
      state.imageUploadCounts.total = action.payload;
    },
    uploadImageSuccess(state) {
      state.imageUploadCounts.success += 1;
    },
    uploadImageFailed(state) {
      state.imageUploadCounts.failed += 1;
    },
    getSkuStarted(state) {
      state.loadingStatus.skuInfo = 'PENDING';
    },
    getSkuSuccess(state, action) {
      state.loadingStatus.skuInfo = 'COMPLETE';
      state.skuInfo = action.payload;
    },
    getSkuFailed(state, action) {
      state.loadingStatus.skuInfo = 'COMPLETE';
      state.feedback = {
        isOpen: true,
        type: 'error',
        message: action.payload.message,
      };
    },
    updateUploadBytes(state, action) {
      state.uploadTransfers = {
        ...state.uploadTransfers,
        [action.payload.imageId]: {
          totalBytes: action.payload.totalBytes,
          transferredBytes: action.payload.transferredBytes,
        },
      };
    },
    resetUploadBytes(state) {
      state.uploadTotalBytes = 0;
      state.uploadTransferredBytes = 0;
    },
    resetState() {
      return initialState;
    },
  },
});

export const {
  setTotalImageUploadCount,
  uploadImageSuccess,
  uploadImageFailed,
  getSkuStarted,
  getSkuSuccess,
  getSkuFailed,
  resetState,
  updateUploadBytes,
  resetUploadBytes,
} = listingsImageUploadSlice.actions;

export default listingsImageUploadSlice.reducer;

export const uploadNewImage = (file, tag, sku) => async (dispatch) => {
  await uploadImage(
    file,
    tag,
    sku,
    (imageId, totalBytes, transferredBytes) =>
      dispatch(updateUploadBytes({ imageId, totalBytes, transferredBytes })),
    () => dispatch(uploadImageSuccess()),
    () => dispatch(uploadImageFailed())
  );
  dispatch(resetUploadBytes());
};

export const fetchSkuInfo = (sku) => async (dispatch) => {
  dispatch(getSkuStarted());
  const result = await getSku(sku);

  if (result.status === 200) {
    dispatch(getSkuSuccess(result.skuInfo));
  } else {
    dispatch(getSkuFailed(result.error));
  }
};
