import { useEffect, useRef, useState } from 'react';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import numeral from 'numeral';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import { CSVReader } from 'react-papaparse';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  alert: {
    marginTop: theme.spacing(3),
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    padding: theme.spacing(4),
  },
  explanationWrapper: {
    color: grey[700],
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  loadingIndicator: {
    marginBottom: theme.spacing(2),
  },
}));

const getSteps = () => {
  return ['Upload report', 'Check SKUs', 'Update database'];
};

const getLoadingValue = (loadingCount) => {
  if (loadingCount.total === 0) {
    return -1;
  }
  return Math.round(
    ((loadingCount.success + loadingCount.failed) / loadingCount.total) * 100
  );
};

const UploadReportDialog = ({
  selectedReportId,
  loadSkus,
  skuLoadingCount,
  failedSkus,
  isOpen,
  uploadReportData,
  uploadReportLoadingStatus,
  closeDialog,
}) => {
  const classes = useStyles();
  const steps = getSteps();

  const reportUploadRef = useRef();

  const skuLoadingValue = getLoadingValue(skuLoadingCount);

  const [activeStep, setActiveStep] = useState(0);
  const [reportData, setReportData] = useState([]);
  const [uploadError, setUploadError] = useState({
    isError: false,
    message: '',
  });
  const [isNextEnabled, setIsNextEnabled] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setActiveStep(0);
      setReportData([]);
      setIsNextEnabled(false);
      setUploadError({
        isError: false,
        message: '',
      });
    }
  }, [isOpen]);

  useEffect(() => {
    if (reportUploadRef.current) {
      reportUploadRef.current.removeFile();
    }

    if (activeStep === 1) {
      if (
        selectedReportId ===
        'amazon-detail-page-sales-and-traffic-by-child-item'
      ) {
        const asinsToLoad = reportData.map((info) => ({
          code: info.childAsin,
          title: info.title,
        }));
        loadSkus(asinsToLoad, 'childAsin');
      } else if (selectedReportId === 'takealot-product-sales') {
        const tsinsToLoad = reportData.map((info) => ({
          code: info.tsin,
          title: info.productTitle,
        }));
        loadSkus(tsinsToLoad, 'tsin');
      } else {
        const skusToLoad = reportData.map((info) => ({
          code: info.sku,
          title: info.title,
        }));
        loadSkus(skusToLoad, 'sku');
      }
    }

    if (activeStep === 2) {
      uploadReportData(reportData);
    }

    if (activeStep === 0) {
      setIsNextEnabled(false);
    } else {
      setIsNextEnabled(true);
    }
  }, [activeStep]);

  const checkFields = (fields, expectedFields) => {
    let isCorrect = true;
    fields.forEach((field, index) => {
      if (field !== expectedFields[index]) {
        isCorrect = false;
      }
    });
    return isCorrect;
  };

  const formatAmazonDetailPageSalesAndTrafficByChildItemData = (data) => {
    const expectedFields = [
      '(Parent) ASIN',
      '(Child) ASIN',
      'Title',
      'Sessions',
      'Session Percentage',
      'Page Views',
      'Page Views Percentage',
      'Buy Box Percentage',
      'Units Ordered',
      'Units Ordered - B2B',
      'Unit Session Percentage',
      'Unit Session Percentage - B2B',
      'Ordered Product Sales',
      'Ordered Product Sales - B2B',
      'Total Order Items',
      'Total Order Items - B2B',
    ];
    const fields = data[0].data;
    const isFieldsCorrect = checkFields(fields, expectedFields);

    if (isFieldsCorrect) {
      setUploadError({
        isError: false,
        message: '',
      });

      const formattedData = data
        .filter((lineItem, index) => index < data.length - 1 && index > 0)
        .map((lineItem) => {
          const unformattedData = lineItem.data;

          return {
            parentAsin: unformattedData[0],
            childAsin: unformattedData[1],
            title: unformattedData[2],
            sessions: numeral(unformattedData[3]).value(),
            sessionPercentage: numeral(unformattedData[4]).value(),
            pageViews: numeral(unformattedData[5]).value(),
            pageViewsPercentage: numeral(unformattedData[6]).value(),
            buyBoxPercentage: numeral(unformattedData[7]).value(),
            unitsOrdered: numeral(unformattedData[8]).value(),
            unitsOrderedB2B: numeral(unformattedData[9]).value(),
            unitSessionPercentage: numeral(unformattedData[10]).value(),
            unitSessionPercentageB2B: numeral(unformattedData[11]).value(),
            orderedProductSales: numeral(unformattedData[12]).value(),
            orderedProductSalesB2B: numeral(unformattedData[13]).value(),
            totalOrderItems: numeral(unformattedData[14]).value(),
            totalOrderItemsB2B: numeral(unformattedData[15]).value(),
          };
        });

      setReportData(formattedData);
      setIsNextEnabled(true);
    } else {
      setUploadError({
        isError: true,
        message:
          'Incorrect report uploaded. Please upload the Detail Page Sales and Traffic by Child Item report.',
      });
    }
  };

  const formatTakealotProductSalesData = (data) => {
    const expectedFields = [
      'TSIN',
      'SKU',
      'Takealot Barcode',
      'Product Title',
      'Status',
      'Page Views',
      'Conversion Rate',
      'Units Sold CPT',
      'Units Sold JHB',
      'Total units sold',
      'Sales CPT (Rand)',
      'Sales JHB (Rand)',
      'Total sales (Rand)',
      'Units Returned',
      'Value of Returns (Rand)',
      'In Stock CPT',
      'In Stock JHB',
      'Total In Stock at Takealot',
      'SOH My Warehouse',
      'Days since last sale',
      'In Stock Days Cover (used for storage fee calculation)',
      'RRP',
      'Current Price',
      '% saving',
      'Leadtime',
    ];
    const fields = data[0].data;
    const isFieldsCorrect = checkFields(fields, expectedFields);

    if (isFieldsCorrect) {
      setUploadError({
        isError: false,
        message: '',
      });

      const formattedData = data
        .filter((lineItem, index) => index < data.length - 1 && index > 1)
        .map((lineItem) => {
          const unformattedData = lineItem.data;

          return {
            tsin: numeral(unformattedData[0]).value(),
            sku: unformattedData[1],
            productTitle: unformattedData[3]
              ? unformattedData[3].replace(/"/g, '')
              : '',
            pageViews: numeral(unformattedData[5]).value(),
            conversionRate: numeral(unformattedData[6]).value() / 100,
            totalUnitsSold: numeral(unformattedData[9]).value(),
            totalSalesRand: numeral(unformattedData[12]).value(),
            unitsReturned: numeral(unformattedData[13]).value(),
            valueOfReturnsRand: numeral(unformattedData[14]).value(),
          };
        });

      setReportData(formattedData);
      setIsNextEnabled(true);
    } else {
      setUploadError({
        isError: true,
        message:
          'Incorrect report uploaded. Please upload the Product Sales report.',
      });
    }
  };

  const handleFileLoad = (data) => {
    switch (selectedReportId) {
      case 'amazon-detail-page-sales-and-traffic-by-child-item':
        formatAmazonDetailPageSalesAndTrafficByChildItemData(data);
        break;
      case 'takealot-product-sales':
        formatTakealotProductSalesData(data);
        break;
      default:
        setUploadError({
          isError: true,
          message: 'Unknown error with file.',
        });
    }
  };

  const handleError = (err) => {
    console.log(err);
  };

  const getDelimiter = () => {
    switch (selectedReportId) {
      case 'amazon-detail-page-sales-and-traffic-by-child-item':
      case 'takealot-product-sales':
        return ',';
      default:
        return '\t';
    }
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <CSVReader
            ref={reportUploadRef}
            onDrop={handleFileLoad}
            onError={handleError}
            addRemoveButton
            config={{
              delimiter: getDelimiter(),
            }}
          >
            <span>Drop report here or click to upload.</span>
          </CSVReader>
        );
      case 1:
        return (
          <div>
            {skuLoadingValue < 100 && (
              <div>
                <LinearProgress
                  color="secondary"
                  className={classes.loadingIndicator}
                  variant="determinate"
                  value={skuLoadingValue}
                />
                <Typography component="p" variant="subtitle1" align="center">
                  Fetching product info from database...
                </Typography>
              </div>
            )}
            {failedSkus.length > 0 && (
              <div>
                <Alert className={classes.alert} severity="error">
                  {`Could not find ${failedSkus.length} skus.`}
                </Alert>
                <List>
                  {failedSkus.map((info) => (
                    <ListItem key={info.code}>
                      <ListItemText
                        primary={info.code}
                        secondary={info.title}
                      />
                    </ListItem>
                  ))}
                </List>
              </div>
            )}
            {skuLoadingValue === 100 && failedSkus.length === 0 && (
              <div>
                <Alert className={classes.alert} severity="success">
                  All SKUs found in the database.
                </Alert>
              </div>
            )}
          </div>
        );
      case 2:
        return (
          <div>
            {uploadReportLoadingStatus !== 'COMPLETE' && (
              <div>
                <LinearProgress
                  color="secondary"
                  className={classes.loadingIndicator}
                  variant="indeterminate"
                />
                <Typography component="p" variant="subtitle1" align="center">
                  Updating database...
                </Typography>
              </div>
            )}
            {uploadReportLoadingStatus === 'COMPLETE' && (
              <div>
                <Alert className={classes.alert} severity="success">
                  Report data has been uploaded.
                </Alert>
              </div>
            )}
          </div>
        );
      default:
        return 'Unknown stepIndex';
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const getReportName = () => {
    switch (selectedReportId) {
      case 'amazon-detail-page-sales-and-traffic-by-child-item':
        return 'Amazon Report: Detail Page Sales and Traffic by Child Item';
      case 'takealot-product-sales':
        return 'Takealot Report: Product Sales';
      default:
        return 'Loading...';
    }
  };

  const getStepExplanation = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        if (
          selectedReportId ===
          'amazon-detail-page-sales-and-traffic-by-child-item'
        ) {
          return 'Download the report in the Business Reports section of Seller Central.';
        }
        if (selectedReportId === 'takealot-product-sales') {
          return 'Download the report in the Reports section of the Takealot Seller portal.';
        }
        return '';
      case 1:
        return '';
      case 2:
        return '';
      default:
        return 'Unknown stepIndex';
    }
  };

  return (
    <Dialog open={isOpen}>
      <DialogTitle>{getReportName()}</DialogTitle>
      <DialogContent className={classes.contentWrapper}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <DialogContentText className={classes.explanationWrapper}>
          {getStepExplanation(activeStep)}
        </DialogContentText>
        {getStepContent(activeStep)}
        {uploadError.isError && (
          <Alert className={classes.alert} severity="error">
            {uploadError.message}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        {activeStep < 2 && (
          <Button onClick={closeDialog} color="primary">
            Cancel
          </Button>
        )}
        <Button
          disabled={
            uploadError.isError ||
            !isNextEnabled ||
            (skuLoadingValue !== -1 && skuLoadingValue < 100) ||
            uploadReportLoadingStatus === 'PENDING'
          }
          onClick={activeStep === steps.length - 1 ? closeDialog : handleNext}
          variant="contained"
          color="secondary"
        >
          {activeStep === steps.length - 1 ? 'Done' : 'Next'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UploadReportDialog.defaultProps = {
  isOpen: true,
  selectedReportId: '',
  skuLoadingCount: {
    total: 0,
    success: 0,
    failed: 0,
  },
  failedSkus: [],
  loadSkus: (codeList, codeType) =>
    console.log(
      `User loaded SKUs using the following codes of type ${codeType}: `,
      codeList
    ),
  uploadReportData: (reportData) =>
    console.log('User uploaded report data:', reportData),
  uploadReportLoadingStatus: 'NOT_STARTED',
  closeDialog: () => console.log('Create statement report dialog was closed.'),
};

UploadReportDialog.propTypes = {
  isOpen: PropTypes.bool,
  selectedReportId: PropTypes.string,
  skuLoadingCount: PropTypes.shape({
    total: PropTypes.number,
    success: PropTypes.number,
    failed: PropTypes.number,
  }),
  failedSkus: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  loadSkus: PropTypes.func,
  uploadReportData: PropTypes.func,
  uploadReportLoadingStatus: PropTypes.string,
  closeDialog: PropTypes.func,
};

export default UploadReportDialog;
