import _ from 'lodash';

const getFormattedListings = (
  skus,
  searchQuery,
  clientFilter,
  platformFilter,
  statusFilter
) => {
  return skus
    .map((info) => {
      return {
        sku: info.sku,
        clientName: info.clientName,
        barcode:
          platformFilter === 'AMAZON'
            ? info.amazon.barcode
            : info.takealot.barcode,
        asin: info.amazon.childAsin,
        tsin: info.takealot.tsin,
        brand: info.brand,
        productName: info.productName,
        variation: info.variation,
        weight: info.measurements ? info.measurements.weight : null,
        length: info.measurements ? info.measurements.length : null,
        width: info.measurements ? info.measurements.width : null,
        height: info.measurements ? info.measurements.height : null,
        stockImagesLink: info.stockImagesLink,
        status:
          platformFilter === 'AMAZON'
            ? info.amazon.status
            : info.takealot.status,
        creationDate: info.creationDate || '-',
      };
    })
    .filter((info) => {
      if (searchQuery !== '') {
        const caseInsensitiveSearch = _.toLower(searchQuery);
        const caseInsensitiveSku = _.toLower(info.sku);
        const caseInsensitiveAsin = _.toLower(info.asin);
        const caseInsensitiveTsin = _.toLower(info.tsin);
        const caseInsensitiveVariation = _.toLower(info.variation);
        const caseInsensitiveProductName = _.toLower(info.productName);
        const caseInsensitiveClientName = _.toLower(info.clientName);
        const caseInsensitiveBrand = _.toLower(info.brand);
        const caseInsensitiveBarcode = _.toLower(info.barcode);

        if (caseInsensitiveSku.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveAsin.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveTsin.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveVariation.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveProductName.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveClientName.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveBrand.includes(caseInsensitiveSearch)) {
          return true;
        }
        if (caseInsensitiveBarcode.includes(caseInsensitiveSearch)) {
          return true;
        }

        return false;
      }

      return true;
    })
    .filter((info) => {
      if (clientFilter !== 'ALL') {
        return info.clientName === clientFilter;
      }
      return true;
    })
    .filter((info) => {
      if (statusFilter !== 'ALL') {
        return info.status === statusFilter;
      }
      return true;
    })
    .sort((a, b) => {
      if (a.creationDate < b.creationDate) return +1;
      if (a.creationDate > b.creationDate) return -1;
      if (a.clientName < b.clientName) return -1;
      if (a.clientName > b.clientName) return +1;
      if (a.brand < b.brand) return -1;
      if (a.brand > b.brand) return +1;
      if (a.productName < b.productName) return -1;
      if (a.productName > b.productName) return +1;
      if (a.variation < b.variation) return -1;
      if (a.variation > b.variation) return +1;
      return 0;
    });
};

export default getFormattedListings;
