import { useState } from 'react';
import PersonIcon from '@material-ui/icons/Person';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';

const AssigneeSelector = ({
  assigneeFilter,
  changeAssigneeFilter,
  assigneeOptions,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const getPrettyFilter = (filter) => {
    if (filter === 'ALL') {
      return 'All Assignees';
    }
    return filter;
  };

  return (
    <>
      <Button
        aria-controls="assignees-menu"
        aria-haspopup="true"
        size="large"
        startIcon={<PersonIcon />}
        onClick={openMenu}
      >
        {getPrettyFilter(assigneeFilter)}
      </Button>
      <Menu
        id="assignee-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem
          onClick={() => {
            changeAssigneeFilter('ALL');
            closeMenu();
          }}
        >
          All
        </MenuItem>
        {assigneeOptions.map((option) => (
          <MenuItem
            key={`filter-option-${option}`}
            onClick={() => {
              changeAssigneeFilter(option);
              closeMenu();
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

AssigneeSelector.defaultProps = {
  assigneeFilter: 'ALL',
  changeAssigneeFilter: (newFilter) =>
    console.log('User changes the assignee filter to:', newFilter),
  assigneeOptions: [],
};

AssigneeSelector.propTypes = {
  assigneeFilter: PropTypes.string,
  changeAssigneeFilter: PropTypes.func,
  assigneeOptions: PropTypes.arrayOf(PropTypes.string),
};

export default AssigneeSelector;
