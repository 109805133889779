import moment from 'moment';
import { firestore, functions } from '../utils/firebase';

export const createReport = async (
  reportCode,
  dateString,
  uploaderId,
  uploaderName,
  data
) => {
  const batch = firestore.batch();

  if (reportCode === 'takealot-product-sales') {
    const secondDayDateString = moment(dateString, 'DDMMYYYY')
      .add(1, 'days')
      .format('DDMMYYYY');

    const firstDayReportRef = firestore
      .collection('reports')
      .doc(`${reportCode}-${dateString}`);

    const secondDayReportRef = firestore
      .collection('reports')
      .doc(`${reportCode}-${secondDayDateString}`);

    const firstDayData = data.map((item) => ({
      ...item,
      pageViews: Math.floor(item.pageViews / 2),
      conversionRate:
        item.pageViews === 0
          ? 0
          : Math.floor(item.totalUnitsSold / 2) /
            Math.floor(item.pageViews / 2),
      totalUnitsSold: Math.floor(item.totalUnitsSold / 2),
      totalSalesRand: Math.floor(item.totalSalesRand / 2),
      valueOfReturnsRand: Math.floor(item.valueOfReturnsRand / 2),
    }));

    const secondDayData = data.map((item) => ({
      ...item,
      pageViews: Math.ceil(item.pageViews / 2),
      conversionRate:
        item.pageViews === 0
          ? 0
          : Math.ceil(item.totalUnitsSold / 2) / Math.ceil(item.pageViews / 2),
      totalUnitsSold: Math.ceil(item.totalUnitsSold / 2),
      totalSalesRand: Math.ceil(item.totalSalesRand / 2),
      valueOfReturnsRand: Math.ceil(item.valueOfReturnsRand / 2),
    }));

    batch.set(firstDayReportRef, {
      uploaderId,
      uploaderName,
      data: firstDayData,
      reportType: reportCode,
      uploadDate: new Date(),
      reportDate: moment(dateString, 'DDMMYYYY').toDate(),
    });
    batch.set(secondDayReportRef, {
      uploaderId,
      uploaderName,
      data: secondDayData,
      reportType: reportCode,
      uploadDate: new Date(),
      reportDate: moment(secondDayDateString, 'DDMMYYYY').toDate(),
    });
    if (
      moment(dateString, 'DDMMYYYY').format('MMYYYY') ===
      moment(secondDayDateString, 'DDMMYYYY').format('MMYYYY')
    ) {
      const reportSummaryRef = firestore
        .collection('reportSummaries')
        .doc(moment(dateString, 'DDMMYYYY').format('MMYYYY'));
      batch.set(
        reportSummaryRef,
        {
          [`${moment(dateString, 'DDMMYYYY').format(
            'DD'
          )}-${reportCode}`]: true,
          [`${moment(secondDayDateString, 'DDMMYYYY').format(
            'DD'
          )}-${reportCode}`]: true,
        },
        { merge: true }
      );
    } else {
      const firstDayReportSummaryRef = firestore
        .collection('reportSummaries')
        .doc(moment(dateString, 'DDMMYYYY').format('MMYYYY'));

      const secondDayReportSummaryRef = firestore
        .collection('reportSummaries')
        .doc(moment(secondDayDateString, 'DDMMYYYY').format('MMYYYY'));

      batch.set(
        firstDayReportSummaryRef,
        {
          [`${moment(dateString, 'DDMMYYYY').format(
            'DD'
          )}-${reportCode}`]: true,
        },
        { merge: true }
      );
      batch.set(
        secondDayReportSummaryRef,
        {
          [`${moment(secondDayDateString, 'DDMMYYYY').format(
            'DD'
          )}-${reportCode}`]: true,
        },
        { merge: true }
      );
    }
  } else {
    const newReportRef = firestore
      .collection('reports')
      .doc(`${reportCode}-${dateString}`);

    const reportSummaryRef = firestore
      .collection('reportSummaries')
      .doc(moment(dateString, 'DDMMYYYY').format('MMYYYY'));
    batch.set(newReportRef, {
      uploaderId,
      uploaderName,
      data,
      reportType: reportCode,
      uploadDate: new Date(),
      reportDate: moment(dateString, 'DDMMYYYY').toDate(),
    });
    batch.set(
      reportSummaryRef,
      {
        [`${moment(dateString, 'DDMMYYYY').format('DD')}-${reportCode}`]: true,
      },
      { merge: true }
    );
  }

  return batch
    .commit()
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const getReportSummary = async (monthString) => {
  const reportSummaryRef = firestore
    .collection('reportSummaries')
    .doc(monthString);

  return reportSummaryRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        const summary = doc.data();
        return {
          summary,
          status: 200,
        };
      }
      return {
        summary: {},
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const getRecentTakealotOrders = async () => {
  const updateRecentOrders = functions.httpsCallable(
    'manuallyUpdateRecentTakealotOrders'
  );

  return updateRecentOrders({
    startDate: moment().toISOString(),
    endDate: moment().subtract(1, 'days').toISOString(),
  })
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};

export const updateOutdatedTakealotSkus = async () => {
  const updateTakealotSkus = functions.httpsCallable(
    'updateOutdatedTakealotSkus'
  );

  return updateTakealotSkus()
    .then(() => {
      return {
        status: 200,
      };
    })
    .catch((error) => {
      return {
        error,
        status: 500,
      };
    });
};
