/* eslint-disable react/prop-types */
import { fade } from '@material-ui/core/styles/colorManipulator';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

const headerStyle = {
  backgroundColor: '#A7A5A5',
  color: '#171415',
};

const useStyles = makeStyles((theme) => ({
  loadingOverlay: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: fade(theme.palette.common.black, 0.8),
  },
  wrapperContent: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

const SKUPricingTakealotTable = ({
  skus,
  updateSkus,
  updateBasedOnCostPricing,
  updateBasedOnWholesalePricing,
  updateBasedOnRetailPricing,
}) => {
  const classes = useStyles();

  const columns = [
    {
      title: 'Description',
      field: 'description',
      editable: 'never',
    },
    {
      title: 'Wholesale Price',
      field: 'wholesalePrice',
      render: (rowData) => (
        <TextField
          InputProps={{
            startAdornment: <InputAdornment position="start">R</InputAdornment>,
          }}
          type="number"
          value={rowData.wholesalePrice}
          onBlur={() => updateBasedOnWholesalePricing(rowData.id)}
          onChange={(e) => {
            updateSkus(
              skus.map((skuInfo) => {
                if (skuInfo.id === rowData.id) {
                  return {
                    ...skuInfo,
                    wholesalePrice: e.target.value,
                  };
                }
                return skuInfo;
              })
            );
          }}
        />
      ),
    },
    {
      title: 'Cost Price',
      field: 'costPrice',
      render: (rowData) => (
        <TextField
          InputProps={{
            startAdornment: <InputAdornment position="start">R</InputAdornment>,
          }}
          type="number"
          value={rowData.costPrice}
          onBlur={() => updateBasedOnCostPricing(rowData.id)}
          onChange={(e) => {
            updateSkus(
              skus.map((skuInfo) => {
                if (skuInfo.id === rowData.id) {
                  return {
                    ...skuInfo,
                    costPrice: e.target.value,
                  };
                }
                return skuInfo;
              })
            );
          }}
        />
      ),
    },
    {
      title: 'Retail Price',
      field: 'retailPrice',
      render: (rowData) => (
        <TextField
          InputProps={{
            startAdornment: <InputAdornment position="start">R</InputAdornment>,
          }}
          type="number"
          value={rowData.retailPrice}
          onBlur={() => updateBasedOnRetailPricing(rowData.id)}
          onChange={(e) => {
            updateSkus(
              skus.map((skuInfo) => {
                if (skuInfo.id === rowData.id) {
                  return {
                    ...skuInfo,
                    retailPrice: e.target.value,
                  };
                }
                return skuInfo;
              })
            );
          }}
        />
      ),
    },
  ];

  return (
    <div className={classes.wrapperContent}>
      <MaterialTable
        columns={columns}
        data={skus}
        options={{
          headerStyle,
          sorting: false,
          grouping: false,
          draggable: false,
          search: false,
          showTitle: false,
          toolbar: false,
          paging: false,
        }}
        components={{
          OverlayLoading: () => (
            <div className={classes.loadingOverlay}>
              <CircularProgress color="primary" size={32} />
            </div>
          ),
        }}
        localization={{
          header: {
            actions: '',
          },
          body: {
            emptyDataSourceMessage: 'No Takealot SKUs.',
          },
        }}
      />
    </div>
  );
};

SKUPricingTakealotTable.defaultProps = {
  skus: [],
  updateSkus: (newSkus) => console.log('Skus were updated to:', newSkus),
  updateBasedOnCostPricing: (newSkus, id) =>
    console.log('Skus and pricing were updated using:', id),
  updateBasedOnWholesalePricing: (newSkus, id) =>
    console.log('Skus and pricing were updated using:', id),
  updateBasedOnRetailPricing: (newSkus, id) =>
    console.log('Skus and pricing were updated using:', id),
};

SKUPricingTakealotTable.propTypes = {
  skus: PropTypes.arrayOf(PropTypes.shape()),
  updateSkus: PropTypes.func,
  updateBasedOnCostPricing: PropTypes.func,
  updateBasedOnWholesalePricing: PropTypes.func,
  updateBasedOnRetailPricing: PropTypes.func,
};

export default SKUPricingTakealotTable;
