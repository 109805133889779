import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import DateFnsUtils from '@date-io/moment';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.primary.contrastText,
    margin: `0 ${theme.spacing(2)}px`,
  },
}));

const RangeSelector = ({
  startDate,
  changeStartDate,
  changeEndDate,
  rangeFilter,
  changeRangeFilter,
  ranges,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const getRangeLabel = (id) => {
    const rangeInfo = ranges.find((info) => info.id === id);
    if (!rangeInfo) {
      return 'Unknown';
    }

    return rangeInfo.label;
  };

  return (
    <>
      <Button
        aria-controls="range-menu"
        aria-haspopup="true"
        size="large"
        startIcon={<CalendarTodayIcon />}
        onClick={openMenu}
      >
        {getRangeLabel(rangeFilter)}
      </Button>
      <Menu
        id="range-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {ranges.map((info) => (
          <MenuItem
            key={info.id}
            onClick={() => {
              changeRangeFilter(info.id);
              closeMenu();
            }}
          >
            {info.label}
          </MenuItem>
        ))}
      </Menu>
      {rangeFilter === 'CUSTOM' && (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Typography
            className={classes.text}
            variant="subtitle1"
            component="p"
          >
            |
          </Typography>
          <KeyboardDatePicker
            disableToolbar
            views={['year', 'month']}
            variant="inline"
            format="MMMM YYYY"
            id="date-picker-start"
            value={startDate}
            onChange={(date) => {
              changeStartDate(
                moment(date.toDate()).startOf('month').subtract(1, 'days')
              );
              changeEndDate(moment(date.toDate()).endOf('month'));
            }}
            minDate={new Date('01/01/2020')}
            maxDate={new Date()}
            KeyboardButtonProps={{
              'aria-label': 'change start date',
            }}
          />
        </MuiPickersUtilsProvider>
      )}
    </>
  );
};

RangeSelector.defaultProps = {
  startDate: new Date(),
  changeStartDate: (newValue) =>
    `Start date was changed to '${newValue.toString()}'`,
  changeEndDate: (newValue) =>
    `End date was changed to '${newValue.toString()}'`,
  rangeFilter: 'PAST_7_DAYS',
  changeRangeFilter: (newValue) => `Range was changed to '${newValue}'`,
  ranges: [
    {
      id: 'PAST_7_DAYS',
      label: 'Past 7 Days',
    },
    {
      id: 'PAST_30_DAYS',
      label: 'Past 30 Days',
    },
    {
      id: 'THIS_WEEK',
      label: 'This Week',
    },
    {
      id: 'THIS_MONTH',
      label: 'This Month',
    },
    {
      id: 'CUSTOM',
      label: 'Custom Month',
    },
  ],
};

RangeSelector.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  changeStartDate: PropTypes.func,
  changeEndDate: PropTypes.func,
  rangeFilter: PropTypes.string,
  changeRangeFilter: PropTypes.func,
  ranges: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};

export default RangeSelector;
