import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  loadingWrapper: {
    padding: theme.spacing(8),
    textAlign: 'center',
  },
}));

const UserInfoCard = ({ userInfo, isLoading }) => {
  const classes = useStyles();
  const { name, surname, email, phoneNumber, title, agencyName } = userInfo;

  const getFormattedAgencyName = () => {
    if (agencyName) {
      return agencyName;
    }
    return 'N/A';
  };

  return (
    <Card>
      <CardHeader title="Details" />
      <Divider />
      <CardContent>
        {isLoading ? (
          <div className={classes.loadingWrapper}>
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <List>
            <ListItem>
              <ListItemText primary={name} secondary="First Name" />
            </ListItem>
            <ListItem>
              <ListItemText primary={surname} secondary="Surname" />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={getFormattedAgencyName()}
                secondary="Agency"
              />
            </ListItem>
            <ListItem>
              <ListItemText primary={title} secondary="Job Title" />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={email || 'Unknown'}
                secondary="Email Address"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={phoneNumber || 'Unknown'}
                secondary="Phone Number"
              />
            </ListItem>
          </List>
        )}
      </CardContent>
    </Card>
  );
};

UserInfoCard.defaultProps = {
  userInfo: {
    id: '12345',
    name: 'Jane',
    surname: 'Doe',
    email: 'jane@doe.com',
    phoneNumber: '+27 84 444 5555',
    title: 'Senior Anonymous Officer',
    agencyName: 'EasyOnline',
  },
  isLoading: false,
};

UserInfoCard.propTypes = {
  userInfo: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    surname: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    title: PropTypes.string,
    agencyName: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
};

export default UserInfoCard;
