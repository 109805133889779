import { useState } from 'react';
import Button from '@material-ui/core/Button';
import CategoryIcon from '@material-ui/icons/ViewHeadline';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';

const ReportTypeSelector = ({ reportType, changeReportType, options }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const getReportTypeLabel = (id) => {
    const reportTypeInfo = options.find((info) => info.id === id);
    if (!reportTypeInfo) {
      return 'Unknown';
    }

    return reportTypeInfo.label;
  };

  return (
    <>
      <Button
        aria-controls="report-types-menu"
        aria-haspopup="true"
        size="large"
        startIcon={<CategoryIcon />}
        onClick={openMenu}
      >
        {getReportTypeLabel(reportType)}
      </Button>
      <Menu
        id="report-types-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {options
          .map((info) => info)
          .map((info) => (
            <MenuItem
              key={info.id}
              onClick={() => {
                changeReportType(info.id);
                closeMenu();
              }}
            >
              {info.label}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

ReportTypeSelector.defaultProps = {
  reportType: 'ALL',
  changeReportType: (newValue) => `Report type was changed to '${newValue}'`,
  options: [
    {
      id: 'PRICING_STOCK',
      label: 'Pricing & Stock',
    },
    {
      id: 'CONTENT',
      label: 'Content',
    },
  ],
};

ReportTypeSelector.propTypes = {
  reportType: PropTypes.oneOf(['PRICING_STOCK', 'CONTENT']),
  changeReportType: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};

export default ReportTypeSelector;
