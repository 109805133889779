import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles((theme) => ({
  wrapperContent: {
    width: '100%',
    maxWidth: 1200,
    margin: '0 auto',
    padding: theme.spacing(3),
    overflowX: 'hidden',
  },
}));

const ContentWrapper = ({ children }) => {
  const classes = useStyles();
  const [sectionChange, setSectionChange] = useState(true);

  return (
    <div className={classes.wrapperContent}>
      <Slide
        direction="left"
        in={sectionChange}
        onExited={() => setSectionChange(false)}
        mountOnEnter
        unmountOnExit
      >
        <div>{children}</div>
      </Slide>
    </div>
  );
};

ContentWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContentWrapper;
