import { useState } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PersonIcon from '@material-ui/icons/Person';
import PropTypes from 'prop-types';

const SalesPersonSelector = ({
  salesPersonId,
  changeSalesPerson,
  salesPeople,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const getSalesPersonName = (id) => {
    if (id === 'ALL') {
      return 'All Salespeople';
    }

    const salesPersonInfo = salesPeople.find((info) => info.id === id);
    if (!salesPersonInfo) {
      return 'Unknown';
    }

    return salesPersonInfo.name;
  };

  return (
    <>
      <Button
        aria-controls="sales-person-menu"
        aria-haspopup="true"
        size="large"
        startIcon={<PersonIcon />}
        onClick={openMenu}
      >
        {getSalesPersonName(salesPersonId)}
      </Button>
      <Menu
        id="sales-person-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem
          onClick={() => {
            changeSalesPerson('ALL');
            closeMenu();
          }}
        >
          All
        </MenuItem>
        {salesPeople
          .map((info) => info)
          .sort((a, b) => {
            if (a.name > b.name) return +1;
            if (a.name < b.name) return -1;
            return 0;
          })
          .map((info) => (
            <MenuItem
              key={info.id}
              onClick={() => {
                changeSalesPerson(info.id);
                closeMenu();
              }}
            >
              {info.name}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

SalesPersonSelector.defaultProps = {
  salesPersonId: 'ALL',
  changeSalesPerson: (newValue) => `Sales person was changed to '${newValue}'`,
  salesPeople: [
    {
      id: 1,
      name: 'John Doe',
    },
    {
      id: 2,
      name: 'Jane Doe',
    },
  ],
};

SalesPersonSelector.propTypes = {
  salesPersonId: PropTypes.string,
  changeSalesPerson: PropTypes.func,
  salesPeople: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
};

export default SalesPersonSelector;
